import React, { useRef, useEffect, useState, useMemo, useLayoutEffect} from 'react';
import { datesGenerator } from 'dates-generator';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { UserRow } from './';
import moment from 'moment';
import 'moment/locale/da';
import { ROLES } from '../../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/pro-regular-svg-icons';

const months = ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'];

const classNames = mergeStyleSets({
    root: {
        marginTop: 16,
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
    },
    header: {
        height: 30,
        borderRadius: 8,
        padding: '0 8px',
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fff'
    },
    date: {
        // fontWeight: 'bold',
        // fontSize: 11,
        lineHeight: 13,
        color: '#000000'
    },
    userSlotContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    slotContainer: {
        background: 'red',
        width: '100%',
        height: 32
    }
});

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const UserSlots = ({planData, index, projectData}) => {
    const containerReference = useRef(null);
    const [horizontalPositionMap, setHorizontalPositionMap] = useState(null);
    const [width, height] = useWindowSize();

    useEffect(() => {
        if(planData && projectData) {
            const startInput = projectData?.start;
            const endInput = projectData?.end;
            const startDate = new Date(startInput);
            const endDate = new Date(endInput);

            let datesData = datesGenerator({ month: startDate.getMonth(), year: startDate.getFullYear(), startingDay: 1 });
            let dates = datesData['dates'];
            let generatorCounter = 0;
            while((datesData['nextYear'] <= endDate.getFullYear() && datesData['nextMonth'] <= endDate.getMonth()) || generatorCounter < 6 ) {
                datesData = datesGenerator({ month: datesData.nextMonth, year: datesData.nextYear, startingDay: 1 });
                let temp = datesData['dates'];
                // removing duplicated weeks
                if(dates[dates.length - 1][0]['date'] === temp[0][0]['date']) {
                    temp.shift();
                }
                dates = [...dates, ...datesData['dates']];
                generatorCounter++;
            };
            
            startDate.setHours(0);
            endDate.setHours(0);
            const startText = moment(startDate, "YYYY-M-D").format('D.M.YY');
            const endText = moment(endDate, "YYYY-M-D").format('D.M.YY');
            
            const result = {
                startText, endText
            };
            const datePositionCorrespondance = {};
            let counter = 0;
            for (let i = 0; i < dates.length; i++) {
                for (let j = 0; j < dates[i].length; j++) {
                    //date: 1, month: 2, year: 2021
                    let currentDate = new Date(dates[i][j]['year'], dates[i][j]['month'], dates[i][j]['date']);
                    if (currentDate > endDate) {
                        break;
                    }
                    if (startDate <= currentDate) {
                        let key = `${dates[i][j]['year']}-${dates[i][j]['month']+1}-${dates[i][j]['date']}`;
                        result[key] = counter;
                        datePositionCorrespondance[counter] = {
                            value: moment(key, "YYYY-M-D").format('YYYY-MM-DD[T]HH:mm:ss.SSS'),
                            display: `${dates[i][j]['date']}. ${months[dates[i][j]['month']]} ${dates[i][j]['year']}`
                        }
                        counter++;
                    }
                }
            }
            result['end'] = --counter;
            result['positionsToDates'] = datePositionCorrespondance;
            setHorizontalPositionMap(result);
        }
    }, [index, planData, projectData])

    const calculateLength = () => {
        if (horizontalPositionMap && horizontalPositionMap['end'] && containerReference) {
            if(containerReference.current && containerReference.current.offsetWidth) {
                return containerReference.current.offsetWidth / horizontalPositionMap['end'];
            }
        }
        return null;
    }

    const length = useMemo(() => calculateLength(containerReference, horizontalPositionMap), [containerReference, horizontalPositionMap, width])

    return (
        <div className={classNames.root} >
            {/* <ImageColumn /> */}
            {/* height: (35 + 32) * 4 + 24 */}
            <div ref={containerReference} style={{ overflow: 'hidden', width: '100%'}}>
                { length && horizontalPositionMap &&
                    <div className={classNames.userSlotContainer}>
                        {/* project */}
                        <div className={classNames.header}>
                            <div className={classNames.date} style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 16, height: 16, marginRight: 8}}>
                                    <FontAwesomeIcon style={{fontSize: 16}} icon={faPencilRuler}/>
                                </div>
                                {horizontalPositionMap['startText']}
                            </div>
                            <div className={classNames.date}>{horizontalPositionMap['endText']}</div>
                        </div>
                        {/* <div style={{background: `rgba(${planData['color']},1)`, height: 4, borderRadius: 2, marginBottom: 20 }} /> */}
                        {/* machine */}
                        <UserRow isMachine role={ROLES.MACHINE} colorCode={planData['color']} users={planData?.machineRequirements[index]?.machines ?? []} length={length} horizontalPositionMap={horizontalPositionMap} />
                        {/* driver */}
                        <UserRow role={ROLES.DRIVER} colorCode={planData['color']} users={planData?.machineRequirements[index]?.drivers ?? []} length={length} horizontalPositionMap={horizontalPositionMap} />
                        {/* worker */}
                        <UserRow role={ROLES.WORKER} colorCode={planData['color']} users={planData?.machineRequirements[index]?.workers ?? []} length={length} horizontalPositionMap={horizontalPositionMap} />
                        {/* supervisor */}
                        <UserRow role={ROLES.SUPERVISOR} colorCode={planData['color']} users={planData?.machineRequirements[index]?.managers ?? []} length={length} horizontalPositionMap={horizontalPositionMap} />
                    </div>
                }
            </div>
        </div>
    )
}

export default UserSlots;
