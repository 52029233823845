// Import libraries
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

// Import actions
import {
  OverlapKeys,
  OverlapSchedulesData,
} from "../../../redux/project/project.actions";
import { Image, Spinner, SpinnerSize, mergeStyleSets } from "@fluentui/react";
import {
  IconDefinition,
  faChevronDown,
  faChevronLeft,
  faDigging,
  faPenSquare,
  faUserHardHat,
  faUserTie,
} from "@fortawesome/pro-regular-svg-icons";
import { config, useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openInNewTab } from "../../../utils/utils";

// OverlapSchedulesData

const classNames = mergeStyleSets({
  buttonBottomContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButtonOutside: {
    zIndex: 1,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 20,
    left: -40,
    height: 40,
    width: 40,
    background: "#fff",
    cursor: "pointer",
    boxShadow:
      "rgb(0 0 0 / 22%) -5px 0px 5px 0px, rgb(0 0 0 / 18%) -14px 0px 20px 0px",
    borderTopLeftRadius: "10%",
    borderBottomLeftRadius: "10%",
  },
  closeButtonInside: {
    width: "80%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    selectors: {
      ":hover": {
        background: "rgba(0, 0, 0, 0.05)",
      },
    },
  },
  mainContainer: {
    marginLeft: 10,
  },
  searchInput: {
    display: "flex",
  },
  searchResult: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    width: "97%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    marginTop: 20,
    zIndex: 3,
  },
  contentData: {
    display: "flex",
    flexDirection: "column",
  },
  headerIcon: {
    fontSize: 19,
    color: "#006CAD",
  },
  showHideIcon: {
    fontSize: 14,
    color: "#000",
    transition: "all 0.3s ease",
  },
  headerText: {
    fontSize: 12,
    fontFamily: "Verdana",
    fontWeight: 700,
    marginLeft: 10,
    userSelect: "none",
  },
  contentText: {
    fontSize: 14,
    fontFamily: "Verdana",
    fontWeight: 400,
    marginLeft: 26,
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "75%",
    height: 37,
    display: "flex",
    alignItems: "flex-end",
  },
  contentTextNormal: {
    fontSize: 14,
    fontFamily: "Verdana",
    fontWeight: 400,
    marginLeft: 26,
    height: 37,
    display: "flex",
    alignItems: "flex-end",
  },
  badge: {
    background: "#006CAD",
    color: "#ffffff",
    fontSize: 11,
    textAlign: "center",
    lineHeight: 20,
    height: 20,
    width: 20,
    position: "absolute",
    top: -8,
    right: -14,
    borderRadius: "50%",
  },
  showAllButton: {
    fontSize: 12,
    color: "#006CAD",
    cursor: "pointer",
    margin: "20px 0px 0px 27px",
  },
});

type CRMOverlapProps = {
  data: OverlapSchedulesData;
};

// Get the proper icons for the respective classes

const dataInfoInitial: {
  [k: string]: {
    icon: string | IconDefinition;
    text: string;
    showData: boolean;
    showAll: boolean;
  };
} = {
  machines: {
    icon: `${window.location.origin}/img/machineBlue.svg`,
    text: "MASKINE",
    showData: true,
    showAll: false,
  },
  managers: {
    icon: faUserTie,
    text: "FORMAND",
    showData: true,
    showAll: false,
  },
  drivers: {
    icon: faUserHardHat,
    text: "MASKINFØRER",
    showData: true,
    showAll: false,
  },
  workers: {
    icon: faDigging,
    text: "HÅNDMAND",
    showData: true,
    showAll: false,
  },
};

const CRMOverlap = ({ data }: CRMOverlapProps) => {
  const [dataInfo, setDataInfo] = useState(dataInfoInitial);

  const toggleContent = (key: string, propKey: string, boolean: boolean) => {
    setDataInfo({
      ...dataInfo,
      [key]: {
        ...dataInfo[key as keyof typeof dataInfo],
        [propKey]: !boolean,
      },
    });
  };

  const machinesSlideStyle = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: dataInfo.machines.showData ? 1 : 0,
      height: dataInfo.machines.showData
        ? (dataInfo.machines.showAll
            ? data?.machines?.data?.length
            : data?.machines?.data?.slice(0, 5)?.length) *
            37 +
            (data?.machines?.data?.length > 5 ? 20 : 0) || 0
        : 0,
      pointerEvents: dataInfo.machines.showData ? "unset" : "none",
    },
  });

  const driversSlideStyle = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: dataInfo.drivers.showData ? 1 : 0,
      height: dataInfo.drivers.showData
        ? (dataInfo.drivers.showAll
            ? data?.drivers?.data?.length
            : data?.drivers?.data?.slice(0, 5)?.length) *
            37 +
            (data?.drivers?.data?.length > 5 ? 20 : 0) || 0
        : 0,
      pointerEvents: dataInfo.drivers.showData ? "unset" : "none",
    },
  });

  const managersSlideStyle = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: dataInfo.managers.showData ? 1 : 0,
      height: dataInfo.managers.showData
        ? (dataInfo.managers.showAll
            ? data?.managers?.data?.length
            : data?.managers?.data?.slice(0, 5)?.length) *
            37 +
            (data?.managers?.data?.length > 5 ? 20 : 0) || 0
        : 0,
      pointerEvents: dataInfo.managers.showData ? "unset" : "none",
    },
  });

  const workersSlideStyle = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: dataInfo.workers.showData ? 1 : 0,
      height: dataInfo.workers.showData
        ? (dataInfo.workers.showAll
            ? data?.workers?.data?.length
            : data?.workers?.data?.slice(0, 5)?.length) *
            37 +
            (data?.workers?.data?.length > 5 ? 20 : 0) || 0
        : 0,
      pointerEvents: dataInfo.workers.showData ? "unset" : "none",
    },
  });

  const animation = {
    machines: machinesSlideStyle,
    drivers: driversSlideStyle,
    managers: managersSlideStyle,
    workers: workersSlideStyle,
  };

  // Get the types for that new endpoints
  // Make a new action for that
  // place it here
  // look at the project ui to find the list
  // Implement it

  if (!data) {
    return (
      <Spinner
        style={{ marginTop: "30%" }}
        size={SpinnerSize.large}
        label="Loading..."
        labelPosition="right"
      />
    );
  }

  const keys = Object.keys(data) as OverlapKeys[];

  return (
    <>
      {keys.map((key, index) => (
        <div key={`plan-${index}`} className={classNames.searchResult}>
          <div
            className={classNames.header}
            onClick={() =>
              toggleContent(key, "showData", dataInfo[key].showData)
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {typeof dataInfo[key].icon === "string" ? (
                <Image
                  style={{ userSelect: "none" }}
                  src={dataInfo[key].icon as string}
                  alt="machine"
                  width={20}
                  height={32}
                />
              ) : (
                <FontAwesomeIcon
                  // @ts-ignore
                  icon={dataInfo[key].icon}
                  className={classNames.headerIcon}
                />
              )}
              <span className={classNames.headerText}>
                {dataInfo[key].text}
              </span>
            </div>
            <FontAwesomeIcon
              // @ts-ignore
              icon={dataInfo[key].showData ? faChevronDown : faChevronLeft}
              className={classNames.showHideIcon}
            />
          </div>
          <animated.div
            style={{
              ...animation[key],
              zIndex: dataInfo[key].showData ? 2 : 1,
            }}
          >
            <div id={`contentData-${key}`} className={classNames.contentData}>
              {(dataInfo[key].showAll
                ? data[key].data
                : data[key].data.slice(0, 5)
              ).map(({ machineReqIdx, resourceName, projectId }, index) => (
                <div
                  key={projectId + "-" + machineReqIdx + "-" + index}
                  style={{ display: "flex" }}
                >
                  <span
                    className={classNames.contentText}
                    onClick={() =>
                      openInNewTab(`/planning/${projectId}/${machineReqIdx}`)
                    }
                  >
                    {resourceName}
                  </span>
                </div>
              ))}

              {/* @ts-ignore */}
              {data[key]?.data.length > 5 && (
                <div
                  className={classNames.showAllButton}
                  onClick={() =>
                    toggleContent(key, "showAll", dataInfo[key].showAll)
                  }
                >
                  {dataInfo[key].showAll ? "Show less" : "Show all"}
                </div>
              )}
            </div>
          </animated.div>
        </div>
      ))}
    </>
  );
  // return <div>CRMOverlap</div>;
};

export default CRMOverlap;
