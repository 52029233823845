import React, { Children, cloneElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getPlans } from "../../../redux/plan/plan.actions";
import {
  workingRoleOptions,
  reasonVacationStaffOptions,
} from "../../../../src/utils/constants";
import { getPrebookings } from "../../../redux/prebooking/prebooking.actions";
import { getMachines } from "../../../redux/machines/machines.actions";
import { getCrewServiceSchedule } from "../../../utils/service";

const CalendarSchedule = ({ children, target, targetInfo, preBookingId }) => {
  const dispatch = useDispatch();
  const bookedSchedules = [];
  const { selectedDayOnCalendar } = useSelector((state) => state.machine);
  const { data: allPlansData } = useQuery("plans", () =>
    dispatch(getPlans(new Date(selectedDayOnCalendar).getFullYear()))
  );
  const { data: prebookingsData } = useQuery(
    "prebookings",
    dispatch(getPrebookings)
  );
  const { data: machinesData } = useQuery("machines", dispatch(getMachines));

  if (target === "staff" && allPlansData && targetInfo) {
    const staffId = targetInfo.userId;
    const staffRoles = targetInfo.workingRole
      .split(";")
      .map((role) => parseInt(role));
    staffRoles.forEach((role) => {
      const roleKey = workingRoleOptions.find(
        (item) => item.key === role
      )?.name;
      if (!roleKey) return;
      targetInfo.roleKey = roleKey.slice(0, -1);
      targetInfo.fullName = targetInfo.firstName + " " + targetInfo.lastName;
      allPlansData.forEach((plans) => {
        plans.machineRequirements.forEach((req) => {
          [0, 1, 2].includes(role) &&
            req[roleKey].forEach((staffSchedule) => {
              if (staffSchedule.id === staffId && !plans.inactive) {
                bookedSchedules.push({
                  start: staffSchedule.start,
                  end: staffSchedule.end,
                  color: plans.color,
                  name: plans.projectName,
                  backgroundCaro: false,
                });
              }
            });
        });
      });
      targetInfo?.starfVacations?.forEach((vacation) => {
        bookedSchedules.push({
          start: vacation.start,
          end: vacation.end,
          color: vacation.color,
          name: reasonVacationStaffOptions.find(
            (item) => item.key === vacation.reason
          ).text,
          backgroundCaro: true,
        });
      });
    });

    const serviceSchedules = getCrewServiceSchedule(
      staffId,
      machinesData || []
    );

    serviceSchedules.forEach(({ start, end }) => {
      bookedSchedules.push({
        start: start,
        end: end,
        // color: service.color,
        // name: service.comment,
        // backgroundCaro: true,
      });
    });
  } else if (
    target === "machine" &&
    allPlansData &&
    prebookingsData &&
    targetInfo
  ) {
    const machineId = targetInfo.machineId;
    targetInfo.roleKey = "machine";
    targetInfo.fullName = targetInfo.machineName;
    allPlansData.forEach((plans) => {
      plans.machineRequirements.forEach((req) => {
        req["machines"].forEach((machineSchedule) => {
          if (machineSchedule.machineId === machineId && !plans.inactive) {
            bookedSchedules.push({
              start: machineSchedule.start,
              end: machineSchedule.end,
              color: plans.color,
              name: plans.projectName,
              backgroundCaro: false,
            });
          }
        });
      });
    });
    prebookingsData
      .filter((prebooking) => prebooking.id !== preBookingId)
      .forEach((prebooking) => {
        if (prebooking.machineId === machineId && !prebooking.inactive) {
          bookedSchedules.push({
            start: prebooking.start,
            end: prebooking.end,
            color: "227,181,5",
            name: prebooking.projectName,
            backgroundCaro: false,
          });
        }
      });
    targetInfo?.machineService?.forEach((service) => {
      bookedSchedules.push({
        start: service.start,
        end: service.end,
        color: service.color,
        name: service.comment,
        backgroundCaro: true,
      });
    });
  }

  return (
    <>
      {Children.map(children, (child) =>
        cloneElement(child, { bookedSchedules, targetInfo })
      )}
    </>
  );
};

export default CalendarSchedule;
