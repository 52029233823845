import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient, useMutation } from "react-query";
import { deleteTeam } from "../../../redux/machines/machines.actions";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";
import { DeleteConfirmation } from "../../common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPowerOff } from '@fortawesome/pro-regular-svg-icons';

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        fontSize: 22,
        color: 'rgb(0, 108, 173)',
        cursor: 'pointer',
        marginRight: 12,
    },
});

const MachineControls = ({ index, team, history }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
	const [teachingBubbleEditMachine, { toggle: toggleTeachingDeleteMachine }] = useBoolean(false);

    const {mutate: mutationDeleteMachine} = useMutation((teamId) => dispatch(deleteTeam(teamId)), {
		onSuccess: () => {
			queryClient.invalidateQueries("teams");
		},
	});

	const onClickConfirm = () => {
        toggleTeachingDeleteMachine();
        const temp = team.id;
        mutationDeleteMachine(temp);
    }

    return (
        <Fragment>
            <div className={classNames.container}>
                {!team.inactive && <FontAwesomeIcon icon={faEdit} className={classNames.icon} onClick={() => history.push(`/team/${team.id}`)} />}
                <FontAwesomeIcon icon={faPowerOff} className={classNames.icon} id={`editMachineId-${index}`} onClick={toggleTeachingDeleteMachine}/>
            </div>
            {teachingBubbleEditMachine && (
                <DeleteConfirmation label={`Are you sure you want to delete this team?`} target={`#editMachineId-${index}`} toggleTeaching={toggleTeachingDeleteMachine} onClickConfirm={onClickConfirm}/>
            )}
        </Fragment>
    );
};

export default MachineControls;
