import React, { useState, useCallback, Fragment, useEffect } from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ColorPicker, getColorFromString } from '@fluentui/react/lib/index';
import { IconButton, DefaultButton, PrimaryButton } from '@fluentui/react/';
import { ColorSelectorItem } from './';
import { defaultButtonStyles, primaryButtonStyles } from '../../utils/theme';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getFavoriteColor, addFavoriteColors } from '../../redux/colorPicker/colorPicker.action';
import { connect } from 'react-redux';
import { colorList, machineColorList } from '../../utils/constants';

const classNames = mergeStyleSets({
    wrapper: { display: 'flex', flexDirection: 'column' },
    colorSelector: { 
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto auto',
        gridGap: 20,
        float: 'left',
        padding: 12,
        maxWidth: 300,
        minWidth: 300,
    },
    icon: {
        margin: 4
    },
    pickerControlContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 8,
    },
    cancel: {
        marginRight: 10,
    }
});

const colorPickerStyles = {
    panel: { padding: 12 },
    root: {
        maxWidth: 320,
        minWidth: 320,
    },
    colorRectangle: { height: 268 },
};

// const defaultColors = ['0,108,173', '110,84,47', '245,124,0']

const white = getColorFromString('#ffffff');

const ColorPickerComponent = ({selectColor, color, getFavoriteColor, addFavoriteColors, togglePickerVisible, isMachine}) => {
    const [colorCodes, setColorCodes] = useState(isMachine ? machineColorList : colorList);
    const [pickerColor, setPickerColor] = useState(white);
    const [selectedColor, setSelectedColor] = useState(color);
    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const updateColor = useCallback((event, colorObj) => setPickerColor(colorObj), []);
    const queryClient = useQueryClient();

    // const { data: favoriteColors } = useQuery('favoriteColors', getFavoriteColor);

    // const { mutate: mutateAddFavoriteColor } = useMutation((colorRgb) => addFavoriteColors([colorRgb]), {
    //     onSuccess: (data, variable) => {
    //         selectColor(variable);
    //         queryClient.invalidateQueries('favoriteColors')
    //     }
    // })

    // useEffect(() => {
    //     if(favoriteColors) {
    //         setColorCodes([...defaultColors, ...favoriteColors])
    //     }
    // }, [favoriteColors])

    // const addNewColorOption = () => {
    //     const colorRgb = `${pickerColor.r},${pickerColor.g},${pickerColor.b}`;
    //     togglePickerVisible();
    //     mutateAddFavoriteColor(colorRgb)
    // }

    const chooseColor = (color) => {
        setSelectedColor(color);
        selectColor(color);
    }

    const resetColor = () => {
        setSelectedColor(null);
        selectColor(null);
    }

    return (
        <div className={classNames.wrapper}>
            <div className={classNames.colorSelector}>
                {colorCodes.length && colorCodes.map((color) => (
                    <ColorSelectorItem key={color} onClick={() => chooseColor(color)} colorCode={color} selected={color === selectedColor ? true : false} />
                ))}
                {isMachine && <ColorSelectorItem onClick={resetColor} colorCode='241,241,241' selected={!color} />}
                {/* <IconButton className={classNames.icon} iconProps={{ iconName: 'Add' }} title="Emoji" ariaLabel="Emoji" onClick={() => { setColorPickerOpen(prevState => !prevState)}} /> */}
            </div>
            {/* {colorPickerOpen &&
                <Fragment>
                    <ColorPicker
                        color={pickerColor}
                        onChange={updateColor}
                        alphaType={'none'}
                        showPreview={false}
                        styles={colorPickerStyles}
                        // The ColorPicker provides default English strings for visible text.
                        // If your app is localized, you MUST provide the `strings` prop with localized strings.
                        strings={{
                            // By default, the sliders will use the text field labels as their aria labels.
                            // If you'd like to provide more detailed instructions, you can use these props.
                            alphaAriaLabel: 'Alpha slider: Use left and right arrow keys to change value, hold shift for a larger jump',
                            transparencyAriaLabel: 'Transparency slider: Use left and right arrow keys to change value, hold shift for a larger jump',
                            hueAriaLabel: 'Hue slider: Use left and right arrow keys to change value, hold shift for a larger jump',
                        }}
                    />
                    <div className={classNames.pickerControlContainer}>
                        <DefaultButton styles={defaultButtonStyles} className={classNames.cancel} text="Annullere" onClick={() => setColorPickerOpen(false)} />
                        <PrimaryButton styles={primaryButtonStyles} text="Gem" onClick={addNewColorOption} />
                    </div>
                </Fragment>
            } */}
        </div>
    )
}

export default connect(null, { getFavoriteColor, addFavoriteColors })(ColorPickerComponent);
