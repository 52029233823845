import React, { useEffect, useState } from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { TextField } from '@fluentui/react/lib/TextField';
import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';
import PersonaItem from './PersonaItem';
import { getCommentByProjectId, postNewComment, deleteComment } from '../../../../../redux/comment/comment.action';
import { connect, useSelector } from 'react-redux';
import CommentField from './CommentField';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAltMedical } from '@fortawesome/pro-regular-svg-icons';
import { Sticky, StickyPositionType } from '@fluentui/react/lib/Sticky';
import { techDepartmentOptions } from '../../../../../utils/constants';

const classNames = mergeStyleSets({
    iconOutSide: {
        flexBasis: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconInside: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20%',
        cursor: 'pointer',
        height: 30,
        width: 30,
    },
    icon: {
        color: '#fff',
        fontSize: 20,
    },
    commentContentSection: {
        borderRadius: 10,
        position: 'relative',
        backgroundColor: '#fff',
    },
    pane: {
        padding: 16,
    },
    contentContainer: {
        borderRadius: 10,
    },
    tag: {
        fontSize: 10,
        borderRadius: 5,
        padding: '2px 5px',
        cursor: 'pointer',
        selectors: {
            ':hover': {
                backgroundColor: "#99b3ff"
            },
        }
    }
});

export const customInputStyles = {
    fieldGroup: {
        height: 40,
        border: 'none',
        borderRadius: 'none',
    },
};

const scrollablePaneStyles = { root: classNames.pane, contentContainer: classNames.contentContainer };

const CommentContainer = ({
    projectId,
    planId,
    projectColor,
    machineReq,
    userName,
    userImageUrl,
    getCommentByProjectId,
    postNewComment,
    deleteComment,
    isPlan,
}) => {
    const { machineTypeOptions, sizeGroupOptions } = useSelector((state) => state.defaultData);
    // const [newComment, setNewComment] = useState([]);
    const [comment, setComment] = useState('');
    const [data, setData] = useState(null);
    const [id, setId] = useState(projectId);
    const queryClient = useQueryClient();
    let subText = '';
    if (isPlan) {
        const machineType = machineTypeOptions
            .find((item) => item.id === machineReq.techDepartment)
            ?.options.find((item) => item.key === machineReq.machineType);
        const machineSizeGroup = sizeGroupOptions.find((item) => item.key === machineReq.sizeGroup);
        subText = `${machineType?.text || 'Unknown'} - ${machineSizeGroup?.text || 'Unknown'}`;
    }

    const { data: projectComment } = useQuery(['projectComments', projectId], () => getCommentByProjectId(projectId, 0 /* pageNum */));
    const { data: planComment } = useQuery(['planComments', planId], () => (isPlan ? getCommentByProjectId(planId, 0 /* pageNum */) : []));
    const { mutate: deleteMutation } = useMutation((commentId) => deleteComment(commentId), {
        onSuccess: () => {
            queryClient.invalidateQueries(id === projectId ? ['projectComments', projectId] : ['planComments', planId]);
        },
    });
    const { mutate: postMutation } = useMutation((commentData) => postNewComment(commentData), {
        onSuccess: () => {
            queryClient.invalidateQueries(id === projectId ? ['projectComments', projectId] : ['planComments', planId]);
            setComment('');
        },
    });

    useEffect(() => {
        if (projectComment && planComment) {
            const combinedComments = [...projectComment, ...(planComment.map(item => ({...item, subText})))].sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
            setData(combinedComments);
        }
    }, [projectComment, planComment]);

    const pushNewComment = () => {
        if (!comment) return;
        const newComment = {
            projectId: id,
            comment,
        };
        postMutation(newComment);
    };

    const onDeleteComment = (commentId) => {
        deleteMutation(commentId);
    };

    return (
        <div style={{ backgroundColor: '#fff', borderRadius: 10, height: 450, width: '100%', position: 'relative' }}>
            <ScrollablePane styles={scrollablePaneStyles}>
                <Sticky stickyPosition={StickyPositionType.Both}>
                    <div style={{ padding: 16, borderRadius: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10, marginTop: isPlan ? 0 : 50 }}>
                            <div style={{ flexBasis: '90%' }}>
                                {isPlan && <div style={{margin: '-10px 0 10px 0', display: 'flex', zIndex: 10}}>
                                    <div className={classNames.tag} style={{marginRight: 10, background: id === projectId ? '#99b3ff' : 'lightGrey'}} onClick={() => setId(projectId)}>Project</div>
                                    <div className={classNames.tag} style={{background: id !== projectId ? '#99b3ff' : 'lightGrey'}} onClick={() => setId(planId)}>{subText}</div>
                                </div>}
                                <TextField
                                    value={comment}
                                    onChange={(e) => {
                                        setComment(e.target.value);
                                    }}
                                    styles={customInputStyles}
                                    multiline
                                    resizable={false}
                                    placeholder='Write your comment...'
                                />
                            </div>
                            <div className={classNames.iconOutSide} onClick={pushNewComment}>
                                <div className={classNames.iconInside} style={{ backgroundColor: `rgb(${projectColor})` }}>
                                    <FontAwesomeIcon icon={faCommentAltMedical} className={classNames.icon} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Sticky>
                <div className={classNames.commentContentSection}>
                    {data &&
                        data.map(({ id, userName, comment, userImageUrl, createdDate, subText }, index) => (
                            <div key={id} style={{ padding: 16, backgroundColor: index % 2 === 0 ? '#F1F1F1' : '#fff' }}>
                                <CommentField
                                    id={id}
                                    userName={userName}
                                    comment={comment}
                                    deleteComment={onDeleteComment}
                                    userImageUrl={userImageUrl}
                                    createdDate={createdDate}
                                    subText={subText}
                                />
                            </div>
                        ))}
                </div>
            </ScrollablePane>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userName: `${state?.user?.user?.firstName} ${state?.user?.user?.lastName}`,
    userImageUrl: state?.user?.user?.imageUrl,
});

export default connect(mapStateToProps, { getCommentByProjectId, postNewComment, deleteComment })(CommentContainer);
