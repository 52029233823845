import axios, { AxiosResponse } from "axios";
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from "../message/message.actions";
import { MessageBarType } from "@fluentui/react/";
import setDefaults from "../../utils/setDefaults";
import { SOURCE_API } from "../../utils/constants";
import { TProject } from "../../types";
import { AppDispatch } from "../store";

export const getProjects =
  (from: string, to: string, showInactive: boolean) =>
  async (dispatch: AppDispatch) => {
    try {
      let uri = `${SOURCE_API}/api/Project/getall`;
      if (from && to) {
        uri += `?from=${from}&to=${to}`;
      }

      if (showInactive) {
        if (from && to) {
          uri += `&includeInactive=${showInactive}`;
        } else {
          uri += `?includeInactive=${showInactive}`;
        }
      }
      setDefaults(localStorage.getItem("token"));

      const response = await axios.get(uri);
      return (response.data.data as TProject[]) || null;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return null;
    }
  };

type OverlapData = {
  resourceName: string;
  machineReqIdx: number;
  projectId: string;
};

type OverlapScheduleData = {
  data: OverlapData[];
  failed: boolean;
  succeeded: boolean;
  message: null | string;
};

export type OverlapKeys = "machines" | "drivers" | "workers" | "managers";

export type OverlapSchedulesData = Record<OverlapKeys, OverlapScheduleData>;

export const getOverlapProject =
  (section: string) => async (dispatch: AppDispatch) => {
    try {
      let uri = `${SOURCE_API}/api/planning/GetOverlappedSchedules?techDepartment=${section}`;

      setDefaults(localStorage.getItem("token"));

      const response = await axios.get<
        any,
        AxiosResponse<OverlapSchedulesData>
      >(uri);

      return response.data;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };

export const getProject = (id: string) => async (dispatch: AppDispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/Project/get?projectIds=${id}`
    );
    return response.data.data[0] ? (response.data.data[0] as TProject) : null;
  } catch (error) {
    dispatch(checkTokenValidity(error));
    return null;
  }
};

export const createProject =
  (projectData: TProject) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.post(
        `${SOURCE_API}/api/Project`,
        projectData
      );
      if (response.data.succeeded === true) {
        dispatch(
          saveMessage("Project sucessfully created!", MessageBarType.success)
        );
      } else {
        dispatch(saveMessage(response.data?.message || "There was an error"));
      }
      return response.data.data;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };

export const updateProject =
  (projectData: TProject) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.put(
        `${SOURCE_API}/api/Project`,
        projectData
      );
      if (response.data.succeeded === true) {
        dispatch(
          saveMessage("Project sucessfully updated!", MessageBarType.success)
        );
      } else {
        dispatch(saveMessage("There was an error"));
      }

      return (response.data.data as TProject) || null;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return null;
    }
  };

export const deleteProject =
  (projectId: string) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.delete(
        `${SOURCE_API}/api/Project?projectId=${projectId}`
      );

      if (response.data.succeeded === true) {
        dispatch(saveMessage("Project deleted", MessageBarType.success));
      } else {
        dispatch(saveMessage("There was an error"));
      }
      return true;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };
