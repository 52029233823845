import axios from "axios";
import { SOURCE_API } from "../../utils/constants";
import setDefaults from "../../utils/setDefaults";
import { checkTokenValidity } from "../user/user.actions";
import { AppActionTypes } from "./app.types";

export const changeSelectedPivot = (pivotValue, pivotKey) => (dispatch) => {
  dispatch({
    type: AppActionTypes.CHANGE_SELECTED_PIVOT,
    value: pivotValue,
    key: pivotKey,
  });
};

export const setSideBarStatus = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_SIDE_BAR_STATUS, value: value });
};

export const changeShowOtherSections = (value, key) => (dispatch) => {
  dispatch({
    type: AppActionTypes.CHANGE_SHOW_OTHER_SECTIONS,
    value: value,
    key: key,
  });
};

export const changeSearchOverlay = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.CHANGE_SEARCH_OVERLAY, value: value });
};

export const changeSearchHistory = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.CHANGE_SEARCH_HISTORY, value: value });
};

export const clearSearchHistory = () => (dispatch) => {
  dispatch({ type: AppActionTypes.CLEAR_SEARCH_HISTORY });
};

export const changeSearchType = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.CHANGE_SEARCH_ENUM, value: value });
};

export const getSearchResult = (inputValue, searchType) => async (dispatch) => {
  try {
    const searchData = {
      keyword: inputValue,
      pageIndex: 0,
      pageSize: 100,
      start: "2021-01-05T02:45:13.545Z",
      end: "2022-01-05T02:45:13.545Z",
      searchType: searchType,
    };
    setDefaults(localStorage.getItem("token"));
    const response = await axios.post(`${SOURCE_API}/api/Search`, searchData);
    return response.data.data ? response.data.data : null;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const changeCalendarLength = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.CHANGE_CALENDAR_LENGTH, value: value });
};

export const setBarDrawer = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_BAR_DRAWER, payload: value });
};

export const setBarDrawingMachineService = (value) => (dispatch) => {
  dispatch({
    type: AppActionTypes.SET_BAR_DRAWING_MACHINE_SERVICE,
    payload: value,
  });
};

export const setHighlightMachine = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_HIGHLIGHT_MACHINE, value: value });
};

export const setHighlightStaff = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_HIGHLIGHT_STAFF, value: value });
};

export const setMachineServiceBubble = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_MACHINE_SERVICE_BUBBLE, value: value });
};

export const setStaffVacationBubble = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_STAFF_VACATION_BUBBLE, value: value });
};

export const changeCRMOverlay = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.CHANGE_CRM_OVERLAY, value: value });
};

export const selectCRMTab = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SELECT_CRM_TAB, value: value });
};

export const setDrawingProjectId = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.DRAWING_PROJECT_ID, value: value });
};

export const setDrawingMachineReqIndex = (value) => (dispatch) => {
  dispatch({
    type: AppActionTypes.SET_DRAWING_MACHINE_REQ_INDEX,
    value: value,
  });
};

export const setCRMcounter = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_CRM_COUNTER, value: value });
};

export const setDarkOverlay = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_DARK_OVERLAY, value: value });
};

export const setPlanPlaceholderData = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_PLAN_PLACE_HOLDER_DATA, value: value });
};

export const setCurrentUnplannedItem = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_CURRENT_UNPLANNED_ITEM, value: value });
};

export const setDrawingProjectName = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.DRAWING_PROJECT_NAME, value: value });
};

export const setSyncProcess = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_SYNC_PROCESS, value: value });
};

// action value is datestring
export const setSyncLastCall = (value) => (dispatch) => {
  dispatch({ type: AppActionTypes.SET_SYNC_LAST_CALL, value: value });
};

export const toggleInactiveMissingUsers = () => (dispatch) => {
  dispatch({ type: AppActionTypes.TOGGLE_INACTIVE_MISSING_USERS });
};

export const setWeeksShown = (value) => (dispatch) => {
  dispatch({
    type: AppActionTypes.SET_WEEKS_SHOWN,
    value,
  });
};

export const getHolidayList =
  (year, countryCode = "DK", isPublic = true, language = "da") =>
  async (dispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.get(
        `${SOURCE_API}/api/Search/Holidays?country=${countryCode}&year=${year}&isPublic=${isPublic}&language=${language}`
      );
      return response.data.data ? response.data.data : null;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };

export const toggleInactiveProject = () => (dispatch) => {
  dispatch({ type: AppActionTypes.TOGGLE_INACTIVE_PROJECT });
};

export const setTableSort =
  ({ key, value }) =>
  (dispatch) => {
    dispatch({ type: AppActionTypes.SET_TABLE_SORT, value: { key, value } });
  };
