// Import libraries
import { mergeStyleSets } from "@fluentui/react";

const classNames = mergeStyleSets({
  sidebarSeparatorContainer: {
    // marginTop: 15,
    // marginBottom: 15,
    height: 32,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    left: 50,
  },
  separatorStyle: {
    backgroundColor: "#DFDFDF",
    minHeight: 2,
    maxHeight: 2,
  },
});

const SectionSeparator = () => {
  return (
    <div className={classNames.sidebarSeparatorContainer}>
      <div className={classNames.separatorStyle} />
    </div>
  );
};

export default SectionSeparator;
