import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SkillsActionTypes } from "./skill.types";

export interface FilterState {
  filter: {
    selectedTypes?: string[];
    selectedSections?: string[];
    skillNames?: string;
  };
}

const initialState: FilterState = {
  filter: {},
};

export const skillsReducer = createSlice({
  name: SkillsActionTypes.CHANGE_FILTER,
  initialState,
  reducers: {
    setFilter: (state: any, action: PayloadAction<{ [key: string]: any }>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        if (["selectedTypes", "selectedSections", "skillNames"].includes(key)) {
          state.filter[key] = value;
        }
      }
    },
  },
});

export default skillsReducer.reducer;
