// Import constants
import { techDepartmentOptions } from "../../../utils/constants";

// Import components
import Badge from "../../common/Badge";

type MachineTableTechDepartmentInfoCellProps = {
  techDepartments: string;
};

const MachineTableTechDepartmentInfoCell = ({
  techDepartments = "",
}: MachineTableTechDepartmentInfoCellProps) => {
  const techDepartmentList = techDepartments
    ? techDepartments
        .split(";")
        .map(
          (item) =>
            techDepartmentOptions.find(
              (option) => option.key === parseInt(item)
            )?.text
        )
        .filter((item) => !!item?.trim())
        .join(", ")
    : "UNKNOWN";

  return <Badge text={techDepartmentList || "UNKNOWN"} />;
};

export default MachineTableTechDepartmentInfoCell;
