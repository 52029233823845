import React from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { techDepartmentOptions } from "../../../utils/constants";

const classNames = mergeStyleSets({
  container: {
    height: "100%",
    maxWidth: 144,
    display: "flex",
    alignItems: "center",
  },
  item: {
    display: "inline-block",
    color: "#868685",
    background: "#DFDFDF",
    borderRadius: 4,
    height: 14,
    fontSize: 11,
    lineHeight: 14,
    padding: "0 8px",
    margin: 2,
  },
});

const ProjectTableTechDepartmentInfoCell = ({ techDepartments = [] }) => {
  return (
    <div className={classNames.container}>
      {Array.isArray(techDepartments) &&
        techDepartments.map((techDepartment) => (
          <div key={techDepartment} className={classNames.item}>
            {techDepartmentOptions.find((item) => item.key === techDepartment)
              ?.text ?? ""}
          </div>
        ))}
    </div>
  );
};

export default ProjectTableTechDepartmentInfoCell;
