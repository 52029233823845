import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import moment from "moment";
import "moment/locale/da";
import React, { Fragment, useMemo } from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { getPlans } from "../../redux/plan/plan.actions";
import { getStaffs } from "../../redux/user/user.actions";
import {
  CALENDAR_PAGE,
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  months,
  stationOptions,
} from "../../utils/constants";
import { useQueryParams } from "../../utils/utils";
import { DayLabel, MonthLabel, WeekLabel } from "../common/calendar";
import { StaffContainer } from "./printPage/staff";
import { StaffVacationItem } from "./staff/index";

const classNames = mergeStyleSets({
  spinner: {
    marginTop: 24,
  },
  root: {
    margin: "0px 0 0 48px",
    minHeight: "100%",
    width: 3650,
    display: "flex",
    flexDirection: "row",
  },
  titleText: {
    fontSize: 18,
    fontWeight: 700,
  },
  staffText: {
    marginTop: 35,
    fontSize: 12,
    fontWeight: 700,
  },
  leftContainer: {
    width: 250,
    position: "sticky",
    zIndex: 1002,
    top: 0,
    left: 0,
    marginTop: 57,
  },
  rightContainer: {
    marginTop: "40px",
  },
  machineAndCalendarContainer: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  machineContainer: {
    minHeight: "100%",
    marginTop: 14,
  },
  calendarContainer: {
    minHeight: "100%",
    width: 300,
  },
  days: {
    display: "flex",
    justifyContent: "flex-start",
  },
  dayOfWeeks: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 8,
  },
  weeks: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 4,
  },
  week: {
    minWidth: 175,
    height: 20,
    fontSize: 11,
    lineHeight: 20,
    borderRadius: 10,
  },
});

const PrintStaffVacations = ({ getPlans, getStaffs, dates }) => {
  const queryParams = useQueryParams();
  const { data: plansData, isLoading } = useQuery("printStaffVacations", () =>
    getPlans(new Date(queryParams.get("from")).getFullYear() - 1)
  );
  const { data: staffsData } = useQuery("staffs", getStaffs);
  const techDepartment = parseInt(queryParams.get("techDepartment"));
  const printStart = new Date(queryParams.get("from"));
  const printEnd = new Date(queryParams.get("to"));

  const getMonthIndicator = (dates) => {
    let components = [];
    let temp = dates[0][0]["month"];
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        if (dates[i][j]["month"] === temp) {
          counter++;
        } else {
          components.push({ length: counter, label: months[temp] });
          counter = 1;
          temp = dates[i][j]["month"];
        }
      }
    }
    components.push({ length: counter, label: months[temp] });
    return components;
  };

  const filteredStaffs = useMemo(() => {
    const result = [];
    if (staffsData && staffsData.length > 0) {
      staffsData.forEach((staff) => {
        if (
          (staff.workingRole.replace("-1", "").includes("1") ||
            staff.workingRole.includes("2")) &&
          staff.starfVacations?.length &&
          staff.techDepartment === techDepartment
        ) {
          result.push({ ...staff });
        }
      });
    }
    return result;
  }, [staffsData]);

  const horizontalPositionMap = useMemo(() => {
    const result = {};
    const datePositionCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        let key = `${dates[i][j]["year"]}-${dates[i][j]["month"] + 1}-${
          dates[i][j]["date"]
        }`;
        result[key] = counter;
        datePositionCorrespondence[counter] = moment(key, "YYYY-M-D").format(
          "YYYY-MM-DD[T]HH:mm:ss.SSS"
        );
        counter++;
      }
    }
    result["end"] = --counter;
    result["positionsToDates"] = datePositionCorrespondence;

    return result;
  }, [dates]);

  const verticalPositionMap = useMemo(() => {
    const result = {};
    const positionIdCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < filteredStaffs.length; i++) {
      result[filteredStaffs[i]["userId"]] = counter;
      positionIdCorrespondence[counter * CALENDAR_STAFFVIEW_STEP_HEIGHT] =
        filteredStaffs[i]["userId"];
      counter++;
    }
    result["positionsToIds"] = positionIdCorrespondence;
    return result;
  }, [filteredStaffs, dates, horizontalPositionMap, plansData, filteredStaffs]);

  const staffItemContainer = useMemo(() => {
    const list = [];
    for (let i = 0; i < filteredStaffs.length; i++) {
      list.push(
        <div>
          <StaffContainer
            count={1}
            calendarPage={CALENDAR_PAGE.DISPOSITION}
            key={`${filteredStaffs[i].userId}`}
            workingRole={filteredStaffs[i].workingRole}
            machineDocumentUrl={filteredStaffs[i].machineDocumentUrl}
            index={`${filteredStaffs[i].userId}`}
            name={`${filteredStaffs[i].firstName} ${
              filteredStaffs[i].lastName ?? ""
            }`}
            machineId={filteredStaffs[i].machineId}
            techDepartment={filteredStaffs[i].techDepartment}
            techArea={filteredStaffs[i].techArea}
            imageUrl={filteredStaffs[i]?.imageUrl}
          />
          <hr
            style={{
              position: "absolute",
              border: "0.6px solid rgb(219, 219, 219, 0.5)",
              width: CALENDAR_STEP_WIDTH * 7 * dates.length,
              margin: 0,
              height: "0.1px",
              backgroundColor: "rgb(219, 219, 219, 0.5)",
              marginLeft: "-420px",
            }}
          />
        </div>
      );
    }

    return list;
  }, [filteredStaffs, verticalPositionMap]);

  const heightOfBackground =
    filteredStaffs.length * CALENDAR_STAFFVIEW_STEP_HEIGHT +
    CALENDAR_STAFFVIEW_STEP_HEIGHT;

  return (
    <div id="printStaffVacations" style={{ background: "#F1F1F1" }}>
      {isLoading ? null : (
        <div>
          <div className={classNames.root}>
            <div className={classNames.leftContainer}>
              <div className={classNames.titleText}>
                {stationOptions[techDepartment]["text"]} uge{" "}
                {queryParams.get("fromWeek")} - {queryParams.get("toWeek")}
              </div>
              <div className={classNames.staffText}>STAFF VACATION</div>
              <div className={classNames.machineContainer}>
                {filteredStaffs && staffItemContainer}
              </div>
            </div>
            <div className={classNames.rightContainer}>
              <div className={classNames.stickyHeader}>
                <div className={classNames.machineAndCalendarContainer}>
                  <div className={classNames.calendarContainer}>
                    <div className={classNames.weeks}>
                      {dates.length &&
                        getMonthIndicator(dates).map(
                          (monthData, indexMonth) => (
                            <MonthLabel
                              index={indexMonth}
                              key={
                                indexMonth +
                                monthData["length"] +
                                monthData["label"]
                              }
                              length={monthData["length"]}
                              label={monthData["label"]}
                            />
                          )
                        )}
                    </div>
                    <div className={classNames.weeks}>
                      {dates.length > 0 &&
                        dates.map((week, indexWeek) => (
                          <WeekLabel
                            index={indexWeek}
                            month={week[0]["month"]}
                            day={week[0]["date"]}
                            year={week[0]["year"]}
                            key={`${week[0]["month"]}-${week[0]["date"]}-${week[0]["year"]}`}
                          />
                        ))}
                    </div>
                    <div className={classNames.days}>
                      {dates.length > 0 &&
                        dates.map((week) =>
                          week.map((each) => (
                            <DayLabel
                              label={each.date}
                              date={each}
                              key={JSON.stringify(each)}
                            />
                          ))
                        )}
                    </div>
                    <div className={classNames.dayOfWeeks}>
                      {dates.length > 0 &&
                        dates.map((week) =>
                          week.map((each) => (
                            <DayLabel
                              label={each.date}
                              date={each}
                              key={JSON.stringify(each)}
                              isDaysOfWeek
                            />
                          ))
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={classNames.machineAndCalendarContainer}>
                <div className={classNames.calendarContainer}>
                  <div
                    style={{
                      height: heightOfBackground,
                      width: CALENDAR_STEP_WIDTH * 7 * dates.length,
                      position: "relative",
                      backgroundImage: 'url("./img/calendarBackground3.png")',
                      backgroundRepeat: "repeat",
                    }}
                  >
                    <div
                      className="planContainer"
                      style={{
                        position: "relative",
                        top: 0,
                        left: 0,
                        zIndex: 556,
                        width: "100%",
                        height:
                          filteredStaffs.length *
                          CALENDAR_STAFFVIEW_STEP_HEIGHT,
                      }}
                    >
                      {Array.isArray(filteredStaffs) &&
                        filteredStaffs.map((staff, index1) => (
                          <Fragment key={`${staff.userId}-${index1}`}>
                            {staff.starfVacations &&
                              staff?.starfVacations.length > 0 &&
                              staff.starfVacations
                                .filter(
                                  (vacation) =>
                                    new Date(vacation.start).getTime() <=
                                      printEnd.getTime() &&
                                    new Date(vacation.end).getTime() >=
                                      printStart.getTime()
                                )
                                .map((item, index2) => (
                                  <StaffVacationItem
                                    calendarPage={CALENDAR_PAGE.PRINT}
                                    viewOnly
                                    key={`${staff.userId}-${index2}`}
                                    staffVacationIndex={index2}
                                    staffVacation={item}
                                    staff={staff}
                                    userId={staff.userId}
                                    start={item["start"]}
                                    end={item["end"]}
                                    horizontalPositions={horizontalPositionMap}
                                    verticalPositions={verticalPositionMap}
                                    calendarStart={
                                      new Date(queryParams.get("from"))
                                    }
                                    calendarEnd={
                                      new Date(queryParams.get("to"))
                                    }
                                  />
                                ))}
                          </Fragment>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, { getPlans, getStaffs })(PrintStaffVacations);
