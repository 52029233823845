// Import libraries
import { PublicClientApplication } from "@azure/msal-browser";
import { loadTheme } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import React from 'react';
import ReactDOM from 'react-dom';
/* @ts-ignore */
import HttpsRedirect from 'react-https-redirect';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// Import config
import { msalConfig } from "./authConfig";

// Import style
import './index.css';

// Import web vital files
import reportWebVitals from './reportWebVitals';

// Import redux
import { persistor, store } from "./redux/store";

// Import components
import App from './App';
import CacheBuster from "./components/common/CacheBuster";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders. 
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.handleRedirectPromise().then((tokenResponse) => {
  // Handle redirect response
}).catch((error) => {
  // Handle redirect error
});

initializeIcons();

loadTheme({
  defaultFontStyle: { fontFamily: 'Verdana', fontWeight: 'regular' },
});

ReactDOM.render(
  <CacheBuster>
    <React.StrictMode>
      {/* @ts-ignore */}
      <Provider store={store}>
          <PersistGate persistor={persistor}>
            <HttpsRedirect>
                <App instance={msalInstance}/>
            </HttpsRedirect>
          </PersistGate>
      </Provider>
    </React.StrictMode>
  </CacheBuster>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();