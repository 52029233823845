import React from "react";
import { useDispatch } from "react-redux";
import { setFilter } from "../../../../redux/machines/machines.actions";
import { Stack } from "@fluentui/react";
import { toggleSelectorItem } from "../../../../redux/machines/machines.utils";
import { classNames } from "./SelectorStyles";

const ToggleableSelector = ({filterKey, techDepartment, allOptions, availableOptions, selectedOptions}) => {
    const dispatch = useDispatch();
    return (
        <div className={classNames.root}>
            <div className={classNames.techDepartmentSection}>
                <Stack horizontal="true" tokens={{ childrenGap: 8 }} wrap={true}>
                    {allOptions?.filter(section => availableOptions.includes(section.key)).map((option) => (
                        <div
                            key={option["key"]}
                            className={selectedOptions?.includes(option["key"]) ? classNames.buttonSelected : classNames.button}
                            onClick={() =>
                                dispatch(
                                    setFilter({
                                        [techDepartment] : {
                                            [filterKey]: toggleSelectorItem(selectedOptions, option["key"]),
                                        }
                                    })
                                )
                            }
                        >
                            {option["text"]}
                        </div>
                    ))}
                </Stack>
            </div>
        </div>
    );
};

export default ToggleableSelector;
