import { Formik } from "formik";
import React, { Fragment } from "react";
import { useMutation, useQueryClient } from "react-query";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { createTeam, getMachines } from "../../redux/machines/machines.actions";
import { getStaffs } from "../../redux/user/user.actions";
import { MachineTeamForm } from "./machines/";
import { useQuery } from "react-query";
import { techDepartmentOptions } from "../../utils/constants";

const NewTeam = ({ createTeam, getMachines, getStaffs }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { data: machineData, status } = useQuery("machines", () => getMachines(), { staleTime: 36000 });
  const { data: staffData } = useQuery("staffs", () => getStaffs(), { staleTime: 36000 });
  
  const validationSchema = Yup.object({
    name: Yup.string("Please enter a name").required("Name is required"),
    techDepartments: Yup.string().min(1,'Minimum one department has to be selected.').required('Department is required'),
    machineId: Yup.string().min(1,'Machine is required').required("Machine is required")
  });

  const mutationCreateMachine = useMutation(
    (team) => createTeam({ ...team, model: "Model test" }),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("machines");
          queryClient.refetchQueries();
          history.push({
            pathname: "/machines",
            state: 'MACHINE_TABLE_TEAMS'
          });
        }
      },
    }
  );

  const formSubmit = (data) => {
    const team = {
      name: data.name,
      machineId: data.machineId,
      techDepartment: data.techDepartment,
      drivers: data.drivers ? (Array.isArray(data.drivers) ? data.drivers.map((v) => ({ id: v.id, machineId: data.machineId })) : [data.drivers].map((v) => ({ id: v.id, machineId: data.machineId }))) : [],
      workers: data.workers ? (Array.isArray(data.workers) ? data.workers.map((v) => ({ id: v.id, machineId: data.machineId })) : [data.workers].map((v) => ({ id: v.id, machineId: data.machineId }))) : [],
      managers: data.managers ? (Array.isArray(data.managers) ? data.managers.map((v) => ({ id: v.id, machineId: data.machineId })) : [data.managers].map((v) => ({ id: v.id, machineId: data.machineId }))) : [],
    };
    mutationCreateMachine.mutate(team);
  };

  return (
    <Fragment>
      {status === "success" && machineData && (
        <Formik
          initialValues={{
            name: '',
            techDepartments: '',
            machineId: ''
          }}
          validationSchema={validationSchema}
          onSubmit={formSubmit}>
          {props => <MachineTeamForm history={history} {...props} machineData={machineData} staffData={staffData} />}
        </Formik>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  defaultData: state.defaultData
});

export default connect(mapStateToProps, { createTeam, getMachines, getStaffs })(NewTeam);
