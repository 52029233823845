// Import libraries
import React from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { useSelector } from "react-redux";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

// Import components
import { MachineRequirementsDateDisplay } from "../../common";

// Import constants
import { techDepartmentOptions } from "../../../utils/constants";

// Define constants
const TECH_DEPARTMENT = "techDepartment";
const PERIOD = "period";
const MACHINE_TYPE = "machineType";
const SIZE_GROUP = "sizeGroup";
const ACTIVITY = "activity";
const CRM_ANSVARLIG = "crmAnsvarlig";
const NOTE = "note";

const NONE = "None";

const ChooseMachineRequirementTable = ({ machineRequirements, selection }) => {
  const { machineTypes, sizeGroupOptions, activitiesOptions } = useSelector(
    (state) => state.defaultData
  );

  const classNames = mergeStyleSets({
    textAreaInput: {
      marginRight: 10,
      borderRadius: 5,
      width: "95%",
    },
  });

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName || ""];

    switch (column.key) {
      case TECH_DEPARTMENT:
        return (
          <span>
            {techDepartmentOptions.find(
              (techDepartment) => techDepartment.key === item.techDepartment
            )?.text ?? NONE}
          </span>
        );
      case ACTIVITY:
        return (
          <span>
            {activitiesOptions
              .find((activity) => activity.id === item.techDepartment)
              ?.options.find(
                (activity) => activity.key === parseInt(item.crmActivities)
              )?.text ?? NONE}
          </span>
        );

      case CRM_ANSVARLIG:
        return <span>{item.crmAnsvarlig || NONE}</span>;

      case MACHINE_TYPE:
        return (
          <span>
            {machineTypes.find((type) => type.id === item.machineType)?.name ??
              NONE}
          </span>
        );

      case SIZE_GROUP:
        return (
          <span>
            {sizeGroupOptions.find(
              (sizeGroup) => sizeGroup.key === item.sizeGroup
            )?.text ?? NONE}
          </span>
        );

      case NOTE:
        return (
          <textarea
            className={classNames.textAreaInput}
            style={{ background: "none", border: "none" }}
            value={item.crmDescription}
          />
        );

      case PERIOD:
        return (
          <MachineRequirementsDateDisplay start={item.start} end={item.end} />
        );
      default:
        return <span>{fieldContent}</span>;
    }
  };

  const columns = [
    {
      data: "string",
      fieldName: TECH_DEPARTMENT,
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: TECH_DEPARTMENT,
      maxWidth: 80,
      minWidth: 80,
      name: "Fagsektion",
    },
    {
      data: "string",
      fieldName: ACTIVITY,
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: ACTIVITY,
      maxWidth: 200,
      minWidth: 200,
      name: "Aktivitet",
    },
    {
      data: "string",
      fieldName: CRM_ANSVARLIG,
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: CRM_ANSVARLIG,
      maxWidth: 200,
      minWidth: 200,
      name: "Ansvarlig",
    },
    {
      data: "string",
      fieldName: MACHINE_TYPE,
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: MACHINE_TYPE,
      maxWidth: 220,
      minWidth: 220,
      name: "Hovedanlægstype",
    },
    {
      data: "string",
      fieldName: SIZE_GROUP,
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: SIZE_GROUP,
      maxWidth: 180,
      minWidth: 180,
      name: "Size",
    },
    {
      data: "string",
      fieldName: NOTE,
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: NOTE,
      maxWidth: 180,
      minWidth: 180,
      name: "Note",
    },
    {
      data: "string",
      fieldName: PERIOD,
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: PERIOD,
      maxWidth: 160,
      minWidth: 160,
      name: "Periode",
    },
  ];

  return (
    <div>
      <DetailsList
        items={machineRequirements}
        compact={false}
        columns={columns}
        selectionMode={SelectionMode.single}
        setKey="none"
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onRenderItemColumn={renderItemColumn}
      />
    </div>
  );
};

export default ChooseMachineRequirementTable;
