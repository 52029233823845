import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import {
  changePrebookingBubbleInfo,
  setOverlayPrebookingId,
  updatePrebooking,
} from "../../../../redux/prebooking/prebooking.actions";
import { saveMessage } from "../../../../redux/message/message.actions";
import {
  calendarConfirmationStatus,
  CALENDAR_STEP_WIDTH,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  ACTIVITY_LOG_TYPE_ENUM,
  ACTIVITY_LOG_ENUM,
} from "../../../../utils/constants";
import moment from "moment";
import "moment/locale/da";
import { Rnd } from "react-rnd";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faEdit,
  faEye,
  faArrows,
  faEllipsisH,
} from "@fortawesome/pro-regular-svg-icons";
import { useHistory } from "react-router-dom";
import { TooltipForText } from "../../../common";
import { TeachingBubble } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  findDifferent,
  hasEditRight,
  lighterDarkerColor,
} from "../../../../utils/utils";
import ConfirmBubble from "../../../common/ConfirmBubble";
import { getStringifiedMachine } from "../../../../redux/machines/machine.selector";

const classNames = mergeStyleSets({
  icon: {
    color: "#575756",
    marginRight: 2,
    fontSize: 13,
    fontWeight: "lighter",
    selectors: {
      ":hover": {
        color: "#DBDBDB",
      },
    },
    cursor: "pointer",
  },
  iconTooltip: {
    color: "#006CAD",
    marginRight: 2,
    fontSize: 13,
    fontWeight: "lighter",
    selectors: {
      ":hover": {
        color: "#000080",
      },
    },
    cursor: "pointer",
  },
});

const Prebooking = ({
  prebooking,
  start,
  end,
  horizontalPositions,
  verticalPositions,
  calendarStart,
  calendarEnd,
  setOverlayPrebookingId,
  saveMessage,
  updatePrebooking,
  renderDependencies: propRenderDependencies,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const currentUserId = useSelector((state) => state?.user?.user?.userId);
  const calendarState = useSelector(getStringifiedMachine);

  const displayWeekView = useSelector((state) => state.machine.displayWeekView);
  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [confirmation, setConfirmation] = useState(
    calendarConfirmationStatus.CONFIRMATION_UNSET
  );
  const [width, setWidth] = useState(250);
  const [display, setDisplay] = useState(false);
  const colorCode = "242, 135, 5";
  const queryClient = useQueryClient();
  const [openActions, { toggle: toggleOpenActions }] = useBoolean(false);
  const needConfirmation =
    confirmation !== calendarConfirmationStatus.CONFIRMATION_UNSET;
  const ref = useRef(null);
  // const renderDependencies = calendarState + JSON.stringify(verticalPositions);

  const renderDependencies =
    propRenderDependencies || calendarState + JSON.stringify(verticalPositions);
  if (!ref.current) ref.current = renderDependencies;
  const shouldComponentRerender = ref.current !== renderDependencies;

  const [updatePackage, setUpdatePackage] = useState(null);
  let bgColor = `repeating-linear-gradient(90deg, rgb(${colorCode}), rgb(${colorCode}) 24px, rgb(${lighterDarkerColor(
    colorCode,
    10
  )}) 24px, rgb(${lighterDarkerColor(colorCode, 10)}) 48px)`;

  useEffect(() => {
    if (
      confirmation === calendarConfirmationStatus.CONFIRMATION_UNSET &&
      ((!x && !y) || shouldComponentRerender)
    ) {
      if (shouldComponentRerender) ref.current = renderDependencies;
      let yPosition = verticalPositions[prebooking["machineId"]];
      if (typeof yPosition === "undefined") {
        setDisplay(false);
        return;
      }

      const keyStart = moment(start).utcOffset(0).format("YYYY-M-D");
      const keyEnd = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");

      let xPosition = horizontalPositions[keyStart];
      let endValue = horizontalPositions[keyEnd];

      if (
        new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
        new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime() &&
        typeof xPosition === "undefined" &&
        typeof endValue === "undefined"
      ) {
        xPosition = 0;
        endValue = horizontalPositions["end"];
      }

      if (
        new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
        typeof endValue !== "undefined"
      ) {
        xPosition = 0;
      }

      if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
        endValue = horizontalPositions["end"];
      }

      setY(yPosition * CALENDAR_STEP_HEIGHT);
      if (typeof xPosition !== "undefined") {
        setX(xPosition * calendarStepWidth);
        setWidth((endValue - xPosition) * calendarStepWidth);
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    }
  }, [
    horizontalPositions,
    verticalPositions,
    calendarStart,
    calendarEnd,
    start,
    end,
    calendarStepWidth,
    prebooking,
    shouldComponentRerender,
    x,
    y,
    confirmation,
    renderDependencies,
  ]);

  const mutationUpdatePlan = useMutation(
    (prebooking) => updatePrebooking(prebooking.data),
    {
      onSuccess: (data, variables) => {
        setUpdatePackage(null);
        if (data !== true) {
          rollback(variables.rollbackData);
        }
      },
      onFailure: (data, variables) => {
        setUpdatePackage(null);
        rollback(variables.rollbackData);
      },
      onSettled: () => {
        queryClient.invalidateQueries("prebookings");
        queryClient.invalidateQueries(["prebooking", prebooking.id]);
      },
    }
  );

  const isOverlap = (newX, newWidth, newY) => {
    const newStart = parseInt(parseInt(newX / calendarStepWidth).toFixed(0));
    const newEnd = parseInt(
      parseInt(newStart + newWidth / calendarStepWidth).toFixed(0)
    );
    const tempY = newY ?? y;
    // Prevent the project go out of vertical range
    if (!verticalPositions["positionsToIds"][tempY]) {
      return true;
    }
    const tempList = JSON.parse(
      JSON.stringify(verticalPositions["positionsToIds"][tempY])
    );
    if (y === tempY) {
      const preStart = x / calendarStepWidth;
      const preEnd = preStart + width / calendarStepWidth;
      const index = tempList.listOfPlan.findIndex(
        (item) => item.start === preStart && preEnd
      );
      index !== -1 && tempList.listOfPlan.splice(index, 1);
    }
    const listToCheck = [...tempList.listOfPlan, ...tempList.listOfService];
    for (let i = 0; i < listToCheck.length; i++) {
      const startBeforeCurrentPlanEnd =
        typeof listToCheck[i].end === "undefined"
          ? true
          : newStart <= listToCheck[i].end;
      const endAfterCurrentPlanStart =
        typeof listToCheck[i].start === "undefined"
          ? true
          : newEnd > listToCheck[i].start;

      if (startBeforeCurrentPlanEnd && endAfterCurrentPlanStart) {
        saveMessage("Cannot Overlap");
        setDisplay(false);
        setDisplay(true);
        return true;
      }
    }
    return false;
  };

  const update = async ({
    newX,
    newY,
    newW,
    previousX,
    previousY,
    previousW,
  }) => {
    const data = { ...prebooking };

    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);

    if (newY !== previousY) {
      const tempMachine = verticalPositions["positionsToIds"][newY];
      if (!tempMachine) {
        return false;
      }

      data["machineId"] = tempMachine.id;
      data["machineName"] = tempMachine.name;
    }
    if (newX !== previousX) {
      // const newStartDate = horizontalPositions['positionsToDates'][newX];
      const newStartDate =
        horizontalPositions["positionsToDates"][newX / calendarStepWidth];
      if (!newStartDate) {
        return false;
      }
      data["start"] = newStartDate;
    }
    if (newX !== previousX || newW !== previousW) {
      const endPositionKey = (newW + newX) / calendarStepWidth;
      const newEndDate =
        horizontalPositions["positionsToDates"][endPositionKey];
      if (!newEndDate) {
        return false;
      }
      const newEndDateMinusOneDay = moment(newEndDate)
        .subtract(1, "d")
        .format("YYYY-MM-DD[T]00:00:00.000");
      data["end"] = newEndDateMinusOneDay;
    }
    const different = [];
    Object.keys(prebooking).forEach((key) => {
      let keyDataDifferent = null;
      switch (key) {
        case "projectName":
          keyDataDifferent = findDifferent(prebooking[key], data[key], key);
          break;
        case "personResponsbible":
          keyDataDifferent = findDifferent(
            prebooking[key].name,
            data[key].name,
            "personResponsible"
          );
          break;
        case "machineId":
          keyDataDifferent = findDifferent(
            `machineId:${prebooking[key]}`,
            `machineId:${data[key]}`,
            "machine",
            false
          );
          break;
        case "start":
          const startText = moment(prebooking.start, "YYYY-M-D").format(
            "D.M.YY"
          );
          const endText = moment(prebooking.end, "YYYY-M-D").format("D.M.YY");
          const newStartText = moment(data.start, "YYYY-M-D").format("D.M.YY");
          const newEndText = moment(data.end, "YYYY-M-D").format("D.M.YY");
          keyDataDifferent = findDifferent(
            `${startText} - ${endText}`,
            `${newStartText} - ${newEndText}`,
            "period"
          );
          break;
        default:
          break;
      }
      if (keyDataDifferent) different.push(keyDataDifferent);
    });
    const activityLog = different.length
      ? {
          type: ACTIVITY_LOG_TYPE_ENUM.UPDATE,
          resourceId: data.projectId,
          resourceType: ACTIVITY_LOG_ENUM.BOOKING,
          userId: currentUserId,
          comment: different.join("\n"),
        }
      : null;
    data.activityLog = activityLog;
    mutationUpdatePlan.mutate({
      data,
      rollbackData: { previousX, previousY, previousW },
    });
  };

  const rollback = ({ previousX, previousY, previousW }) => {
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
    setX(previousX);
    setY(previousY);
    setWidth(previousW);
    setUpdatePackage(null);
  };

  const onClickToDetailPage = () => {
    dispatch(
      changePrebookingBubbleInfo({
        openOverlay: true,
        overlayPrebookingId: prebooking.id,
      })
    );
  };

  const openPrebookingModal = () => {
    dispatch(
      changePrebookingBubbleInfo({
        showPrebookingModal: true,
        isEditModal: true,
        overlayPrebookingId: prebooking.id,
        bubbleTarget: `editPrebooking-${prebooking.id}`,
      })
    );
  };

  let content = (
    <div
      className={allowEdit && !needConfirmation ? "dragHandle" : "dragDisabled"}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "calc(100% - 15px)",
        height: "100%",
        cursor: allowEdit
          ? `url(${window.location.origin}/img/arrows-alt.svg), auto`
          : "default",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
          marginLeft: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-end",
            marginTop: 4,
          }}
        >
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              userSelect: "none",
            }}
          >
            <span
              style={{
                color: "#575756",
                fontWeight: "bold",
                fontSize: 11,
                marginRight: 8,
              }}
            >
              {prebooking["projectName"]
                ? prebooking["projectName"].replace(/\s/g, "\xa0")
                : null}
            </span>
            <span style={{ color: "#575756", fontSize: 11 }}>
              {prebooking["projectId"] ? prebooking["projectId"] : null}
            </span>
          </div>
          <div style={{ marginRight: 11, whiteSpace: "nowrap" }}>
            <span style={{ color: "#575756", fontSize: 11 }}>
              {prebooking?.personResponsbible?.name
                ? prebooking?.personResponsbible?.name
                : "Unknown"}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
            color: "#575756",
            marginBottom: 8,
          }}
        >
          {allowEdit && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: 16,
              }}
              onClick={openPrebookingModal}
            >
              <FontAwesomeIcon
                id={`editPrebooking-${prebooking.id}`}
                icon={faEdit}
                style={{ marginRight: 4, fontSize: 13 }}
                className={classNames.icon}
              />
              <span style={{ color: "#575756", fontSize: 11 }}>Edit</span>
            </div>
          )}
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={onClickToDetailPage}
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{ marginRight: 4, fontSize: 13 }}
              className={classNames.icon}
            />
            <span style={{ color: "#575756", fontSize: 11 }}>Details</span>
          </div>
        </div>
      </div>
    </div>
  );
  if (
    width <= (displayWeekView ? calendarStepWidth * 5 : calendarStepWidth * 2)
  ) {
    content = (
      <div
        className={
          allowEdit && !needConfirmation ? "dragHandle" : "dragDisabled"
        }
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          width: "calc(100% - 15px)",
          height: "100%",
          marginLeft: 4,
          cursor: allowEdit
            ? `url(${window.location.origin}/img/arrows-alt.svg), auto`
            : "default",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              color: "#575756",
              fontWeight: "bold",
              fontSize: 11,
              whiteSpace: "nowrap",
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span
              style={{
                color: "#575756",
                fontWeight: "bold",
                fontSize: 11,
                marginRight: 8,
              }}
            >
              {prebooking["projectName"]
                ? prebooking["projectName"].replace(/\s/g, "\xa0")
                : null}
            </span>
          </div>
          <div id={`Prebooking${prebooking.id}`} style={{ maxWidth: 20 }}>
            <FontAwesomeIcon
              id={`editPrebooking-${prebooking.id}`}
              icon={faEllipsisH}
              style={{ fontSize: 18 }}
              className={classNames.icon}
              onClick={(e) => {
                toggleOpenActions();
                e.stopPropagation();
              }}
            />
            {openActions && (
              <TeachingBubble
                target={`#Prebooking${prebooking.id}`}
                hasSmallHeadline={true}
                onDismiss={toggleOpenActions}
                closeButtonAriaLabel="Close"
                styles={{
                  subComponentStyles: {
                    callout: {
                      beak: {
                        display: "none",
                      },
                      root: {
                        marginLeft: -10,
                        maxWidth: 130,
                        height: 30,
                      },
                    },
                  },
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: 100,
                    marginLeft: -7,
                    marginBottom: -8,
                    marginTop: -7,
                  }}
                >
                  {allowEdit && (
                    <FontAwesomeIcon
                      icon={faEdit}
                      className={classNames.iconTooltip}
                      style={{ margin: 0 }}
                      onClick={openPrebookingModal}
                    />
                  )}
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className={classNames.iconTooltip}
                    style={{ margin: 0 }}
                    onClick={onClickToDetailPage}
                  />
                </div>
              </TeachingBubble>
            )}
          </div>
        </div>
      </div>
    );
  } else if (width <= 13 * calendarStepWidth) {
    content = (
      <div
        className={
          allowEdit && !needConfirmation ? "dragHandle" : "dragDisabled"
        }
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "calc(100% - 15px)",
          height: "100%",
          marginLeft: 10,
          cursor: allowEdit
            ? `url(${window.location.origin}/img/arrows-alt.svg), auto`
            : "default",
          zIndex: -1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
            width: "100%",
            marginTop: 4,
          }}
        >
          <div
            style={{
              color: "#575756",
              fontWeight: "bold",
              fontSize: 11,
              whiteSpace: "nowrap",
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {width <= calendarStepWidth * 8
              ? `${prebooking["projectName"].slice(
                  0,
                  Math.round(width / 20)
                )}..`
              : prebooking["projectName"].replace(/\s/g, "\xa0")}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "100%",
              marginBottom: 8,
            }}
          >
            {allowEdit && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: 16,
                }}
                onClick={openPrebookingModal}
              >
                <FontAwesomeIcon
                  id={`editPrebooking-${prebooking.id}`}
                  icon={faEdit}
                  style={{ marginRight: 4, fontSize: 13 }}
                  className={classNames.icon}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={onClickToDetailPage}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ marginRight: 4, fontSize: 13 }}
                className={classNames.icon}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    display &&
    width > 0 && (
      <Rnd
        style={{ zIndex: 3, top: 0, left: 0, display: "absolute" }}
        // default={{ x: x, y: y, width: width, height: 48}}
        size={{ width: width, height: CALENDAR_STEP_HEIGHT }}
        position={{ x: x, y: y }}
        enableResizing={{
          left: allowEdit ? true : false,
          right: allowEdit ? true : false,
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          const prevX = x;
          const prevW = width;
          const newX = parseInt(position.x.toFixed(0));
          let newWidth = ref.offsetWidth;
          // Sometime the offsetWidth value maybe differnt 1 or 2 pixel which causing wrong value, below block code handle that case
          if (newWidth % calendarStepWidth !== 0) {
            newWidth =
              (newWidth / calendarStepWidth).toFixed(0) * calendarStepWidth;
          }
          if (newX === prevX && newWidth === prevW) {
            return;
          }
          if (isOverlap(newX, newWidth)) {
            rollback({ previousY: y, previousW: prevW });
            return;
          }
          setX(newX);
          setWidth(newWidth);
          setUpdatePackage({
            newX: newX,
            newY: y,
            newW: newWidth,
            previousX: prevX,
            previousY: y,
            previousW: prevW,
          });
          setConfirmation(
            calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
          );
        }}
        onDragStop={(e, d) => {
          // this is kinda suprising but the values that match the grids are the lastX and lastY instead of x and y, so that is what we set
          const prevX = x;
          const prevY = y;
          const newX = parseInt(d.lastX.toFixed(0));
          const newY = parseInt(d.lastY.toFixed(0));
          if (newX === prevX && newY === prevY) {
            return;
          }
          if (isOverlap(newX, width, newY)) {
            return;
          }
          setX(newX);
          setY(newY);
          setUpdatePackage({
            newX: newX,
            newY: newY,
            newW: width,
            previousX: x,
            previousY: y,
            previousW: width,
          });
          setConfirmation(
            calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
          );
        }}
        resizeGrid={[calendarStepWidth, CALENDAR_STEP_HEIGHT]}
        dragGrid={[calendarStepWidth, CALENDAR_STEP_HEIGHT]}
        dragAxis="both"
        bounds=".planContainer"
        dragHandleClassName="dragHandle"
      >
        <div
          id={prebooking.id}
          style={{
            background: bgColor,
            display: "flex",
            marginTop: 5,
            height: CALENDAR_STEP_HEIGHT - 8,
            borderRadius: 4,
          }}
        >
          {confirmation ===
            calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED &&
            typeof updatePackage === "object" && (
              <ConfirmBubble
                onCancel={() => rollback(updatePackage)}
                onApprove={() => update(updatePackage)}
                targetId={prebooking.id}
              />
            )}
          <TooltipForText text={prebooking["projectName"]}>
            {content}
          </TooltipForText>
        </div>
      </Rnd>
    )
  );
};

export default connect(null, {
  setOverlayPrebookingId,
  saveMessage,
  updatePrebooking,
})(Prebooking);
