import { Formik } from "formik";
import React, { Fragment } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { getStaff, updateStaff } from "../../redux/user/user.actions";
import StaffForm from "./staff/StaffForm";

const EditStaff = ({ updateStaff, getStaff, hideHeader = false }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { staffId } = useParams();
  const { data, status } = useQuery(
    ["staff", staffId],
    () => getStaff([staffId]),
    { staleTime: 36000 }
  );

  const validationSchema = Yup.object({
    imageUrl: Yup.string("Upload image").nullable(),
    firstName: Yup.string("Enter a first name").required(
      "First name is required"
    ),
    lastName: Yup.string("Enter a last name")
      .required("Last name is required")
      .nullable(),
    email: Yup.string("Enter a email")
      .required("Email is required")
      .email("Invalid email"),
    techDepartment: Yup.string("Please select Sektion").required(
      "Department is required"
    ),
    workingRole: Yup.string("Please select Rolle").required(
      "Working Role is required"
    ),
    techArea: Yup.string("Please select Disponent").required(
      "Disponent is required"
    ),
  });

  const mutationUpdateStaff = useMutation((staff) => updateStaff(staff), {
    onSuccess: (data, variables) => {
      if (data) {
        queryClient.invalidateQueries("staffs");
        queryClient.invalidateQueries(["staff", staffId]);
        queryClient.invalidateQueries("usersMissingInfo");
        history.push("/staff");
      }
    },
  });

  const formSubmit = (newData) => {
    const staffData = {
      ...data[0],
      userId: newData.userId,
      imageUrl: newData.imageUrl,
      firstName: newData.firstName,
      lastName: newData.lastName,
      techDepartment: newData.techDepartment,
      techArea: newData.techArea,
      email: newData.email,
      password: newData.password,
      workingRole: newData.workingRole,
      authorizeRole: 0,
    };

    mutationUpdateStaff.mutate(staffData);
  };

  return (
    <Fragment>
      {status === "success" && data && data?.length > 0 && (
        <Formik
          initialValues={{ ...data[0] }}
          validationSchema={validationSchema}
          onSubmit={formSubmit}
        >
          {(props) => (
            <StaffForm
              hideHeader={hideHeader}
              history={history}
              editOnly={true}
              {...props}
            />
          )}
        </Formik>
      )}
    </Fragment>
  );
};

export default connect(null, { updateStaff, getStaff })(EditStaff);
