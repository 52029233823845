import { mergeStyleSets } from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import { faMessage, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { DeleteConfirmation } from '../../../../common';
import PersonaItem from './PersonaItem';

const classNames = mergeStyleSets({
    removeIcon: {
        fontSize: 18,
        color: '#006CAD',
    },
    removeContainer: {
        cursor: 'pointer',
        marginRight: 10,
    },
    tag: {
        fontSize: 10,
        borderRadius: 5,
        padding: '2px 5px',
        cursor: 'pointer',
        background: 'lightGrey',
        marginTop: 5,
    }
});

const CommentField = ({ id, userName, comment, deleteComment, userImageUrl, createdDate, subText }) => {
    const [deleteConfirmation, { toggle: toggleDeleteConfirmation }] = useBoolean(false);
    const onClickConfirmHandler = () => {
        deleteComment(id);
        toggleDeleteConfirmation();
    };
    const timeDelta = moment.utc(new Date()) - moment(createdDate);
    let timeCreated = ''
    if (timeDelta > 86400000*3) timeCreated = moment(createdDate).format('DD MMM YY HH:mm').toUpperCase();
    else timeCreated = moment(createdDate).locale('en').fromNow();
    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
            {/* <PersonaItem
                id={id}
                userName={userName}
                deleteComment={deleteComment}
                imageUrl={userImageUrl}
                createdDate={createdDate}
            /> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                    <div style={{ fontSize: 14, fontWeight: 'bold' }}><FontAwesomeIcon icon={faMessage}/>&ensp;{userName}</div>
                    {subText && <div className={classNames.tag}>{subText}</div>}
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{ fontSize: 11, color: 'rgb(159,159,171)', marginRight: 15 }}>{timeCreated}</div>
                    <div className={classNames.removeContainer} id={`deleteCommentIcon-${id}`} onClick={toggleDeleteConfirmation}>
                        <FontAwesomeIcon className={classNames.removeIcon} icon={faTrashAlt} />
                    </div>
                </div>
            </div>
            <div style={{ width: '90%', overflowWrap: 'break-word' }}>{comment}</div>
            {deleteConfirmation && (
                <DeleteConfirmation
                    target={`#deleteCommentIcon-${id}`}
                    toggleTeaching={toggleDeleteConfirmation}
                    onClickConfirm={onClickConfirmHandler}
                    mainPositionRight='77.0637px'
                    beakPositionRight='-8px'
                />
            )}
        </div>
    );
};

export default CommentField;
