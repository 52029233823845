import React from 'react';

const ColorSelectorItem = ({colorCode, onClick, selected = false}) => {
    return (
        <div onClick={onClick} style={{background: `rgba(${colorCode},1)`, height: 40, width: 40, borderRadius: '50%', cursor: 'pointer' }}>
            {selected &&
                <div style={{background: 'white', height: 16, width: 16, borderRadius: '50%', marginTop: 12, marginLeft: 12}} />
            }
        </div>
    )
}

export default ColorSelectorItem;
