import React, { useEffect, useState, useMemo, Fragment, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { updateStaff } from "../../../redux/user/user.actions";
import moment from "moment";
import "moment/locale/da";
import { Rnd } from "react-rnd";
import {
  reasonVacationStaffOptions,
  calendarConfirmationStatus,
  CALENDAR_STEP_WIDTH,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  CALENDAR_PAGE,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
} from "../../../utils/constants";
import { useQueryClient, useMutation } from "react-query";
import { saveMessage } from "../../../redux/message/message.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faArrows } from "@fortawesome/pro-regular-svg-icons";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";
import { DeleteConfirmation, TooltipForText } from "../../common";
import ConfirmBubble from "../../common/ConfirmBubble";
import { hasEditRight } from "../../../utils/utils";
import { getStringifiedMachine } from "../../../redux/machines/machine.selector";

const convertTimeToNumber = (horizontalPositions, time) => {
  const formattedDate = moment(time, "YYYY-M-D").format("YYYY-M-D");
  return horizontalPositions[formattedDate] || null;
};

const StaffMachineServiceItem = ({
  //   staffVacation,
  userMachineService,
  staffVacationIndex,
  staff,
  userId,
  start,
  end,
  horizontalPositions,
  verticalPositions,
  calendarStart,
  calendarEnd,
  updateStaff,
  saveMessage,
  viewOnly = false,
  calendarPage,
  //   staffInTeams,
  staffFullData,
  staffY,
  serviceText,
  renderDependencies: propRenderDependencies,
}) => {
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  //   let allowEdit = hasEditRight(userRoles);
  let allowEdit = false;

  const calendarState = useSelector(getStringifiedMachine);

  const { displayWeekView, displayStaffOnPlanning } = useSelector(
    (state) => state.machine
  );
  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;
  const currentUserId = useSelector((state) => state?.user?.user?.userId);
  const [teachingBubbleStaffVacation, { toggle: toggleTeachingStaffVacation }] =
    useBoolean(false);
  if (!allowEdit) viewOnly = true;
  if (userId === currentUserId) {
    allowEdit = true;
    viewOnly = false;
  }
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [width, setWidth] = useState(250);
  const [display, setDisplay] = useState(false);
  const [confirmation, setConfirmation] = useState(
    calendarConfirmationStatus.CONFIRMATION_UNSET
  );
  const needConfirmation =
    confirmation !== calendarConfirmationStatus.CONFIRMATION_UNSET;
  const colorCode = "241,241,241";
  let bgColor = `repeating-linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255) 24px, rgb(${colorCode}) 24px, rgb(${colorCode}) 48px)`;
  const queryClient = useQueryClient();
  const [updatePackage, setUpdatePackage] = useState(null);
  let calendarStepHeight = CALENDAR_STAFFVIEW_STEP_HEIGHT;
  const ref = useRef(null);
  // const renderDependencies = calendarState + JSON.stringify(verticalPositions);
  const renderDependencies =
    propRenderDependencies || calendarState + JSON.stringify(verticalPositions);
  if (!ref.current) ref.current = renderDependencies;
  const shouldComponentRerender = ref.current !== renderDependencies;
  if (
    calendarPage !== CALENDAR_PAGE.DISPOSITION &&
    calendarPage !== CALENDAR_PAGE.PRINT
  ) {
    calendarStepHeight = CALENDAR_STEP_HEIGHT;
  }

  const classNames = mergeStyleSets({
    icon: {
      color: "#575756",
      marginRight: 2,
      fontSize: 13,
      fontWeight: "lighter",
      selectors: {
        ":hover": {
          color: "#DBDBDB",
        },
      },
      cursor: "pointer",
    },
    draggable: {
      position: "relative",
      top: "50%",
      transform: "translateY(-50%)",
    },
    barContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "calc(100% - 15px)",
      height: "100%",
      cursor:
        allowEdit && calendarPage !== CALENDAR_PAGE.PRINT
          ? calendarPage === CALENDAR_PAGE.DISPOSITION
            ? `url(${window.location.origin}/img/trash-can.svg), auto`
            : `url(${window.location.origin}/img/arrows-alt.svg), auto`
          : "default",
    },
  });

  const listVacation = useMemo(
    () =>
      (staff?.starfVacations || []).map((item) => {
        const start = convertTimeToNumber(horizontalPositions, item.start);
        const end = convertTimeToNumber(horizontalPositions, item.end);
        return { start, end };
      }) || [],
    [staff?.starfVacations, horizontalPositions]
  );

  const { mutate: mutateUpdateStaff } = useMutation(
    (staff) => updateStaff(staff.data),
    {
      onSuccess: (data, variables) => {
        setUpdatePackage(null);
        if (data !== true) {
          rollback(variables.rollbackData);
        }
        queryClient.invalidateQueries("staffs");
        queryClient.invalidateQueries("plans");
      },
      onFailure: (data, variables) => {
        setUpdatePackage(null);
        rollback(variables.rollbackData);
      },
      onSettled: () => {
        queryClient.invalidateQueries("staffs");
        queryClient.invalidateQueries("plans");
      },
    }
  );

  useEffect(() => {
    if (
      confirmation === calendarConfirmationStatus.CONFIRMATION_UNSET &&
      ((!x && !y) || shouldComponentRerender)
    ) {
      if (shouldComponentRerender) ref.current = renderDependencies;
      let yPosition =
        typeof staff.index !== "undefined"
          ? verticalPositions[userId][staff.index]
          : verticalPositions[userId];

      const keyStart = moment(start).utcOffset(0).format("YYYY-M-D");
      const keyEnd = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");

      let xPosition = horizontalPositions[keyStart];
      let endValue = horizontalPositions[keyEnd];

      if (
        new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
        new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime() &&
        typeof xPosition === "undefined" &&
        typeof endValue === "undefined"
      ) {
        xPosition = 0;
        endValue = horizontalPositions["end"];
      }

      if (
        new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
        typeof endValue !== "undefined"
      ) {
        xPosition = 0;
      }

      if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
        endValue = horizontalPositions["end"];
      }
      if (calendarPage !== CALENDAR_PAGE.DISPOSITION) {
        setY(yPosition * calendarStepHeight);
      } else {
        setY(staffY);
      }
      if (typeof xPosition !== "undefined") {
        setX(xPosition * calendarStepWidth);
        setWidth((endValue - xPosition) * calendarStepWidth);
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    }
  }, [
    start,
    end,
    horizontalPositions,
    verticalPositions,
    calendarStart,
    calendarEnd,
    staff,
    userId,
    calendarStepWidth,
    calendarPage,
    x,
    y,
    staffY,
    calendarStepHeight,
    confirmation,
    renderDependencies,
    shouldComponentRerender,
  ]);

  const rollback = ({ previousX, previousY, previousW }) => {
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
    setX(previousX);
    setY(previousY);
    setWidth(previousW);
    setUpdatePackage(null);
  };

  const update = ({ newX, newY, newW, previousX, previousY, previousW }) => {
    const newStaff = { ...staff };
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
    if (newX !== previousX) {
      const newStartDate =
        horizontalPositions["positionsToDates"][newX / calendarStepWidth];
      if (!newStartDate) {
        return false;
      }
      newStaff.starfVacations[staffVacationIndex].start = newStartDate;
    }

    if (newX !== previousX || newW !== previousW) {
      const endPositionKey = (newW + newX) / calendarStepWidth;
      const newEndDate =
        horizontalPositions["positionsToDates"][endPositionKey];
      if (!newEndDate) {
        return false;
      }
      const newEndDateMinusOneDay = moment(newEndDate)
        .subtract(1, "d")
        .format("YYYY-MM-DD[T]00:00:00.000");
      newStaff.starfVacations[staffVacationIndex].end = newEndDateMinusOneDay;
    }
    mutateUpdateStaff({
      data: newStaff,
      rollbackData: { previousX, previousY, previousW },
    });
  };

  const isOverlap = (newX, newWidth) => {
    const newStart = newX / calendarStepWidth;
    const newEnd = newStart + newWidth / calendarStepWidth;
    const tempList = JSON.parse(JSON.stringify(listVacation));
    tempList.splice(staffVacationIndex, 1);
    for (let i = 0; i < tempList.length; i++) {
      if (
        (newStart >= tempList[i].start && newEnd <= tempList[i].end) ||
        (newStart < tempList[i].start && newEnd > tempList[i].start) ||
        (newStart < tempList[i].end && newEnd > tempList[i].end)
      ) {
        saveMessage("Cannot Overlap");
        setDisplay(false);
        setDisplay(true);
        return true;
      }
    }
    return false;
  };

  const text = serviceText || "";

  const onClickConfirmDelete = (staffVacationIndex) => {
    const staffData = staffFullData ? { ...staffFullData } : { ...staff };
    staffData.starfVacations.splice(staffVacationIndex, 1);
    toggleTeachingStaffVacation();
    mutateUpdateStaff({ data: staffData });
  };

  let content = (
    <div
      id={`staffVacationBar-${userId}-${staffVacationIndex}`}
      className={`${
        allowEdit &&
        !viewOnly &&
        !needConfirmation &&
        calendarPage !== CALENDAR_PAGE.DISPOSITION
          ? "dragHandle"
          : "dragDisabled"
      } ${classNames.barContent}`}
      style={{ marginLeft: viewOnly ? 5 : 10, justifyContent: "flex-start" }}
      onClick={
        calendarPage === CALENDAR_PAGE.DISPOSITION
          ? toggleTeachingStaffVacation
          : () => null
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-end",
            marginTop: calendarPage !== CALENDAR_PAGE.DISPOSITION ? 4 : 1,
          }}
        >
          <span
            style={{
              color: "#575756",
              fontWeight: "bold",
              fontSize: 11,
              marginRight: 8,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {/* {text}
            <span
              style={{
                color: "#575756",
                fontWeight: 400,
                fontSize: 11,
                marginLeft: 8,
              }}
            >
              {staffVacation["notes"] || ""}
            </span> */}
            {text}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
            color: "#575756",
            marginBottom: 8,
          }}
        >
          {!viewOnly &&
            calendarPage !== CALENDAR_PAGE.DISPOSITION &&
            calendarPage !== CALENDAR_PAGE.PRINT && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: 16,
                }}
                id={`deleteVacation-${userId}-${staffVacationIndex}`}
                onClick={toggleTeachingStaffVacation}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{ marginRight: 4, fontSize: 13 }}
                  className={classNames.icon}
                />
                <span style={{ color: "#575756", fontSize: 11 }}>Delete</span>
              </div>
            )}
        </div>
      </div>
    </div>
  );
  if (width <= calendarStepWidth * 2) {
    content = (
      <div
        id={`staffVacationBar-${userId}-${staffVacationIndex}`}
        className={`${
          allowEdit && !viewOnly && !needConfirmation
            ? "dragHandle"
            : "dragDisabled"
        } ${classNames.barContent}`}
        style={{ justifyContent: "center" }}
        onClick={
          displayStaffOnPlanning &&
          calendarPage === CALENDAR_PAGE.DISPOSITION &&
          viewOnly
            ? toggleTeachingStaffVacation
            : ""
        }
      >
        {!viewOnly ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
                width: "calc(100% - 5px)",
                marginLeft: 10,
              }}
            >
              <div
                style={{
                  color: "#575756",
                  fontWeight: "bold",
                  fontSize: 11,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {text}
              </div>
              <FontAwesomeIcon
                icon={faTrashAlt}
                className={classNames.icon}
                style={{
                  margin: 0,
                  minWidth: 10,
                  marginLeft: width === calendarStepWidth ? -3 : 0,
                }}
                id={`deleteVacation-${userId}-${staffVacationIndex}`}
                onClick={toggleTeachingStaffVacation}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100%",
              width: "calc(100%)",
              marginLeft: 10,
              color: "red",
            }}
          >
            <div
              style={{
                color: "#575756",
                fontWeight: "bold",
                fontSize: 11,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                minWidth: 5,
              }}
            >
              {text}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    display &&
    width > 0 && (
      <Fragment>
        <Rnd
          style={{ zIndex: 3, top: 0, left: 0, display: "absolute" }}
          // default={{ x: x, y: y, width: width, height: 48}}
          size={{ width: width, height: calendarStepHeight }}
          position={{ x: x, y: y }}
          enableResizing={{
            left:
              !viewOnly &&
              !needConfirmation &&
              calendarPage !== CALENDAR_PAGE.DISPOSITION &&
              calendarPage !== CALENDAR_PAGE.PRINT,
            right:
              !viewOnly &&
              !needConfirmation &&
              calendarPage !== CALENDAR_PAGE.DISPOSITION &&
              calendarPage !== CALENDAR_PAGE.PRINT,
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            const prevX = x;
            const prevW = width;
            const newX = parseInt(position.x.toFixed(0));
            let newWidth = ref.offsetWidth;
            // Sometime the offsetWidth value maybe differnt 1 or 2 pixel which causing wrong value, below block code handle that case
            if (newWidth % calendarStepWidth !== 0) {
              newWidth =
                (newWidth / calendarStepWidth).toFixed(0) * calendarStepWidth;
            }
            if (newX === prevX && newWidth === prevW) {
              return;
            }
            if (isOverlap(newX, newWidth)) {
              rollback({ previousY: y, previousW: prevW });
              return;
            }
            setX(newX);
            setWidth(newWidth);
            setUpdatePackage({
              newX: newX,
              newY: y,
              newW: newWidth,
              previousX: prevX,
              previousY: y,
              previousW: prevW,
            });
            setConfirmation(
              calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
            );
          }}
          onDragStop={(e, d) => {
            const prevX = x;
            const prevY = y;
            if ((d.lastX === prevX && d.lastY === prevY) || d.lastY !== prevY) {
              return;
            }
            if (isOverlap(d.lastX, width)) {
              return;
            }
            setX(d.lastX);
            setY(d.lastY);
            setUpdatePackage({
              newX: d.lastX,
              newY: d.lastY,
              newW: width,
              previousX: x,
              previousY: y,
              previousW: width,
            });
            setConfirmation(
              calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
            );
          }}
          resizeGrid={[calendarStepWidth, calendarStepHeight]}
          dragGrid={[calendarStepWidth, calendarStepHeight]}
          dragAxis={allowEdit ? "both" : "x"}
          bounds=".staffVacationContainer"
          dragHandleClassName="dragHandle"
        >
          <div
            id={JSON.stringify(userMachineService)}
            className={classNames.draggable}
            style={{
              backgroundImage: bgColor,
              backgroundSize: viewOnly ? "" : "48px 48px",
              backgroundPosition: viewOnly
                ? "0 0, 0 0, -10px -10px, 10px 10px"
                : "0 0, 0 0, -24px -24px, 24px 24px",
              display: "flex",
              height: calendarStepHeight - 8,
              border: "2px solid #868685",
              boxSizing: "border-box",
              borderRadius: 4,
            }}
          >
            {confirmation ===
              calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED &&
              typeof updatePackage === "object" && (
                <ConfirmBubble
                  onCancel={() => rollback(updatePackage)}
                  onApprove={() => update(updatePackage)}
                  targetId={JSON.stringify(userMachineService)}
                />
              )}
            <TooltipForText text={text}>{content}</TooltipForText>
          </div>
        </Rnd>
        {teachingBubbleStaffVacation && (
          <DeleteConfirmation
            target={
              displayStaffOnPlanning &&
              calendarPage === CALENDAR_PAGE.DISPOSITION
                ? `#staffVacationBar-${userId}-${staffVacationIndex}`
                : `#deleteVacation-${userId}-${staffVacationIndex}`
            }
            toggleTeaching={toggleTeachingStaffVacation}
            onClickConfirm={() => {
              onClickConfirmDelete(staffVacationIndex);
            }}
          />
        )}
      </Fragment>
    )
  );
};

export default connect(null, { updateStaff, saveMessage })(
  StaffMachineServiceItem
);
