import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/pro-regular-svg-icons';

const classNames = mergeStyleSets({
    icon: {
        fontSize: 16,
        margin: '0 5px',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 'normal !important'
    }
});
const MachineRequirementsDateDisplay = ({start, end}) => {
    const bottomBorder = (value) => ({borderBottom: new Date(value).getTime() < 0 ? '1px solid red' : 'unset'});
    return (
        <div className={classNames.container} >
            <span style={bottomBorder(start)}>{moment(start).format('DD.MM.YYYY')}</span>
            <FontAwesomeIcon icon={faLongArrowAltRight} className={classNames.icon}/>
            <span style={bottomBorder(end)}>{moment(end).format('DD.MM.YYYY')}</span>
        </div>
    )
}

export default MachineRequirementsDateDisplay;
