import { useState, useEffect, useRef, useMemo } from "react";
import { useQuery } from "react-query";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getMachines } from "../../../redux/machines/machines.actions";
import { changeSectionFilter } from "../../../redux/user/user.actions";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import {
  techAreaOptions,
  techDepartmentOptions,
} from "../../../utils/constants";
import { copyAndSort, hasEditRight } from "../../../utils/utils";
import { MachineControls, MachineTableTechDepartmentInfoCell } from "./";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Stack, PrimaryButton } from "@fluentui/react";
import {
  headerRightButtonStyles,
  primaryButtonStyles,
} from "../../../utils/theme";
import MachineTableInfoCell from "./MachineTableInfoCell";
import { ColorIndicator, MachineIconWithBadge } from "../../common";
import { setTableSort } from "../../../redux/app/app.actions";

const commonStationSelectorButton = {
  height: 20,
  borderRadius: 24,
  color: "#ffffff",
  background: "#006CAD",
  padding: "4px 16px",
  fontSize: 14,
  lineHeight: 20,
  margin: "8px 4px",
  cursor: "pointer",
  selectors: {
    ":hover": {
      color: "#DBDBDB",
    },
  },
};
const classNames = mergeStyleSets({
  alignItemRow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  button: [
    commonStationSelectorButton,
    {
      color: "#868685",
      background: "#DFDFDF",
      selectors: {
        ":hover": {
          color: "#DFDFDF",
          background: "#868685",
        },
      },
    },
  ],
  buttonSelected: commonStationSelectorButton,
});

const NOT_SORTING_FIELDS = ["action", "equipment"];

const WrapperText = ({ inactive, children }) => (
  <div style={{ color: inactive ? "#868685" : "#000" }}>{children}</div>
);

const MachineTable = ({
  getMachines,
  defaultData,
  techDepartmentDefaultFilter,
  selectedTechDepartmentFilter,
  changeSectionFilter,
  scrollY,
  tableSort,
}) => {
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const { data: machineData } = useQuery("machines", () => getMachines(), {
    refetchOnWindowFocus: false,
  });
  const [listData, setListData] = useState(machineData || []);
  //   const techDepartmentFilter = selectedTechDepartmentFilter
  //     ? techDepartmentOptions.map((item) => ({
  //         ...item,
  //         isOn: selectedTechDepartmentFilter.includes(item.key),
  //       }))
  //     : techDepartmentOptions.map((item) => ({
  //         ...item,
  //         isOn: item.key === techDepartmentDefaultFilter,
  //       }));

  const techDepartmentFilter = useMemo(() => {
    if (machineData && machineData?.length) {
      let filters = selectedTechDepartmentFilter
        ? techDepartmentOptions.map((item) => ({
            ...item,
            isOn: selectedTechDepartmentFilter.includes(item.key),
          }))
        : techDepartmentOptions.map((item) => ({
            ...item,
            isOn: item.key === techDepartmentDefaultFilter,
          }));

      filters = filters.filter((el) =>
        machineData.find((machine) =>
          machine.techDepartments.includes(el.key.toString())
        )
      );

      return filters;
    }

    return [];
  }, [selectedTechDepartmentFilter, machineData]);

  const techDepartmentFilterRef = useRef(null);
  if (
    !techDepartmentFilterRef.current ||
    JSON.stringify(techDepartmentFilter) !==
      JSON.stringify(techDepartmentFilterRef.current)
  ) {
    techDepartmentFilterRef.current = techDepartmentFilter;
  }

  const onChangeSectionFilter = (key) => {
    const tempArray = techDepartmentFilter
      .filter((item) => item.isOn)
      .map((item) => item.key);
    tempArray.indexOf(key) === -1
      ? tempArray.push(key)
      : tempArray.splice(tempArray.indexOf(key), 1);
    changeSectionFilter(tempArray);
  };

  useEffect(() => {
    if (machineData) {
      let newListDisplay = machineData
        .filter((machine) =>
          techDepartmentFilter.some(
            (role) => role.isOn && machine.techDepartments.includes(role.key)
          )
        )
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .sort((a, b) => (!a.inactive && b.inactive ? -1 : 0));
      if (tableSort.machineTable) {
        const { columnName, isSortedDescending } = tableSort.machineTable;
        newListDisplay = copyAndSort(
          newListDisplay,
          columnName,
          isSortedDescending
        );
        setColumns((prevColumns) =>
          prevColumns.map((col) => {
            col.isSorted = col.key === columnName;
            if (col.isSorted) {
              col.isSortedDescending = isSortedDescending;
            }
            return col;
          })
        );
      }
      setListData(newListDisplay);
    }
  }, [machineData, techDepartmentFilterRef.current]);

  const onClickSorting = (e, column) => {
    if (NOT_SORTING_FIELDS.includes(column.fieldName)) {
      return;
    }
    let isSortedDescending = column.isSortedDescending;
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }
    dispatch(
      setTableSort({
        key: "machineTable",
        value: { columnName: column.fieldName, isSortedDescending },
      })
    );
    let sortedItems = copyAndSort(
      listData,
      column.fieldName,
      isSortedDescending
    );
    setListData(sortedItems);
    setColumns((prevColumns) =>
      prevColumns.map((col) => {
        col.isSorted = col.key === column.key;
        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }
        return col;
      })
    );
  };
  const history = useHistory();

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName || ""];
    switch (column.key) {
      case "color":
        return <ColorIndicator colorCode={item.color} />;
      case "sortIndex":
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>
              {item.sortIndex !== null ? item.sortIndex + 1 : "-"}
            </WrapperText>
          </div>
        );
      case "equipment":
        return (
          <MachineTableInfoCell
            machineDocumentUrl={item.machineDocumentUrl}
            machineId={item.machineId}
            history={history}
          />
        );
      case "action":
        return (
          <MachineControls
            machine={item}
            machineId={item.machineId}
            history={history}
          />
        );
      case "sizeGroup":
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>
              {defaultData?.sizeGroupOptions.find(
                (sizeGroup) => sizeGroup.key === item.sizeGroup
              )?.text ?? "UNKNOWN"}
            </WrapperText>
          </div>
        );
      case "disponent":
        const techAreaList = item.techAreas
          ? item.techAreas
              .split(";")
              .filter((item) => item !== "-1")
              .map(
                (key) =>
                  techAreaOptions.find(
                    (techArea) => techArea.key === parseInt(key)
                  )?.text
              )
              .join(", ")
          : "UNKNOWN";
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>{techAreaList}</WrapperText>
          </div>
        );
      case "machineType":
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>
              {defaultData?.machineTypes?.find(
                (type) => type.id === item.machineType
              )?.name ?? "UNKNOWN"}
            </WrapperText>
          </div>
        );
      case "techDepartments":
        return (
          <MachineTableTechDepartmentInfoCell
            techDepartments={item.techDepartments}
          />
        );
      case "requiredSkills":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <span>
              <MachineIconWithBadge count={fieldContent?.length || 0} />
            </span>
          </div>
        );
      default:
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>{fieldContent}</WrapperText>
          </div>
        );
    }
  };

  const initColumns = [
    {
      data: "string",
      fieldName: "color",
      isPadded: true,
      isRowHeader: true,
      key: "color",
      maxWidth: 30,
      minWidth: 30,
      name: "",
    },
    {
      data: "index",
      fieldName: "sortIndex",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: "sortIndex",
      maxWidth: 50,
      minWidth: 50,
      name: "INDEX",
    },
    {
      data: "number",
      fieldName: "machineNo",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: "machineNo",
      maxWidth: 128,
      minWidth: 128,
      name: "MASKINNUMMER",
    },
    {
      data: "string",
      fieldName: "machineName",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: "machineName",
      maxWidth: 250,
      minWidth: 210,
      name: "NAVN",
    },
    {
      data: "string",
      fieldName: "machineType",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "machineType",
      maxWidth: 100,
      minWidth: 50,
      name: "MASKINTYPE",
    },
    {
      data: "string",
      fieldName: "sizeGroup",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "sizeGroup",
      maxWidth: 100,
      minWidth: 100,
      name: "SATSGRUPPE",
    },
    {
      data: "string",
      fieldName: "techDepartments",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "techDepartments",
      maxWidth: 144,
      minWidth: 144,
      name: "FAGSEKTION",
    },
    {
      data: "string",
      fieldName: "techArea",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "disponent",
      maxWidth: 100,
      minWidth: 100,
      name: "DISPONENT",
    },
    {
      data: "number",
      fieldName: "equipment",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "equipment",
      maxWidth: 100,
      minWidth: 100,
      name: "MORE INFO",
    },
    {
      data: "string",
      fieldName: "requiredSkills",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "requiredSkills",
      maxWidth: 100,
      minWidth: 100,
      name: "SKILLS",
    },
  ];

  if (allowEdit) {
    initColumns.push({
      data: "number",
      fieldName: "action",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "action",
      maxWidth: 30,
      minWidth: 30,
      name: "ACTION",
    });
  }

  const [columns, setColumns] = useState(initColumns);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
          width: "calc(100% - 150px)",
        }}
      >
        <Stack horizontal="true" tokens={{ childrenGap: 8 }}>
          {techDepartmentFilter.map((option, index) => (
            <div
              key={option["key"]}
              className={
                option.isOn ? classNames.buttonSelected : classNames.button
              }
              onClick={() => {
                onChangeSectionFilter(option["key"]);
              }}
            >
              {option["text"]}
            </div>
          ))}
        </Stack>
        {allowEdit && (
          <div
            style={headerRightButtonStyles(
              document.documentElement.offsetWidth,
              scrollY
            )}
          >
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 20 }}
              className={classNames.buttonStack}
            >
              {/* <DefaultButton disabled iconProps={{ iconName: "CloudImportExport" }} text='Synk data' onClick={() => {}} styles={defaultButtonStyles}/> */}
              <PrimaryButton
                text="OPRET NY"
                onClick={() => {
                  history.push("/new-machine");
                }}
                styles={primaryButtonStyles}
              />
            </Stack>
          </div>
        )}
      </div>
      <DetailsList
        items={listData && Array.isArray(listData) ? listData : []}
        compact={false}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onRenderItemColumn={renderItemColumn}
        onColumnHeaderClick={onClickSorting}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  defaultData: state.defaultData,
  techDepartmentDefaultFilter:
    state?.user?.user?.defaultFilter?.techDepartmentFilter,
  selectedTechDepartmentFilter: state?.user?.user?.techDepartmentFilter,
  tableSort: state?.app?.tableSort,
});

export default connect(mapStateToProps, { getMachines, changeSectionFilter })(
  MachineTable
);
