// Import libraries
import { Spinner, SpinnerSize } from "@fluentui/react/";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
// @ts-expect-error
import { datesGenerator } from "dates-generator";
import domtoimage from "dom-to-image";

import jsPDF from "jspdf";
import "moment/locale/da";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

// Import redux
import { getHolidayList } from "../../redux/app/app.actions";
import { saveMessage } from "../../redux/message/message.actions";

// Import utils
import { stationOptions } from "../../utils/constants";
import {
  calculateFinalDates,
  findYearOfDates,
  getCalendarHoliday,
  getMonday,
  useQueryParams,
} from "../../utils/utils";

// Import components
import PrintMachines from "./PrintMachines";
import PrintProjects from "./PrintProjects";
import PrintStaffVacations from "./PrintStaffVacations";
import PrintSupervisors from "./PrintSupervisors";

// Import types
import { TDates, THoliday } from "../../types";

const classNames = mergeStyleSets({
  printBtn: {
    display: "none",
    margin: "47px 0 0 48px",
  },
  printButtonContainer: {
    display: "flex",
    position: "fixed",
    top: 5,
    right: 20,
    zIndex: 1000,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#006CAD",
    border: "1px solid #006CAD",
    borderRadius: 4,
    width: 150,
    height: 40,
    color: "#fff",
    cursor: "pointer",
    selectors: {
      ":hover": {
        backgroundColor: "#00609c",
      },
    },
  },
});

const Print = () => {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const fromWeek = queryParams.get("fromWeek");
  const toWeek = queryParams.get("toWeek");
  const techDepartment = parseInt(queryParams.get("techDepartment") as string);
  const [dates, setDates] = useState<TDates>([]);
  const holidayRef = useRef<{
    years: number[];
    holidays: THoliday[];
  } | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    async function getDates() {
      const array = new Array(24);
      const startYear = new Date(
        queryParams.get("from") as string
      ).getFullYear();
      // const endYear = new Date(queryParams.get("to") as string).getFullYear();
      array[0] = datesGenerator({ month: 0, year: startYear, startingDay: 1 });
      for (let i = 1; i < array.length; i++) {
        array[i] = datesGenerator({
          month: array[i - 1].nextMonth,
          year: array[i - 1].nextYear,
          startingDay: 1,
        });
      }

      let { finalDates } = calculateFinalDates(array) as { finalDates: TDates };

      const dt = finalDates[0][0];
      const date = new Date(dt.year, dt.month, dt.date);

      if (
        finalDates[0][6].year === startYear &&
        new Date(date).getTime() === getMonday(date).getTime()
      ) {
      } else {
        finalDates.shift();
      }

      // This has to be done because we can't have 53 weeks
      // In some cases the first week is not thrown off
      if (
        finalDates.length > 52 &&
        finalDates[finalDates.length - 1][6].year !== startYear
      ) {
        finalDates.splice(52, 1);
      }

      let weekEndSlice = parseInt(queryParams.get("toWeek") as string);
      let weekStartSlice = parseInt(queryParams.get("fromWeek") as string) - 1;

      // if (startYear < endYear) {
      //   weekEndSlice += 52;
      // }
      // if (startYear === endYear && startYear !== new Date().getFullYear()) {
      //   weekStartSlice += 52;
      //   weekEndSlice += 52;
      // }

      finalDates = finalDates.slice(weekStartSlice, weekEndSlice);
      // Find the holiday of calendar
      const yearOfDates = findYearOfDates(finalDates);
      let holidays: THoliday[] = [];
      if (
        !holidayRef.current ||
        JSON.stringify(yearOfDates) !==
          JSON.stringify(holidayRef.current?.years)
      ) {
        for (const year of yearOfDates) {
          const currentHolidays: {
            holidays: THoliday[];
          } = await getHolidayList(year)(dispatch);

          holidays = [...holidays, ...(currentHolidays?.holidays || [])];
        }
        holidayRef.current = {
          years: yearOfDates,
          holidays: holidays,
        };
      } else {
        holidays = holidayRef.current.holidays;
      }
      finalDates = getCalendarHoliday(finalDates, holidays);

      setDates(finalDates);
    }
    getDates();
  }, []);

  const printReport = async () => {
    try {
      setDownloading(true);

      // @ts-expect-error
      const pdf = new jsPDF({
        unit: "mm",
        format: "A4",
        userUnit: "mm",
        orientation: "landscape",
      });

      const printIds = [
        "printMachines",
        "printSupervisors",
        "printStaffVacations",
        "printProjects",
      ];

      for (const id of printIds) {
        const printTarget = document.getElementById(id);

        if (printTarget) {
          const targetDimension = printTarget.getBoundingClientRect();
          // Adjust w/h scale to enhace quality and fit 6 weeks of print
          const widthScale = id === "printProjects" ? 2 : 2.13;
          const heightScale = id === "printProjects" ? 2 : 3;
          await domtoimage
            .toPng(printTarget, {
              width: printTarget.clientWidth * widthScale,
              height: targetDimension.height * heightScale,
              style: {
                transform: `scale(${heightScale})`,
                transformOrigin: "top left",
              },
            })
            .then((imgData) => {
              const imgWidth = 295;
              const pageHeight = 210;
              const imgHeight =
                (targetDimension.height * imgWidth * heightScale) /
                (targetDimension.width * widthScale);
              let heightLeft = imgHeight;
              let topPosition = 0;
              if (id !== "printMachines") pdf.addPage();
              pdf.addImage(imgData, "PNG", 0, topPosition, imgWidth, imgHeight);
              heightLeft -= pageHeight;

              while (heightLeft > 0) {
                topPosition = heightLeft - imgHeight; // top padding for this page
                pdf.addPage();
                pdf.addImage(
                  imgData,
                  "PNG",
                  0,
                  topPosition,
                  imgWidth,
                  imgHeight
                );
                heightLeft -= pageHeight;
              }
            });
        } else {
          throw new Error(`HTML Element with id :${id} is not found`);
        }
      }

      pdf.save(
        // @ts-ignore
        `${stationOptions[techDepartment]["text"]} uge ${fromWeek} - ${toWeek}.pdf`
      );
    } catch (error) {
      dispatch(saveMessage((error as Error).message));
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div>
      <div className={classNames.printButtonContainer} onClick={printReport}>
        Generer PDF
        {downloading && (
          <Spinner size={SpinnerSize.small} style={{ marginLeft: 5 }} />
        )}
      </div>
      {dates.length ? (
        <div>
          <PrintMachines dates={dates} />
          <PrintSupervisors dates={dates} />
          <PrintStaffVacations dates={dates} />
          {/* @ts-ignore */}
          <PrintProjects dates={dates} />
        </div>
      ) : null}
    </div>
  );
};

export default Print;
