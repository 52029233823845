import React, { useState, useEffect } from 'react';
import {
    getTheme,
    FontWeights,
    mergeStyleSets,
  } from '@fluentui/react';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import { useSelector } from 'react-redux';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '700px',
        height: 'fit-content',
        borderRadius: '8px'
    },
    header: [
        {
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            fontSize: '24px',
            padding: '12px 45px 14px',
            selectors: {
                h3: {
                    fontSize: '20px',
                    textAlign: 'center',
                    width: '100%',
                    marginLeft: '35px'
                }
            }
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 45px 24px',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    Persona: {
        padding: '0px 15px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        color: '#000',
        height: 30,
        fontSize: '14px',
        ':hover': { color: 'rgb(88, 88, 88)' }
    },
    station: {
        margin: 5,
        width: 25,
        height: 14,
        color: '#868685',
        background: '#DFDFDF',
        textAlign: 'center',
        fontSize: 11,
        lineHeight: 14,
        borderRadius: 4,
        padding: 2
    },
    customStack: {
        display: 'flex',
        flexFlow: 'wrap',
        justifyContent: 'space-between',
    },
    detailsContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '450px',
        height: 'fit-content',
        borderRadius: '8px'
    },
    detailsHeader: [
        {
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            fontSize: '24px',
            padding: '12px 45px 14px',
            selectors: {
                h3: {
                    fontSize: '20px',
                    textAlign: 'center',
                    width: '100%',
                    marginLeft: '35px',
                    color: '#fff'
                }
            },
            height: '150px'
        },
    ],
    selectedTeamBlock: {
        borderRadius: '8px',
        backgroundColor: 'white',
        width: '300px',
        padding: 20,
        textAlign: 'center',
        margin: '-33px auto 10px',
        fontSize: '18px',
        fontWeight: 700
    },
    titleElementStyle: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginBottom: 10,
        marginTop: 20
    },
    titleNameElementStyle: {
        fontWeight: 'bold', marginRight: '16px'
    },
    image: {
        marginRight: '15px',
        fontSize: 16
    },
    teamDetailsBlock: {
        width: 300,
        margin: '0 auto'
    },
    detailsPersona: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '30px',
        marginLeft: '30px'
    },
    buttonControls: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
})

const scrollablePane = {
    root: {
        position: 'relative'
    },
    contentContainer: {
        position: 'relative'
    }
}

const PopupTeams = ({ target, onAdd, machineData, toggleTeaching }) => {
    const [teamList, setTeamList] = useState([]);
    const { sizeGroupOptions } = useSelector((state) => state.defaultData);
    
    useEffect(() => {
        const tempList = machineData.map((item) => ({...item, sizeGroupDisplay: sizeGroupOptions.find((sizeGroup) => sizeGroup.key === item.sizeGroup)?.text ?? 'UNKNOWN'}))
        setTeamList(tempList[0].teams)
    }, [machineData, sizeGroupOptions]);

    const handleAddTeam = (team) => {
        toggleTeaching()
        onAdd(team)
    }

    return (
        <TeachingBubble
            target={`#${target}`}
            hasSmallHeadline={true}
            onDismiss={toggleTeaching}
            closeButtonAriaLabel="Close"
        >  
            <ScrollablePane 
                scrollbarVisibility={ScrollbarVisibility.auto}
                styles={scrollablePane}
            >
                {
                    teamList.map((team) => (
                        <div key={`machine-${team.id}`} className={classNames.Persona} onClick={() => handleAddTeam(team)}>
                            {team.name}
                        </div>
                    ))
                }
            </ScrollablePane>
        </TeachingBubble>
    )
}

export default PopupTeams;