import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';

export type IconWithBadgeProps = {
    isRed: boolean,
    count: number,
    onClick: React.MouseEventHandler<HTMLDivElement> | undefined,
    isSmall: boolean,
    iconImgUrl: string
}

const IconWithBadge = ({count = 0, isRed, onClick = () => {}, isSmall = false,iconImgUrl}: IconWithBadgeProps) => {
    const classNames = mergeStyleSets({
        container: {
            height: isSmall ? 16 : 20,
            width: isSmall ? 16 : 20,
            position: 'relative',
            marginRight: isSmall ? 15 : 10,
            cursor: 'pointer'
        },
        badge: {
            background: isRed ? '#A4272D' : '#006CAD',
            color: '#ffffff',
            fontSize: 11,
            textAlign: 'center',
            lineHeight: isSmall ? 18 : 20,
            height: isSmall ? 18 : 20,
            width: isSmall ? 18 : 20,
            position: 'absolute',
            top: -8,
            right: -14,
            borderRadius: '50%',
        }
    });
    return (
        <div className={classNames.container} onClick={onClick}>
            <Image
                src={iconImgUrl}
                imageFit={ImageFit.contain}
                alt='Machine'
                width={24}
                height={24}
            />
            { count > 0 &&   
                <div className={classNames.badge}>{count}</div>
            }
        </div>
    )
}

export default IconWithBadge;
