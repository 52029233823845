import { Formik } from "formik";
import { Fragment } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  getMachine,
  updateMachine,
} from "../../redux/machines/machines.actions";
import { getSkills } from "../../redux/skill/skill.actions";
import { SKILLS_QUERY_KEY } from "../../utils/constants";
import { MachineForm } from "./machines/";

const Machine = ({ updateMachine, getMachine, defaultData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { machineId } = useParams();

  const {
    data: skillsData,
    // isFetching: isLoadingGetSkills,
    // refetch: refetchSkills,
  } = useQuery(SKILLS_QUERY_KEY, () => getSkills(dispatch), {
    initialData: [],
  });

  const { data, status } = useQuery(
    ["machine", machineId],
    () => getMachine(machineId),
    { staleTime: 36000 }
  );

  const oneOfMachineType = defaultData?.machineTypes.map((type) => type.id) ?? [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 999,
  ];
  const oneOfSizeGroup = defaultData?.sizeGroupOptions.map(
    (sizeGroup) => sizeGroup.key
  ) ?? [0, 1, 2, 3, 4, 5, 6, 7];
  const validationSchema = Yup.object({
    imageUrl: Yup.string("Upload image").nullable(),
    machineDocumentUrl: Yup.lazy((val) =>
      Array.isArray(val)
        ? Yup.array().of(Yup.string())
        : Yup.string().nullable()
    ),
    machineNo: Yup.string("Enter a name").required("MachineId is required"),
    machineName: Yup.string("Enter a name").required("Name is required"),
    // machineType: Yup.mixed()
    //   .oneOf(oneOfMachineType)
    //   .required("Machine type is required"),
    machineType: Yup.string("Select machine type").required(
      "Machine type is required"
    ),
    sizeGroup: Yup.mixed()
      .oneOf(oneOfSizeGroup)
      .required("Size Group is required"),
    techDepartments: Yup.string("Select department").required(
      "Department is required"
    ),
    techAreas: Yup.string("Select disponent").required("Disponent is required"),
    requiredSkills: Yup.array()
      .of(Yup.string())
      .required("Required skills is required"),
  });

  const mutationUpdateMachine = useMutation(
    (machine) => updateMachine({ ...machine, model: data.model }),
    {
      onSuccess: (data, variables) => {
        if (data) {
          queryClient.invalidateQueries("machines");
          queryClient.invalidateQueries(["machine", machineId]);
          // history.push("/machines");
        }
      },
    }
  );

  const formSubmit = (newData) => {
    const machine = {
      ...data,
      imageUrl: newData.imageUrl,
      machineDocumentUrl: newData.machineDocumentUrl
        ? Array.isArray(newData.machineDocumentUrl)
          ? newData.machineDocumentUrl
          : [newData.machineDocumentUrl]
        : null,
      machineId: newData.machineId,
      machineNo: newData.machineNo,
      machineName: newData.machineName,
      machineType: newData.machineType,
      sizeGroup: newData.sizeGroup,
      techDepartments: newData.techDepartments,
      techAreas: newData.techAreas,
      color: newData.color,
      requiredSkills: newData.requiredSkills,
    };
    mutationUpdateMachine.mutate(machine);
  };

  return (
    <Fragment>
      {status === "success" && data && (
        <Formik
          initialValues={{
            ...data,
            machineType: Number.parseInt(data.machineType, 10),
          }}
          validationSchema={validationSchema}
          onSubmit={formSubmit}
        >
          {(props) => (
            <MachineForm skills={skillsData} history={history} {...props} />
          )}
        </Formik>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  defaultData: state.defaultData,
});

export default connect(mapStateToProps, { updateMachine, getMachine })(Machine);
