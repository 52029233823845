// Import libraries
import { Image, ImageFit } from "@fluentui/react";

// Import utils
import {
  BLACK_FILTER,
  GREEN_FILTER,
  ORANGE_FILTER,
} from "../../../../utils/skill";

const details: { filter: string; id: string; text: string }[] = [
  {
    filter: GREEN_FILTER,
    id: "GREEN",
    text: "All good",
  },
  {
    filter: ORANGE_FILTER,
    id: "ORANGE",
    text: "Skills need attention",
  },
  {
    filter: BLACK_FILTER,
    id: "BLACK",
    text: "No skills on project",
  },
];

const ProjectRequirementDetail = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <div>
        <h3>Project Skill Requirement</h3>
      </div>
      {details.map(({ filter, id, text }) => (
        <div
          style={{
            display: "flex",
            gap: 6,
            justifyContent: "center",
          }}
          key={id}
        >
          <div>
            <Image
              src={`${window.location.origin}/img/projekter.svg`}
              imageFit={ImageFit.cover}
              width={15}
              height={15}
              style={{
                filter,
              }}
            />
          </div>
          <div>{text}</div>
        </div>
      ))}
    </div>
  );
};

export default ProjectRequirementDetail;
