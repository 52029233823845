import React from "react";
import { useDispatch } from "react-redux";
import { Stack } from "@fluentui/react";
import { classNames } from "../../pages/planning/filterSelector/SelectorStyles";
import { setFilter } from "../../../redux/machines/machines.actions";
import { setFilter as setSkillFilter } from "../../../redux/skill/skill.actions";
import { toggleSelectorItem } from "../../../redux/machines/machines.utils";

interface ToggleableSelectorProps {
  filterKey?: string;
  techDepartment?: string;
  allOptions: any[];
  availableOptions: any[];
  selectedOptions: any[];
  isSkillSelector?: boolean;
}

const ToggleableSelector = ({
  filterKey = "",
  techDepartment = "",
  allOptions,
  availableOptions,
  selectedOptions,
  isSkillSelector = false,
}: ToggleableSelectorProps) => {
  const dispatch = useDispatch();
  return (
    <div className={classNames.root}>
      <div className={classNames.techDepartmentSection}>
        <Stack horizontal={true} tokens={{ childrenGap: 8 }} wrap={true}>
          {allOptions
            ?.filter((section: any) =>
              (availableOptions as any).includes(section.key)
            )
            .map((option) => (
              <div
                key={option["key"]}
                className={
                  selectedOptions?.includes(option["key"])
                    ? classNames.buttonSelected
                    : classNames.button
                }
                onClick={() =>
                  dispatch(
                    isSkillSelector
                      ? setSkillFilter({
                          [filterKey]: toggleSelectorItem(
                            selectedOptions,
                            option["key"]
                          ),
                        })
                      : setFilter({
                          [techDepartment]: {
                            [filterKey]: toggleSelectorItem(
                              selectedOptions,
                              option["key"]
                            ),
                          },
                        })
                  )
                }
              >
                {option["text"]}
              </div>
            ))}
        </Stack>
      </div>
    </div>
  );
};

export default ToggleableSelector;
