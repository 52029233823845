import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { workingRoleOptions, CALENDAR_STAFFVIEW_STEP_HEIGHT } from '../../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const classNames = mergeStyleSets({
    root: {
        // width: 250,
        height: 55,
        // borderBottom: '1px solid #DBDBDB',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        selectors: {
            ":hover": {
                backgroundColor: 'rgb(232, 232, 232)'
            }
        }
    },
    iconContainer: {
        width: 24
    },
    icon: {
        color: '#000',
        fontSize: 16,
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 190,
        // marginLeft: 16,
        fontWeight: 'bold',
        fontSize: 11,
        lineHeight: 13,
        wordWrap: 'break-word'
    },
});
const StaffContainer = ({workingRole, count, name}) => {
    const imageUrlRoleStaff = workingRoleOptions.find((item) => item.key === workingRole)?.icon;
    const height = CALENDAR_STAFFVIEW_STEP_HEIGHT * count;
    return (
        <div className={classNames.root} style={{height: height}}>
            <div className={classNames.text}>
                {imageUrlRoleStaff && <div className={classNames.iconContainer}><FontAwesomeIcon icon={imageUrlRoleStaff} className={classNames.icon}/></div>}
                {name}
            </div>
            {/* <div className={classNames.iconSection}> 
                {className && text && (
                    <div className={className}>{text}</div>
                )}
            </div> */}
        </div>
    )
};

export default StaffContainer;
