import React from 'react';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ProjectTableTechDepartmentInfoCell, TablePeriodDisplay } from '../../projects/index.js';
import { connect } from 'react-redux';

const commonStationSelectorButton = {
	height: 20,
	borderRadius: 24,
	color: '#ffffff',
	background: '#006CAD',
	padding: '4px 16px',
	fontSize: 14,
	lineHeight: 20,
	margin: '8px 4px',
	cursor: 'pointer',
	selectors: {
		':hover': {
			color: '#DBDBDB',
		}
	},
};

const classNames = mergeStyleSets({
    alignItemRow: {
        display: 'flex',
        marginTop: '7px'
    },
    itemRowDir: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '7px',
        div: {
            marginBottom: '8px'
        }
    },
    button: [
		commonStationSelectorButton,
		{
			color: '#868685',
			background: '#DFDFDF',
			selectors: {
				':hover': {
					color: '#DFDFDF',
					background: '#868685'
				}
			} 
		}
	],
	buttonSelected: commonStationSelectorButton,
});

const WrapperText = ({inactive, children}) => (<div style={{color: inactive ? '#868685' : '#000'}}>{children}</div>);

const ProjectTable = ({project, sectionOptions}) => {
    const listData = [project];
    
    const columns = [
        {
            data: 'number',
            fieldName: 'hovedsagsNummer',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            isSortedDescending: false,
            key: 'hovedsagsNummer',
            maxWidth: 200,
            minWidth: 200,
            name: 'HOVEDSAG',
        },
        {
            data: 'string',
            fieldName: 'personResponsible',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            key: 'personResponsible',
            // maxWidth: 200,
            minWidth: 200,
            name: 'SAGSANSVARLIG',
        },
        {
            data: 'string',
            fieldName: 'projectType',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            key: 'projectType',
            // maxWidth: 200,
            minWidth: 200,
            name: 'AKTIVITETER',
        },
        {
            data: 'string',
            fieldName: 'projectDescription',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            key: 'projectDescription',
            // maxWidth: 200,
            minWidth: 200,
            name: 'BESKRIVELSE',
        },
        {
            data: 'string',
            fieldName: 'machine',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            key: 'machine',
            maxWidth: 200,
            minWidth: 200,
            name: 'MASKINE',
        },
        {
            data: 'string',
            fieldName: 'bemanding',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            key: 'bemanding',
            maxWidth: 200,
            minWidth: 200,
            name: 'BEMANDING',
        }
    ];

    const renderColList = (val, inactive) => {
        return val.map((m, index) => <WrapperText key={index} inactive={inactive}>{m}</WrapperText>)
    } 

    const renderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName || ''];
        switch (column.key) {
            case 'personResponsible':
                return <div className={classNames.alignItemRow}><WrapperText inactive={item.inactive}>{item?.personResponsbible?.name ?? 'UNKNOWN'}</WrapperText></div>
            case 'techDepartments':
                return <ProjectTableTechDepartmentInfoCell techDepartments={item.techDepartments} />
            case 'sectionText':
                return <div className={classNames.alignItemRow}><WrapperText inactive={item.inactive}>{sectionOptions.find((section) => section.key === item.section)?.text ?? ''}</WrapperText></div>;
            case 'machine':
                return <div className={classNames.itemRowDir}>{renderColList(item.machines, item.inactive)}</div>;
            case 'bemanding':
                return <div className={classNames.itemRowDir}>{renderColList(item.people, item.inactive)}</div>;
            default:
                return <div className={classNames.alignItemRow}><WrapperText inactive={item.inactive}>{fieldContent}</WrapperText></div>;
        }
    };

    return (
        <DetailsList
            items={listData && Array.isArray(listData) ? listData : []}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderItemColumn={renderItemColumn}
        />
    )
};

const mapStateToProps = (state) => ({
    sectionOptions: state?.defaultData?.sectionOptions
});

export default connect(mapStateToProps, null)(ProjectTable);