// Import libraries
import {
  Image,
  ImageFit,
  Persona,
  PersonaSize,
  mergeStyleSets,
} from "@fluentui/react";
import { useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";

// Import types
import { TMachine, TMachineTeamModel } from "../../../types";

// Import utils
import { ID_TARGET, SOURCE_API } from "../../../utils/constants";

// Import components
import FixPositionBubble from "../../common/FixPositionBubble";
import { TooltipForText } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { PopupTeams } from "../planning/detailedPlanning";

const classNames = mergeStyleSets({
  image: {
    marginRight: "15px",
    fontSize: 16,
  },
  iconPlusContainer: {
    position: "relative",
    left: -61,
    top: 10,
  },

  titleNameElementStyle: {
    fontWeight: "bold",
    marginRight: "16px",
    textTransform: "uppercase",
  },
  iconPlusCircle: {
    marginTop: 10,
    marginLeft: 20,
  },
  sideBarContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 36,
    minHeight: 110,
    // backgroundColor: "red",
  },
  sideBarHeader: {
    display: "flex",
    marginLeft: 65,
    height: 32,
    alignItems: "center",
    // marginTop: 12,
  },
  sideBarRightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 100,
    // marginRight: 10,
    marginLeft: -20,
  },
  sidebarIconContainer: {
    width: 100,
    display: "flex",
    alignItems: "center",
    position: "relative",
    // top: 5,
    justifyContent: "flex-end",
  },
  personaStyle: {
    width: 32,
    height: 32,
    marginRight: "10px",
  },
  functionalIcon: {
    fontSize: 18,
    height: 16,
    color: "#006CAD",
    selectors: {
      ":hover": {
        color: "#DBDBDB",
      },
    },
    cursor: "pointer",
    marginRight: "10px",
  },
});

// sidebarIconContainer,personaStyle

type THoverData = {
  text: string;
  boldText: string;
  hoverId: string;
};

type MachineSectionProps = {
  machineData: TMachine;
  allowEdit: boolean;
  onClickMachineTeam: () => void;
  showMachineTeamButton?: boolean;
};

const coinProps = {
  styles: {
    image: {
      margin: 0,
      width: 32,
    },
  },
};

const MachineSection = ({
  machineData,
  allowEdit,
  onClickMachineTeam,
  showMachineTeamButton,
}: MachineSectionProps) => {
  const [hoverData, setHoverData] = useState<THoverData | null>(null);

  const [teachingBubbleTeams, { toggle: toggleTeachingTeams }] =
    useBoolean(false);

  return (
    <>
      {/* Originally marginTop is 16 */}
      {/* style={{ marginTop: 0 }} */}
      <div className={classNames.sideBarContainer}>
        <div className={classNames.sideBarHeader}>
          <Image
            src={`${window.location.origin}/img/machineBlack.svg`}
            imageFit={ImageFit.cover}
            alt="machine"
            width={20}
            height={18}
            className={classNames.image}
          />
          {/* <div className={classNames.titleNameElementStyle}>MASKINE</div> */}
          <div className={classNames.titleNameElementStyle}>
            {/* {machineData.machineName} */}
            MASKINE
          </div>
        </div>
        <div className={classNames.sideBarRightContainer}>
          <div key={machineData.machineId}>
            {/* style={{ height: 36 }} */}
            <div>
              <div
                className={classNames.sidebarIconContainer}
                //   style={{
                //     visibility: isStaffMachineSelected(machineList)
                //       ? "visible"
                //       : "hidden",
                //   }}
              >
                {allowEdit && showMachineTeamButton ? (
                  <TooltipForText text={"Add team for whole machine section"}>
                    <FontAwesomeIcon
                      id={ID_TARGET.TEAMS}
                      // onClick={() =>
                      //   getMachineTeams(
                      //     machine.machineId,
                      //     false
                      //   )
                      // }
                      onClick={() => {
                        // toggleTeachingTeams();
                        onClickMachineTeam();
                      }}
                      icon={faUsers as IconDefinition}
                      className={classNames.functionalIcon}
                    />
                  </TooltipForText>
                ) : (
                  <></>
                )}
                <Persona
                  imageUrl={
                    machineData.imageUrl
                      ? `${SOURCE_API}${machineData.imageUrl}`
                      : ""
                  }
                  size={PersonaSize.size32}
                  coinProps={coinProps}
                  className={classNames.personaStyle}
                  onMouseOut={() => {
                    setHoverData && setHoverData(null);
                  }}
                  onMouseOver={() => {
                    setHoverData &&
                      setHoverData({
                        text: machineData.machineName,
                        boldText: "",
                        hoverId: `hover-${ID_TARGET.MACHINE}-${machineData.machineId}`,
                      });
                  }}
                  id={`hover-${ID_TARGET.MACHINE}-${machineData.machineId}`}
                />
              </div>
            </div>
          </div>
          {/* <div className={classNames.iconPlusCircle}>
            <FontAwesomeIcon
              icon={faPlusCircle as IconDefinition}
              style={{
                visibility: "hidden",
              }}
            />
          </div> */}
        </div>
      </div>
      {hoverData ? (
        <FixPositionBubble
          target={"#" + hoverData.hoverId}
          onDismiss={() => {
            setHoverData(null);
          }}
          // @ts-ignore
          closeButtonAriaLabel="Close"
        >
          <span>
            {hoverData.text + " "}
            <strong>
              {hoverData.boldText ? "(" + hoverData.boldText + ")" : ""}
            </strong>
          </span>
        </FixPositionBubble>
      ) : (
        <></>
      )}
      {teachingBubbleTeams ? (
        // <PopupTeams
        //   target={ID_TARGET.TEAMS}
        //   onAdd={(team: TMachineTeamModel) => {
        //       console.log(team)
        //       // handleSelectedTeam(team, false)
        //     }
        //   }
        //   machineData={machineTeamList}
        //   toggleTeaching={toggleTeachingTeams}
        // />
        <></>
      ) : (
        <></>
      )}
    </>
  );
};

export default MachineSection;
