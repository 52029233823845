import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import moment from "moment";
import "moment/locale/da";
import React, { Fragment, useMemo } from "react";
import { useQuery } from "react-query";
import { connect, useDispatch } from "react-redux";
import { getPlans } from "../../redux/plan/plan.actions";
import { getStaffs } from "../../redux/user/user.actions";
import {
  CALENDAR_PAGE,
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  months,
  stationOptions,
} from "../../utils/constants";
import { useQueryParams } from "../../utils/utils";
import { DayLabel, MonthLabel, WeekLabel } from "../common/calendar";
import { StaffBar } from "./planning/machineDisposition";
import { StaffContainer } from "./printPage/staff";
import { StaffVacationItem } from "./staff/index";
import { getCrewServiceSchedule } from "../../utils/service";
import { getMachines } from "../../redux/machines/machines.actions";
import StaffMachineServiceItem from "./staff/StaffMachineServiceItem";

const classNames = mergeStyleSets({
  spinner: {
    marginTop: 24,
  },
  root: {
    margin: "0px 0 0 48px",
    minHeight: "100%",
    width: 3650,
    display: "flex",
    flexDirection: "row",
  },
  titleText: {
    fontSize: 18,
    fontWeight: 700,
  },
  supervisorText: {
    marginTop: 35,
    fontSize: 12,
    fontWeight: 700,
  },
  leftContainer: {
    width: 250,
    position: "sticky",
    zIndex: 1002,
    top: 0,
    left: 0,
    marginTop: 57,
  },
  rightContainer: {
    marginTop: "40px",
  },
  machineAndCalendarContainer: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  machineContainer: {
    minHeight: "100%",
    marginTop: 14,
  },
  calendarContainer: {
    minHeight: "100%",
    width: 300,
  },
  days: {
    display: "flex",
    justifyContent: "flex-start",
  },
  dayOfWeeks: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 8,
  },
  weeks: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 4,
  },
  week: {
    minWidth: 175,
    height: 20,
    fontSize: 11,
    lineHeight: 20,
    borderRadius: 10,
  },
});

const PrintSupervisors = ({ getPlans, getStaffs, dates }) => {
  const queryParams = useQueryParams();
  const dispatch = useDispatch();
  const { data: plansData, isLoading } = useQuery("printSupervisors", () =>
    getPlans(new Date(queryParams.get("from")).getFullYear())
  );
  const { data: staffsData } = useQuery("staffs", getStaffs);
  const { data: machinesData } = useQuery("machines", dispatch(getMachines));

  const techDepartment = parseInt(queryParams.get("techDepartment"));
  const techArea = queryParams.get("techArea")
    ? queryParams.get("techArea")?.split(",").map(Number)
    : [];
  const printStart = new Date(queryParams.get("from"));
  const printEnd = new Date(queryParams.get("to"));

  const getMonthIndicator = (dates) => {
    let components = [];
    let temp = dates[0][0]["month"];
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        if (dates[i][j]["month"] === temp) {
          counter++;
        } else {
          components.push({ length: counter, label: months[temp] });
          counter = 1;
          temp = dates[i][j]["month"];
        }
      }
    }
    components.push({ length: counter, label: months[temp] });
    return components;
  };

  const filteredSupervisors = useMemo(() => {
    let filterData = [];
    if (Array.isArray(plansData) && Array.isArray(staffsData)) {
      filterData = staffsData.filter(
        (staff) =>
          !staff.inactive &&
          staff.workingRole.includes("0") &&
          techArea.includes(staff.techArea)
      );
    }
    return filterData;
  }, [staffsData, plansData]);

  const filteredStaffs = useMemo(() => {
    const result = [];

    if (plansData && plansData.length > 0) {
      plansData.forEach((plan) => {
        if (!plan.inactive) {
          plan.machineRequirements.forEach(
            (machineRequirement, indexMachineRequirement) => {
              [
                machineRequirement["drivers"],
                machineRequirement["workers"],
                machineRequirement["managers"],
              ].forEach((array) => {
                array.forEach((item) => {
                  let startDate = new Date(item.start);
                  let endDate = new Date(item.end);
                  if (
                    startDate.getTime() <= printEnd.getTime() &&
                    endDate.getTime() >= printStart.getTime()
                  ) {
                    if (
                      filteredSupervisors.some(
                        (item2) => item2.userId === item.id
                      )
                    ) {
                      let replicateStaff = null;
                      for (let i = result.length - 1; i >= 0; i--) {
                        if (result[i].id === item.id) {
                          replicateStaff = result[i];
                          break;
                        }
                      }
                      const index = replicateStaff
                        ? replicateStaff.index + 1
                        : 0;

                      const serviceSchedules = getCrewServiceSchedule(
                        item.id,
                        machinesData
                      );

                      result.push({
                        ...item,
                        starfVacations: !replicateStaff
                          ? item.starfVacations
                          : null,
                        personResponsbible: plan?.personResponsbible?.name,
                        isQuotation: plan.isQuotation,
                        projectNo: plan.projectNo,
                        projectId: plan.projectId,
                        projectName: plan.projectName,
                        color: plan.color,
                        indexMachineRequirement,
                        index,
                        crmProjectStatusCode: plan.crmProjectStatusCode,
                        serviceSchedules,
                      });
                    }
                  }
                });
              });
            }
          );
        }
      });
    }
    filteredSupervisors.forEach((item) => {
      const index = result.findIndex((item2) => item2.id === item.userId);
      if (index === -1 && item.starfVacations) {
        result.push({ ...item, id: item.userId, index: 0 });
      }
    });

    return result;
  }, [filteredSupervisors]);

  const horizontalPositionMap = useMemo(() => {
    const result = {};
    const datePositionCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        let key = `${dates[i][j]["year"]}-${dates[i][j]["month"] + 1}-${
          dates[i][j]["date"]
        }`;
        result[key] = counter;
        datePositionCorrespondence[counter] = moment(key, "YYYY-M-D").format(
          "YYYY-MM-DD[T]HH:mm:ss.SSS"
        );
        counter++;
      }
    }
    result["end"] = --counter;
    result["positionsToDates"] = datePositionCorrespondence;

    return result;
  }, [dates]);

  const verticalPositionMap = useMemo(() => {
    const result = {};
    const positionIdCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < filteredSupervisors.length; i++) {
      const tempListWithSameId = filteredStaffs.filter(
        (staff) => staff.id === filteredSupervisors[i]["userId"]
      );
      const tempIndex = counter;
      if (tempListWithSameId.length > 1) {
        for (let j = 0; j < tempListWithSameId.length; j++) {
          let flag = false;
          for (let k = j + 1; k < tempListWithSameId.length; k++) {
            if (
              !(
                new Date(tempListWithSameId[j]?.start).getTime() >
                  new Date(tempListWithSameId[k]?.end).getTime() ||
                new Date(tempListWithSameId[j]?.end).getTime() <
                  new Date(tempListWithSameId[k]?.start).getTime()
              )
            ) {
              result[filteredSupervisors[i]["userId"]] = {
                ...result[filteredSupervisors[i]["userId"]],
                [tempListWithSameId[j].index]: ++counter,
              };
              flag = true;
              break;
            }
          }
          if (!flag) {
            result[filteredSupervisors[i]["userId"]] = {
              ...result[filteredSupervisors[i]["userId"]],
              [tempListWithSameId[j].index]: tempIndex,
            };
          }
        }
      } else {
        result[filteredSupervisors[i]["userId"]] = { 0: counter };
      }
      positionIdCorrespondence[counter * CALENDAR_STEP_HEIGHT] =
        filteredSupervisors[i]["userId"];
      counter++;
    }
    result["positionsToIds"] = positionIdCorrespondence;
    return result;
  }, [
    filteredSupervisors,
    dates,
    horizontalPositionMap,
    plansData,
    filteredStaffs,
  ]);

  const calculateQuantityOfItemContainer = useMemo(() => {
    let count = 0;
    for (let i = 0; i < filteredSupervisors.length; i++) {
      const temp = verticalPositionMap[filteredSupervisors[i].userId];
      let tempList = new Set();
      for (const key in temp) {
        tempList.add(temp[key]);
      }
      tempList = Array.from(tempList);
      for (let j = 0; j < tempList.length; j++) {
        count++;
      }
    }
    return count;
  }, [filteredSupervisors, verticalPositionMap]);

  const staffItemContainer = useMemo(() => {
    const list = [];
    for (let i = 0; i < filteredSupervisors.length; i++) {
      const temp = verticalPositionMap[filteredSupervisors[i].userId];
      let tempList = new Set();
      for (const key in temp) {
        tempList.add(temp[key]);
      }
      tempList = Array.from(tempList);
      let count = 1;
      if (tempList.length >= 2) {
        count *= tempList.length;
      }
      list.push(
        <div>
          <StaffContainer
            calendarPage={CALENDAR_PAGE.DISPOSITION}
            count={count}
            key={`${filteredSupervisors[i].userId}`}
            workingRole={filteredSupervisors[i].workingRole}
            machineDocumentUrl={filteredSupervisors[i].machineDocumentUrl}
            index={`${filteredSupervisors[i].userId}`}
            name={`${filteredSupervisors[i].firstName} ${
              filteredSupervisors[i].lastName ?? ""
            }`}
            machineId={filteredSupervisors[i].machineId}
            techDepartment={filteredSupervisors[i].techDepartment}
            techArea={filteredSupervisors[i].techArea}
            imageUrl={filteredSupervisors[i]?.imageUrl}
          />
          <hr
            style={{
              position: "absolute",
              border: "0.6px solid rgb(219, 219, 219, 0.5)",
              width: CALENDAR_STEP_WIDTH * 7 * dates.length,
              margin: 0,
              height: "0.1px",
              backgroundColor: "rgb(219, 219, 219, 0.5)",
              marginLeft: "-420px",
            }}
          />
        </div>
      );
    }

    return list;
  }, [filteredSupervisors, verticalPositionMap]);

  const heightOfBackground =
    calculateQuantityOfItemContainer * CALENDAR_STAFFVIEW_STEP_HEIGHT +
    CALENDAR_STAFFVIEW_STEP_HEIGHT;

  return (
    <div id="printSupervisors" style={{ background: "#F1F1F1" }}>
      {isLoading ? null : (
        <div>
          <div className={classNames.root}>
            <div className={classNames.leftContainer}>
              <div className={classNames.titleText}>
                {stationOptions[techDepartment]["text"]} uge{" "}
                {queryParams.get("fromWeek")} - {queryParams.get("toWeek")}
              </div>
              <div className={classNames.supervisorText}>FORMAND</div>
              <div className={classNames.machineContainer}>
                {filteredSupervisors && staffItemContainer}
              </div>
            </div>
            <div className={classNames.rightContainer}>
              <div className={classNames.stickyHeader}>
                <div className={classNames.machineAndCalendarContainer}>
                  <div className={classNames.calendarContainer}>
                    <div className={classNames.weeks}>
                      {dates.length &&
                        getMonthIndicator(dates).map(
                          (monthData, indexMonth) => (
                            <MonthLabel
                              index={indexMonth}
                              key={
                                indexMonth +
                                monthData["length"] +
                                monthData["label"]
                              }
                              length={monthData["length"]}
                              label={monthData["label"]}
                            />
                          )
                        )}
                    </div>
                    <div className={classNames.weeks}>
                      {dates.length > 0 &&
                        dates.map((week, indexWeek) => (
                          <WeekLabel
                            index={indexWeek}
                            month={week[0]["month"]}
                            day={week[0]["date"]}
                            year={week[0]["year"]}
                            key={`${week[0]["month"]}-${week[0]["date"]}-${week[0]["year"]}`}
                          />
                        ))}
                    </div>
                    <div className={classNames.days}>
                      {dates.length > 0 &&
                        dates.map((week) =>
                          week.map((each) => (
                            <DayLabel
                              label={each.date}
                              date={each}
                              key={JSON.stringify(each)}
                            />
                          ))
                        )}
                    </div>
                    <div className={classNames.dayOfWeeks}>
                      {dates.length > 0 &&
                        dates.map((week) =>
                          week.map((each) => (
                            <DayLabel
                              label={each.date}
                              date={each}
                              key={JSON.stringify(each)}
                              isDaysOfWeek
                            />
                          ))
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={classNames.machineAndCalendarContainer}>
                <div className={classNames.calendarContainer}>
                  <div
                    style={{
                      height: heightOfBackground,
                      width: CALENDAR_STEP_WIDTH * 7 * dates.length,
                      position: "relative",
                      backgroundImage: 'url("./img/calendarBackground3.png")',
                      backgroundRepeat: "repeat",
                    }}
                  >
                    <div
                      className="planContainer"
                      style={{
                        position: "relative",
                        top: 0,
                        left: 0,
                        zIndex: 556,
                        width: "100%",
                        height:
                          filteredSupervisors.length * CALENDAR_STEP_HEIGHT,
                      }}
                    >
                      {Array.isArray(filteredStaffs) &&
                        filteredStaffs.map((staff, index1) => (
                          <Fragment key={`${staff.id}-${index1}`}>
                            {staff.start && staff.end && (
                              <StaffBar
                                staff={staff}
                                horizontalPositions={horizontalPositionMap}
                                verticalPositions={verticalPositionMap}
                                calendarStart={
                                  new Date(queryParams.get("from"))
                                }
                                calendarEnd={new Date(queryParams.get("to"))}
                              />
                            )}
                            {staff.starfVacations &&
                              staff?.starfVacations.length > 0 &&
                              staff.starfVacations
                                .filter(
                                  (vacation) =>
                                    new Date(vacation.start).getTime() <=
                                      printEnd.getTime() &&
                                    new Date(vacation.end).getTime() >=
                                      printStart.getTime()
                                )
                                .map((item, index2) => (
                                  <StaffVacationItem
                                    calendarPage={CALENDAR_PAGE.PRINT}
                                    viewOnly
                                    key={`${staff.id}-${index2}`}
                                    staffVacationIndex={index2}
                                    staffVacation={item}
                                    staff={staff}
                                    userId={staff.id}
                                    start={item["start"]}
                                    end={item["end"]}
                                    horizontalPositions={horizontalPositionMap}
                                    verticalPositions={verticalPositionMap}
                                    calendarStart={
                                      new Date(queryParams.get("from"))
                                    }
                                    calendarEnd={
                                      new Date(queryParams.get("to"))
                                    }
                                  />
                                ))}
                            {staff.serviceSchedules &&
                              staff?.serviceSchedules.length > 0 &&
                              staff.serviceSchedules
                                .filter(
                                  (vacation) =>
                                    new Date(vacation.start).getTime() <=
                                      printEnd.getTime() &&
                                    new Date(vacation.end).getTime() >=
                                      printStart.getTime()
                                )
                                .map((item, index2) => (
                                  <StaffMachineServiceItem
                                    calendarPage={CALENDAR_PAGE.PRINT}
                                    viewOnly
                                    key={`${staff.id}-machine-service-${index2}`}
                                    staffVacationIndex={index2}
                                    staffVacation={item}
                                    staff={staff}
                                    userId={staff.id}
                                    start={item["start"]}
                                    end={item["end"]}
                                    horizontalPositions={horizontalPositionMap}
                                    verticalPositions={verticalPositionMap}
                                    calendarStart={
                                      new Date(queryParams.get("from"))
                                    }
                                    calendarEnd={
                                      new Date(queryParams.get("to"))
                                    }
                                  />
                                ))}
                          </Fragment>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, { getPlans, getStaffs })(PrintSupervisors);
