import { Formik } from "formik";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { createMachine } from "../../redux/machines/machines.actions";
import { techDepartmentOptions } from "../../utils/constants";
import { MachineForm } from "./machines/";

const NewMachine = ({ createMachine, defaultData }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const oneOfMachineType = defaultData?.machineTypes.map((type) => type.id) ?? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 999];
  const oneOfSizeGroup = defaultData?.sizeGroupOptions.map((sizeGroup) => sizeGroup.key) ?? [0, 1, 2, 3, 4, 5, 6, 7];
  const validationSchema = Yup.object({
    imageUrl: Yup.string("Upload image").nullable(),
    machineDocumentUrl: Yup.lazy(val => (Array.isArray(val) ? Yup.array().of(Yup.string()) : Yup.string().nullable())),
    machineNo: Yup.string("Enter a name").required("MachineId is required"),
    machineName: Yup.string("Enter a name").required("Name is required"),
    // machineType: Yup.mixed()
    //   .oneOf(oneOfMachineType)
    //   .required("Machine type is required"),
    machineType: Yup.string("Select machine type")
      .required("Machine type is required"),
    sizeGroup: Yup.mixed()
      .oneOf(oneOfSizeGroup)
      .required("Size Group is required"),
    techDepartments: Yup.string("Select department")
      .required("Department is required"),
    techAreas: Yup.string("Select department")
      .required("Disponent is required"),
  });

  const mutationCreateMachine = useMutation(
    (machine) => createMachine({ ...machine, model: "Model test" }),
    {
      onSuccess: (data, variables) => {
        if (data) {
          queryClient.invalidateQueries("machines");
          queryClient.refetchQueries();
          history.push("/machines");
        }
      },
    }
  );

  const formSubmit = (data) => {
    const machine = {
      imageUrl: data.imageUrl,
      machineDocumentUrl: data.machineDocumentUrl ? (Array.isArray(data.machineDocumentUrl) ? data.machineDocumentUrl : [data.machineDocumentUrl]) : null,
      machineNo: data.machineNo,
      machineName: data.machineName,
      machineType: data.machineType,
      sizeGroup: data.sizeGroup,
      techDepartments: data.techDepartments,
      techAreas: data.techAreas,
    };
    mutationCreateMachine.mutate(machine);
  };

  return (
    <Formik
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={formSubmit}>
      {(props) => <MachineForm history={history} {...props} />}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  defaultData: state.defaultData
});

export default connect(mapStateToProps, { createMachine })(NewMachine);
