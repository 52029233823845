import React from 'react';
import { useSelector } from "react-redux";
import { Header, SideMenuSmall } from '../common/';
import { ProjectTable } from './projects/';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import ScreenSizeAndPosition from '../common/ScreenSizeAndPosition';
import SearchOverlay from '../common/search/SearchOverlay';

const Projects = ({scrollY}) => {
    const showSidebar = useSelector((state) => state.app.showSidebar);
    const { openSearchOverlay } = useSelector(state => state.app);
    const responsiveMarginLeft = showSidebar ? 144 : 40;
    const classNames = mergeStyleSets({
        root: {
            display: 'flex',
            flexDirection: 'row',
        },
        pageContentContainer: {
            background: '#F1F1F1',
            height: '95vh',
            width: '100%',
    
        },
        pageInnerContainer: {
            margin: `40px 124px 40px ${responsiveMarginLeft}px`,
            transition: 'all 0.5s ease',
        },
        buttonStack: {
            marginBottom: 32
        }
    });
    return (
        <div className={classNames.root}>
            {openSearchOverlay && <SearchOverlay/>}
            <ScreenSizeAndPosition><SideMenuSmall scrollY={scrollY}/></ScreenSizeAndPosition>
            <div className={classNames.pageContentContainer}>
                <div className={classNames.pageInnerContainer}>
                    <ScreenSizeAndPosition>
                        <Header title='Projekter' subTitle='OVERBLIK OVER PROJEKTER' />
                        <ProjectTable/>
                    </ScreenSizeAndPosition>
                </div>
            </div>
        </div>   
    )
}

export default Projects;