// Import libraries
import {
  mergeStyleSets,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

// Import actions
import {
  deleteSkill,
  getSkills,
  setFilter,
  updateSkills,
} from "../../redux/skill/skill.actions";

// Import components
import { Header, SideMenuSmall } from "../common";
import ScreenSizeAndPosition from "../common/ScreenSizeAndPosition";
import SearchOverlay from "../common/search/SearchOverlay";
import SkillsTable from "./settings/SkillsTable";

// Import utils
import { hasEditRight } from "../../utils/utils";
import {
  SKILL_TYPE_OPTIONS,
  SKILLS_QUERY_KEY,
  techDepartmentOptions,
} from "../../utils/constants";

// Import types
import { NewSkill, Skill } from "../../types/settings";
import { SettingPageFilter } from "./settings/SettingPageFilter";

const SETTINGS_PIVOT_KEYS = {
  SKILLS: "SKILLS",
};

const Settings = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [selectedKey, setSelectedKey] = useState(SETTINGS_PIVOT_KEYS.SKILLS);

  // @ts-ignore
  const showSidebar = useSelector((state) => state.app.showSidebar);
  // @ts-ignore
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  //  @ts-ignore
  const { openSearchOverlay } = useSelector((state) => state.app);
  const filter = useSelector((state: any) => state?.skill?.filter);

  const allowEdit = hasEditRight(userRoles);

  // Query and Mutations
  const {
    data: skillsData,
    isFetching: isLoadingGetSkills,
    refetch: refetchSkills,
  } = useQuery<Skill[], Error>(SKILLS_QUERY_KEY, () => getSkills(dispatch), {
    initialData: [],
    staleTime: Infinity,
  });

  const { mutate: mutateUpdateSkills, isLoading: isMutateUpdateSkillsLoading } =
    useMutation(
      (skills: (Skill | NewSkill)[]) => updateSkills(skills, dispatch),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(SKILLS_QUERY_KEY);
          refetchSkills();
        },
      }
    );

  const { mutate: mutateDeleteSkill, isLoading: isMutateDeleteSkillLoading } =
    useMutation((skillId: string) => deleteSkill(skillId, dispatch), {
      onSuccess: () => {
        queryClient.invalidateQueries(SKILLS_QUERY_KEY);
        refetchSkills();
      },
    });

  // We are creating a state for a add new skill function
  // because I want to place the logic of the add new skill inside skills table
  // However the button is placed ui wise in the settings page by design
  const [addNewSkilFunc, setAddNewSkillFunc] = useState<() => void>();

  const isLoading = useMemo(
    () =>
      isLoadingGetSkills &&
      isMutateUpdateSkillsLoading &&
      isMutateDeleteSkillLoading,
    [
      isLoadingGetSkills,
      isMutateUpdateSkillsLoading,
      isMutateDeleteSkillLoading,
    ]
  );

  useEffect(() => {
    if (!filter.selectedTypes || !filter.selectedSections) {
      dispatch(
        setFilter({
          selectedTypes: SKILL_TYPE_OPTIONS.map((type) => type.key),
          selectedSections: techDepartmentOptions.map((type) => type.key),
        })
      );
    }
  }, [dispatch, filter]);

  useEffect(() => {
    queryClient.invalidateQueries(SKILLS_QUERY_KEY);
    refetchSkills();
  }, [queryClient, refetchSkills]);

  const realPageWidth = document.documentElement.offsetWidth;
  const responsiveMarginLeft = showSidebar ? 144 : 40;
  const advanceResponsiveMarginLeft = showSidebar
    ? realPageWidth > 1000
      ? 144
      : 124
    : realPageWidth > 1000
    ? 40
    : 20;
  const responsiveMarginLeftCalendar = showSidebar
    ? realPageWidth > 1000
      ? 124
      : 104
    : realPageWidth > 1000
    ? 20
    : 0;

  const classNames = mergeStyleSets({
    root: {
      display: "flex",
      flexDirection: "row",
      background: "#F1F1F1",
      // width: '100%'
    },
    pageContentContainer: {
      height: "100vh",
      width: "100%",
    },
    pageInnerContainer: {
      margin: `40px 124px 40px ${responsiveMarginLeft}px`,
      transition: "all 0.5s ease",
    },
    responsiveContainer: {
      marginLeft: advanceResponsiveMarginLeft,
      transition: "all 0.5s ease",
      width: `calc(100vw - ${advanceResponsiveMarginLeft + 22}px)`,
    },
    responsiveCalendarContainer: {
      marginLeft: responsiveMarginLeftCalendar,
      transition: "all 0.5s ease",
    },
    badge: {
      background: "#006CAD",
      color: "#ffffff",
      fontSize: 11,
      textAlign: "center",
      lineHeight: 20,
      height: 20,
      width: 20,
      position: "absolute",
      top: 2,
      right: -9,
      borderRadius: "50%",
    },
    contentContainer: {
      marginTop: 44,
    },
  });

  const handleUpdateSkills = useCallback(
    (skills: (Skill | NewSkill)[]) => {
      mutateUpdateSkills(skills);
    },
    [mutateUpdateSkills]
  );

  const handleDeleteSkill = useCallback(
    (id: string) => {
      mutateDeleteSkill(id);
    },
    [mutateDeleteSkill]
  );

  const renderContent = useCallback(() => {
    switch (selectedKey) {
      case SETTINGS_PIVOT_KEYS.SKILLS:
        return (
          <SkillsTable
            setAddNewSkillFunc={setAddNewSkillFunc}
            canEdit={allowEdit}
            skills={skillsData as Skill[]}
            onUpdatedSkills={handleUpdateSkills}
            onDeleteSkill={handleDeleteSkill}
          />
        );
      default:
        return <></>;
    }
  }, [
    selectedKey,
    allowEdit,
    skillsData,
    handleUpdateSkills,
    handleDeleteSkill,
  ]);

  return (
    <div className={classNames.root}>
      {openSearchOverlay && <SearchOverlay />}
      <ScreenSizeAndPosition>
        <SideMenuSmall />
      </ScreenSizeAndPosition>
      <div className={classNames.pageContentContainer}>
        <div className={classNames.pageInnerContainer}>
          <ScreenSizeAndPosition>
            <Header title="Settings" subTitle="" />
          </ScreenSizeAndPosition>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Pivot
              selectedKey={selectedKey}
              onLinkClick={(item) => {
                item?.props?.itemKey && setSelectedKey(item?.props?.itemKey);
              }}
            >
              <PivotItem headerText="SKILLS" itemKey={selectedKey} />
            </Pivot>
          </div>
          <Stack horizontal tokens={{ padding: 10 }}>
            <ScreenSizeAndPosition>
              <SettingPageFilter />
              <PrimaryButton
              text="Opret Ny"
              onClick={() => {
                if (addNewSkilFunc) {
                  addNewSkilFunc();
                }
              }}
              style={{
                width: 102,
                height: 40,
                borderRadius: 4,
                boxSizing: "border-box",
              }}
              styles={{
                label: {
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: "Verdana",
                  lineHeight: 14,
                },
              }}
            />
            </ScreenSizeAndPosition>
          </Stack>
          {!isLoading && (
            <div className={classNames.contentContainer}>{renderContent()}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
