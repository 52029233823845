import { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Header, SideMenuSmall } from "../common/";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { StaffTable, MissingInfoStaffTable } from "./staff/";
import StaffCalendar from "./staff/StaffCalendar";
import { useQuery } from "react-query";
import {
  getLastSync,
  getUsersMissingInfo,
} from "../../redux/user/user.actions";
import {
  changeSelectedPivot,
  toggleInactiveMissingUsers,
} from "../../redux/app/app.actions";
import { COLOR_CONS_HEX, STAFF_KEY_SELECT_PIVOT } from "../../utils/constants";
import ScreenSizeAndPosition from "../common/ScreenSizeAndPosition";
import SearchOverlay from "../common/search/SearchOverlay";

const Staff = () => {
  const showSidebar = useSelector((state) => state.app.showSidebar);
  const { openSearchOverlay } = useSelector((state) => state.app);
  const realPageWidth = document.documentElement.offsetWidth;
  const responsiveMarginLeft = showSidebar ? 144 : 40;
  const advanceResponsiveMarginLeft = showSidebar
    ? realPageWidth > 1000
      ? 144
      : 124
    : realPageWidth > 1000
    ? 40
    : 20;
  const responsiveMarginLeftCalendar = showSidebar
    ? realPageWidth > 1000
      ? 124
      : 104
    : realPageWidth > 1000
    ? 20
    : 0;
  const classNames = mergeStyleSets({
    root: {
      display: "flex",
      flexDirection: "row",
      background: "#F1F1F1",
      // width: '100%'
    },
    pageContentContainer: {
      height: "100vh",
      width: "100%",
    },
    pageInnerContainer: {
      margin: `40px 124px 40px ${responsiveMarginLeft}px`,
      transition: "all 0.5s ease",
    },
    responsiveContainer: {
      marginLeft: advanceResponsiveMarginLeft,
      transition: "all 0.5s ease",
      width: `calc(100vw - ${advanceResponsiveMarginLeft + 22}px)`,
    },
    responsiveCalendarContainer: {
      marginLeft: responsiveMarginLeftCalendar,
      transition: "all 0.5s ease",
    },
    badge: {
      background: "#006CAD",
      color: "#ffffff",
      fontSize: 11,
      textAlign: "center",
      lineHeight: 20,
      height: 20,
      width: 20,
      position: "absolute",
      top: 2,
      right: -9,
      borderRadius: "50%",
    },
  });
  const dispatch = useDispatch();
  const selectedKey = useSelector((state) => state.app.pivot.staff);
  const showInactiveMissingUser = useSelector(
    (state) => state.app.showInactiveMissingUser
  );
  const { data: usersMissingInfoData } = useQuery(
    "usersMissingInfo",
    dispatch(getUsersMissingInfo())
  );

  const { data: lastSyncTime } = useQuery("lastSyncTime", getLastSync);

  const filteredList = useMemo(() => {
    if (usersMissingInfoData && Array.isArray(usersMissingInfoData)) {
      return usersMissingInfoData.filter((el) => {
        return showInactiveMissingUser || !el.inactive;
      });
    }
  }, [usersMissingInfoData, showInactiveMissingUser]);

  let content = null;
  switch (selectedKey) {
    case STAFF_KEY_SELECT_PIVOT.STAFF_TABLE:
      content = (
        <div className={classNames.responsiveContainer}>
          <StaffTable />
        </div>
      );
      break;

    case STAFF_KEY_SELECT_PIVOT.STAFF_MISSING_INFO:
      content = (
        <div className={classNames.responsiveContainer}>
          <ScreenSizeAndPosition>
            <MissingInfoStaffTable
              toggleShowActiveUsers={() => {
                dispatch(toggleInactiveMissingUsers());
              }}
              showingInactiveUsers={showInactiveMissingUser}
              usersMissingInfoData={filteredList}
            />
          </ScreenSizeAndPosition>
        </div>
      );
      break;

    case STAFF_KEY_SELECT_PIVOT.STAFF_CALENDAR:
      content = (
        <div className={classNames.responsiveCalendarContainer}>
          <StaffCalendar />
        </div>
      );
      break;

    default:
      break;
  }
  const missingInfoText = (usersLength) => (
    <div>
      <div>BRUGER MANGLER INFO</div>
      <div className={classNames.badge}>{usersLength}</div>
    </div>
  );
  return (
    <div className={classNames.root}>
      {openSearchOverlay && <SearchOverlay />}
      <ScreenSizeAndPosition>
        <SideMenuSmall />
      </ScreenSizeAndPosition>
      <div className={classNames.pageContentContainer}>
        <div className={classNames.pageInnerContainer}>
          <ScreenSizeAndPosition>
            <Header title="Bemanding" subTitle="OVERBLIK OVER MANDSKAB" />
          </ScreenSizeAndPosition>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Pivot
              selectedKey={selectedKey}
              onLinkClick={(item) =>
                dispatch(changeSelectedPivot(item.props.itemKey, "staff"))
              }
            >
              <PivotItem
                styles={{
                  root: { marginLeft: realPageWidth > 1000 ? 0 : -10 },
                }}
                headerText={"MANDSKAB"}
                itemKey={STAFF_KEY_SELECT_PIVOT.STAFF_TABLE}
              />
              {filteredList?.length && (
                <PivotItem
                  headerText={missingInfoText(filteredList.length)}
                  itemKey={STAFF_KEY_SELECT_PIVOT.STAFF_MISSING_INFO}
                />
              )}
              <PivotItem
                headerText="FERIE OG ANDET FRAVÆR"
                itemKey={STAFF_KEY_SELECT_PIVOT.STAFF_CALENDAR}
              />
            </Pivot>
            {lastSyncTime ? (
              <div>
                Last sync:
                <span
                  style={{
                    color: "#006CAD",
                    fontWeight: 400,
                    fontSize: 12,
                    margin: 5,
                  }}
                >
                  {lastSyncTime} minutes ago
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {content}
      </div>
    </div>
  );
};

export default Staff;
