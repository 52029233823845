import moment from "moment";
import "moment/locale/da";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import {
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
} from "../../../../utils/constants";
import { getStringifiedMachine } from "../../../../redux/machines/machine.selector";

const PlanPlaceholder = ({
  machine,
  start,
  end,
  horizontalPositions,
  verticalPositions,
  calendarStart,
  calendarEnd,
  renderDependencies: propRenderDependencies,
}) => {
  const calendarState = useSelector(getStringifiedMachine);

  const displayWeekView = useSelector((state) => state.machine.displayWeekView);
  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [width, setWidth] = useState(250);
  const [display, setDisplay] = useState(false);
  const ref = useRef(null);
  // const renderDependencies = calendarState + JSON.stringify(verticalPositions);

  const renderDependencies =
    propRenderDependencies || calendarState + JSON.stringify(verticalPositions);

  if (!ref.current) ref.current = renderDependencies;
  const shouldComponentRerender = ref.current !== renderDependencies;

  useEffect(() => {
    if ((!x && !y) || shouldComponentRerender) {
      if (shouldComponentRerender) ref.current = renderDependencies;
      let yPosition = verticalPositions[machine["machineId"]];
      if (typeof yPosition === "undefined") {
        setDisplay(false);
        return;
      }

      const keyStart = moment(start).utcOffset(0).format("YYYY-M-D");
      const keyEnd = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");

      let xPosition = horizontalPositions[keyStart];
      let endValue = horizontalPositions[keyEnd];

      if (
        new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
        new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime() &&
        typeof xPosition === "undefined" &&
        typeof endValue === "undefined"
      ) {
        xPosition = 0;
        endValue = horizontalPositions["end"];
      }

      if (
        new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
        typeof endValue !== "undefined"
      ) {
        xPosition = 0;
      }

      if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
        endValue = horizontalPositions["end"];
      }

      setY(yPosition * CALENDAR_STEP_HEIGHT);
      if (typeof xPosition !== "undefined") {
        setX(xPosition * calendarStepWidth);
        setWidth((endValue - xPosition) * calendarStepWidth);
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    }
  }, [
    horizontalPositions,
    verticalPositions,
    calendarStart,
    calendarEnd,
    start,
    end,
    calendarStepWidth,
  ]);

  return (
    display &&
    width > 0 && (
      <Rnd
        style={{ zIndex: 100002, top: 0, left: 0, display: "absolute" }}
        size={{ width: width, height: CALENDAR_STEP_HEIGHT }}
        position={{ x: x, y: y }}
        enableResizing={{
          left: false,
          right: false,
        }}
        resizeGrid={[calendarStepWidth, CALENDAR_STEP_HEIGHT]}
        dragGrid={[calendarStepWidth, CALENDAR_STEP_HEIGHT]}
        dragAxis="both"
        bounds=".planContainer"
        dragHandleClassName="dragHandle"
      >
        <div
          style={{
            background: "#fff",
            display: "flex",
            marginTop: 5,
            height: CALENDAR_STEP_HEIGHT - 8,
            borderRadius: 4,
          }}
        ></div>
      </Rnd>
    )
  );
};

export default PlanPlaceholder;
