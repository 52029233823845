import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';

const classNames = mergeStyleSets({
    root: {
        width: 250,
        height: 72,
        display: 'flex',
        justifyContent: 'flex-start'
    },
});

const StationSelector = () => {
    return (
        <div className={classNames.root}>
            
        </div>
    )
}

export default StationSelector;