import { DefaultDataTypes } from './defaultData.type';

const initialState = {
    machineTypes: null,
    machineTypeOptions: null,
    sizeGroupOptions: null,
    sectionOptions: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case DefaultDataTypes.SET_DEFAULT_DATA:
            return action.payload;
        default:
            return state;
    }
};

export default reducer;