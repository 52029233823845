import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getProjects } from "../../../redux/project/project.actions";
import { changeSectionFilter } from "../../../redux/user/user.actions";
import { useHistory } from "react-router-dom";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import {
  ProjectControls,
  TablePeriodDisplay,
  ProjectTableInfoCell,
  ProjectTableTechDepartmentInfoCell,
} from "./";
import { ColorIndicator } from "../../common/";
import { techDepartmentOptions } from "../../../utils/constants";
import {
  copyAndSort,
  elementObserver,
  getTextWidth,
  hasEditRight,
} from "../../../utils/utils";
import {
  defaultButtonStyles,
  headerRightButtonStyles,
  primaryButtonStyles,
} from "../../../utils/theme";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Stack, PrimaryButton, values, DefaultButton } from "@fluentui/react";
import { TooltipForText } from "../../common";
import { buttonStyle } from "../../common/calendar/ButtonStyles";
import {
  setTableSort,
  toggleInactiveProject,
} from "../../../redux/app/app.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/pro-regular-svg-icons";
import ToggleButton from "../../common/ToggleButton";

const NOT_SORTING_FIELDS = ["color", "equipment", "action", "quotation"];

const FILTER_TYPE = {
  Techsection: "Techsection",
  Projectsection: "Projectsection",
};

const commonStationSelectorButton = {
  height: 20,
  borderRadius: 24,
  color: "#ffffff",
  background: "#006CAD",
  padding: "4px 16px",
  fontSize: 14,
  lineHeight: 20,
  margin: "8px 4px",
  cursor: "pointer",
  selectors: {
    ":hover": {
      color: "#DBDBDB",
    },
  },
};
const classNames = mergeStyleSets({
  alignItemRow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  button: [
    commonStationSelectorButton,
    {
      color: "#868685",
      background: "#DFDFDF",
      selectors: {
        ":hover": {
          color: "#DFDFDF",
          background: "#868685",
        },
      },
    },
  ],
  buttonSelected: commonStationSelectorButton,
  unSelectedIcon: {
    marginRight: 2,
    fontSize: 16,
    color: "#006CAD",
  },
  selectedIcon: {
    marginRight: 2,
    fontSize: 16,
    color: "#fff",
  },
});

const WrapperText = ({ inactive, children }) => (
  <div style={{ color: inactive ? "#868685" : "#000" }}>{children}</div>
);

const ProjectTable = ({
  getProjects,
  techDepartmentDefaultFilter,
  sectionOptions,
  regions,
  selectedTechDepartmentFilter,
  changeSectionFilter,
  scrollY,
  tableSort,
}) => {
  const dispatch = useDispatch();
  const { crmProjectStatuscode } = useSelector((state) => state.defaultData);
  const { showInactiveProject } = useSelector((state) => state.app);
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const [showTooltip, setShowTooltip] = useState({});
  const allowEdit = hasEditRight(userRoles);
  const realPageWidth = document.documentElement.offsetWidth;
  const responsiveMarginLeft = realPageWidth > 1000 ? 0 : -20;

  const { data } = useQuery(
    ["projects", showInactiveProject],
    () => getProjects(null, null, showInactiveProject),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [currentFilter, setCurrentFilter] = useState(FILTER_TYPE.Techsection);

  const [listData, setListData] = useState(data || []);

  const techDepartmentFilter = useMemo(() => {
    if (data && data?.length) {
      let filter = selectedTechDepartmentFilter
        ? techDepartmentOptions.map((item) => ({
            ...item,
            isOn: selectedTechDepartmentFilter.includes(item.key),
          }))
        : techDepartmentOptions.map((item) => ({
            ...item,
            isOn: item.key === techDepartmentDefaultFilter,
          }));

      if (currentFilter === FILTER_TYPE.Techsection) {
        filter = filter.filter((el) =>
          data.find((project) => project.techDepartments.includes(el.key))
        );
      } else {
        filter = filter.filter((el) =>
          data.find((project) => project.section === el.key)
        );
      }

      return filter;
    }

    return [];
  }, [data, selectedTechDepartmentFilter, currentFilter]);

  const techDepartmentFilterRef = useRef(null);
  if (
    !techDepartmentFilterRef.current ||
    JSON.stringify(techDepartmentFilter) !==
      JSON.stringify(techDepartmentFilterRef.current)
  ) {
    techDepartmentFilterRef.current = techDepartmentFilter;
  }

  const onChangeSectionFilter = (key) => {
    const tempArray = techDepartmentFilter
      .filter((item) => item.isOn)
      .map((item) => item.key);
    tempArray.indexOf(key) === -1
      ? tempArray.push(key)
      : tempArray.splice(tempArray.indexOf(key), 1);
    changeSectionFilter(tempArray);
  };

  useEffect(() => {
    if (data) {
      const techSectionActive = techDepartmentFilter.some((el) => el.isOn);

      let newListDisplay =
        currentFilter === FILTER_TYPE.Techsection
          ? techSectionActive
            ? data.filter((project) =>
                techDepartmentFilter.some(
                  (role) =>
                    role.isOn &&
                    (project.techDepartments.some(
                      (item) => item === role.key
                    ) ||
                      (!techSectionActive &&
                        !project.techDepartments.length)) &&
                    (showInactiveProject || !project.inactive)
                )
              )
            : data.filter((project) => {
                return (
                  !project.techDepartments.length &&
                  (showInactiveProject || !project.inactive)
                );
              })
          : data.filter((project) =>
              techDepartmentFilter.some(
                (role) =>
                  role.isOn &&
                  project.section === role.key &&
                  (showInactiveProject ? true : !project.inactive)
              )
            );

      newListDisplay
        .sort((a, b) => (!a.inactive && b.inactive ? -1 : 0))
        .map((item) => ({
          ...item,
          startTimestamp: new Date(item.start).getTime(),
        }));
      if (tableSort.projectTable) {
        const { columnName, isSortedDescending } = tableSort.projectTable;
        newListDisplay = copyAndSort(
          newListDisplay,
          columnName,
          isSortedDescending
        );
        setColumns((prevColumns) =>
          prevColumns.map((col) => {
            col.isSorted = col.key === columnName;
            if (col.isSorted) {
              col.isSortedDescending = isSortedDescending;
            }
            return col;
          })
        );
      }
      setListData(newListDisplay);
    }
  }, [
    data,
    techDepartmentFilter,
    techDepartmentFilterRef.current,
    showInactiveProject,
    currentFilter,
  ]);

  const onClickSorting = (e, column) => {
    if (NOT_SORTING_FIELDS.includes(column.fieldName)) {
      return;
    }
    let isSortedDescending = column.isSortedDescending;
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }
    dispatch(
      setTableSort({
        key: "projectTable",
        value: { columnName: column.fieldName, isSortedDescending },
      })
    );
    let sortedItems = copyAndSort(
      listData,
      column.fieldName,
      isSortedDescending
    );
    setListData(sortedItems);
    setColumns((prevColumns) =>
      prevColumns.map((col) => {
        col.isSorted = col.key === column.key;
        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }
        return col;
      })
    );
  };
  const history = useHistory();

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName || ""];

    switch (column.key) {
      case "action":
        // 1270
        return <ProjectControls project={item} history={history} />;
      case "equipment":
        // 1270
        // return !item.inactive || (item.inactive && showInactiveProject) ? (
        return !item.inactive ? (
          <ProjectTableInfoCell
            isQuotation={item.isQuotation}
            projectDescription={item.projectDescription}
            geoCoordinate={item.geoCoordinate}
            machineCount={
              item.machineRequirementCount ? item.machineRequirementCount : 0
            }
            history={history}
            projectId={item.crmProjectId || item.projectId}
            missingRequiredInfo={item.missingRequiredInfo}
          />
        ) : null;
      case "color":
        return <ColorIndicator colorCode={item.color} />;
      case "period":
        return (
          <WrapperText inactive={item.inactive}>
            <TablePeriodDisplay
              start={new Date(item.start)}
              end={new Date(item.end)}
            />
          </WrapperText>
        );
      case "techDepartments":
        return (
          <ProjectTableTechDepartmentInfoCell
            techDepartments={item.techDepartments}
          />
        );
      case "sectionText":
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>
              {regions.find((region) => region.sections.includes(item.section))
                ?.name || ""}
            </WrapperText>
          </div>
        );
      case "projectName":
        const observer = elementObserver(`#Project-${item.projectId}`);
        observer.then((element) => {
          const containerWidth =
            element.parentElement.parentElement.getBoundingClientRect().width;
          const textWidth = getTextWidth(
            item.projectName,
            "normal 14px Verdana"
          );
          if (textWidth > containerWidth) {
            setShowTooltip((values) => ({ ...values, [item.projectId]: true }));
          }
        });
        return (
          <TooltipForText
            text={showTooltip[item.projectId] ? item.projectName : ""}
            noWidth
          >
            <div
              id={`Project-${item.projectId}`}
              style={{ color: item.inactive ? "#868685" : "#000", height: 30 }}
              className={classNames.alignItemRow}
            >
              {item.projectName}
            </div>
          </TooltipForText>
        );
      case "crmStatusCode":
        const value = crmProjectStatuscode.find(
          (el) => el.key == item.crmStatusCode
        )?.text;
        return <div>{value}</div>;
      default:
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>{fieldContent}</WrapperText>
          </div>
        );
    }
  };

  const initColumns = [
    {
      data: "number",
      fieldName: "color",
      isPadded: true,
      isRowHeader: true,
      key: "color",
      maxWidth: 30,
      minWidth: 30,
      name: "",
    },
    {
      data: "number",
      fieldName: "hovedsagsNummer",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: "hovedsagsNummer",
      maxWidth: 128,
      minWidth: 128,
      name: "HOVEDSAG",
    },
    {
      data: "number",
      fieldName: "projectNo",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: "projectNo",
      maxWidth: 128,
      minWidth: 128,
      name: "ARKIVERINGSSAG",
    },
    {
      data: "number",
      fieldName: "crmStatusCode",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: "crmStatusCode",
      maxWidth: 128,
      minWidth: 128,
      name: "STATUS",
    },
    {
      data: "string",
      fieldName: "projectName",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: false,
      key: "projectName",
      maxWidth: 200,
      minWidth: 100,
      name: "SAGSNAVN",
    },
    {
      data: "string",
      fieldName: "section",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "section",
      maxWidth: 50,
      minWidth: 50,
      name: "SEKTIONSNUMMER",
      onColumnClick: () => {},
    },
    {
      data: "string",
      fieldName: "section",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "sectionText",
      maxWidth: 50,
      minWidth: 50,
      name: "REGION",
    },
    {
      data: "string",
      fieldName: "startTimestamp", // period
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "period",
      maxWidth: 200,
      minWidth: 150,
      name: "PRODUCTION PERIODE",
    },
    {
      data: "string",
      fieldName: "projectType",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "projectType",
      maxWidth: 100,
      minWidth: 100,
      name: "AKTIVITETER",
    },
    {
      data: "string",
      fieldName: "techDepartments",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "techDepartments",
      maxWidth: 144,
      minWidth: 144,
      name: "TECHSECTION",
    },
    {
      data: "number",
      fieldName: "equipment",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "equipment",
      maxWidth: 150,
      minWidth: 150,
      name: "MORE INFO",
    },
  ];

  if (allowEdit) {
    initColumns.push({
      data: "number",
      fieldName: "action",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "action",
      maxWidth: 40,
      minWidth: 40,
      name: "ACTION",
    });
  }

  const [columns, setColumns] = useState(initColumns);

  const renderFilterToggles = () => {
    let content = <></>;

    if (currentFilter === FILTER_TYPE.Projectsection) {
      content = (
        <>
          <DefaultButton
            onClick={() => {
              setCurrentFilter(FILTER_TYPE.Techsection);
            }}
            styles={defaultButtonStyles}
          >
            Techsection
          </DefaultButton>
          <PrimaryButton
            onClick={() => {
              setCurrentFilter(FILTER_TYPE.Projectsection);
            }}
            styles={primaryButtonStyles}
          >
            Projectsection
          </PrimaryButton>
        </>
      );
    } else {
      content = (
        <>
          <PrimaryButton
            onClick={() => {
              setCurrentFilter(FILTER_TYPE.Techsection);
            }}
            styles={primaryButtonStyles}
          >
            Techsection
          </PrimaryButton>
          <DefaultButton
            onClick={() => {
              setCurrentFilter(FILTER_TYPE.Projectsection);
            }}
            styles={defaultButtonStyles}
          >
            Projectsection
          </DefaultButton>
        </>
      );
    }

    return (
      <div
        style={{
          gap: 5,
          display: "flex",
          marginRight: 20,
        }}
      >
        {content}
      </div>
    );
  };

  return (
    <div
      style={{ marginLeft: responsiveMarginLeft, transition: "all 0.5s ease" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0 15px 0",
        }}
      >
        <Stack horizontal="true" tokens={{ childrenGap: 10 }} wrap={true}>
          {techDepartmentFilter.map((option, index) => (
            <div
              key={option["key"]}
              className={
                option.isOn ? classNames.buttonSelected : classNames.button
              }
              onClick={() => {
                onChangeSectionFilter(option["key"]);
              }}
            >
              {option["text"]}
            </div>
          ))}
        </Stack>

        <div style={headerRightButtonStyles(realPageWidth, scrollY)}>
          <Stack
            horizontal
            horizontalAlign="end"
            tokens={{ childrenGap: 10 }}
            className={classNames.buttonStack}
          >
            {renderFilterToggles()}
            <TooltipForText
              text={
                showInactiveProject
                  ? "Showing inactive project"
                  : "Hiding inactive project"
              }
              noWidth
            >
              <DefaultButton
                styles={buttonStyle(showInactiveProject)}
                onClick={() => {
                  dispatch(toggleInactiveProject());
                }}
              >
                <FontAwesomeIcon
                  icon={faPowerOff}
                  className={
                    showInactiveProject
                      ? classNames.selectedIcon
                      : classNames.unSelectedIcon
                  }
                />
              </DefaultButton>
            </TooltipForText>
            {allowEdit && (
              <PrimaryButton
                text="OPRET NYT"
                onClick={() => {
                  history.push("/new-project");
                }}
                styles={primaryButtonStyles}
              />
            )}
          </Stack>
        </div>
      </div>
      <DetailsList
        styles={{ root: { overflow: "inherit" } }}
        items={listData && Array.isArray(listData) ? listData : []}
        compact={false}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onRenderItemColumn={renderItemColumn}
        onColumnHeaderClick={onClickSorting}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  techDepartmentDefaultFilter:
    state?.user?.user?.defaultFilter?.techDepartmentFilter,
  sectionOptions: state?.defaultData?.sectionOptions,
  regions: state?.defaultData?.regions,
  selectedTechDepartmentFilter: state?.user?.user?.techDepartmentFilter,
  tableSort: state?.app?.tableSort,
});

export default connect(mapStateToProps, { getProjects, changeSectionFilter })(
  ProjectTable
);
