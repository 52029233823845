import React from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

// toggle false: switch on left
// toggle true: switch on right
const classNames = (toggle) =>
    mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        toggle: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            border: "1px solid #006CAD",
            background: "#006CAD",
            borderRadius: '10px',
            height: 18,
            width: 38,
            cursor: "pointer",
            margin: '0px 10px 0px 10px',
            transition: "all 0.1s ease",
            '&:hover': {
                backgroundColor: 'rgb(0, 81, 132)',
            }
        },
        switch: {
            height: 12,
            width: 12,
            borderRadius: '50%',
            background: 'rgb(241, 241, 241)',
            position: "absolute",
            transform: toggle ? "translate(84%, 0)" : "translate(-84%, 0)",
            transition: "all 0.1s ease",
            zIndex: 1,
        },
        text: {
            zIndex: 2,
        },
    });

const ToggleButtonPill = (props) => {
    const { toggle, onClick, leftText, rightText } = props;
    const classes = classNames(toggle);
    return (
        <div className={classes.container}>
            <div className={classes.text}>{leftText}</div>
            <div onClick={onClick} className={classes.toggle}>
                <div className={classes.switch} />
            </div>
            <div className={classes.text}>{rightText}</div>
        </div>
    );
};

export default ToggleButtonPill;
