import React, { Fragment } from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import moment from 'moment';
import 'moment/locale/da';
import { CALENDAR_STEP_WIDTH, CALENDAR_WEEKVIEW_STEP_WIDTH } from '../../../../utils/constants';

const classNames = mergeStyleSets({
    week: {
        // minWidth: 175,
        height: 20,
        // background: '#E7E7E7',
        fontSize: 11,
        lineHeight: 20,
        // borderRadius: 10,
        position: 'relative'
    },
    weekText: {
        marginLeft: 6
    }
});

const WeekLabelDetailedPlanning = ({length, month, day, year, index, isWeekView = false}) => {
    
    const getWeekNumber = (month, day, year) => {
        const date = `${ day}-${month}-${year}`;
        var weeknumber = moment(date, "DMYYYY").isoWeek();
        return weeknumber;
    }

    return (
        <Fragment>
            <div className={classNames.week} style={{minWidth: isWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH * 7 : CALENDAR_STEP_WIDTH * 7, marginBottom: isWeekView ? 10 : 0}}>
                <div className={classNames.weekText}>
                    {getWeekNumber(month+1 , day, year)}
                </div>
                { index !== 0 && <div style={{position: 'absolute', top: 0, borderLeft: '2px solid #868685', height: '100%', width: '20px' }}/>}
            </div>
        </Fragment>
    )
}

export default WeekLabelDetailedPlanning;
