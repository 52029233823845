import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  DefaultButton,
  DirectionalHint,
  IButtonStyles,
  ISearchBoxStyles,
  mergeStyleSets,
  SearchBox,
  TeachingBubble,
} from "@fluentui/react";
import { Skill } from "../../../types/settings";
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../../../redux/skill/skill.actions";
// import ToggleableSelector from "../planning/filterSelector/ToggleableSelector";
import {
  SKILL_TYPE_OPTIONS,
  techDepartmentOptions,
} from "../../../utils/constants";
import ToggleableSelector from "../../common/filter/ToggleableSelector";
import { buttonStyle } from "../../common/calendar/ButtonStyles";

//#region Styles
const classNames = mergeStyleSets({
  heading: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#000000",
  },
  sectionContainer: {
    marginTop: 35,
  },
  lastSection: {
    marginTop: 35,
    marginBottom: 50,
  },
  iconNumber: {
    position: "absolute",
    paddingLeft: "3px",
    paddingRight: "3px",
    borderRadius: "50%",
    marginTop: "-15px",
    marginLeft: "15px",
    color: "#FFFFFF",
    fontSize: 10,
    fontWeight: "bold",
    backgroundColor: "#006CAD",
  },
});

const searchBoxStyle: ISearchBoxStyles = {
  root: {
    border: "none",
    backgroundColor: "#FFFFFF",
    margin: "10px -10px 0 -5px",
    height: "56px",
    ":after": {
      border: "none",
    },
    width: "180px",
  },
  iconContainer: {
    display: "none",
  },
  clearButton: {},
};
//#endregion

export const SettingPageFilter = () => {
  const dispatch = useDispatch();
  const filter = useSelector((state: any) => state?.skill?.filter);
  const currentSearchKey = "skillNames";

  const [showBubble, setShowBubble] = useState(false);

  const countSelectedFiltersNumber = (filter: any) => {
    let count = 0;
    Object.keys(filter).forEach((key) => {
      if (key === "selectedTypes" || key === "selectedSections") {
        count += filter[key].filter((x: any) => x).length;
      }
    });
    return count;
  };

  return (
    <div>
      <div id="setting-filter-button">
        <DefaultButton
          onClick={() => setShowBubble(!showBubble)}
          styles={{ ...buttonStyle(false) } as IButtonStyles}
        >
          <FontAwesomeIcon
            icon={faFilter as IconDefinition}
          />
          {countSelectedFiltersNumber(filter) > 0 && (
            <span className={classNames.iconNumber}>
              {countSelectedFiltersNumber(filter)}
            </span>
          )}
        </DefaultButton>
      </div>
      {showBubble && (
        <TeachingBubble
          calloutProps={{ directionalHint: DirectionalHint.rightTopEdge }}
          target="#setting-filter-button"
          closeButtonAriaLabel="Close"
          onDismiss={() => setShowBubble(!showBubble)}
          isWide={true}
        >
          <div style={{ margin: "11px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "115%",
              }}
            >
              <div>
                <span className={classNames.heading}>Skill names</span>
                <div style={{ marginTop: "20px" }}>
                  <SearchBox
                    styles={searchBoxStyle}
                    placeholder="Search"
                    clearButtonProps={{
                      style: {
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                    value={filter ? filter[currentSearchKey] : ""}
                    onChange={(e, value) => {
                      dispatch(
                        setFilter({
                          [currentSearchKey]: value,
                        })
                      );
                    }}
                  />
                  <hr
                    style={{
                      width: "145%",
                      marginTop: "-2px",
                      opacity: 0.5,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={classNames.sectionContainer}>
              <span className={classNames.heading}>Type</span>
              <ToggleableSelector
                filterKey="selectedTypes"
                allOptions={SKILL_TYPE_OPTIONS.map((type) => {
                  return { key: type.key, text: type.text };
                })}
                availableOptions={SKILL_TYPE_OPTIONS.map((type) => type.key)}
                selectedOptions={filter.selectedTypes}
                isSkillSelector={true}
              />
            </div>
            <div className={classNames.lastSection}>
              <span className={classNames.heading}>Sektion</span>
              <ToggleableSelector
                filterKey="selectedSections"
                allOptions={techDepartmentOptions.map((type) => {
                  return { key: type.key, text: type.text };
                })}
                availableOptions={techDepartmentOptions.map((type) => type.key)}
                selectedOptions={filter.selectedSections}
                isSkillSelector={true}
              />
            </div>
          </div>
        </TeachingBubble>
      )}
    </div>
  );
};
