import { DirectionalHint, TeachingBubble } from "@fluentui/react";
import React, { useEffect, useState, useRef } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";

const classNames = mergeStyleSets({
  modalContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: 112,
    marginLeft: -15,
  },
  icon: {
    fontSize: 24,
    color: "#006CAD",
    cursor: "pointer",
  },
});

/* when using css zoom, we need to adjust the position of bubble manually, 
that's why this component's position is complicated
We will get the x position from the mouse and y position from the target */
const ConfirmBubble = ({ onCancel, onApprove, targetId }) => {
  const targetPosition = document
    .getElementById(targetId)
    ?.getBoundingClientRect();
  const [mousePosition, setMousePosition] = useState(null);
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const userZoomRatio = 1;
  const [bubbleInitiated, setBubbleInitiated] = useState(false);
  const mouse = useRef(null);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove, { passive: true });
    setTimeout(() => window.dispatchEvent(new Event("mousemove")), 100);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  });

  const handleMouseMove = (e) => {
    if (!mousePosition && !mouse.current) {
      const val = {
        x: e.pageX || e.clientX,
        y: e.pageY || e.clientY,
      };

      //   Incase the values are undefined
      if (val.x && val.y) {
        setMousePosition(val);

        mouse.current = val;
      }
    }
  };

  const bubbleStyles = () => {
    setTimeout(() => {
      const teachingBubble = document.querySelectorAll(".ms-TeachingBubble")[0];
      const beak = document.querySelectorAll(".ms-Callout-beak")[0];

      if (teachingBubble) {
        setBubbleInitiated(true);
        teachingBubble.style.bottom = `-${targetPosition.top - 10}px`;
        beak.style.display = "block";
      }
    }, 10);
    const delta =
      ((mousePosition?.x || 0) - window.pageXOffset) / userZoomRatio +
      56 -
      window.innerWidth;
    return delta > 48 ? 48 : delta > 0 ? delta : 0;
  };

  return mousePosition && targetPosition ? (
    <TeachingBubble
      target={{
        x: (mousePosition.x - window.pageXOffset) / userZoomRatio,
        y: targetPosition.top,
      }}
      calloutProps={{
        directionalHint: DirectionalHint.topCenter,
        calloutWidth: 128,
        calloutHeight: 36,
      }}
      styles={{
        root: {
          left: (mousePosition.x - window.pageXOffset) / userZoomRatio - 56,
        },
        subComponentStyles: {
          callout: {
            calloutMain: {
              display: bubbleInitiated ? "block" : "none",
            },
            beak: {
              marginRight: bubbleStyles(),
            },
          },
        },
      }}
    >
      <div className={classNames.modalContainer}>
        <FontAwesomeIcon
          onClick={onApprove}
          icon={faCheck}
          className={classNames.icon}
        />
        <FontAwesomeIcon
          onClick={onCancel}
          icon={faTimes}
          className={classNames.icon}
        />
      </div>
    </TeachingBubble>
  ) : null;
};

export default ConfirmBubble;
