import axios from 'axios';
import setDefaults from '../../utils/setDefaults';
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from '../message/message.actions';
import { MessageBarType } from '@fluentui/react/';
import { SOURCE_API } from '../../utils/constants';

export const getFavoriteColor = () => async (dispatch) =>  {
    try {
        setDefaults(localStorage.getItem('token'));
        const response = await axios.get(`${SOURCE_API}/api/User/GetFavoriteColor`);
        return response.data.data
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
};

export const addFavoriteColors = (favoriteColors) => async (dispatch) => {
    try {
        setDefaults(localStorage.getItem('token'));
        const response = await axios.post(`${SOURCE_API}/api/User/AddFavoriteColor`, favoriteColors);

        if(response.data.succeeded === true) {
            dispatch(saveMessage('Add New Favorite Color!', MessageBarType.success));
        } else {
            dispatch(saveMessage('There was an error'));
        }
        return response.data.succeeded;
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
}