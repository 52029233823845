import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Projects,
  Project,
  Planning,
  DetailedPlanning,
  NewProject,
  AddMachineRequirements,
  Machines,
  Login,
  Staff,
  EditStaff,
  MapMachines,
  NewMachine,
  Machine,
  MachineDocument,
  NotificationManagement,
  ReplacePrebooking,
  SelectWeek,
  PrintProjects,
  PrintSupervisors,
  PrintMachines,
  NewTeam,
  Team,
  Print,
  NewStaff,
  LoginTeams,
} from "./components/pages";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@fluentui/react";
import { appTheme } from "./utils/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import PrivateRoute from "./components/routing/PrivateRoute";
import { ReactQueryDevtools } from "react-query/devtools";
import { Message, ConflictMessage } from "./components/common";
import { setFilter } from "./redux/machines/machines.actions";
import { getDefaultData } from "./redux/defaultData/defaultData.action";
import UserLeaveConfirmation from "./components/common/UserLeaveConfirmation";
import { MsalProvider } from "@azure/msal-react";
import ScreenSizeAndPosition from "./components/common/ScreenSizeAndPosition";
import DarkOverlay from "./components/common/DarkOverlay";
import { isProdUrl, setCookie } from "./utils/utils";
import { createBrowserHistory } from "history";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import API_SOURCE from "./config.json";
import { logout } from "./redux/user/user.actions";
import { STORE_VERSION } from "./utils/constants";
import { PublicClientApplication } from "@azure/msal-browser";
import { app } from "@microsoft/teams-js";
import Settings from "./components/pages/Settings";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import MachineService from "./components/pages/MachineService";
import { setWeeksShown } from "./redux/app/app.actions";
import TeamsConfiguration from "./components/pages/TeamsConfiguration";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const browserHistory = createBrowserHistory({});
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      // window.location.href.includes(
      //   "https://aarsleff.kodebaze.com"
      // )
      isProdUrl()
        ? API_SOURCE.connectionString
        : API_SOURCE.devConnectionString,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
// if (window.location.href.includes("https://aarsleff.kodebaze.com"))
if (isProdUrl()) appInsights.loadAppInsights();

type AppProps = {
  instance: PublicClientApplication;
};

const App = ({ instance }: AppProps): JSX.Element => {
  // @ts-ignore
  const storeVersion = useSelector((state) => state.app.storeVersion);
  const defaultFilter = useSelector(
    // @ts-ignore
    (state) => state?.user?.user?.defaultFilter
  );
  // @ts-ignore
  const { openDarkOverlay } = useSelector((state) => state.app);
  // @ts-ignore
  const filter = useSelector((state) => state?.machine?.filter);

  const dispatch = useDispatch();
  const { techAreaFilters, techDepartmentFilter } = defaultFilter ?? {
    techAreaFilters: null,
    techDepartmentFilter: null,
  };
  const [confirmOpen, setConfirmOpen] = useState(true);

  // Set cookie to be able to return back to this url if require login or session expires
  if (window.location.pathname.includes("add-machine-requirements")) {
    setCookie("machineReqUrl", window.location.pathname, 30000);
  }

  useLayoutEffect(() => {
    const body = document.querySelectorAll("body")[0];
    // @ts-ignore
    body.style.zoom = 1;
  }, []);

  useEffect(() => {
    if (window.location.href.includes("/login")) {
      return;
    }
    dispatch(getDefaultData());
  }, [dispatch, filter]);

  useEffect(() => {
    if (defaultFilter) {
      const weekShownIndex = (defaultFilter.customFilters || []).findIndex(
        (el: any) => el.key === "weeksShown"
      );

      if (weekShownIndex !== -1) {
        const val = parseInt(defaultFilter.customFilters[weekShownIndex].text);

        if (!isNaN(val) && typeof val === "number" && val >= 1 && val <= 20) {
          dispatch(setWeeksShown(val));
        }
      }
    }
  }, [defaultFilter, dispatch]);

  useEffect(() => {
    if (techAreaFilters && techDepartmentFilter) {
      dispatch(
        setFilter({
          techDepartment: filter.techDepartment
            ? filter.techDepartment
            : techDepartmentFilter,
          [techDepartmentFilter]: {
            techArea: filter[techDepartmentFilter].techArea
              ? filter[techDepartmentFilter].techArea
              : techAreaFilters,
          },
        })
      );
    }
  }, [dispatch, techAreaFilters, techDepartmentFilter]);

  useEffect(() => {
    if (storeVersion !== STORE_VERSION) {
      dispatch(logout());
    }
  });

  useEffect(() => {
    if (!app.isInitialized() && window !== window.parent) {
      app.initialize(["https://aarsleff.crm4.dynamics.com/"]);
    }
  }, []);

  return (
    <MsalProvider instance={instance}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={appTheme}>
          <Router
            getUserConfirmation={(message, callback) => {
              return UserLeaveConfirmation(
                message,
                callback,
                confirmOpen,
                setConfirmOpen
              );
            }}
          >
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/terms">
                <Terms />
              </Route>
              <Route exact path="/configure-teams">
                <TeamsConfiguration />
              </Route>
              <Route exact path="/login-teams">
                <LoginTeams />
              </Route>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/">
                <Planning />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/projects">
                <ScreenSizeAndPosition>
                  {/* @ts-ignore */}
                  <Projects />
                </ScreenSizeAndPosition>
                {/* @ts-ignore */}
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/project/:projectId">
                <Project />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute
                exact
                path="/planning/:projectId/:planningIndex/:teamId?"
              >
                <DetailedPlanning />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/machines">
                <ScreenSizeAndPosition>
                  <Machines />
                </ScreenSizeAndPosition>
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/machine/:machineId">
                <Machine />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute
                exact
                path="/machine-service/:machineId/:serviceIndex"
              >
                <MachineService />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/new-machine">
                <NewMachine />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/new-project">
                <NewProject />
              </PrivateRoute>

              {/* @ts-ignore */}
              <PrivateRoute exact path="/staff">
                <ScreenSizeAndPosition>
                  <Staff />
                </ScreenSizeAndPosition>
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/settings">
                <ScreenSizeAndPosition>
                  <Settings />
                </ScreenSizeAndPosition>
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/new-staff">
                <NewStaff />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/edit-staff/:staffId">
                <EditStaff />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/map-machine/:staffId">
                <MapMachines />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/map-machine/:staffId">
                <MapMachines />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/add-machine-requirements/:projectId">
                <AddMachineRequirements />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/machine-document/:machineId">
                <MachineDocument />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/notification-management">
                <NotificationManagement />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/replacePrebooking/:prebookingId">
                <ReplacePrebooking />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/select-week">
                <SelectWeek />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/print-projects">
                <PrintProjects />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/print-supervisors">
                {/* @ts-ignore */}
                <PrintSupervisors />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/print-machines">
                {/* @ts-ignore */}
                <PrintMachines />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/new-team">
                <NewTeam />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/team/:teamId">
                <Team />
              </PrivateRoute>
              {/* @ts-ignore */}
              <PrivateRoute exact path="/print">
                <Print />
              </PrivateRoute>
              {/* <PrivateRoute exact path="/t">
                                <Test />
                            </PrivateRoute> */}
            </Switch>
          </Router>
          {/* @ts-ignore */}
          <Message />
          {openDarkOverlay && <DarkOverlay />}
          <ConflictMessage />
          <ReactQueryDevtools />
        </ThemeProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
};

export default withAITracking(reactPlugin, App);
