import React, { Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { useQueryClient, useMutation } from "react-query";
import { updateProject } from "../../../redux/project/project.actions";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";
import { DeleteConfirmation } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPowerOff } from "@fortawesome/pro-regular-svg-icons";

const classNames = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    fontSize: 22,
    color: "rgb(0, 108, 173)",
    cursor: "pointer",
    marginRight: 12,
  },
});

const ProjectControls = ({ project, updateProject, history }) => {
  const queryClient = useQueryClient();
  const { showInactiveProject } = useSelector((state) => state.app);
  const [teachingBubbleEditProject, { toggle: toggleTeachingEditProject }] =
    useBoolean(false);

  const { mutate: mutationEditProject } = useMutation(
    (projectData) => updateProject(projectData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projects");
      },
    }
  );

  const onClickConfirm = () => {
    toggleTeachingEditProject();
    const temp = { ...project, inactive: !!!project.inactive };
    mutationEditProject(temp);
  };
  return (
    <Fragment>
      <div className={classNames.container}>
        {/* 1270 */}
        {!project.inactive && (
          <FontAwesomeIcon
            icon={faEdit}
            className={classNames.icon}
            onClick={() => history.push(`/project/${project.projectId}`)}
          />
        )}
        {/* {(!project.inactive || showInactiveProject) && <FontAwesomeIcon icon={faEdit} className={classNames.icon} onClick={() => history.push(`/project/${project.projectId}`)}/>} */}
        <FontAwesomeIcon
          id={`editProjectId-${project.projectId}`}
          icon={faPowerOff}
          className={classNames.icon}
          onClick={toggleTeachingEditProject}
        />
      </div>
      {teachingBubbleEditProject && (
        <DeleteConfirmation
          label={`Are you sure you want to ${
            project.inactive ? "active" : "inactive"
          } this?`}
          target={`#editProjectId-${project.projectId}`}
          toggleTeaching={toggleTeachingEditProject}
          onClickConfirm={onClickConfirm}
        />
      )}
    </Fragment>
  );
};

export default connect(null, { updateProject })(ProjectControls);
