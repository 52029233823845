import React, { useState, useLayoutEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Image, Panel, Spinner, SpinnerSize } from "@fluentui/react/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  changeSearchHistory,
  changeSearchOverlay,
  changeSearchType,
  clearSearchHistory,
  getSearchResult,
} from "../../../redux/app/app.actions";
import { getPlans } from "../../../redux/plan/plan.actions";
import {
  faChevronDown,
  faChevronLeft,
  faHardHat,
  faPencilRuler,
  faPenSquare,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import Select from "react-select";
import {
  SEARCH_TYPE_ENUM,
  techDepartmentOptions,
} from "../../../utils/constants";
import { animated, useSpring, config } from "react-spring";
import { MachineIconWithBadge } from "..";
import { openInNewTab } from "../../../utils/utils";
import { autoCompleteSearchStyles } from "../../../utils/theme";
import { getProjects } from "../../../redux/project/project.actions";

const classNames = mergeStyleSets({
  buttonBottomContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButtonOutside: {
    zIndex: 1,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 20,
    left: -40,
    height: 40,
    width: 40,
    background: "#fff",
    cursor: "pointer",
    boxShadow:
      "rgb(0 0 0 / 22%) -5px 0px 5px 0px, rgb(0 0 0 / 18%) -14px 0px 20px 0px",
    borderTopLeftRadius: "10%",
    borderBottomLeftRadius: "10%",
  },
  closeButtonInside: {
    width: "80%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    selectors: {
      ":hover": {
        background: "rgba(0, 0, 0, 0.05)",
      },
    },
  },
  mainContainer: {
    marginLeft: 10,
  },
  searchInput: {
    display: "flex",
  },
  searchResult: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    width: "97%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    marginTop: 20,
    zIndex: 3,
  },
  contentData: {
    display: "flex",
    flexDirection: "column",
  },
  headerIcon: {
    fontSize: 19,
    color: "#006CAD",
  },
  showHideIcon: {
    fontSize: 14,
    color: "#000",
    transition: "all 0.3s ease",
  },
  headerText: {
    fontSize: 12,
    fontFamily: "Verdana",
    fontWeight: 700,
    marginLeft: 10,
    userSelect: "none",
  },
  contentText: {
    fontSize: 14,
    fontFamily: "Verdana",
    fontWeight: 400,
    marginLeft: 26,
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "75%",
    height: 37,
    display: "flex",
    alignItems: "flex-end",
  },
  contentTextNormal: {
    fontSize: 14,
    fontFamily: "Verdana",
    fontWeight: 400,
    marginLeft: 26,
    height: 37,
    display: "flex",
    alignItems: "flex-end",
  },
  badge: {
    background: "#006CAD",
    color: "#ffffff",
    fontSize: 11,
    textAlign: "center",
    lineHeight: 20,
    height: 20,
    width: 20,
    position: "absolute",
    top: -8,
    right: -14,
    borderRadius: "50%",
  },
  showAllButton: {
    fontSize: 12,
    color: "#006CAD",
    cursor: "pointer",
    margin: "20px 0px 0px 27px",
  },
});

const overlayStyle = {
  main: {
    overflow: "visible",
    minWidth: "35%",
    background: "#fff",
  },
};

const dataInfoInitial = {
  machines: {
    icon: `${window.location.origin}/img/machineBlue.svg`,
    text: "MACHINE",
    showData: true,
    showAll: false,
  },
  plannings: {
    icon: faPenSquare,
    text: "PLAN",
    showData: true,
    showAll: false,
  },
  projects: {
    icon: faPencilRuler,
    text: "PROJECT",
    showData: true,
    showAll: false,
  },
  staffs: {
    icon: faHardHat,
    text: "STAFF",
    showData: true,
    showAll: false,
  },
};

const SearchOverlay = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState({});
  const [dataInfo, setDataInfo] = useState(dataInfoInitial);
  const { openSearchOverlay, searchHistory, selectedSearchEnum } = useSelector(
    (state) => state.app
  );
  const { machineTypeOptions, sizeGroupOptions } = useSelector(
    (state) => state.defaultData
  );
  const { data: allPlansData } = useQuery("plans", () => dispatch(getPlans()));
  const { data: projectsData } = useQuery("projects", () =>
    dispatch(getProjects())
  );
  const { data: searchResult, isSuccess: getSearchResultSuccess } = useQuery(
    ["search", inputValue, selectedSearchEnum],
    () =>
      inputValue && dispatch(getSearchResult(inputValue, selectedSearchEnum))
  );
  let searchHistoryWithLabel =
    searchHistory
      ?.map((value, index) => ({ key: index, label: value }))
      .reverse() || [];
  if (searchHistoryWithLabel.length)
    searchHistoryWithLabel = [
      { key: 11, label: "Clear previous search" },
      ...searchHistoryWithLabel,
    ];
  const baseUrl = window.location.origin;

  const machinesSlideStyle = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: dataInfo["machines"].showData ? 1 : 0,
      height: dataInfo["machines"].showData
        ? (dataInfo["machines"].showAll
            ? data?.machines?.length
            : data?.machines?.slice(0, 5)?.length) *
            37 +
            (data?.machines?.length > 5 ? 20 : 0) || 0
        : 0,
      pointerEvents: dataInfo["machines"].showData ? "unset" : "none",
    },
  });
  const planningsSlideStyle = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: dataInfo["plannings"].showData ? 1 : 0,
      height: dataInfo["plannings"].showData
        ? (dataInfo["plannings"].showAll
            ? data?.plannings
            : data?.plannings?.slice(0, 5)
          )
            ?.map((item) => item.machineRequirements.length + 1)
            .reduce((acc, curr) => acc + curr, 0) *
            37 +
            (data?.plannings?.length > 5 ? 20 : 0) || 0
        : 0,
      pointerEvents: dataInfo["plannings"].showData ? "unset" : "none",
    },
  });
  const projectsSlideStyle = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: dataInfo["projects"].showData ? 1 : 0,
      height: dataInfo["projects"].showData
        ? (dataInfo["projects"].showAll
            ? data?.projects?.length
            : data?.projects?.slice(0, 5)?.length) *
            37 +
            (data?.projects?.length > 5 ? 20 : 0) || 0
        : 0,
      pointerEvents: dataInfo["projects"].showData ? "unset" : "none",
    },
  });
  const staffsSlideStyle = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: dataInfo["staffs"].showData ? 1 : 0,
      height: dataInfo["staffs"].showData
        ? (dataInfo["staffs"].showAll
            ? data?.staffs?.length
            : data?.staffs?.slice(0, 5)?.length) *
            37 +
            (data?.staffs?.length > 5 ? 40 : 0) || 0
        : 0,
      pointerEvents: dataInfo["staffs"].showData ? "unset" : "none",
    },
  });

  const animation = {
    machines: machinesSlideStyle,
    plannings: planningsSlideStyle,
    projects: projectsSlideStyle,
    staffs: staffsSlideStyle,
  };

  useLayoutEffect(() => {
    if (searchResult && allPlansData && projectsData) {
      const tempData = {};
      for (const key of Object.keys(searchResult)) {
        switch (key) {
          case "machines":
            if (!searchResult[key]) break;
            tempData[key] = searchResult[key].map((item) => ({
              id: item.machineId,
              name: item.machineName,
              link: `${baseUrl}/machine/${item.machineId}`,
            }));
            break;
          case "plannings":
            if (!searchResult[key]) break;
            const planningsData = JSON.parse(JSON.stringify(searchResult[key]));
            let modifiedPlanningsData = [];
            planningsData.forEach((project, index) => {
              const projectData = allPlansData.find(
                (item) => item.projectId === project.projectId
              );
              let machineRequirements = [];
              projectData?.machineRequirements?.forEach(
                (machineReq, reqIndex) => {
                  const techDepartmentName =
                    techDepartmentOptions.find(
                      (item) => item.key === machineReq.techDepartment
                    )?.text || "Unknown";
                  const machineType =
                    machineTypeOptions
                      .find((item) => item.id === machineReq.techDepartment)
                      ?.options.find(
                        (item) => item.key === machineReq.machineType
                      )?.text || "Unknown";
                  const machineSizeGroup =
                    sizeGroupOptions.find(
                      (item) => item.key === machineReq.sizeGroup
                    )?.text || "Unknown";
                  let link = `${baseUrl}/planning/${planningsData[index].projectId}/${reqIndex}`;
                  if (
                    `${techDepartmentName}${machineType}${machineSizeGroup}`.includes(
                      "Unknown"
                    )
                  ) {
                    link = `${baseUrl}/add-machine-requirements/${
                      projectData.crmProjectId || projectData.projectId
                    }`;
                  }
                  machineRequirements.push({
                    index: reqIndex,
                    name: `${techDepartmentName} - ${machineType} - ${machineSizeGroup}`,
                    link,
                  });
                }
              );
              if (machineRequirements.length) {
                const tempPlanning = {
                  id: planningsData[index].projectId,
                  name: `${planningsData[index].projectName}`,
                  machineRequirements: machineRequirements,
                };
                modifiedPlanningsData = [
                  ...modifiedPlanningsData,
                  tempPlanning,
                ];
              }
            });
            tempData[key] = modifiedPlanningsData;
            break;
          case "projects":
            if (!searchResult[key]) break;
            tempData[key] = searchResult[key].map((item) => {
              const projectData = projectsData.find(
                (project) => item.projectId === project.projectId
              );
              return {
                id: item.projectId,
                name: item.projectName,
                link: `${baseUrl}/project/${item.projectId}`,
                numOfPlans: projectData?.machineRequirementCount || 0,
                isRed: projectData?.missingRequiredInfo,
              };
            });
            break;
          case "staffs":
            if (!searchResult[key]) break;
            tempData[key] = searchResult[key].map((item) => ({
              id: item.id,
              name: `${item.firstName} ${item.lastName}`,
              link: `${baseUrl}/edit-staff/${item.id}`,
            }));
            break;
          default:
            break;
        }
      }
      setData(tempData);
    } else setData({});
  }, [searchResult, allPlansData]);

  const onInputChange = (input, event) => {
    if (event.action === "input-change") {
      setInputValue(input);
    } else if (inputValue?.length) {
      dispatch(changeSearchHistory(inputValue));
    }
  };

  const toggleContent = (key, propKey, boolean) => {
    setDataInfo({
      ...dataInfo,
      [key]: {
        ...dataInfo[key],
        [propKey]: !boolean,
      },
    });
  };

  const onSelectItem = (item) => {
    if (item?.label === "Clear previous search") {
      dispatch(clearSearchHistory());
      setInputValue("");
    } else {
      setInputValue(item?.label);
    }
  };

  return (
    <Panel
      isOpen={openSearchOverlay}
      hasCloseButton={false}
      styles={overlayStyle}
    >
      <div className={classNames.closeButtonOutside}>
        <div
          className={classNames.closeButtonInside}
          onClick={() => dispatch(changeSearchOverlay(false))}
        >
          <FontAwesomeIcon
            icon={faTimes}
            style={{ color: "#006CAD", fontSize: 30 }}
          />
        </div>
      </div>
      <div className={classNames.mainContainer}>
        <div className={classNames.searchInput}>
          <div style={{ width: "90%" }}>
            <Select
              placeholder="Search"
              closeMenuOnSelect={true}
              options={searchHistoryWithLabel}
              isClearable={true}
              isSearchable={true}
              onInputChange={onInputChange}
              onChange={onSelectItem}
              className={classNames.input}
              styles={autoCompleteSearchStyles}
              value={inputValue && { label: inputValue }}
              getOptionValue={(option) => option.label}
              noOptionsMessage={() => null}
            />
          </div>
          <div style={{ width: "10%" }}>
            <select
              style={{
                fontFamily: "Verdana",
                position: "relative",
                height: 47,
                border: "none",
                borderBottom: "1px solid rgb(159, 159, 159)",
                marginLeft: -46,
              }}
              onChange={(e) => {
                dispatch(changeSearchType(e.target.value));
                queryClient.invalidateQueries("search");
              }}
              value={selectedSearchEnum}
            >
              <option value={SEARCH_TYPE_ENUM.EVERYTHING}>Everything</option>
              <option value={SEARCH_TYPE_ENUM.PLANNING}>Plannings</option>
              <option value={SEARCH_TYPE_ENUM.MACHINE}>Machines</option>
              <option value={SEARCH_TYPE_ENUM.STAFF}>Staffs</option>
              <option value={SEARCH_TYPE_ENUM.PROJECT}>Projects</option>
            </select>
          </div>
        </div>
        {getSearchResultSuccess ? (
          Object.keys(data).map((key, index) => (
            <div key={`plan-${index}`} className={classNames.searchResult}>
              <div
                className={classNames.header}
                onClick={() =>
                  toggleContent(key, "showData", dataInfo[key].showData)
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {key === "machines" ? (
                    <Image
                      style={{ userSelect: "none" }}
                      src={dataInfo[key].icon}
                      alt="machine"
                      width={20}
                      height={32}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={dataInfo[key].icon}
                      className={classNames.headerIcon}
                    />
                  )}
                  <span className={classNames.headerText}>
                    {dataInfo[key].text}
                  </span>
                </div>
                <FontAwesomeIcon
                  icon={dataInfo[key].showData ? faChevronDown : faChevronLeft}
                  className={classNames.showHideIcon}
                />
              </div>
              <animated.div
                style={{
                  ...animation[key],
                  zIndex: dataInfo[key].showData ? 2 : 1,
                }}
              >
                <div
                  id={`contentData-${key}`}
                  className={classNames.contentData}
                >
                  {key === "plannings"
                    ? (dataInfo[key].showAll
                        ? data[key]
                        : data[key].slice(0, 5)
                      ).map((item, index) => (
                        <div
                          key={item.id + "-" + index}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span className={classNames.contentTextNormal}>
                            {item.name}
                          </span>
                          <span
                            style={{
                              marginLeft: 20,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {item.machineRequirements.map((item, index) => (
                              <span
                                key={index}
                                className={classNames.contentText}
                                onClick={() => openInNewTab(item.link)}
                              >
                                {item.name}
                              </span>
                            ))}
                          </span>
                        </div>
                      ))
                    : key === "projects"
                    ? (dataInfo[key].showAll
                        ? data[key]
                        : data[key].slice(0, 5)
                      ).map((item, index) => (
                        <div
                          key={item.id + "-" + index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className={classNames.contentText}
                            onClick={() => openInNewTab(item.link)}
                          >
                            {item.name}
                          </span>
                          <MachineIconWithBadge
                            count={item.numOfPlans}
                            isRed={item.isRed}
                            isSmall
                            onClick={() =>
                              openInNewTab(
                                `${baseUrl}/add-machine-requirements/${item.id}`
                              )
                            }
                          />
                        </div>
                      ))
                    : (dataInfo[key].showAll
                        ? data[key]
                        : data[key].slice(0, 5)
                      ).map((item, index) => (
                        <div
                          key={item.id + "-" + index}
                          style={{ display: "flex" }}
                        >
                          <span
                            className={classNames.contentText}
                            onClick={() => openInNewTab(item.link)}
                          >
                            {item.name}
                          </span>
                        </div>
                      ))}
                  {data[key].length > 5 && (
                    <div
                      className={classNames.showAllButton}
                      onClick={() =>
                        toggleContent(key, "showAll", dataInfo[key].showAll)
                      }
                    >
                      {dataInfo[key].showAll ? "Show less" : "Show all"}
                    </div>
                  )}
                </div>
              </animated.div>
            </div>
          ))
        ) : (
          <Spinner
            style={{ marginTop: "30%" }}
            size={SpinnerSize.large}
            label="Loading..."
            labelPosition="right"
          />
        )}
      </div>
    </Panel>
  );
};

export default SearchOverlay;
