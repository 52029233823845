import React, { Fragment } from "react";
import { Rnd } from "react-rnd";
import { useBoolean } from "@fluentui/react-hooks";
import { DeleteConfirmation, TooltipForText } from "../../../common";
import moment from "moment";
import "moment/locale/da";
import { CALENDAR_STEP_WIDTH } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import { hasEditRight } from "../../../../utils/utils";

const textStyles = {
  padding: "0 10px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const Machine = ({
  machine: { start, end, startText, endText, width, machineName, machineId },
  horizontalPositions,
  onResize,
  onDrag,
  index,
  indexList,
  onDelete,
  role,
  boundArea,
  calendarStepWidth = CALENDAR_STEP_WIDTH,
  cancelDrawOnModifyBar,
  allowEdit,
  backgroundColor = "#fff",
  color = "#000",
}) => {
  const [teachingBubbleDelete, { toggle: toggleTeachingDelete }] =
    useBoolean(false);
  const startDate = moment(startText.value.slice(0, 10), "YYYY-M-D").format(
    "YYYY-M-D"
  );
  const endDate = moment(endText.value.slice(0, 10))
    .add(1, "d")
    .format("YYYY-M-D");
  const startDateArray = startDate.split("-");
  const endDateArray = endDate.split("-");
  const startCalendar = horizontalPositions.startText.split(".").reverse();
  const endCalendar = horizontalPositions.endText.split(".").reverse();
  const startTemp =
    new Date(startDateArray[0], startDateArray[1] - 1, startDateArray[2]) <
    new Date("20" + startCalendar[0], startCalendar[1] - 1, startCalendar[2])
      ? 0
      : horizontalPositions[startDate];
  const endTemp =
    new Date(endDateArray[0], endDateArray[1] - 1, endDateArray[2]).getTime() >=
    new Date(
      "20" + endCalendar[0],
      endCalendar[1] - 1,
      parseInt(endCalendar[2]) + 1
    ).getTime()
      ? horizontalPositions[endDate] || horizontalPositions.end + 1
      : horizontalPositions[endDate];
  const x = startTemp * calendarStepWidth;
  const widthBar = (endTemp - startTemp) * calendarStepWidth;

  let renderDetail = (
    <TooltipForText text={machineName}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color,
        }}
      >
        <div style={textStyles}>{startText?.display}</div>
        <div
          style={textStyles}
          id={`${role}-${machineId}-${index}`}
          onClick={allowEdit ? toggleTeachingDelete : null}
        >
          {machineName}
        </div>
        <div style={textStyles}>{endText?.display}</div>
      </div>
    </TooltipForText>
  );
  if (widthBar <= 11 * calendarStepWidth) {
    renderDetail = (
      <TooltipForText text={machineName}>
        <div style={{ display: "flex", justifyContent: "center", color }}>
          <div
            style={textStyles}
            id={`${role}-${machineId}-${index}`}
            onClick={allowEdit ? toggleTeachingDelete : null}
          >
            {machineName}
          </div>
        </div>
      </TooltipForText>
    );
  }

  return (
    <Fragment>
      <Rnd
        style={{ zIndex: 3, top: 0, left: 0 }}
        size={{ width: widthBar, height: 20 }}
        position={{ x: x, y: 0 }}
        resizeGrid={[calendarStepWidth, 1]}
        dragGrid={[calendarStepWidth, 1]}
        enableResizing={{
          left: allowEdit ? true : false,
          right: allowEdit ? true : false,
        }}
        disableDragging={!allowEdit}
        bounds={`.${boundArea}`}
        onResize={cancelDrawOnModifyBar}
        onDrag={cancelDrawOnModifyBar}
        onResizeStop={(e, direction, ref, delta, position) => {
          onResize(e, direction, ref, delta, position, index, indexList, role);
        }}
        onDragStop={(e, d) => {
          onDrag(e, d, widthBar, index, indexList, role);
        }}
      >
        <div
          id="detailBar"
          style={{
            backgroundColor,
            display: "flex",
            marginBottom: 12,
            height: 36,
            borderRadius: 8,
            userSelect: "none",
          }}
        >
          <div
            style={{
              color: "#fff",
              fontWeight: "bold",
              height: "100%",
              width: "100%",
              fontSize: 13,
              lineHeight: "32px",
              margin: "2px 4px",
            }}
          >
            {renderDetail}
          </div>
        </div>
      </Rnd>
      {teachingBubbleDelete && (
        <DeleteConfirmation
          target={`#${role}-${machineId}-${index}`}
          toggleTeaching={toggleTeachingDelete}
          onClickConfirm={() => {
            onDelete(index, indexList, role);
          }}
        />
      )}
    </Fragment>
  );
};

export default Machine;
