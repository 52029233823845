import moment from "moment";
import { ACTIVITY_LOG_ENUM, ACTIVITY_LOG_TYPE_ENUM } from "../../utils/constants";

export const planActivityLogComposer = (data, newData, currentUserId) => {
    const different = [];
    const transformData = (data, key) => {
        const transformedData = {};
        data.forEach(item => {
            const currentName = key === 'machines' ? item.machineName : `${item.firstName} ${item.lastName}`;
            if (!transformedData[currentName]) transformedData[currentName] = [];
            transformedData[currentName].push({
                id: key === 'machines' ? item.machineId : item.id,
                start: item.start,
                end: item.end
            });
        })
        return transformedData;
    }
    Object.keys(data).forEach(key => {
        switch (key) {
            case "drivers":
            case "workers":
            case "managers":
            case "machines":
                const modifiedData = transformData(data[key].map(item => ({...item, start: item.start.slice(0,10), end: item.end.slice(0,10)})), key);
                const modifiedNewData = transformData(newData[key].map(item => ({...item, start: item.start.slice(0,10), end: item.end.slice(0,10)})), key);
                // Check for staff/machine that existed in plan before
                Object.keys(modifiedData).forEach(name => {
                    // current item is removed all from the plan
                    if (!modifiedNewData[name]) different.push(`Removed ${key.slice(0,-1)} ${key === 'machines' ? 'machineId': 'staffId'}:${modifiedData[name][0].id} from the plan.`);
                    else {
                        let dataToString = modifiedData[name].map(item => JSON.stringify(item));
                        let newDataToString = modifiedNewData[name].map(item => JSON.stringify(item));
                        // Remove the same item between old and new
                        for (let i = dataToString.length - 1; i >= 0; i--) {
                            if (newDataToString.indexOf(dataToString[i]) !== -1) {
                                newDataToString.splice(newDataToString.indexOf(dataToString[i]), 1);
                                dataToString.splice(i, 1);
                            }
                        }
                        dataToString = dataToString.map(item => JSON.parse(item));
                        newDataToString = newDataToString.map(item => JSON.parse(item));
                        // Check for period changes
                        for (let i = dataToString.length - 1; i >= 0; i--) {
                            for (let j = newDataToString.length - 1; j >= 0; j--) {
                                if (dataToString[i]) {
                                    const startText = moment(dataToString[i].start, "YYYY-M-D").format('D.M.YY');
                                    const endText = moment(dataToString[i].end, "YYYY-M-D").format('D.M.YY');
                                    const newStartText = moment(newDataToString[j].start, "YYYY-M-D").format('D.M.YY');
                                    const newEndText = moment(newDataToString[j].end, "YYYY-M-D").format('D.M.YY');
                                    different.push(`Changed Period of ${key.slice(0,-1)} ${key === 'machines' ? 'machineId': 'staffId'}:${dataToString[i].id} from "${startText} - ${endText}" to "${newStartText} - ${newEndText}".`);
                                    dataToString.splice(i, 1);
                                    newDataToString.splice(j, 1);
                                }
                            }
                        }
                        // Check for removed plan
                        for (let i = dataToString.length - 1; i >= 0; i--) {
                            const startText = moment(dataToString[i].start, "YYYY-M-D").format('D.M.YY');
                            const endText = moment(dataToString[i].end, "YYYY-M-D").format('D.M.YY');
                            different.push(`Removed ${key.slice(0,-1)} ${key === 'machines' ? 'machineId': 'staffId'}:${dataToString[i].id} with Period "${startText} - ${endText}".`);
                            dataToString.splice(i, 1);
                        }
                        // Check for added plan
                        for (let i = newDataToString.length - 1; i >= 0; i--) {
                            const newStartText = moment(newDataToString[i].start, "YYYY-M-D").format('D.M.YY');
                            const newEndText = moment(newDataToString[i].end, "YYYY-M-D").format('D.M.YY');
                            different.push(`Added ${key.slice(0,-1)} ${key === 'machines' ? 'machineId': 'staffId'}:${newDataToString[i].id} with Period "${newStartText} - ${newEndText}".`);
                            newDataToString.splice(i, 1);
                        }
                    }
                })
                // check for newly added staff/machine
                Object.keys(modifiedNewData).forEach(name => {
                    if (!modifiedData[name]) {
                        let newData = modifiedNewData[name];
                        // Check for added plan
                        for (let i = newData.length - 1; i >= 0; i--) {
                            const newStartText = moment(newData[i].start, "YYYY-M-D").format('D.M.YY');
                            const newEndText = moment(newData[i].end, "YYYY-M-D").format('D.M.YY');
                            different.push(`Added ${key.slice(0,-1)} ${key === 'machines' ? 'machineId': 'staffId'}:${newData[i].id} with Period "${newStartText} - ${newEndText}".`);
                            newData.splice(i, 1);
                        }
                    }
                })
                break;
            default:
                break;
        }
    })
    if (data.done !== newData.done) different.push(`Changed plan status to ${newData.done ? 'done' : 'not done'}.`);
    const activityLog = {
        type: ACTIVITY_LOG_TYPE_ENUM.UPDATE,
        resourceId: data.guid,
        resourceType: ACTIVITY_LOG_ENUM.PLANNING,
        userId: currentUserId,
        comment: different.join('\n'),
    }
    return different.length ? activityLog : null;
}