import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useBoolean } from "@fluentui/react-hooks";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useMutation, useQueryClient } from "react-query";
import { updateStaff } from "../../../redux/user/user.actions";
import { DeleteConfirmation } from "../../common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPowerOff } from '@fortawesome/pro-regular-svg-icons';

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: 60,
    },
    icon: {
        fontSize: 22,
        color: 'rgb(0, 108, 173)',
        cursor: 'pointer',
    },
});

const StaffControls = ({ updateStaff, staff, history, allowInactive = true }) => {
    const queryClient = useQueryClient();
    const [staffEditConfirmation, { toggle: toggleStaffEditConfirmation }] = useBoolean(false);

    const mutationEditStaff = useMutation((staffData) => updateStaff(staffData), {
        onSuccess: () => { 
            queryClient.invalidateQueries("staffs");
            queryClient.invalidateQueries("usersMissingInfo");
        } 
    });

    const onClickConfirm = () => {
        toggleStaffEditConfirmation();
        const temp = {...staff, inactive: !!!staff.inactive}
        mutationEditStaff.mutate(temp);
    }

    return (
        <Fragment>
            <div className={classNames.container}>
                {!staff.inactive && <FontAwesomeIcon icon={faEdit} className={classNames.icon} onClick={() => history.push(`/edit-staff/${staff.userId}`)}/>}
                {allowInactive && <FontAwesomeIcon icon={faPowerOff} className={classNames.icon} onClick={toggleStaffEditConfirmation} id={`editStaffId-${staff.userId}`}/>}
                {staffEditConfirmation && (
                    <DeleteConfirmation label={`Are you sure you want to ${staff.inactive ? 'active' : 'inactive'} this?`} target={`#editStaffId-${staff.userId}`} toggleTeaching={toggleStaffEditConfirmation} onClickConfirm={onClickConfirm}/>
                )}
            </div>
        </Fragment>
    );
};

export default connect(null, { updateStaff })(StaffControls);
