// Import libaries
import moment from "moment";
import { Rnd } from "react-rnd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Import types
import { TPlanHorizontalPositionMap } from "../../../types/planner";

// Import constants
import {
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  reasonVacationStaffOptions,
} from "../../../utils/constants";

// Import component
import { TooltipForText } from "../../common";

const textStyles: React.CSSProperties = {
  padding: "0 10px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

type StaffVacationBarProps = {
  start: string;
  end: string;
  yPos: number;
  horizontalPositionMap: TPlanHorizontalPositionMap;
  reason: number;
  notes: string;
  color?: string;
};

const StaffVacationBar = ({
  color = "26,147,111",
  end,
  horizontalPositionMap,
  notes,
  reason,
  start,
  yPos,
}: StaffVacationBarProps) => {
  const [x, setX] = useState(0);
  const [width, setWidth] = useState(0);

  const [startText, setStartText] = useState(null);
  const [endText, setEndText] = useState(null);

  const [display, setDisplay] = useState<boolean>(false);

  let textDisplay = `${reasonVacationStaffOptions[reason]["text"]}${
    notes ? ` (${notes})` : ""
  }`;

  // ======================= Use Selectors ========================================================
  const displayWeekView = useSelector(
    // @ts-ignore
    (state) => state.machine.displayWeekView
  ) as boolean;

  //   const calendarStepWidth = displayWeekView
  //     ? CALENDAR_WEEKVIEW_STEP_WIDTH
  //     : CALENDAR_STEP_WIDTH;
  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;

  useEffect(() => {
    if (!start || !end) {
      return;
    }
    let startDate = moment(start, "YYYY-M-D").format("YYYY-M-D");
    let endDate = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");
    const startCalendar = horizontalPositionMap.startText.split(".").reverse();
    const endCalendar = horizontalPositionMap.endText.split(".").reverse();
    const startDateBeforeStartCalendar =
      new Date(startDate).getTime() <
      new Date(
        +("20" + startCalendar[0]),
        +startCalendar[1] - 1,
        +startCalendar[2]
      ).getTime();
    const endDateAfterEndCalendar =
      new Date(endDate).getTime() >=
      new Date(
        +("20" + endCalendar[0]),
        +endCalendar[1] - 1,
        parseInt(endCalendar[2]) + 1
      ).getTime();
    let startTemp = startDateBeforeStartCalendar
      ? 0
      : horizontalPositionMap[startDate];
    let endTemp = endDateAfterEndCalendar
      ? horizontalPositionMap[endDate] || horizontalPositionMap.end + 1
      : horizontalPositionMap[endDate];

    // @ts-ignore
    let tempX = startTemp * calendarStepWidth;
    // @ts-ignore
    let tempWidth = (endTemp - startTemp) * calendarStepWidth;
    if (typeof startTemp === "undefined" && typeof endTemp !== "undefined") {
      tempX = 0;
      // @ts-ignore
      tempWidth = endTemp * calendarStepWidth;
    }
    if (typeof startTemp === "undefined" || typeof endTemp === "undefined") {
      setWidth(0);
      return;
    }
    if (typeof startTemp === "undefined" && typeof endTemp === "undefined") {
      // @ts-ignore
      tempWidth =
        // @ts-ignore
        (horizontalPositionMap["end"] - startTemp) * calendarStepWidth;
    }
    const startTextTemp = moment(start, "YYYY-M-D").format("D.M.YY");
    // @ts-ignore
    const endTextTemp = moment(end, "YYYY-M-D").format("D.M.YY");

    // @ts-ignore
    setStartText(startTextTemp);
    // @ts-ignore
    setEndText(endTextTemp);
    setX(tempX);
    setWidth(tempWidth);

    if (!horizontalPositionMap[startDate]) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [start, end, horizontalPositionMap, calendarStepWidth]);

  let renderDetail = (
    <TooltipForText text={textDisplay}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#fff",
        }}
      >
        <div style={textStyles}>{startText}</div>
        <div style={textStyles}>{textDisplay}</div>
        <div style={textStyles}>{endText}</div>
      </div>
    </TooltipForText>
  );

  return width > 0 && display ? (
    <Rnd
      style={{ zIndex: 3, top: 0, left: 0 }}
      size={{ width: width, height: 20 }}
      position={{ x: x, y: yPos || 0 }}
      enableResizing={false}
      disableDragging={true}
    >
      <div
        id="detailBar"
        style={{
          backgroundImage: `linear-gradient(45deg, rgba(${color},1) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(${color},1) 75%), linear-gradient(45deg, transparent 75%, rgba(${color},1) 75%), linear-gradient(45deg, rgba(${color},1) 25%, rgba(${color},0.5) 25%)`,
          backgroundSize: "38px 38px",
          backgroundPosition: "0 0, 0 0, -19px -19px, 19px 19px",
          display: "flex",
          marginBottom: 12,
          height: 36,
          borderRadius: 8,
        }}
      >
        <div
          style={{
            color: "#fff",
            fontWeight: "bold",
            height: "100%",
            width: "100%",
            fontSize: 13,
            lineHeight: "32px",
            margin: "2px 4px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#000",
            }}
          >
            {renderDetail}
          </div>
        </div>
      </div>
    </Rnd>
  ) : (
    <></>
  );
};

export default StaffVacationBar;
