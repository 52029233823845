export const AppActionTypes = {
  CHANGE_SELECTED_PIVOT: "CHANGE_SELECTED_PIVOT",
  CHANGE_SHOW_OTHER_SECTIONS: "CHANGE_SHOW_OTHER_SECTIONS",
  SET_SIDE_BAR_STATUS: "SET_SIDE_BAR_STATUS",
  CHANGE_SEARCH_OVERLAY: "CHANGE_SEARCH_OVERLAY",
  CHANGE_SEARCH_HISTORY: "CHANGE_SEARCH_HISTORY",
  CLEAR_SEARCH_HISTORY: "CLEAR_SEARCH_HISTORY",
  CHANGE_SEARCH_ENUM: "CHANGE_SEARCH_ENUM",
  CHANGE_CALENDAR_LENGTH: "CHANGE_CALENDAR_LENGTH",
  SET_BAR_DRAWER: "SET_BAR_DRAWER",
  SET_HIGHLIGHT_MACHINE: "SET_HIGHLIGHT_MACHINE",
  SET_HIGHLIGHT_STAFF: "SET_HIGHLIGHT_STAFF",
  SET_MACHINE_SERVICE_BUBBLE: "SET_MACHINE_SERVICE_BUBBLE",
  SET_STAFF_VACATION_BUBBLE: "SET_STAFF_VACATION_BUBBLE",
  CHANGE_CRM_OVERLAY: "CHANGE_CRM_OVERLAY",
  SELECT_CRM_TAB: "SELECT_CRM_TAB",
  DRAWING_PROJECT_ID: "DRAWING_PROJECT_ID",
  DRAWING_PROJECT_NAME: "DRAWING_PROJECT_NAME",
  SET_DRAWING_MACHINE_REQ_INDEX: "SET_DRAWING_MACHINE_REQ_INDEX",
  SET_CRM_COUNTER: "SET_CRM_COUNTER",
  SET_DARK_OVERLAY: "SET_DARK_OVERLAY",
  SET_PLAN_PLACE_HOLDER_DATA: "SET_PLAN_PLACE_HOLDER_DATA",
  SET_CURRENT_UNPLANNED_ITEM: "SET_CURRENT_UNPLANNED_ITEM",
  SET_SYNC_PROCESS: "SET_SYNC_PROCESS",
  TOGGLE_INACTIVE_PROJECT: "TOGGLE_INACTIVE_PROJECT",
  SET_TABLE_SORT: "SET_TABLE_SORT",
  SET_SYNC_LAST_CALL: "SET_SYNC_LAST_CALL",
  TOGGLE_INACTIVE_MISSING_USERS: "TOGGLE_INACTIVE_MISSING_USERS",
  SET_BAR_DRAWING_MACHINE_SERVICE: "SET_BAR_DRAWING_MACHINE_SERVICE",
  SET_WEEKS_SHOWN: "SET_WEEKS_SHOWN",
};
