import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { stationOptions, CALENDAR_PAGE } from "../../../../utils/constants";
import { onChangeFilterStaffRole, setFilter } from "../../../../redux/machines/machines.actions";
import { mergeStyleSets, Stack } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipForText } from "../../../common";
import { toggleSelectorItem } from "../../../../redux/machines/machines.utils";

const commonStationSelectorButton = {
    height: 20,
    borderRadius: 24,
    color: "#ffffff",
    background: "#006CAD",
    padding: "4px 16px",
    fontSize: 14,
    lineHeight: 20,
    margin: "8px 0px",
    cursor: "pointer",
    selectors: {
        ":hover": {
            color: "#DBDBDB",
        },
    },
};

const StationSelector = ({ calendarPage }) => {
    const classNames = mergeStyleSets({
        root: {
            width: 250,
            height: 104,
            marginLeft: calendarPage === CALENDAR_PAGE.DISPOSITION ? 40 : 20,
        },
        techDepartmentSection: {
            display: "flex",
            justifyContent: "flex-start",
        },
        button: [
            commonStationSelectorButton,
            {
                color: "#868685",
                background: "#DFDFDF",
                selectors: {
                    ":hover": {
                        color: "#DFDFDF",
                        background: "#868685",
                    },
                },
            },
        ],
        buttonSelected: commonStationSelectorButton,
        image: {
            marginTop: 2,
        },
        icon: {
            fontSize: 16,
        },
    });
    const dispatch = useDispatch();
    const { techDepartment } = useSelector((state) => state?.machine?.filter);
    const { techArea, staffRole } = useSelector((state) => state?.machine?.filter?.[techDepartment]);
    const displayStaffView = useSelector((state) => state.machine.displayStaffOnPlanning);
    const showFilterStaff = (calendarPage === CALENDAR_PAGE.DISPOSITION && displayStaffView) || calendarPage === CALENDAR_PAGE.STAFF;

    return (
        <div className={classNames.root}>
            <div className={classNames.techDepartmentSection}>
                <Stack horizontal="true" tokens={{ childrenGap: 8 }}>
                    {techDepartment &&
                        stationOptions[techDepartment]["options"].map((option) => (
                            <div
                                key={option["key"]}
                                className={techArea?.includes(option["key"]) ? classNames.buttonSelected : classNames.button}
                                onClick={() =>
                                    dispatch(
                                        setFilter({
                                            [techDepartment]: {
                                                techArea: toggleSelectorItem(techArea, option["key"]),
                                            }
                                        })
                                    )
                                }
                            >
                                {option["value"]}
                            </div>
                        ))}
                </Stack>
            </div>
            {showFilterStaff && (
                <Stack horizontal="true" tokens={{ childrenGap: 8 }}>
                    {staffRole.map((option, index) => (
                        <TooltipForText key={option["key"]} text={option.text} noWidth>
                            <div
                                className={option.isOn ? classNames.buttonSelected : classNames.button}
                                onClick={() => {dispatch(onChangeFilterStaffRole(index, staffRole, techDepartment, false))}}
                            >
                                <FontAwesomeIcon icon={option.icon} className={classNames.icon} />
                            </div>
                        </TooltipForText>
                    ))}
                </Stack>
            )}
        </div>
    );
};

export default StationSelector;
