import { IconButton, DirectionalHint } from "@fluentui/react";
import React from "react";
import { Formik } from "formik";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "../../../utils/utils";
import CalendarSchedule from "../../common/calendar/CalendarSchedule";
import FixPositionBubble from "../../common/FixPositionBubble";
import { getStaffs, updateStaff } from "../../../redux/user/user.actions";
import VacationStaffForm from "./VacationStaffForm";
import { setStaffVacationBubble } from "../../../redux/app/app.actions";
import { iconButtonStyles } from "../../../utils/theme";

const StaffVacationBubble = () => {
  const dispatch = useDispatch();
  const realPageWidth = document.documentElement.offsetWidth;
  const { openStaffVacationBubble } = useSelector((state) => state.app);
  const queryClient = useQueryClient();

  const { data, status } = useQuery("staffs", () => dispatch(getStaffs()), {
    staleTime: 36000,
  });

  const { mutate: updateStaffMutation } = useMutation(
    (staff) => dispatch(updateStaff(staff)),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("staffs");
          onExit();
        }
      },
    }
  );

  const validationSchema = Yup.object({
    staffIndex: Yup.number("Select a name").required("Staff name is required"),
    reason: Yup.mixed().oneOf([0, 1, 2]).required("Reason is required"),
  });

  const formSubmit = (formData) => {
    const [start, end] = formatDate(formData.period);
    let color = null;
    switch (formData.reason) {
      case 0:
        color = "245,124,0"; // orange
        break;
      case 1:
        color = "0,108,173"; // blue
        break;
      case 2:
        color = "26, 147, 111"; // green
        break;
      default:
    }
    const vacationStaffForm = {
      color,
      reason: formData.reason,
      notes: formData.notes,
      start,
      end,
    };
    if (data[formData.staffIndex]) {
      const starfVacations =
        data[formData.staffIndex].starfVacations &&
        data[formData.staffIndex].starfVacations.length > 0
          ? [...data[formData.staffIndex].starfVacations, vacationStaffForm]
          : [vacationStaffForm];
      const staff = { ...data[formData.staffIndex], starfVacations };
      updateStaffMutation(staff);
    }
  };

  const onExit = () => {
    dispatch(setStaffVacationBubble(false));
  };

  return (
    openStaffVacationBubble && (
      <FixPositionBubble
        calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
        target={realPageWidth > 1000 ? "#newStaffVacation" : "#showActions"}
        mainPositionRight={realPageWidth > 1000 ? "237.309px" : "-9px"}
        beakPositionRight={realPageWidth > 1000 ? "155.8px" : "52.5px"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "-15px",
          }}
        >
          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: "Cancel" }}
            ariaLabel="Close popup modal"
            onClick={() => onExit()}
          />
        </div>
        <div
          style={{
            zIndex: 2,
          }}
        >
          {status === "success" && data && (
            <Formik
              initialValues={{}}
              validationSchema={validationSchema}
              onSubmit={formSubmit}
            >
              {(props) => (
                <CalendarSchedule
                  target="staff"
                  targetInfo={data[props.values?.staffIndex]}
                >
                  <VacationStaffForm {...props} userData={data} />
                </CalendarSchedule>
              )}
            </Formik>
          )}
        </div>
      </FixPositionBubble>
    )
  );
};

export default StaffVacationBubble;
