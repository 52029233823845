import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/da";
import { Rnd } from "react-rnd";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { useHistory } from "react-router-dom";
import { TooltipForText } from "../../../common";
import {
  CALENDAR_STEP_WIDTH,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  CALENDAR_PAGE,
  crmSpecialStatus,
  COLOR_CONS_HEX,
} from "../../../../utils/constants";
import { connect, useDispatch } from "react-redux";
import { changePrebookingBubbleInfo } from "../../../../redux/prebooking/prebooking.actions";

const classNames = mergeStyleSets({
  icon: {
    color: "#fff",
    marginRight: 2,
    fontSize: 13,
    fontWeight: "lighter",
    selectors: {
      ":hover": {
        color: "#DBDBDB",
      },
    },
    cursor: "pointer",
  },
  draggable: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },
  outterContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  smallContent: {
    display: "block",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    cursor: "pointer",
  },
  innerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    paddingLeft: 5,
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    marginTop: 2,
  },
  projectName: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 11,
    marginRight: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  projectNo: {
    color: "#fff",
    fontWeight: 400,
    fontSize: 11,
    marginLeft: 8,
  },
  personResponsible: {
    color: "#fff",
    fontWeight: 400,
    fontSize: 14,
    marginRight: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const StaffBar = ({
  staff,
  horizontalPositions,
  verticalPositions,
  calendarStart,
  calendarEnd,
  calendarStepWidth = CALENDAR_STEP_WIDTH,
  displayStaffView,
  calendarPage,
  machineY,
  type,
  inactive,
  staffY,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    color = "26,147,111",
    start,
    end,
    id,
    indexMachineRequirement,
    crmProjectStatusCode,
  } = staff;
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [width, setWidth] = useState(250);
  const [display, setDisplay] = useState(false);
  const [calendarStepHeight, setCalendarStepHeight] = useState(
    CALENDAR_STAFFVIEW_STEP_HEIGHT
  );

  useEffect(() => {
    setCalendarStepHeight(CALENDAR_STAFFVIEW_STEP_HEIGHT);
  }, [displayStaffView]);

  useEffect(() => {
    let y = null;
    if (calendarPage !== CALENDAR_PAGE.DISPOSITION) {
      y = verticalPositions[id][staff.index] * calendarStepHeight;
    } else if (typeof machineY !== "undefined") {
      y = machineY;
    } else {
      y = staffY;
    }

    const keyStart = moment(start).utcOffset(0).format("YYYY-M-D");
    const keyEnd = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");

    let xPosition = horizontalPositions[keyStart];
    let endValue = horizontalPositions[keyEnd];

    if (
      new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
      new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime() &&
      typeof xPosition === "undefined" &&
      typeof endValue === "undefined"
    ) {
      xPosition = 0;
      endValue = horizontalPositions["end"];
    }

    if (
      new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
      typeof endValue !== "undefined"
    ) {
      xPosition = 0;
    }

    if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
      endValue = horizontalPositions["end"];
    }

    setY(y);
    if (typeof xPosition !== "undefined") {
      setX(xPosition * calendarStepWidth);
      setWidth((endValue - xPosition) * calendarStepWidth);
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [
    horizontalPositions,
    verticalPositions,
    calendarStart,
    calendarEnd,
    start,
    end,
    id,
    calendarStepWidth,
  ]);

  const onClickToEditPage = () => {
    type === "prebooking"
      ? dispatch(
          changePrebookingBubbleInfo({
            openOverlay: true,
            overlayPrebookingId: staff.id,
          })
        )
      : history.push(
          `/planning/${staff["projectId"]}/${indexMachineRequirement}`
        );
  };
  let content = (
    <div className={classNames.outterContent} onClick={onClickToEditPage}>
      <div className={classNames.innerContent}>
        <div className={classNames.textContainer}>
          <span className={classNames.projectName}>
            {crmSpecialStatus[crmProjectStatusCode] && (
              <span
                style={{
                  color: COLOR_CONS_HEX.ALERT,
                  fontWeight: "bold",
                  fontSize: 11,
                  marginRight: 8,
                }}
              >
                {crmSpecialStatus[crmProjectStatusCode].text}
              </span>
            )}
            {staff.projectName}
            <span className={classNames.projectNo}>{staff.projectNo}</span>
          </span>
          <span className={classNames.personResponsible}>
            {staff.personResponsbible ?? "Unknown"}
          </span>
        </div>
      </div>
    </div>
  );
  if (width <= calendarStepWidth * 3) {
    content = (
      <div className={classNames.smallContent} onClick={onClickToEditPage}>
        <span className={classNames.projectName} style={{ marginLeft: 3 }}>
          {crmSpecialStatus[crmProjectStatusCode] && (
            <span
              style={{
                color: COLOR_CONS_HEX.ALERT,
                fontWeight: "bold",
                fontSize: 11,
                marginRight: 8,
              }}
            >
              {crmSpecialStatus[crmProjectStatusCode].text}
            </span>
          )}
          {staff.projectName}
          <span className={classNames.projectNo}>{staff.projectNo}</span>
        </span>
      </div>
    );
  } else if (width <= 13 * calendarStepWidth) {
    content = (
      <div className={classNames.outterContent} onClick={onClickToEditPage}>
        <div className={classNames.innerContent}>
          <div className={classNames.textContainer} style={{ marginTop: 4 }}>
            <span className={classNames.projectName}>
              {crmSpecialStatus[crmProjectStatusCode] && (
                <span
                  style={{
                    color: COLOR_CONS_HEX.ALERT,
                    fontWeight: "bold",
                    fontSize: 11,
                    marginRight: 8,
                  }}
                >
                  {crmSpecialStatus[crmProjectStatusCode].text}
                </span>
              )}
              {staff.projectName}
              <span className={classNames.projectNo}>{staff.projectNo}</span>
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    display &&
    width > 0 && (
      <Rnd
        style={{ zIndex: 3, top: 0, left: 0, display: "absolute" }}
        size={{ width: width, height: calendarStepHeight }}
        position={{ x: x, y: y }}
        enableResizing={false}
        disableDragging={true}
        bounds=".planContainer"
      >
        <div
          className={classNames.draggable}
          style={{
            background: `rgba(${color},1)`,
            display: "flex",
            height: calendarStepHeight - 8,
            borderRadius: 4,
            borderBottom: !!staff?.isQuotation ? "4px solid #F28750" : "",
            boxSizing: "border-box",
            opacity:
              crmSpecialStatus[crmProjectStatusCode] || inactive ? 0.75 : 1,
          }}
        >
          <TooltipForText
            text={`${staff.projectName} ${
              type !== "prebooking" ? staff.projectNo : ""
            }`}
          >
            {content}
          </TooltipForText>
        </div>
      </Rnd>
    )
  );
};

const mapStateToProps = (state) => ({
  displayStaffView: state.machine.displayStaffOnPlanning,
});

export default connect(mapStateToProps, null)(StaffBar);
