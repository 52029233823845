import { PrebookingActionTypes } from './prebooking.types';

const initialState = {
    openOverlay: false,
    overlayPrebookingId: null,
    showPrebookingModal: false,
    isEditModal: false,
    bubbleTarget: null,
};

const prebookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case PrebookingActionTypes.UPDATE_OVERLAY_PREBOOKING_ID:
            return {
                ...state,
                overlayPrebookingId: action.payload,
            };
        case PrebookingActionTypes.CLEAR_OVERLAY_PREBOOKING_ID:
            return {
                ...state,
                overlayPrebookingId: null,
                openOverlay: false,
            };
        case PrebookingActionTypes.CHANGE_PREBOOKING_MODAL_INFO:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default prebookingReducer;