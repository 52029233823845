import moment from "moment";
import { TPlanHorizontalPositionMap } from "../types/planner";

export const getWidthStartEndNumberByHorizontalPositionMap = (
  horizontalPositionMap: TPlanHorizontalPositionMap,
  start: string,
  end: string,
  calendarStepWidth: number
) => {
  let tempStart = moment(start, "YYYY-M-D").format("YYYY-M-D");
  //   @ts-ignore
  tempStart = horizontalPositionMap[tempStart];
  let tempEnd = moment(end, "YYYY-M-D").format("YYYY-M-D");

  //   @ts-ignore
  tempEnd = horizontalPositionMap[tempEnd];
  //   @ts-ignore
  const width = (tempEnd - tempStart) * calendarStepWidth;
  const startText =
    //   @ts-ignore
    horizontalPositionMap["positionsToDates"][tempStart * calendarStepWidth];
  const endText =
    //   @ts-ignore
    horizontalPositionMap["positionsToDates"][tempEnd * calendarStepWidth];
  return { width, start: tempStart, end: tempEnd, startText, endText };
};
