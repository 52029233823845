import React, { Fragment } from "react";
import { Rnd } from "react-rnd";
import {
  CALENDAR_STEP_WIDTH,
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
} from "../../../../utils/constants";
import { lighterDarkerColor } from "../../../../utils/utils";
import { TooltipForText } from "../../../common";

const PreBookingProjectBar = ({
  machine: { start, width, projectName, personResponsbible },
  calendarStepWidth = CALENDAR_STEP_WIDTH,
}) => {
  const colorCode = "242, 135, 5";
  let bgColor = `repeating-linear-gradient(90deg, rgb(${colorCode}), rgb(${colorCode}) 24px, rgb(${lighterDarkerColor(
    colorCode,
    10
  )}) 24px, rgb(${lighterDarkerColor(colorCode, 10)}) 48px)`;

  let renderDetail = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
          paddingLeft: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: 11,
              marginRight: 8,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {projectName}
          </span>
          <span
            style={{
              color: "#fff",
              fontWeight: 400,
              fontSize: 14,
              marginRight: 8,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {personResponsbible?.name ?? "Unknown"}
          </span>
        </div>
      </div>
    </div>
  );

  if (width <= 11 * calendarStepWidth) {
    renderDetail = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
            paddingLeft: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: 11,
                marginRight: 8,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {projectName}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <Rnd
        style={{ zIndex: 3, top: 0, left: 0 }}
        size={{ width: width, height: CALENDAR_STAFFVIEW_STEP_HEIGHT }}
        position={{ x: start, y: 0 }}
        enableResizing={false}
        disableDragging={true}
      >
        <div
          style={{
            background: bgColor,
            display: "flex",
            height: CALENDAR_STAFFVIEW_STEP_HEIGHT - 8,
            borderRadius: 4,
          }}
        >
          <TooltipForText text={`${projectName}`}>
            {renderDetail}
          </TooltipForText>
        </div>
      </Rnd>
    </Fragment>
  );
};

export default PreBookingProjectBar;
