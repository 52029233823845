import { Spinner, SpinnerSize } from "@fluentui/react/";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import moment from "moment";
import "moment/locale/da";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { getPlans } from "../../redux/plan/plan.actions";
import { getPrebookings } from "../../redux/prebooking/prebooking.actions";
import { getMachines } from "../../redux/machines/machines.actions";
import {
  CALENDAR_STEP_WIDTH,
  months,
  stationOptions,
} from "../../utils/constants";
import { dynamicSort, useQueryParams } from "../../utils/utils";
import { DayLabel, MonthLabel, WeekLabel } from "../common/calendar";
import { MachineRequirement } from "./printPage/machines";

const classNames = mergeStyleSets({
  spinner: {
    marginTop: 24,
  },
  root: {
    margin: "0px 0 0 48px",
    minHeight: "100%",
    width: 3650,
    display: "flex",
    flexDirection: "row",
  },
  leftContainer: {
    width: 250,
    position: "sticky",
    top: 0,
    left: 0,
    marginTop: "57px",
  },
  rightContainer: {
    marginTop: "40px",
  },
  titleText: {
    fontSize: 18,
    fontWeight: 700,
  },
  machineText: {
    marginTop: 35,
    fontSize: 12,
    fontWeight: 700,
  },
  calendarContainer: {
    minHeight: "100%",
    width: 300,
  },
  days: {
    display: "flex",
    justifyContent: "flex-start",
  },
  dayOfWeeks: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 8,
  },
  weeks: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 4,
  },
  week: {
    minWidth: 175,
    height: 20,
    background: "#E7E7E7",
    fontSize: 11,
    lineHeight: 20,
    borderRadius: 10,
  },
});

const PrintMachines = ({ getPlans, getPrebookings, getMachines, dates }) => {
  const queryParams = useQueryParams();
  const printStart = new Date(queryParams.get("from"));
  const printEnd = new Date(queryParams.get("to"));

  const { data: machinesData } = useQuery("machines", getMachines);
  const { data: plansData, isLoading } = useQuery("printMachines", () =>
    getPlans(new Date(queryParams.get("from")).getFullYear())
  );
  const { data: preBookingData } = useQuery("preBookings", () =>
    getPrebookings(printStart.toISOString(), printEnd.toISOString())
  );
  const [totalHeight, setTotalHeight] = useState(0);
  const techDepartment = parseInt(queryParams.get("techDepartment"));
  const techArea = queryParams.get("techArea")
    ? queryParams.get("techArea")?.split(",").map(Number)
    : [];

  const getMonthIndicator = (dates) => {
    let components = [];
    // const currentMonth = calendar.month;
    let temp = dates[0][0]["month"];
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        if (dates[i][j]["month"] === temp) {
          counter++;
        } else {
          components.push({ length: counter, label: months[temp] });
          counter = 1;
          temp = dates[i][j]["month"];
        }
      }
    }
    components.push({ length: counter, label: months[temp] });
    return components;
  };

  const horizontalPositionMap = useMemo(() => {
    const result = {};
    const datePositionCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        //date: 1, month: 2, year: 2021
        let key = `${dates[i][j]["year"]}-${dates[i][j]["month"] + 1}-${
          dates[i][j]["date"]
        }`;
        result[key] = counter;
        datePositionCorrespondence[counter] = moment(key, "YYYY-M-D").format(
          "YYYY-MM-DD[T]HH:mm:ss.SSS"
        );
        counter++;
      }
    }
    result["end"] = --counter;
    result["positionsToDates"] = datePositionCorrespondence;
    return result;
  }, [dates]);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      const ElementStyleElements =
        document.getElementsByClassName("ElementStyle");
      let height = 0;
      for (let item of ElementStyleElements) {
        const style = item.currentStyle || window.getComputedStyle(item);
        const marginBottom = parseInt(
          style.marginBottom.replace(/\D/g, ""),
          10
        );
        height += item.offsetHeight + marginBottom;
      }
      setTotalHeight(height);
    }, 500);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const crewMachineServiceMap = useMemo(() => {
    if (!Array.isArray(machinesData)) {
      return {};
    }

    const map = {};

    machinesData.forEach((machine) => {
      const services = machine.machineService || [];

      services.forEach((service, serviceIndex) => {
        const crews = service.crews || [];

        crews.forEach((crew) => {
          const { id } = crew;

          if (!map[id]) {
            map[id] = [];
          }

          map[id].push({ crew, service, serviceIndex });
        });
      });
    });

    return map;
  }, [machinesData]);

  const isAvailableMachines = (plan, printStart, printEnd) => {
    for (let j = 0; j < plan?.machineRequirements.length; j++) {
      for (let k = 0; k < plan?.machineRequirements[j]?.machines.length; k++) {
        let startDate = new Date(
          plan?.machineRequirements[j]?.machines[k].start
        );
        let endDate = new Date(plan?.machineRequirements[j]?.machines[k].end);
        if (
          startDate <= printEnd &&
          endDate >= printStart &&
          !plan?.plans.inactive
        )
          return true;
      }
    }
    return false;
  };

  const newMachineSet = useMemo(() => {
    if (!plansData || !preBookingData || !machinesData) return [];
    let machinesWithPlan = [];
    plansData &&
      plansData.map((i) =>
        i.machineRequirements.map((j) =>
          j.machines.map(
            (k) =>
              k.techDepartments.includes(techDepartment) &&
              machinesWithPlan.push({
                machineId: k.machineId,
                machineName: k.machineName,
                machineType: k.machineType,
                techAreas: k.techAreas,
                techDepartments: k.techDepartments,
                inactive: k.inactive,
                sortIndex: k.sortIndex,
                plans: {
                  activityLogs: i.activityLogs,
                  crmProjectStatusCode: i.crmProjectStatusCode,
                  color: i.color,
                  createBy: i.createBy,
                  createdDate: i.createdDate,
                  end: i.end,
                  inactive: i.inactive,
                  reason: i.reason,
                  isQuotation: i.isQuotation,
                  modifiedDate: i.modifiedDate,
                  modifyBy: i.modifyBy,
                  personResponsbible: i.personResponsbible,
                  projectId: i.projectId,
                  projectName: i.projectName,
                  projectNo: i.projectNo,
                  section: i.section,
                  start: i.start,
                },
                machineRequirements: [
                  {
                    activityLogs: j.activityLogs,
                    drivers: j.drivers,
                    end: j.end,
                    inactive: j.inactive,
                    machineType: j.machineType,
                    machines: [k],
                    managers: j.managers,
                    sizeGroup: j.sizeGroup,
                    start: j.start,
                    techDepartment: j.techDepartment,
                    workers: j.workers,
                  },
                ],
              })
          )
        )
      );

    const otherMachines =
      machinesData
        ?.filter(
          (machine) =>
            !machine.inactive &&
            machine.techDepartments.includes(techDepartment) &&
            techArea.some((key) =>
              machine.techAreas.replace("-1", "").includes(key)
            ) &&
            !machinesWithPlan.some(
              (item) => item.machineId === machine.machineId
            )
        )
        .map((machine) => ({
          machineId: machine.machineId,
          machineName: machine.machineName,
          machineType: machine.machineType,
          techAreas: machine.techAreas,
          techDepartments: machine.techDepartments,
          inactive: machine.inactive,
          sortIndex: machine.sortIndex,
          plans: [],
          machineRequirements: [],
        })) || [];

    const allMachines = [...machinesWithPlan, ...otherMachines];

    let machinesWithDataKey = [];
    allMachines &&
      allMachines.forEach((r) => {
        if (!machinesWithDataKey[r.machineId]) {
          machinesWithDataKey[r.machineId] = {
            machineId: r.machineId,
            machineName: r.machineName,
            machineType: r.machineType,
            techAreas: r.techAreas,
            techDepartments: r.techDepartments,
            inactive: r.inactive,
            sortIndex: r.sortIndex,
            data: [],
          };
        }

        machinesWithDataKey[r.machineId].data.push(r);
      });

    const sortedPlanList = Object.values(machinesWithDataKey)
      .filter(
        (machine) =>
          !machine.inactive &&
          machine.techDepartments.includes(techDepartment) &&
          techArea.some((key) =>
            machine.techAreas.replace("-1", "").includes(key)
          )
      )
      .sort((a, b) => dynamicSort(["machineType", "machineName"])(a, b));

    const bookList =
      preBookingData &&
      preBookingData
        .map(
          (machine) =>
            machine.techDepartments.includes(techDepartment) && machine
        )
        .filter((i) => i !== false);

    let machinesWithReqKey = [];
    bookList &&
      bookList.forEach((r) => {
        if (!machinesWithReqKey[r.machineId]) {
          machinesWithReqKey[r.machineId] = {
            machineId: r.machineId,
            machineName: r.machineName,
            machineType: r.machineType,
            activityLogs: r.activityLogs,
            createdUserId: r.createdUserId,
            id: r.id,
            machineInactive: r.machineInactive,
            location: r.location,
            start: r.start,
            end: r.end,
            techAreas: r.techAreas,
            techDepartments: r.techDepartments,
            sortIndex: r.machineSortIndex,
            data: [],
          };
        }
        machinesWithReqKey[r.machineId].data.push({
          machineRequirements: [{ machines: [r] }],
        });
      });

    const sortedBookList = Object.values(machinesWithReqKey)
      .filter(
        (machine) =>
          !machine.inactive &&
          machine.techDepartments.includes(techDepartment) &&
          techArea.some((key) =>
            machine.techAreas.replace("-1", "").includes(key)
          )
      )
      .sort((a, b) => dynamicSort(["machineType", "machineName"])(a, b));

    const combinedList =
      sortedPlanList && sortedPlanList.concat(sortedBookList);

    let machineCombinesDataArray = [];
    combinedList &&
      combinedList.forEach((r) => {
        if (!machineCombinesDataArray[r.machineId]) {
          machineCombinesDataArray[r.machineId] = {
            machineInactive: r.machineInactive,
            machineId: r.machineId,
            machineName: r.machineName,
            machineType: r.machineType,
            techDepartments: r.techDepartments,
            techAreas: r.techAreas,
            sortIndex: r.sortIndex,
            data: [],
          };
        }
        machineCombinesDataArray[r.machineId].data.push(r.data || []);
      });

    const newMachineSet = Object.values(machineCombinesDataArray)
      .filter(
        (machine) =>
          !machine.machineInactive &&
          machine.techDepartments.includes(techDepartment) &&
          techArea.some((key) =>
            machine.techAreas.replace("-1", "").includes(key)
          )
      )
      .sort((a, b) => dynamicSort(["machineType", "machineName"])(a, b))
      .sort((a, b) => a.sortIndex - b.sortIndex);
    return newMachineSet;
  }, [plansData, machinesData, preBookingData]);

  return (
    <div id="printMachines" style={{ background: "#F1F1F1" }}>
      {isLoading ? (
        <Spinner
          className={classNames.spinner}
          size={SpinnerSize.large}
          label="Loading..."
        />
      ) : (
        <div>
          <div className={classNames.root}>
            <div className={classNames.leftContainer}>
              <div className={classNames.titleText}>
                {stationOptions[techDepartment]["text"]} uge{" "}
                {queryParams.get("fromWeek")} - {queryParams.get("toWeek")}
              </div>
              <div className={classNames.machineText}>MASKINE</div>
            </div>
            <div className={classNames.rightContainer}>
              <div className={classNames.stickyHeader}>
                <div className={classNames.machineAndCalendarContainer}>
                  <div className={classNames.calendarContainer}>
                    <div className={classNames.weeks}>
                      {dates.length &&
                        getMonthIndicator(dates).map(
                          (monthData, indexMonth) => (
                            <MonthLabel
                              index={indexMonth}
                              key={
                                indexMonth +
                                monthData["length"] +
                                monthData["label"]
                              }
                              length={monthData["length"]}
                              label={monthData["label"]}
                            />
                          )
                        )}
                    </div>
                    <div className={classNames.weeks}>
                      {dates.length > 0 &&
                        dates.map((week, indexWeek) => (
                          <WeekLabel
                            index={indexWeek}
                            month={week[0]["month"]}
                            day={week[0]["date"]}
                            year={week[0]["year"]}
                            key={`${week[0]["month"]}-${week[0]["date"]}-${week[0]["year"]}`}
                          />
                        ))}
                    </div>
                    <div className={classNames.days}>
                      {dates.length > 0 &&
                        dates.map((week) =>
                          week.map((each) => (
                            <DayLabel
                              label={each.date}
                              date={each}
                              key={JSON.stringify(each)}
                            />
                          ))
                        )}
                    </div>
                    <div className={classNames.dayOfWeeks}>
                      {dates.length > 0 &&
                        dates.map((week) =>
                          week.map((each) => (
                            <DayLabel
                              label={each.date}
                              date={each}
                              key={JSON.stringify(each)}
                              isDaysOfWeek
                              heightOfBackground={totalHeight}
                            />
                          ))
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames.machineAndCalendarContainer}>
                <div className={classNames.calendarContainer}>
                  <div
                    style={{
                      height: "fit-content",
                      width:
                        CALENDAR_STEP_WIDTH * horizontalPositionMap["end"] +
                        CALENDAR_STEP_WIDTH,
                      position: "relative",
                      backgroundImage: `url("${window.location.origin}/img/calendarBackground3.png")`,
                      backgroundRepeat: "repeat",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        top: 0,
                        left: 0,
                        zIndex: 556,
                        width:
                          CALENDAR_STEP_WIDTH * horizontalPositionMap["end"],
                        height: "100%",
                        marginTop: 5,
                      }}
                    >
                      {newMachineSet &&
                        crewMachineServiceMap &&
                        newMachineSet.map(
                          (set, index1) =>
                            set.techDepartments.includes(techDepartment) && (
                              <MachineRequirement
                                printStart={new Date(queryParams.get("from"))}
                                printEnd={new Date(queryParams.get("to"))}
                                techArea={techArea}
                                key={`${index1}`}
                                data={set.data}
                                horizontalPositionMap={horizontalPositionMap}
                                crewMachineServiceMap={crewMachineServiceMap}
                              />
                            )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, { getPlans, getPrebookings, getMachines })(
  PrintMachines
);
