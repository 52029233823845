// Bad name because some genius decided to create a component named MachineServiceBubble.js
// With react-query and mutation in it
// My god haven't this people heard of making components separated from the server integration
// Kill Me

import * as Yup from "yup";
import React from "react";
import FixPositionBubble from "../../common/FixPositionBubble";
import MachineServiceForm from "./MachineServiceForm";
import { DirectionalHint, IconButton } from "@fluentui/react";
import { Formik } from "formik";
import { iconButtonStyles } from "../../../utils/theme";

// targetId include the # at the front
const MachineServiceBubbleUI = ({
  onSubmit,
  targetId,
  onExit,
  machinesData,
  showMachineSelector,
  text = "Save",
}) => {
  const realPageWidth = document.documentElement.offsetWidth;

  const validationSchema = Yup.object({
    comment: Yup.string("Enter a comment").required("Comment is required"),
    reason: Yup.string("Select a reason").required("Reason is required"),
  });

  return (
    <FixPositionBubble
      calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
      target={targetId}
      mainPositionRight={realPageWidth > 1000 ? "253.645px" : "-9px"}
      beakPositionRight={realPageWidth > 1000 ? "155.8px" : "52.5px"}
      styles={{
        root: {
          zIndex: 4,
        },
      }}
      onDismiss={() => {
        // console.log("Test");
        // onExit();
      }}
    >
      <div
        id="Mayiru"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "-15px",
        }}
      >
        <IconButton
          styles={iconButtonStyles}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Close popup modal"
          onClick={() => onExit()}
        />
      </div>
      <div style={{ zIndex: 2 }}>
        <Formik
          initialValues={{
            comment: "",
            reason: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            // <CalendarSchedule
            //   target="machine"
            //   targetInfo={data[props.values?.machineIndex]}
            // >
            <MachineServiceForm
              {...props}
              showMachineSelector={showMachineSelector}
              data={machinesData}
              text={text}
            />
            // </CalendarSchedule>
          )}
        </Formik>
      </div>
    </FixPositionBubble>
  );
};

export default MachineServiceBubbleUI;
