import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import "moment/locale/da";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { saveMessage } from "../../../../redux/message/message.actions";
import { ROLES } from "../../../../utils/constants";
import { checkTimeOverlap } from "../../../../utils/utils";
import { TooltipForText } from "../../../common";

const classNames = mergeStyleSets({
  textStyles: {
    padding: "0 10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    userSelect: "none",
  },
});

const DetailPlanBarDrawer = ({
  calendarStepWidth,
  horizontalPositionMap,
  name,
  listToCheck,
  onSave,
  boundArea,
  targetData,
  setDrawData,
  role,
  machine,
  onDrawAddOverlap,
  backgroundColor = "#fff",
  color = "#000",
}) => {
  const dispatch = useDispatch();
  const startOfXAxis = 316;
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const userZoomRatio = 1;
  const [barInfo, setBarInfo] = useState({
    start: null,
    end: null,
  });
  const [x, setX] = useState(null);
  const [width, setWidth] = useState(0);
  const [display, setDisplay] = useState(false);
  const [drawing, setDrawing] = useState(true);
  const [scrollX, setScrollX] = useState(window.scrollX / userZoomRatio);
  const [scrollY, setScrollY] = useState(window.scrollY / userZoomRatio);
  const [containerPosition, setContainerPosition] = useState();

  useEffect(() => {
    changeCursorOfPlanContainer(drawing);
  }, [drawing]);

  useEffect(() => {
    const el = document.querySelector(`.${boundArea}`);

    if (el) {
      setContainerPosition(el.getBoundingClientRect());
    } else {
      const timeOutRef = setTimeout(() => {
        const el = document.querySelector(`.${boundArea}`);

        if (el) {
          setContainerPosition(el.getBoundingClientRect());
        }
      }, 350);

      return () => {
        clearTimeout(timeOutRef);
      };
    }
  }, [boundArea]);

  const changeCursorOfPlanContainer = (drawing) => {
    const planContainer = document.getElementsByClassName(
      "detailPlanContainer"
    )[0];
    planContainer.style.cursor = drawing
      ? `url(${window.location.origin}/img/pencil-alt.svg), auto`
      : "default";
  };

  const isOverlap = (newX, newWidth) => {
    const startDate = new Date(
      horizontalPositionMap.positionsToDates[newX].value.slice(0, 10)
    );
    let endDate = new Date(
      horizontalPositionMap.positionsToDates[newX + newWidth].value.slice(0, 10)
    );
    endDate.setDate(endDate.getDate() - 1);
    for (let i = 0; i < listToCheck.length; i++) {
      // This conditional is added because
      // We can now directly draw for a machine without it being added first
      // Issue 1252
      if (
        (listToCheck[i].startText?.value || listToCheck[i].start) &&
        (listToCheck[i].endText?.value || listToCheck[i].end)
      ) {
        let itemStartDate = new Date(
          (listToCheck[i].startText?.value || listToCheck[i].start).slice(0, 10)
        );
        let itemEndDate = new Date(
          (listToCheck[i].endText?.value || listToCheck[i].end).slice(0, 10)
        );
        const overlap = checkTimeOverlap(
          startDate,
          endDate,
          itemStartDate,
          itemEndDate
        );

        if (overlap) {
          onDrawAddOverlap && onDrawAddOverlap();
          dispatch(saveMessage("Cannot Overlap"));
          setDisplay(false);
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown, { passive: true });
    window.addEventListener("mousemove", handleMouseMove, { passive: true });
    window.addEventListener("mouseup", handleMouseUp, { passive: true });
    window.addEventListener("keydown", handleEscClick, { passive: true });
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("keydown", handleEscClick);
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleMouseDown = (e) => {
    if (drawing && containerPosition) {
      console.log(containerPosition);
      const xPosition = e.pageX / userZoomRatio - startOfXAxis;
      const yPosition = e.pageY / userZoomRatio;
      const elementsAtPosition = document.elementsFromPoint(
        e.pageX - window.pageXOffset,
        e.pageY - window.pageYOffset
      );
      const clickedOnAnotherBar = elementsAtPosition.some(
        (node) => node.id === "detailBar"
      );
      const delta =
        yPosition -
        scrollY -
        (containerPosition.y - containerPosition.height / 2);
      let startOfBar = Math.floor(xPosition / calendarStepWidth);
      if (delta > containerPosition.height || delta < 0 || clickedOnAnotherBar)
        cancelDrawBar();

      setBarInfo({
        start: startOfBar,
        end: startOfBar + 1,
        startText:
          horizontalPositionMap.positionsToDates[
            startOfBar * calendarStepWidth
          ],
        endText:
          horizontalPositionMap.positionsToDates[
            (startOfBar + 1) * calendarStepWidth
          ],
      });
    }
  };

  const handleMouseMove = (e) => {
    // Highlight the machine has same y axis
    if (drawing && containerPosition) {
      const yPosition = e.pageY / userZoomRatio;
      const delta =
        yPosition -
        scrollY -
        (containerPosition.y - containerPosition.height / 2);
      changeCursorOfPlanContainer(
        delta <= containerPosition.height && delta > 0
      );
    }

    if (drawing && barInfo.start !== null) {
      const xPosition = e.pageX / userZoomRatio - startOfXAxis;
      const endOfBar = Math.floor(xPosition / calendarStepWidth);
      setBarInfo({
        ...barInfo,
        end: endOfBar,
        endText:
          horizontalPositionMap.positionsToDates[
            (endOfBar - 1) * calendarStepWidth
          ],
      });
    }
  };

  const handleMouseUp = (e) => {
    if (drawing) {
      const xPosition = e.pageX / userZoomRatio - startOfXAxis;
      const end = Math.floor(xPosition / calendarStepWidth);
      const endOfBar = end === barInfo.start ? end + 1 : end - 1;
      const isBackwardDrawing = endOfBar < barInfo.start;
      let overlap = false;
      if (role !== ROLES.SUPERVISOR) {
        overlap = isOverlap(
          barInfo.start * calendarStepWidth,
          (endOfBar - barInfo.start) * calendarStepWidth
        );
      }
      if (isBackwardDrawing || overlap) cancelDrawBar();
      else {
        const endText =
          horizontalPositionMap.positionsToDates[endOfBar * calendarStepWidth];
        const width = (endOfBar - barInfo.start) * calendarStepWidth;
        const newTargetData = {
          ...targetData,
          start: barInfo.start,
          end: endOfBar,
          startText: barInfo.startText,
          endText,
          width,
        };

        role === ROLES.MACHINE
          ? onSave(newTargetData, false, true)
          : onSave(newTargetData, role, machine, false, false, true);
        resetDrawBar();
      }
    }
  };

  const handleEscClick = ({ key }) => {
    if (key === "Escape") cancelDrawBar();
  };

  const handleScroll = () => {
    setContainerPosition(
      document.querySelector(`.${boundArea}`).getBoundingClientRect()
    );
    setScrollX(window.scrollX / userZoomRatio);
    setScrollY(window.scrollY / userZoomRatio);
  };

  useEffect(() => {
    if (barInfo.start !== null && barInfo.end) {
      setX(barInfo.start * calendarStepWidth);
      setWidth((barInfo.end - barInfo.start) * calendarStepWidth);
      setDisplay(true);
    }
  }, [horizontalPositionMap, calendarStepWidth, barInfo]);

  const resetDrawBar = () => {
    setBarInfo({ start: null, end: null });
    setX(null);
    setWidth(0);
  };

  const cancelDrawBar = () => {
    changeCursorOfPlanContainer(false);
    setDrawing(false);
    setDrawData(null);
  };

  let renderDetail = (
    <TooltipForText text={name}>
      <div
        style={{
          display: "flex",
          justifyContent:
            width > 11 * calendarStepWidth ? "space-between" : "center",
          color,
          userSelect: "none",
        }}
      >
        {width > 11 * calendarStepWidth && (
          <div className={classNames.textStyles}>
            {barInfo.startText?.display}
          </div>
        )}
        <div className={classNames.textStyles}>{name}</div>
        {width > 11 * calendarStepWidth && (
          <div className={classNames.textStyles}>
            {barInfo.endText?.display}
          </div>
        )}
      </div>
    </TooltipForText>
  );

  return display && width > 0 ? (
    <Rnd
      style={{ zIndex: 3, top: 0, left: 0, position: "absolute" }}
      size={{ width: width, height: 20 }}
      position={{ x: x, y: 0 }}
      bounds={`.${boundArea}`}
      enableResizing={{
        left: false,
        right: false,
      }}
      disableDragging={true}
    >
      <div
        style={{
          backgroundColor,
          display: "flex",
          marginBottom: 12,
          height: 36,
          borderRadius: 8,
          userSelect: "none",
        }}
      >
        <div
          style={{
            color: "#fff",
            fontWeight: "bold",
            height: "100%",
            width: "100%",
            fontSize: 13,
            lineHeight: "32px",
            margin: "2px 4px",
          }}
        >
          {renderDetail}
        </div>
      </div>
    </Rnd>
  ) : (
    <></>
  );
};

export default DetailPlanBarDrawer;
