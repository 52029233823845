// Import libraries
import { useBoolean } from "@fluentui/react-hooks";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPowerOff,
  faTrashAlt,
  //   faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

// Import components
import { DeleteConfirmation } from "../../common";

const classNames = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: 60,
  },
  icon: {
    fontSize: 22,
    color: "rgb(0, 108, 173)",
    cursor: "pointer",
  },
});

type SkillControlsProps = {
  id: string;
  inactive: boolean;
  showInactive?: boolean;
  onClickEdit: () => void;
  onClickInactive: () => void;
  onClickDelete: () => void;
  showDelete?: boolean;
  showEdit?: boolean;
};

const SkillControls = ({
  id,
  onClickEdit,
  inactive,
  onClickInactive,
  showInactive = true,
  showEdit = true,
  onClickDelete,
  showDelete = false,
}: SkillControlsProps) => {
  const [
    inactiveConfirmation,
    { toggle: toggleInactiveConfirmation, setFalse: setInactiveFalse },
  ] = useBoolean(false);
  const [
    deleteConfirmation,
    { toggle: toggleDeleteConfirmation, setFalse: setDeleteFalse },
  ] = useBoolean(false);

  const onClickInactiveConfirm = () => {
    if (onClickInactive) {
      onClickInactive();
      setInactiveFalse();
    }
  };

  const onClickDeleteConfirm = () => {
    if (onClickDelete) {
      onClickDelete();
      setDeleteFalse();
    }
  };

  const handleClickEdit = () => {
    if (onClickEdit) {
      onClickEdit();
    }
  };

  const inactiveTargetId = `editSkillId-${id}`;
  const deleteTargetId = `deleteSkillId-${id}`;

  return (
    <>
      <div className={classNames.container}>
        {showEdit && (
          <FontAwesomeIcon
            icon={faEdit as IconDefinition}
            className={classNames.icon}
            onClick={handleClickEdit}
          />
        )}
        {/* Inactive */}
        {showInactive && (
          <FontAwesomeIcon
            icon={faPowerOff as IconDefinition}
            className={classNames.icon}
            onClick={() => {
              toggleInactiveConfirmation();
            }}
            id={inactiveTargetId}
          />
        )}
        {inactiveConfirmation && (
          <DeleteConfirmation
            label={`Are you sure you want to ${
              inactive ? "active" : "inactive"
            } this?`}
            target={`#${inactiveTargetId}`}
            toggleTeaching={toggleInactiveConfirmation}
            onClickConfirm={onClickInactiveConfirm}
          />
        )}
        {/* Delete */}
        {showDelete && (
          <FontAwesomeIcon
            icon={faTrashAlt as IconDefinition}
            className={classNames.icon}
            onClick={() => {
              toggleDeleteConfirmation();
            }}
            id={deleteTargetId}
          />
        )}
        {deleteConfirmation && (
          <DeleteConfirmation
            label={`Are you sure you want to delete this?`}
            target={`#${deleteTargetId}`}
            toggleTeaching={toggleDeleteConfirmation}
            onClickConfirm={onClickDeleteConfirm}
          />
        )}
      </div>
    </>
  );
};

export default SkillControls;
