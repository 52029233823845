import React, { useEffect, useState, useMemo, Fragment, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useQueryClient, useMutation } from "react-query";
import moment from "moment";
import "moment/locale/da";
import { Rnd } from "react-rnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faEye,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";

// Import constants
import {
  calendarConfirmationStatus,
  CALENDAR_STEP_WIDTH,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
} from "../../../utils/constants";

// Import redux
import {
  saveMessage,
  showConflictMessage,
} from "../../../redux/message/message.actions";
import { updateMachine } from "../../../redux/machines/machines.actions";

// Import components
import { TooltipForText, DeleteConfirmation } from "../../common";
import ConfirmBubble from "../../common/ConfirmBubble";

import {
  findStaffSchedule,
  hasEditRight,
  isProdUrl,
} from "../../../utils/utils";

// Import types
import {
  TMachine,
  TMachineService,
  TPlan,
  User,
  UserWorkerVM,
} from "../../../types";
import {
  PlanVerticalPositionMap,
  TPlanHorizontalPositionMap,
} from "../../../types/planner";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useHistory } from "react-router-dom";
import { MessageBarType, TeachingBubble } from "@fluentui/react";
import { getStringifiedMachine } from "../../../redux/machines/machine.selector";
import { findMostSuitableTime, getRole } from "../../../utils/mapping";
import { OverlapKeys } from "../../../redux/project/project.actions";
import { getCrewServiceSchedule } from "../../../utils/service";

const classNames = mergeStyleSets({
  icon: {
    color: "#575756",
    marginRight: 2,
    fontSize: 13,
    fontWeight: "lighter",
    width: 10,
    selectors: {
      ":hover": {
        color: "#DBDBDB",
      },
    },
    cursor: "pointer",
  },
  iconTooltip: {
    color: "#006CAD",
    marginRight: 2,
    fontSize: 13,
    fontWeight: "lighter",
    selectors: {
      ":hover": {
        color: "#000080",
      },
    },
    cursor: "pointer",
  },
});

const convertTimeToNumber = (
  horizontalPositions: TPlanHorizontalPositionMap,
  time: string
) => {
  const formattedDate = moment(time, "YYYY-M-D").format("YYYY-M-D");
  return horizontalPositions[formattedDate] || null;
};

type MachineServiceItemProps = {
  machineService: TMachineService;
  machineServiceIndex: number;
  machine: TMachine;
  // machines: TMachine[];
  machineId: string;
  start: string;
  end: string;
  horizontalPositions: TPlanHorizontalPositionMap;
  verticalPositions: PlanVerticalPositionMap;
  calendarStart: Date;
  calendarEnd: Date;
  updateMachine: any;
  saveMessage: any;
  viewOnly?: boolean;
  isPlanningPage: boolean;
  renderDependencies?: any;
  staffsData: User[];
  machines: TMachine[];
  allPlansData: TPlan[];
};

const MachineServiceItem = ({
  machineService,
  machineServiceIndex,
  machine,
  // machines,
  machineId,
  start,
  end,
  horizontalPositions,
  verticalPositions,
  calendarStart,
  calendarEnd,
  updateMachine,
  saveMessage,
  viewOnly = false,
  isPlanningPage,
  staffsData = [],
  allPlansData,
  machines,
  renderDependencies: propRenderDependencies,
}: MachineServiceItemProps) => {
  const history = useHistory();

  const userRoles = useSelector(
    // @ts-ignore
    (state) => state?.user?.user?.workingRole
  ) as string;
  const allowEdit = hasEditRight(userRoles);
  viewOnly = !allowEdit;
  const [
    teachingBubbleMachineService,
    { toggle: toggleTeachingMachineService },
  ] = useBoolean(false);

  const onClickEditMachineService = () => {
    history.push(`/machine-service/${machineId}/${machineServiceIndex}`);
  };

  // const calendarState = JSON.stringify(
  //   // @ts-ignore
  //   useSelector((state) => state.machine)
  // ) as string;

  const calendarState = useSelector(getStringifiedMachine);

  const displayWeekView = useSelector(
    // @ts-ignore
    (state) => state.machine.displayWeekView
  ) as boolean;

  const openActionId = `open-action-${machine.machineId}-${machineServiceIndex}`;

  const deleteId = `deleteMachineService-${machine.machineId}-${machineServiceIndex}`;

  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [width, setWidth] = useState(250);
  const [display, setDisplay] = useState(false);
  const [confirmation, setConfirmation] = useState(
    calendarConfirmationStatus.CONFIRMATION_UNSET
  );
  const dispatch = useDispatch();

  const needConfirmation =
    confirmation !== calendarConfirmationStatus.CONFIRMATION_UNSET;
  const colorCode = "241,241,241";
  let bgColor = `repeating-linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255) 24px, rgb(${colorCode}) 24px, rgb(${colorCode}) 48px)`;
  const queryClient = useQueryClient();
  const [updatePackage, setUpdatePackage] = useState(null);
  const ref = useRef<string | null>(null);
  const renderDependencies =
    propRenderDependencies || calendarState + JSON.stringify(verticalPositions);
  if (!ref.current) ref.current = renderDependencies;
  const shouldComponentRerender = ref.current !== renderDependencies;
  const [openActions, { toggle: toggleOpenActions }] = useBoolean(false);

  const verticalPositionsRef = useRef({} as any);
  if (!verticalPositionsRef.current || shouldComponentRerender)
    verticalPositionsRef.current = verticalPositions;
  const listMachineService = useMemo(
    () =>
      machine?.machineService.map((item) => {
        const start = convertTimeToNumber(horizontalPositions, item.start);
        const end = convertTimeToNumber(horizontalPositions, item.end);
        return { start, end };
      }) || [],
    [machine?.machineService, horizontalPositions]
  );

  // @ts-ignore
  const { mutate: mutateUpdateMachineService } = useMutation(
    // @ts-ignore
    (machine) => updateMachine(machine.data),
    {
      onSuccess: (data, variables) => {
        setUpdatePackage(null);
        if (data !== true) {
          // @ts-ignore
          rollback(variables.rollbackData);
        }
      },
      // @ts-ignore
      onFailure: (data, variables) => {
        setUpdatePackage(null);
        rollback(variables.rollbackData);
      },
      onSettled: () => {
        queryClient.invalidateQueries("machines");
      },
    }
  );

  useEffect(() => {
    if (
      confirmation === calendarConfirmationStatus.CONFIRMATION_UNSET &&
      ((!x && !y) || shouldComponentRerender)
    ) {
      if (shouldComponentRerender) ref.current = renderDependencies;
      let yPosition = verticalPositions[machine["machineId"]];
      if (typeof yPosition === "undefined") {
        if (machineId) {
          setDisplay(false);
          return;
        } else {
          // @ts-ignore
          if (machine["position"]) {
            // @ts-ignore
            yPosition = machine["position"];
          }
        }
      }

      const keyStart = moment(start.replace(".000", "Z"))
        .utcOffset(0)
        .format("YYYY-M-D");
      const keyEnd = moment(end.replace(".000", "Z"))
        .add(1, "d")
        .utcOffset(0)
        .format("YYYY-M-D");

      let xPosition = horizontalPositions[keyStart];
      let endValue = horizontalPositions[keyEnd];

      if (
        new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
        new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime() &&
        typeof xPosition === "undefined" &&
        typeof endValue === "undefined"
      ) {
        xPosition = 0;
        endValue = horizontalPositions["end"];
      }

      if (
        new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
        typeof endValue !== "undefined"
      ) {
        xPosition = 0;
      }

      if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
        endValue = horizontalPositions["end"];
      }

      // @ts-ignore
      setY(yPosition * CALENDAR_STEP_HEIGHT);
      if (typeof xPosition !== "undefined") {
        // @ts-ignore
        setX(xPosition * calendarStepWidth);
        // @ts-ignore
        setWidth((endValue - xPosition) * calendarStepWidth);

        setDisplay(true);
      } else {
        setDisplay(false);
      }
    }
  }, [
    start,
    end,
    machineId,
    horizontalPositions,
    verticalPositions,
    calendarStart,
    calendarEnd,
    machine,
    calendarStepWidth,
    renderDependencies,
    confirmation,
    shouldComponentRerender,
    x,
    y,
  ]);

  const rollback = ({
    previousX,
    previousY,
    previousW,
  }: {
    previousX: number;
    previousY: number;
    previousW: number;
  }) => {
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
    setX(previousX);
    setY(previousY);
    setWidth(previousW);
    setUpdatePackage(null);
  };

  const update = ({
    newX,
    newY,
    newW,
    previousX,
    previousY,
    previousW,
  }: {
    newX: number;
    newY: number;
    newW: number;
    previousX: number;
    previousY: number;
    previousW: number;
  }) => {
    const newMachine = { ...machine };
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);

    let newStartDate = null;
    let newEndDate = null;

    if (newX !== previousX) {
      newStartDate =
        horizontalPositions["positionsToDates"][
          Math.round(newX) / calendarStepWidth
        ];
      if (!newStartDate) {
        return false;
      }

      // @ts-ignore
      newMachine.machineService[machineServiceIndex].start = newStartDate;
    }

    if (newX !== previousX || newW !== previousW) {
      const endPositionKey = (newW + Math.round(newX)) / calendarStepWidth;
      newEndDate = horizontalPositions["positionsToDates"][endPositionKey];
      if (!newEndDate) {
        return false;
      }

      // @ts-ignore
      const newEndDateMinusOneDay = moment(newEndDate)
        .subtract(1, "d")
        .format("YYYY-MM-DD[T]00:00:00.000");
      newMachine.machineService[machineServiceIndex].end =
        newEndDateMinusOneDay;
    }

    if (newX === previousX) {
      newStartDate =
        horizontalPositions["positionsToDates"][
          Math.round(newX) / calendarStepWidth
        ];
      if (!newStartDate) {
        return false;
      }

      // @ts-ignore
      newMachine.machineService[machineServiceIndex].start = newStartDate;
    }

    const crews = newMachine.machineService[machineServiceIndex].crews;

    if (!newStartDate || !newEndDate) {
      return;
    }

    let hasConflict = false;
    let conflictMessage = "";

    for (let i = 0; i < crews.length && hasConflict === false; i++) {
      const crew = crews[i];

      const foundUser = staffsData.find((el) => el.userId === crew.id);

      if (foundUser) {
        const { end, start } = findMostSuitableTime({
          // @ts-ignore
          start: newStartDate,
          // @ts-ignore
          end: newEndDate,
          allUserData: {
            array: [],
            staffSchedule: findStaffSchedule(
              foundUser.userId,
              "",
              getRole(foundUser.workingRole) as OverlapKeys,
              allPlansData
            ),
            staffVacation: foundUser.starfVacations || [],
            serviceSchedules: getCrewServiceSchedule(
              foundUser.userId,
              machines,
              {
                ignoreMachineId: machineId,
                ignoreServiceIndex: machineServiceIndex,
              }
            ),
            staffData: foundUser,
            id: foundUser.userId,
          },
        });

        if (!end || !start) {
          conflictMessage = `${foundUser.firstName} ${foundUser.lastName} has an overlap`;
          hasConflict = true;
        } else {
          crew.start = start;
          crew.end = end;

          crews[i] = crew;
        }
      }
    }

    newMachine.machineService[machineServiceIndex].crews = crews;

    if (hasConflict) {
      setTimeout(() => onClickEditMachineService(), 1000);
      setTimeout(
        () =>
          dispatch(
            showConflictMessage(conflictMessage, MessageBarType.warning)
          ),
        3000
      );
    }

    // @ts-ignore
    mutateUpdateMachineService({
      data: newMachine,
      rollbackData: { previousX, previousY, previousW },
    });
  };

  //Current implementation only checking the overlapping of the same machine service
  const isOverlap = (newX: number, newWidth: number, newY: number | null) => {
    const tempY = newY ?? y;

    const tempList = JSON.parse(
      JSON.stringify(verticalPositionsRef.current["positionsToIds"][tempY])
    );
    const newStart = newX / calendarStepWidth;
    const newEnd = newStart + newWidth / calendarStepWidth;
    // const tempList = JSON.parse(JSON.stringify(listMachineService));
    // tempList.splice(machineServiceIndex, 1);
    // for (let i = 0; i < tempList.length; i++) {
    //   if (
    //     (newStart >= tempList[i].start && newEnd <= tempList[i].end) ||
    //     (newStart < tempList[i].start && newEnd > tempList[i].start) ||
    //     (newStart < tempList[i].end && newEnd > tempList[i].end)
    //   ) {
    //     saveMessage("Cannot Overlap");
    //     setDisplay(false);
    //     setDisplay(true);
    //     return true;
    //   }
    // }
    if (y === tempY) {
      const preStart = x / calendarStepWidth;
      const preEnd = preStart + width / calendarStepWidth;
      const index = tempList.listOfService.findIndex(
        (item: any) => item.start === preStart && preEnd
      );
      index !== -1 && tempList.listOfService.splice(index, 1);
    }
    const listToCheck = [...tempList.listOfPlan, ...tempList.listOfService];
    for (let i = 0; i < listToCheck.length; i++) {
      const startBeforeCurrentPlanEnd =
        typeof listToCheck[i].end === "undefined"
          ? true
          : newStart <= listToCheck[i].end;
      const endAfterCurrentPlanStart =
        typeof listToCheck[i].start === "undefined"
          ? true
          : newEnd > listToCheck[i].start;

      if (startBeforeCurrentPlanEnd && endAfterCurrentPlanStart) {
        saveMessage("Cannot Overlap");
        return true;
      }
    }
    return false;
  };

  const onClickConfirmDelete = (machineServiceIndex: number) => {
    const machineData = { ...machine };
    machineData.machineService.splice(machineServiceIndex, 1);
    toggleTeachingMachineService();

    // @ts-ignore
    mutateUpdateMachineService({ data: machineData });
  };

  let text = machineService["comment"];

  if (machineService["reason"]) {
    text += ` (${machineService["reason"]})`;
  }

  const content = useMemo(
    () =>
      width <= 100 ? (
        width <= 50 ? (
          <div
            className={
              !viewOnly && !needConfirmation ? "dragHandle" : "dragDisabled"
            }
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: 5,
              justifyContent: "flex-start",
              alignItems: "center",
              width: "calc(100% - 15px)",
              height: "100%",
              cursor: !viewOnly
                ? `url(${window.location.origin}/img/arrows-alt.svg), auto`
                : "default",
            }}
          >
            {/* {!viewOnly ? (
              <>
                <TooltipForText text={text}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      height: "100%",
                      width: "calc(100% - 13px)",
                    }}
                  >
                    <div
                      style={{
                        color: "#575756",
                        fontWeight: "bold",
                        fontSize: 11,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: width - 18,
                      }}
                    >
                      {text}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        width: "100%",
                        color: "#575756",
                        marginTop: 8,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisH as IconDefinition}
                        className={classNames.icon}
                        id={openActionId}
                        onClick={() => {
                          toggleOpenActions();
                        }}
                        style={{
                          marginRight: 16,
                        }}
                      />
                    </div>
                  </div>
                </TooltipForText>
              </>
            ) : null} */}
            <TooltipForText text={text}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "100%",
                  width: "calc(100% - 13px)",
                }}
              >
                <div
                  style={{
                    color: "#575756",
                    fontWeight: "bold",
                    fontSize: 11,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: width - 18,
                  }}
                >
                  {text}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    color: "#575756",
                    marginTop: 8,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisH as IconDefinition}
                    className={classNames.icon}
                    id={openActionId}
                    onClick={() => {
                      toggleOpenActions();
                    }}
                    style={{
                      marginRight: 16,
                    }}
                  />
                </div>
              </div>
            </TooltipForText>
          </div>
        ) : (
          <div
            className={
              !viewOnly && !needConfirmation ? "dragHandle" : "dragDisabled"
            }
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: 5,
              justifyContent: "flex-start",
              alignItems: "center",
              width: "calc(100% - 15px)",
              height: "100%",
              cursor: !viewOnly
                ? `url(${window.location.origin}/img/arrows-alt.svg), auto`
                : "default",
            }}
          >
            <TooltipForText text={text}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "100%",
                  width: "calc(100% - 13px)",
                }}
              >
                <div
                  style={{
                    color: "#575756",
                    fontWeight: "bold",
                    fontSize: 11,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: width - 18,
                  }}
                >
                  {text}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    color: "#575756",
                    marginTop: 8,
                  }}
                >
                  {!isProdUrl() ? (
                    <FontAwesomeIcon
                      icon={
                        viewOnly
                          ? (faEye as IconDefinition)
                          : (faEdit as IconDefinition)
                      }
                      className={classNames.icon}
                      id={`edit-${machine.machineId}-${machineServiceIndex}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickEditMachineService();
                      }}
                      style={{
                        marginRight: 16,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {!viewOnly ? (
                    <FontAwesomeIcon
                      icon={faTrashAlt as IconDefinition}
                      className={classNames.icon}
                      id={deleteId}
                      onClick={toggleTeachingMachineService}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </TooltipForText>
          </div>
        )
      ) : (
        <div
          className={
            !viewOnly && !needConfirmation ? "dragHandle" : "dragDisabled"
          }
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: viewOnly ? 5 : 10,
            justifyContent: "flex-start",
            alignItems: "center",
            width: "calc(100% - 18px)",
            height: "100%",
            cursor: !viewOnly
              ? `url(${window.location.origin}/img/arrows-alt.svg), auto`
              : "default",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              width: "calc(100% - 18px)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "flex-end",
                marginTop: 4,
              }}
            >
              <div>
                <TooltipForText text={text}>
                  <div
                    style={{
                      color: "#575756",
                      fontWeight: "bold",
                      fontSize: 11,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: width - 18,
                      marginLeft: 7,
                    }}
                  >
                    {text}
                  </div>
                </TooltipForText>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                width: "100%",
                color: "#575756",
                marginBottom: 8,
              }}
            >
              {/* {!viewOnly && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: 16,
                      marginLeft: 5,
                    }}
                    id={`edit-${machine.machineId}-${machineServiceIndex}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickEditMachineService();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faEdit as IconDefinition}
                      style={{ marginRight: 4, fontSize: 13 }}
                      className={classNames.icon}
                    />
                    <span style={{ color: "#575756", fontSize: 11 }}>Edit</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: 16,
                      marginLeft: 5,
                    }}
                    id={`deleteMachineService-${machine.machineId}-${machineServiceIndex}`}
                    onClick={toggleTeachingMachineService}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt as IconDefinition}
                      style={{ marginRight: 4, fontSize: 13 }}
                      className={classNames.icon}
                    />
                    <span style={{ color: "#575756", fontSize: 11 }}>
                      Delete
                    </span>
                  </div>
                </>
              )} */}
              <>
                {!isProdUrl() ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: 16,
                      marginLeft: 5,
                    }}
                    id={`edit-${machine.machineId}-${machineServiceIndex}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickEditMachineService();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        viewOnly
                          ? (faEye as IconDefinition)
                          : (faEdit as IconDefinition)
                      }
                      style={{ marginRight: 4, fontSize: 13 }}
                      className={classNames.icon}
                    />
                    <span style={{ color: "#575756", fontSize: 11 }}>
                      {viewOnly ? "View" : "Edit"}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                {!viewOnly ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: 16,
                      marginLeft: 5,
                    }}
                    id={`deleteMachineService-${machine.machineId}-${machineServiceIndex}`}
                    onClick={toggleTeachingMachineService}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt as IconDefinition}
                      style={{ marginRight: 4, fontSize: 13 }}
                      className={classNames.icon}
                    />
                    <span style={{ color: "#575756", fontSize: 11 }}>
                      Delete
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </>
            </div>
          </div>
        </div>
      ),
    [width, calendarStepWidth]
  );

  return (
    display &&
    width > 0 && (
      <Fragment>
        <Rnd
          style={{ zIndex: 3, top: 0, left: 0, display: "absolute" }}
          // default={{ x: x, y: y, width: width, height: 48}}
          size={{ width: width, height: CALENDAR_STEP_HEIGHT }}
          position={{ x: x, y: y }}
          enableResizing={{
            left: !viewOnly && !needConfirmation,
            right: !viewOnly && !needConfirmation,
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            const prevX = x;
            const prevW = width;
            const newX = parseInt(position.x.toFixed(0));

            let newWidth = ref.offsetWidth;
            // Sometime the offsetWidth value maybe differnt 1 or 2 pixel which causing wrong value, below block code handle that case
            if (newWidth % calendarStepWidth !== 0) {
              newWidth =
                // @ts-ignore
                (newWidth / calendarStepWidth).toFixed(0) * calendarStepWidth;
            }
            if (newX === prevX && newWidth === prevW) {
              return;
            }
            if (isOverlap(newX, newWidth, null)) {
              // @ts-ignore
              rollback({ previousY: y, previousW: prevW });
              return;
            }
            setX(newX);
            setWidth(newWidth);
            setUpdatePackage({
              // @ts-ignore
              newX: newX,
              newY: y,
              newW: newWidth,
              previousX: prevX,
              previousY: y,
              previousW: prevW,
            });
            setConfirmation(
              calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
            );
          }}
          onDragStop={(e, d) => {
            const prevX = x;
            const prevY = y;
            const newX = parseInt(d.lastX.toFixed(0));
            const newY = parseInt(d.lastY.toFixed(0));
            if ((d.lastX === prevX && d.lastY === prevY) || d.lastY !== prevY) {
              return;
            }
            if (isOverlap(newX, width, newY)) {
              return;
            }
            setX(d.lastX);
            setY(d.lastY);
            setUpdatePackage({
              // @ts-ignore
              newX: d.lastX,
              newY: d.lastY,
              newW: width,
              previousX: x,
              previousY: y,
              previousW: width,
            });
            setConfirmation(
              calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
            );
          }}
          resizeGrid={[calendarStepWidth, CALENDAR_STEP_HEIGHT]}
          dragGrid={[calendarStepWidth, CALENDAR_STEP_HEIGHT]}
          dragAxis="both"
          bounds={
            isPlanningPage ? ".planContainer" : ".machineServiceContainer"
          }
          dragHandleClassName="dragHandle"
        >
          <div
            id={machineId}
            style={{
              backgroundImage: bgColor,
              backgroundSize: "48px 48px",
              backgroundPosition: "0 0, 0 0, -24px -24px, 24px 24px",
              display: "flex",
              marginTop: 5,
              height: 48,
              border: "2px solid #868685",
              boxSizing: "border-box",
              borderRadius: 4,
            }}
          >
            {confirmation ===
              calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED &&
              typeof updatePackage === "object" && (
                <ConfirmBubble
                  // @ts-ignore
                  onCancel={() => rollback(updatePackage)}
                  // @ts-ignore
                  onApprove={() => update(updatePackage)}
                  targetId={machineId}
                />
              )}
            {content}
          </div>
        </Rnd>
        {teachingBubbleMachineService && (
          <DeleteConfirmation
            target={`#deleteMachineService-${machine.machineId}-${machineServiceIndex}`}
            toggleTeaching={toggleTeachingMachineService}
            onClickConfirm={() => {
              onClickConfirmDelete(machineServiceIndex);
            }}
          />
        )}
        {openActions && (
          <TeachingBubble
            target={`#${openActionId}`}
            hasSmallHeadline={true}
            onDismiss={() => {
              toggleOpenActions();

              if (teachingBubbleMachineService) {
                toggleTeachingMachineService();
              }
            }}
            closeButtonAriaLabel="Close"
            styles={{
              subComponentStyles: {
                callout: {
                  beak: {
                    display: "none",
                  },
                  root: {
                    marginLeft: -10,
                    maxWidth: 130,
                    height: 30,
                  },
                },
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                width: 100,
                marginLeft: -7,
                marginBottom: -8,
                marginTop: -7,
              }}
            >
              {!isProdUrl() ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={onClickEditMachineService}
                >
                  <FontAwesomeIcon
                    icon={
                      allowEdit
                        ? (faEdit as IconDefinition)
                        : (faEye as IconDefinition)
                    }
                    style={{ fontSize: 13 }}
                    className={classNames.iconTooltip}
                  />
                  )
                </div>
              ) : (
                <></>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  toggleTeachingMachineService();
                  // onClickConfirmDelete(machineServiceIndex);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt as IconDefinition}
                  style={{ fontSize: 13 }}
                  className={classNames.iconTooltip}
                  id={deleteId}
                />
              </div>
            </div>
          </TeachingBubble>
        )}
      </Fragment>
    )
  );
};

export default connect(null, { updateMachine, saveMessage })(
  // @ts-ignore
  MachineServiceItem
);
