import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";
import { CALENDAR_STEP_WIDTH } from "../../../../utils/constants";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import {
  ProjectBar,
  MachineServiceStaffVacationBar,
  PreBookingProjectBar,
} from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserHardHat, faDigging } from "@fortawesome/pro-regular-svg-icons";

const classNames = mergeStyleSets({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  pageContentContainer: {
    background: "#F1F1F1",
    width: "100%",
  },
  pageInnerContainer: {
    margin: "40px 0 40px 118px",
  },
  calendarContainer: {
    minHeight: "100%",
    width: 300,
    marginLeft: 200,
  },
  days: {
    display: "flex",
    justifyContent: "flex-start",
  },
  dayOfWeeks: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 8,
  },
  weeks: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 4,
  },
  week: {
    minWidth: 175,
    height: 20,
    background: "#E7E7E7",
    fontSize: 11,
    lineHeight: 20,
    borderRadius: 10,
  },
  image: {
    // marginRight: '15px',
    fontSize: 18,
  },
  iconPlusContainer: {
    position: "relative",
    left: -64,
    top: 10,
  },
  iconPlus: {
    fontSize: 28,
    height: 29,
    color: "#006CAD",
    selectors: {
      ":hover": {
        color: "#DBDBDB",
      },
    },
    cursor: "pointer",
  },
  functionalIcon: {
    fontSize: 18,
    height: 29,
    color: "#006CAD",
    selectors: {
      ":hover": {
        color: "#DBDBDB",
      },
    },
    cursor: "pointer",
  },
  separatorContainerStyle: {
    marginTop: 60,
    height: "20px",
    position: "relative",
    left: -230,
    width: "calc(100% + 230px)",
  },
  separatorStyle: {
    border: "1px solid #DFDFDF",
  },
  ElementStyle: {
    position: "relative",
    minHeight: 100,
    marginBottom: 40,
  },
  titleElementStyle: {
    display: "flex",
    alignItems: "center",
    // height: '100px',
    position: "absolute",
    left: -269,
  },
  titleElementStyleIndent: {
    display: "flex",
    alignItems: "center",
    // height: '100px',
    position: "absolute",
    left: -269,
    paddingLeft: "27px",
  },
  titleNameElementStyle: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    marginRight: "16px",
    fontSize: "11px",
  },
  contentElementStyle: {
    position: "relative",
    width: "100%",
    // top: 25,
    // minHeight: 100
  },
  avatarContainerStyle: {
    position: "relative",
    left: -88,
    top: 5,
    width: 76,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  personaStyle: {
    width: 28,
  },
  textBold: {
    fontWeight: "700",
    marginLeft: "2px",
  },
  textNormal: {
    fontWeight: "400",
    marginLeft: "2px",
  },
  iconContainer: {
    width: 24,
  },
});

const getWidthStartEndNumberByHorizontalPositionMap = (
  horizontalPositionMap,
  start,
  end,
  printStart,
  printEnd
) => {
  const keyStart = moment(start).utcOffset(0).format("YYYY-M-D");
  const keyEnd = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");

  let xPosition = horizontalPositionMap[keyStart];
  let endValue = horizontalPositionMap[keyEnd];

  if (
    new Date(start.slice(0, -1))?.getTime() <= printStart?.getTime() &&
    new Date(end.slice(0, -1))?.getTime() >= printEnd?.getTime() &&
    typeof xPosition === "undefined" &&
    typeof endValue === "undefined"
  ) {
    xPosition = 0;
    endValue = horizontalPositionMap["end"];
  }

  if (
    new Date(start.slice(0, -1))?.getTime() <= printStart?.getTime() &&
    typeof endValue !== "undefined"
  ) {
    xPosition = 0;
  }

  if (new Date(end.slice(0, -1))?.getTime() >= printEnd?.getTime()) {
    endValue = horizontalPositionMap["end"];
  }
  const width = (endValue - xPosition) * CALENDAR_STEP_WIDTH;
  return { width, start: xPosition * CALENDAR_STEP_WIDTH, end: endValue };
};

const MachineRequirement = ({
  data,
  horizontalPositionMap,
  printStart,
  printEnd,
  techArea = [],
  crewMachineServiceMap,
}) => {
  const [machines, setMachines] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [drivers, setDrivers] = useState([]);

  const machinesData = data.map((r) =>
    r.map((t) => t.machineRequirements.map((v) => v.machines).shift())
  );
  const driversData = data.map((r) =>
    r.map((t) => t.machineRequirements.map((v) => v.drivers).shift())
  );
  const workersData = data.map((r) =>
    r.map((t) => t.machineRequirements.map((v) => v.workers).shift())
  );
  const plan = data[0].map((t) => t.plans);

  useEffect(() => {
    const tempMachines =
      machinesData &&
      machinesData.map((target, index1) =>
        target.map((c, index2) =>
          c?.reduce((array, item) => {
            const { width, start, end } =
              getWidthStartEndNumberByHorizontalPositionMap(
                horizontalPositionMap,
                item.start,
                item.end,
                printStart,
                printEnd
              );
            let planDetails = [];
            target[0] && planDetails.push(plan[index2]);

            const machine = {
              ...item,
              start,
              end,
              width,
              planDetails,
            };

            const index = array.findIndex(
              (item1) => item1.id === item.machineId
            );
            if (item.techAreas !== "-1") {
              if (index !== -1) {
                if (
                  techArea.some((key) => item.techAreas.includes(key)) &&
                  !Number.isNaN(start) &&
                  !Number.isNaN(end) &&
                  typeof !Number.isNaN(width)
                ) {
                  array[index].array.push(machine);
                }
              } else if (
                techArea.some((key) => item.techAreas.includes(key)) &&
                !Number.isNaN(start) &&
                !Number.isNaN(end) &&
                typeof !Number.isNaN(width)
              ) {
                array.push({
                  id: item.machineId,
                  machineName: item.machineName,
                  array: [machine],
                  machineService: item.machineService,
                });
              }
            } else {
              if (index !== -1) {
                if (
                  !Number.isNaN(start) &&
                  !Number.isNaN(end) &&
                  typeof !Number.isNaN(width)
                ) {
                  array[index].array.push(machine);
                }
              } else if (
                !Number.isNaN(start) &&
                !Number.isNaN(end) &&
                typeof !Number.isNaN(width)
              ) {
                array.push({
                  id: item.machineId,
                  machineName: item.machineName,
                  array: [machine],
                });
              }
            }

            return array;
          }, [])
        )
      );

    if (driversData[0] || workersData[0]) {
      const [tempDrivers, tempWorkers] = [driversData[0], workersData[0]].map(
        (target, index1) =>
          target.map((c, index2) =>
            c !== undefined
              ? c?.reduce((array, item) => {
                  const { width, start, end } =
                    getWidthStartEndNumberByHorizontalPositionMap(
                      horizontalPositionMap,
                      item.start,
                      item.end,
                      printStart,
                      printEnd
                    );
                  let planDetails = [];
                  target[0] && planDetails.push(plan[index2]);

                  const user = {
                    ...item,
                    userName: `${item.firstName} ${item.lastName}`,
                    userId: item.id,
                    imageUrl: item.imageUrl,
                    start,
                    end,
                    width,
                    planDetails,
                  };
                  const index = array.findIndex(
                    (item1) => item1.id === item.id
                  );
                  if (index !== -1) {
                    if (
                      techArea.includes(item.techArea) &&
                      !Number.isNaN(start) &&
                      !Number.isNaN(end) &&
                      typeof !Number.isNaN(width)
                    ) {
                      array[index].array.push(user);
                    }
                  } else if (
                    techArea.includes(item.techArea) &&
                    !Number.isNaN(start) &&
                    !Number.isNaN(end) &&
                    typeof !Number.isNaN(width)
                  ) {
                    array.push({
                      id: item.id,
                      userName: `${item.firstName} ${item.lastName ?? ""}`,
                      array: [user],
                      staffVacation: item.starfVacations,
                    });
                  }
                  return array;
                }, [])
              : []
          )
      );
      setDrivers(tempDrivers);
      setWorkers(tempWorkers);
    }
    setMachines(tempMachines);
  }, []);

  let tempList = [];
  let tempList2 = [];
  let tempList3 = [];
  let machineList = [];
  let driverList = [];
  let workerList = [];

  machines &&
    machines.map((r) =>
      r.map((v) =>
        v?.reduce((array, i) => {
          tempList.push(i);
        }, [])
      )
    );
  drivers &&
    drivers.map((v) =>
      v.reduce((array, i) => {
        tempList2.push(i);
      }, [])
    );
  workers &&
    workers.map((v) =>
      v.reduce((array, i) => {
        tempList3.push(i);
      }, [])
    );

  tempList.forEach((z) => {
    if (!machineList[z.id]) {
      machineList[z.id] = {
        id: z.id,
        machineName: z.machineName,
        array: [],
      };
    }
    machineList[z.id].array.push(z.array);
  });

  tempList2.forEach((z) => {
    if (!driverList[z.id]) {
      driverList[z.id] = {
        id: z.id,
        userName: z.userName,
        array: [],
      };
    }
    driverList[z.id].array.push(z.array);
  });

  tempList3.forEach((z) => {
    if (!workerList[z.id]) {
      workerList[z.id] = {
        id: z.id,
        userName: z.userName,
        array: [],
      };
    }
    workerList[z.id].array.push(z.array);
  });

  const singleMachine = Object.values(machineList).length
    ? Object.values(machineList)
    : [
        {
          id: data[0][0].machineId,
          machineName: data[0][0].machineName,
          array: [],
        },
      ];
  const singleDriver = Object.values(driverList);
  const singleWorker = Object.values(workerList);

  const displayMachineService = (machineList) => {
    return machineList.array.map((m) =>
      m.map(
        (r) =>
          r.machineService &&
          r?.machineService.map((itemMachineService, indexMachineService) => {
            let startDate = new Date(itemMachineService.start);
            let endDate = new Date(itemMachineService.end);

            if (
              (startDate <= printStart && printEnd <= endDate) ||
              (printStart <= startDate && endDate <= printEnd) ||
              (startDate < printStart &&
                printStart <= endDate &&
                endDate <= printEnd) ||
              (printStart <= startDate &&
                startDate <= printEnd &&
                printEnd < endDate)
            ) {
              return (
                <div key={indexMachineService} style={{ position: "relative" }}>
                  <MachineServiceStaffVacationBar
                    key={indexMachineService}
                    item={itemMachineService}
                    horizontalPositions={horizontalPositionMap}
                    isMachineService
                    calendarStart={printStart}
                    calendarEnd={printEnd}
                  />
                </div>
              );
            }
          })
      )
    );
  };

  const displayStaffVacation = (userList) => {
    const arr = userList.array.map((m) =>
      m.map(
        (r) =>
          r.starfVacations &&
          r?.starfVacations.map((itemStaffService, indexStaffVacation) => {
            let startDate = new Date(itemStaffService.start);
            let endDate = new Date(itemStaffService.end);

            if (
              (startDate <= printStart && printEnd <= endDate) ||
              (printStart <= startDate && endDate <= printEnd) ||
              (startDate < printStart &&
                printStart <= endDate &&
                endDate <= printEnd) ||
              (printStart <= startDate &&
                startDate <= printEnd &&
                printEnd < endDate)
            ) {
              return (
                <div
                  key={`vacation-index-${indexStaffVacation}-${userList.id}`}
                  style={{ position: "relative" }}
                >
                  <MachineServiceStaffVacationBar
                    key={indexStaffVacation}
                    item={itemStaffService}
                    horizontalPositions={horizontalPositionMap}
                    calendarStart={printStart}
                    calendarEnd={printEnd}
                  />
                </div>
              );
            }
          })
      )
    );

    const servicesArr = [];

    const services = crewMachineServiceMap[userList.id];

    if (Array.isArray(services)) {
      services.forEach(({ serviceIndex, service, crew }) => {
        const { start, end } = crew;
        let startDate = new Date(start);
        let endDate = new Date(end);

        if (
          (startDate <= printStart && printEnd <= endDate) ||
          (printStart <= startDate && endDate <= printEnd) ||
          (startDate < printStart &&
            printStart <= endDate &&
            endDate <= printEnd) ||
          (printStart <= startDate &&
            startDate <= printEnd &&
            printEnd < endDate)
        ) {
          return (
            <div
              key={`service-machine-index-${serviceIndex}-${userList.id}`}
              style={{ position: "relative" }}
            >
              <MachineServiceStaffVacationBar
                key={serviceIndex}
                item={service}
                horizontalPositions={horizontalPositionMap}
                calendarStart={printStart}
                calendarEnd={printEnd}
              />
            </div>
          );
        }
      });
    }

    return [...arr, ...servicesArr];
  };

  return (
    singleMachine.length > 0 && (
      <div
        className="ElementStyle"
        style={{
          position: "relative",
          marginBottom:
            machines.length > 0 || drivers.length > 0 || workers.length > 0
              ? 18
              : 0,
        }}
      >
        {/* Machines */}
        {singleMachine.map((machineList, index) => (
          <Fragment key={`${machineList.id}-${index}`}>
            <div className={classNames.titleElementStyle}>
              <div className={classNames.titleNameElementStyle}>
                <div className={classNames.iconContainer}>
                  <Image
                    src={`${window.location.origin}/img/machineBlack.svg`}
                    imageFit={ImageFit.cover}
                    alt="machine"
                    width={18}
                    height={18}
                    className={classNames.image}
                  />
                </div>
                <span className={classNames.textBold}>
                  {machineList.machineName}
                </span>
              </div>
            </div>
            <div className={classNames.contentElementStyle}>
              <div style={{ zIndex: 556, width: "100%", height: 32 }}>
                {displayMachineService(machineList)}
                {machineList.array.map((machine, index2) =>
                  machine.map((r) =>
                    r.projectName ? (
                      <PreBookingProjectBar
                        index={index2}
                        key={`${index2}${r.machineId}`}
                        machine={r}
                      />
                    ) : (
                      r.planDetails &&
                      r.planDetails.map(
                        (p, index3) =>
                          !p.inactive && (
                            <ProjectBar
                              plan={p}
                              horizontalPositions={horizontalPositionMap}
                              index={index3}
                              key={`m-${index3}`}
                              machine={r}
                            />
                          )
                      )
                    )
                  )
                )}
              </div>
            </div>
            <hr
              style={{
                border: "0.6px solid rgb(219, 219, 219, 0.5)",
                width: "calc(100% + 420px)",
                height: "0.1px",
                backgroundColor: "rgb(219, 219, 219, 0.5)",
                margin: "-8px 0 2px -420px",
              }}
            />
          </Fragment>
        ))}
        {/* Drivers */}
        {singleDriver.map((driverList, index) => (
          <Fragment key={`${driverList.id}-${index}`}>
            <div className={classNames.titleElementStyleIndent}>
              <div className={classNames.titleNameElementStyle}>
                <div className={classNames.iconContainer}>
                  <FontAwesomeIcon
                    icon={faUserHardHat}
                    className={classNames.image}
                  />
                </div>
                <span className={classNames.textNormal}>
                  {driverList.userName}
                </span>
              </div>
            </div>
            <div className={classNames.contentElementStyle}>
              <div style={{ zIndex: 556, width: "100%", height: 32 }}>
                {/* {driverList?.staffVacation ? (
                                driverList?.staffVacation.map((itemVacation, indexVacation) => (
                                    <div key={indexVacation} style={{position: 'relative'}}>
                                        <MachineServiceStaffVacationBar key={indexVacation} item={itemVacation} horizontalPositions={horizontalPositionMap}/>
                                    </div>
                                ))
                            ) : null} */}
                {displayStaffVacation(driverList)}
                {driverList.array.map((driver, index2) =>
                  driver.map(
                    (r) =>
                      r.planDetails &&
                      r.planDetails.map(
                        (p, index3) =>
                          !p.inactive && (
                            <ProjectBar
                              plan={p}
                              horizontalPositions={horizontalPositionMap}
                              index={index3}
                              key={`${Math.random()}`}
                              machine={r}
                            />
                          )
                      )
                  )
                )}
              </div>
            </div>
            <hr
              style={{
                border: "0.6px solid rgb(219, 219, 219, 0.5)",
                width: "calc(100% + 420px)",
                height: "0.1px",
                backgroundColor: "rgb(219, 219, 219, 0.5)",
                margin: "-8px 0 2px -420px",
              }}
            />
          </Fragment>
        ))}
        {/* Workers */}
        {singleWorker.map((workerList, index) => (
          <Fragment key={`${workerList.id}-${index}`}>
            <div className={classNames.titleElementStyleIndent}>
              <div className={classNames.titleNameElementStyle}>
                <div className={classNames.iconContainer}>
                  <FontAwesomeIcon
                    icon={faDigging}
                    className={classNames.image}
                  />
                </div>
                <span className={classNames.textNormal}>
                  {workerList.userName}
                </span>
              </div>
            </div>
            <div className={classNames.contentElementStyle}>
              <div style={{ zIndex: 556, width: "100%", height: 32 }}>
                {/* {workerList?.staffVacation ? (
                                workerList?.staffVacation.map((itemVacation, indexVacation) => (
                                    <div key={indexVacation} style={{position: 'relative'}}>
                                        <MachineServiceStaffVacationBar key={indexVacation} item={itemVacation} horizontalPositions={horizontalPositionMap}/>
                                    </div>
                                ))
                            ) : null} */}
                {displayStaffVacation(workerList)}
                {workerList.array.map((worker, index2) =>
                  worker.map(
                    (r) =>
                      r.planDetails &&
                      r.planDetails.map(
                        (p, index3) =>
                          !p.inactive && (
                            <ProjectBar
                              plan={p}
                              horizontalPositions={horizontalPositionMap}
                              index={index3}
                              key={`${Math.random()}`}
                              machine={r}
                            />
                          )
                      )
                  )
                )}
              </div>
            </div>
            <hr
              style={{
                border: "0.6px solid rgb(219, 219, 219, 0.5)",
                width: "calc(100% + 420px)",
                height: "0.1px",
                backgroundColor: "rgb(219, 219, 219, 0.5)",
                margin: "-7px 0 2px -420px",
              }}
            />
          </Fragment>
        ))}
      </div>
    )
  );
};

export default MachineRequirement;
