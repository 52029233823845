import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

const classNames = mergeStyleSets({
    icon: {
        color: '#006CAD',
        margin: 8,
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
});

const TablePeriodDisplay = ({start, end}) => {
    return (
        <div className={classNames.container} >
            {moment(start).format('DD.MM.YYYY')}
            <FontAwesomeIcon icon={faLongArrowRight} className={classNames.icon}/>
            {moment(end).format('DD.MM.YYYY')}
        </div>
    )
}

export default TablePeriodDisplay
