import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/da";
import { Rnd } from "react-rnd";
import {
  reasonVacationStaffOptions,
  CALENDAR_STEP_WIDTH,
} from "../../../../utils/constants";
import { TooltipForText } from "../../../common";

const textStyles = {
  padding: "0 10px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const MachineServiceStaffVacationBar = ({
  start,
  end,
  horizontalPositionMap,
  text,
  notes,
  isMachineService = false,
  color = "26,147,111",
  calendarStepWidth = CALENDAR_STEP_WIDTH,
}) => {
  const [x, setX] = useState(0);
  const [width, setWidth] = useState(0);
  // const [display, setDisplay] = useState(true);
  const [startText, setStartText] = useState(null);
  const [endText, setEndText] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  let textDisplay = text;
  if (!isMachineService) {
    textDisplay = `${reasonVacationStaffOptions[text]["text"]}${
      notes ? ` (${notes})` : ""
    }`;
  }
  useEffect(() => {
    if (!start || !end) {
      return;
    }
    let startDate = moment(start, "YYYY-M-D").format("YYYY-M-D");
    let endDate = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");
    const startCalendar = horizontalPositionMap.startText.split(".").reverse();
    const endCalendar = horizontalPositionMap.endText.split(".").reverse();
    const startDateBeforeStartCalendar =
      new Date(startDate).getTime() <
      new Date(
        "20" + startCalendar[0],
        startCalendar[1] - 1,
        startCalendar[2]
      ).getTime();
    const endDateAfterEndCalendar =
      new Date(endDate).getTime() >=
      new Date(
        "20" + endCalendar[0],
        endCalendar[1] - 1,
        parseInt(endCalendar[2]) + 1
      ).getTime();
    let startTemp = startDateBeforeStartCalendar
      ? 0
      : horizontalPositionMap[startDate];
    let endTemp = endDateAfterEndCalendar
      ? horizontalPositionMap[endDate] || horizontalPositionMap.end + 1
      : horizontalPositionMap[endDate];
    let tempX = startTemp * calendarStepWidth;
    let tempWidth = (endTemp - startTemp) * calendarStepWidth;
    if (typeof startTemp === "undefined" && typeof endTemp !== "undefined") {
      tempX = 0;
      tempWidth = endTemp * calendarStepWidth;
    }
    if (typeof startTemp === "undefined" || typeof endTemp === "undefined") {
      setWidth(0);
      return;
    }
    if (typeof startTemp === "undefined" && typeof endTemp === "undefined") {
      tempWidth =
        (horizontalPositionMap["end"] - startTemp) * calendarStepWidth;
    }
    const startTextTemp = moment(start, "YYYY-M-D").format("D.M.YY");
    const endTextTemp = moment(end, "YYYY-M-D").format("D.M.YY");
    setStartText(startTextTemp);
    setEndText(endTextTemp);
    setX(tempX);
    setWidth(tempWidth);
  }, [start, end, horizontalPositionMap, calendarStepWidth]);

  let renderDetail = (
    <TooltipForText text={textDisplay}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#fff",
        }}
      >
        <div style={textStyles}>{startText}</div>
        <div style={textStyles}>{textDisplay}</div>
        <div style={textStyles}>{endText}</div>
      </div>
    </TooltipForText>
  );
  if (width <= 275) {
    renderDetail = (
      <TooltipForText text={textDisplay}>
        <div
          style={{ display: "flex", justifyContent: "center", color: "#fff" }}
        >
          <div style={textStyles}>{textDisplay}</div>
        </div>
      </TooltipForText>
    );
  }

  return width > 0 ? (
    <Rnd
      style={{ zIndex: isHovering ? 5 : 3, top: 0, left: 0 }}
      size={{ width: width, height: 20 }}
      position={{ x: x, y: 0 }}
      enableResizing={false}
      disableDragging={true}
    >
      <div
        id="detailBar"
        style={{
          backgroundImage: `linear-gradient(45deg, rgba(${color},1) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(${color},1) 75%), linear-gradient(45deg, transparent 75%, rgba(${color},1) 75%), linear-gradient(45deg, rgba(${color},1) 25%, rgba(${color},0.5) 25%)`,
          backgroundSize: "38px 38px",
          backgroundPosition: "0 0, 0 0, -19px -19px, 19px 19px",
          display: "flex",
          marginBottom: 12,
          height: 36,
          borderRadius: 8,
        }}
        //   onMouseOver={(e) => {
        //     setIsHovering(true);
        //   }}
        //   onMouseLeave={(e) => {
        //     setIsHovering(false);
        //   }}
        //   onMouseDown={(e) => {
        //     setIsHovering(false);
        //   }}
      >
        <div
          style={{
            color: "#fff",
            fontWeight: "bold",
            height: "100%",
            width: "100%",
            fontSize: 13,
            lineHeight: "32px",
            margin: "2px 4px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#000",
            }}
          >
            {renderDetail}
          </div>
        </div>
      </div>
    </Rnd>
  ) : (
    <></>
  );
};

export default MachineServiceStaffVacationBar;
