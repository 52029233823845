import {
  DirectionalHint,
  Dropdown,
  TeachingBubble,
  TextField,
} from "@fluentui/react";
import React, { useEffect, useState, useRef } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";
import { reasonVacationStaffOptions } from "../../utils/constants";
import { dropDownStyles, inputStyles } from "../../utils/theme";

const classNames = mergeStyleSets({
  modalContainer: {
    display: "flex",
    flexDirection: "column",
  },
  radioContainer: {
    display: "flex",
    marginBottom: 17,
    color: "black",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: 224,
    marginLeft: -15,
    marginTop: 10,
  },
  icon: {
    fontSize: 24,
    color: "#006CAD",
    cursor: "pointer",
  },
});

/* when using css zoom, we need to adjust the position of bubble manually, 
that's why this component's position is complicated
We will get the x position from the mouse and y position from the target */
const ConfirmStaffVacationBubble = ({
  onCancel,
  onApprove,
  targetId,
  reason = 0,
  notes = "",
  setReason,
  setNotes,
}) => {
  const targetPosition = document
    .getElementById(targetId)
    ?.getBoundingClientRect();
  const [mousePosition, setMousePosition] = useState(null);
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const userZoomRatio = 1;
  const [bubbleInitiated, setBubbleInitiated] = useState(false);
  const mouse = useRef(null);
  if (mousePosition && !mouse.current) {
    mouse.current = mousePosition;
  }

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove, { passive: true });
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  });

  const handleMouseMove = (e) => {
    if (!mousePosition) {
      setMousePosition({
        x: e.pageX,
        y: e.pageY,
      });
    }
  };

  const bubbleStyles = () => {
    setTimeout(() => {
      const teachingBubble = document.querySelectorAll(".ms-TeachingBubble")[0];
      const beak = document.querySelectorAll(".ms-Callout-beak")[0];
      if (teachingBubble) {
        setBubbleInitiated(true);
        teachingBubble.style.bottom = `-${targetPosition.top - 10}px`;
        beak.style.display = "block";
      }
    }, 10);
    const delta =
      (mouse.current?.x - window.pageXOffset) / userZoomRatio +
      56 -
      window.innerWidth;
    return delta > 48 ? 48 : delta > 0 ? delta : 0;
  };

  const onRenderOption = (option) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ flexBasis: "80%", width: "80%%" }}>{option.text}</div>
      </div>
    );
  };

  return mouse.current && targetPosition ? (
    <TeachingBubble
      target={{
        x: (mouse.current.x - window.pageXOffset) / userZoomRatio,
        y: targetPosition.top,
      }}
      calloutProps={{
        directionalHint: DirectionalHint.topCenter,
        calloutWidth: 250,
        calloutHeight: 36,
      }}
      styles={{
        root: {
          left: (mouse.current.x - window.pageXOffset) / userZoomRatio - 56,
        },
        subComponentStyles: {
          callout: {
            calloutMain: {
              display: bubbleInitiated ? "block" : "none",
            },
            beak: {
              marginRight: bubbleStyles(),
            },
          },
        },
      }}
    >
      <div className={classNames.modalContainer}>
        <Dropdown
          label="ÅRSAG"
          selectedKey={reason}
          name="Reason"
          onChange={(event, item) => setReason(item.key)}
          placeholder="ÅRSAG"
          options={reasonVacationStaffOptions}
          styles={{
            ...dropDownStyles,
            dropdown: { ...dropDownStyles.dropdown, width: 200 },
            title: { ...dropDownStyles.title, backgroundColor: "white" },
          }}
          className={classNames.input}
          onRenderOption={onRenderOption}
        />
        {reason === 2 && (
          <TextField
            label="HOVEDSAGNAVN"
            styles={{
              ...inputStyles,
              fieldGroup: {
                ...inputStyles.fieldGroup,
                background: "none",
                marginTop: 10,
              },
            }}
            value={notes}
            name="Notes"
            placeholder="HOVEDSAGNAVN"
            className={classNames.input}
            onChange={(event, value) => setNotes(value)}
          />
        )}
        <div className={classNames.iconContainer}>
          <FontAwesomeIcon
            onClick={onApprove}
            icon={faCheck}
            className={classNames.icon}
          />
          <FontAwesomeIcon
            onClick={onCancel}
            icon={faTimes}
            className={classNames.icon}
          />
        </div>
      </div>
    </TeachingBubble>
  ) : null;
};

export default ConfirmStaffVacationBubble;
