import React, { PropsWithChildren, useEffect, useState } from "react";

type TDispositionStickyControl = {
  scrollToPosition: {
    top: number;
  };
  initialPosition: {
    top: number;
  };
  scrollPoint: number;
  style?: React.CSSProperties;
  backgroundStyle: React.CSSProperties;
};

const DispositionStickyControl = ({
  children,
  initialPosition,
  scrollPoint,
  scrollToPosition,
  style = {},
  backgroundStyle,
}: PropsWithChildren<TDispositionStickyControl>) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        style={{
          position: "sticky",
          top:
            scrollPosition > scrollPoint
              ? scrollToPosition.top
              : initialPosition.top,
          ...style,
        }}
      >
        {children}
      </div>
      {scrollPosition > scrollPoint ? (
        <div style={backgroundStyle}></div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DispositionStickyControl;
