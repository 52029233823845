// Import libraries
import moment from "moment";

// Import redux actions
import { getTeamByTeamId } from "../redux/machines/machines.actions";
import { getPlan, getPlans, updatePlan } from "../redux/plan/plan.actions";
import { getPrebookings } from "../redux/prebooking/prebooking.actions";
import { getProject } from "../redux/project/project.actions";
// import { AppDispatch } from "../redux/store";

// Import types
// import {
//   MachineProjectVMAdded,
//   TMachine,
//   TMachineService,
//   TMachineTeamModel,
//   TPlan,
//   TStaffSchedule,
//   TStaffVacation,
//   UserWorkerVMAdded
// } from "../types";
// import { TBooking } from "../types/api/booking";
// import { TPlanHorizontalPositionMap } from "../types/planner";
import { getPlanData } from "./plan";
import { findStaffSchedule, getLastDate } from "./utils";
import {
  ROLES,
  crmSpecialStatus,
  reasonVacationStaffOptions,
} from "./constants";
import {
  saveMessage,
  showConflictMessage,
} from "../redux/message/message.actions";
import { MessageBarType } from "@fluentui/react";
import { planActivityLogComposer } from "../components/pages/PlanningUtils";
import { findStaffIndexAndCounter } from "./utils";

// type AddTeamToMachineArgsType = {
//   team?: TMachineTeamModel;
//   machine: TMachine;
//   dispatch: AppDispatch;
//   teamId: string;
//   plan: TPlan;
//   machineRequirementIndex: number;
//   techDepartment: string,
//   calendarStepWidth: number
// };

// const getWidthStartEndNumberByHorizontalPositionMap = (horizontalPositionMap: TPlanHorizontalPositionMap, start: string, end: string, calendarStepWidth: number) => {
const getWidthStartEndNumberByHorizontalPositionMap = (
  horizontalPositionMap,
  start,
  end,
  calendarStepWidth
) => {
  let tempStart =
    horizontalPositionMap[moment(start, "YYYY-M-D").format("YYYY-M-D")];
  // as number;
  let tempEnd =
    horizontalPositionMap[moment(end, "YYYY-M-D").format("YYYY-M-D")];
  // as number;
  const width = (tempEnd - tempStart) * calendarStepWidth;
  const startText =
    horizontalPositionMap["positionsToDates"][tempStart * calendarStepWidth];
  const endText =
    horizontalPositionMap["positionsToDates"][tempEnd * calendarStepWidth];
  return { width, start: tempStart, end: tempEnd, startText, endText };
};

// type findMachineBookedProjectsArgType = {
//   machineId: string,
//   projectId: string,
//   allPlansData: TPlan[],
// }

// type MachineBookedProject = {
//   projectId: string,
//   name: string,
//   color: string,
//   start: string,
//   end: string,
//   crmProjectStatusCode: string
// };

// const findMachineBookedProjects = ({machineId, projectId,allPlansData}: findMachineBookedProjectsArgType): MachineBookedProject[] => {
const findMachineBookedProjects = ({ machineId, projectId, allPlansData }) => {
  // const machineBookedProjects: MachineBookedProject[] = [];
  const machineBookedProjects = [];
  allPlansData
    ?.filter((plan) => !plan.inactive)
    .forEach((plan) => {
      plan.machineRequirements.forEach((requirement) => {
        requirement.machines.forEach((machine) => {
          if (plan.projectId !== projectId && machine.machineId === machineId) {
            const machineInfo = {
              projectId: plan.projectId,
              name: plan.projectName,
              color: plan.color,

              start: machine.start,

              end: machine.end,
              crmProjectStatusCode: plan.crmProjectStatusCode,
            };
            machineBookedProjects.push(machineInfo);
          }
        });
      });
    });
  return machineBookedProjects;
};

const checkTimeOverlap = (start, end, currentStart, currentEnd) => {
  const startDate = new Date(
    start
      .split("-")
      .map((item) => (item.length < 2 ? `0${item}` : item))
      .join("-")
  );
  const endDate = new Date(
    end
      .split("-")
      .map((item) => (item.length < 2 ? `0${item}` : item))
      .join("-")
  );
  const currentStartDate = new Date(currentStart);
  const currentEndDate = new Date(currentEnd);
  const startBeforeCurrentEnd = startDate <= currentEndDate;
  const endAfterCurrentStart = endDate >= currentStartDate;
  const isOverLap = startBeforeCurrentEnd && endAfterCurrentStart;
  return isOverLap;
};

const checkForOverlappedMachines = (
  currentMachines,
  projectId,
  allPlansData
) => {
  const conflictMachineData = {};

  currentMachines.forEach((machineReq) => {
    machineReq.array.forEach((currentMachine) => {
      const machineId = currentMachine.machineId;

      const overlappedMachines = [];
      // Find all overlapped machines

      allPlansData
        .filter(
          (plan) =>
            !plan.inactive && !crmSpecialStatus[plan.crmProjectStatusCode]
        )
        .forEach((plan) => {
          plan.machineRequirements.forEach((requirement) => {
            requirement.machines.forEach((machine) => {
              const isOverLap = checkTimeOverlap(
                currentMachine.startText.value,
                currentMachine.endText?.value,
                machine.start,
                machine.end
              );
              if (
                plan.projectId !== projectId &&
                machine.machineId === machineId &&
                isOverLap
              ) {
                const machineInfo = {
                  ...machine,
                  projectId: plan.projectId,
                  name: plan.projectName,
                  color: plan.color,
                  backgroundCaro: false,
                };
                overlappedMachines.push(machineInfo);

                if (!conflictMachineData[currentMachine.machineName])
                  conflictMachineData[currentMachine.machineName] = [];
                // check if machine is presented in the array

                if (
                  !JSON.stringify(
                    conflictMachineData[currentMachine.machineName]
                  ).includes(JSON.stringify(machineInfo))
                )
                  conflictMachineData[currentMachine.machineName].push(
                    machineInfo
                  );
              }
            });
          });
        });

      machineReq.machineService?.forEach((service) => {
        const isOverLap = checkTimeOverlap(
          currentMachine.startText.value,
          currentMachine.endText?.value,
          service.start.slice(0, -1), // slice(0,-1) to remove timezone
          service.end.slice(0, -1)
        );
        if (isOverLap) {
          service.name = service.comment;
          service.backgroundCaro = true;
          overlappedMachines.push(service);

          if (!conflictMachineData[currentMachine.machineName])
            conflictMachineData[currentMachine.machineName] = [];
          // check if service is presented in the array

          if (
            !JSON.stringify(
              conflictMachineData[currentMachine.machineName]
            ).includes(JSON.stringify(service))
          )
            conflictMachineData[currentMachine.machineName].push(service);
        }
      });

      machineReq.machineBookedPrebookings?.forEach((prebooking) => {
        const isOverLap = checkTimeOverlap(
          currentMachine.startText.value,
          currentMachine.endText?.value,
          prebooking.start.slice(0, -1), // slice(0,-1) to remove timezone
          prebooking.end.slice(0, -1)
        );
        if (isOverLap) {
          prebooking.name = prebooking.prebookingName;
          prebooking.backgroundCaro = false;

          overlappedMachines.push(prebooking);

          if (!conflictMachineData[currentMachine.machineName])
            conflictMachineData[currentMachine.machineName] = [];
          // check if prebooking is presented in the array

          if (
            !JSON.stringify(
              conflictMachineData[currentMachine.machineName]
            ).includes(JSON.stringify(prebooking))
          )
            conflictMachineData[currentMachine.machineName].push(prebooking);
        }
      });

      currentMachine.overlapped = overlappedMachines;
    });
  });
  return { machinesNew: currentMachines, conflictMachineData };
};

const checkForOverlappedStaffs = (
  currentStaffs,
  projectId,
  role,
  allPlansData
) => {
  const conflictStaffData = {};

  currentStaffs.forEach((item) => {
    item.array.forEach((staff) => {
      const staffId = staff.id || staff.userId;
      const staffFullName = staff.userName;

      const overlappedSchedule = [];
      if (role !== "managers") {
        // Manager's plans are allowed to be overlapped
        // Find all overlapped plans

        allPlansData
          .filter(
            (plan) =>
              !plan.inactive && !crmSpecialStatus[plan.crmProjectStatusCode]
          )
          .forEach((plan) => {
            plan.machineRequirements.forEach((requirement) => {
              requirement[role].forEach((currentStaff) => {
                const isOverLap = checkTimeOverlap(
                  staff.startText.value,
                  staff.endText?.value,
                  currentStaff.start.slice(0, -1),
                  currentStaff.end.slice(0, -1)
                );
                if (
                  plan.projectId !== projectId &&
                  currentStaff.id === staffId &&
                  isOverLap
                ) {
                  const staffInfo = {
                    ...currentStaff,
                    projectId: plan.projectId,
                    name: plan.projectName,
                    color: plan.color,
                    backgroundCaro: false,
                  };
                  overlappedSchedule.push(staffInfo);

                  if (!conflictStaffData[staffFullName])
                    conflictStaffData[staffFullName] = [];
                  // check if staff is presented in the array

                  if (
                    !JSON.stringify(conflictStaffData[staffFullName]).includes(
                      JSON.stringify(staffInfo)
                    )
                  )
                    conflictStaffData[staffFullName].push(staffInfo);
                }
              });
            });
          });
      }

      item.staffVacation?.forEach((vacation) => {
        const isOverLap = checkTimeOverlap(
          staff.startText.value,
          staff.endText?.value,
          vacation.start.slice(0, -1),
          vacation.end.slice(0, -1)
        ); // slice(0,-1) to remove timezone
        if (isOverLap) {
          vacation.name = reasonVacationStaffOptions[vacation.reason].text;
          vacation.backgroundCaro = true;
          overlappedSchedule.push(vacation);

          if (!conflictStaffData[staffFullName])
            conflictStaffData[staffFullName] = [];
          // check if service is presented in the array

          if (
            !JSON.stringify(conflictStaffData[staffFullName]).includes(
              JSON.stringify(vacation)
            )
          )
            conflictStaffData[staffFullName].push(vacation);
        }
      });

      staff.overlapped = overlappedSchedule;
    });
  });
  return {
    [`${role}New`]: currentStaffs,
    [`${role}ConflictData`]: conflictStaffData,
  };
};

// user,
// role,
// machine = null,
// isAutoAdd = false,
// isTeam = false,
// isDrawn,
// horizontalPositionMap,
// allPlansData,
// planData,
// arr,
// machines,
// planningIndex,
// calendarStepWidth,
// dispatch

const findNearestPossibleTime = (
  currentData,
  horizontalPositionMap,
  target
) => {
  let newStartEnd = 0; // Start check from the beginning of calendar, allow one day booking

  while (newStartEnd <= horizontalPositionMap.end) {
    const isOverLap = checkTimeOverlapOthers(
      currentData,
      horizontalPositionMap,
      target,
      newStartEnd,
      newStartEnd
    );
    if (!isOverLap) return { start: newStartEnd, end: newStartEnd };
    newStartEnd++;
  }
  return false;
};

const findMostSuitableTime = (
  currentData,
  horizontalPositionMap,
  target,
  machineReqStart,
  machineReqEnd
) => {
  const possibleTime = [];
  let newStart = machineReqStart; // Start check from the beginning of calendar, allow one day booking
  let newEnd = machineReqStart; // Start check from the beginning of calendar, allow one day booking

  while (newEnd <= machineReqEnd) {
    const isOverLap = checkTimeOverlapOthers(
      currentData,
      horizontalPositionMap,
      target,
      newStart,
      newEnd
    );
    if (!isOverLap) {
      possibleTime.push({ start: newStart, end: newEnd });
      newEnd++;
    } else {
      newStart++;
      newEnd = newStart;
    }
  }
  const mostSuitableTime = possibleTime.sort(
    (a, b) => b.end - b.start - (a.end - a.start)
  )[0];
  return mostSuitableTime;
};

const checkTimeOverlapOthers = (
  currentData,
  horizontalPositionMap,
  target,
  newStart,
  newEnd
) => {
  const filterArraysKey =
    target === "machine"
      ? [
          "array",
          "machineService",
          "machineBookedProjects",
          "machineBookedPrebookings",
        ]
      : ["array", "staffSchedule", "staffVacation"];
  let isOverLap = false;
  let newStartDate = Object.keys(horizontalPositionMap).find(
    (key) => horizontalPositionMap[key] === newStart
  );
  let newEndDate = Object.keys(horizontalPositionMap).find(
    (key) => horizontalPositionMap[key] === newEnd
  );
  for (const key of filterArraysKey) {
    for (const item of currentData[key] || []) {
      const currentStart =
        key === "array"
          ? item.startText.value.slice(0, 10)
          : item.start.slice(0, 10);
      const currentEnd =
        key === "array"
          ? item.endText.value.slice(0, 10)
          : item.end.slice(0, 10);
      isOverLap = checkTimeOverlap(
        newStartDate,
        newEndDate,
        currentStart,
        currentEnd
      );
      if (isOverLap) break;
    }
    if (isOverLap) break;
  }
  return isOverLap;
};

const roleProp = {
  WORKER: "workers",
  DRIVER: "drivers",
  SUPERVISOR: "managers",
};

const findSupervisorsVariant = (supervisor) => {
  const supervisorBars = [...supervisor.staffSchedule];
  const supervisorPlans = [...supervisor.array];
  let isOverlap = false;
  // If array is overlap with any other plans, calculate for the position counter
  for (const plan of supervisorPlans) {
    const planStartDate = plan.startText?.value.slice(0, 10);
    const planEndDate = plan.endText?.value.slice(0, 10);
    for (let i = 0; i < supervisorBars.length; i++) {
      const itemStartDate = supervisorBars[i].start;
      const itemEndDate = supervisorBars[i].end;
      isOverlap = checkTimeOverlap(
        planStartDate,
        planEndDate,
        itemStartDate,
        itemEndDate
      );
      if (isOverlap) break;
    }
    if (isOverlap) break;
  }
  if (isOverlap) {
    const { positionAndCounter } = findStaffIndexAndCounter(supervisorBars, 1);
    supervisor.staffSchedule = supervisor.staffSchedule.map((item, index) => ({
      ...item,
      counter: positionAndCounter[index],
    }));
    supervisor.maxCounter = Math.max(...Object.values(positionAndCounter));
  } else supervisor.maxCounter = 1;
  return supervisor;
};

const addEmployment = ({
  user,
  role,
  machine = null,
  isAutoAdd = false,
  isTeam = false,
  isDrawn,
  horizontalPositionMap,
  allPlansData,
  planData,
  arr,
  machines,
  planningIndex,
  calendarStepWidth,
  dispatch,
}) => {
  // role could be WORKER, DRIVER, SUPERVISOR, FUNCTIONKER
  if (horizontalPositionMap && allPlansData) {
    // const { target, setTarget, toggleTeachingBubble } = chooseRole(role);
    const tempList = [...arr];
    while (arr.length > 0) {
      arr.pop();
    }
    const userId = user.userId ? user.userId : user.id;
    const indexList = tempList.findIndex((item) => item.id === userId);
    if (isDrawn) {
      const newTarget = {
        machineName: machine?.machineName ?? user?.machineName ?? null,
        machineId: machine?.machineName ?? user?.machineId ?? null,
        userName: `${user.firstName} ${user.lastName}`,
        userId: user.userId ? user.userId : user.id,
        imageUrl: user.imageUrl,
        ...user,
      };
      tempList[indexList].array = [...tempList[indexList].array, newTarget];
      arr.push(...tempList);
      return;
    }
    let machineReqStartDate = moment(
      planData?.machineRequirements[+planningIndex]?.start
    ).format("YYYY-M-D");
    let machineReqEndDate = moment(
      planData?.machineRequirements[+planningIndex]?.end
    ).format("YYYY-M-D");
    let start, end;
    if (indexList !== -1) {
      const list = [...tempList[indexList].array];
      if (role === ROLES.SUPERVISOR || role === ROLES.Funktionær) {
        if (isTeam) {
          const teamMachine = machines.find(
            (item) => item.id === machine.machineId
          );
          const firstMachineInfo = teamMachine.array[0];
          start = firstMachineInfo.start;
          end =
            horizontalPositionMap[
              moment(
                firstMachineInfo.endText.value.slice(0, 10),
                "YYYY-M-D"
              ).format("YYYY-M-D")
            ]; //avoid end different
        } else {
          const lastTarget = list[list.length - 1];
          if (lastTarget.end + 1 >= horizontalPositionMap["end"]) {
            return;
          }
          start = lastTarget["end"] + 1;
          end = lastTarget["end"] + 1;
        }
      } else {
        let checkTimeStart = horizontalPositionMap[machineReqStartDate];
        let checkTimeEnd = horizontalPositionMap[machineReqEndDate];
        if (role === ROLES.DRIVER || isTeam) {
          const driverMachine = machines.find(
            (item) => item.id === machine.machineId
          );
          if (driverMachine) {
            const firstMachineInfo = driverMachine.array[0];
            checkTimeStart = firstMachineInfo.start;
            checkTimeEnd =
              horizontalPositionMap[
                moment(
                  firstMachineInfo.endText.value.slice(0, 10),
                  "YYYY-M-D"
                ).format("YYYY-M-D")
              ]; //avoid end different
          }
        } else {
          const firstMachine = machines[0];
          if (firstMachine) {
            const firstMachineInfo = firstMachine.array[0];
            checkTimeStart = firstMachineInfo.start;
            checkTimeEnd =
              horizontalPositionMap[
                moment(
                  firstMachineInfo.endText.value.slice(0, 10),
                  "YYYY-M-D"
                ).format("YYYY-M-D")
              ]; //avoid end different
          }
        }
        const mostSuitableTime = findMostSuitableTime(
          tempList[indexList],
          horizontalPositionMap,
          "staff",
          checkTimeStart,
          checkTimeEnd
        );
        if (mostSuitableTime) {
          start = mostSuitableTime.start;
          end = mostSuitableTime.end;

          if (
            start !== horizontalPositionMap[machineReqStartDate] ||
            end !== horizontalPositionMap[machineReqEndDate]
          ) {
            return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
          }
        } else {
          const nearestPossibleTime = findNearestPossibleTime(
            tempList[indexList],
            horizontalPositionMap,
            "machine"
          );
          if (!nearestPossibleTime) return;
          start = nearestPossibleTime.start;
          end = nearestPossibleTime.end;

          if (
            start < horizontalPositionMap[machineReqStartDate] ||
            end < horizontalPositionMap[machineReqStartDate] ||
            start !== horizontalPositionMap[machineReqStartDate] ||
            end !== horizontalPositionMap[machineReqEndDate]
          ) {
            return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
          }

          if (start === 0 && end === 0) {
            return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
          }
        }
        // const startEnd = findNearestPossibleTime(tempList[indexList], horizontalPositionMap, 'staff');
        // if (!startEnd) return;
        // start = startEnd?.start;
        // end = startEnd?.end;
      }

      const startText =
        horizontalPositionMap.positionsToDates[start * calendarStepWidth];
      const endText =
        horizontalPositionMap.positionsToDates[end * calendarStepWidth];

      if (startText && endText) {
        const startValue = startText.value;
        const endValue = endText.value;

        const today = new Date();

        if (new Date(startValue) < today && today < new Date(endValue)) {
          start =
            horizontalPositionMap[
              moment(today.toISOString()).format("YYYY-M-D")
            ];
        }
      }

      if (isTeam) {
        const startDate = new Date(
          horizontalPositionMap.positionsToDates[
            start * calendarStepWidth
          ].value.slice(0, 10)
        );

        const endDate = new Date(
          horizontalPositionMap.positionsToDates[
            end * calendarStepWidth
          ].value.slice(0, 10)
        );

        const machineStartDate = new Date(machineReqStartDate);
        const machineEndDate = new Date(machineReqEndDate);

        if (startDate < machineStartDate && endDate < machineEndDate) {
          return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
        }
      }

      const newTarget = {
        machineName: machine?.machineName ?? user?.machineName ?? null,
        machineId: machine?.machineName ?? user?.machineId ?? null,
        userName: isAutoAdd
          ? user.userName
            ? user.userName
            : `${user.firstName} ${user.lastName}`
          : `${user.firstName} ${user.lastName}`,
        userId: user.userId ? user.userId : user.id,
        imageUrl: user.imageUrl,
        start,
        end,
        width: 25,
        startText:
          horizontalPositionMap.positionsToDates[start * calendarStepWidth],
        endText:
          horizontalPositionMap.positionsToDates[end * calendarStepWidth],
      };
      tempList[indexList].array = [...list, newTarget];
      arr.push(...tempList);
    } else {
      const staffSchedule = findStaffSchedule(
        user.userId ? user.userId : user.id,
        planData.projectId,
        roleProp[role],
        allPlansData
      );
      let currentStaffData = {
        id: user.userId ? user.userId : user.id,
        array: [],
        staffVacation: user.starfVacations,
        staffSchedule,
      };
      start = horizontalPositionMap[machineReqStartDate];
      end = horizontalPositionMap[machineReqEndDate];
      if (role === ROLES.DRIVER || isTeam) {
        const driverMachine = machines.find(
          (item) => item.id === machine.machineId
        );
        if (driverMachine) {
          const firstMachineInfo = driverMachine.array[0];
          start = firstMachineInfo.start;
          end =
            horizontalPositionMap[
              moment(
                firstMachineInfo.endText.value.slice(0, 10),
                "YYYY-M-D"
              ).format("YYYY-M-D")
            ]; //avoid end different
        }
      } else {
        const firstMachine = machines[0];
        if (firstMachine) {
          const firstMachineInfo = firstMachine.array[0];
          start = firstMachineInfo.start;
          end =
            horizontalPositionMap[
              moment(
                firstMachineInfo.endText.value.slice(0, 10),
                "YYYY-M-D"
              ).format("YYYY-M-D")
            ]; //avoid end different
        }
      }
      const timeIsOverlap = checkTimeOverlapOthers(
        currentStaffData,
        horizontalPositionMap,
        "staff",
        start,
        end
      );
      // Supervisors are allowed to be overlapped
      if (timeIsOverlap && role !== ROLES.SUPERVISOR) {
        const mostSuitableTime = findMostSuitableTime(
          currentStaffData,
          horizontalPositionMap,
          "staff",
          start,
          end
        );
        if (mostSuitableTime) {
          start = mostSuitableTime.start;
          end = mostSuitableTime.end;

          if (
            start !== horizontalPositionMap[machineReqStartDate] ||
            end !== horizontalPositionMap[machineReqEndDate]
          ) {
            return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
          }
        } else {
          const nearestPossibleTime = findNearestPossibleTime(
            currentStaffData,
            horizontalPositionMap,
            "staff"
          );
          if (!nearestPossibleTime) {
            dispatch(
              saveMessage(
                `Schedule of ${roleProp[role].slice(0, -1)} ${user.firstName} ${
                  user.firstName
                } was fully booked!`
              )
            );
            return;
          }
          start = nearestPossibleTime.start;
          end = nearestPossibleTime.end;

          if (
            start < horizontalPositionMap[machineReqStartDate] ||
            end < horizontalPositionMap[machineReqStartDate] ||
            start !== horizontalPositionMap[machineReqStartDate] ||
            end !== horizontalPositionMap[machineReqEndDate]
          ) {
            return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
          }

          if (start === 0 && end === 0) {
            return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
          }
        }
      }

      // We want to ensure that the start and end of the employee is not before today
      // Meaning if today was 17-11-2022 and the machine requirement start date is 15-11-2022
      // We want to set the start date of the employee to 17-11-2022
      const startText =
        horizontalPositionMap.positionsToDates[start * calendarStepWidth];
      const endText =
        horizontalPositionMap.positionsToDates[end * calendarStepWidth];

      if (startText && endText) {
        const startValue = startText.value;
        const endValue = endText.value;

        const today = new Date();

        if (new Date(startValue) < today && today < new Date(endValue)) {
          start =
            horizontalPositionMap[
              moment(today.toISOString()).format("YYYY-M-D")
            ];
        }
      }

      if (isTeam) {
        const startDate = new Date(
          horizontalPositionMap.positionsToDates[
            start * calendarStepWidth
          ].value.slice(0, 10)
        );

        const endDate = new Date(
          horizontalPositionMap.positionsToDates[
            end * calendarStepWidth
          ].value.slice(0, 10)
        );

        const machineStartDate = new Date(machineReqStartDate);
        const machineEndDate = new Date(machineReqEndDate);

        if (startDate < machineStartDate && endDate < machineEndDate) {
          return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
        }
      }

      const array = [
        {
          machineName: machine?.machineName ?? null,
          machineId: machine?.machineId ?? null,
          userName: `${user.firstName} ${user.lastName}`,
          userId: user.userId ? user.userId : user.id,
          imageUrl: user.imageUrl,
          start,
          end,
          width: 25,
          startText:
            horizontalPositionMap.positionsToDates[start * calendarStepWidth],
          endText:
            horizontalPositionMap.positionsToDates[end * calendarStepWidth],
        },
      ];
      currentStaffData.array = array;
      if (role === ROLES.SUPERVISOR)
        currentStaffData = findSupervisorsVariant(currentStaffData);
      tempList.push(currentStaffData);
      arr.push(...tempList);
    }
  }
};

// Check for overlapped machines, d
// export const addTeamToMachine = async ({
//   teamId,
//   dispatch,
//   machine,
//   plan,
//   machineRequirementIndex,
//   techDepartment,
//   calendarStepWidth = 25
// }: AddTeamToMachineArgsType) => {
export const addTeamToMachine = async ({
  teamId,
  dispatch,
  machine,
  plan,
  machineRequirementIndex,
  techDepartment,
  calendarStepWidth = 25,
  currentUserId,
  navigateToDetailedPlan,
}) => {
  try {
    // teamData to the tempDrivers and so on

    const teamData = await getTeamByTeamId(teamId)(dispatch);
    const planData = await getPlan(plan.projectId)(dispatch);
    const project = await getProject(plan.projectId)(dispatch);
    const prebookingsData = await getPrebookings()(dispatch);

    if (teamData && planData && project) {
      const { dates, horizontalPositionMap } = await getPlanData({
        project,
        plan: planData,
        machineRequirementIndex,
        calendarStepWidth,
        dispatch,
      });

      if (dates.length) {
        const startDate = dates[0][0];
        const endDate = getLastDate(dates);
        const startCalendar = new Date(
          startDate.year,
          startDate.month,
          startDate.date
        ).toISOString();
        const endCalendar = new Date(
          endDate.year,
          endDate.month,
          endDate.date
        ).toISOString();
        const allPlansData = await getPlans(
          startDate.year,
          startCalendar,
          endCalendar,
          techDepartment
        )(dispatch);

        if (allPlansData) {
          // let machineReqStartDate = moment(
          //   planData?.machineRequirements[machineRequirementIndex]?.start
          // ).format("YYYY-M-D");
          // let machineReqEndDate = moment(
          //   planData?.machineRequirements[machineRequirementIndex]?.end
          // ).format("YYYY-M-D");
          // let start, end;

          const staffRoles = ["drivers", "workers", "managers"];

          // <{
          //   id: string,
          //   machineName: string,
          //   array: TMachine & {
          //     start: number,
          //     end: number,
          //     width: number,
          //     startText: string,
          //     endText: string,
          //   },
          //   machineBookedProjects: MachineBookedProject[],
          //   machineBookedPrebookings: TBooking[],
          //   machineService: TMachineService[]
          // }[]>

          const tempMachines = planData.machineRequirements[
            machineRequirementIndex
          ].machines.reduce((acc, crr) => {
            const { width, start, end, startText, endText } =
              getWidthStartEndNumberByHorizontalPositionMap(
                horizontalPositionMap,

                crr.start,

                crr.end,
                calendarStepWidth
              );
            const machine = {
              ...crr,
              start,
              end,
              width,
              startText,
              endText,
            };

            const index = acc.findIndex((item1) => item1.id === crr.machineId);
            if (index !== -1) {
              acc[index].array.push(machine);
            } else {
              // array.push({id: item.machineId, machineName: item.machineName, array: [machine], machineService: item.machineService})
              const machineBookedProjects = findMachineBookedProjects({
                machineId: crr.machineId,
                projectId: planData.projectId,
                allPlansData,
              });

              const machineBookedPrebookings = prebookingsData

                .filter((item) => item.machineId === crr.machineId)

                .map((item) => ({
                  color: "242, 135, 5",
                  end: item.end,
                  prebookingName: item.projectName,
                  start: item.start,
                }));
              acc.push({
                id: crr.machineId,

                array: [machine],
                machineName: crr.machineName,
                machineService: crr.machineService,
                machineBookedProjects,
                //@ts-ignore
                machineBookedPrebookings,
              });
            }
            return acc;
          }, []);

          const [tempDrivers, tempWorkers, tempSupervisors] = [
            planData.machineRequirements[machineRequirementIndex].drivers,
            planData.machineRequirements[machineRequirementIndex].workers,
            planData.machineRequirements[machineRequirementIndex].managers,

            // {
            //   id:string,
            //   array: (UserWorkerVMAdded & {
            //     userName: string,
            //     userId: string,
            //     imageUrl: string,
            //     start: number,
            //     end: number,
            //     width: number,
            //     startText: {
            //         value: string;
            //         display: string;
            //     },
            //     endText: {
            //         value: string;
            //         display: string;
            //     },
            //   })[],
            //   staffSchedule: TStaffSchedule,
            //   vacation: TStaffVacation[]
            // }[]
          ].map((target, currentIndex) =>
            target.reduce((array, item) => {
              const { width, start, end, startText, endText } =
                getWidthStartEndNumberByHorizontalPositionMap(
                  horizontalPositionMap,
                  item.start,
                  item.end,
                  calendarStepWidth
                );

              const user = {
                ...item,
                userName: `${item.firstName} ${item.lastName}`,
                userId: item.id,
                imageUrl: item.imageUrl,
                start,
                end,
                width,
                startText,
                endText,
              };

              const index = array.findIndex((item1) => item1.id === item.id);
              if (index !== -1) {
                array[index].array.push(user);
              } else {
                const staffSchedule = findStaffSchedule(
                  item.id,
                  planData.projectId,
                  staffRoles[currentIndex],
                  allPlansData
                );

                array.push({
                  id: item.id,
                  array: [user],
                  staffVacation: item.starfVacations,
                  staffSchedule,
                });
              }
              return array;
            }, [])
          );

          const errorMessages = [];

          for await (const driver of teamData?.drivers || []) {
            const err = addEmployment({
              allPlansData,
              calendarStepWidth,
              dispatch,
              horizontalPositionMap,
              machines: tempMachines,
              planData,
              planningIndex: machineRequirementIndex,
              role: ROLES.DRIVER,
              arr: tempDrivers,
              isAutoAdd: true,
              isTeam: true,
              user: driver,
              machine,
            });

            if (err && typeof err === "string") {
              errorMessages.push(err);
            }
          }

          for await (const worker of teamData?.workers || []) {
            const err = addEmployment({
              allPlansData,
              calendarStepWidth,
              dispatch,
              horizontalPositionMap,
              machines: tempMachines,
              planData,
              planningIndex: machineRequirementIndex,
              role: ROLES.WORKER,
              arr: tempWorkers,
              isAutoAdd: true,
              isTeam: true,
              user: worker,
              machine,
            });

            if (err && typeof err === "string") {
              errorMessages.push(err);
            }
          }

          for await (const manager of teamData?.managers || []) {
            const err = addEmployment({
              allPlansData,
              calendarStepWidth,
              dispatch,
              horizontalPositionMap,
              machines: tempMachines,
              planData,
              planningIndex: machineRequirementIndex,
              role: ROLES.SUPERVISOR,
              arr: tempSupervisors,
              isAutoAdd: true,
              isTeam: true,
              user: manager,
              machine,
            });

            if (err && typeof err === "string") {
              errorMessages.push(err);
            }
          }

          if (errorMessages.length !== 0) {
            const message = errorMessages.join("\r\n");

            dispatch(saveMessage(message));

            navigateToDetailedPlan();

            throw new Error(message);
          }

          const { machinesNew, conflictMachineData } =
            checkForOverlappedMachines(
              tempMachines,
              planData.projectId,
              allPlansData
            );
          const { driversNew, driversConflictData } = checkForOverlappedStaffs(
            tempDrivers,
            planData.projectId,
            "drivers",
            allPlansData
          );
          const { workersNew, workersConflictData } = checkForOverlappedStaffs(
            tempWorkers,
            planData.projectId,
            "workers",
            allPlansData
          );

          const overlappedMachines = machinesNew.some((machine) =>
            machine.array.some((item) => item.overlapped?.length)
          );
          const overlappedDrivers = driversNew.some((staff) =>
            staff.array.some((item) => item.overlapped?.length)
          );
          const overlappedWorkers = workersNew.some((staff) =>
            staff.array.some((item) => item.overlapped?.length)
          );

          if (overlappedMachines || overlappedDrivers || overlappedWorkers) {
            dispatch(
              showConflictMessage(
                {
                  machine: conflictMachineData,
                  driver: driversConflictData,
                  worker: workersConflictData,
                  // manager: managersConflictData,
                },
                MessageBarType.error
              )
            );

            navigateToDetailedPlan();

            return false;
          }

          if (planData?.machineRequirements[machineRequirementIndex]) {
            let [replaceDrivers, replaceWorkers, replaceSupervisors] = [
              tempDrivers,
              tempWorkers,
              tempSupervisors,
            ].map((target) =>
              target
                .reduce((array, item) => [...array, ...item.array], [])
                .map((item) => ({
                  id: item.userId,
                  firstName: item.firstName
                    ? item.firstName
                    : item.userName.split(" ")[0],
                  lastName: item.lastName
                    ? item.lastName
                    : item.userName.split(" ")[1],
                  start: item.startText.value,
                  end: item.endText.value,
                  machineId: item?.machineId ?? null,
                }))
            );
            const replaceMachines = tempMachines
              .reduce((array, item) => [...array, ...item.array], [])
              .map((machine) => ({
                machineId: machine.machineId,
                machineName: machine.machineName,
                start: machine.startText.value,
                end: machine.endText.value,
              }));
            const newPlan = JSON.parse(JSON.stringify(planData));
            newPlan.machineRequirements[machineRequirementIndex].machines =
              replaceMachines;
            newPlan.machineRequirements[machineRequirementIndex].drivers =
              replaceDrivers;
            newPlan.machineRequirements[machineRequirementIndex].workers =
              replaceWorkers;
            newPlan.machineRequirements[machineRequirementIndex].managers =
              replaceSupervisors;
            // newPlan.machineRequirements[planningIndex].done = done;
            newPlan.activityLog = planActivityLogComposer(
              planData?.machineRequirements[machineRequirementIndex],
              newPlan.machineRequirements[machineRequirementIndex],
              currentUserId
            );
            await updatePlan(newPlan)(dispatch);
          }

          return true;
        }
      }
    }
  } catch (error) {
    console.error(error);

    dispatch(saveMessage(error?.message || "Error", MessageBarType.error));

    return false;
  }
};
