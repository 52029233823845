// Import types
import { TMachine } from "../../../../types";

// Import libraries
import { mergeStyleSets } from "@fluentui/react";

// Import styles
import { commonStationSelectorButton } from "../../NotificationManagement";
import { useMemo } from "react";

const classNames = mergeStyleSets({
  button: [
    commonStationSelectorButton,
    {
      color: "#868685",
      background: "#DFDFDF",
      selectors: {
        ":hover": {
          color: "#DFDFDF",
          background: "#868685",
        },
      },
    },
  ],
  buttonSelected: commonStationSelectorButton,
});

type MachineFiltersProps = {
  machines: TMachine[];
  selectedMachines: string[];
  onSelectMachine: (val: string[]) => void;
};

export const BLANK_MACHINE_FILTER_OPTION = "Blank";

const MachineFilters = ({
  machines,
  selectedMachines,
  onSelectMachine,
}: MachineFiltersProps) => {
  const allSelected = useMemo(() => {
    return machines.every((el) => selectedMachines.includes(el.machineName));
  }, [machines, selectedMachines]);

  return (
    <div
      style={{
        flexWrap: "wrap",
        display: "flex",
        gap: 10,
      }}
    >
      {machines.map(({ machineName }) => {
        const selected = selectedMachines.includes(machineName);

        return (
          <div
            key={`machine-filter-${machineName}`}
            className={selected ? classNames.buttonSelected : classNames.button}
            onClick={() => {
              if (selected) {
                onSelectMachine(
                  selectedMachines.filter((el) => el !== machineName)
                );
              } else {
                onSelectMachine([...selectedMachines, machineName]);
              }
            }}
          >
            {machineName}{" "}
          </div>
        );
      })}

      <div
        className={allSelected ? classNames.buttonSelected : classNames.button}
        onClick={() => {
          // if (!allSelected) {
          // }
          onSelectMachine([
            ...machines.map((el) => el.machineName),
            BLANK_MACHINE_FILTER_OPTION,
          ]);
        }}
      >
        All
      </div>
      <div
        className={
          selectedMachines.includes(BLANK_MACHINE_FILTER_OPTION)
            ? classNames.buttonSelected
            : classNames.button
        }
        onClick={() => {
          if (selectedMachines.includes(BLANK_MACHINE_FILTER_OPTION)) {
            onSelectMachine(
              selectedMachines.filter(
                (el) => el !== BLANK_MACHINE_FILTER_OPTION
              )
            );
          } else {
            onSelectMachine([...selectedMachines, BLANK_MACHINE_FILTER_OPTION]);
          }
        }}
      >
        Not Assigned
      </div>
    </div>
  );
};

export default MachineFilters;
