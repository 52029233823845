import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/da";
import { Rnd } from "react-rnd";
import {
  CALENDAR_STEP_WIDTH,
  COLOR_CONS_HEX,
  crmSpecialStatus,
} from "../../../../utils/constants";
import { TooltipForText } from "../../../common";

const textStyles = {
  padding: "0 10px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const MachineStaffBookedBar = ({
  start,
  end,
  horizontalPositionMap,
  text,
  color = "219,219,219",
  calendarStepWidth = CALENDAR_STEP_WIDTH,
  crmProjectStatusCode = "",
  staffY = 0,
}) => {
  const [x, setX] = useState(0);
  const [width, setWidth] = useState(0);
  const [startText, setStartText] = useState(null);
  const [endText, setEndText] = useState(null);
  let textDisplay = text;
  useEffect(() => {
    if (!start || !end) {
      return;
    }
    let startDate = moment(start, "YYYY-M-D").format("YYYY-M-D");
    let endDate = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");
    const startCalendar = horizontalPositionMap.startText.split(".").reverse();
    const endCalendar = horizontalPositionMap.endText.split(".").reverse();
    const startDateBeforeStartCalendar =
      new Date(startDate).getTime() <
      new Date(
        "20" + startCalendar[0],
        startCalendar[1] - 1,
        startCalendar[2]
      ).getTime();
    const endDateAfterEndCalendar =
      new Date(endDate).getTime() >=
      new Date(
        "20" + endCalendar[0],
        endCalendar[1] - 1,
        parseInt(endCalendar[2]) + 1
      ).getTime();
    let startTemp = startDateBeforeStartCalendar
      ? 0
      : horizontalPositionMap[startDate];
    let endTemp = endDateAfterEndCalendar
      ? horizontalPositionMap[endDate] || horizontalPositionMap.end + 1
      : horizontalPositionMap[endDate];
    let tempX = startTemp * calendarStepWidth;
    let tempWidth = (endTemp - startTemp) * calendarStepWidth;
    if (typeof startTemp === "undefined" && typeof endTemp !== "undefined") {
      tempX = 0;
      tempWidth = endTemp * calendarStepWidth;
    }
    if (typeof startTemp === "undefined" || typeof endTemp === "undefined") {
      setWidth(0);
      return;
    }
    if (typeof startTemp === "undefined" && typeof endTemp === "undefined") {
      tempWidth =
        (horizontalPositionMap["end"] - startTemp) * calendarStepWidth;
    }
    const startTextTemp = moment(start, "YYYY-M-D").format("D.M.YY");
    const endTextTemp = moment(end, "YYYY-M-D").format("D.M.YY");
    setStartText(startTextTemp);
    setEndText(endTextTemp);
    setX(tempX);
    setWidth(tempWidth);
  }, [start, end, horizontalPositionMap, calendarStepWidth]);

  let renderDetail = (
    <TooltipForText text={textDisplay}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#fff",
        }}
      >
        <div style={textStyles}>{startText}</div>
        <div style={textStyles}>
          {crmSpecialStatus[crmProjectStatusCode] && (
            <span
              style={{
                color: COLOR_CONS_HEX.ALERT,
                fontWeight: "bold",
                marginRight: 8,
              }}
            >
              {crmSpecialStatus[crmProjectStatusCode].text}
            </span>
          )}
          {textDisplay}
        </div>
        <div style={textStyles}>{endText}</div>
      </div>
    </TooltipForText>
  );
  if (width <= 275) {
    renderDetail = (
      <TooltipForText text={textDisplay}>
        <div
          style={{ display: "flex", justifyContent: "center", color: "#fff" }}
        >
          <div style={textStyles}>{textDisplay}</div>
        </div>
      </TooltipForText>
    );
  }

  return width > 0 ? (
    <Rnd
      style={{ zIndex: 3, top: 0, left: 0 }}
      size={{ width: width, height: 20 }}
      position={{ x: x, y: staffY }}
      enableResizing={false}
      disableDragging={true}
    >
      <div
        id="detailBar"
        style={{
          background: `repeating-linear-gradient(90deg, rgba(${color},1), rgba(${color},1) 24px, rgba(${color},0.4) 24px, rgba(${color},0.4) 48px)`,
          display: "flex",
          marginBottom: 12,
          height: 36,
          borderRadius: 8,
        }}
      >
        <div
          style={{
            color: "#fff",
            fontWeight: "bold",
            height: "100%",
            width: "100%",
            fontSize: 13,
            lineHeight: "32px",
            margin: "2px 4px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#000",
            }}
          >
            {renderDetail}
          </div>
        </div>
      </div>
    </Rnd>
  ) : (
    <></>
  );
};

export default MachineStaffBookedBar;
