// import { monthsCapitalized } from './constants';
// Import libraries
import { MessageBarType } from "@fluentui/react";
// @ts-ignore
import { datesGenerator } from "dates-generator";
import moment from "moment";
import { useLocation } from "react-router";
import { startOfWeek, differenceInDays, subDays, startOfYear } from "date-fns";

// Import actions
import { showConflictMessage } from "../redux/message/message.actions";
import {
  MachineRequirementVMAdded,
  TPlan,
  TStaffSchedule,
  TStaffVacation,
  UserWorkerVMAdded,
} from "../types";

// Import types
import { TDate, TDates, TDatesGenerator, THoliday } from "../types/dates";

// Import constants
import {
  ACTIVITY_LOG_ENUM,
  ACTIVITY_LOG_TYPE_ENUM,
  PROJECT_CATEGORY,
  months,
  techDepartmentOptions,
  workingRoleOptions,
} from "./constants";
import { eachDayOfInterval } from "date-fns";
import { OverlapKeys } from "../redux/project/project.actions";

// export const printDate = (date) => {
//     return `${date.getDate()}. ${monthsCapitalized[date.getMonth()]} ${date.getFullYear()}`;
// }

export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export const copyAndSort = (
  items: any[],
  key: string,
  isSortedDescending: boolean
) => {
  return items.slice(0).sort((a, b) => {
    let compare1 = !isNaN(a[key]) ? +a[key] : a[key];
    let compare2 = !isNaN(b[key]) ? +b[key] : b[key];
    if (key === "workingRole") {
      const rolesA =
        a.workingRole !== "-1" && a.workingRole !== "Undiefined"
          ? a.workingRole
              .split(";")
              .filter((item: any) => item !== "-1")
              .map((key: any) => workingRoleOptions[key]?.text)
              .join(", ")
          : "UNKNOWN ROLE";
      const rolesB =
        b.workingRole !== "-1" && b.workingRole !== "Undiefined"
          ? b.workingRole
              .split(";")
              .filter((item: any) => item !== "-1")
              .map((key: any) => workingRoleOptions[key]?.text)
              .join(", ")
          : "UNKNOWN ROLE";
      compare1 = rolesA;
      compare2 = rolesB;
    }
    return (isSortedDescending ? compare1 < compare2 : compare1 > compare2)
      ? 1
      : -1;
  });
};

export const getDaysAwayAndGoBack = (selectedDate: Date) => {
  // Find the starting day of the year
  const startOfYearDate = startOfYear(selectedDate);

  // Find the Sunday of the week of the starting day of the year
  const sundayOfStartOfYearWeek = startOfWeek(startOfYearDate, {
    weekStartsOn: 1,
  }); // 0 represents Sunday

  // Calculate how many days away from the Sunday the starting day of the year is
  const daysAway = differenceInDays(startOfYearDate, sundayOfStartOfYearWeek);

  // Go back that many days from the starting day of the year to get to the Sunday
  const previousSunday = subDays(startOfYearDate, daysAway);

  return {
    daysAway,
    previousSunday,
  };
};

export const calculateFinalDates = (
  array: any[],
  getCurrentWeekIndex = false
) => {
  let currentWeekIndex = null;
  for (let i = 0; i < array.length - 1; i++) {
    const lastWeek1 = array[i].dates.length - 1;
    const lastDay1 = array[i].dates[lastWeek1][0]["date"];
    const lastDay2 = array[i + 1].dates[0][0]["date"];
    if (lastDay1 === lastDay2) {
      array[i + 1].dates.splice(0, 1);
    }
  }
  const finalDates = [];
  for (let i = 0; i < array.length; i++) {
    finalDates.push(...array[i].dates);
  }
  if (getCurrentWeekIndex) {
    const now = new Date();
    const currentDay = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const tempFinalDates = JSON.parse(JSON.stringify(finalDates));
    tempFinalDates.shift();
    for (let i = 0; i < tempFinalDates.length; i++) {
      if (
        tempFinalDates[i].some(
          (item: any) =>
            item.date === currentDay &&
            item.month === currentMonth &&
            item.year === currentYear
        )
      ) {
        currentWeekIndex = i;
        break;
      }
    }
  }
  const start = new Date(
    array[0].dates[0][0]["year"],
    array[0].dates[0][0]["month"],
    array[0].dates[0][0]["date"]
  );
  const lastIndexWeekEnd = array[array.length - 1].dates.length - 1;

  const end = new Date(
    array[array.length - 1].dates[lastIndexWeekEnd][6]["year"],
    array[array.length - 1].dates[lastIndexWeekEnd][6]["month"],
    array[array.length - 1].dates[lastIndexWeekEnd][6]["date"]
  );
  return { finalDates, start, end, currentWeekIndex };
};

export const getMonthIndicator = (dates: TDates) => {
  let components = [];
  // const currentMonth = calendar.month;
  let temp = dates[0][0]["month"];
  let counter = 0;
  for (let i = 0; i < dates.length; i++) {
    for (let j = 0; j < dates[i].length; j++) {
      if (dates[i][j]["month"] === temp) {
        counter++;
      } else {
        components.push({ length: counter, label: months[temp] });
        counter = 1;
        temp = dates[i][j]["month"];
      }
    }
  }
  components.push({ length: counter, label: months[temp] });
  return components;
};

export const formatDate = (period: string[]) => {
  const start = moment(period[0]).format("YYYY-MM-DD[T]00:00:00.000");
  const end = moment(period[1]).format("YYYY-MM-DD[T]00:00:00.000");
  return [start, end];
};

export const dynamicSort = (properties: any[], order = "asc") => {
  let sortOrder = 1;
  if (order === "desc") {
    sortOrder = -1;
  }
  return function (a: any, b: any) {
    // a should come before b in the sorted order
    if (properties.some((property) => a[property] < b[property])) {
      return -1 * sortOrder;
      // a should come after b in the sorted order
    } else if (properties.some((property) => a[property] > b[property])) {
      return 1 * sortOrder;
      // a and b are the same
    } else {
      return 0 * sortOrder;
    }
  };
};

export const startCalendarFromMonth = (date: Date, firstWeek: TDate[]) => {
  const tempFirstWeek = firstWeek;
  for (let i = firstWeek.length - 1; i >= 0; i--) {
    const minDate = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
    const currentDate = new Date(
      firstWeek[i].year,
      firstWeek[i].month,
      firstWeek[i].date
    ).getTime();
    if (currentDate < minDate) tempFirstWeek.splice(i, 1);
  }
  return tempFirstWeek;
};

// Find the start of calendar if choose to start from selected week (avoid week/day different)
export const startCalendarFromSelectedWeek = (
  date: Date,
  finalDates: any[]
) => {
  const currentWeek = getWeekNumber(
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear()
  );
  const tempFinalDates = JSON.parse(JSON.stringify(finalDates));
  let stopLoop = false;
  for (let week of tempFinalDates) {
    if (stopLoop) break;
    for (let i = week.length - 1; i >= 0; i--) {
      const weekNumberOfDay = getWeekNumber(
        week[i].month + 1,
        week[i].date,
        week[i].year
      );
      const dateOfDay = new Date(week[i].year, week[i].month, week[i].date);
      if (
        weekNumberOfDay < currentWeek ||
        (weekNumberOfDay > currentWeek && dateOfDay.getTime() < date.getTime())
      )
        week.splice(i, 1);
      else {
        stopLoop = true;
        break;
      }
    }
  }
  return tempFinalDates.filter((item: any) => item.length);
};

export const removeFirstWeek = (dates: TDates, removeFirstWeek: boolean) => {
  let newDates = JSON.parse(JSON.stringify(dates));
  if (removeFirstWeek) newDates.splice(0, 1);
  return newDates;
};

export const getWeekNumber = (
  month: number | string,
  day: number | string,
  year: number | string
) => {
  const date = `${day}-${month}-${year}`;
  var weeknumber = moment(date, "DMYYYY").isoWeek();
  return weeknumber !== 53 ? weeknumber : 0;
};

export const getWeeksOfYear = (
  currentYear: number = new Date().getFullYear()
) => {
  const array: TDatesGenerator[] = new Array(12);
  array[0] = datesGenerator({ month: 0, year: currentYear, startingDay: 1 });

  for (let i = 1; i < array.length; i++) {
    array[i] = datesGenerator({
      month: array[i - 1].nextMonth,
      year: array[i - 1].nextYear,
      startingDay: 1,
    });
  }
  const { finalDates, currentWeekIndex } = calculateFinalDates(
    array,
    true /* getCurrentWeekIndex */
  ) as { finalDates: TDates; currentWeekIndex: number };

  const dt: TDate = finalDates[0][0];
  const date = new Date(dt.year, dt.month, dt.date);

  if (
    finalDates[0][6].year === currentYear &&
    new Date(date).getTime() === getMonday(date).getTime()
  ) {
  } else {
    finalDates.shift();
  }

  // This has to be done because we can't have 53 weeks
  // In some cases the first week is not thrown off
  if (
    finalDates.length > 52 &&
    finalDates[finalDates.length - 1][6].year !== currentYear
  ) {
    finalDates.splice(52, 1);
  }

  const now = new Date();
  const nowDay = now.getDate();
  const nowMonth = now.getMonth();
  const nowYear = now.getFullYear();

  const tempWeeks = [];
  for (let i = 0; i < finalDates.length; i++) {
    let isCurrentWeek = finalDates[i].some(
      (item) =>
        item.date === nowDay && item.month === nowMonth && item.year === nowYear
    );
    if (isCurrentWeek) {
      tempWeeks.push({
        isSelected: false,
        dates: finalDates[i],
        currentWeek: finalDates[i],
        year: currentYear,
      });
    } else {
      tempWeeks.push({
        isSelected: false,
        dates: finalDates[i],
        year: currentYear,
      });
    }
  }
  return tempWeeks;
};

export const getMonday = (date: Date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.setDate(diff));
};

export const getMonthNumberFromName = (monthName: string) => {
  return "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(monthName) / 3 + 1;
};

export const convertCamelCase = (str: string) => {
  const result = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

// @ts-ignore
export const findDifferent = (data, newData, key, usingDoubleQuotes = true) => {
  const doubleQuotes = usingDoubleQuotes ? '"' : "";
  if (!data) data = "";
  if (!newData) newData = "";
  if (typeof data === "string" || typeof data === "number") {
    if (data !== newData)
      return `Changed ${convertCamelCase(
        key
      )} from ${doubleQuotes}${data}${doubleQuotes} to ${doubleQuotes}${newData}${doubleQuotes}.`;
    else return null;
  } else if (Array.isArray(data)) {
    data = data.join(", ");
    newData = newData.join(", ");
    return findDifferent(data, newData, key);
    // @ts-ignore
  } else if (typeof data === "object") {
    // @ts-ignore
    const result = [];
    Object.keys(data).forEach((dataKey) => {
      const different = findDifferent(data[dataKey], newData[dataKey], key);
      if (different) result.push(different);
    });
    // @ts-ignore
    if (result.length) return result.join(", ");
  } else if (typeof data === "boolean") {
    if (data !== newData)
      return `Changed ${convertCamelCase(key)} from ${doubleQuotes}${
        data ? "Yes" : "No"
      }${doubleQuotes} to ${doubleQuotes}${
        newData ? "Yes" : "No"
      }${doubleQuotes}.`;
    else return null;
  }
};

export const checkTimeOverlap = (
  startDate: Date,
  endDate: Date,
  currentStartDate: Date,
  currentEndDate: Date,
  minusOneDay: boolean = true
) => {
  const startBeforeCurrentEnd = startDate.getTime() < currentEndDate.getTime();
  const endAfterCurrentStart =
    endDate.getTime() >
    currentStartDate.getTime() - (minusOneDay ? 86400000 : 0); //Minus one day
  const isOverLap = startBeforeCurrentEnd && endAfterCurrentStart;
  return isOverLap;
};

// Check for overlap machineService/staffVacation
// @ts-ignore
export const handleBeforeSubmitPeriod = (
  // @ts-ignore
  bookedSchedules,
  // @ts-ignore
  targetInfo,
  // @ts-ignore
  period,
  // @ts-ignore
  dispatch
) => {
  let overlap = false;
  if (bookedSchedules) {
    // @ts-ignore
    const overlapSchedules = bookedSchedules.filter((schedule) => {
      const currentStartDate = new Date(schedule.start);
      const currentEndDate = new Date(schedule.end);
      const isOverLap = checkTimeOverlap(
        period[0],
        period[1],
        currentStartDate,
        currentEndDate
      );
      return isOverLap;
    });

    if (overlapSchedules.length) {
      overlap = true;
      dispatch(
        showConflictMessage(
          {
            [targetInfo.roleKey]: {
              [targetInfo.fullName]: overlapSchedules,
            },
          },
          MessageBarType.error
        )
      );
    }
  }
  return overlap;
};
/*
    params: 
        rgb: rgb string code (ex: '0, 107, 173')
        ratio: -100 to 100 (use negative to make darker color)
*/
export const lighterDarkerColor = (rgbString: string, ratio: number) => {
  const rgb = rgbString
    .replace(/ /g, "")
    .split(",")
    .map((item) => parseInt(item));
  return rgb
    .map((value) => ((value += ratio) < 0 ? 0 : value > 255 ? 255 : value | 0))
    .join(",");
};

export const isProdUrl = () => {
  const urls = [
    "https://aarsleff.kodebaze.com",
    "https://fleetplanner.aarsleff.com",
  ];

  return urls.some((el) => window.location.href.includes(el));
};

export const addDaysToDate = (date: Date, days: number) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export type TFindStaffSchedule = typeof findStaffSchedule;

export const findStaffSchedule = (
  staffId: string,
  projectId: string,
  role: OverlapKeys,
  allPlansData: TPlan[]
): TStaffSchedule[] => {
  const staffSchedule: TStaffSchedule[] = [];
  allPlansData?.forEach((plan) => {
    plan.machineRequirements.forEach((requirement) => {
      (
        requirement[
          role as keyof MachineRequirementVMAdded
        ] as UserWorkerVMAdded[]
      )?.forEach((staff) => {
        if (
          plan.projectId !== projectId &&
          staff.id === staffId &&
          !plan.inactive
        ) {
          const staffInfo = {
            projectId: plan.projectId,
            name: plan.projectName,
            color: plan.color,
            start: staff.start,
            end: staff.end,
            crmProjectStatusCode: plan.crmProjectStatusCode,
          };
          staffSchedule.push(staffInfo);
        }
      });
    });
  });

  return staffSchedule;
};

// @ts-ignore
export const findStaffBarVariant = (verticalPositions, id) => {
  const staffBarVariant = Object.values(verticalPositions[id]);
  const staffBarUniqueVariant = new Set(Object.values(staffBarVariant)).size;
  return staffBarUniqueVariant;
};

export const removeDupplicateInArray = (arrayData: any[]) => {
  const stringArrayData = arrayData.map((item) => JSON.stringify(item));
  const arrayUniqueData = [...new Set(stringArrayData)].map((item) =>
    JSON.parse(item)
  );
  return arrayUniqueData;
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank")?.focus();
};

type TBorder = {
  borderRadius: number;
  boxSizing: string;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRight?: string;
  border?: string;
};

export const projectBorder = (
  isAttention: boolean,
  isQuotation: boolean
): TBorder => {
  let border: TBorder = { borderRadius: 4, boxSizing: "border-box" };
  if (isAttention && isQuotation) {
    border = {
      ...border,
      borderTop: "4px solid #CB033F",
      borderRight: "4px solid #CB033F",
      borderBottom: "4px solid #F28750",
      borderLeft: "4px solid #CB033F",
    };
  } else if (!isAttention && isQuotation) {
    border = {
      ...border,
      borderBottom: "4px solid #F28750",
    };
  } else if (isAttention && !isQuotation) {
    border = {
      ...border,
      border: "4px solid #CB033F",
    };
  }
  return border;
};

export const setCookie = (name: string, value: string, expiresTime: number) => {
  const d = new Date();
  d.setTime(d.getTime() + expiresTime);
  let expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return (parts.pop() as string).split(";").shift();
};

// @ts-ignore
export const getDatesInRange = (startDate, endDate) => {
  return eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  });
};

// @ts-ignore
export const updateProjectData = (
  // @ts-ignore
  data,
  // @ts-ignore
  newData,
  // @ts-ignore
  defaultData,
  // @ts-ignore
  currentUserId
) => {
  // @ts-ignore
  const different = [];
  Object.keys(data).forEach((key) => {
    let keyDataDifferent = null;

    switch (key) {
      case "personResponsbible":
        keyDataDifferent = findDifferent(
          data[key]?.name,
          typeof newData[key] === "string" ? newData[key] : newData[key]?.name,
          "personResponsible"
        );
        break;
      case "reason":
        keyDataDifferent = findDifferent(data[key], newData[key], "attention");
        break;
      case "geoCoordinate":
        keyDataDifferent = findDifferent(
          `${data[key]?.latitude || ""}, ${data[key]?.longitude || ""}`,
          `${newData[key]?.latitude || ""}, ${newData[key]?.longitude || ""}`,
          key
        );
        break;
      case "section":
        keyDataDifferent = findDifferent(
          // @ts-ignore
          defaultData?.sectionOptions.find((item) => item.key === data[key])
            ?.text,
          // @ts-ignore
          defaultData?.sectionOptions.find((item) => item.key === newData[key])
            ?.text,
          key
        );
        break;
      case "techDepartments":
        // @ts-ignore
        const techDepartments = data[key].map(
          // @ts-ignore
          (item) =>
            // @ts-ignore
            techDepartmentOptions.find((department) => department.key === item)
              .text
        );
        // @ts-ignore
        const newTechDepartments = newData[key].map(
          // @ts-ignore
          (item) =>
            // @ts-ignore
            techDepartmentOptions.find((department) => department.key === item)
              .text
        );
        keyDataDifferent = findDifferent(
          techDepartments,
          newTechDepartments,
          key
        );
        break;
      case "color":
        const color = data.color ? `rgb{${data.color}}` : "unset";
        const newColor = `rgb{${newData.color}}`;
        keyDataDifferent = findDifferent(
          color,
          newColor,
          key,
          false /*using double quotes*/
        );
        break;
      case "isQuotation":
        keyDataDifferent = findDifferent(
          data[key] ? "Yes" : "No",
          newData[key] ? "Yes" : "No",
          "Quotation"
        );
        break;
      case "start":
        const startText = moment(data.start, "YYYY-M-D").format("D.M.YY");
        const endText = moment(data.end, "YYYY-M-D").format("D.M.YY");
        const newStartText = moment(newData.start, "YYYY-M-D").format("D.M.YY");
        const newEndText = moment(newData.end, "YYYY-M-D").format("D.M.YY");
        keyDataDifferent = findDifferent(
          `${startText} - ${endText}`,
          `${newStartText} - ${newEndText}`,
          "period"
        );
        break;
      case "end":
        break;
      default:
        keyDataDifferent = findDifferent(data[key], newData[key], key);
        break;
    }
    if (keyDataDifferent) different.push(keyDataDifferent);
  });
  const personResponsbible = {
    userId: null,
    name:
      typeof newData.personResponsbible === "string"
        ? newData.personResponsbible
        : newData.personResponsbible?.name,
  };
  const activityLog = different.length
    ? {
        type: ACTIVITY_LOG_TYPE_ENUM.UPDATE,
        resourceId: data.projectId,
        resourceType: ACTIVITY_LOG_ENUM.PROJECT,
        userId: currentUserId,
        // @ts-ignore
        comment: different.join("\n"),
      }
    : null;
  const projectUpdatedData = {
    hovedsagsNummer: newData.hovedsagsNummer,
    projectId: newData.projectId,
    projectNo: newData.projectNo,
    projectName: newData.projectName,
    projectDescription: newData.projectDescription,
    color: newData.color,
    projectType: String(newData.projectType),
    start: newData.start,
    section: newData.section,
    end: newData.end,
    isQuotation: newData.isQuotation,
    reason: newData.reason,
    techDepartments: newData.techDepartments,
    geoCoordinate: newData.geoCoordinate,
    redirectToMachineReq: !!newData.redirectToMachineReq,
    personResponsbible,
    requiredSkills: newData.requiredSkills,
    activityLog,
  };
  return projectUpdatedData;
};

export const crmLink = (projectId: string, category: number) => {
  let etn =
    category === PROJECT_CATEGORY.Aftaleopgave.key
      ? "paa_rammeaftaleopgave"
      : "paa_projekt";

  return `https://aarsleff.crm4.dynamics.com/main.aspx?appid=ea908395-6d2b-4e16-ad33-2f7e5853f0e1&forceUCI=1&pagetype=entityrecord&etn=${etn}&id=${projectId}`;
};

// @ts-ignore
export const modifyCustomFilters = (defaultFilter, key, value) => {
  let newCustomFilters = defaultFilter.customFilters;
  // @ts-ignore
  const target = defaultFilter.customFilters?.find((item) => item.key === key);
  if (target) {
    // @ts-ignore
    const indexOfZoomRatio = defaultFilter.customFilters
      // @ts-ignore
      .map((item) => JSON.stringify(item))
      .indexOf(JSON.stringify(target));
    newCustomFilters[indexOfZoomRatio] = { key: key, text: value };
  } else {
    newCustomFilters = [
      ...(defaultFilter.customFilters || []),
      { key: key, text: value },
    ];
  }
  const newSettings = {
    ...defaultFilter,
    customFilters: newCustomFilters,
  };
  return newSettings;
};

// @ts-ignore
export const elementObserver = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const getTextWidth = (text: string, fontProp: string) => {
  var tag = document.createElement("div");
  tag.style.position = "absolute";
  tag.style.left = "-99in";
  tag.style.whiteSpace = "nowrap";
  tag.style.font = fontProp;
  tag.innerHTML = text;

  document.body.appendChild(tag);
  var result = tag.clientWidth;
  document.body.removeChild(tag);
  return result;
};

export const getCalendarHoliday = (
  dates: TDates,
  holidays: THoliday[]
): TDates => {
  // @ts-ignore
  let datesClone = dates.map((week) => {
    // @ts-ignore
    week = week.map((day) => {
      let isHoliday = false;
      let holidayName = "";
      const dateString = moment(
        new Date(day.year, day.month, day.date),
        "YYYY-M-D"
      ).format("YYYY-MM-DD");
      // @ts-ignore
      const holiday = holidays?.find((item) => item.date === dateString);
      if (holiday && holiday?.public) {
        isHoliday = true;
        holidayName = holiday.name;
      }
      return { ...day, isHoliday, holidayName };
    });
    return week;
  });
  return datesClone;
};

export const findYearOfDates = (dates: TDates) => {
  if (!dates.length) return [];
  const years = [];
  const firstYear = dates[0][0].year;
  years.push(firstYear);
  const secondYear = getLastDate(dates).year;
  if (firstYear !== secondYear) years.push(secondYear);
  return years;
};

export const getPeriodDuration = (start: Date, end: Date, type = "day") => {
  let startDate = moment(start, "YYYY-MM-DD");
  let endDate = moment(end, "YYYY-MM-DD");
  return type === "day"
    ? moment.duration(endDate.diff(startDate)).asDays() + 1
    : moment.duration(endDate.diff(startDate)).asMonths();
};

// @ts-ignore
export const hasEditRight = (rolesString) => {
  return rolesString?.includes(3);
  // return true;
};

export const getLastDate = (dates: TDates) =>
  dates.slice(-1).slice(-1)[0].slice(-1)[0];

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const getAvailableTimesOnConflict = (
  itemStartText: string,
  itemEndText: string,
  startText: string,
  endText: string
) => {
  const availableTimes = [];
  //There is three types of conflict
  /*
        Type 1:
            --------------------
        ==============
    */
  if (
    moment(itemStartText).isSameOrAfter(moment(startText)) &&
    moment(itemStartText).isBefore(moment(endText))
  ) {
    availableTimes.push({
      startText: moment(endText).add(1, "day").format("YYYY-MM-DD"),
      endText: itemEndText,
    });
  } else if (
    moment(itemStartText).isBefore(moment(startText)) &&
    moment(itemEndText).isAfter(moment(endText))
  ) {
    /*
        Type 2:
            --------------------
                ==========
    */
    availableTimes.push({
      startText: itemStartText,
      endText: moment(startText).add(-1, "day").format("YYYY-MM-DD"),
    });
    availableTimes.push({
      startText: moment(endText).add(1, "day").format("YYYY-MM-DD"),
      endText: itemEndText,
    });
  } else if (
    moment(itemStartText).isBefore(moment(startText)) &&
    moment(itemEndText).isAfter(moment(startText))
  ) {
    /*
        Type 3:
            --------------------
                        ==========
    */
    availableTimes.push({
      startText: itemStartText,
      endText: moment(startText).add(-1, "day").format("YYYY-MM-DD"),
    });
  }
  return availableTimes;
};

type TStaffListWithSameId = Root2[];

export interface Root2 {
  guid: any;
  id: string;
  firstName: string;
  lastName: string;
  machineId: string;
  machineName: string;
  imageUrl: any;
  techArea: number;
  starfVacations: TStaffVacation[];
  exceptionDates: any[];
  start: string;
  end: string;
  inactive: boolean;
  activityLog: any;
  serviceSchedules: any[];
  personResponsbible: string;
  isQuotation: boolean;
  projectNo: string;
  projectId: string;
  projectName: string;
  color: string;
  indexMachineRequirement: number;
  index: number;
  counter: number;
}

type TConflictList = { index: number; start: string; end: string };

/*
    For supervisor their plans maybe in multiple lines, this function calculate 
    the index and return the corresponding counter without overlap
*/
export const findStaffIndexAndCounter = (
  staffListWithSameId: TStaffListWithSameId,
  currentCounter: number,
  checkForSameId = false
) => {
  staffListWithSameId = staffListWithSameId.map((item, index) => ({
    ...item,
    index,
  }));

  const staffListConflictArray: {
    index: number;
    staffName: string;
    count: number;
    conflictList: TConflictList[];
  }[] = [];
  let max = 0;

  // Find the array of conflict for each item
  staffListWithSameId.forEach((staffPlan, index) => {
    let counter = 0;
    const conflictList: TConflictList[] = [];
    const startDate = new Date(staffPlan.start);
    const endDate = new Date(staffPlan.end);
    // @ts-ignore
    staffListWithSameId.forEach((item, subIndex) => {
      const currentStartDate = new Date(item.start);
      const currentEndDate = new Date(item.end);
      const isConflict = checkTimeOverlap(
        startDate,
        endDate,
        currentStartDate,
        currentEndDate
      );

      if (
        isConflict &&
        (checkForSameId ? staffPlan.machineId === item.machineId : true)
      ) {
        counter++;
        conflictList.push({
          index: item.index,
          start: item.start,
          end: item.end,
        });
      }
    });
    if (counter > max) max = counter;

    staffListConflictArray.push({
      index: staffPlan.index,
      staffName: `${staffPlan.firstName} ${staffPlan.lastName}`,
      count: counter,
      conflictList,
    });
  });

  // Get the largest conflict count -> it will be the number of lines
  const maxLengthConflictArray = staffListConflictArray.find(
    (item) => item.count === max
  );

  // List not included in the maxLengthConflictArray
  let listToCheck = staffListWithSameId.filter(
    (item) =>
      // @ts-ignore
      !maxLengthConflictArray?.conflictList.some(
        (el) => el.index === item.index
      )
  );

  // List included in the maxLengthConflictArray
  let listToCompare = staffListWithSameId.filter((item) =>
    // @ts-ignore
    maxLengthConflictArray.conflictList.some((el) => el.index === item.index)
  );

  // Append the counter to each item on the conflict list, because they can't in the same lines
  // @ts-ignore
  maxLengthConflictArray.conflictList.forEach((staffPlan) => {
    staffListWithSameId[staffPlan.index].counter = currentCounter;

    currentCounter++;
  });

  // Append the counter to other items
  listToCheck.forEach((staffPlan) => {
    const startDate = new Date(staffPlan.start);
    const endDate = new Date(staffPlan.end);
    for (const item of listToCompare) {
      let isConflict = false;
      const listHasSameCounter = staffListWithSameId.filter(
        (el) => el.counter === item.counter
      );
      for (const element of listHasSameCounter) {
        const currentStartDate = new Date(element.start);
        const currentEndDate = new Date(element.end);
        isConflict =
          checkTimeOverlap(
            startDate,
            endDate,
            currentStartDate,
            currentEndDate
          ) &&
          (checkForSameId ? element.machineId === staffPlan.machineId : true);

        if (isConflict) break;
      }
      if (!isConflict) {
        staffListWithSameId[staffPlan.index].counter = item.counter;
        break;
      }
    }
  });

  const positionAndCounter = staffListWithSameId.reduce(
    // @ts-ignore
    (acc, curr) => ({ ...acc, [curr.index]: curr.counter }),
    {}
  );

  return { positionAndCounter, currentCounter };
};

export const getReqCrmLink = (crmAnsvarlig: string) =>
  `${"https://aarsleff.crm4.dynamics.com/main.aspx?appid=ea908395-6d2b-4e16-ad33-2f7e5853f0e1&forceUCI=1&pagetype=entityrecord&etn=pai_teknologi&id="}${crmAnsvarlig}`;

export const idGenerator = () => Math.random().toString(16).slice(2);

// export const formatOptionLabel = ({ icon, label }: {icon:any,label: any}) => (
//     <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
//         <FontAwesomeIcon icon={icon} />
//         <div style={{ marginLeft: 8 }}>{label}</div>
//     </div>
// );

export const getUserOrbitId = ({
  aadEmployeeNo,
  aadCompanyNo,
  aadKoncernNo,
}: {
  aadEmployeeNo: string;
  aadCompanyNo: string;
  aadKoncernNo: string;
}) => {
  // User Id in Orbit portal is fixed to 9 digits.
  const maxLength = 9;

  if ([aadCompanyNo, aadKoncernNo, aadEmployeeNo].some((el) => !el)) {
    return null;
  }

  return (
    aadEmployeeNo +
    (aadCompanyNo + aadKoncernNo).padStart(
      maxLength - aadEmployeeNo.length,
      "0"
    )
  );
};

export const isValidDateString = (dateStr: string) =>
  !!dateStr && !isNaN(new Date().getTime());

export const deepMergeObjects = <T extends Record<string, any>>(
  obj1: T,
  obj2: T
): T => {
  const merged: Partial<T> = {};

  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      if (Object.prototype.hasOwnProperty.call(obj2, key)) {
        if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
          merged[key] = deepMergeObjects(obj1[key], obj2[key]);
        } else {
          merged[key] = obj2[key];
        }
      } else {
        merged[key] = obj1[key];
      }
    }
  }

  for (const key in obj2) {
    if (
      Object.prototype.hasOwnProperty.call(obj2, key) &&
      !Object.prototype.hasOwnProperty.call(obj1, key)
    ) {
      merged[key] = obj2[key];
    }
  }

  return merged as T;
};

export const isDateEqual = (date1: Date, date2: Date) => {
  const isoDate1 = date1.toISOString().slice(0, 10); // Extracting only the year, month, and day
  const isoDate2 = date2.toISOString().slice(0, 10);

  return isoDate1 === isoDate2;
};
