// Import libraries
// @ts-ignore
import { datesGenerator } from "dates-generator";
import moment from "moment";

// Import types
import { getHolidayList } from "../redux/app/app.actions";
import { AppDispatch } from "../redux/store";
import {
  TPlan,
  TProject,
  MachineRequirementVMAdded,
  UserWorkerVM,
  MachineProjectVMAdded,
} from "../types";
import { TDates, TDatesGenerator, THoliday } from "../types/dates";
import { TPlanHorizontalPositionMap, TPositionsToDate } from "../types/planner";

// Import utils
import {
  addDaysToDate,
  findYearOfDates,
  getCalendarHoliday,
  getMonday,
  startCalendarFromSelectedWeek,
} from "./utils";

// Import constants
const { crmSpecialStatus } = require("./constants");

type getPlanDatesArgType = {
  project: TProject;
  // machineRequirementIndex is the same as planning index in DetailedPlanning.ts
  machineRequirementIndex: number;
  plan: TPlan;
  calendarStepWidth: number;
  dispatch: AppDispatch;
};

export const getPlanData = async ({
  project,
  machineRequirementIndex,
  plan,
  calendarStepWidth,
  dispatch,
}: getPlanDatesArgType) => {
  let startDate = new Date(project.start);
  let endDate = new Date(project.end);

  if (
    startDate >
    new Date(plan.machineRequirements[machineRequirementIndex]?.start)
  ) {
    startDate = new Date(
      plan.machineRequirements[machineRequirementIndex].start
    );
  }
  if (
    endDate < new Date(plan.machineRequirements[machineRequirementIndex]?.end)
  ) {
    endDate = new Date(plan.machineRequirements[machineRequirementIndex].end);
  }

  if (
    Array.isArray(plan.machineRequirements[machineRequirementIndex]?.machines)
  ) {
    const keyToCheck: (keyof MachineRequirementVMAdded)[] = [
      "machines",
      "drivers",
      "workers",
      "managers",
    ];
    keyToCheck.forEach((key) => {
      (
        plan.machineRequirements[machineRequirementIndex][key] as
          | UserWorkerVM[]
          | MachineProjectVMAdded[]
      ).forEach((machine: UserWorkerVM | MachineProjectVMAdded) => {
        if (startDate > new Date(machine?.start)) {
          startDate = new Date(machine?.start);
        }
        if (endDate < new Date(machine?.end)) {
          endDate = new Date(machine?.end);
        }
      });
    });
  }

  if (endDate < new Date()) {
    endDate = addDaysToDate(new Date(), 60);
  }

  let datesData = datesGenerator({
    month: startDate.getMonth(),
    year: startDate.getFullYear(),
    startingDay: 1,
  }) as TDatesGenerator;
  let dates = startCalendarFromSelectedWeek(
    getMonday(startDate),
    datesData["dates"]
  ); //Get monday to start from the beginning of week

  while (datesData["nextYear"] <= endDate.getFullYear()) {
    datesData = datesGenerator({
      month: datesData.nextMonth,
      year: datesData.nextYear,
      startingDay: 1,
    });
    let temp = datesData["dates"];
    // removing duplicated weeks
    if (dates[dates.length - 1][0]["date"] === temp[0][0]["date"]) {
      temp.shift();
    }
    dates = [...dates, ...datesData["dates"]];
  }

  startDate.setHours(0);
  endDate.setHours(0);
  const startText = moment(startDate, "YYYY-M-D").format("D.M.YY");
  const endText = moment(endDate, "YYYY-M-D").format("D.M.YY");

  const horizontalPositionMap: TPlanHorizontalPositionMap = {
    startText,
    endText,
    // The bottom fields are just initialized purely for typescript
    end: 1,
    displayEnd: 1,
    positionsToDates: {},
  };

  const datePositionCorrespondence: TPositionsToDate = {};
  let datesToDisplay: TDates = [];
  let effectiveCounter = 0;
  let counter = 0;

  for (let i = 0; i < dates.length; i++) {
    let weekToSave = [];
    for (let j = 0; j < dates[i].length; j++) {
      //date: 1, month: 2, year: 2021
      let currentDate = new Date(
        dates[i][j]["year"],
        dates[i][j]["month"],
        dates[i][j]["date"]
      );
      if (currentDate > endDate && counter >= 66) {
        break;
      }
      if (startDate <= currentDate) {
        weekToSave.push(dates[i][j]);
        let key = `${dates[i][j]["year"]}-${dates[i][j]["month"] + 1}-${
          dates[i][j]["date"]
        }`;
        horizontalPositionMap[key] = effectiveCounter;
        datePositionCorrespondence[effectiveCounter * calendarStepWidth] = {
          value: moment(key, "YYYY-M-D").format("YYYY-MM-DD[T]HH:mm:ss.SSS"),
          display: moment(key, "YYYY-M-D").format("D.M.YY"),
        };
        effectiveCounter++;
      }
      counter++;
    }
    if (weekToSave.length) {
      datesToDisplay.push(weekToSave);
    }
  }

  // Find the holiday of calendar
  async function getDatesWithHolidays() {
    const yearOfDates = findYearOfDates(datesToDisplay);
    let holidays: THoliday[] = [];
    for (const year of yearOfDates) {
      const currentHolidays = await getHolidayList(year)(dispatch);
      holidays = [...holidays, ...(currentHolidays?.holidays || [])];
    }
    return getCalendarHoliday(datesToDisplay, holidays);
  }

  datesToDisplay = await getDatesWithHolidays();
  const newStartDate = new Date(
    datesToDisplay[0][0].year,
    datesToDisplay[0][0].month,
    datesToDisplay[0][0].date
  );
  horizontalPositionMap.startText = moment(newStartDate, "YYYY-M-D").format(
    "D.M.YY"
  );
  horizontalPositionMap["end"] = --effectiveCounter;
  horizontalPositionMap["displayEnd"] = --counter;
  horizontalPositionMap["positionsToDates"] = datePositionCorrespondence;

  return {
    dates: datesToDisplay,
    horizontalPositionMap,
  };
};

export const checkTimeOverlap = (
  start: string,
  end: string,
  currentStart: string,
  currentEnd: string
) => {
  const startDate = new Date(
    start
      .split("-")
      .map((item) => (item.length < 2 ? `0${item}` : item))
      .join("-")
  );
  const endDate = new Date(
    end
      .split("-")
      .map((item) => (item.length < 2 ? `0${item}` : item))
      .join("-")
  );
  const currentStartDate = new Date(currentStart);
  const currentEndDate = new Date(currentEnd);
  const startBeforeCurrentEnd = startDate <= currentEndDate;
  const endAfterCurrentStart = endDate >= currentStartDate;
  const isOverLap = startBeforeCurrentEnd && endAfterCurrentStart;
  return isOverLap;
};

// export const checkForOverlappedMachines = (currentMachines: TMachine[], projectId: string,allPlansData: TPlan[]) => {
//     const conflictMachineData = {};
//     currentMachines.forEach((machineReq) => {
//         machineReq.array.forEach((currentMachine) => {
//             const machineId = currentMachine.machineId;
//             const overlappedMachines : TMachine[] = [];
//             // Find all overlapped machines
//             allPlansData
//                 .filter((plan) => !plan.inactive && !crmSpecialStatus[plan.crmProjectStatusCode])
//                 .forEach((plan) => {
//                     plan.machineRequirements.forEach((requirement) => {
//                         requirement.machines.forEach((machine) => {
//                             const isOverLap = checkTimeOverlap(currentMachine.startText.value, currentMachine.endText?.value, machine.start, machine.end);
//                             if (plan.projectId !== projectId && machine.machineId === machineId && isOverLap) {
//                                 const machineInfo = {
//                                     ...machine,
//                                     projectId: plan.projectId,
//                                     name: plan.projectName,
//                                     color: plan.color,
//                                     backgroundCaro: false,
//                                 };
//                                 overlappedMachines.push(machineInfo);
//                                 if (!conflictMachineData[currentMachine.machineName]) conflictMachineData[currentMachine.machineName] = [];
//                                 // check if machine is presented in the array
//                                 if (!JSON.stringify(conflictMachineData[currentMachine.machineName]).includes(JSON.stringify(machineInfo)))
//                                     conflictMachineData[currentMachine.machineName].push(machineInfo);
//                             }
//                         });
//                     });
//                 });
//             machineReq.machineService?.forEach((service) => {
//                 const isOverLap = checkTimeOverlap(
//                     currentMachine.startText.value,
//                     currentMachine.endText?.value,
//                     service.start.slice(0, -1), // slice(0,-1) to remove timezone
//                     service.end.slice(0, -1)
//                 );
//                 if (isOverLap) {
//                     service.name = service.comment;
//                     service.backgroundCaro = true;
//                     overlappedMachines.push(service);
//                     if (!conflictMachineData[currentMachine.machineName]) conflictMachineData[currentMachine.machineName] = [];
//                     // check if service is presented in the array
//                     if (!JSON.stringify(conflictMachineData[currentMachine.machineName]).includes(JSON.stringify(service)))
//                         conflictMachineData[currentMachine.machineName].push(service);
//                 }
//             });
//             machineReq.machineBookedPrebookings?.forEach((prebooking) => {
//                 const isOverLap = checkTimeOverlap(
//                     currentMachine.startText.value,
//                     currentMachine.endText?.value,
//                     prebooking.start.slice(0, -1), // slice(0,-1) to remove timezone
//                     prebooking.end.slice(0, -1)
//                 );
//                 if (isOverLap) {
//                     prebooking.name = prebooking.prebookingName;
//                     prebooking.backgroundCaro = false;
//                     overlappedMachines.push(prebooking);
//                     if (!conflictMachineData[currentMachine.machineName]) conflictMachineData[currentMachine.machineName] = [];
//                     // check if prebooking is presented in the array
//                     if (!JSON.stringify(conflictMachineData[currentMachine.machineName]).includes(JSON.stringify(prebooking)))
//                         conflictMachineData[currentMachine.machineName].push(prebooking);
//                 }
//             });
//             currentMachine.overlapped = overlappedMachines;
//         });
//     });
//     return { machinesNew: currentMachines, conflictMachineData };
// };
