// Import libraries
import { addMonths } from "date-fns";

// Import types
import {
  // TProject,
  User,
} from "../types";
import { MachineData } from "../types/detailed-planning/machinelist";
import { AllMachineData } from "../types/custom";

export const GREEN_FILTER =
  "brightness(0) saturate(100%) invert(45%) sepia(82%) saturate(399%) hue-rotate(111deg) brightness(88%) contrast(91%)";
export const ORANGE_FILTER =
  "brightness(0) saturate(100%) invert(65%) sepia(43%) saturate(7101%) hue-rotate(7deg) brightness(100%) contrast(107%)";
export const BLACK_FILTER = "brightness(0)";

const getLastMachineAllocationDate = ({
  machine,
}: {
  machine: AllMachineData;
}) => {
  const arr = [...machine.array];

  const { length } = arr;

  if (!length) {
    return null;
  }

  arr.sort((a, b) => {
    return (
      new Date(a.endText.value).getTime() - new Date(b.endText.value).getTime()
    );
  });

  return arr[length - 1];
};

// Ticket 1301
// Project Icon :
// Green :  when the person has the skills and it is active, and with in the end date of the skill is after the last day that the machine is booked.
// Orange : if the person skill is not up to date or if the person do not have the skill
// Black : if the project dose not have any skills on it.

export const getProjectSkillColor = ({
  machine,
  projectSkills: requiredSkills,
  staff,
}: {
  machine: AllMachineData;
  projectSkills: string[];
  staff: User;
}) => {
  const { generalSkills } = staff;

  if (requiredSkills.length === 0) {
    return BLACK_FILTER;
  }

  const latestMachineTime = getLastMachineAllocationDate({ machine });

  if (!latestMachineTime) {
    return BLACK_FILTER;
  }

  const {
    endText: { value },
  } = latestMachineTime;

  const lastMachineEndDate = new Date(value);

  const matchingSkills = generalSkills.filter((skill) =>
    requiredSkills.includes(skill.skillId)
  );

  if (matchingSkills.length !== requiredSkills.length) {
    return ORANGE_FILTER;
  }

  return matchingSkills.every(
    (el) =>
      addMonths(new Date(el.acquired), el.expiryPeriod) >= lastMachineEndDate
  )
    ? GREEN_FILTER
    : ORANGE_FILTER;
};

// Ticket 1301
// Machine icon :
// Green : when the person has the machine certificate and it is active, AND with in the end date of the machine certificate is after the last day that the machine is booked AND the person has the skills that is need for driving the machine
// Orange : If the person dos not have machine certificate on the machine OR if the end date of the machine certificate is before the ending of the machine certificate OR the person skills is not up to date with what is required on the machine certificate.

export const getMachineSkillColor = ({
  machine,
  // project,
  staff,
}: {
  machine: AllMachineData;
  // project: TProject;
  staff: User;
}) => {
  const { machineSkills } = staff;

  const machineSkill = (machineSkills || []).find(
    (el) => el.machineId === machine.id
  );

  if (!machineSkill) {
    return ORANGE_FILTER;
  }

  const latestMachineTime = getLastMachineAllocationDate({ machine });

  // Covering an edge case where there is a machine but there is no allocation in the requirement/planning
  if (!latestMachineTime) {
    return BLACK_FILTER;
  }

  const {
    endText: { value },
  } = latestMachineTime;

  const lastMachineEndDate = new Date(value);

  const { inactive, reCertification } = machineSkill;

  return !inactive && new Date(reCertification) >= lastMachineEndDate
    ? GREEN_FILTER
    : ORANGE_FILTER;
};
