// Import libraries
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarMinus,
  faCalendarPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { mergeStyleSets } from "@fluentui/react";

// Import types
import { TDates } from "../../../types";

// Import components
import { DayLabel, MonthLabel } from "../../common/calendar";
import { WeekLabelDetailedPlanning } from "../planning/detailedPlanning";

// Import utils
import { getMonthIndicator } from "../../../utils/utils";
import { BACKGROUND_HEIGHT } from "../../../utils/constants";

// Import redux
import { changeCalendarLength } from "../../../redux/app/app.actions";

type MachineServiceCalendarProps = {
  dates: TDates;
  backgroundHeight?: number;
  widthOfCalendar: number;
};

const MachineServiceCalendar = ({
  dates,
  backgroundHeight = BACKGROUND_HEIGHT,
  widthOfCalendar,
}: MachineServiceCalendarProps) => {
  const dispatch = useDispatch();

  // ======================================== Use Selector =====================================
  const displayWeekView = useSelector(
    // @ts-ignore
    (state) => state.machine.displayWeekView
  ) as boolean;

  // @ts-ignore
  const { addCalendarLength } = useSelector((state) => state.app) as number;

  // ======================================== Style Sheet ======================================
  const classNames = mergeStyleSets({
    calendarContainer: {
      width: widthOfCalendar,
    },
    days: {
      display: "flex",
      justifyContent: "flex-start",
    },
    dayOfWeeks: {
      marginTop: -15,
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8,
    },
    weeks: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 4,
    },
    expandCalendarIcon: {
      marginLeft: 30,
      fontSize: 35,
      color: "#006CAD",
      cursor: "pointer",
    },
  });

  return (
    <>
      {dates.length ? (
        <div className={classNames.calendarContainer}>
          {displayWeekView && <div style={{ height: 44 }} />}
          <div className={classNames.weeks}>
            {dates.length &&
              getMonthIndicator(dates).map((monthData, indexMonth) => (
                <MonthLabel
                  isWeekView={displayWeekView}
                  index={indexMonth}
                  key={Math.random() + monthData["length"] + monthData["label"]}
                  length={monthData["length"]}
                  label={monthData["label"]}
                />
              ))}
          </div>
          <div className={classNames.weeks}>
            {dates.length > 0 &&
              dates.map((week, indexWeek) => (
                <WeekLabelDetailedPlanning
                  isWeekView={displayWeekView}
                  index={indexWeek}
                  length={week.length}
                  month={week[0]["month"]}
                  day={week[0]["date"]}
                  year={week[0]["year"]}
                  key={`${Math.random()} ${week[0]["month"]}-${
                    week[0]["date"]
                  }-${week[0]["year"]}`}
                />
              ))}
          </div>
          {displayWeekView && <div style={{ height: 2 }} />}
          {!displayWeekView && (
            <>
              <div className={classNames.days}>
                {dates.length > 0 &&
                  dates.map((week) =>
                    week.map((each) => (
                      <DayLabel
                        label={each.date}
                        // @ts-ignore
                        date={each}
                        key={JSON.stringify(each)}
                        isHoliday={each.isHoliday}
                        holidayName={each.holidayName}
                      />
                    ))
                  )}
                <FontAwesomeIcon
                  icon={
                    !addCalendarLength
                      ? (faCalendarPlus as IconDefinition)
                      : (faCalendarMinus as IconDefinition)
                  }
                  className={classNames.expandCalendarIcon}
                  onClick={() =>
                    dispatch(changeCalendarLength(!addCalendarLength ? 2 : 0))
                  }
                />
              </div>
              <div className={classNames.dayOfWeeks}>
                {dates.length > 0 &&
                  dates.map((week) =>
                    week.map((each) => (
                      <DayLabel
                        label={each.date}
                        // @ts-ignore
                        date={each}
                        key={JSON.stringify(each)}
                        isDaysOfWeek
                        heightOfBackground={backgroundHeight}
                        isHoliday={each.isHoliday}
                        holidayName={each.holidayName}
                      />
                    ))
                  )}
              </div>
            </>
          )}
        </div>
      ) : null}
    </>
  );
};

export default MachineServiceCalendar;
