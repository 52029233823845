import { useState, useEffect } from "react";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { Stack } from "@fluentui/react/";
import {
  ScrollablePane,
  ScrollbarVisibility,
} from "@fluentui/react/lib/ScrollablePane";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useDispatch, useSelector } from "react-redux";
import {
  COLOR_CONS_HEX,
  SOURCE_API,
  stationOptions,
} from "../../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { changeShowOtherSections } from "../../../../redux/app/app.actions";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";

const commonLabelStyle = {
  margin: 5,
  width: 25,
  height: 14,
  color: "#868685",
  background: "#DFDFDF",
  textAlign: "center",
  fontSize: 11,
  lineHeight: 14,
  borderRadius: 4,
};

const classNames = mergeStyleSets({
  Persona: {
    padding: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  station: {
    margin: 5,
    width: 25,
    height: 14,
    color: "#868685",
    background: "#DFDFDF",
    textAlign: "center",
    fontSize: 11,
    lineHeight: 14,
    borderRadius: 4,
    padding: 2,
  },
  stationEast: commonLabelStyle,
  stationWest: [
    commonLabelStyle,
    {
      color: "#DFDFDF",
      background: "#868685",
    },
  ],
  bar: {
    position: "absolute",
    left: "200px",
    cursor: "pointer",
    top: "28px",
  },
  draw: {
    position: "absolute",
    left: "220px",
    color: COLOR_CONS_HEX.BLUE,
    cursor: "pointer",
    top: "26px",
  },
});

const bookedStateStyle = (state) => ({
  position: "absolute",
  marginLeft: "235px",
  fontSize: "12px",
  textAlign: "right",
  borderRadius: "50%",
  color: state === BOOKED_STATE.PARTIAL ? "#ff7837" : "#d50000",
});

const BOOKED_STATE = {
  NOT: "NOT",
  PARTIAL: "PARTIAL",
  FULLY: "FULLY",
};

const PopupMachines = ({
  target,
  toggleTeaching,
  onAdd,
  otherSectionsMachine,
  machineData,
  machines,
  findNearestPossibleTime,
  horizontalPositionMap,
  findMachineBookedProjects,
  projectId,
  prebookingsData,
  onClickDraw,
  canDraw = true,
}) => {
  const dispatch = useDispatch();
  const [displayList, setDisplayList] = useState([]);
  const { techDepartment } = useSelector((state) => state?.machine?.filter);
  const { sizeGroupOptions } = useSelector((state) => state.defaultData);
  const showOtherSections = useSelector(
    (state) => state.app.showOtherSections.machines
  );
  const startCalendar = horizontalPositionMap.startText.split(".").reverse();
  const endCalendar = horizontalPositionMap.endText.split(".").reverse();
  const startCalendarDate = new Date(
    "20" + startCalendar[0],
    startCalendar[1] - 1,
    startCalendar[2]
  );
  const endCalendarDate = new Date(
    "20" + endCalendar[0],
    endCalendar[1] - 1,
    endCalendar[2]
  );
  const currentMachineData = showOtherSections
    ? [...machineData, ...otherSectionsMachine]
    : machineData;
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const tempList = currentMachineData
      .filter((machine) =>
        machine.machineName.toLowerCase().includes(searchValue.toLowerCase())
      )
      .map((item) => ({
        ...item,
        sizeGroupDisplay:
          sizeGroupOptions.find((sizeGroup) => sizeGroup.key === item.sizeGroup)
            ?.text ?? "UNKNOWN",
      }));
    setDisplayList(tempList);
  }, [machineData, sizeGroupOptions, showOtherSections, searchValue]);

  const checkMachineBookedCondition = (machineId) => {
    let machinePlanning = machines.find((machine) => machine.id === machineId);
    if (!machinePlanning) {
      const machineBookedProjects = findMachineBookedProjects(
        machineId,
        projectId
      )?.filter(
        (item) =>
          new Date(item.start.slice(0, -1)) <= endCalendarDate &&
          new Date(item.end.slice(0, -1)) >= startCalendarDate
      );

      const machineBookedPrebookings = prebookingsData
        .filter((item) => item.machineId === machineId)
        .map((item) => ({
          color: "242, 135, 5",
          end: item.end,
          prebookingName: item.projectName,
          start: item.start,
        }))
        ?.filter(
          (item) =>
            new Date(item.start.slice(0, -1)) <= endCalendarDate &&
            new Date(item.end.slice(0, -1)) >= startCalendarDate
        );

      const machineService = currentMachineData
        .find((machine) => machine.machineId === machineId)
        ?.machineService?.filter(
          (item) =>
            new Date(item.start.slice(0, -1)) <= endCalendarDate &&
            new Date(item.end.slice(0, -1)) >= startCalendarDate
        );
      if (
        !machineBookedProjects?.length &&
        !machineService?.length &&
        !machineBookedPrebookings?.length
      )
        return BOOKED_STATE.NOT;
      machinePlanning = {
        id: machineId,
        array: [],
        machineService,
        machineBookedProjects,
      };
    }
    const possibleTime = findNearestPossibleTime(
      machinePlanning,
      horizontalPositionMap,
      "machine"
    );
    if (possibleTime) return BOOKED_STATE.PARTIAL;
    return BOOKED_STATE.FULLY;
  };

  const TechAreaRenderer = ({ techDepartments, techArea, index }) => {
    const classes = [classNames.stationEast, classNames.stationWest];
    let className = null;
    let text = null;
    if (techDepartments !== "-1") {
      let options = [];
      if (techDepartments.includes(techDepartment)) {
        options = stationOptions[techDepartment].options;
      } else
        options = techDepartments
          .split(";")
          .reduce((acc, curr) => [...acc, ...stationOptions[curr].options], []);
      if (options) {
        for (let i = 0; i < options.length; i++) {
          if (parseInt(techArea) === options[i].key) {
            text = options[i].shortcutText;
            if (i > 1) className = classes[Math.floor((i + 1) / 2)];
            else className = classes[i];
            break;
          }
        }
      }
    }
    return (
      <div key={index} className={className}>
        {text}
      </div>
    );
  };

  return (
    <TeachingBubble
      target={`#${target}`}
      hasSmallHeadline={true}
      onDismiss={toggleTeaching}
      closeButtonAriaLabel="Close"
    >
      {/* <SearchBox
                styles={searchBoxStyles}
                placeholder='Søg'
                clearButtonProps={{
                    style: {
                        backgroundColor: '#FFFFFF',
                        marginLeft: '20px',
                    },
                }}
                value={searchValue}
                onChange={(e, value) => setSearchValue(value)}
            />
            <hr style={{ width: '145%', marginLeft: '-50px', marginTop: '-2px', opacity: 0.5 }} /> */}
      <div
        style={{
          height: "240px",
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ScrollablePane
          style={{ height: "200px" }}
          scrollbarVisibility={ScrollbarVisibility.auto}
        >
          <Stack vertical tokens={{ childrenGap: 10 }}>
            {displayList &&
              displayList.map((machine) => (
                <div
                  key={`machine-${machine.machineId}`}
                  className={classNames.Persona}
                  style={{
                    opacity: `${
                      checkMachineBookedCondition(machine.machineId) ===
                      BOOKED_STATE.FULLY
                        ? 0.4
                        : 1
                    }`,
                    cursor: `${
                      checkMachineBookedCondition(machine.machineId) ===
                      BOOKED_STATE.FULLY
                        ? "context-menu"
                        : ""
                    }`,
                    position: "relative",
                  }}
                  // onClick={() => {
                  //   checkMachineBookedCondition(machine.machineId) !==
                  //     BOOKED_STATE.FULLY && onAdd(machine);
                  // }}
                >
                  <Persona
                    text={machine.machineName}
                    secondaryText={machine.sizeGroupDisplay}
                    size={PersonaSize.size40}
                    imageUrl={
                      machine.imageUrl ? `${SOURCE_API}${machine.imageUrl}` : ""
                    }
                  />
                  {[BOOKED_STATE.PARTIAL, BOOKED_STATE.FULLY].includes(
                    checkMachineBookedCondition(machine.machineId)
                  ) && (
                    <FontAwesomeIcon
                      style={{
                        ...bookedStateStyle(
                          checkMachineBookedCondition(machine.machineId)
                        ),
                        position: "absolute",
                        top: "26px",
                      }}
                      icon={faCircle}
                    />
                  )}
                  <Image
                    src={`${window.location.origin}/img/bar.svg`}
                    imageFit={ImageFit.cover}
                    alt="place staff"
                    className={classNames.bar}
                    onClick={() => {
                      checkMachineBookedCondition(machine.machineId) !==
                        BOOKED_STATE.FULLY && onAdd(machine);
                    }}
                  />
                  {canDraw ? (
                    <FontAwesomeIcon
                      icon={faPencil}
                      className={classNames.draw}
                      onClick={() => {
                        checkMachineBookedCondition(machine.machineId) !==
                          BOOKED_STATE.FULLY &&
                          onClickDraw &&
                          onClickDraw(machine);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <div>
                    {machine.techAreas.split(";").map((techArea, index) => (
                      <TechAreaRenderer
                        techDepartments={machine.techDepartments}
                        key={index}
                        techArea={techArea}
                      />
                    ))}
                  </div>
                </div>
              ))}
          </Stack>
        </ScrollablePane>
        <div
          style={{
            margin: "220px auto 20px auto",
            color: "rgb(0, 108, 173)",
            cursor: "pointer",
          }}
          onClick={() =>
            dispatch(changeShowOtherSections(!showOtherSections, "machines"))
          }
        >
          {showOtherSections ? "Hide" : "Show"} other sections
        </div>
      </div>
    </TeachingBubble>
  );
};

export default PopupMachines;
