import React, { Fragment } from 'react';
import { ProjectNameBar, ProjectTable } from './';

const ProjectDetail = ({ project }) => {
    return (
        <Fragment>
            <ProjectNameBar
                projectName={project.projectName}
                projectColor={project.color}
                address={project.address}
                postNummer={project.postNummer}
                projectBy={project.projectBy}
            />
            <ProjectTable project={project} />
            <div style={{ height: 16 }} />
        </Fragment>
    );
};

export default ProjectDetail;
