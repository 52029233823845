import React, { useState, useEffect, useMemo } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { machineColorList, stationOptions } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faEllipsisVAlt,
  faFile,
  faPalette,
  faQrcode,
} from "@fortawesome/pro-regular-svg-icons";
import { useHistory } from "react-router-dom";
import {
  workingRoleOptions,
  SOURCE_API,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  CALENDAR_PAGE,
} from "../../utils/constants";
import { connect, useSelector } from "react-redux";
import {
  lighterDarkerColor,
  openInNewTab,
  hasEditRight,
} from "../../utils/utils";
import { ColorPickerComponent, ColorSelectorItem, TooltipForText } from ".";
import Select from "react-select";
import {
  customAutoCompleteStyles,
  primaryButtonStyles,
} from "../../utils/theme";
import { IconButton, PrimaryButton } from "@fluentui/react";
import { useMutation, useQueryClient } from "react-query";
import { createPlan } from "../../redux/plan/plan.actions";
import { useDispatch } from "react-redux";
import { planActivityLogComposer } from "../pages/PlanningUtils";
import moment from "moment";
import CalendarCustom from "./CalendarCustom";
import CalendarSchedule from "./calendar/CalendarSchedule";
import { getMachineDocumentLink } from "../../redux/machines/machines.utils";

const commonLabelStyle = {
  margin: 5,
  width: 25,
  height: 14,
  color: "#868685",
  background: "#DFDFDF",
  textAlign: "center",
  fontSize: 11,
  lineHeight: 14,
  borderRadius: 4,
};

const ItemContainer = ({
  name,
  techDepartments,
  techAreas,
  index,
  imageUrl,
  machineDocumentUrl,
  machineId,
  workingRole,
  count = 1,
  calendarPage,
  color,
  machineData,
  updateMachineService,
  isPlanningPage = false,
  setDisableDragMachine,
  unplannedItems,
  isUpdated,
  machineBarList,
  horizontalPositionMap,
  calendarStart,
  calendarEnd,
  isClone,
  staffId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { techDepartment } = useSelector((state) => state?.machine?.filter);
  const { machineTypeOptions, sizeGroupOptions } = useSelector(
    (state) => state.defaultData
  );
  const { highlightedMachineId, highlightedStaffId } = useSelector(
    (state) => state.app
  );
  const displayStaffView = useSelector(
    (state) => state.machine.displayStaffOnPlanning
  );
  const currentUserId = useSelector((state) => state?.user?.user?.userId);
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const [timerRef, setTimerRef] = useState();

  const [calendarStepHeight, setCalendarStepHeight] = useState(
    displayStaffView ? CALENDAR_STAFFVIEW_STEP_HEIGHT : CALENDAR_STEP_HEIGHT
  );
  const [selectedUnplannedItem, setSelectedUnplannedItem] = useState(null);
  const [period, setPeriod] = useState(null);
  const [showNewDate, setShowNewDate] = useState(false);
  const [overlapped, setOverlapped] = useState(false);
  const [teachingBubbleShowImage, { toggle: toggleTeachingShowImage }] =
    useBoolean(false);
  const [showMachineActions, { toggle: toggleShowMachineActions }] =
    useBoolean(false);
  const height = useMemo(
    () => calendarStepHeight * count,
    [calendarStepHeight, count]
  );
  const showDocumentIcon =
    !!machineDocumentUrl && machineDocumentUrl?.length > 0;
  // Highlight machine/staff with according y axis which is being hovered when draw bar
  if (
    isPlanningPage &&
    ((staffId === highlightedStaffId && !isClone && displayStaffView) ||
      (!displayStaffView && machineId === highlightedMachineId))
  )
    color = "153, 179, 255";

  const modifiedUnplannedItems = unplannedItems?.map((plan) => {
    const machineType =
      machineTypeOptions
        .find((item) => item.id === plan.techDepartment)
        ?.options.find((item) => item.key === plan.machineType)?.text || "None";
    const machineSizeGroup =
      sizeGroupOptions.find((item) => item.key === plan.sizeGroup)?.text ||
      "Unknown";

    return {
      ...plan,
      label: plan.projectName,
      subText: `${machineType} - ${machineSizeGroup}`,
    };
  });

  const machineActionsBubbleStyles = () => ({
    root: {
      marginTop:
        document
          .getElementById(`machineActions-${machineId}`)
          ?.getBoundingClientRect().top > 130
          ? showDocumentIcon
            ? 90
            : 70
          : 0,
      width: 600,
    },
    subComponentStyles: {
      callout: {
        beak: {
          marginTop:
            document
              .getElementById(`machineActions-${machineId}`)
              ?.getBoundingClientRect().top > 130
              ? -(showDocumentIcon ? 90 : 70)
              : 0,
        },
      },
    },
    subText: { color: "black" },
  });

  const classNames = mergeStyleSets({
    root: {
      width: 250,
      height: height,
      borderBottom: "1px solid #DBDBDB",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: `rgb(${color})`,
      selectors: {
        ":hover": {
          backgroundColor: color
            ? `rgb(${lighterDarkerColor(color, -10)})`
            : "rgb(232, 232, 232)",
        },
      },
      boxSizing: "border-box",
    },
    iconSection: {
      display: "flex",
      alignItems: "center",
      userSelect: "none",
    },
    iconContainer: {
      width: 24,
    },
    icon: {
      color: "#006cad",
      fontSize: 16,
    },
    staffIcon: {
      marginRight: 8,
    },
    text: {
      display: "flex",
      alignItems: "center",
      maxWidth: 190,
      marginLeft: 16,
      fontWeight: "bold",
      fontSize: 11,
      lineHeight: 13,
      wordWrap: "break-word",
      userSelect: "none",
    },
    stationEast: commonLabelStyle,
    stationWest: [
      commonLabelStyle,
      {
        color: "#DFDFDF",
        background: "#868685",
      },
    ],
    colorSelector: {
      display: "grid",
      gridTemplateColumns: "auto auto auto auto auto",
      gridGap: 20,
      float: "left",
      padding: 12,
      maxWidth: 200,
      minWidth: 200,
    },
    actionHeader: {
      fontWeight: "bold",
      fontFamily: "Verdana",
      fontSize: 13,
    },
    section: {
      margin: "10px 0",
    },
  });

  const exitIconStyles = {
    root: {
      color: "rgb(86,85,85)",
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px",
    },
    rootHovered: {
      backgroundColor: "#b2b2b2",
    },
  };

  useEffect(() => {
    let originalHeight = displayStaffView
      ? CALENDAR_STAFFVIEW_STEP_HEIGHT
      : CALENDAR_STEP_HEIGHT;
    if (count > 1 && displayStaffView) {
      originalHeight = displayStaffView
        ? CALENDAR_STAFFVIEW_STEP_HEIGHT
        : CALENDAR_STEP_HEIGHT;
    }
    if (calendarPage !== CALENDAR_PAGE.DISPOSITION) {
      originalHeight = CALENDAR_STEP_HEIGHT;
    }
    if (calendarPage === CALENDAR_PAGE.STAFF) {
      originalHeight = CALENDAR_STAFFVIEW_STEP_HEIGHT;
    }
    setCalendarStepHeight(originalHeight);
  }, [calendarPage, count, displayStaffView]);

  //   const history = useHistory();
  const imageUrlRoleStaff = workingRoleOptions.find((item) =>
    (workingRole || "").split(";").includes(item.key + "")
  )?.icon;
  //   const workingRoleText =
  //     workingRoleOptions.find((item) =>
  //       (workingRole || "").split(";").includes(item.key + "")
  //     )?.text || "";
  const toggleMachineAction = async () => {
    // Close all previous machine action bubble
    document.getElementById(`machineBubble`)?.closest(".ms-Layer")?.remove();

    if (timerRef) {
      clearTimeout(timerRef);
    }

    const ref = setTimeout(() => {
      toggleShowMachineActions();
      resetState();
    }, 10);

    setTimerRef(ref);
  };

  useEffect(() => {
    if (isPlanningPage && machineBarList) {
      if (showMachineActions) setDisableDragMachine(true);
      else setDisableDragMachine(false);
    }
  }, [showMachineActions]);

  const mutationUpdatePlan = useMutation(
    (plan) => dispatch(createPlan(plan.data)),
    {
      onSuccess: (data, variables) => {
        toggleShowMachineActions();
      },
      onFailure: (data, variables) => {},
      onSettled: () => {
        queryClient.invalidateQueries("machines");
        queryClient.invalidateQueries("plans");
      },
    }
  );

  const isOverlap = (startDate, endDate) => {
    const keyStart = moment(startDate).utcOffset(0).format("YYYY-M-D");
    const keyEnd = moment(endDate).add(1, "d").utcOffset(0).format("YYYY-M-D");

    let start = horizontalPositionMap[keyStart];
    let end = horizontalPositionMap[keyEnd];

    if (
      new Date(startDate.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
      new Date(endDate.slice(0, -1))?.getTime() >= calendarEnd?.getTime() &&
      typeof start === "undefined" &&
      typeof end === "undefined"
    ) {
      start = 0;
      end = horizontalPositionMap["end"];
    }

    if (
      new Date(startDate.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
      typeof end !== "undefined"
    ) {
      start = 0;
    }

    if (new Date(endDate.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
      end = horizontalPositionMap["end"];
    }

    const listToCheck = [
      ...machineBarList.listOfPlan,
      ...machineBarList.listOfService,
    ];
    for (let i = 0; i < listToCheck.length; i++) {
      const startBeforeCurrentPlanEnd =
        typeof listToCheck[i].end === "undefined"
          ? true
          : start <= listToCheck[i].end;
      const endAfterCurrentPlanStart =
        typeof listToCheck[i].start === "undefined"
          ? true
          : end > listToCheck[i].start;

      if (startBeforeCurrentPlanEnd && endAfterCurrentPlanStart) {
        // saveMessage('Cannot Overlap');
        return true;
      }
    }
    return false;
  };

  const updateProjectMachine = async () => {
    setOverlapped(false);
    if (!selectedUnplannedItem) return;
    const data = JSON.parse(JSON.stringify(selectedUnplannedItem.plan));
    const machineRequirementIndex =
      selectedUnplannedItem.machineRequirementIndexInPlan;

    delete data.createBy;
    delete data.createdDate;
    delete data.modifiedDate;
    delete data.modifyBy;

    const newMachine = {
      machineId: machineId,
      machineName: name,
      imageUrl: imageUrl,
      machineType:
        data["machineRequirements"][machineRequirementIndex]["machineType"],
      sizeGroup:
        data["machineRequirements"][machineRequirementIndex]["sizeGroup"],
      techAreaEnum:
        data["machineRequirements"][machineRequirementIndex]["techArea"],
      start: showNewDate
        ? period[0].toISOString()
        : data["machineRequirements"][machineRequirementIndex]["start"],
      end: showNewDate
        ? period[1].toISOString()
        : data["machineRequirements"][machineRequirementIndex]["end"],
    };
    data["machineRequirements"][machineRequirementIndex]["machines"] = [
      newMachine,
    ];

    const activityLog = await planActivityLogComposer(
      selectedUnplannedItem.plan,
      data.machineRequirements[machineRequirementIndex],
      currentUserId
    );
    data.activityLog = activityLog;
    const overlap = isOverlap(newMachine.start, newMachine.end);
    if (!overlap) {
      await mutationUpdatePlan.mutate({ data: data });
      if (typeof isUpdated === "function") {
        isUpdated(selectedUnplannedItem.projectId);
      }
    } else {
      setPeriod([new Date(newMachine.start), new Date(newMachine.end)]);
      setOverlapped(true);
    }
  };

  const onSelectPeriod = (period) => {
    setPeriod(period);
    if (!isOverlap(period[0].toISOString(), period[1].toISOString())) {
      setOverlapped(false);
    } else setShowNewDate(true);
  };

  const resetState = () => {
    setOverlapped(false);
    setShowNewDate(false);
    setPeriod(null);
  };

  const formatOptionLabel = ({ value, label, subText }) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>{label}</div>
      <div style={{ color: "#808080", fontSize: 10 }}>{subText}</div>
    </div>
  );

  const TechAreaRenderer = ({ techArea, index }) => {
    const classes = [classNames.stationEast, classNames.stationWest];
    let className = null;
    let text = null;
    if (techDepartments !== "-1") {
      const options = stationOptions[techDepartment].options;
      if (options) {
        for (let i = 0; i < options.length; i++) {
          if (parseInt(techArea) === options[i].key) {
            text = options[i].shortcutText;
            if (i > 1) className = classes[Math.floor((i + 1) / 2)];
            else className = classes[i];
            break;
          }
        }
      }
    }
    return (
      <div key={index} className={className}>
        {text}
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames.root}
        onDoubleClick={toggleTeachingShowImage}
        id={`showImage-${index}`}
      >
        {/* <TooltipForText text={staffId}> */}
        <div className={classNames.text}>
          {imageUrlRoleStaff && (
            <div className={classNames.iconContainer}>
              <FontAwesomeIcon
                icon={imageUrlRoleStaff}
                className={classNames.icon}
              />
            </div>
          )}
          {/* {`${name} ${workingRoleText}`} */}
          {`${name}`}
        </div>
        {/* </TooltipForText> */}
        <div className={classNames.iconSection}>
          <div
            style={{
              width: 20,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={toggleMachineAction}
          >
            {machineData && (
              <FontAwesomeIcon
                id={`machineActions-${machineId}`}
                icon={faEllipsisVAlt}
                className={classNames.icon}
              />
            )}
            {isClone && (
              <FontAwesomeIcon
                id={`machineActions-${machineId}`}
                icon={faClone}
                className={classNames.icon}
              />
            )}
          </div>
          <div>
            {techAreas.split(";").map((techArea, index) => (
              <TechAreaRenderer key={index} techArea={techArea} />
            ))}
          </div>
        </div>
      </div>
      {teachingBubbleShowImage && (
        <TeachingBubble
          closeButtonAriaLabel="Close"
          target={`#showImage-${index}`}
          onDismiss={toggleTeachingShowImage}
        >
          <Image
            imageFit={ImageFit.contain}
            src={imageUrl ? `${SOURCE_API}${imageUrl}` : ""}
            alt="Machine Image"
          />
        </TeachingBubble>
      )}

      {showMachineActions && (
        <TeachingBubble
          closeButtonAriaLabel="Close"
          target={`#machineActions-${machineId}`}
          styles={machineActionsBubbleStyles}
        >
          <div
            id="machineBubble"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "-15px",
            }}
          >
            <IconButton
              styles={exitIconStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={toggleMachineAction}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              pointerEvents: allowEdit ? "auto" : "none",
            }}
          >
            <div className={classNames.section}>
              <span className={classNames.actionHeader}>
                Change background color
              </span>
              <div className={classNames.colorSelector}>
                {machineColorList.map((currentColor) => (
                  <ColorSelectorItem
                    key={currentColor}
                    onClick={() =>
                      updateMachineService({
                        ...machineData,
                        color: currentColor,
                      })
                    }
                    colorCode={currentColor}
                    selected={currentColor === color ? true : false}
                  />
                ))}
                <ColorSelectorItem
                  onClick={() =>
                    updateMachineService({ ...machineData, color: null })
                  }
                  colorCode="241,241,241"
                  selected={!color}
                />
              </div>
            </div>
            {isPlanningPage && (
              <div className={classNames.section}>
                <span className={classNames.actionHeader}>Assign Project</span>
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "70%" }}>
                    <div
                      style={{
                        position: "absolute",
                        fontFamily: "Verdana",
                        fontSize: 11,
                        transform: "translate(9px, 18px)",
                        marginTop: -13,
                        zIndex: 2,
                      }}
                    >
                      UNASSIGNED PROJECTS
                    </div>
                    <Select
                      placeholder="NAVN"
                      closeMenuOnSelect={true}
                      options={modifiedUnplannedItems}
                      isClearable={true}
                      isSearchable={true}
                      onChange={(item) => {
                        if (item) {
                          resetState();
                          setSelectedUnplannedItem(item);
                        }
                      }}
                      className={classNames.input}
                      styles={customAutoCompleteStyles(false, true)}
                      getOptionValue={(option) => option.label}
                      formatOptionLabel={formatOptionLabel}
                    />
                  </div>
                  <div style={{ marginTop: 4 }}>
                    <PrimaryButton
                      text="Assign"
                      disabled={overlapped}
                      onClick={updateProjectMachine}
                      styles={{ ...primaryButtonStyles }}
                    />
                  </div>
                </div>
              </div>
            )}
            {(overlapped || showNewDate) && (
              <div className={classNames.section} style={{ marginTop: 20 }}>
                {overlapped && (
                  <span
                    className={classNames.actionHeader}
                    style={{ color: "rgb(164, 38, 44)" }}
                  >
                    Overlapped! Please select another period
                  </span>
                )}
                <CalendarSchedule target="machine" targetInfo={machineData}>
                  <CalendarCustom period={period} setPeriod={onSelectPeriod} />
                </CalendarSchedule>
              </div>
            )}
            <div
              className={classNames.section}
              style={{ marginTop: 20, pointerEvents: "auto" }}
            >
              <FontAwesomeIcon icon={faQrcode} className={classNames.icon} />
              &ensp;
              <span
                style={{
                  color: "#006CAD",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  openInNewTab(getMachineDocumentLink(machineData.machineNo))
                }
              >
                Sharepoint link
              </span>
            </div>
            {/* {showDocumentIcon && (
                            <div className={classNames.section} style={{marginTop: 20, pointerEvents: 'auto'}}>
                                <FontAwesomeIcon icon={faFile} className={classNames.icon}/>
                                &ensp;
                                <span
                                    style={{
                                        color: '#006CAD',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => history.push(`/machine-document/${machineId}`)}
                                >Document</span>
                            </div>
                        )} */}
          </div>
        </TeachingBubble>
      )}
    </>
  );
};

export default ItemContainer;
