// Import libraries
import { IStyleFunctionOrObject, ITeachingBubbleStyleProps, ITeachingBubbleStyles, PrimaryButton, TeachingBubble } from "@fluentui/react";
import { useEffect, useState } from "react";

type TextWithTeachingBubbleProps = {
    target: string,
    onDismiss: () => void,
    value?: string,
    onChangeValue: (val: string) => void
};

const teachingBubbleStyles: IStyleFunctionOrObject<ITeachingBubbleStyleProps, ITeachingBubbleStyles> = {
    bodyContent: {
        padding: "20px 18px",
        width: '100%'
    },
    content: {
        width: '100%',
    },
    subText: {
        width: '100%'
    },
};

const TextWithTeachingBubble = ({ target, onDismiss, value = "", onChangeValue }: TextWithTeachingBubbleProps) => {
    const [tempValue, setTempValue] = useState<string>('');

    useEffect(() => {
        if (value) {
            setTempValue(value);
        }
    }, [value]);

    return (
        <TeachingBubble
            styles={teachingBubbleStyles}
            target={target}
            hasSmallHeadline={true}
            onDismiss={onDismiss}
            closeButtonAriaLabel="Close"
            isWide
        >
            <textarea
                value={tempValue}
                onChange={(e) => {
                    setTempValue(e.target.value);
                }}
                style={{
                    width: '415px',
                    resize: 'vertical'
                }}
            />
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: 35,
                paddingTop: 10
            }}>
                <PrimaryButton text="Gem" onClick={(e) => {
                    e.stopPropagation();
                    onChangeValue(tempValue);
                    onDismiss();
                }} />
            </div>
        </TeachingBubble>
    )
}

export default TextWithTeachingBubble