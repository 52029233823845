import React, { useCallback } from "react";
import { connect } from 'react-redux';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "../../redux/file/file.actions";
import { SOURCE_API } from '../../utils/constants';

const classNames = mergeStyleSets({
    uploadZone: {
        border: "solid 1px #DFDFDF",
        marginBottom: 24,
        padding: 8,
        minHeight: 240,
    },
    flex: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 180,
    },
    iconClass: {
        fontSize: 50,
        height: 50,
        width: 50,
        margin: "0 25px",
        color: "#868685",
    },
    upper: {
        textTransform: "uppercase",
    },
});

const FileUpload = ({ placeholder, setFile, imageUrl, uploadFile }) => {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach(async (file) => {
            const uploadResult = await uploadFile(file);
            setFile(uploadResult['filePath']);
        });
    }, []);
    
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png'
    });
    

    return (
        <div {...getRootProps()} className={classNames.uploadZone}>
            <input {...getInputProps()} />
            {!imageUrl ? (
                <div className={classNames.beforeDrop}>
                    {placeholder && (
                        <span className={classNames.upper}>{placeholder}</span>
                    )}
                    <div className={classNames.flex}>
                        <FontIcon iconName='camera' className={classNames.iconClass} />
                    </div>
                </div>
            ) : (
                <div className={classNames.afterDrop}>
                    {imageUrl && (
                        <Image
                            src={`${SOURCE_API}${imageUrl}`}
                            imageFit={ImageFit.cover}
                            alt='Image dropzone'
                            width='100%'
                            height={240}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default connect(null, {uploadFile})(FileUpload);