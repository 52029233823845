import React, { useMemo } from 'react';
import { useQueryParams } from '../../utils/utils';
import { getProjects } from '../../redux/project/project.actions';
import { getPlans } from '../../redux/plan/plan.actions';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ProjectDetail } from './printPage/projects';
import { stationOptions } from '../../utils/constants';

const classNames = mergeStyleSets({
    spinner: {
		marginTop: 24,
  	},
    root: {
        margin: '0 48px 0 48px',
        minHeight: '100%',
        width: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    titleText: {
        margin: '47px 0px 34px 0px',
        fontSize: 18,
        fontWeight: 700
    },
    projectText: {
        marginBottom: 35,
        fontSize: 12,
        fontWeight: 700,
    }
});

const isAvailableProject = (plan, techArea, printStart, printEnd) => {
    for (let j = 0; j < plan?.machineRequirements.length; j++) {
        for (let k = 0; k < plan?.machineRequirements[j]?.machines.length; k++) {
            let planAreas = plan?.machineRequirements[j]?.machines[k].techAreas;
            let inactive = plan?.machineRequirements[j]?.machines[k].inactive;
            let startDate = new Date(plan?.machineRequirements[j]?.machines[k].start);
            let endDate = new Date(plan?.machineRequirements[j]?.machines[k].end);

            if(
                (startDate <= printStart && printEnd <= endDate) ||
                (printStart <= startDate && endDate <= printEnd) ||
                (startDate < printStart && printStart <= endDate && endDate <= printEnd) ||  
                (printStart <= startDate && startDate <= printEnd && printEnd < endDate)
            ) {
                if (!inactive && techArea.some(key => planAreas.replace('-1', '').includes(key))) {
                    return true;
                }
            }
        }
    }
    return false;
}

const renderMachineWorkers = (arr, techArea) => {
    const res = []
    arr.map(a => a.machines.map(b => (!b.inactive && techArea.some(key => b.techAreas.replace('-1', '').includes(key))) && res.push(b.machineName + ' # ' + b.machineNo)))
    return [...new Set(res)]
}

const renderProjectWorkers = (arr, techArea) => {
    const res = []

    arr.map(d => d.drivers.map(i => techArea.includes(i.techArea) && res.push(((i.firstName !== null ? i.firstName : '') + ' ' + (i.lastName !== null ? i.lastName : '')))))
    arr.map(w => w.workers.map(i => techArea.includes(i.techArea) && res.push(((i.firstName !== null ? i.firstName : '') + ' ' + (i.lastName !== null ? i.lastName : '')))))
    arr.map(m => m.managers.map(i => techArea.includes(i.techArea) && res.push(((i.firstName !== null ? i.firstName : '') + ' ' + (i.lastName !== null ? i.lastName : '')))))

    return [...new Set(res)]
}

const PrintProjects = () => {
    const dispatch = useDispatch()
    const queryParams = useQueryParams();
    const { data: projectData, isLoading: isLoadingProjects } = useQuery('printProjects', () => dispatch(getProjects()));
    const { data: planData, isLoading: isLoadingPlans } = useQuery('printPlans', () => dispatch(getPlans(new Date(queryParams.get('from')).getFullYear())));
    const techArea = queryParams.get('techArea') ? queryParams.get('techArea')?.split(',').map(Number) : []

    const projectRender = useMemo(() => {
        const printStart = new Date(queryParams.get('from'));
        const printEnd = new Date(queryParams.get('to'));
        const availableProjectsList = [];
        const finalRes = [];

        if (projectData && planData) {
            const availableProjects = new Set();

            for (let i = 0; i < planData.length; i++) {
                if (isAvailableProject(planData[i], techArea, printStart, printEnd)) {
                    availableProjects.add(planData[i].projectId);
                    availableProjectsList.push(planData[i])
                }
            }

            for(let i = 0; i < projectData.length; i++) {
                for(let j = 0; j < availableProjectsList.length; j++) {
                    if(availableProjectsList[j].projectId ===  projectData[i].projectId) {
                        finalRes.push({
                            ...projectData[i],
                            machines: renderMachineWorkers(availableProjectsList[j].machineRequirements, techArea),
                            people: renderProjectWorkers(availableProjectsList[j].machineRequirements, techArea)
                        })
                    }
                }
            }
        }
        return finalRes;
    }, [projectData, planData]);
    const techDepartment = parseInt(queryParams.get('techDepartment'))

    return (
        <div id='printProjects' style={{ background: '#F1F1F1'}}>
            {(isLoadingProjects || isLoadingPlans) ? null : (
                <div>
                    <div id="print-projects" className={classNames.root}>
                        <div className={classNames.titleText}>{stationOptions[techDepartment]['text']} uge {queryParams.get('fromWeek')} - {queryParams.get('toWeek')}</div>
                        <div className={classNames.projectText}>PROJEKTER</div>
                        {projectRender && projectRender.map((project) => (
                            !project.inactive ? <ProjectDetail key={project.projectId} project={project}/> : null
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
};

export default PrintProjects;