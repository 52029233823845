import React, { useState } from "react";
import { useQuery, useQueryClient } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { getMachine } from '../../redux/machines/machines.actions';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import { SOURCE_API } from '../../utils/constants';

const classNames = mergeStyleSets({
    container: {
        minHeight: "100vh",
        background: "#F1F1F1",
    },
    goBack: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "30px",
        height: "30px",
        backgroundColor: "#fff",
        borderRadius: "50%",
        selectors: {
            ":hover": {
                backgroundColor: "rgb(232, 232, 232)"
            }
        }
    },
    icon: {
        color: "#006CAD",
        fontSize: "24px"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        padding: "50px 124px 10px 118px",
    },
    pdfContainer: {
        margin: "0 auto",
        width: '80vw',
        height: '90vh',
        backgroundColor: '#e4e4e4',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const MachineDocument = ({getMachine}) => {
    const queryClient = useQueryClient();
    const { machineId } = useParams();
    const history = useHistory();
    const [displayDocument, setDisplayDocument] = useState(false);
    const { data: machineData } = useQuery(["machine", machineId], () => getMachine(machineId), { 
        initialData: () => queryClient.getQueryData("machines")?.find((machine) => machine.machineId === machineId),
        onSuccess: (machineData) => {
            if(machineData?.machineDocumentUrl) {
                setDisplayDocument(true);
            }
        }
    });
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <div className={classNames.container}>
            <div className={classNames.header}>
                <div className={classNames.goBack} onClick={() => history.goBack()}>
                    <FontAwesomeIcon className={classNames.icon} icon={faTimes}/>
                </div>
            </div>
            <div className={classNames.pdfContainer}>
                {displayDocument && machineData?.machineDocumentUrl?.length > 0 &&(
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <Viewer defaultScale={1} fileUrl={`${SOURCE_API}${machineData?.machineDocumentUrl[0]}`} plugins={[defaultLayoutPluginInstance]} />
                    </Worker>
                )}
            </div>
        </div>
    );
};

export default connect(null, {getMachine})(MachineDocument);
