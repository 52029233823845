import { useCallback, useEffect, useState } from "react";
import { lighterDarkerColor } from "../../../utils/utils";

type EColorCode = "staffVacation" | "machineServiceAllocation";

type TBarDrawerProps = {
  yPos: number;
  barHeight: number;
  onMouseDown: (val: { xPos: number }) => void;
  onMouseUp: (val: { startXPos: number; endXPos: number }) => void;
  containerClassName: string;
  colorCode: EColorCode;
  onCancel?: () => void;
  text?: string;
  calendarStepWidth: number;
};

const backgroundColors = {
  ["machineServiceAllocation" as EColorCode]: {
    text: "Machine service",
    textColor: "#575756",
    colorCode: "241,241,241",
    border: "1px solid #000",
  },
  ["staffVacation" as EColorCode]: {
    text: "Staff vacation",
    textColor: "#575756",
    colorCode: "241,241,241",
    border: "3px solid #fff",
  },
};

const CURSOR_HEIGHT = 15;

const BarDrawer = ({
  barHeight,
  onMouseDown,
  onMouseUp,
  yPos,
  colorCode,
  onCancel,
  containerClassName,
  text,
  calendarStepWidth,
}: TBarDrawerProps) => {
  const [startXPos, setStartXPos] = useState<number>(0);
  const [endXPos, setEndXPos] = useState<number>(0);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);

  const [containerElement, setContainerElement] = useState<HTMLElement>();

  const barData = backgroundColors[colorCode];

  const color = barData.colorCode;
  let bgColor = `repeating-linear-gradient(90deg, rgb(${color}), rgb(${color}) 24px, rgb(${lighterDarkerColor(
    colorCode,
    10
  )}) 24px, rgb(${lighterDarkerColor(colorCode, 10)}) 48px)`;

  useEffect(() => {
    const planContainer = document.getElementsByClassName(
      containerClassName
    )[0] as HTMLElement;

    if (planContainer) {
      planContainer.style.cursor = `url(${window.location.origin}/img/pencil-alt.svg), auto`;

      setContainerElement(planContainer);
    }

    return () => {
      if (planContainer) {
        planContainer.style.cursor = "default";
      }
    };
  }, []);

  const handleMouseDown = (event: MouseEvent) => {
    const targetDivClassName = containerClassName;

    // Get the target element of the event
    const targetElement = event.target as HTMLElement;

    // Check if the target element or any of its parents have the class name
    // let currentElement: HTMLElement | null = targetElement;

    // let isWithinDiv = false;

    // while (currentElement && isWithinDiv === false) {
    //   if (currentElement.classList.contains(targetDivClassName)) {
    //     isWithinDiv = true;
    //   } else {
    //     currentElement = currentElement.parentElement;
    //   }
    // }

    // if (!isWithinDiv || !currentElement) {
    //   onCancel && onCancel();
    //   return;
    // }

    const xPosition = event.pageX;
    const clickYPosition = event.pageY;

    let leftAdjust = 0;

    if (containerElement && containerElement.contains(targetElement)) {
      const { left, top: topContainerPosition } =
        containerElement.getBoundingClientRect();

      if (xPosition < left) {
        onCancel && onCancel();
        return;
      }

      if (
        clickYPosition < topContainerPosition ||
        !(
          clickYPosition - topContainerPosition >= yPos - CURSOR_HEIGHT &&
          clickYPosition - topContainerPosition <=
            yPos + barHeight - CURSOR_HEIGHT
        )
      ) {
        onCancel && onCancel();
        return;
      }

      leftAdjust = left;
    }

    const clickedXPos = xPosition - leftAdjust;

    onMouseDown({ xPos: clickedXPos });
    setStartXPos(clickedXPos);
    setIsMouseDown(true);
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isMouseDown) {
      return;
    }

    let leftAdjust = 0;

    if (containerElement) {
      leftAdjust = containerElement.getBoundingClientRect().left;
    }

    let xPosition = event.pageX;
    let endX = xPosition - leftAdjust;

    setEndXPos(endX);
  };

  const handleMouseUp = () => {
    if (endXPos < startXPos) {
      onCancel && onCancel();
      return;
    }

    setIsMouseDown(false);
    onMouseUp({ endXPos, startXPos });
    // onCancel && onCancel();
  };

  const handleEscClick = (event: KeyboardEvent) => {
    if (event.key === "Escape" || event.key === "Esc") {
      onCancel && onCancel();
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown, { passive: true });
    window.addEventListener("mousemove", handleMouseMove, { passive: true });
    window.addEventListener("mouseup", handleMouseUp, { passive: true });
    window.addEventListener("keydown", handleEscClick, { passive: true });
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("keydown", handleEscClick);
    };
  });

  const renderBar = useCallback(() => {
    if (startXPos !== endXPos || endXPos > startXPos) {
      let startOfBar = Math.floor(startXPos / calendarStepWidth);
      let endOfBar = Math.floor(endXPos / calendarStepWidth);

      return (
        <div
          style={{
            position: "absolute",
            width: (endOfBar - startOfBar) * calendarStepWidth,
            height: barHeight,
            background: "white",
            top: yPos,
            left: startOfBar * calendarStepWidth,
            border: barData.border,
            borderRadius: 8,
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#000",
              fontWeight: "bold",
              fontSize: 13,
            }}
          >
            {text}
          </div>
        </div>
      );
    }

    return <></>;
  }, [startXPos, endXPos, barData, barHeight, yPos, calendarStepWidth, text]);

  return renderBar();
};

export default BarDrawer;
