import axios from 'axios';
import { checkTokenValidity } from "../user/user.actions";
import setDefaults from '../../utils/setDefaults';
import { DefaultDataTypes } from './defaultData.type';
import { SOURCE_API } from '../../utils/constants';

export const getDefaultData = () => async dispatch =>  {
    try {
        setDefaults(localStorage.getItem('token'));

        const response = await axios.get(`${SOURCE_API}/api/Search/GetEnums`);
        if (!response.data.succeeded && response.data.message) {
            throw new Error(response.data.message);
        }

        dispatch({type: DefaultDataTypes.SET_DEFAULT_DATA, payload: response.data.data})

    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
};
