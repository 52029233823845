import React, { Fragment } from 'react';
import { Rnd } from 'react-rnd';
import { CALENDAR_STEP_WIDTH, CALENDAR_STAFFVIEW_STEP_HEIGHT, crmSpecialStatus, COLOR_CONS_HEX } from '../../../../utils/constants';
import { TooltipForText } from '../../../common';

const ProjectBar = ({ machine: { start, width}, plan : { color, crmProjectStatusCode, projectName, projectNo, personResponsbible, reason, isQuotation }, horizontalPositions, index, calendarStepWidth = CALENDAR_STEP_WIDTH }) => {
	let renderDetail = (
		<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%', height: '100%'}}>
			<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', width: '100%', paddingLeft: 5}}>
				<div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
					<span style={{color: '#fff', fontWeight: 'bold', fontSize: 11, marginRight: 8, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
						{crmSpecialStatus[crmProjectStatusCode] && <span style={{color: COLOR_CONS_HEX.ALERT, fontWeight: 'bold', fontSize: 11, marginRight: 8}}>{crmSpecialStatus[crmProjectStatusCode].text}</span>}
						{projectName}
						<span style={{color: '#fff', fontWeight: 400, fontSize: 11, marginLeft: 8}}>{projectNo}</span></span>
					<span style={{color: '#fff', fontWeight: 400, fontSize: 14, marginRight: 8, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{personResponsbible?.name ?? 'Unknown'}</span>
				</div>
			</div>
		</div>
	)
	if (width <= 11 * calendarStepWidth) {
		renderDetail = (
			<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%', height: '100%'}}>
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', width: '100%', paddingLeft: 5}}>
					<div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
						<span style={{color: '#fff', fontWeight: 'bold', fontSize: 11, marginRight: 8, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
							{crmSpecialStatus[crmProjectStatusCode] && <span style={{color: COLOR_CONS_HEX.ALERT, fontWeight: 'bold', fontSize: 11, marginRight: 8}}>{crmSpecialStatus[crmProjectStatusCode].text}</span>}
							{projectName}
							<span style={{color: '#fff', fontWeight: 400, fontSize: 11, marginLeft: 8}}>{projectNo}</span></span>
					</div>
				</div>
			</div>
		)
	}

	const projectBorder = (isAttention, isQuotation) => {
        let border = {borderRadius: 4, boxSizing: 'border-box'};
        if (isAttention && isQuotation) {
            border = {
                ...border,
                borderTop: '4px solid #CB033F',
                borderRight: '4px solid #CB033F',
                borderBottom: '4px solid #F28750',
                borderLeft: '4px solid #CB033F',
            }
        } else if (!isAttention && isQuotation) {
            border = {
                ...border,
                borderBottom: '4px solid #F28750',
            }
        } else if (isAttention && !isQuotation) {
            border = {
                ...border,
                border: '4px solid #CB033F',
            }
        }
        return border;
    }
	
	return (
		<Fragment>
			<Rnd
				style={{ zIndex: 3, top: 0, left: 0}}
				size={{ width: width, height: CALENDAR_STAFFVIEW_STEP_HEIGHT }}
				position={{ x: start, y: 0 }}
				enableResizing={false}
				disableDragging={true}
			>
				<div 
					style={{
						background: `rgba(${color},1)`,
						display: 'flex',
						height: CALENDAR_STAFFVIEW_STEP_HEIGHT - 8,
						borderRadius: 4,
						borderBottom: !!isQuotation ? '4px solid #F28750' : '',
                        ...projectBorder(reason, isQuotation),
						opacity: crmSpecialStatus[crmProjectStatusCode] ? 0.75 : 1
					}}
				>
					<TooltipForText text={`${projectName} ${projectNo}`}>
						{renderDetail}
					</TooltipForText>
				</div>
			</Rnd>
		</Fragment>
	)
};

export default ProjectBar;
