import React from 'react';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { MenuButton } from './';
import { Link } from "react-router-dom";

const classNames = mergeStyleSets({
    image: {
        margin: '40px 59px 50px 59px'
    },
    menuContainer: {
        background: 'white',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex', 
        flexDirection: 'column',
        height: '100vh',
        width: '218px',
    },
    link: {
        textDecoration: 'none',
    }
});

const SideMenu = () => {
    return (
        <div className={classNames.menuContainer}>
            <Image
                src='./img/Logo.svg'
                imageFit={ImageFit.contain}
                alt='Example of the image fit value "contain" on an image wider than the frame.'
                width={100}
                height={54}
                className={classNames.image}
            />
            <Link to="/" className={classNames.link}><MenuButton iconName="plan" label="PLANLÆG" /></Link>
            <Link to="/projects" className={classNames.link}><MenuButton iconName="project" label="PROJEKTER" /></Link>
            <Link to="/machines" className={classNames.link}><MenuButton iconName="machine" label="MASKINER" /></Link>
            <Link to="/staff" className={classNames.link}><MenuButton iconName="staff" label="BEMANDING" /></Link>
            <Link to="/settings" className={classNames.link}><MenuButton iconName="gear" label="SETTINGS" /></Link>
        </div>
    )
}

export default SideMenu;