import {
  faUserHardHat,
  faDigging,
  faUserTie,
  faLineColumns,
  faPencilRuler,
  faHardHat,
  faShuttleVan,
  faUserCrown,
  faUser,
  faThumbsUp,
  faExclamationCircle,
  faInfoCircle,
  faUserGear,
  faGear,
  faExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import API_SOURCE from "../config.json";
import { MessageBarType } from "@fluentui/react/";
import { SkillTypeEnum } from "../types/settings";
import { TTechDepartmentEnum } from "../types";
import { isProdUrl } from "./utils";
// import { app } from "@microsoft/teams-js";

// // In-situ:
// Ankermaskine
// Bil
// Boremaskine
// Boreudstyr
// Boreværktøj, Kelly
// Boreværktøj, VDW
// Div. Udlejningsgrej
// Geo Blandeanlæg
// Geo Test
// Geo Transport
// Kran
// Pæleboremaskine

// // Ramme:
// Hamre
// Ingeniørinstrumenter
// Kran
// Multimaskine Fundering
// Rammemaskine
// Skurvogn/Container
// Vibrator

// // GEO:
// CTP rig
// Diverse
// Geo Blandeanlæg
// Geo Borerig
/*
Section options
220: Vest
230: Geo
260: Øst
290: Udland
*/

// Whenever we want to reset the store on new deployment, increase currentStoreVersion and state.app.storeVersion by 0.1
export const STORE_VERSION = "1.0";

export const COLOR_CONS_HEX = {
  BLUE: "#006CAD",
  ALERT: "#F57C00",
  RED: "#A4272D",
};

export const techDepartmentOptions = [
  { key: 203, text: "Gvs", label: "Gvs" },
  { key: 204, text: "Geo", label: "Geo" },
  { key: 202, text: "Ramme", label: "Ramme" },
  { key: 201, text: "Insitu", label: "Insitu" },
  { key: 200, text: "DK Stabe", label: "DK Stabe" },
  { key: 205, text: "GE Stabe", label: "GE Stabe" },
  { key: 220, text: "Vest", label: "Vest" },
  { key: 230, text: "Øst1", label: "Øst1" },
  { key: 260, text: "Øst2", label: "Øst2" },
  { key: 270, text: "Øresund", label: "Øresund" },
  { key: 280, text: "Udland", label: "Udland" },
];

export const COLOR_GREY_OUT = "126,126,125";

export const COLOR_GREY_OUT_HEX = "#4c4c4bd9";

export const techDepartmentOptionsStaffPage = [
  ...techDepartmentOptions,
  { key: 999, text: "Andet", label: "Andet" },
];

export const workingRoleOptions = [
  {
    key: 0,
    text: "Formand",
    role: "SUPERVISOR",
    name: "managers",
    icon: faUserTie,
  }, // Supervisor
  {
    key: 1,
    text: "Maskinfører",
    role: "DRIVER",
    name: "drivers",
    icon: faUserHardHat,
  }, // Driver
  {
    key: 2,
    text: "Håndmand",
    role: "WORKER",
    name: "workers",
    icon: faDigging,
  }, // Worker
  { key: 3, text: "Disponent", role: "DISPONENT", name: "", icon: faUserCrown }, // Disponent
  { key: 4, text: "Funktionær", role: "FUNKTIONÆR", name: "", icon: faUser }, // Funktionær
  { key: 5, text: "Ekstern", role: "EKSTERN", name: "", icon: faUserGear }, // Ekstern
];

export const techAreaOptions = [
  { key: 0, text: "Geoteknik" },
  { key: 1, text: "GVS" },
  { key: 2, text: "Øst" },
  { key: 3, text: "Vest" },
  { key: 4, text: "Kelly" },
  { key: 5, text: "Anker" },
  { key: 6, text: "None" },
  { key: 7, text: "VDW" },
];

export const noneValue = {
  key: 999,
  text: "None",
  label: "None",
};

export const reasonVacationStaffOptions = [
  { key: 0, text: "Andet fravær", color: "245,124,0" }, // sick
  { key: 1, text: "Ferie", color: "0,108,173" }, // vacation
  { key: 2, text: "Udlån", color: "26, 147, 111" }, // other project
];

export const SIDE_MENU_LABEL = [
  { to: "/", label: "DISPONER", iconName: "plan", icon: faLineColumns },
  {
    to: "/projects",
    label: "PROJEKTER",
    iconName: "project",
    icon: faPencilRuler,
  },
  {
    to: "/machines",
    label: "MASKINER",
    iconName: "machineBlue",
    icon: faShuttleVan,
  },
  { to: "/staff", label: "BEMANDING", iconName: "staff", icon: faHardHat },
  { to: "/settings", label: "SETTINGS", iconName: "gear", icon: faGear },
];

// We don't show the disponent selection in machine form for GVS
// [203, 204]
export const ignoreStationOptionsList = [];

// @ts-expect-error
export const stationOptions: Record<
  Exclude<TTechDepartmentEnum, "-1">,
  {
    options: {
      key: number;
      value: string;
      text: string;
      label: string;
      shortcutText: string;
    }[];
    defaultValues: number[] | (typeof noneValue)[];
    text: string;
  }
> = {
  203: {
    options: [
      // {
      //   key: 0,
      //   value: "Geoteknik",
      //   text: "Geoteknik",
      //   label: "Geoteknik",
      //   shortcutText: "G",
      // },
      { key: 0, value: "GVS", text: "GVS", label: "GVS", shortcutText: "GVS" },
    ],
    defaultValues: [0, 1],
    text: "Gvs",
  },
  204: {
    options: [
      {
        key: 0,
        value: "Geo",
        text: "Geo",
        label: "Geo",
        shortcutText: "G",
      },
      // {
      //   key: 0,
      //   value: "Geoteknik",
      //   text: "Geoteknik",
      //   label: "Geoteknik",
      //   shortcutText: "G",
      // },
      // { key: 1, value: "GVS", text: "GVS", label: "GVS", shortcutText: "GVS" },
    ],
    defaultValues: [0, 1],
    text: "Geo",
  },
  202: {
    options: [
      { key: 2, value: "Øst", text: "Øst", label: "Øst", shortcutText: "Ø" },
      { key: 3, value: "Vest", text: "Vest", label: "Vest", shortcutText: "V" },
    ],
    defaultValues: [2, 3],
    text: "Ramme",
  },
  201: {
    options: [
      {
        key: 4,
        value: "Kelly",
        text: "Kelly",
        label: "Kelly",
        shortcutText: "K",
      },
      {
        key: 5,
        value: "Anker",
        text: "Anker",
        label: "Anker",
        shortcutText: "A",
      },
      { key: 7, value: "VDW", text: "VDW", label: "VDW", shortcutText: "V" },
    ],
    defaultValues: [4, 5],
    text: "Insitu",
  },
  200: {
    options: [
      { key: 6, value: "None", text: "None", label: "None", shortcutText: "N" },
    ],
    defaultValues: [noneValue],
    text: "DK Stabe",
  },
  205: {
    options: [
      { key: 6, value: "None", text: "None", label: "None", shortcutText: "N" },
    ],
    defaultValues: [noneValue],
    text: "GE Stabe",
  },
  220: {
    options: [
      { key: 6, value: "None", text: "None", label: "None", shortcutText: "N" },
    ],
    defaultValues: [noneValue],
    text: "Vest",
  },
  230: {
    options: [
      { key: 6, value: "None", text: "None", label: "None", shortcutText: "N" },
    ],
    defaultValues: [noneValue],
    text: "Øst1",
  },
  260: {
    options: [
      { key: 6, value: "None", text: "None", label: "None", shortcutText: "N" },
    ],
    defaultValues: [noneValue],
    text: "Øst2",
  },
  270: {
    options: [
      { key: 6, value: "None", text: "None", label: "None", shortcutText: "N" },
    ],
    defaultValues: [noneValue],
    text: "Øresund",
  },
  280: {
    options: [
      { key: 6, value: "None", text: "None", label: "None", shortcutText: "N" },
    ],
    defaultValues: [noneValue],
    text: "Udland",
  },
  999: {
    options: [
      { key: 6, value: "None", text: "None", label: "None", shortcutText: "N" },
    ],
    defaultValues: [noneValue],
    text: "Andet",
  },
};

export const DAYS_OF_WEEK = ["S", "M", "T", "W", "T", "F", "S"];

export const CALENDAR_PAGE = {
  DISPOSITION: "DISPOSITION",
  MACHINE_SERVICE: "MACHINE_SERVICE",
  STAFF: "STAFF",
  PRINT: "PRINT",
};

export const calendarConfirmationStatus = {
  CONFIRMATION_UNSET: 0,
  CONFIRMATION_DECISION_NEEDED: 1,
  CONFIRMATION_ACCEPT: 2,
  CONFIRMATION_CANCEL: 3,
};

export const REACT_APP_GOOGLE_MAPS_API_KEY = API_SOURCE.googleApiKey;

// Found unnecessary variable unsure about use case
// export const HOLIDAY_API_KEY = API_SOURCE.holidayApiKey;

export const months = [
  "JANUAR",
  "FEBRUAR",
  "MARTS",
  "APRIL",
  "MAJ",
  "JUNI",
  "JULI",
  "AUGUST",
  "SEPTEMBER",
  "OKTOBER",
  "NOVEMBER",
  "DECEMBER",
];

export const BACKGROUND_HEIGHT = 1500;

export const ROLES = {
  MACHINE: "MACHINE",
  WORKER: "WORKER", // HANDMAND
  DRIVER: "DRIVER", // MASKINFORER
  SUPERVISOR: "SUPERVISOR",
  FUNKTIONÆR: "FUNKTIONÆR",
};

export const CALENDAR_STEP_WIDTH = 25;
export const CALENDAR_WEEKVIEW_STEP_WIDTH = 10;

export const CALENDAR_STEP_HEIGHT = 56;
export const CALENDAR_STAFFVIEW_STEP_HEIGHT = 32;

const userAgent = window.navigator.userAgent.toLowerCase();
const isTeamsUserAgent = userAgent.includes("teamsmobile");

export const isInTeams = () => isTeamsUserAgent || window.parent !== window;

// const getEmbeddedBy = (): string => {
//   return window.location !== window.parent.location
//     ? document.referrer
//     : document.location.href;
// };

// const isEmbeddedByMsTeams = (): boolean => {
//   return (
//     window !== window.top &&
//     getEmbeddedBy().startsWith("https://teams.microsoft.com")
//   );
// };

// const isEmbeddedByMsTeamsNative = (): boolean => {
//   return (
//     navigator.userAgent.toLocaleLowerCase().includes("electron") &&
//     isEmbeddedByMsTeams()
//   );
// };

// export const SOURCE_API =
//   isEmbeddedByMsTeams() || isEmbeddedByMsTeamsNative()
//     ? "https://localhost:5001"
//     : window.location.href.includes("https://aarsleff.kodebaze.com")
//     ? API_SOURCE.apiURL
//     : API_SOURCE.devApiUrl;

export const SOURCE_API = [
  "https://aarsleff.kodebaze.com",
  "https://fleetplanner.aarsleff.com",
].some((el) => window.location.href.includes(el))
  ? API_SOURCE.apiURL
  : API_SOURCE.devApiUrl;

// window.location.href.includes(
//   "https://aarsleff.kodebaze.com"
// )

export const CONFIRM_PANEL_MODE = {
  NORMAL: {
    backgroundColor: "#fff",
    color: "#000",
  },
  DANGER: {
    backgroundColor: "#F28750",
    color: "#000",
    noBGColor: "#F28750",
    noColor: "#fff",
  },
  WARNING: {
    backgroundColor: "#006CAD",
    color: "#fff",
    noBGColor: "#006CAD",
    noColor: "#fff",
    confirmBGColor: "#fff",
    confirmColor: "#000",
  },
};

export const PRINTING_PAGE = {
  MACHINES: "MACHINES",
  SUPERVISOR: "SUPERVISOR",
  PROJECTS: "PROJECTS",
};

export const PRINTING_PAGE_OPTIONS = [
  { key: PRINTING_PAGE.MACHINES, text: "Print Machines Page" },
  { key: PRINTING_PAGE.SUPERVISOR, text: "Print Supervisors Page" },
  { key: PRINTING_PAGE.PROJECTS, text: "Print Projects Page" },
];

export const MACHINE_KEY_SELECT_PIVOT = {
  MACHINE_TABLE: "MACHINE_TABLE",
  MACHINE_CALENDAR: "MACHINE_CALENDAR",
  MACHINE_TABLE_TEAMS: "MACHINE_TABLE_TEAMS",
};

export const STAFF_KEY_SELECT_PIVOT = {
  STAFF_TABLE: "STAFF_TABLE",
  STAFF_MISSING_INFO: "STAFF_MISSING_INFO",
  STAFF_CALENDAR: "STAFF_CALENDAR",
};

export const ACTIVITY_LOG_ENUM = {
  PROJECT: 1,
  PLANNING: 2,
  BOOKING: 3,
  MACHINE: 4,
  MACHINE_TEAM: 5,
  USER: 6,
  MACHINE_REQ: 7,
};

export const ACTIVITY_LOG_TYPE_ENUM = {
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
};

export const colorList = [
  "0,95,174",
  "18,36,89",
  "182,225,242",
  "228,0,43",
  "101,84,47",
  "96,135,66",
  "95,107,109",
];

export const machineColorList = ["170,170,170", "220,220,220"];

export const ZOOM_CONTROL = [0.5, 0.67, 0.75, 0.8, 0.9, 1.0];

export const SEARCH_TYPE_ENUM = {
  EVERYTHING: 0,
  PLANNING: 1,
  MACHINE: 2,
  STAFF: 3,
  PROJECT: 4,
};

export const SKILL_TYPE_KEY: { [k: string]: SkillTypeEnum } = {
  UNDEFINED: 0,
  GENERAL: 1,
  MACHINE: 2,
};

export const SKILLS_QUERY_KEY = ["skills"];

export const SKILL_TYPE_OPTIONS = [
  { key: SKILL_TYPE_KEY.GENERAL, text: "General", label: "General" },
  { key: SKILL_TYPE_KEY.MACHINE, text: "Machine", label: "Machine" },
];

export const MESSAGE_INFO = {
  [MessageBarType.success]: {
    text: "Success!",
    icon: faThumbsUp,
    color: "#1A936F",
  },
  [MessageBarType.error]: {
    text: "Error!",
    icon: faExclamation,
    color: "#F57C00",
  },
  [MessageBarType.warning]: {
    text: "Warning!",
    icon: faExclamationCircle,
    color: "#e60000",
  },
  [MessageBarType.info]: {
    text: "Info!",
    icon: faInfoCircle,
    color: "#006CAD",
  },
};

export const crmSpecialStatus = {
  804240000: { text: "Lost" },
  804240003: { text: "Lost" },
};

export const STORAGE_EVT_KEY_UPDATE_PLAN = "STORAGE_EVT_KEY_UPDATE_PLAN";

// Undefined = -1,

// // Arbejdsmiljøuddannelse
// Uddannelse = 0,
// // Arbejdsmiljøkoordinator
// Koordinator = 1,
// // Kranfører
// Kranfører = 2,
// // Fører af gaffelstabler, gaffeltruck og teleskoplæsser
// Gaffelstabler_Teleskoplæsser = 3,
// // Opstille stilladser
// Stilladser = 4,
// // Arbejde med svejsning og termisk skæring samt den slibning, der er tilknyttet dette arbejde
// Svejsning_TermiskSkæring_Slibning = 5,
// // Arbejde med elevatorer
// Elevatorer = 6,
// // Arbejde med materialer med asbest
// AsbestMaterialer = 7,
// // Arbejde med asfaltmaterialer
// AsfaltMaterialer = 8,
// // Arbejde med epoxy og isocyanater
// Epoxy_Isocyanater = 9,
// // Arbejde med styren og polyesterstøbning
// Styren_PolyesterStøbning = 10,
// // Arbejde som kølemontør
// Kølemontør = 11,
// // Arbejde som kedelpasser
// Kedelpasser = 12,

export const ID_TARGET = {
  MACHINE: "MACHINE",
  WORKER: "WORKER",
  DRIVER: "DRIVER",
  SUPERVISOR: "SUPERVISOR",
  TEAMS: "TEAMS",
};

export const GENERAL_SKILLS = {
  Uddannelse: { key: 0, name: "Uddannelse" },
  Koordinator: { key: 1, name: "Koordinator" },
  Kranfører: { key: 2, name: "Kranfører" },
  Gaffelstabler_Teleskoplæsser: {
    key: 3,
    name: "Gaffelstabler Teleskoplæsser",
  },
  Stilladser: { key: 4, name: "Stilladser" },
  Svejsning_TermiskSkæring_Slibning: {
    key: 5,
    name: "Svejsning Termisk Skæring Slibning",
  },
  Elevatorer: { key: 6, name: "Svejsning Elevatorer" },
  AsbestMaterialer: { key: 7, name: "Asbest Materialer" },
  AsfaltMaterialer: { key: 8, name: "Asfalt Materialer" },
  Epoxy_Isocyanater: { key: 9, name: "Epoxy Isocyanater" },
  Styren_PolyesterStøbning: { key: 10, name: "Styren Polyester Støbning" },
  Kølemontør: { key: 11, name: "Kølemontør" },
  Kedelpasser: { key: 12, name: "Kedelpasser" },
};
export const PROJECT_CATEGORY = {
  Projekt: {
    key: 1,
  },
  Aftaleopgave: {
    key: 2,
  },
};

export const CRM_DK = "804240000";

export const RND_BAR_HEIGHT = 32;

export const COLOR_LIST: string[] = [
  "#2e8b57",
  "#800000",
  "#808000",
  "#800080",
  "#ff0000",
  "#ffa500",
  "#ffff00",
  "#0000cd",
  "#00ff00",
  "#00fa9a",
  "#4169e1",
  "#00ffff",
  "#00bfff",
  "#f08080",
  "#ff00ff",
  "#f0e68c",
  "#dda0dd",
  "#ff1493",
];
