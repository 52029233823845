import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TMachineService } from "../../../types";
import {
  PlanVerticalPositionMap,
  TPlanHorizontalPositionMap,
} from "../../../types/planner";
import {
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
} from "../../../utils/constants";
import { hasEditRight } from "../../../utils/utils";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Rnd } from "react-rnd";
import { TooltipForText } from "../../common";
import { getStringifiedMachine } from "../../../redux/machines/machine.selector";

// verticalPositions={verticalPositionMap}
// calendarStart={calendar.start}
// calendarEnd={calendar.end}
// calendarPage={CALENDAR_PAGE.DISPOSITION}
// machineY={machineContainer.y}
// type="machine"
// horizontalPositions={
// 	horizontalPositionMap
// }
// key={`${machine.machineId}-${machineIndex}-${machineRequirementIndex}`}
// calendarStepWidth={calendarStepWidth}
// machineService

type MachineServiceItemMiniProps = {
  verticalPositions: PlanVerticalPositionMap;
  horizontalPositions: TPlanHorizontalPositionMap;
  yPosition: number;
  calendarStart: Date;
  calendarEnd: Date;
  calendarStepWidth: number;
  machineService: TMachineService;
  // renderDependencies?: any
};

const MachineServiceItemMini = ({
  calendarEnd,
  calendarStart,
  // calendarStepWidth,
  horizontalPositions,
  machineService,
  verticalPositions,
  yPosition,
}: // renderDependencies : propRenderDependencies
MachineServiceItemMiniProps) => {
  const userRoles = useSelector(
    // @ts-ignore
    (state) => state?.user?.user?.workingRole
  ) as string;
  // let allowEdit = hasEditRight(userRoles) as boolean;

  // const calendarState = useSelector(getStringifiedMachine) as string;

  const {
    displayWeekView,
    // displayStaffOnPlanning
  } = useSelector(
    //   @ts-ignore
    (state) => state.machine
  );

  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;

  // const currentUserId = useSelector(
  //   //   @ts-ignore
  //   (state) => state?.user?.user?.userId
  // ) as string;

  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [width, setWidth] = useState<number>(250);
  const [display, setDisplay] = useState<boolean>(false);

  const colorCode = "241,241,241";
  let bgColor = `repeating-linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255) 24px, rgb(${colorCode}) 24px, rgb(${colorCode}) 48px)`;

  let calendarStepHeight = CALENDAR_STAFFVIEW_STEP_HEIGHT;

  //   if (calendarPage !== CALENDAR_PAGE.DISPOSITION && calendarPage !== CALENDAR_PAGE.PRINT) {
  //     calendarStepHeight = CALENDAR_STEP_HEIGHT;
  // }

  const classNames = mergeStyleSets({
    icon: {
      color: "#575756",
      marginRight: 2,
      fontSize: 13,
      fontWeight: "lighter",
      selectors: {
        ":hover": {
          color: "#DBDBDB",
        },
      },
      cursor: "pointer",
    },
    draggable: {
      position: "relative",
      top: "50%",
      transform: "translateY(-50%)",
    },
    barContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "calc(100% - 15px)",
      cursor: "pointer",
      //   height: "100%",
      //   cursor:
      //     allowEdit && calendarPage !== CALENDAR_PAGE.PRINT
      //       ? calendarPage === CALENDAR_PAGE.DISPOSITION
      //         ? `url(${window.location.origin}/img/trash-can.svg), auto`
      //         : `url(${window.location.origin}/img/arrows-alt.svg), auto`
      //       : "default",
    },
  });

  useEffect(() => {
    const { start, end } = machineService;

    //   let yPosition =
    //     typeof staff.index !== "undefined"
    //       ? verticalPositions[userId][staff.index]
    //       : verticalPositions[userId];

    const keyStart = moment(start).utcOffset(0).format("YYYY-M-D");
    const keyEnd = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");

    let xPosition = horizontalPositions[keyStart] as number;

    let endValue = horizontalPositions[keyEnd] as number;

    if (
      new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
      new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime() &&
      typeof xPosition === "undefined" &&
      typeof endValue === "undefined"
    ) {
      xPosition = 0;
      endValue = horizontalPositions["end"];
    }

    if (
      new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
      typeof endValue !== "undefined"
    ) {
      xPosition = 0;
    }

    if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
      endValue = horizontalPositions["end"];
    }

    setY(yPosition);

    if (typeof xPosition !== "undefined") {
      setX(xPosition * calendarStepWidth);
      setWidth((endValue - xPosition) * calendarStepWidth);
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [
    machineService,
    horizontalPositions,
    verticalPositions,
    calendarStart,
    calendarEnd,
    calendarStepWidth,
  ]);

  let text = machineService["comment"];

  if (machineService["reason"]) {
    text += ` (${machineService["reason"]})`;
  }

  let content = (
    <div
      className={`${
        // allowEdit &&
        // !viewOnly &&
        // !needConfirmation &&
        // calendarPage !== CALENDAR_PAGE.DISPOSITION
        //   ? "dragHandle"
        //   : "dragDisabled"
        "dragDisabled"
      } ${classNames.barContent}`}
      style={{ marginLeft: 5, justifyContent: "flex-start" }}
      //   onClick={
      //     calendarPage === CALENDAR_PAGE.DISPOSITION
      //       ? toggleTeachingStaffVacation
      //       : () => null
      //   }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
      >
        <TooltipForText text={text}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-end",
              marginTop: 1,
            }}
          >
            <span
              style={{
                color: "#575756",
                fontWeight: "bold",
                fontSize: 11,
                marginRight: 8,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {text}
            </span>
          </div>
        </TooltipForText>
      </div>
    </div>
  );

  return display && width > 0 ? (
    <>
      <Rnd
        style={{ zIndex: 3, top: 0, left: 0, display: "absolute" }}
        // default={{ x: x, y: y, width: width, height: 48}}
        size={{ width: width, height: calendarStepHeight - 8 }}
        position={{ x: x, y: y }}
        enableResizing={{
          left: false,
          right: false,
        }}
        resizeGrid={[calendarStepWidth, calendarStepHeight]}
        dragGrid={[calendarStepWidth, calendarStepHeight]}
        dragAxis="none"
        bounds={
          // isPlanningPage ? ".planContainer" : ".machineServiceContainer"
          ".planContainer"
        }
        dragHandleClassName="dragHandle"
        disableDragging
      >
        <div
          style={{
            backgroundImage: bgColor,
            backgroundSize: "48px 48px",
            backgroundPosition: "0 0, 0 0, -24px -24px, 24px 24px",
            display: "flex",
            marginTop: 5,
            height: "100%",
            border: "2px solid #868685",
            boxSizing: "border-box",
            borderRadius: 4,
          }}
        >
          {content}
        </div>
      </Rnd>
    </>
  ) : (
    <></>
  );
};

export default MachineServiceItemMini;
