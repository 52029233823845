import React from 'react';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler, faUserHardHat, faDigging, faUserTie } from '@fortawesome/pro-regular-svg-icons';

const classNames = mergeStyleSets({
    imageColumn: {
        display: 'flex',
        flexDirection: 'column',
        height: 282,
        width: 48,
        marginRight: 5
    },
    image: {
        height: 40,
        width: 40,
        align: 'center',
        background: '#ffffff',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 24px 16px 0',
    },
    lastImage: {
        marginRight: 24
    },
    icon: {
        fontSize: 20
    }
});

const ImageColumn = () => {
    return (
        <div className={classNames.imageColumn} >
            {/* project */}
            <div className={classNames.image}>
                <FontAwesomeIcon icon={faPencilRuler} className={classNames.icon}/>
            </div>
            {/* machine */}
            <div className={classNames.image}>
                <Image
                    src={`./img/machineBlack.svg`}
                    imageFit={ImageFit.contain}
                    alt="manager"
                    width={24}
                    height={24}
                />
            </div>
            {/* driver */}
            <div className={classNames.image}>
                <FontAwesomeIcon icon={faUserHardHat} className={classNames.icon}/>
            </div>
            {/* worker */}
            <div className={classNames.image}>
                <FontAwesomeIcon icon={faDigging} className={classNames.icon}/>
            </div>
            {/* supervisor */}
            <div className={classNames.image}>
                <FontAwesomeIcon icon={faUserTie} className={classNames.icon}/>
            </div>
        </div>
    )
}

export default ImageColumn;
