import { useEffect } from "react";
import versionJSON from "../data/version.json";

// We need typescript to know we are extending it
declare global {
  interface Window {
    AppLib: {
      version: number | string
    }
  }
}

const useCacheBuster = () => {
  // Fetch the meta.json in the public folder from the server since no cache set
  // Meta.json is autogenerated during build setting the version to server time
  // version.json is also updated during build setting the version to server time
  // version.json is the app's local version
  // meta.json is the latest app version
  useEffect(() => {
    fetch(`/meta.json?v=${+new Date()}`, { cache: "no-cache" })
      .then((response) => response.json())
      .then((meta) => {
        if (meta?.version) {
          const metaVersion = meta.version;

          if (versionJSON.cacheVersion < metaVersion) {
            if(window?.location?.reload){
              const asyncClearCache = async () => {
                if (caches) {
                  // Service worker cache should be cleared with caches.delete()
                  const keys = await caches.keys();
  
                  for (let name of keys) {
                    await caches.delete(name);
                  }
  
                }
                
                //@ts-ignore
                window.location.reload(true);
              };
  
              asyncClearCache();
            }
          }
          
          // I made a global variable so we can check the version of the app
          // in the console
          window.AppLib = {
            version: metaVersion,
          };
        }
      })
      .catch((error) => {
        console.error("something went wrong fetching meta.json", error);
      });
  }, []);

  return null;
};

export default useCacheBuster;
