import { mergeStyleSets } from '@fluentui/merge-styles';
import { Image } from '@fluentui/react';
import { faDigging, faUserHardHat, faUserTie } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const classNames = mergeStyleSets({
    tableColumn: {
        padding: '5px',
        fontWeight: 'bold',
        overflow: 'hidden',
    },
    titleElementStyle: {
        display: 'flex',
    },
    titleNameElementStyle: {
        fontWeight: 'bold',
        margin: '5px 0px 0px 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 180,
    },
    text: {
        padding: '0 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '85px',
        color: '#000',
    },
});

const icons = {
    driver: faUserHardHat,
    worker: faDigging,
    manager: faUserTie,
};

const PlanReview = ({ data }) => {
    const [planContent, setPlanContent] = useState({});

    const color = '223,223,223';

    useEffect(() => {
        const planContentTemp = {
            machine: {},
            worker: {},
            manager: {},
        };
        // Get all machines
        data.machines.forEach((machine) => {
            if (!planContentTemp.machine[machine.machineName]) planContentTemp.machine[machine.machineName] = { period: [], driver: {} };
            planContentTemp.machine[machine.machineName].period.push({
                start: machine.start,
                end: machine.end,
            });
        });
        // Get all staff
        const staffKey = ['workers', 'managers'];
        staffKey.forEach((key) => {
            data[key].forEach((staff) => {
                if (!planContentTemp[key.slice(0, -1)][`${staff.firstName} ${staff.lastName}`])
                    planContentTemp[key.slice(0, -1)][`${staff.firstName} ${staff.lastName}`] = [];
                planContentTemp[key.slice(0, -1)][`${staff.firstName} ${staff.lastName}`].push({
                    start: staff.start,
                    end: staff.end,
                });
            });
        });
        // Append driver to machine
        Object.keys(planContentTemp.machine).forEach((machineName) => {
            data.drivers.forEach((driver) => {
                if ((driver.machineName || driver.machineId) === machineName) {
                    if (!planContentTemp.machine[machineName].driver) planContentTemp.machine[machineName].driver = [];
                    const driverFullName = `${driver.firstName} ${driver.lastName}`;
                    if (!planContentTemp.machine[machineName].driver[driverFullName]) planContentTemp.machine[machineName].driver[driverFullName] = [];
                    planContentTemp.machine[machineName].driver[driverFullName].push({
                        name: driverFullName,
                        start: driver.start,
                        end: driver.end,
                    });
                }
            });
        });
        setPlanContent(planContentTemp);
    }, [data]);

    const itemBar = (key, start, end) => (
        <div key={key}
            style={{
                display: 'flex',
                marginBottom: 6,
                marginRight: 10,
                borderRadius: 8,
                background: `rgba(${color},1)`,
                visibility: 'hidden'
                // background : `repeating-linear-gradient(90deg, rgba(${color},1), rgba(${color},1) 24px, rgba(${color},0.7) 24px, rgba(${color},0.7) 48px)`,
            }}
        >
            <div style={{ color: '#fff', fontWeight: 'bold', height: '100%', width: '100%', fontSize: 12, lineHeight: '32px', margin: '0px 4px' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', color: '#fff' }}>
                        <div className={classNames.text}>
                            {start} - {end}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div style={{ backgroundColor: '#fff', borderRadius: 10, marginTop: 20 }}>
            <div style={{ height: 10 }} />
            <table style={{ borderCollapse: 'collapse', margin: '5px 0 20px 10px', overFlow: 'scroll', tableLayout: 'fixed' }}>
                {Object.keys(planContent).map((key) =>
                    Object.keys(planContent[key]).length
                        ? Object.keys(planContent[key]).map((name, mainIndex) =>
                            key === 'machine' ? (
                                <tbody key={`main-${name}${mainIndex}`}>
                                    <tr key={`${name}${mainIndex}`}>
                                        <td className={classNames.tableColumn} style={{ verticalAlign: 'top' }}>
                                            <div className={classNames.titleElementStyle}>
                                                <Image
                                                    style={{ marginTop: -5 }}
                                                    src={`${window.location.origin}/img/machineBlack.svg`}
                                                    alt='machine'
                                                    width={20}
                                                    height={32}
                                                    className={classNames.image}
                                                />
                                                <div className={classNames.titleNameElementStyle}>{name}</div>
                                            </div>
                                        </td>
                                        <td
                                            className={classNames.tableColumn}
                                            style={{ display: 'flex', flexWrap: 'wrap', width: '104%', textAlign: 'center' }}
                                        >
                                            {planContent[key][name]['period'].map((item, barIndex) =>
                                                itemBar(`bar-${name}-${barIndex}`, moment(item.start).format('DD.MM.YY'), moment(item.end).format('DD.MM.YY'))
                                            )}
                                        </td>
                                    </tr>
                                    {Object.keys(planContent[key][name].driver).map((driverName, index) => (
                                        <tr key={`${driverName}${index}`}>
                                            <td className={classNames.tableColumn} style={{ verticalAlign: 'top' }}>
                                                <div className={classNames.titleElementStyle} style={{ marginLeft: 30 }}>
                                                    <FontAwesomeIcon icon={icons['driver']} style={{ fontSize: 19, marginTop: 5, width: '1.125em' }} />
                                                    <div className={classNames.titleNameElementStyle}>{driverName}</div>
                                                </div>
                                            </td>
                                            <td
                                                className={classNames.tableColumn}
                                                style={{ display: 'flex', flexWrap: 'wrap', width: '104%', textAlign: 'center' }}
                                            >
                                                {planContent[key][name]['driver'][driverName].map((item, barIndex) =>
                                                    itemBar(`bar-${name}-${barIndex}`, moment(item.start).format('DD.MM.YY'), moment(item.end).format('DD.MM.YY'))
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tr key={`${name}${mainIndex}`}>
                                    <td className={classNames.tableColumn} style={{ verticalAlign: 'top' }}>
                                        <div className={classNames.titleElementStyle}>
                                            <FontAwesomeIcon icon={icons[key]} style={{ fontSize: 19, marginTop: 5, width: '1.125em' }} />
                                            <div className={classNames.titleNameElementStyle}>{name}</div>
                                        </div>
                                    </td>
                                    <td
                                        className={classNames.tableColumn}
                                        style={{ display: 'flex', flexWrap: 'wrap', width: '104%', textAlign: 'center' }}
                                    >
                                        {planContent[key][name].map((item, barIndex) =>
                                            itemBar(`bar-${name}-${barIndex}`, moment(item.start).format('DD.MM.YY'), moment(item.end).format('DD.MM.YY'))
                                        )}
                                    </td>
                                </tr>
                            )
                        )
                    : null
                )}
            </table>
        </div>
    );
};

export default PlanReview;
