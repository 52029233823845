// Import libraries
import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Label } from "@fluentui/react/lib/Label";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import * as Yup from "yup";
import {
  IconButton,
  PrimaryButton,
  Stack,
  Spinner,
  SpinnerSize,
  // ActionButton,
  // Checkbox,
} from "@fluentui/react/";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { connect } from "react-redux";

// Import redux
import { getMachines } from "../../redux/machines/machines.actions";
import { getStaff, updateStaff } from "../../redux/user/user.actions";

// Import hooks
import { useUrlQuery } from "../../hooks";

// Import utils
import {
  COLOR_CONS_HEX,
  SKILLS_QUERY_KEY,
  // techAreaOptions,
  techDepartmentOptions,
} from "../../utils/constants";
import { getUserOrbitId, hasEditRight } from "../../utils/utils";

// Import components
import MachineSkillTable from "./staff/MachineSkillTable";
import GeneralSkillTable from "./staff/GeneralSkillTable";
import { saveMessage } from "../../redux/message/message.actions";
import {
  getGeneralSkills,
  getMachineSkills,
  getSkills,
} from "../../redux/skill/skill.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPlus, faUser } from "@fortawesome/pro-regular-svg-icons";
import { faUser as faSolidUser } from "@fortawesome/pro-solid-svg-icons";
import EditStaff from "./EditStaff";
import StaffForm from "./staff/StaffForm";
import { Formik } from "formik";
import { TooltipForText } from "../common";

const classNames = mergeStyleSets({
  icon: {
    fontSize: 22,
    color: "rgb(0, 108, 173)",
    cursor: "pointer",
    marginRight: 12,
  },
  container: {
    minHeight: "100vh",
    background: "#F1F1F1",
  },
  label: {
    marginLeft: 50,
    fontSize: 29,
    lineHeight: 29,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "136px 124px 116px 118px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
  },
  input: {
    marginBottom: 24,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    minWidth: "600px",
    justifyContent: "space-between",
    borderBottom: "solid 1px #DBDBDB",
  },
  colorPicker: {
    backgroundColor: "#ffffff !important",
  },
  machineItem: {
    padding: 15,
    margin: 0,
    width: 150,
  },
  selectAll: {
    fontSize: 14,
    fontWeight: 400,
    cursor: "pointer",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 52,
  },
  tabDefault: {
    borderBottom: "2px solid #DFDFDF",
  },
  tabActive: {
    borderBottom: "4px solid #006CAD",
  },
  tab: {
    fontSize: 13,
    fontWeight: 700,
    width: 140,
    height: 35,
    textAlign: "center",
    margin: "0 10px",
    cursor: "pointer",
    paddingTop: 10,
    selectors: {
      ":hover": {
        backgroundColor: "#99b3ff",
      },
    },
  },
});

const GENERAL = "general";
const MASKINE = "maskine";
const USER = "user";
const SKILL_QUERY_KEY = "skill";

// @ts-ignore
const MapMachines = ({ getMachines, getStaff, updateStaff }) => {
  const history = useHistory();
  // @ts-ignore
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);

  const [staffData, setStaffData] = useState(null);
  const [generalSkills, setGeneralSkills] = useState([]);
  const [machineSkills, setMachineSkills] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(null);

  const dispatch = useDispatch();
  let query = useUrlQuery().get(SKILL_QUERY_KEY);

  const [tabView, setTabView] = useState(
    query === GENERAL ? GENERAL : query === USER ? USER : MASKINE
  );

  const [openUser, { toggle: toggleOpenUser }] = useBoolean(false);

  const { staffId } = useParams();

  const {
    data: skillsData,
    // isFetching: isLoadingGetSkills,
    // refetch: refetchSkills,
  } = useQuery(SKILLS_QUERY_KEY, () => getSkills(dispatch), {
    initialData: [],
  });

  const {
    data: generalSkillsData,
    // isFetching: isFetchingGeneralSkills
  } = useQuery(["skills", "general"], () => getGeneralSkills(dispatch));

  const {
    data: machineSkillsData,
    // isFetching: isFetchingMachineSkills
  } = useQuery(["skills", "machines"], () => getMachineSkills(dispatch));

  const { data: userData, isLoading: isLoadingUser } = useQuery(
    ["staff", staffId],
    () => getStaff([staffId]),
    {
      onSuccess: (data) => {
        if (data && Array.isArray(data) && data[0]) {
          setStaffData(data[0]);
          setGeneralSkills(data[0]?.generalSkills || []);
          setMachineSkills(data[0]?.machineSkills || []);
        }
        setFetchMachine(true);
      },
    }
  );

  const [fetchMachine, setFetchMachine] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { data: machinesData } = useQuery("machines", getMachines, {
    enabled: fetchMachine,
    onSuccess: (data) => {
      const currentDepartmenMachines = data.filter(
        (machine) =>
          !machine.inactive &&
          machine.techDepartments.includes(userData[0].techDepartment)
      );
      const otherDepartmentMachines = data
        .filter(
          (machine) =>
            !machine.inactive &&
            !machine.techDepartments.includes(userData[0].techDepartment)
        )
        .sort((a, b) =>
          techDepartmentOptions
            .find((item) => a.techDepartments.includes(item.key))
            .text.localeCompare(
              techDepartmentOptions.find((item) =>
                b.techDepartments.includes(item.key)
              ).text
            )
        );
      // const sortedMachines = [
      //   ...currentDepartmenMachines,
      //   ...otherDepartmentMachines,
      // ];

      // if (allowEdit) {
      //   if (userData[0]?.machineFavorites) {
      //     setMachines(
      //       sortedMachines.map((machine) => ({
      //         ...machine,
      //         checked: userData[0]?.machineFavorites.some(
      //           ({ machineId }) => machineId === machine.machineId
      //         ),
      //       }))
      //     );
      //     if (userData[0]?.machineFavorites?.length === sortedMachines.length)
      //       setHasSelectAll(true);
      //   } else
      //     setMachines(
      //       sortedMachines.map((machine) => ({ ...machine, checked: false }))
      //     );
      // } else {
      //   const machineFavorites = sortedMachines
      //     .map((machine) => ({
      //       ...machine,
      //       checked: userData[0]?.machineFavorites?.some(
      //         ({ machineId }) => machineId === machine.machineId
      //       ),
      //     }))
      //     .filter((machine) => machine.checked);
      //   setMachines(machineFavorites);
      // }

      // setMachines(sortedMachines);
      setIsLoading(false);
    },
  });

  // const [machines, setMachines] = useState([]);
  // const [hasSelectAll, setHasSelectAll] = useState(false);
  const queryClient = useQueryClient();
  const { mutate: mutateUpdateStaff, isLoading: isMutateUpdateStaffLoading } =
    useMutation((staff) => updateStaff(staff), {
      onSettled: () => {
        queryClient.invalidateQueries("staffs");
        queryClient.invalidateQueries(["staff", staffId]);
        // history.push("/staff");
      },
    });

  useEffect(() => {
    return () => {
      setIsEditing(false);
    };
  }, []);

  // const handleToggleSelectAll = () => {
  //   const currentSelect = !hasSelectAll;
  //   if (currentSelect) {
  //     setMachines((prevState) =>
  //       prevState.map((machine) => ({ ...machine, checked: true }))
  //     );
  //   } else {
  //     setMachines((prevState) =>
  //       prevState.map((machine) => ({ ...machine, checked: false }))
  //     );
  //   }
  //   setHasSelectAll(currentSelect);
  // };

  // const handleCheck = (index) => {
  //   const machinesTemp = [...machines];
  //   machinesTemp[index].checked = !machinesTemp[index].checked;
  //   setMachines(machinesTemp);
  // };

  const handleSubmit = () => {
    if (isEditing) {
      dispatch(
        saveMessage("You must complete the editing process before saving")
      );
    } else {
      const staff = { ...userData[0] };

      staff.generalSkills = generalSkills;

      staff.machineSkills = machineSkills;

      mutateUpdateStaff(staff);

      // const machineFavorites = machines
      //   .filter((machine) => !!machine.checked)
      //   .map(({ machineId, machineName }) => ({ machineId, machineName }));
      // const staff = { ...userData[0], machineFavorites };
    }
  };

  const onUpdateStaffGeneralSkills = (updatedGeneralSkills) => {
    setGeneralSkills(updatedGeneralSkills);

    const staff = { ...userData[0] };

    staff.generalSkills = updatedGeneralSkills;

    staff.machineSkills = machineSkills;

    mutateUpdateStaff(staff);
  };

  const onUpdateStaffMachineSkills = (updatedMachineSkills) => {
    setMachineSkills(updatedMachineSkills);

    const staff = { ...userData[0] };

    staff.generalSkills = generalSkills;

    staff.machineSkills = updatedMachineSkills;

    mutateUpdateStaff(staff);
  };

  const validationSchema = Yup.object({
    imageUrl: Yup.string("Upload image").nullable(),
    firstName: Yup.string("Enter a first name").required(
      "First name is required"
    ),
    lastName: Yup.string("Enter a last name")
      .required("Last name is required")
      .nullable(),
    email: Yup.string("Enter a email")
      .required("Email is required")
      .email("Invalid email"),
    techDepartment: Yup.string("Please select Sektion").required(
      "Department is required"
    ),
    workingRole: Yup.string("Please select Rolle").required(
      "Working Role is required"
    ),
    techArea: Yup.string("Please select Disponent").required(
      "Disponent is required"
    ),
  });

  const mutationUpdateStaff = useMutation((staff) => updateStaff(staff), {
    onSuccess: (data, variables) => {
      if (data) {
        queryClient.invalidateQueries("staffs");
        queryClient.invalidateQueries(["staff", staffId]);
        queryClient.invalidateQueries("usersMissingInfo");
        // history.push("/staff");
      }
    },
  });

  const formSubmit = (newData) => {
    const staffData = {
      ...userData[0],
      userId: newData.userId,
      imageUrl: newData.imageUrl,
      firstName: newData.firstName,
      lastName: newData.lastName,
      techDepartment: newData.techDepartment,
      techArea: newData.techArea,
      email: newData.email,
      password: newData.password,
      workingRole: newData.workingRole,
      authorizeRole: 0,
    };

    mutationUpdateStaff.mutate(staffData);
  };

  const tempSetData = useCallback(
    (formData) => {
      setFormData(formData);
    },
    [setFormData]
  );

  const renderUserForm = useCallback(() => {
    return (
      <>
        {userData?.length && !isLoadingUser && openUser ? (
          <div
            style={{
              marginLeft: 40,
            }}
          >
            <Label
              className={classNames.label}
              style={{
                fontSize: 20,
                fontWeight: "normal",
                marginBottom: 10,
                // textAlign: "center",
              }}
            >
              {/* {`${userData[0]?.firstName} ${userData[0]?.lastName}`} */}
              General Information
            </Label>

            <Formik
              initialValues={{ ...userData[0] }}
              validationSchema={validationSchema}
              onSubmit={formSubmit}
              // innerRef={innerRef}
            >
              {(props) => (
                <StaffForm
                  hideHeader={true}
                  history={history}
                  editOnly={true}
                  {...props}
                  setFormData={tempSetData}
                />
              )}
            </Formik>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }, [userData, isLoadingUser, openUser]);

  return (
    <div className={classNames.container}>
      <div className={classNames.header}>
        <IconButton
          iconProps={{ iconName: "ChromeBack" }}
          onClick={() => history.goBack()}
        />
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Label className={classNames.label}>
            {!isLoadingUser &&
              `${allowEdit ? "Tilføj" : "Se"} Maskine til ${
                userData[0].firstName
              } ${userData[0].lastName}`}
          </Label>

          <FontAwesomeIcon
            icon={openUser ? faSolidUser : faUser}
            // className={classNames.icon}
            style={{
              color: "rgb(0, 108, 173)",
              cursor: "pointer",
              marginLeft: 12,
            }}
            onClick={() => {
              toggleOpenUser();
            }}
          />
          {userData &&
          userData[0] &&
          getUserOrbitId({
            aadCompanyNo: userData[0].aadCompanyNo,
            aadEmployeeNo: userData[0].aadEmployeeNo,
            aadKoncernNo: userData[0].aadKoncernNo,
          }) ? (
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={`https://kompetencer.aarsleff.com/profile/external-id/${getUserOrbitId(
                {
                  aadCompanyNo: userData[0].aadCompanyNo,
                  aadEmployeeNo: userData[0].aadEmployeeNo,
                  aadKoncernNo: userData[0].aadKoncernNo,
                }
              )}`}
            >
              <TooltipForText text={"Orbit Link"}>
                <FontAwesomeIcon
                  icon={faLink}
                  style={{
                    color: COLOR_CONS_HEX.BLUE,
                  }}
                />
              </TooltipForText>
            </a>
          ) : (
            <></>
          )}
        </div>
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          {/* <DefaultButton text="OPRET NY MASKINE" onClick={() => {}} /> */}
          {openUser && formData?.handleSubmit && (
            <PrimaryButton
              text="Gem"
              disabled={!formData?.isValid || isMutateUpdateStaffLoading}
              onClick={formData?.handleSubmit}
            />
          )}
        </Stack>
      </div>
      {/* isLoading || isMutateUpdateStaffLoading */}
      {isLoading ? (
        <Spinner
          className={classNames.spinner}
          size={SpinnerSize.large}
          label="Loading in..."
          labelPosition="right"
        />
      ) : (
        // Tab at the top Maskine skills and General Skills
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          {renderUserForm()}
          <div className={classNames.content}>
            <div className={classNames.tabContainer}>
              <Pivot
                onLinkClick={(item) => {
                  const key = item.props.itemKey;
                  if (key) {
                    if (!isEditing) {
                      if ([MASKINE, GENERAL, USER].includes(key)) {
                        setTabView(key);
                      }
                    } else {
                      dispatch(
                        saveMessage(
                          "Pleash finish editing the current skill before changing tabs"
                        )
                      );
                    }
                  }
                }}
                selectedKey={tabView}
              >
                {/* <PivotItem headerText={"USER"} itemKey={USER} /> */}
                <PivotItem
                  headerText={"MACHINE CERTIFICATE"}
                  itemKey={MASKINE}
                />
                <PivotItem headerText={"SKILLS"} itemKey={GENERAL} />
              </Pivot>
            </div>
            <Stack
              style={{ marginBottom: "50px" }}
              horizontal
              horizontalAlign="center"
            >
              {/* We need the staff Data */}
              {tabView === MASKINE && staffData ? (
                <MachineSkillTable
                  machines={machinesData}
                  onUpdateStaffMachineSkills={onUpdateStaffMachineSkills}
                  staffMachineSkills={machineSkills}
                  availableMachineSkills={machineSkillsData || []}
                  setIsEditing={(isEditing) => {
                    setIsEditing(isEditing);
                  }}
                  canEdit={allowEdit}
                  staffGeneralSkills={generalSkills}
                  allSkills={skillsData || []}
                  staffData={staffData}
                />
              ) : (
                <></>
              )}
              {tabView === GENERAL && staffData ? (
                <GeneralSkillTable
                  onUpdateStaffGeneralSkills={onUpdateStaffGeneralSkills}
                  generalSkills={generalSkills}
                  setIsEditing={(isEditing) => {
                    setIsEditing(isEditing);
                  }}
                  canEdit={allowEdit}
                  availableGeneralSkills={[
                    ...(generalSkillsData || []),
                    ...(machineSkillsData || []),
                  ]}
                  staffData={staffData}
                />
              ) : (
                <></>
              )}
              {tabView === USER && staffData ? <></> : <></>}
            </Stack>
            {/* <Stack style={{marginBottom: "50px"}} horizontal horizontalAlign="center">
                        {status === "success" && machinesData && machinesData?.length > 0 && (
                            <Stack
                                vertical
                                verticalAlign="center"
                                tokens={customSpacingStackTokens}
                            >
                                {allowEdit &&
                                    <div className={classNames.selectAll}>
                                        <ActionButton style={{color: "#00588F"}} onClick={handleToggleSelectAll}>{hasSelectAll ? 'Deselect All' : 'Select All'}</ActionButton>
                                    </div>                        
                                }
                                {!machines?.length && <div>No machine assigned</div>}
                                {machines.map((machine, index) => (
                                    <div key={index} className={classNames.row}>
                                        <div key={`${machine.machineId}-${index}`} className={classNames.machineItem} style={{width: '50%'}}>
                                            <Checkbox styles={{checkbox: {border: '2px solid #006CAD'}}} label={machine.machineName} disabled={!allowEdit} onChange={() => {handleCheck(index)}} checked={machine.checked}/>
                                        </div>
                                        <div key={`${machine.techAreas}-${index}`} className={classNames.machineItem}>
                                            {machine.techAreas !== '-1'
                                                ? machine.techAreas
                                                        .split(';')
                                                        .filter((item) => item !== '-1')
                                                        .map((key) => techAreaOptions.find((techArea) => techArea.key === parseInt(key))?.text)
                                                        .join(', ')
                                                : 'UNKNOWN'
                                            }
                                        </div>
                                        <div key={`${machine.techDepartments}-${index}`} className={classNames.machineItem}>
                                            {
                                                machine.techDepartments !== '-1'
                                                ? machine.techDepartments
                                                        .split(';')
                                                        .filter((item) => item !== '-1')
                                                        .map((key) => techDepartmentOptions.find((item) => item.key === parseInt(key)).text)
                                                        .join(', ')
                                                : 'UNKNOWN'
                                            }
                                        </div>
                                    </div>
                                ))}
                            </Stack>
                        )}  
                    </Stack> */}
          </div>
        </Stack>
      )}
    </div>
  );
};

export default connect(null, { getMachines, getStaff, updateStaff })(
  MapMachines
);
