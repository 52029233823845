import React, { Fragment } from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useBoolean } from "@fluentui/react-hooks";
import { DeleteConfirmation } from '../../common';

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        fontSize: 22,
        color: 'rgb(0, 108, 173)',
        cursor: 'pointer',
        marginRight: 12,
    },
});

const MachineRequirementsControls = ({ index, edit, remove, isCrmReq }) => {
    const [deleteConfirmation, { toggle: toggleDeleteConfirmation }] = useBoolean(false);

    const onClickConfirm = () => {
        toggleDeleteConfirmation()
        remove(index)
    };

    return (
        <Fragment>
            <div className={classNames.container}>
                <FontAwesomeIcon icon={faEdit} className={classNames.icon} onClick={() => edit(index)} />
                {!isCrmReq && <FontAwesomeIcon id={`delete-${index}`} icon={faTrashAlt} className={classNames.icon} onClick={toggleDeleteConfirmation}/>}
            </div>
            {deleteConfirmation && (
                <DeleteConfirmation target={`#delete-${index}`} toggleTeaching={toggleDeleteConfirmation} onClickConfirm={onClickConfirm}/>
            )}
        </Fragment>
    )
}

export default MachineRequirementsControls;