import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDigging } from '@fortawesome/pro-regular-svg-icons';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';

const classNames = mergeStyleSets({
    container: {
        height: 20,
        width: 20,
        position: 'relative',
        marginRight: 15,
        cursor: 'pointer'
    },
    badge: {
        background: '#006CAD',
        color: '#ffffff',
        fontSize: 11,
        textAlign: 'center',
        lineHeight: 20,
        height: 20,
        width: 20,
        position: 'absolute',
        top: -8,
        right: -14,
        borderRadius: '50%',
    },
    icon: {
        fontSize: 24,
        color: 'rgb(0, 108, 173)',
        cursor: 'pointer',
        marginRight: 12
    },
});

const WorkerIconWithBadge = ({redirect, count = 0, onClick = () => {}}) => {
    return (
        <div className={classNames.container} onClick={onClick}>
            <FontAwesomeIcon onClick={redirect} icon={faDigging} className={classNames.icon}/>
            { count > 0 &&   
                <div className={classNames.badge}>{count}</div>
            }
        </div>
    )
}

export default WorkerIconWithBadge;
