import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/da';
import { Rnd } from 'react-rnd';
import { CALENDAR_STEP_WIDTH } from '../../../../utils/constants';

const MachineRequirementBar = ({ horizontalPositionMap, start, end, color, calendarStepWidth = CALENDAR_STEP_WIDTH, }) => {
    const [x, setX] = useState(0);
    const [width, setWidth] = useState(0);
    
    useEffect(() => {
        if (!start || !end) {
            return;
        }
        let startDate = moment(start, "YYYY-M-D").format('YYYY-M-D');
        let endDate = moment(end).add(1, 'd').utcOffset(0).format('YYYY-M-D');
        const startCalendar = horizontalPositionMap.startText.split('.').reverse();
        const endCalendar = horizontalPositionMap.endText.split('.').reverse();
        let startTemp = new Date(startDate) < new Date('20' + startCalendar[0], startCalendar[1]-1, startCalendar[2]) ? 0 : horizontalPositionMap[startDate];
        let endTemp = (new Date(endDate)).getTime() >= (new Date('20' + endCalendar[0], endCalendar[1]-1, parseInt(endCalendar[2]) + 1)).getTime() ? (horizontalPositionMap[endDate] || (horizontalPositionMap.end + 1)) : horizontalPositionMap[endDate];
        let tempX = startTemp * calendarStepWidth;
        let tempWidth = (endTemp - startTemp) * calendarStepWidth;
        if(typeof startTemp === 'undefined' || typeof endTemp === 'undefined') {
            return;
        }
        if(!startTemp && endTemp) {
            tempX = 0;
            tempWidth = endTemp * calendarStepWidth;
        }
        if(startTemp && !endTemp)  {
            tempWidth = (horizontalPositionMap['end'] - startTemp) * calendarStepWidth;
        }
        setX(tempX);
        setWidth(tempWidth)
    }, [end, start, horizontalPositionMap, calendarStepWidth]);


    return (
        <Rnd
            style={{ zIndex: 3, top: 0, left: 0}}
            size={{ width: width, height: 20 }}
            position={{ x: x, y: -8 }}
            enableResizing={false}
            disableDragging={true}
        >
            <div style={{backgroundColor: color ? `rgba(${color}` : '#fff', display: 'flex', height: 8, borderRadius: 25}}></div>
        </Rnd>
    )
}

export default MachineRequirementBar;