import { PrimaryButton, TeachingBubble } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { MessageBarType, TextField } from "@fluentui/react/";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import {
  faEdit,
  faEllipsisH,
  faEye,
  faInfoCircle,
  faTriangleExclamation,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment/locale/da";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { connect, useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { useHistory } from "react-router-dom";
import { setCurrentUnplannedItem } from "../../../../redux/app/app.actions";
import {
  saveMessage,
  showConflictMessage,
} from "../../../../redux/message/message.actions";
import {
  createPlan,
  updatePlan,
  setPlanOverlayProjectId,
} from "../../../../redux/plan/plan.actions";
import {
  calendarConfirmationStatus,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  COLOR_CONS_HEX,
  crmSpecialStatus,
  reasonVacationStaffOptions,
} from "../../../../utils/constants";
import {
  checkTimeOverlap,
  findStaffSchedule,
  hasEditRight,
  lighterDarkerColor,
  projectBorder,
} from "../../../../utils/utils";
import { DeleteConfirmation, TooltipForText } from "../../../common";
import ConfirmBubble from "../../../common/ConfirmBubble";
import { planActivityLogComposer } from "../../PlanningUtils";

import { inputStyles, primaryButtonStyles } from "../../../../utils/theme";
import { getStringifiedMachine } from "../../../../redux/machines/machine.selector";
import { getCrewServiceSchedule } from "../../../../utils/service";

const classNames = mergeStyleSets({
  icon: {
    color: "#fff",
    marginRight: 2,
    fontSize: 13,
    fontWeight: "lighter",
    selectors: {
      ":hover": {
        color: "#DBDBDB",
      },
    },
    cursor: "pointer",
  },
  iconTooltip: {
    color: "#006CAD",
    marginRight: 2,
    fontSize: 13,
    fontWeight: "lighter",
    selectors: {
      ":hover": {
        color: "#000080",
      },
    },
    cursor: "pointer",
  },
});

const Project = ({
  id,
  plan,
  machine,
  machineTeamList,
  machineId,
  machineRequirementIndex,
  machineIndex,
  start,
  end,
  horizontalPositions,
  verticalPositions,
  calendarStart,
  calendarEnd,
  // createPlan,
  // updatePlan,
  setPlanOverlayProjectId,
  saveMessage,
  handleModal,
  isUpdated,
  allPlansData,
  hidden = false,
  optimisticUpdate,
  onFailed,
  activities,
  renderDependencies: propRenderDependencies,
  createPlanMutation,
  updateMachineRequirementMutation,
  machines = [],
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [
    deleteProjectConfirmation,
    { toggle: toggleDeleteProjectConfirmation },
  ] = useBoolean(false);
  const { openDarkOverlay } = useSelector((state) => state.app);
  const { currentUnplannedItem } = useSelector((state) => state.app);
  // const calendarState = JSON.stringify(useSelector((state) => state.machine));
  const calendarState = useSelector(getStringifiedMachine);

  const softReloadPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000); 
  };
  const displayWeekView = useSelector((state) => state.machine.displayWeekView);
  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;
  const currentUserId = useSelector((state) => state?.user?.user?.userId);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [reason, setReason] = useState(
    plan?.machineRequirements[machineRequirementIndex].attentionReason || ""
  );
  const [confirmation, setConfirmation] = useState(
    calendarConfirmationStatus.CONFIRMATION_UNSET
  );
  const [width, setWidth] = useState(250);
  const [originalX, setOriginalX] = useState(0);
  const [originalY, setOriginalY] = useState(0);
  const [originalWidth, setOriginalWidth] = useState(250);
  const [options, setOptions] = useState([]);
  const [display, setDisplay] = useState(false);
  const [openActions, { toggle: toggleOpenActions }] = useBoolean(false);
  const [attentionVisible, { toggle: toggleAttentionVisible }] =
    useBoolean(false);
  const needConfirmation =
    confirmation !== calendarConfirmationStatus.CONFIRMATION_UNSET;
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles) && !plan.inactive;
  const colorCode = plan["color"] ? plan["color"] : "26,147,111";
  const verticalPositionsRef = useRef(null);
  const ref = useRef(null);
  // const renderDependencies = calendarState + JSON.stringify(verticalPositions);
  const renderDependencies =
    propRenderDependencies || calendarState + JSON.stringify(verticalPositions);

  if (!ref.current) ref.current = renderDependencies;
  const shouldComponentRerender = ref.current !== renderDependencies;
  if (!verticalPositionsRef.current || shouldComponentRerender)
    verticalPositionsRef.current = verticalPositions;
  const bgColor = useMemo(() => {
    let value = `rgba(${colorCode},1)`;
    if (
      // (!machineId ||
      //     [
      //         plan?.machineRequirements[machineRequirementIndex]?.drivers ?? [],
      //         plan?.machineRequirements[machineRequirementIndex]?.workers ?? [],
      //         plan?.machineRequirements[machineRequirementIndex]?.managers ?? [],
      //     ].some((array) => array.length === 0)) &&
      // !plan?.machineRequirements[machineRequirementIndex]?.done
      plan?.machineRequirements[machineRequirementIndex]?.isHidden
    ) {
      value = `repeating-linear-gradient(-45deg, rgb(${colorCode}), rgb(${colorCode}) 24px, rgb(${lighterDarkerColor(
        colorCode,
        10
      )}) 24px, rgb(${lighterDarkerColor(colorCode, 10)}) 48px)`;
    }
    return value;
  }, [colorCode, plan, machineRequirementIndex]);
  const queryClient = useQueryClient();

  const [updatePackage, setUpdatePackage] = useState(null);

  useEffect(() => {
    if (
      confirmation === calendarConfirmationStatus.CONFIRMATION_UNSET &&
      ((!x && !y) || shouldComponentRerender)
    ) {
      if (shouldComponentRerender) ref.current = renderDependencies;
      let yPosition = verticalPositions[machine["machineId"]];

      if (typeof yPosition === "undefined") {
        if (machineId) {
          setDisplay(false);
          return;
        } else {
          if (machine["position"]) {
            yPosition = machine["position"];
          }
        }
      }

      // This is a hack done because the format of the data locally for start and end
      // looks like this 2023-01-06T00:00:00.000 instead of 2023-01-05T16:00:00.000Z
      // My assumption is that the backend is converting it toISOString()
      // The reason I have this here is emulate optimistic updates.
      const getStart = () => {
        if (start && !start.includes("Z")) {
          return new Date(start.split("T")[0]).toISOString();
        }

        return start;
      };

      const getEnd = () => {
        if (end && !end.includes("Z")) {
          return new Date(end.split("T")[0]).toISOString();
        }

        return end;
      };

      // We had to manually convert start and end toISOString because
      // Because we want to do optimistic updates
      const keyStart = moment(getStart()).utcOffset(0).format("YYYY-M-D");
      const keyEnd = moment(getEnd())
        .add(1, "d")
        .utcOffset(0)
        .format("YYYY-M-D");

      let xPosition = horizontalPositions[keyStart];
      let endValue = horizontalPositions[keyEnd];

      if (
        new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
        new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime() &&
        typeof xPosition === "undefined" &&
        typeof endValue === "undefined"
      ) {
        xPosition = 0;
        endValue = horizontalPositions["end"];
      }

      if (
        new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
        typeof endValue !== "undefined"
      ) {
        xPosition = 0;
      }

      if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
        endValue = horizontalPositions["end"];
      }

      setY(yPosition * CALENDAR_STEP_HEIGHT);
      setOriginalY(yPosition * CALENDAR_STEP_HEIGHT);
      if (
        typeof xPosition !== "undefined" &&
        typeof yPosition !== "undefined"
      ) {
        setX(xPosition * calendarStepWidth);
        setOriginalX(xPosition * calendarStepWidth);
        setWidth((endValue - xPosition) * calendarStepWidth);
        setOriginalWidth((endValue - xPosition) * calendarStepWidth);
        setDisplay(true);
      } else {
        setDisplay(false);
      }
    }
  }, [
    horizontalPositions,
    x,
    y,
    shouldComponentRerender,
    confirmation,
    renderDependencies,
    verticalPositions,
    calendarStart,
    calendarEnd,
    machine,
    start,
    end,
    machineId,
    calendarStepWidth,
  ]);

  useEffect(() => {
    const list =
      machineTeamList &&
      machineTeamList.filter((t) => t.machineId === machine.machineId);
    const res = [];

    if (list === undefined || list.length < 0) return [];

    for (let i = 0; i < list.length; i++) {
      const teams = list[i].teams;
      for (let j = 0; j < teams.length; j++) {
        res.push({ key: teams[j].id, text: "Team " + teams[j].name });
      }
    }
    setOptions(res);
  }, []);

  useEffect(() => {
    setReason(
      plan?.machineRequirements[machineRequirementIndex].attentionReason || ""
    );
  }, [machineRequirementIndex, plan]);

  const isOverlap = (newX, newWidth, newY) => {
    const newStart = parseInt(parseInt(newX / calendarStepWidth).toFixed(0));
    const newEnd = parseInt(
      parseInt(newStart + newWidth / calendarStepWidth).toFixed(0)
    );
    const tempY = newY ?? y;
    // Prevent the project go out of vertical range
    if (!verticalPositions["positionsToIds"][tempY]) {
      return true;
    }
    const tempList = JSON.parse(
      JSON.stringify(verticalPositionsRef.current["positionsToIds"][tempY])
    );
    if (y === tempY) {
      const preStart = x / calendarStepWidth;
      const preEnd = preStart + width / calendarStepWidth;
      const index = tempList.listOfPlan.findIndex(
        (item) => item.start === preStart && preEnd
      );
      index !== -1 && tempList.listOfPlan.splice(index, 1);
    }
    const listToCheck = [...tempList.listOfPlan, ...tempList.listOfService];
    for (let i = 0; i < listToCheck.length; i++) {
      const startBeforeCurrentPlanEnd =
        typeof listToCheck[i].end === "undefined"
          ? true
          : newStart <= listToCheck[i].end;
      const endAfterCurrentPlanStart =
        typeof listToCheck[i].start === "undefined"
          ? true
          : newEnd > listToCheck[i].start;

      if (startBeforeCurrentPlanEnd && endAfterCurrentPlanStart) {
        saveMessage("Cannot Overlap");
        return true;
      }
    }
    return false;
  };

  const updateAttentionReason = async ({ attentionReason }) => {
    const data = JSON.parse(JSON.stringify(plan));

    data.machineRequirements[machineRequirementIndex].attentionReason =
      attentionReason;

    delete data.createBy;
    delete data.createdDate;
    delete data.modifiedDate;
    delete data.modifyBy;

    await updateMachineRequirementMutation.mutate(
      { data },
      {
        onFailure: (data, variables) => {
          setReason(plan?.attentionReason || "");
        },
        onerror: (data, variables) => {
          setReason(plan?.attentionReason || "");
        },
        onSettled: () => {
          queryClient.invalidateQueries("machines");
          queryClient.invalidateQueries("plans");
          queryClient.invalidateQueries(["plan", machine["projectId"]]);
        },
      }
    );
  };

  const update = async ({
    newX,
    newY,
    newW,
    previousX,
    previousY,
    previousW,
  }) => {
    const data = JSON.parse(JSON.stringify(plan));
    let targetMachineTeams = null;
    let newMachineId = null;
    delete data.createBy;
    delete data.createdDate;
    delete data.modifiedDate;
    delete data.modifyBy;

    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);

    if (newY !== originalY) {
      const tempMachine = verticalPositions["positionsToIds"][newY];
      if (!tempMachine) {
        return false;
      }

      if (!machineId) {
        const newMachine = {
          machineId: tempMachine.id,
          machineName: tempMachine.name,
          imageUrl: tempMachine.image,
          machineType:
            data["machineRequirements"][machineRequirementIndex]["machineType"],
          sizeGroup:
            data["machineRequirements"][machineRequirementIndex]["sizeGroup"],
          techAreaEnum:
            data["machineRequirements"][machineRequirementIndex]["techArea"],
          start: data["machineRequirements"][machineRequirementIndex]["start"],
          end: data["machineRequirements"][machineRequirementIndex]["end"],
        };
        data["machineRequirements"][machineRequirementIndex]["machines"] = [
          newMachine,
        ];
      } else {
        data["machineRequirements"][machineRequirementIndex]["machines"][
          machineIndex
        ]["machineId"] = tempMachine.id;
        data["machineRequirements"][machineRequirementIndex]["machines"][
          machineIndex
        ]["machineName"] = tempMachine.name;
        data["machineRequirements"][machineRequirementIndex]["machines"][
          machineIndex
        ]["imageUrl"] = tempMachine.image;
        data["machineRequirements"][machineRequirementIndex]["drivers"] = [];
        data["machineRequirements"][machineRequirementIndex]["workers"] = [];
        data["machineRequirements"][machineRequirementIndex]["done"] = false;
        targetMachineTeams = machineTeamList.find(
          (item) => item.machineId === tempMachine.id
        )?.teams;
        newMachineId = tempMachine.id;
      }
    }
    const machineOldStartDate =
      data["machineRequirements"][machineRequirementIndex]["machines"][
        machineIndex
      ]["start"];
    const machineOldEndDate =
      data["machineRequirements"][machineRequirementIndex]["machines"][
        machineIndex
      ]["end"];
    const newStartDate =
      horizontalPositions["positionsToDates"][newX / calendarStepWidth];
    const endPositionKey = (newW + newX) / calendarStepWidth;
    const newEndDate = horizontalPositions["positionsToDates"][endPositionKey];
    const newEndDateMinusOneDay = moment(newEndDate)
      .subtract(1, "d")
      .format("YYYY-MM-DD[T]00:00:00.000");

    if (newX !== originalX) {
      if (!newStartDate) {
        return false;
      }
      data["machineRequirements"][machineRequirementIndex]["machines"][
        machineIndex
      ]["start"] = newStartDate;
      adjustStaffPeriod(
        data,
        machineOldStartDate,
        machineOldEndDate,
        newStartDate,
        newEndDateMinusOneDay
      );
    }
    if (newX !== originalX || newW !== originalWidth) {
      if (!newEndDate) {
        return false;
      }
      data["machineRequirements"][machineRequirementIndex]["machines"][
        machineIndex
      ]["end"] = newEndDateMinusOneDay;
      adjustStaffPeriod(
        data,
        machineOldStartDate,
        machineOldEndDate,
        newStartDate,
        newEndDateMinusOneDay
      );
    }
    if (typeof isUpdated === "function") {
      isUpdated();
    }
    const activityLog = await planActivityLogComposer(
      plan?.machineRequirements[machineRequirementIndex],
      data.machineRequirements[machineRequirementIndex],
      currentUserId
    );
    data.activityLog = activityLog;

    await createPlanMutation.mutate(
      {
        data,
        rollbackData: { previousX, previousY, previousW },
      },
      {
        onSuccess: (data, variables, context) => {
          // setUpdatePackage(null);
          if (data !== true) {
            rollback(variables.rollbackData);
          }
        },
        onFailure: (data, variables) => {
          // setUpdatePackage(null);
          rollback(variables.rollbackData);
          onFailed();
        },
        onSettled: () => {
          queryClient.invalidateQueries("machines");
          queryClient.invalidateQueries("plans");
          queryClient.invalidateQueries(["plan", machine["projectId"]]);
        },
      }
    );
    if (targetMachineTeams) {
      handleModal(plan["projectId"] + newMachineId + machineIndex);
    }
  };

  const adjustStaffPeriod = (
    data,
    machineOldStartDate,
    machineOldEndDate,
    machineNewStartDate,
    machineNewEndDate
  ) => {
    const conflictMessage = {
      driver: {},
      worker: {},
      manager: {},
    };
    let hasConflict = false;
    ["drivers", "workers", "managers"].forEach((key) => {
      const staffsData =
        data["machineRequirements"][machineRequirementIndex][key];
      staffsData.forEach((staff, staffIndex) => {
        let isOverlap = false;
        if (
          staff.start === machineOldStartDate &&
          staff.end === machineOldEndDate
        ) {
          let staffOthersPeriod = [];
          let staffSchedule = [];
          if (key !== "managers") {
            // Only managers are allowed to be overlapped (except vacation)
            staffOthersPeriod = staffsData
              .filter(
                (item) =>
                  item.id === staff.id &&
                  item.start !== staff.start &&
                  item.end !== staff.end
              )
              .map((item) => ({
                projectId: data.projectId,
                name: data.projectName,
                color: data.color,
                start: item.start,
                end: item.end,
              }));
            staffSchedule = findStaffSchedule(
              staff.id,
              data.projectId,
              key,
              allPlansData
            );
          }
          const staffVacations =
            staff.starfVacations?.map((item) => ({
              ...item,
              name: reasonVacationStaffOptions[item.reason].text,
              backgroundCaro: true,
            })) || [];
          const serviceSchedules = getCrewServiceSchedule(
            staff.id,
            machines || []
          );

          const listToCheck = [
            ...staffOthersPeriod,
            ...staffSchedule,
            ...staffVacations,
            ...serviceSchedules,
          ];
          for (const item of listToCheck) {
            const overlap = checkTimeOverlap(
              new Date(item.start.slice(0, 10)),
              new Date(item.end.slice(0, 10)),
              new Date(machineNewStartDate.slice(0, 10)),
              new Date(machineNewEndDate.slice(0, 10)),
              false //minus newEnd one day
            );
            if (overlap) {
              const conflictKey = key.slice(0, -1);
              const staffFullName = `${staff.firstName} ${staff.lastName}`;
              if (!conflictMessage[conflictKey][staffFullName])
                conflictMessage[conflictKey][staffFullName] = [];
              conflictMessage[conflictKey][staffFullName].push(item);
              hasConflict = true;
              isOverlap = true;
            }
          }
          if (!isOverlap) {
            staff.start = machineNewStartDate;
            staff.end = machineNewEndDate;
          }
        }
      });
    });
    if (hasConflict) {
      setTimeout(() => onClickToEditPage(), 1000);
      setTimeout(
        () =>
          dispatch(
            showConflictMessage(conflictMessage, MessageBarType.warning)
          ),
        3000
      );
    }
  };

  const rollback = ({ previousX, previousY, previousW }) => {
    let oldX = previousX;
    let oldY = previousY;
    let oldWidth = previousW;
    if (currentUnplannedItem) {
      oldX = currentUnplannedItem.x;
      oldY = currentUnplannedItem.y;
      oldWidth = currentUnplannedItem.width;
      dispatch(setCurrentUnplannedItem(null));
      const elementNode = document.getElementById(id);
      elementNode.style.opacity = 0;
    }
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
    setX(oldX);
    setY(oldY);
    setWidth(oldWidth);
    setUpdatePackage(null);
  };

  const onClickToDetailPage = () => {
    setPlanOverlayProjectId(
      plan["projectId"],
      machineRequirementIndex,
      machineIndex
    );
  };

  const onClickToEditPage = () => {
    history.push(`/planning/${plan["projectId"]}/${machineRequirementIndex}`);
  };

  /*
        When bar has box-sizing border, the content will shrink equal to the space occupied by the border
        This function will calculate the height and margin of bar to avoid this behavior
    */
  const fitHeightAndMargin = () => {
    let heightAndMargin = {
      height: "50px",
      margin: "0 0 0 10px",
    };
    const hasAllBorder = JSON.stringify(
      projectBorder(
        !!plan?.machineRequirements[machineRequirementIndex].attentionReason,
        plan?.isQuotation
      )
    ).includes("#CB033F");
    const hasOnlyBottomBorder = JSON.stringify(
      projectBorder(
        !!plan?.machineRequirements[machineRequirementIndex].attentionReason,
        plan?.isQuotation
      )
    ).includes("#F28750");
    if (hasAllBorder) {
      heightAndMargin = {
        // height: 'calc(100% + 8px)',
        height: "50px",
        margin: "-4px 0px 4px 6px",
      };
    } else if (hasOnlyBottomBorder) {
      heightAndMargin = {
        // height: 'calc(100% + 5px)',
        height: "50px",
        margin: "-2px 0px 4px 10px",
      };
    }
    return heightAndMargin;
  };

  const deleteReq = async () => {
    const data = JSON.parse(JSON.stringify(plan));
    data["machineRequirements"].splice(machineRequirementIndex, 1);
    await createPlanMutation.mutate(
      { data: data, rollbackData: {} },
      {
        onSuccess: (data, variables, context) => {
          // setUpdatePackage(null);
          if (data !== true) {
            rollback(variables.rollbackData);
          }
        },
        onFailure: (data, variables) => {
          // setUpdatePackage(null);
          rollback(variables.rollbackData);
          onFailed();
        },
        onSettled: () => {
          queryClient.invalidateQueries("machines");
          queryClient.invalidateQueries("plans");
          queryClient.invalidateQueries(["plan", machine["projectId"]]);
        },
      }
    );
  };

  const attentionTargetId = `attentionInput-${id}`;

  const actionDiv =
    width >
    (displayWeekView ? calendarStepWidth * 11 : calendarStepWidth * 4) ? (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          width: "100%",
          marginBottom: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginRight: 16,
          }}
          onClick={onClickToEditPage}
        >
          <FontAwesomeIcon
            icon={allowEdit ? faEdit : faEye}
            style={{ marginRight: 4, fontSize: 13 }}
            className={classNames.icon}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginRight: 16,
          }}
          onClick={onClickToDetailPage}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ marginRight: 4, fontSize: 13 }}
            className={classNames.icon}
          />
        </div>
        {allowEdit && options.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              marginRight: 12,
            }}
            onClick={handleModal}
          >
            <FontAwesomeIcon
              icon={faUsers}
              style={{ marginRight: 4, fontSize: 13 }}
              className={classNames.icon}
            />
          </div>
        )}
        {allowEdit && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              marginRight: 16,
            }}
            id={attentionTargetId}
            onClick={toggleAttentionVisible}
          >
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              style={{ marginRight: 4, fontSize: 13 }}
              className={classNames.icon}
            />
          </div>
        )}
      </div>
    ) : (
      <div id={`Plan${id}`} style={{ maxWidth: 20 }}>
        <FontAwesomeIcon
          icon={faEllipsisH}
          style={{ fontSize: 20 }}
          className={classNames.icon}
          onClick={(e) => {
            toggleOpenActions();
            e.stopPropagation();
          }}
        />
        {openActions && (
          <TeachingBubble
            target={`#Plan${id}`}
            hasSmallHeadline={true}
            onDismiss={toggleOpenActions}
            closeButtonAriaLabel="Close"
            styles={{
              subComponentStyles: {
                callout: {
                  beak: {
                    display: "none",
                  },
                  root: {
                    marginLeft: -10,
                    maxWidth: 130,
                    height: 30,
                  },
                },
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                width: 100,
                marginLeft: -7,
                marginBottom: -8,
                marginTop: -7,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={onClickToEditPage}
              >
                <FontAwesomeIcon
                  icon={allowEdit ? faEdit : faEye}
                  style={{ fontSize: 13 }}
                  className={classNames.iconTooltip}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={onClickToDetailPage}
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ fontSize: 13 }}
                  className={classNames.iconTooltip}
                />
              </div>
              {allowEdit && options.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleModal}
                >
                  <FontAwesomeIcon
                    icon={faUsers}
                    style={{ fontSize: 13 }}
                    className={classNames.iconTooltip}
                  />
                </div>
              )}
              {allowEdit && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  id={attentionTargetId}
                  onClick={toggleAttentionVisible}
                >
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    style={{ fontSize: 13 }}
                    className={classNames.iconTooltip}
                  />
                </div>
              )}
            </div>
          </TeachingBubble>
        )}
      </div>
    );

  const contentCursor = allowEdit
    ? crmSpecialStatus[plan.crmProjectStatusCode]
      ? `url(${window.location.origin}/img/trash-can.svg), auto`
      : `url(${window.location.origin}/img/arrows-alt.svg), auto`
    : "default";

  let content = (
    <div
      // className={allowEdit && !needConfirmation && !crmSpecialStatus[plan.crmProjectStatusCode] ? "dragHandle" : "dragDisabled"}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        margin: fitHeightAndMargin().margin,
        alignItems: "center",
        width: "calc(100% - 13px)",
        height: fitHeightAndMargin().height,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-end",
            marginTop: 4,
          }}
        >
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {crmSpecialStatus[plan.crmProjectStatusCode] && (
              <span
                style={{
                  color: COLOR_CONS_HEX.ALERT,
                  fontWeight: "bold",
                  fontSize: 11,
                  marginRight: 8,
                }}
              >
                {crmSpecialStatus[plan.crmProjectStatusCode].text}
              </span>
            )}
            <span
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: 11,
                marginRight: 8,
              }}
            >
              {plan["projectName"]}
            </span>
            <span style={{ color: "#fff", fontSize: 11 }}>
              {plan["hovedsagsNummer"] ? plan["hovedsagsNummer"] : null}
            </span>
            <span style={{ color: "#fff", fontSize: 11 }}>
              {activities ? " - " + activities : ""}
            </span>
          </div>
          <div style={{ marginRight: 11, whiteSpace: "nowrap" }}>
            <span style={{ color: "#fff", fontSize: 11 }}>
              {plan?.personResponsbible?.name
                ? plan?.personResponsbible?.name
                : "Unknown"}
            </span>
          </div>
        </div>
        {
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "100%",
              color: "#fff",
              marginBottom: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: 16,
              }}
              onClick={onClickToEditPage}
            >
              <FontAwesomeIcon
                icon={allowEdit ? faEdit : faEye}
                style={{ marginRight: 4, fontSize: 13 }}
                className={classNames.icon}
              />
              <span style={{ color: "#fff", fontSize: 11 }}>
                {allowEdit ? "Edit" : "View"}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: 16,
              }}
              onClick={onClickToDetailPage}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ marginRight: 4, fontSize: 13 }}
                className={classNames.icon}
              />
              <span style={{ color: "#fff", fontSize: 11 }}>Details</span>
            </div>
            {allowEdit && options.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: 16,
                }}
                onClick={handleModal}
              >
                <FontAwesomeIcon
                  icon={faUsers}
                  style={{ marginRight: 4, fontSize: 13 }}
                  className={classNames.icon}
                />
                <span style={{ color: "#fff", fontSize: 11 }}>Teams</span>
              </div>
            )}
            {allowEdit && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                id={attentionTargetId}
                onClick={toggleAttentionVisible}
              >
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  style={{ marginRight: 4, fontSize: 13 }}
                  className={classNames.icon}
                />
                <span style={{ color: "#fff", fontSize: 11 }}>Attention</span>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );

  if (width <= calendarStepWidth * 2) {
    content = (
      <div
        // className={allowEdit && !needConfirmation ? "dragHandle" : "dragDisabled"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          width: "calc(100% - 10px)",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
            width: "100%",
            marginTop: 4,
            marginLeft: 4,
          }}
        >
          <div
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: 11,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {crmSpecialStatus[plan.crmProjectStatusCode] && (
              <span
                style={{
                  color: COLOR_CONS_HEX.ALERT,
                  fontWeight: "bold",
                  fontSize: 11,
                  marginRight: 8,
                }}
              >
                {crmSpecialStatus[plan.crmProjectStatusCode].text}
              </span>
            )}
            {plan["projectName"] + (activities ? " - " + activities : "")}
          </div>
          <div>{actionDiv}</div>
        </div>
      </div>
    );
  } else if (
    width <= (displayWeekView ? calendarStepWidth * 26 : calendarStepWidth * 13)
  ) {
    content = (
      <div
        // className={allowEdit && !needConfirmation ? "dragHandle" : "dragDisabled"}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "calc(100% - 15px)",
          height: fitHeightAndMargin().height,
          margin: fitHeightAndMargin().margin,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
            width: "100%",
            marginTop: 4,
          }}
        >
          <div
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: 11,
              marginRight: 8,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {crmSpecialStatus[plan.crmProjectStatusCode] && (
              <span
                style={{
                  color: COLOR_CONS_HEX.ALERT,
                  fontWeight: "bold",
                  fontSize: 11,
                  marginRight: 8,
                }}
              >
                {crmSpecialStatus[plan.crmProjectStatusCode].text}
              </span>
            )}
            {plan["projectName"] + (activities ? " - " + activities : "")}
          </div>
          {actionDiv}
        </div>
      </div>
    );
  }

  const tooltipText = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        {plan["projectName"]} {plan["projectNo"]}{" "}
      </div>
      {plan["reason"] && (
        <div style={{ color: "#CB033F" }}> Attention: {plan["reason"]}</div>
      )}
      {activities ? " - " + activities : ""}
    </div>
  );

  useEffect(() => {
    const func = function handleDragUnPlanItem(e) {
      if (e?.data?.text === "Update unplanned plan data") {
        const { projectId, requirementIndex, newX, newY } = e.data;
        if (
          projectId === plan["projectId"] &&
          requirementIndex === machineRequirementIndex &&
          currentUnplannedItem &&
          currentUnplannedItem.barId === id
        ) {
          const elementNode = document.getElementById(id);
          elementNode.style.opacity = 1;
          const width = elementNode.offsetWidth;
          if (newX < 0 || newY < 0) {
            elementNode.style.transform = `translate(${currentUnplannedItem.x}px, ${currentUnplannedItem.y}px)`;
            elementNode.style.opacity = 0;
            elementNode.style.pointerEvents = "unset";
          }
          if (isOverlap(newX, width, newY) || newX < 0 || newY < 0) {
            let oldX = x;
            let oldY = y;
            let oldWidth = width;
            if (currentUnplannedItem) {
              oldX = currentUnplannedItem.x;
              oldY = currentUnplannedItem.y;
              oldWidth = currentUnplannedItem.width;
            }
            rollback({
              previousX: oldX,
              previousY: oldY,
              previousW: oldWidth,
            });
          } else {
            setX(newX);
            setY(newY);
            setUpdatePackage({
              newX: newX,
              newY: newY,
              newW: width,
              previousX: x,
              previousY: y,
              previousW: width,
            });
            setConfirmation(
              calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
            );
            setTimeout(() => window.dispatchEvent(new Event("mousemove")), 300);
          }
          this.removeEventListener("message", handleDragUnPlanItem, false);
        } else {
          return;
        }
      }
      e.stopPropagation();
      e.stopImmediatePropagation();
    };

    window.addEventListener("message", func, false);

    return () => {
      window.removeEventListener("message", func);
    };
  }, [updatePackage, confirmation, currentUnplannedItem, dispatch]);

  return (
    <>
      {display && width > 0 && (
        <Rnd
          style={{
            zIndex: 3,
            top: 0,
            left: 0,
            display: "absolute",
            opacity: hidden ? 0 : 1,
          }}
          size={{ width: width, height: CALENDAR_STEP_HEIGHT }}
          position={{ x: x, y: y }}
          enableResizing={{
            left:
              allowEdit && !crmSpecialStatus[plan.crmProjectStatusCode]
                ? true
                : false,
            right:
              allowEdit && !crmSpecialStatus[plan.crmProjectStatusCode]
                ? true
                : false,
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            const prevX = x;
            const prevW = width;
            const newX = parseInt(position.x.toFixed(0));
            let newWidth = ref.offsetWidth;
            // Sometime the offsetWidth value maybe different 1 or 2 pixel which causing wrong value, below block code handle that case
            if (newWidth % calendarStepWidth !== 0) {
              newWidth =
                (newWidth / calendarStepWidth).toFixed(0) * calendarStepWidth;
            }
            if (newX === prevX && newWidth === prevW) {
              return;
            }
            if (isOverlap(newX, newWidth)) {
              rollback({ previousY: y, previousW: prevW });
              return;
            }
            setX(newX);
            setWidth(newWidth);
            setUpdatePackage({
              newX: newX,
              newY: y,
              newW: newWidth,
              previousX: prevX,
              previousY: y,
              previousW: prevW,
            });
            setConfirmation(
              calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
            );
          }}
          onDragStop={(e, d) => {
            // this is kinda suprising but the values that match the grids are the lastX and lastY instead of x and y, so that is what we set
            const prevX = x;
            const prevY = y;
            const newX = parseInt(d.lastX.toFixed(0));
            const newY = parseInt(d.lastY.toFixed(0));
            if (newX === prevX && newY === prevY) {
              return;
            }
            if (isOverlap(newX, width, newY)) {
              return;
            }
            setX(newX);
            setY(newY);
            setUpdatePackage({
              newX: newX,
              newY: newY,
              newW: width,
              previousX: x,
              previousY: y,
              previousW: width,
            });
            setConfirmation(
              calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
            );
          }}
          resizeGrid={[calendarStepWidth, CALENDAR_STEP_HEIGHT]}
          dragGrid={[calendarStepWidth, CALENDAR_STEP_HEIGHT]}
          dragAxis="both"
          bounds=".planContainer"
          dragHandleClassName="dragHandle"
          id={id}
        >
          <div
            style={{
              background: bgColor,
              display: "flex",
              marginTop: 5,
              height: CALENDAR_STEP_HEIGHT - 8,
              borderRadius: 4,
              ...projectBorder(
                !!plan?.machineRequirements[machineRequirementIndex]
                  .attentionReason,
                plan?.isQuotation
              ),
              opacity:
                crmSpecialStatus[plan.crmProjectStatusCode] || plan.inactive
                  ? 0.75
                  : 1,
            }}
          >
            {confirmation ===
              calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED &&
              typeof updatePackage === "object" && (
                <ConfirmBubble
                  onCancel={() => rollback(updatePackage)}
                  onApprove={() => update(updatePackage)}
                  targetId={id}
                />
              )}
            {deleteProjectConfirmation && (
              <DeleteConfirmation
                target={`#${id}`}
                toggleTeaching={toggleDeleteProjectConfirmation}
                onClickConfirm={deleteReq}
              />
            )}
            <div
              className={
                allowEdit &&
                !needConfirmation &&
                !crmSpecialStatus[plan.crmProjectStatusCode]
                  ? "dragHandle"
                  : "dragDisabled"
              }
              style={{ cursor: contentCursor, width: "100%", height: "100%" }}
              onClick={() =>
                crmSpecialStatus[plan.crmProjectStatusCode]
                  ? toggleDeleteProjectConfirmation()
                  : false
              }
            >
              {!openDarkOverlay ? (
                <TooltipForText text={tooltipText}>{content}</TooltipForText>
              ) : (
                content
              )}
            </div>
          </div>
        </Rnd>
      )}
      {attentionVisible && (
        <TeachingBubble
          target={`#${attentionTargetId}`}
          onDismiss={toggleAttentionVisible}
          calloutProps={{ calloutWidth: 300 }}
          styles={{
            content: {
              background: "white",
            },
          }}
        >
          <TextField
            label="ATTENTION"
            styles={{
              ...inputStyles,
              fieldGroup: { ...inputStyles.fieldGroup, background: "none" },
            }}
            value={reason}
            placeholder="Reason"
            className={classNames.input}
            onChange={(e) => setReason(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <PrimaryButton
              text="GEM"
              onClick={() => {
                updateAttentionReason({ attentionReason: reason });
                toggleAttentionVisible();
                softReloadPage()
              }}
              styles={primaryButtonStyles}
            />
          </div>
        </TeachingBubble>
      )}
    </>
  );
};

export default connect(null, {
  createPlan,
  setPlanOverlayProjectId,
  saveMessage,
  updatePlan,
})(Project);
