// Import components
import IconWithBadge from "./IconWithBadge";

// Import types
import { Omit } from "../../types";
import { IconWithBadgeProps } from "./IconWithBadge";

// HOC
type MachineIconWithBadgeProps = Omit<IconWithBadgeProps,'iconImgUrl'>;

const MachineIconWithBadge = ({...rest}: MachineIconWithBadgeProps) => {
    return (
        <IconWithBadge
            {...rest}
            iconImgUrl={"./img/machineBlue.svg"}
        />
    );
}

export default MachineIconWithBadge;
