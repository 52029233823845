import { IconButton, TeachingBubble, DirectionalHint } from "@fluentui/react";
import React from "react";
import { Formik } from "formik";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getMachines } from "../../../redux/machines/machines.actions";
import {
  createPrebooking,
  getPrebooking,
  updatePrebooking,
  changePrebookingBubbleInfo,
} from "../../../redux/prebooking/prebooking.actions";
import { findDifferent, formatDate } from "../../../utils/utils";
import {
  ACTIVITY_LOG_ENUM,
  ACTIVITY_LOG_TYPE_ENUM,
} from "../../../utils/constants";
import { PrebookingForm } from ".";
import CalendarSchedule from "../../common/calendar/CalendarSchedule";
import FixPositionBubble from "../../common/FixPositionBubble";
import EditPrebookingBubble from "../../common/EditPrebookingBubble";
import { iconButtonStyles } from "../../../utils/theme";

const PrebookingBubble = () => {
  const dispatch = useDispatch();
  const realPageWidth = document.documentElement.offsetWidth;
  const currentUserId = useSelector((state) => state?.user?.user?.userId);
  const {
    showPrebookingModal,
    isEditModal,
    overlayPrebookingId,
    bubbleTarget,
  } = useSelector((state) => state.prebooking);
  const queryClient = useQueryClient();
  const { data: machineData, isLoading: isLoadingMachines } = useQuery(
    "machines",
    dispatch(getMachines)
  );
  const { data: prebookingData, status } = useQuery(
    ["prebooking", overlayPrebookingId],
    () => dispatch(getPrebooking(overlayPrebookingId)),
    { enabled: !!overlayPrebookingId }
  );
  const { mutate: mutateCreatePrebooking } = useMutation(
    (prebookingData) => dispatch(createPrebooking(prebookingData)),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("prebookings");
          onExit();
        }
      },
    }
  );
  const { mutate: mutateUpdatePrebooking } = useMutation(
    (prebookingTempData) => dispatch(updatePrebooking(prebookingTempData)),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("prebookings");
          queryClient.invalidateQueries(["prebooking", overlayPrebookingId]);
          onExit();
        }
      },
    }
  );

  const validationSchema = Yup.object({
    prebookingName: Yup.string("Enter a name").required("Name is required"),
    personResponsbible: Yup.string().nullable(),
    machineId: Yup.string("Enter a name").required("Machine is required"),
    // period: Yup.array().of(Yup.date()).required("Start date is required"),
  });

  const formSubmit = async (data) => {
    const personResponsbible = {
      userId: null,
      name: data.personResponsbible,
    };
    const [start, end] = formatDate(data.period);

    if (isEditModal) {
      const prebookingTempData = {
        ...prebookingData,
        projectName: data.prebookingName,
        personResponsbible: personResponsbible,
        machineId: data.machineId,
        start,
        end,
      };
      const different = [];
      Object.keys(prebookingData).forEach((key) => {
        let keyDataDifferent = null;
        switch (key) {
          case "projectName":
            keyDataDifferent = findDifferent(
              prebookingData[key],
              prebookingTempData[key],
              key
            );
            break;
          case "personResponsbible":
            keyDataDifferent = findDifferent(
              prebookingData[key].name,
              personResponsbible.name,
              "personResponsible"
            );
            break;
          case "machineId":
            keyDataDifferent = findDifferent(
              `machineId:${prebookingData[key]}`,
              `machineId:${prebookingTempData[key]}`,
              "machine",
              false
            );
            break;
          case "start":
            const startText = moment(prebookingData.start, "YYYY-M-D").format(
              "D.M.YY"
            );
            const endText = moment(prebookingData.end, "YYYY-M-D").format(
              "D.M.YY"
            );
            const newStartText = moment(start, "YYYY-M-D").format("D.M.YY");
            const newEndText = moment(end, "YYYY-M-D").format("D.M.YY");
            keyDataDifferent = findDifferent(
              `${startText} - ${endText}`,
              `${newStartText} - ${newEndText}`,
              "period"
            );
            break;
          default:
            break;
        }
        if (keyDataDifferent) different.push(keyDataDifferent);
      });
      const activityLog = different.length
        ? {
            type: ACTIVITY_LOG_TYPE_ENUM.UPDATE,
            resourceId: data.projectId,
            resourceType: ACTIVITY_LOG_ENUM.BOOKING,
            userId: currentUserId,
            comment: different.join("\n"),
          }
        : null;
      prebookingTempData.activityLog = activityLog;
      await mutateUpdatePrebooking(prebookingTempData);
    } else {
      const startText = moment(start, "YYYY-M-D").format("D.M.YY");
      const endText = moment(end, "YYYY-M-D").format("D.M.YY");
      const activityLog = {
        type: ACTIVITY_LOG_TYPE_ENUM.CREATE,
        resourceType: ACTIVITY_LOG_ENUM.BOOKING,
        userId: currentUserId,
        comment: `Created this Prebooking with period "${startText} - ${endText}"`,
      };
      const prebookingData = {
        projectName: data.prebookingName,
        personResponsbible: personResponsbible,
        machineId: data.machineId,
        start,
        end,
        activityLog,
      };
      await mutateCreatePrebooking(prebookingData);
    }
  };

  const onExit = () => {
    dispatch(
      changePrebookingBubbleInfo({
        showPrebookingModal: false,
        isEditModal: false,
        bubbleTarget: null,
      })
    );
  };

  return (
    showPrebookingModal &&
    bubbleTarget &&
    (isEditModal ? (
      bubbleTarget.includes("Overlay") ? (
        <FixPositionBubble
          calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
          target={`#${bubbleTarget}`}
          mainPositionRight="8px"
          beakPositionRight="151px"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "-15px",
            }}
          >
            <IconButton
              styles={iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={() => onExit()}
            />
          </div>
          <div
            style={{
              zIndex: 2,
            }}
          >
            {status === "success" && prebookingData && machineData && (
              <Formik
                initialValues={{
                  ...prebookingData,
                  prebookingName: prebookingData.projectName,
                  personResponsbible: prebookingData.personResponsbible?.name,
                  period: [
                    new Date(prebookingData.start),
                    new Date(prebookingData.end),
                  ],
                }}
                validationSchema={validationSchema}
                onSubmit={formSubmit}
              >
                {(props) => (
                  <CalendarSchedule
                    target="machine"
                    preBookingId={overlayPrebookingId}
                    targetInfo={machineData.find(
                      (item) =>
                        item.machineId ===
                        (props.values.machineId || prebookingData.machineId)
                    )}
                  >
                    <PrebookingForm
                      {...props}
                      machineData={machineData}
                      isLoadingMachines={isLoadingMachines}
                    />
                  </CalendarSchedule>
                )}
              </Formik>
            )}
          </div>
        </FixPositionBubble>
      ) : (
        <EditPrebookingBubble targetId={bubbleTarget}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "-15px",
            }}
          >
            <IconButton
              styles={iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={() => onExit()}
            />
          </div>
          <div style={{ zIndex: 2 }}>
            {status === "success" && prebookingData && machineData && (
              <Formik
                initialValues={{
                  ...prebookingData,
                  prebookingName: prebookingData.projectName,
                  personResponsbible: prebookingData.personResponsbible?.name,
                  period: [
                    new Date(prebookingData.start),
                    new Date(prebookingData.end),
                  ],
                }}
                validationSchema={validationSchema}
                onSubmit={formSubmit}
              >
                {(props) => (
                  <CalendarSchedule
                    target="machine"
                    preBookingId={overlayPrebookingId}
                    targetInfo={machineData.find(
                      (item) =>
                        item.machineId ===
                        (props.values.machineId || prebookingData.machineId)
                    )}
                  >
                    <PrebookingForm
                      {...props}
                      machineData={machineData}
                      isLoadingMachines={isLoadingMachines}
                    />
                  </CalendarSchedule>
                )}
              </Formik>
            )}
          </div>
        </EditPrebookingBubble>
      )
    ) : (
      <FixPositionBubble
        calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
        target={`#${bubbleTarget}`}
        mainPositionRight={realPageWidth > 1000 ? "430.7px" : "-9px"}
        beakPositionRight={realPageWidth > 1000 ? "155.8px" : "52.5px"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "-15px",
          }}
        >
          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: "Cancel" }}
            ariaLabel="Close popup modal"
            onClick={() => onExit()}
          />
        </div>
        <div
          style={{
            zIndex: 2,
          }}
        >
          <Formik
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={formSubmit}
          >
            {(props) => (
              <CalendarSchedule
                target="machine"
                targetInfo={machineData.find(
                  (item) =>
                    item.machineId ===
                    (props.values?.machineId || prebookingData?.machineId)
                )}
              >
                <PrebookingForm
                  {...props}
                  machineData={machineData}
                  isLoadingMachines={isLoadingMachines}
                />
              </CalendarSchedule>
            )}
          </Formik>
        </div>
      </FixPositionBubble>
    ))
  );
};

export default PrebookingBubble;
