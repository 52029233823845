import React from 'react';
// import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { DispositionCalendar } from './planning/machineDisposition/';
import { SideMenuSmall } from '../common';
import ScreenSizeAndPosition from '../common/ScreenSizeAndPosition';

const classNames = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'row',
        background: '#F1F1F1',
        width: '100%'
    },
    // pageContentContainer: {
    // },
    // pageInnerContainer: {
    //     margin: '40px 124px 40px 150px'
    // },
});

const Planning = () => {
    return (
        <div className={classNames.root}>
            <ScreenSizeAndPosition>
                <SideMenuSmall/>
            </ScreenSizeAndPosition>
            <div className={classNames.pageContentContainer}>
                <div className={classNames.pageInnerContainer}>
                    {/* <Header title='Planlæg' subTitle='OVERBLIK OVER DISPONERING' /> */}
                <DispositionCalendar />
                    {/* <Pivot aria-label="Basic Pivot Example">
                        <PivotItem headerText="MASKIN- OG FORMANDSDISPONERING">
                        
                        </PivotItem>
                        <PivotItem headerText="MANDSKABSDISPONERING">
                            
                        </PivotItem>
                        <PivotItem headerText="PROJEKT VISNING">
                            
                        </PivotItem>
                    </Pivot> */}
                </div>
            </div>
        </div>   
    )
}

export default Planning;
