import React from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

// toggle false: switch on left
// toggle true: switch on right
const classNames = (toggle) =>
  mergeStyleSets({
    toggle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      border: "1px solid #006CAD",
      borderRadius: 4,
      height: 38,
      width: 104,
      minWidth: 106,
      cursor: "pointer",
    },
    switch: {
      height: 40,
      width: 53,
      borderRadius: 4,
      background: "#006CAD",
      position: "absolute",
      transform: toggle ? "translate(51%, 0)" : "translate(-51%, 0)",
      transition: "all 0.3s ease",
      zIndex: 1,
    },
    component: {
      zIndex: 2,
    },
  });

const ToggleButton = (props) => {
  const { toggle, onClick, LeftIcon, RightIcon, styles, onMouseOver } = props;
  const classes = classNames(toggle);
  return (
    <div
      onClick={onClick}
      className={classes.toggle}
      style={{ ...styles }}
      onMouseOver={onMouseOver}
    >
      <div className={classes.switch} />
      <div className={classes.component}>
        <LeftIcon />
      </div>
      <div className={classes.component}>
        <RightIcon />
      </div>
    </div>
  );
};

export default ToggleButton;
