import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';

const classNames = mergeStyleSets({
    root: {
        fontSize: 12,
        fontWeight: 400,
        color: 'rgb(164, 38, 44)'
    },
});

const CustomInputErrorComponent = ({errorMessage, styles = {}}) => {
    if(!errorMessage) {
        return null;
    }

    return (
        <div className={classNames.root} style={{...styles}}>{errorMessage}</div>
    )
}

export default CustomInputErrorComponent
