import React, { Fragment } from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { DriverIconWithBadge, WorkerIconWithBadge, SupervisorIconWithBadge } from "../../common/";

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        alignItems: 'center'
    },
    icon: {
        fontSize: 24,
        color: 'rgb(0, 108, 173)',
        cursor: 'pointer',
        marginRight: 16
    },
});

const MachineTeamsTableCrewCell = ({history, teamId, drivers, workers, managers}) => {
    return (
        <Fragment>
            <div className={classNames.container}>
                {(!drivers || drivers.length < 1) ? null : <DriverIconWithBadge redirect={() => history.push(`/team/${teamId}`)} count={drivers?.length}/>}
                {(!workers || workers.length < 1) ? null : <WorkerIconWithBadge redirect={() => history.push(`/team/${teamId}`)} count={workers?.length}/>}
                {(!managers || managers.length < 1) ? null : <SupervisorIconWithBadge redirect={() => history.push(`/team/${teamId}`)} count={managers?.length}/>}
            </div>
        </Fragment>
    )
}

export default MachineTeamsTableCrewCell;
