import React, { Fragment } from "react";
import { Rnd } from "react-rnd";
import moment from "moment";
import "moment/locale/da";
import {
  CALENDAR_STEP_WIDTH,
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  reasonVacationStaffOptions,
} from "../../../../utils/constants";
import { TooltipForText } from "../../../common";

const MachineServiceStaffVacationBar = ({
  item: { start, end, color, reason, notes, comment },
  horizontalPositions,
  calendarStepWidth = CALENDAR_STEP_WIDTH,
  isMachineService,
  calendarStart,
  calendarEnd,
}) => {
  const keyStart = moment(start).format("YYYY-M-D");
  const keyEnd = moment(end).add(1, "d").format("YYYY-M-D");
  let xPosition = horizontalPositions[keyStart];
  let endValue = horizontalPositions[keyEnd];
  if (
    new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
    new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime() &&
    typeof xPosition === "undefined" &&
    typeof endValue === "undefined"
  ) {
    xPosition = 0;
    endValue = horizontalPositions["end"];
  }

  if (
    new Date(start.slice(0, -1))?.getTime() <= calendarStart?.getTime() &&
    typeof endValue !== "undefined"
  ) {
    xPosition = 0;
  }

  if (new Date(end.slice(0, -1))?.getTime() >= calendarEnd?.getTime()) {
    endValue = horizontalPositions["end"];
  }
  const x = xPosition * calendarStepWidth;
  const widthBar = (endValue - xPosition) * calendarStepWidth;
  const name = isMachineService
    ? comment
    : `${reasonVacationStaffOptions[reason]["text"]}${
        notes ? ` (${notes})` : ""
      }`;
  const colorCode = "241,241,241";
  let bgColor = `repeating-linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255) 24px, rgb(${colorCode}) 24px, rgb(${colorCode}) 48px)`;
  let renderDetail = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
          paddingLeft: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: "#575756",
              fontWeight: "bold",
              fontSize: 11,
              marginRight: 8,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Rnd
        style={{ zIndex: 3, top: 0, left: 0 }}
        size={{ width: widthBar, height: CALENDAR_STAFFVIEW_STEP_HEIGHT }}
        position={{ x: x, y: 0 }}
        enableResizing={false}
        disableDragging={true}
      >
        <div
          style={{
            backgroundImage: bgColor,
            backgroundPosition: "0 0, 0 0, -10px -10px, 10px 10px",
            display: "flex",
            height: CALENDAR_STAFFVIEW_STEP_HEIGHT - 8,
            border: "2px solid #868685",
            boxSizing: "border-box",
            borderRadius: 4,
          }}
        >
          <TooltipForText text={name}>{renderDetail}</TooltipForText>
        </div>
      </Rnd>
    </Fragment>
  );
};

export default MachineServiceStaffVacationBar;
