import { DirectionalHint, Dropdown, TeachingBubble } from "@fluentui/react";
import React, { useEffect, useState, useRef } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faPalette,
} from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";
import { reasonVacationStaffOptions } from "../../utils/constants";
import { customAutoCompleteStyles, dropDownStyles } from "../../utils/theme";
import Select from "react-select";
import { ColorPickerComponent, CustomInputErrorComponent } from ".";

const classNames = mergeStyleSets({
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    color: "black",
  },
  radioContainer: {
    display: "flex",
    marginBottom: 17,
    color: "black",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: 224,
    marginLeft: -15,
    marginTop: 10,
  },
  icon: {
    fontSize: 24,
    color: "#006CAD",
    cursor: "pointer",
  },
  colorSelector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(159, 159, 159)",
    borderRadius: 4,
    height: 46,
    width: 200,
    marginTop: 24,
    selectors: {
      ":hover": {
        border: "1px solid rgb(42, 42, 42)",
      },
    },
  },
});

/* when using css zoom, we need to adjust the position of bubble manually, 
that's why this component's position is complicated
We will get the x position from the mouse and y position from the target */
const ConfirmProjectBubble = ({
  onCancel,
  onApprove,
  targetId,
  projectData,
  section,
  setSection,
  color,
  setColor,
}) => {
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const targetPosition = document
    .getElementById(targetId)
    ?.getBoundingClientRect();
  const [mousePosition, setMousePosition] = useState(null);
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const userZoomRatio = 1;
  const [bubbleInitiated, setBubbleInitiated] = useState(false);
  const { sectionOptions } = useSelector((state) => state.defaultData);
  const sectionOptionsWithLabel = sectionOptions.map((option) => ({
    key: option.key,
    label: option.text,
  }));
  const mouse = useRef(null);
  if (mousePosition && !mouse.current) {
    mouse.current = mousePosition;
  }

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove, { passive: true });
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  });

  const handleMouseMove = (e) => {
    if (!mousePosition) {
      setMousePosition({
        x: e.pageX,
        y: e.pageY,
      });
    }
  };

  const bubbleStyles = () => {
    setTimeout(() => {
      const teachingBubble = document.querySelectorAll(".ms-TeachingBubble")[0];
      const beak = document.querySelectorAll(".ms-Callout-beak")[0];
      if (teachingBubble) {
        setBubbleInitiated(true);
        teachingBubble.style.bottom = `-${targetPosition.top - 10}px`;
        beak.style.display = "block";
      }
    }, 10);
    const delta =
      (mouse.current?.x - window.pageXOffset) / userZoomRatio +
      56 -
      window.innerWidth;
    return delta > 48 ? 48 : delta > 0 ? delta : 0;
  };

  const togglePickerVisible = () => {
    setColorPickerVisible((prevState) => !prevState);
  };

  return mouse.current && targetPosition ? (
    <TeachingBubble
      target={{
        x: (mouse.current.x - window.pageXOffset) / userZoomRatio,
        y: targetPosition.top,
      }}
      calloutProps={{
        directionalHint: DirectionalHint.topCenter,
        calloutWidth: 250,
        calloutHeight: 36,
      }}
      styles={{
        root: {
          left: (mouse.current.x - window.pageXOffset) / userZoomRatio - 56,
        },
        subComponentStyles: {
          callout: {
            calloutMain: {
              display: bubbleInitiated ? "block" : "none",
            },
            beak: {
              marginRight: bubbleStyles(),
            },
          },
        },
      }}
    >
      <div className={classNames.modalContainer}>
        <div style={{ fontWeight: "bold", fontSize: 13 }}>
          Please input missing data!
        </div>
        {!projectData.color && (
          <>
            <div
              onClick={togglePickerVisible}
              className={classNames.colorSelector}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 8,
                }}
              >
                <div
                  style={{
                    fontSize: 11,
                    marginRight: 8,
                    fontWeight: 600,
                    color: "#2A2A2A",
                    marginTop: 5,
                  }}
                >
                  FARVE
                </div>
                <div
                  style={{
                    background: `rgba(${color},1)`,
                    height: 16,
                    width: 150,
                    borderRadius: 4,
                    marginTop: 3,
                    marginRight: 8,
                  }}
                />
              </div>
              <div className={classNames.icon}>
                <FontAwesomeIcon
                  icon={faPalette}
                  id="colorInput"
                  style={{ color: "black", margin: "10px 0 0 -36px" }}
                />
              </div>
            </div>
            <CustomInputErrorComponent
              errorMessage={!color ? "Please select a color" : ""}
            />
            <div style={{ marginTop: 20 }}></div>
          </>
        )}
        {(!projectData.section || projectData.section === -1) && (
          <>
            <div
              style={{
                fontFamily: "Verdana",
                fontSize: 11,
                fontWeight: "bold",
                transform: "translate(9px, 18px)",
                zIndex: 2,
                marginTop: -13,
                color: "black",
              }}
            >
              REGION NAVN
            </div>
            <Select
              placeholder="Region"
              name="section"
              closeMenuOnSelect={true}
              defaultValue={sectionOptionsWithLabel.find(
                (item) => item.key === section
              )}
              options={sectionOptionsWithLabel}
              isClearable={true}
              isSearchable={true}
              onChange={(item) => setSection(item?.key)}
              className={classNames.input}
              styles={customAutoCompleteStyles(
                false /*error*/,
                true /*whiteBg*/
              )}
              getOptionValue={(option) => option.label}
            />
            <CustomInputErrorComponent
              errorMessage={
                !section || section === -1 ? "Please select a section" : ""
              }
            />
          </>
        )}

        {section && section !== -1 && color && (
          <div className={classNames.iconContainer}>
            <FontAwesomeIcon
              onClick={onApprove}
              icon={faCheck}
              className={classNames.icon}
            />
            <FontAwesomeIcon
              onClick={onCancel}
              icon={faTimes}
              className={classNames.icon}
            />
          </div>
        )}

        {colorPickerVisible && (
          <TeachingBubble
            target="#colorInput"
            onDismiss={togglePickerVisible}
            styles={{
              content: {
                background: "white",
              },
            }}
          >
            <ColorPickerComponent
              selectColor={(value) => {
                setColor(value);
                togglePickerVisible();
              }}
              color={color}
              togglePickerVisible={togglePickerVisible}
            />
          </TeachingBubble>
        )}
      </div>
    </TeachingBubble>
  ) : null;
};

export default ConfirmProjectBubble;
