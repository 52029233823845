// Import libraries
import axios, { AxiosResponse } from "axios";
import { saveMessage } from "../message/message.actions";
import { MessageBarType } from "@fluentui/react/";

// Import actions
import { checkTokenValidity } from "../user/user.actions";

// Import utils
import setDefaults from "../../utils/setDefaults";

// Import constants
import { SOURCE_API } from "../../utils/constants";

// Import types
import { MachinesActionTypes } from "./machines.types";
import { TMachine, TMachineTeamModel } from "../../types";
import { AppDispatch } from "../store";

// @ts-ignore
export const getMachines = (obj) => async (dispatch: AppDispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get<AxiosResponse<TMachine[]>>(
      `${SOURCE_API}/api/Machine/getall`
    );
    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
    return null;
  }
};

export const getMachine =
  (machineId: string) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));

      const response = await axios.get<any, { data: { data: TMachine[] } }>(
        `${SOURCE_API}/api/Machine/get?machineIds=${machineId}`
      );
      return response.data.data[0] ? response.data.data[0] : null;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };

export const createMachine =
  (machineData: TMachine) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.post(
        `${SOURCE_API}/api/Machine`,
        machineData
      );

      if (!response.data.succeeded && response.data.message) {
        throw new Error(response.data.message);
      }
      dispatch(
        saveMessage("Machine successfully created!", MessageBarType.success)
      );

      return response.data.succeeded as boolean;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };

export const updateMachine =
  (machineData: TMachine) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.put(
        `${SOURCE_API}/api/Machine`,
        machineData
      );

      if (!response.data.succeeded && response.data.message) {
        throw new Error(response.data.message);
      }

      dispatch(
        saveMessage("Machine successfully updated!", MessageBarType.success)
      );
      return response.data.succeeded as boolean;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };

export const deleteMachine =
  (machineId: string) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.delete(
        `${SOURCE_API}/api/Machine?machineId=${machineId}`
      );

      if (response.data.succeeded === true) {
        dispatch(saveMessage("Machine deleted", MessageBarType.success));
        return true;
      } else {
        dispatch(saveMessage("There was an error"));
        return false;
      }
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };

export const getTeams = () => async (dispatch: AppDispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(`${SOURCE_API}/api/MachineTeam/getall`);
    return response.data.data as TMachineTeamModel[];
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getTeam =
  (machineId: TMachineTeamModel) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));

      const response = await axios.get(
        `${SOURCE_API}/api/MachineTeam/get?machineIds=${machineId}`
      );
      return response.data.data[0]
        ? (response.data.data[0] as TMachineTeamModel)
        : null;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return null;
    }
  };

export const getTeamByTeamId =
  (teamId: string) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));

      const response = await axios.get<AxiosResponse<TMachineTeamModel>>(
        `${SOURCE_API}/api/MachineTeam/getById?id=${teamId}`
      );
      return response.data.data ? response.data.data : null;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };

export const createTeam =
  (teamData: TMachineTeamModel) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.post(
        `${SOURCE_API}/api/MachineTeam`,
        teamData
      );

      if (!response.data.succeeded && response.data.message) {
        throw new Error(response.data.message);
      }
      dispatch(
        saveMessage("Team successfully created!", MessageBarType.success)
      );

      return response.data.succeeded;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };

export const updateTeam =
  (teamData: TMachineTeamModel) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.put(
        `${SOURCE_API}/api/MachineTeam`,
        teamData
      );

      if (!response.data.succeeded && response.data.message) {
        throw new Error(response.data.message);
      }

      dispatch(
        saveMessage("Team successfully updated!", MessageBarType.success)
      );
      return response.data.succeeded as boolean;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };

export const deleteTeam = (teamId: string) => async (dispatch: AppDispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.delete(
      `${SOURCE_API}/api/MachineTeam?machineId=${teamId}`
    );

    if (response.data.succeeded === true) {
      dispatch(saveMessage("Team deleted", MessageBarType.success));
      return true;
    } else {
      dispatch(saveMessage("There was an error"));
      return false;
    }
  } catch (error) {
    dispatch(checkTokenValidity(error));
    return false;
  }
};

// export const setTechDepartmentFilter = (techDepartmentId) => dispatch => {
export const setFilter = (filter: any) => (dispatch: AppDispatch) => {
  dispatch({
    type: MachinesActionTypes.CHANGE_FILTER,
    payload: filter,
  });
};

export const toggleDisplayWeekView =
  (toggleDisplay: boolean) => (dispatch: AppDispatch) => {
    dispatch({
      type: MachinesActionTypes.TOGGLE_WEEK_VIEW,
      payload: toggleDisplay,
    });
  };

export const toggleDisplayMachineService =
  (toggleDisplay: boolean) => (dispatch: AppDispatch) => {
    const value = typeof toggleDisplay === "object" ? null : toggleDisplay;
    dispatch({
      type: MachinesActionTypes.TOGGLE_MACHINE_SERVICE,
      payload: value,
    });
  };

export const toggleDisplayStaffView =
  (toggleDisplay: boolean) => (dispatch: AppDispatch) => {
    dispatch({
      type: MachinesActionTypes.TOGGLE_STAFF_VIEW,
      payload: toggleDisplay,
    });
  };

export const selectNewDate = (newDate: any) => (dispatch: AppDispatch) => {
  dispatch({
    type: MachinesActionTypes.SELECT_NEW_MONTH,
    payload: newDate,
  });
};

export const onChangeFilterStaffRole =
  (index: any, staffRole: any, techDepartment: any, staffPage: any) =>
  (dispatch: AppDispatch) => {
    const temp = JSON.parse(JSON.stringify([...staffRole])); // Prevent shallow copy
    temp[index].isOn = !temp[index].isOn;
    const filterStaffRole = staffPage
      ? { staffRoleStaffPage: temp }
      : { [techDepartment]: { staffRole: temp } };
    dispatch(setFilter(filterStaffRole));
  };
