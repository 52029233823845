import React from 'react'
import { mergeStyleSets } from '@fluentui/react/lib/Styling';

const classNames = mergeStyleSets({
    root: {
        width: 250,
        height: 56,
        borderBottom: '1px solid #DBDBDB',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    text: {
        margin: 16,
        fontWeight: 'bold',
        fontSize: 11,
        lineHeight: 13,
    },
    station: {
        marginTop: 16,
        width: 25,
        height: 14,
        color: '#868685',
        background: '#DFDFDF',
        textAlign: 'center',
        fontSize: 11,
        lineHeight: 14,
        borderRadius: 4,
    }

});

const Machine = ({machineName}) => {
    return (
        <div className={classNames.root}>
            <div className={classNames.text}>
                {machineName}
            </div>
            <div className={classNames.station}>Ø</div>
        </div>
    )
}

export default Machine
