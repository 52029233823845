export const UserActionTypes = {
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    USER_LOADED: 'USER_LOADED',
    USER_LOADING: 'USER_LOADING',
    AUTH_ERROR: 'AUTH_ERROR',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    PASSWORD_CHANGE_SUCCESS: 'PASSWORD_CHANGE_SUCCESS',
    UPDATE_SETTING_FILTER: 'UPDATE_SETTING_FILTER',
    CHANGE_SECTION_FILTER: 'CHANGE_SECTION_FILTER',
    SET_AUTO_LOGIN: 'SET_AUTO_LOGIN',
}