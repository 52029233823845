import { PlanActionTypes } from './plan.types';

const initialState = {
    overlayProjectId: null,
    overlayPlanIndex: null
};

const planReducer = (state = initialState, action) => {
    switch (action.type) {
        case PlanActionTypes.UPDATE_OVERLAY_PROJECT_ID:
            return {
                ...state,
                overlayProjectId: action.payload.projectId,
                overlayPlanIndex: action.payload.index
            };
        case PlanActionTypes.CLEAR_OVERLAY_PROJECT_ID:
            return {
                ...state,
                overlayProjectId: null,
                overlayPlanIndex: null,
            };
        default:
            return state;
    }
}

export default planReducer;