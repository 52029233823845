// Import libraries
import { PropsWithChildren, useState } from "react";
import { useSelector } from "react-redux";

// Import types
import { TPlanHorizontalPositionMap } from "../../../types/planner";

// Import constants
import {
  BACKGROUND_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
} from "../../../utils/constants";

type PlanContainerProps = {
  horizontalPositionMap: TPlanHorizontalPositionMap;
  backgroundHeight?: number;
};

const PlanContainer = ({
  horizontalPositionMap,
  children,
  backgroundHeight = BACKGROUND_HEIGHT,
}: PropsWithChildren<PlanContainerProps>) => {
  // ======================================== Use Selector =====================================
  const displayWeekView = useSelector(
    // @ts-ignore
    (state) => state.machine.displayWeekView
  ) as boolean;

  // ==================================== Use State =================================
  const [calendarStepWidth, setCalendarStepWidth] = useState(
    displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
  );

  return (
    <div
      className="detailPlanContainer"
      style={{
        height: backgroundHeight,
        width:
          calendarStepWidth * horizontalPositionMap["end"] + calendarStepWidth,
        position: "absolute",
        backgroundImage: displayWeekView
          ? `url("${window.location.origin}/img/calendarBackground5.png")`
          : `url("${window.location.origin}/img/calendarBackground4.png")`,
        backgroundRepeat: "repeat",
      }}
    >
      {children}
    </div>
  );
};

export default PlanContainer;
