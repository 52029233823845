import React, { useState } from 'react';
import CommentContainer from './Comment/CommentContainer';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { SOURCE_API } from '../../../../utils/constants';
import ActivityContainer from './Comment/ActivityContainer';
import ToggleButton from '../../../common/ToggleButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faHistory } from '@fortawesome/pro-regular-svg-icons';
import { mergeStyleSets } from '@fluentui/merge-styles';

const classNames = mergeStyleSets({
    unSelectedIcon: {
        marginRight: 2,
        fontSize: 16,
        color: '#006CAD',
    },
    selectedIcon: {
        marginRight: 2,
        fontSize: 16,
        color: '#fff',
    },
});

const PictureAndCommentSection = ({ projectId, projectColor, planData, overlayPlanIndex, pageWidth }) => {
    const [displayComment, setDisplayComment] = useState(true);
    const imageUrl = planData?.machineRequirements[overlayPlanIndex]?.machines[0]?.imageUrl;
    return (
        <div style={{ display: 'flex', flexDirection: 'column',height: "100%", justifyContent: 'start', alignItems: 'center' }}>
            <div style={{ minHeight: 144, marginBottom: 16, backgroundColor: '#fff', width: '100%', borderRadius: 10 }}>
                <Image
                    maximizeFrame
                    imageFit={ImageFit.cover}
                    src={imageUrl ? `${SOURCE_API}${imageUrl}` : ''}
                    alt='Machine Image'
                    styles={{ root: { borderRadius: 10 } }}
                />
            </div>
            <div style={{ backgroundColor: '#fff', borderRadius: 10, height: "100%", width: '100%', position: 'relative' }}>
                <div style={{ display: 'flex', backgroundColor: '#fff', justifyContent: 'flex-end', borderRadius: 10 }}>
                    <ToggleButton
                        styles={{ margin: '10px 10px 0 0' }}
                        toggle={displayComment}
                        onClick={() => {
                            setDisplayComment(!displayComment);
                        }}
                        LeftIcon={() => <FontAwesomeIcon icon={faHistory} className={!displayComment ? classNames.selectedIcon : classNames.unSelectedIcon} />}
                        RightIcon={() => <FontAwesomeIcon icon={faComment} className={displayComment ? classNames.selectedIcon : classNames.unSelectedIcon} />}
                    />
                </div>
                {displayComment ? (
                    <CommentContainer
                        projectId={planData?.projectId}
                        planId={planData?.machineRequirements[overlayPlanIndex].guid}
                        projectColor={projectColor}
                        machineReq={planData?.machineRequirements[overlayPlanIndex]}
                        isPlan
                    /> // Use machineReqId to separate data between plans of project
                ) : (
                    <ActivityContainer projectId={projectId} planId={planData?.machineRequirements[overlayPlanIndex].guid} />
                )}
            </div>
        </div>
    );
};

export default PictureAndCommentSection;
