import { UserActionTypes } from './user.types';

const initialState = {
    isAuthenticated: false,
    isLoading: false,
    shelfTags: null,
    user: null,
    tokenExpired: false,
    autoLogin: true,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case UserActionTypes.USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload.email
            };
        case UserActionTypes.LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                tokenExpired: false,
                user: action.payload.user,

                // For testing purposes only
                // user: {
                //     ...action.payload.user,
                //     workingRole: '2;1',
                // },
            };
        case UserActionTypes.PASSWORD_CHANGE_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false
            };
        case UserActionTypes.AUTH_ERROR:
        case UserActionTypes.LOGIN_FAIL:
        case UserActionTypes.LOGOUT_SUCCESS:
        case UserActionTypes.TOKEN_EXPIRED:
            localStorage.removeItem('token');
            return {
                ...state,
                user: null,
                isAuthenticated: false,
                isLoading: false,
                tokenExpired: action.type === UserActionTypes.TOKEN_EXPIRED,
                autoLogin: action.type === UserActionTypes.TOKEN_EXPIRED,
            };
        case UserActionTypes.UPDATE_SETTING_FILTER:
            const userTemp = {...state.user};
            userTemp.defaultFilter = action.payload;
            return {
                ...state,
                user: userTemp 
            }
        case UserActionTypes.CHANGE_SECTION_FILTER:
            const userClone = {...state.user};
            userClone.techDepartmentFilter = action.payload;
            return {
                ...state,
                user: userClone
            }
        case UserActionTypes.SET_AUTO_LOGIN:
            return {
                ...state,
                autoLogin: action.payload
            }
        default:
            return state;
    }
}

export default userReducer;