// Import libraries
import { Dropdown, mergeStyleSets, SpinButton, Toggle } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useCallback, useMemo } from "react";
import { faChevronDown, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { useDispatch } from "react-redux";
import { addMonths, differenceInDays, format } from "date-fns";

// Import types
import { GeneralSkill, User } from "../../../types";
import { techDepartmentOptions } from "../../../utils/constants";
import { Skill } from "../../../types/settings";

// Import redux
import { saveMessage } from "../../../redux/message/message.actions";

// Import components
import DatePicker from "../../common/DatePicker";
import SkillTableControl from "../mapmachines/SkillTableControl";
import TextWithTeachingBubble from "../../common/TextWithTeachingBubble";
import PopupGeneralSkills from "../mapmachines/PopupGeneralSkills";
import { isValidDateString } from "../../../utils/utils";

const dropDownStyles = {
  dropdownItem: {
    height: 48,
  },
  dropdownItemSelected: {
    height: 48,
  },
  title: {
    border: "none",
  },
  root: {
    maxWidth: "156px",
    minWidth: 156,
  },
  dropdown: {
    width: "100%",
    caretDownWrapper: {
      marginTop: 8,
    },
  },
  caretDown: {
    color: "#006CAD",
    fontWeight: "1000",
  },
};

const classNames = mergeStyleSets({
  input: {
    border: "none",
    width: 100,
    backgroundColor: "#f1f1f1",
    fontFamily: "Verdana",
    fontSize: 14,
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tr: {
    textAlign: "left",
    height: 55,
    borderBottom: "1px solid #DBDBDB",
  },
  td: {
    whiteSpace: "noWrap",
    padding: "0px 0px 0px 16px",
  },
  th: {
    boxSizing: "border-box",
    padding: "0px 0px 0px 16px",
    fontSize: 11,
    fontWeight: 700,
    color: "#000",
  },
  pageInnerContainer: {
    maxWidth: 1300,
    width: "100%",
    margin: "0 auto",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    fontSize: 22,
    color: "rgb(0, 108, 173)",
    cursor: "pointer",
    marginRight: 12,
  },
  iconColumn: {
    width: 40,
    textAlign: "center",
    padding: "0 20px",
  },
  textAreaInput: {
    marginRight: 10,
    borderRadius: 5,
    width: "95%",
  },
  ekstraContainer: {
    paddingTop: 9,
    paddingBottom: 9,
  },
  ekstra: {
    fontWeight: "400",
    fontSize: 11,
    fontFamily: "Verdana",
    letterSpacing: 1,
    color: "#575756",
    lineHeight: 12,
    height: 16,
    marginTop: 2,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: "#DFDFDF",
    marginBottom: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

type GeneralSkillTableProps = {
  generalSkills: GeneralSkill[];
  onUpdateStaffGeneralSkills: (updatedGeneralSkills: GeneralSkill[]) => void;
  setIsEditing: (isEditing: boolean) => void;
  canEdit: boolean;
  availableGeneralSkills: Skill[];
  staffData: User;
};

const GeneralSkillTable = ({
  generalSkills = [],
  onUpdateStaffGeneralSkills,
  setIsEditing,
  canEdit,
  availableGeneralSkills,
  staffData,
}: GeneralSkillTableProps) => {
  const dispatch = useDispatch();
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editingSkill, setEditingSkill] = useState<GeneralSkill | null>(null);
  const [isNewSkill, setIsNewSkill] = useState<boolean>(false);
  const [generalSkillsCopy, setGeneralSkillsCopy] =
    useState<GeneralSkill[]>(generalSkills);

  const [skillId, setSkillId] = useState<string | null>(null);

  const [popupData, setPopupData] = useState<{
    id: string;
    value: string;
  }>();

  // We are setting the edit index so that we can reset the view
  // if the user switches to general skill
  useEffect(() => {
    return () => {
      setEditIndex(null);
    };
  }, []);

  // Utility functions
  const isEditing = useMemo(
    () => editIndex !== null && !!editingSkill,
    [editIndex, editingSkill]
  );

  // Event Functions
  const onAddClick = (e: React.MouseEvent<SVGSVGElement>): void => {
    if (!isEditing) {
      const copy = [...generalSkillsCopy];

      const newSkill = {
        note: "",
        skillId: "",
        acquired: new Date().toISOString(),
        expiryPeriod: 18,
        sections: [],
        inactive: false,
      };

      copy.push(newSkill);

      setGeneralSkillsCopy(copy);
      setIsNewSkill(true);
      setEditingSkill(newSkill);
      setEditIndex(copy.length - 1);
      setIsEditing(true);
    } else {
      dispatch(
        saveMessage(
          "You must complete the editing process before create new one."
        )
      );
    }
  };

  // Edit and Remove Functions
  const onClickEditGeneralSkill = useCallback(
    (index: number) => {
      if (isEditing) {
        dispatch(saveMessage("You can only edit one item at a time!"));
      } else {
        if (generalSkillsCopy[index]) {
          const { skillId } = generalSkillsCopy[index];

          const skill = availableGeneralSkills.find((el) => el.id === skillId);

          setEditingSkill({
            ...generalSkillsCopy[index],
            sections: skill?.sections || [],
          });
          setIsEditing(true);
          setEditIndex(index);
        }
      }
    },
    [setEditIndex, isEditing, dispatch, generalSkillsCopy, setIsEditing]
  );

  const resetEditState = useCallback(() => {
    setEditIndex(null);
    setEditingSkill(null);
    setIsNewSkill(false);
    setIsEditing(false);
  }, [setEditIndex, setEditingSkill, setIsEditing, setIsNewSkill]);

  const onApplyChange = useCallback(() => {
    if (editingSkill && editIndex !== null) {
      const { acquired, sections, skillId, expiryPeriod } = editingSkill;

      const missingInfo =
        new Date(acquired).getTime() < 0 ||
        !skillId ||
        expiryPeriod < -1 ||
        expiryPeriod === 0 ||
        !Array.isArray(sections);

      if (missingInfo) {
        dispatch(saveMessage("Please provide missing information!"));
      } else {
        const copy = [...generalSkillsCopy];
        copy[editIndex] = editingSkill;

        setGeneralSkillsCopy(copy);
        onUpdateStaffGeneralSkills(copy);

        resetEditState();
      }
    }
  }, [
    generalSkillsCopy,
    editIndex,
    editingSkill,
    dispatch,
    resetEditState,
    onUpdateStaffGeneralSkills,
  ]);

  const onClickInactiveSkill = useCallback(
    (index: number) => {
      if (typeof generalSkillsCopy[index] !== "undefined") {
        const copy = [...generalSkillsCopy];

        const skillCopy = copy[index];

        skillCopy.inactive = !skillCopy.inactive;

        copy[index] = skillCopy;

        resetEditState();
        setGeneralSkillsCopy(copy);
        onUpdateStaffGeneralSkills(copy);
        // onApplyChange();
      }
    },
    [generalSkillsCopy, resetEditState, onApplyChange]
  );

  const onClickRemoveGeneralSkill = useCallback(
    (index: number) => {
      if (typeof generalSkillsCopy[index] !== "undefined") {
        if (editIndex === null && !editingSkill) {
          const copy = [...generalSkillsCopy];

          copy.splice(index, 1);

          resetEditState();
          setGeneralSkillsCopy(copy);
          onUpdateStaffGeneralSkills(copy);
        } else if (isNewSkill) {
          const copy = [...generalSkillsCopy];
          copy.splice(index, 1);
          setGeneralSkillsCopy(copy);

          resetEditState();
        } else {
          resetEditState();
        }
      }
    },
    [
      resetEditState,
      generalSkillsCopy,
      setGeneralSkillsCopy,
      isNewSkill,
      editIndex,
      editingSkill,
      onUpdateStaffGeneralSkills,
    ]
  );

  // Render Functions
  const renderGeneralSkills = useCallback((): JSX.Element[] => {
    const renderSkillId = (
      index: number,
      skillId: string,
      inactive: boolean
    ) => {
      if (editIndex === index) {
        // const options = availableGeneralSkills
        //   .filter((el) => !el.inactive)
        //   .map((val) => ({
        //     key: val.id,
        //     text: val.name,
        //   }))
        //   .filter(
        //     (el) =>
        //       generalSkillsCopy[index].skillId === el.key ||
        //       !generalSkillsCopy.find((item) => item.skillId === el.key)
        //   );

        return (
          <td
            className={classNames.td}
            style={{
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                setSkillId(skillId);
              }}
            >
              <div>
                {availableGeneralSkills.find((el) => el.id === skillId)?.name ||
                  ""}
              </div>
              <FontAwesomeIcon
                id={`edit-${skillId}`}
                icon={faChevronDown as IconProp}
              />
            </div>
            {/* <Dropdown
              selectedKey={skillId}
              options={options}
              styles={dropDownStyles}
              onChange={(_, item) => {
                if (item) {
                  setEditingSkill((values) => ({
                    ...(values as GeneralSkill),
                    skillId: "" + item.key,
                    sections: availableGeneralSkills.find(
                      (el) => el.id === "" + item.key
                    )?.sections || [],
                    expiryPeriod: availableGeneralSkills.find(
                      (el) => el.id === "" + item.key
                    )?.expiryPeriod || -1
                  }));
                }
              }}
              errorMessage={undefined}
              className={classNames.input}
            /> */}
          </td>
        );
      }

      const skill = availableGeneralSkills.find((el) => el.id === skillId);

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          {skill?.name}
        </td>
      );
    };

    const renderSection = (
      index: number,
      sections: number[],
      inactive: boolean,
      skillId: string
    ) => {
      if (index === editIndex) {
        return (
          <td className={classNames.td}>
            <Dropdown
              options={techDepartmentOptions.map((el) => ({
                ...el,
                key: el.key + "",
              }))}
              onChange={(_, item) => {
                if (item?.key) {
                  if (item.selected) {
                    setEditingSkill((values) => ({
                      ...(values as GeneralSkill),
                      sections: [
                        ...new Set([...(values?.sections || []), +item.key]),
                      ],
                    }));
                  } else {
                    setEditingSkill((values) => ({
                      ...(values as GeneralSkill),
                      sections:
                        values?.sections?.filter(
                          (el) => "" + el === item.key
                        ) || [],
                    }));
                  }
                }
              }}
              multiSelect
              styles={dropDownStyles}
              errorMessage={undefined}
              className={classNames.input}
              selectedKeys={sections.map((el) => el + "")}
            />
          </td>
        );
      }

      const skill = availableGeneralSkills.find(
        (skill) => skill.id === skillId
      );

      let labels: undefined | string[] = [];

      if (skill) {
        const { sections } = skill;

        labels =
          sections
            .map(
              (el) =>
                techDepartmentOptions.find((item) => item.key === el)?.label
            )
            .filter((el): el is string => !!el) || [];
      }

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={`${classNames.td} ${classNames.ekstraContainer}`}
        >
          {!labels
            ? "None"
            : labels.map((label) => {
                return (
                  <p key={label} className={classNames.ekstra}>
                    {label}
                  </p>
                );
              })}
        </td>
      );
    };

    const renderAcquired = (
      index: number,
      acquired: string,
      inactive: boolean,
      expiryPeriod: number
    ) => {
      const receivedDate = new Date(acquired);

      const daysDifference =
        expiryPeriod === -1
          ? Infinity
          : differenceInDays(addMonths(receivedDate, expiryPeriod), new Date());

      const text: string = isValidDateString(acquired)
        ? format(receivedDate, "dd.MM.yyyy")
        : "";

      if (editIndex === index) {
        return (
          <td className={classNames.td}>
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: 20 }}>{text}</span>
              <DatePicker
                id={`general-acquired-date${index}`}
                date={receivedDate}
                onSelectDate={(date) => {
                  if (date) {
                    // @ts-ignore
                    setEditingSkill((values) => ({
                      ...values,
                      acquired: date.toISOString(),
                    }));
                  }
                }}
                isMonthPickerVisible
              />
            </span>
          </td>
        );
      }

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{text}</span>
            {daysDifference >= 0 && daysDifference < 30 ? (
              <span>
                <FontAwesomeIcon
                  icon={faCircleExclamation as IconDefinition}
                  color={"#E3B505"}
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </span>
            ) : (
              <></>
            )}
            {daysDifference < 0 ? (
              <span>
                <FontAwesomeIcon
                  icon={faTriangleExclamation as IconDefinition}
                  color={"#F57C00"}
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </span>
            ) : (
              <></>
            )}
          </span>
        </td>
      );
    };

    const renderExpiryPeriod = (
      index: number,
      expiryPeriod: number,
      inactive: boolean
    ) => {
      if (index === editIndex) {
        return (
          <td className={classNames.td}>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
              }}
            >
              <SpinButton
                style={{
                  backgroundColor: "white",
                }}
                styles={{
                  input: {
                    backgroundColor: "white",
                    width: 30,
                  },
                }}
                disabled={expiryPeriod === -1}
                value={expiryPeriod === -1 ? "0" : "" + expiryPeriod}
                min={1}
                max={2000}
                step={1}
                onChange={(_, value) => {
                  setEditingSkill((values) => ({
                    ...(values as GeneralSkill),
                    expiryPeriod: value ? +value : expiryPeriod,
                  }));
                }}
              />
              <Toggle
                checked={expiryPeriod === -1}
                onChange={(_, checked) => {
                  setEditingSkill((values) => ({
                    ...(values as GeneralSkill),
                    expiryPeriod: checked ? -1 : 1,
                  }));
                }}
                styles={{
                  root: {
                    margin: 0,
                  },
                }}
                onText="Never"
                offText="Never"
              />
            </span>
          </td>
        );
      }

      // måneder
      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          {expiryPeriod === -1 ? "None" : `${expiryPeriod} måneder`}
        </td>
      );
    };

    const renderRecertification = ({
      acquired,
      expiryPeriod,
      inactive,
    }: {
      acquired: string;
      inactive: boolean;
      expiryPeriod: number;
    }) => {
      const receivedDate = new Date(acquired);

      let date = receivedDate;

      if (expiryPeriod !== -1) {
        const addedDate = addMonths(receivedDate, expiryPeriod);
        date = addedDate;
      }

      let text: string = isValidDateString(acquired)
        ? format(date, "dd.MM.yyyy")
        : "";

      text = expiryPeriod !== -1 ? text : "";

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          {text}
        </td>
      );
    };

    const renderNote = (index: number, note: string, inactive: boolean) => {
      const id = `general-skill-${index}`;

      if (index === editIndex) {
        return (
          <td
            style={inactive ? { color: "#868685" } : {}}
            className={classNames.td}
          >
            <textarea
              className={classNames.textAreaInput}
              value={note}
              onChange={(e) => {
                setEditingSkill((values) => ({
                  ...(values as GeneralSkill),
                  note: e.target.value,
                }));
              }}
            />
          </td>
        );
      }

      return (
        <td className={classNames.td}>
          <textarea
            className={classNames.textAreaInput}
            style={{ background: "none", border: "none" }}
            value={note}
            onChange={(e) => {
              setEditingSkill((values) => ({
                ...(values as GeneralSkill),
                note: e.target.value,
              }));
            }}
            readOnly
          />
        </td>
      );
    };

    const renderAction = (index: number, inactive: boolean) => {
      if (!canEdit) {
        return <td className={classNames.td}></td>;
      }

      if (index === editIndex) {
        return (
          <td className={classNames.td}>
            <div className={classNames.iconContainer}>
              <FontAwesomeIcon
                icon={faCheck as IconDefinition}
                className={classNames.icon}
                onClick={onApplyChange}
              />
              <FontAwesomeIcon
                icon={faTimes as IconDefinition}
                className={classNames.icon}
                onClick={() => {
                  // resetEditState();
                  onClickRemoveGeneralSkill(index);
                }}
              />
            </div>
          </td>
        );
      }

      return (
        <td className={classNames.td}>
          <SkillTableControl
            id={index.toString()}
            onClickDelete={() => {
              onClickRemoveGeneralSkill(index);
            }}
            onClickInactive={() => {
              onClickInactiveSkill(index);
            }}
            onClickEdit={() => {
              onClickEditGeneralSkill(index);
            }}
            inactive={inactive}
          />
        </td>
      );
    };

    const arr: JSX.Element[] = [];

    // sort the general skills by skill name based  on availableGeneralSkills array
    generalSkillsCopy.sort((a, b) => {
      const skillA = availableGeneralSkills.find((el) => el.id === a.skillId);
      const skillB = availableGeneralSkills.find((el) => el.id === b.skillId);

      if (!skillA || !skillB) {
        return 0;
      }
      
      return skillB?.name.localeCompare(skillA!.name) || 0;
    });

    generalSkillsCopy?.forEach((generalSkill, index) => {
      const { note, acquired, skillId, expiryPeriod, sections, inactive } =
        generalSkill;

      arr.push(
        <tr key={index} className={classNames.tr}>
          {renderSkillId(
            index,
            index === editIndex ? editingSkill?.skillId || "" : skillId,
            inactive
          )}
          {renderSection(
            index,
            index === editIndex ? editingSkill?.sections || [] : sections,
            inactive,
            skillId
          )}
          {renderAcquired(
            index,
            index === editIndex
              ? editingSkill?.acquired || new Date().toDateString()
              : acquired,
            inactive,
            expiryPeriod
          )}
          {renderExpiryPeriod(
            index,
            index === editIndex
              ? editingSkill?.expiryPeriod || -1
              : expiryPeriod,
            inactive
          )}
          {renderRecertification({
            acquired:
              index === editIndex
                ? editingSkill?.acquired || new Date().toDateString()
                : acquired,
            expiryPeriod:
              index === editIndex
                ? editingSkill?.expiryPeriod || -1
                : expiryPeriod,
            inactive,
          })}
          {renderNote(
            index,
            index === editIndex ? editingSkill?.note || "" : note,
            inactive
          )}
          {renderAction(
            index,
            index === editIndex ? editingSkill?.inactive || false : inactive
          )}
        </tr>
      );
    });

    return arr.reverse();
  }, [
    generalSkillsCopy,
    onClickRemoveGeneralSkill,
    onClickEditGeneralSkill,
    editIndex,
    onApplyChange,
    editingSkill,
    canEdit,
    availableGeneralSkills,
    onClickInactiveSkill,
  ]);

  return (
    <>
      <div className={classNames.pageInnerContainer}>
        <div className={classNames.tableContainer}>
          <table className={classNames.table}>
            <tbody>
              <tr className={classNames.tr}>
                <th style={{ width: 156 }} className={classNames.th}>
                  SKILL
                </th>
                <th style={{ width: 160 }} className={classNames.th}>
                  SECTION
                </th>
                <th style={{ width: 160 }} className={classNames.th}>
                  ACQUIRED
                </th>
                <th style={{ width: 164 }} className={classNames.th}>
                  EXPIRY PERIOD
                </th>
                <th style={{ width: 164 }} className={classNames.th}>
                  RECERTIFICATION
                </th>
                <th style={{ width: 248 }} className={classNames.th}>
                  NOTE
                </th>
                {/* This last header is for action */}
                <th style={{ width: 136 }} className={classNames.th}></th>
              </tr>
              <tr className={classNames.tr}>
                <td colSpan={9}></td>
                <td>
                  {canEdit ? (
                    <FontAwesomeIcon
                      icon={faPlus as IconDefinition}
                      className={classNames.icon}
                      onClick={onAddClick}
                    />
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              {renderGeneralSkills()}
            </tbody>
          </table>
        </div>
      </div>
      {popupData && (
        <TextWithTeachingBubble
          target={`#${popupData?.id}`}
          onDismiss={() => {
            setPopupData(undefined);
          }}
          value={popupData?.value}
          onChangeValue={(newVal) => {
            if (popupData && popupData?.id) {
              const { id } = popupData;

              if (id.split("-")[2]) {
                const idNumber = parseInt(id.split("-")[2]);

                if (typeof idNumber === "number") {
                  const values = generalSkillsCopy[idNumber];

                  if (values) {
                    setEditingSkill((values) => ({
                      ...(values as GeneralSkill),
                      note: newVal,
                    }));
                  }
                }
              }
            }
          }}
        />
      )}
      {skillId !== null ? (
        <PopupGeneralSkills
          target={`edit-${skillId}`}
          toggleTeaching={() => {
            setSkillId(null);
          }}
          // Remove skills which are inactive and that has already been added.
          availableGeneralSkills={availableGeneralSkills.filter(
            (el) =>
              !el.inactive &&
              !generalSkillsCopy.find((genSkill) => genSkill.skillId === el.id)
          )}
          staffTechDepartment={staffData.techDepartment}
          onClickGeneralSkill={(selectedSkill: Skill) => {
            if (isEditing && editingSkill) {
              const skill = availableGeneralSkills.find(
                (el) => el.id === "" + selectedSkill.id
              );

              if (skill) {
                setEditingSkill((values) => ({
                  ...(values as GeneralSkill),
                  skillId: selectedSkill.id,
                  sections: skill.sections || [],
                  expiryPeriod: skill.expiryPeriod || -1,
                }));
              }
            }
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default GeneralSkillTable;
