import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Image, ImageFit } from '@fluentui/react/lib/Image';

const classNames = mergeStyleSets({
    icon: {
        fontSize: 24,
        height: 24,
        width: 24,
        marginTop: '15px',
        color: '#006CAD'
    },
    image: {
        fontSize: 24,
        marginTop: 15
    },
    buttonContent: { 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        height: 80,
        width: 124,
        margin: '45px 47px',
        color: '#868685',
        selectors: {
            ':hover': {
                boxShadow: '0px 20px 42px rgba(0, 0, 0, 0.08), 0px -2px 14px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 29, 0.08)',
                borderRadius: '4px'
            },
            'p': {
                fontSize: 11,
                lineHeigh: 11
            }
        }
    }
});


const MenuButton = ({iconName, label}) => {
    return (
        <div className={classNames.buttonContent}>
            <Image
                src={`./img/${iconName}.svg`}
                imageFit={ImageFit.contain}
                alt={iconName}
                width={24}
                height={24}
                className={classNames.image}
            />
            <p>{label}</p>
        </div>
    )
}

export default MenuButton;
