import { Formik } from "formik";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { createStaff } from "../../redux/user/user.actions";
import StaffForm from "./staff/StaffForm";

const NewStaff = ({ createStaff }) => {
    const history = useHistory();
    const queryClient = useQueryClient();

    const validationSchema = Yup.object({
        imageUrl: Yup.string("Upload image").nullable(),
        firstName: Yup.string("Enter a first name").required("First name is required"),
        lastName: Yup.string("Enter a last name").required("Last name is required").nullable(),
        email: Yup.string("Enter a email").required("Email is required").email("Invalid email"),
        techDepartment: Yup.string("Please select Sektion").required("Department is required"),
        workingRole: Yup.string("Please select Rolle").required("Working Role is required"),
        techArea: Yup.string("Please select Disponent").required("Disponent is required"),
        firma: Yup.string("Enter a firma").required("Firma is required"),
    });

    const mutationCreateStaff = useMutation((staff) => createStaff(staff), {
        onSuccess: (data, variables) => {
            if (data) {
                queryClient.invalidateQueries("staffs");
                history.push("/staff");
            }
        },
    });

    const formSubmit = (data) => {
        const staffData = {
            userId: "",
            imageUrl: data.imageUrl,
            firstName: data.firstName,
            lastName: data.lastName,
            techDepartment: data.techDepartment,
            techArea: data.techArea,
            email: data.email,
            firma: data.firma,
            workingRole: data.workingRole,
            authorizeRole: 0,
        };
        mutationCreateStaff.mutate(staffData);
    };

    return (
        <Formik
            initialValues={{}}
            validationSchema={validationSchema}
            onSubmit={formSubmit}>
            {(props) => <StaffForm history={history} {...props} />}
        </Formik>
    );
};

export default connect(null, { createStaff })(NewStaff);
