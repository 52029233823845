import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { UserSlot } from './';

const classNames = mergeStyleSets({
    root: {
        // height: 40,
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        marginBottom: 7
    },
});

const UserRow = ({length, horizontalPositionMap, users = [], colorCode, role, isMachine = false}) => {
    return (
        <div className={classNames.root} >
            {length && horizontalPositionMap['end'] && 
                <div  style={{width: `${length * horizontalPositionMap['end']}px`, overflow: 'hidden'}}>
                    {Array.isArray(users) && users.length > 0 && users.map((data, index) => (
                        <div style={{position: 'relative', width: '100%', height: 20, marginBottom: 19}}  key={`${index}-${data['userId'] || data['machineId']}`}>
                            <UserSlot isMachine={isMachine} role={role} colorCode={colorCode} index={index} length={length} data={data} horizontalPositionMap={horizontalPositionMap} />
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default UserRow;
