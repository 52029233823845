import { mergeStyleSets } from "@fluentui/react/lib/Styling";

const commonStationSelectorButton = {
    height: 20,
    borderRadius: 24,
    color: "#ffffff",
    background: "#006CAD",
    padding: "4px 16px",
    fontSize: 14,
    lineHeight: 20,
    margin: "8px 0px",
    cursor: "pointer",
    selectors: {
        ":hover": {
            color: "#DBDBDB",
        },
    },
};

export const classNames = mergeStyleSets({
    root: {
        marginTop: 15,
    },
    techDepartmentSection: {
        display: "flex",
        justifyContent: "flex-start",
    },
    button: [
        commonStationSelectorButton,
        {
            color: "#868685",
            background: "#DFDFDF",
            selectors: {
                ":hover": {
                    color: "#DFDFDF",
                    background: "#868685",
                },
            },
        },
    ],
    buttonSelected: commonStationSelectorButton,
    image: {
        marginTop: 2,
    },
    icon: {
        fontSize: 16,
    },
});