import React from 'react';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import moment from 'moment';
import 'moment/locale/da';
import { SOURCE_API } from '../../../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";
import { DeleteConfirmation } from '../../../../common';

const personaStyle = {
    primaryText: {
        fontWeight: '700',
    }
}

const classNames = mergeStyleSets({
    removeIcon: {
        fontSize: 16,
    },
    removeContainer: {
        flexBasis:'10%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        selectors: {
            ":hover": {
                backgroundColor: 'rgb(232, 232, 232)'
            },
        },
    }
})

const PersonaItem = ({ id, userName = 'Unknown', imageUrl, hasDelete = true, deleteComment, createdDate}) => {
    const history = createdDate ? moment(createdDate).format('MM.DD.YY HH:mm') : null;
    const [deleteConfirmation, { toggle: toggleDeleteConfirmation }] = useBoolean(false);
    const onClickConfirmHandler = () => {
        deleteComment(id)
        toggleDeleteConfirmation();
    }
    return (
        <div style={{display: 'flex', marginBottom: 10}}>
            <div style={{flexBasis:'90%'}}>
                <Persona
                    text={userName}
                    secondaryText={history}
                    size={PersonaSize.size40}
                    styles={personaStyle}
                    imageUrl={imageUrl ? `${SOURCE_API}${imageUrl}` : ''}
                />
            </div>
            {/* {hasDelete && <div className={classNames.removeContainer} id={`deleteCommentButton-${id}`} onClick={toggleDeleteConfirmation} >
                <FontAwesomeIcon className={classNames.removeIcon} icon={faTrashAlt} />
            </div>}
            {deleteConfirmation && (
                <DeleteConfirmation target={`#deleteCommentButton-${id}`} toggleTeaching={toggleDeleteConfirmation} onClickConfirm={onClickConfirmHandler}/>
            )} */}
        </div>
    )
};

export default PersonaItem;