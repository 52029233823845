// Import types
import { TMachine, User } from "../types";

export type TStaffServiceSchedule = {
  machineId: string;
  machineName: string;
  start: string;
  end: string;
  reason: string;
  comment: string;
};

export const getCrewServiceSchedule = (
  userId: string,
  machines: TMachine[],
  val?: {
    ignoreServiceIndex: number;
    ignoreMachineId: string;
  }
): TStaffServiceSchedule[] => {
  const arr: TStaffServiceSchedule[] = [];

  machines.forEach(({ machineId, machineName, machineService }) => {
    (machineService || []).forEach((service, serviceIndex) => {
      const crew = service.crews.find((crew) => crew.id === userId);
      const { reason, comment } = service;
      if (crew) {
        const { start, end } = crew;

        if (
          !(
            val?.ignoreServiceIndex === serviceIndex &&
            val?.ignoreMachineId === machineId
          )
        ) {
          arr.push({
            end,
            start,
            machineId,
            machineName,
            reason,
            comment,
          });
        }
      }
    });
  });

  return arr;
};
