import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTeams } from '../../../redux/machines/machines.actions';
import { changeSectionFilter } from '../../../redux/user/user.actions';
import { techDepartmentOptions } from '../../../utils/constants';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Stack, PrimaryButton } from '@fluentui/react';
import { headerRightButtonStyles, primaryButtonStyles } from '../../../utils/theme';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { copyAndSort, hasEditRight } from '../../../utils/utils';
import { MachineTeamsTableCrewCell, MachineTeamControls, MachineTableTechDepartmentInfoCell } from './';

const commonStationSelectorButton = {
    height: 20,
    borderRadius: 24,
    color: '#ffffff',
    background: '#006CAD',
    padding: '4px 16px',
    fontSize: 14,
    lineHeight: 20,
    margin: '8px 4px',
    cursor: 'pointer',
    selectors: {
        ':hover': {
            color: '#DBDBDB',
        },
    },
};

const NOT_SORTING_FIELDS = ['action', 'equipment'];

const WrapperText = ({ inactive, children }) => <div style={{ color: inactive ? '#868685' : '#000' }}>{children}</div>;

const MachineTeamsTable = ({ getTeams, techDepartmentDefaultFilter, selectedTechDepartmentFilter, changeSectionFilter, scrollY }) => {
    const classNames = mergeStyleSets({
        alignItemRow: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        button: [
            commonStationSelectorButton,
            {
                color: '#868685',
                background: '#DFDFDF',
                selectors: {
                    ':hover': {
                        color: '#DFDFDF',
                        background: '#868685',
                    },
                },
            },
        ],
        buttonSelected: commonStationSelectorButton,
    });
    const realPageWidth = document.documentElement.offsetWidth;
    const userRoles = useSelector((state) => state?.user?.user?.workingRole);
    const allowEdit = hasEditRight(userRoles);
    const { data: teamsData } = useQuery('teams', () => getTeams(), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            data &&
                setListData(
                    data
                        .reverse((a, b) => (!a.inactive && b.inactive ? -1 : 0))
                        .filter((machine) => techDepartmentFilter.some((role) => role.isOn && machine.techDepartments.includes(role.key)))
                );
        },
    });
    const [listData, setListData] = useState(teamsData || []);
    const techDepartmentFilter = selectedTechDepartmentFilter
        ? techDepartmentOptions.map((item) => ({ ...item, isOn: selectedTechDepartmentFilter.includes(item.key) }))
        : techDepartmentOptions.map((item) => ({ ...item, isOn: item.key === techDepartmentDefaultFilter }));
    const techDepartmentFilterRef = useRef(null);
    if (!techDepartmentFilterRef.current || JSON.stringify(techDepartmentFilter) !== JSON.stringify(techDepartmentFilterRef.current)) {
        techDepartmentFilterRef.current = techDepartmentFilter;
    }

    const onChangeSectionFilter = (key) => {
        const tempArray = techDepartmentFilter.filter((item) => item.isOn).map((item) => item.key);
        tempArray.indexOf(key) === -1 ? tempArray.push(key) : tempArray.splice(tempArray.indexOf(key), 1);
        changeSectionFilter(tempArray);
    };

    useEffect(() => {
        if (teamsData) {
            const newListDisplay = teamsData.filter((machine) => techDepartmentFilter.some((role) => role.isOn && machine.techDepartments.includes(role.key)));
            setListData(newListDisplay);
        }
    }, [teamsData,techDepartmentFilter]);

    const history = useHistory();

    const onClickSorting = (e, column) => {
        if (NOT_SORTING_FIELDS.includes(column.fieldName)) {
            return;
        }
        let isSortedDescending = column.isSortedDescending;
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedItems = copyAndSort(listData, column.fieldName, isSortedDescending);
        setListData(sortedItems);
        setColumns((prevColumns) =>
            prevColumns.map((col) => {
                col.isSorted = col.key === column.key;
                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }
                return col;
            })
        );
    };

    const renderItemColumn = (item, index, column) => {
        const fieldContent = item[column.fieldName || ''];
        switch (column.key) {
            case 'team':
                return <MachineTeamsTableCrewCell drivers={item.drivers} workers={item.workers} managers={item.managers} teamId={item.id} history={history} />;
            case 'action':
                return <MachineTeamControls index={index} team={item} machineId={item.machineId} history={history} />;

            case 'techDepartments':
                return <MachineTableTechDepartmentInfoCell techDepartments={item.techDepartments} />;
            default:
                return (
                    <div className={classNames.alignItemRow}>
                        <WrapperText inactive={item.inactive}>{fieldContent}</WrapperText>
                    </div>
                );
        }
    };

    const initColumns = [
        {
            data: 'number',
            fieldName: 'machineNo',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            isSortedDescending: false,
            key: 'machineNo',
            maxWidth: 128,
            minWidth: 128,
            name: 'Machine Number',
        },
        {
            data: 'string',
            fieldName: 'machineName',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            isSortedDescending: false,
            key: 'machineName',
            maxWidth: 250,
            minWidth: 210,
            name: 'Machine',
        },
        {
            data: 'string',
            fieldName: 'name',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            isSortedDescending: false,
            key: 'name',
            maxWidth: 250,
            minWidth: 210,
            name: 'Team Name',
        },
        {
            data: 'string',
            fieldName: 'techDepartments',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            isSortedDescending: false,
            key: 'techDepartments',
            maxWidth: 250,
            minWidth: 210,
            name: 'Fagsektion',
        },
        {
            data: 'number',
            fieldName: 'team',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            key: 'team',
            maxWidth: 100,
            minWidth: 100,
            name: 'Team',
        },
    ];

    if (allowEdit)
        initColumns.push({
            data: 'number',
            fieldName: 'action',
            isPadded: true,
            isResizable: true,
            isRowHeader: true,
            key: 'action',
            maxWidth: 30,
            minWidth: 30,
            name: 'Action',
        });

    const [columns, setColumns] = useState(initColumns);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', width: 'calc(100% - 150px)' }}>
                <Stack horizontal='true' tokens={{ childrenGap: 8 }}>
                    {techDepartmentFilter.map((option, index) => (
                        <div
                            key={option['key']}
                            className={option.isOn ? classNames.buttonSelected : classNames.button}
                            onClick={() => {
                                onChangeSectionFilter(option['key']);
                            }}
                        >
                            {option['text']}
                        </div>
                    ))}
                </Stack>
                {allowEdit && (
                    <div style={headerRightButtonStyles(realPageWidth, scrollY)}>
                        <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 20 }} className={classNames.buttonStack}>
                            <PrimaryButton
                                text='OPRET NY'
                                onClick={() => {
                                    history.push('/new-team');
                                }}
                                styles={primaryButtonStyles}
                            />
                        </Stack>
                    </div>
                )}
            </div>
            <DetailsList
                items={listData && Array.isArray(listData) ? listData : []}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey='none'
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderItemColumn={renderItemColumn}
                onColumnHeaderClick={onClickSorting}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    defaultData: state.defaultData,
    techDepartmentDefaultFilter: state?.user?.user?.defaultFilter?.techDepartmentFilter,
    selectedTechDepartmentFilter: state?.user?.user?.techDepartmentFilter,
});

export default connect(mapStateToProps, { getTeams, changeSectionFilter })(MachineTeamsTable);
