import React, { Fragment } from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-regular-svg-icons';

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 85,
    },
    icon: {
        fontSize: 24,
        color: 'rgb(0, 108, 173)',
        cursor: 'pointer'
    },
});

const MachineTableInfoCell = ({history, machineId, machineDocumentUrl}) => {
    if(!machineDocumentUrl || machineDocumentUrl.length < 1) {
        return null;
    }
    return (
        <Fragment>
            <div className={classNames.container}>
                <FontAwesomeIcon onClick={() => history.push(`/machine-document/${machineId}`)} icon={faFile} className={classNames.icon}/>
            </div>
        </Fragment>
    )
}

export default MachineTableInfoCell;
