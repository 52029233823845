import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentUnplannedItem,
  setDarkOverlay,
  setPlanPlaceholderData,
} from "../../redux/app/app.actions";

const DarkOverlay = () => {
  const dispatch = useDispatch();
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const userZoomRatio = 1;
  const { currentUnplannedItem, planPlaceholder } = useSelector(
    (state) => state.app
  );

  const classNames = mergeStyleSets({
    overlay: {
      position: "fixed",
      height: `${100 / userZoomRatio}vh`,
      width: `${100 / userZoomRatio}vw`,
      background: "rgb(42,42,42,0.5)",
      top: 0,
      zIndex: 100001,
    },
    closeIcon: {
      position: "fixed",
      top: "20px",
      right: "20px",
      fontSize: "40px",
      color: "#ff0000",
      cursor: "pointer",
    },
  });

  useEffect(() => {
    window.addEventListener("keydown", handleEscClick, { passive: true });
    return () => {
      window.removeEventListener("keydown", handleEscClick);
    };
  });

  const handleEscClick = ({ key }) => {
    if (key === "Escape") closeDarkOverlay();
  };

  const closeDarkOverlay = () => {
    dispatch(setDarkOverlay(false));
    if (currentUnplannedItem && planPlaceholder.target) {
      const { id, index } = planPlaceholder.target;
      const { x, y } = currentUnplannedItem;
      const elementNode = document.getElementById(`${id}-${index}`);
      elementNode.style.transform = `translate(${x}px, ${y}px)`;
      elementNode.style.opacity = 0;
      dispatch(setCurrentUnplannedItem(null));
    }
    dispatch(setPlanPlaceholderData({}));
  };

  return ReactDOM.createPortal(
    <div className={classNames.overlay}>
      <FontAwesomeIcon
        icon={faTimes}
        className={classNames.closeIcon}
        onClick={closeDarkOverlay}
      />
    </div>,
    document.querySelector("#darkOverlay")
  );
};

export default DarkOverlay;
