// Import libraries
import { Fragment } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { useBoolean } from "@fluentui/react-hooks";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

// Import components
import { DeleteConfirmation } from "../../common";

const classNames = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    fontSize: 22,
    color: "rgb(0, 108, 173)",
    marginRight: 12,
    cursor: "pointer",
  },
});

type MachineRequirementsControlsProps = {
  index: number;
  remove: (index: number) => void;
};

const RequiredSkillControl = ({
  index,
  remove,
}: MachineRequirementsControlsProps) => {
  const id = `removeRequiredSkill-${index}`;

  const [deleteConfirmation, { toggle: toggleDeleteConfirmation }] =
    useBoolean(false);

  const onClickConfirm = () => {
    toggleDeleteConfirmation();
    remove(index);
  };

  return (
    <Fragment>
      <div className={classNames.container}>
        <FontAwesomeIcon
          id={`${id}`}
          icon={faTrashAlt as IconDefinition}
          className={classNames.icon}
          onClick={toggleDeleteConfirmation}
        />
      </div>
      {deleteConfirmation && (
        <DeleteConfirmation
          target={`#${id}`}
          toggleTeaching={toggleDeleteConfirmation}
          onClickConfirm={onClickConfirm}
        />
      )}
    </Fragment>
  );
};

export default RequiredSkillControl;
