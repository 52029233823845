import axios from "axios";
import { UserActionTypes } from "./user.types";
import { saveMessage } from "../message/message.actions";
import setDefaults from "../../utils/setDefaults";
import { MessageBarType } from "@fluentui/react";
import { SOURCE_API } from "../../utils/constants";
import { persistor } from "../store";
import { setSyncLastCall, setSyncProcess } from "../app/app.actions";
import { differenceInMinutes } from "date-fns";
import { sleep } from "../../utils/utils";
import { User } from "../../types";

//Get all staffs
export const getStaffs = () => async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get<any, { data: { data: User[] } }>(
      `${SOURCE_API}/api/User/getall`
    );

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

//Get all users who missing info
export const getUsersMissingInfo = () => async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/User/getallmissinginfo`
    );

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getStaff = (userIds: string[]) => async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/User/get?ids=${userIds.join(",")}`
    );

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const createStaff = (staffData: User) => async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.post(`${SOURCE_API}/api/User`, staffData);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    dispatch(
      saveMessage("Staff successfully created!", MessageBarType.success)
    );

    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));

    // @ts-ignore
    if (error?.response?.status === 401) {
      dispatch(
        saveMessage(
          "The user session has expired, please login again.",
          MessageBarType.warning
        )
      );
    } else {
      // @ts-ignore
      dispatch(saveMessage(error.message));
    }
  }
};

export const syncUser = () => async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.post(`${SOURCE_API}/api/User/SyncADUsers`);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    dispatch(
      saveMessage("Synchronization is completed!", MessageBarType.success)
    );

    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const updateStaff =
  (staffData: User, alert = true) =>
  async (dispatch: any) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.put(`${SOURCE_API}/api/User`, staffData);

      if (!response.data.succeeded && response.data.message) {
        throw new Error(response.data.message);
      }

      alert &&
        dispatch(
          saveMessage("Staff successfully updated!", MessageBarType.success)
        );

      return response.data.succeeded;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };

export const deleteStaff = (staffId: string) => async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token"));
    const response = await axios.delete(`${SOURCE_API}/api/User?id=${staffId}`);

    if (response.data.succeeded === true) {
      dispatch(saveMessage("Staff deleted", MessageBarType.success));
    } else {
      dispatch(saveMessage("There was an error"));
    }

    return true;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

// Login User
export const login = (accessToken: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading());
    setDefaults(null);
    axios.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;

    const response = await axios.post(`${SOURCE_API}/Auth/loginandsync`);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    const payload = {
      user: response.data.data.identityModel,
      token: "Bearer " + response.data.data.token,
    };

    if (response.data.data.identityModel.workingRole === "-1") {
      dispatch(
        saveMessage(
          "Your account has no role, please contact your manager to be assigned a role!",
          MessageBarType.warning
        )
      );
    }

    dispatch({
      type: UserActionTypes.LOGIN_SUCCESS,
      payload: payload,
    });
  } catch (error) {
    console.error(error);
    // @ts-ignore
    console.error(error.message);
    // @ts-ignore
    dispatch(saveMessage(error.message));
    dispatch({ type: UserActionTypes.LOGIN_FAIL });
  }
};

export const loginTeams = (accessToken: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading());
    setDefaults(null);

    axios.defaults.headers.common["App-Context"] = "MainApp";
    axios.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;

    const response = await axios.post(
      `${SOURCE_API}/Auth/loginteamsandsync`,
      null,
      {
        headers: {
          "App-Context": "MainApp",
        },
      }
    );
    // const response = await axios.post(
    //   `https://localhost:5001/Auth/loginteamsandsync`
    // );

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    const payload = {
      user: response.data.data.identityModel,
      token: "Bearer " + response.data.data.token,
    };

    if (response.data.data.identityModel.workingRole === "-1") {
      dispatch(
        saveMessage(
          "Your account has no role, please contact your manager to be assigned a role!",
          MessageBarType.warning
        )
      );
    }

    dispatch({
      type: UserActionTypes.LOGIN_SUCCESS,
      payload: payload,
    });
  } catch (error) {
    // @ts-ignore
    dispatch(saveMessage(error.message));
    dispatch({ type: UserActionTypes.LOGIN_FAIL });
  }
};

// Logout User
export const logout = () => async (dispatch: any) => {
  dispatch({ type: UserActionTypes.LOGOUT_SUCCESS });
  localStorage.clear();
  sessionStorage.clear();
  setTimeout(() => persistor.purge(), 200);
};

// Set loading to true
export const setLoading = () => {
  return { type: UserActionTypes.USER_LOADING };
};

export const checkTokenValidity = (error: any) => (dispatch: any) => {
  if (
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    localStorage.clear();
    sessionStorage.clear();
    dispatch({ type: UserActionTypes.TOKEN_EXPIRED });
    console.log(JSON.stringify(error));
    // dispatch(saveMessage(JSON.stringify(error)));
    dispatch(saveMessage("The user session has expired, please login again."));
    setTimeout(() => persistor.purge(), 200);
  } else {
    if (error.message !== "Network Error") dispatch(saveMessage(error.message));
  }
};

export const updateSettingFilter = (settingData: any) => (dispatch: any) => {
  console.log("Test1");
  dispatch({
    type: UserActionTypes.UPDATE_SETTING_FILTER,
    payload: settingData,
  });
};

export const changeSectionFilter = (sectionArray: any) => (dispatch: any) => {
  dispatch({
    type: UserActionTypes.CHANGE_SECTION_FILTER,
    payload: sectionArray,
  });
};

export const syncCRM = (syncSuccessCallback: any) => async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token"));
    dispatch(setSyncProcess("syncing"));
    const response = await axios.post(`${SOURCE_API}/CRM`);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    if (syncSuccessCallback) {
      syncSuccessCallback();
    }

    await sleep(2000);

    dispatch(setSyncProcess(null));
    dispatch(
      saveMessage("Synchronization is completed!", MessageBarType.success)
    );

    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getLastCRMCall = () => async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(`${SOURCE_API}/CRM/lastsync`);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    // Incase there has not been a crm sync recored in db
    if (response.data.data) {
      const date = new Date();

      const syncCallDate = new Date(response.data.data);

      dispatch(
        setSyncLastCall(
          `${differenceInMinutes(date, syncCallDate)} minutes ago`
        )
      );
    } else {
      dispatch(setSyncLastCall(""));
    }

    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

// api/user/lastsync
export const getLastSync = async () => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(`${SOURCE_API}/api/user/lastsync`);

    if (!response.data.succeeded && response.data.message) {
      throw new Error(response.data.message);
    }

    if (response.data.succeeded) {
      return differenceInMinutes(new Date(), new Date(response.data.data));
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const setAutoLogin = (value: any) => {
  return { type: UserActionTypes.SET_AUTO_LOGIN, payload: value };
};
