// Import libraries
import { Persona, PersonaSize, mergeStyleSets } from "@fluentui/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBoolean } from "@fluentui/react-hooks";
import {
  faPencil,
  // faPencil,
  faPlusCircle,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";

// Import types
import { User } from "../../../types";
import { AllMachineData, AllUserData } from "../../../types/custom";

// Import redux
import { OverlapKeys } from "../../../redux/project/project.actions";

// Import components
import PopupStaff from "./PopupStaff";
import { Fragment, useState } from "react";
import { SOURCE_API } from "../../../utils/constants";
import { DeleteConfirmation } from "../../common";
import FixPositionBubble from "../../common/FixPositionBubble";
import { TMachineServiceDrawData } from "../MachineService";

const coinProps = {
  styles: {
    image: {
      margin: 0,
      width: 32,
      height: 32,
    },
  },
};

const classNames = mergeStyleSets({
  sideBarContainer: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 110,
    // backgroundColor: "transparent",
  },
  sideBarHeader: {
    display: "flex",
    marginLeft: 65,
    // marginTop: 12,
    height: 32,
    alignItems: "center",
  },
  image: {
    marginRight: "15px",
    fontSize: 16,
  },
  sideBarRightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    minWidth: 100,
    marginRight: 10,
    marginLeft: -20,
    gap: 8,
  },
  titleNameElementStyle: {
    fontWeight: "bold",
    marginRight: "16px",
  },
  sidebarIconContainer: {
    width: 100,
    display: "flex",
    alignItems: "center",
    position: "relative",
    // top: 5,
    justifyContent: "end",
  },
  iconPlus: {
    fontSize: 32,
    height: 32,
    color: "#006CAD",
    selectors: {
      ":hover": {
        color: "#DBDBDB",
      },
    },
    cursor: "pointer",
  },
  iconPlusCircle: {
    // marginTop: 10,
    // marginLeft: 20,
  },
  functionalIcon: {
    fontSize: 18,
    height: 16,
    color: "#006CAD",
    selectors: {
      ":hover": {
        color: "#DBDBDB",
      },
    },
    cursor: "pointer",
    marginRight: "10px",
  },
  personaStyle: {
    width: 32,
    height: 32,
    // marginRight: "10px",
  },
});

type SectionProps = {
  sectionTitle: string;
  icon: IconDefinition;
  users: {
    otherSectionStaffs: AllUserData[];
    users: AllUserData[];
  };
  allowEdit: boolean;
  sectionId: string;
  propName: OverlapKeys;
  workers: AllUserData[];
  // staffsData: User[];
  machineData?: AllMachineData | null;
  onAdd: (user: AllUserData, role: OverlapKeys) => void;
  start: string;
  end: string;
  onClickRemove: (user: User, role: OverlapKeys) => void;
  onClickDraw?: (val: TMachineServiceDrawData) => void;
  isDrawingNew?: boolean;
};

const Section = ({
  icon,
  sectionTitle,
  users,
  allowEdit,
  sectionId,
  machineData,
  workers,
  propName,
  onAdd,
  end,
  start,
  onClickRemove,
  onClickDraw,
  isDrawingNew = false,
}: SectionProps) => {
  const [showAddEmployee, { toggle: toggleShowAddEmployee }] =
    useBoolean(false);
  const [hoverData, setHoverData] = useState<{
    text: string;
    boldText: string;
    hoverId: string;
  } | null>(null);

  const [targetDelete, setTargetDelete] = useState<{
    targetId: string;
    user: User;
  } | null>(null);

  return (
    <>
      <div className={classNames.sideBarContainer}>
        <div className={classNames.sideBarHeader}>
          <FontAwesomeIcon icon={icon} className={classNames.image} />
          <div className={classNames.titleNameElementStyle}>{sectionTitle}</div>
        </div>
        <div className={classNames.sideBarRightContainer}>
          {workers &&
            workers.map((worker, index) => (
              <div key={worker.staffData.userId}>
                <Fragment key={`${index}${worker.staffData.userId}`}>
                  {
                    <div className={classNames.sidebarIconContainer}>
                      {allowEdit && (
                        <FontAwesomeIcon
                          onClick={() => {
                            setTargetDelete({
                              targetId: `#delete-${sectionId}-${index}`,
                              user: worker.staffData,
                            });
                          }}
                          id={`delete-${sectionId}-${index}`}
                          icon={faTimes as IconDefinition}
                          className={classNames.functionalIcon}
                        />
                      )}
                      <Persona
                        imageUrl={
                          worker.staffData.imageUrl
                            ? `${SOURCE_API}${worker.staffData.imageUrl}`
                            : ""
                        }
                        size={PersonaSize.size32}
                        coinProps={coinProps}
                        className={classNames.personaStyle}
                        onMouseOut={() => {
                          setHoverData(null);
                        }}
                        onMouseOver={() => {
                          setHoverData({
                            text:
                              worker.staffData.firstName +
                              " " +
                              (worker.staffData.lastName || ""),
                            boldText: machineData?.machineName || "",
                            hoverId: `hover-${sectionId}-${index}`,
                          });
                        }}
                        id={`hover-${sectionId}-${index}`}
                      />
                      {allowEdit && (
                        <FontAwesomeIcon
                          style={{
                            marginRight: "5px",
                            marginLeft: "10px",
                            visibility: !isDrawingNew ? "unset" : "hidden",
                          }}
                          icon={faPencil as IconDefinition}
                          className={classNames.functionalIcon}
                          onClick={() => {
                            onClickDraw &&
                              onClickDraw({
                                id: worker.id,
                                role: propName,
                              });
                          }}
                        />
                      )}
                    </div>
                  }
                </Fragment>
              </div>
            ))}
          {allowEdit && (
            <div className={classNames.iconPlusCircle}>
              <FontAwesomeIcon
                icon={faPlusCircle as IconDefinition}
                id={sectionId}
                className={classNames.iconPlus}
                onClick={toggleShowAddEmployee}
              />
            </div>
          )}
        </div>
      </div>
      {showAddEmployee ? (
        <PopupStaff
          propName={propName}
          staffsAllData={[]}
          users={users}
          targetId={sectionId}
          onDismiss={() => {
            toggleShowAddEmployee();
          }}
          machineData={machineData}
          onAdd={onAdd}
          end={end}
          start={start}
          onClickDraw={(id) => {
            onClickDraw &&
              onClickDraw({
                role: propName,
                id,
                isNew: true,
              });

            toggleShowAddEmployee();
          }}
          isDrawingNew={isDrawingNew}
        />
      ) : (
        <></>
      )}
      {targetDelete ? (
        <DeleteConfirmation
          target={targetDelete.targetId}
          toggleTeaching={() => {
            setTargetDelete(null);
          }}
          onClickConfirm={() => {
            onClickRemove(targetDelete.user, propName);
            setTargetDelete(null);
          }}
        />
      ) : (
        <></>
      )}
      {hoverData ? (
        // @ts-ignore
        <FixPositionBubble
          target={"#" + hoverData.hoverId}
          onDismiss={() => {
            setHoverData(null);
          }}
          // closeButtonAriaLabel="Close"
        >
          <span>
            {hoverData.text + " "}
            <strong>
              {hoverData.boldText ? "(" + hoverData.boldText + ")" : ""}
            </strong>
          </span>
        </FixPositionBubble>
      ) : (
        <></>
      )}
    </>
  );
};

export default Section;
