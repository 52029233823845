// Import libraries
import { useEffect, useMemo, useState } from "react";

// Import constants
import { COLOR_LIST } from "../utils/constants";

type TMapType = { [k: string]: string };

const useGetColorList = (list: string[]) => {
  const [cachedList, setCachedList] = useState<string[]>([]);

  useEffect(() => {
    if (list.length !== cachedList.length) {
      setCachedList(list);
    } else {
      let foundDiff = false;

      const { length } = list;

      for (let i = 0; i < length && foundDiff === false; i++) {
        if (!cachedList.includes(list[i])) {
          foundDiff = true;
        }
      }
    }
  }, [list, cachedList]);

  const map = useMemo<TMapType>(() => {
    const mapType: TMapType = {};

    cachedList.forEach((el, index) => {
      mapType[el] = COLOR_LIST[index];
    });

    return mapType;
  }, [cachedList]);

  return map;
};

export default useGetColorList;
