import React, { Fragment } from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import MapAddressComponent from '../../../common/MapAddressComponent';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react/lib/ScrollablePane';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faLink, faPalette, faQrcode,
} from '@fortawesome/pro-regular-svg-icons';
import { crmLink, openInNewTab, updateProjectData } from '../../../../utils/utils';
import { getMachineDocumentLink } from '../../../../redux/machines/machines.utils';
import { 
    TeachingBubble,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ColorPickerComponent, 
} from '../../../common';
import { useMutation, useQueryClient } from 'react-query';
import { updateProject } from '../../../../redux/project/project.actions';
import { useDispatch, useSelector } from 'react-redux';

const classNames = mergeStyleSets({
    projectNameSection: {
        height: 144,
        width: '100%',
        borderRadius: '10px',
        marginBottom: 16,
    },
    responsibleAndDescriptionSection: {
        height: 144,
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#fff',
        marginBottom: 16,
    },
    locationSection: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#fff',
    },
    icon: {
        fontSize: 16,
    },
    iconClickable: {
        fontSize: 16,
        cursor: 'pointer',
    },
    projectNameContainer: {
        fontSize: 20,
        fontWeight: 700,
        cursor: 'pointer',
    },
});

const ProjectDetails = ({ refetchProject, projectData, planData, overlayPlanIndex, clearPlanOverlayProjectId }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const defaultData = useSelector((state) => state.defaultData);
    const currentUserId = useSelector((state) => state?.user?.user?.userId);
    const [colorPickerVisible, { toggle: togglePickerVisible }] = useBoolean(false);
    const { geoCoordinate } = projectData;
    const onClickToMachineRequirementPage = () => {
        history.push(`/add-machine-requirements/${projectData.projectId}`);
        clearPlanOverlayProjectId();
    };
    const machineData = planData?.machineRequirements[overlayPlanIndex]?.machines[0];
    const mutationUpdateProject = useMutation((project) => dispatch(updateProject(project)), {
        onSuccess: (data) => {
            queryClient.invalidateQueries('projects');
            queryClient.invalidateQueries('plans');
            refetchProject();
        },
    });
    const updateNewProjectData = (key, value) => {
        const updatedProjectData = updateProjectData(projectData, { ...projectData, [key]: value }, defaultData, currentUserId);
        mutationUpdateProject.mutate(updatedProjectData);
    };
    
    return (
        <Fragment>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div className={classNames.projectNameSection} style={{ backgroundColor: `rgb(${projectData.color})` }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                padding: 16,
                                color: '#fff',
                                height: 114,
                                maxWidth: '80%',
                            }}
                        >
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 14, fontWeight: 400 }}>{projectData.hovedsagsNummer}</div>
                                    <div>{projectData.isQuotation && <FontAwesomeIcon icon={faFileInvoiceDollar} className={classNames.icon} />}</div>
                                </div>
                                <div className={classNames.projectNameContainer} onClick={onClickToMachineRequirementPage}>
                                    {projectData.projectName}
                                </div>
                                <div style={{ fontSize: 14, fontWeight: 400, marginTop: 5 }}>{projectData.projectNo}</div>
                            </div>
                            <div style={{ fontSize: 12, textTransform: 'uppercase' }}>
                                {machineData?.machineName ? machineData?.machineName : 'Unknown Machine'}
                                <FontAwesomeIcon
                                    icon={faQrcode}
                                    className={classNames.iconClickable}
                                    style={{ marginLeft: 10 }}
                                    onClick={() => openInNewTab(getMachineDocumentLink(machineData?.machineNo))}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: 16, color: '#fff', height: 114 }}>
                            {
                                projectData?.crmProjectId && <a 
                                        style={{
                                            textDecoration: 'none',
                                            color: 'white'
                                        }} 
                                        href={crmLink(projectData.crmProjectId,projectData.category)} 
                                        rel="noreferrer"  
                                        target="_blank"
                                >
                                    <FontAwesomeIcon icon={faLink} onClick={() => openInNewTab(crmLink(projectData.crmProjectId,projectData.category))} className={classNames.iconClickable} />
                                </a>
                            }            
                            <FontAwesomeIcon id='colorInput' onClick={togglePickerVisible} icon={faPalette} className={classNames.iconClickable} />
                        </div>
                    </div>
                </div>
                <div className={classNames.responsibleAndDescriptionSection}>
                    <div style={{ padding: 16, color: '#000', height: 112 }}>
                        <div style={{ position: 'relative', height: '100%' }}>
                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ marginBottom: 10 }}>
                                        <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 4 }}>Sagsansvarlig</div>
                                        <div style={{ fontSize: 14, fontWeight: 400 }}>
                                            {projectData?.personResponsbible?.name ? projectData?.personResponsbible?.name : 'Unknown'}
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 4 }}>Beskrivelse</div>
                                        <div style={{ fontSize: 14, fontWeight: 400, overflowWrap: 'break-word' }}>{projectData.projectDescription}</div>
                                    </div>
                                </div>
                            </ScrollablePane>
                        </div>
                    </div>
                </div>
                <div className={classNames.locationSection}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: 16, color: '#000' }}>
                        <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 16 }}>Location</div>
                        <div style={{ fontSize: 14, marginBottom: 16 }}>{`Adresse: ${projectData.address || 'No address found!'}`}</div>
                        <div style={{ fontSize: 14, marginBottom: 16 }}>{`Postnummer: ${projectData.postNummer || 'Unknown'}`}</div>
                        <div style={{ fontSize: 14, marginBottom: 16 }}>{`By: ${projectData.projectBy || 'Unknown'}`}</div>
                        <div>
                            <MapAddressComponent height={225} width={'100%'} marker={{ lat: geoCoordinate?.latitude, lng: geoCoordinate?.longitude }} />
                        </div>
                    </div>
                </div>
            </div>
            {colorPickerVisible && (
                <TeachingBubble
                    target='#colorInput'
                    onDismiss={togglePickerVisible}
                    styles={{
                        content: {
                            background: 'white',
                        },
                    }}
                >
                    <ColorPickerComponent
                        selectColor={(value) => updateNewProjectData('color', value)}
                        color={projectData.color}
                        togglePickerVisible={togglePickerVisible}
                    />
                </TeachingBubble>
            )}
        </Fragment>
    );
};

export default ProjectDetails;
