/* eslint-disable no-unused-expressions */
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { datesGenerator } from "dates-generator";
import moment from "moment";
import "moment/locale/da";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useBodyClass } from "../../../hooks";
import { getHolidayList } from "../../../redux/app/app.actions";
import {
  getMachines,
  selectNewDate,
} from "../../../redux/machines/machines.actions";
import { getPlans } from "../../../redux/plan/plan.actions";
import { getStaffs } from "../../../redux/user/user.actions";
import {
  CALENDAR_PAGE,
  CALENDAR_STAFFVIEW_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  months,
} from "../../../utils/constants";
import {
  calculateFinalDates,
  findYearOfDates,
  getCalendarHoliday,
  getLastDate,
  hasEditRight,
  removeFirstWeek,
  startCalendarFromMonth,
  startCalendarFromSelectedWeek,
} from "../../../utils/utils";
import { ItemContainer } from "../../common";
import {
  CalendarControlsLeft,
  CalendarControlsRight,
  DayLabel,
  MonthLabel,
  WeekLabel,
} from "../../common/calendar";
import ScreenSizeAndPosition from "../../common/ScreenSizeAndPosition";
import StaffVacationBubble from "./StaffVacationBubble";
import StaffVacationItemForStaffCalendar from "./StaffVacationItemForStaffCalendar";
import { getCrewServiceSchedule } from "../../../utils/service";
import StaffMachineServiceItem from "./StaffMachineServiceItem";
import { getServiceText } from "../../../utils/mapping";
import StaffMachineServiceItemForStaffCalendar from "./StaffMachineServiceItemForStaffCalendar";
import DispositionStickyControl from "../DispositionStickyControl";

// https://dev.to/aibrahim3546/creating-a-custom-calendar-in-react-from-scratch-1hej
// https://gist.github.com/aibrahim3546/dc68f9525f0f206ff08b1d09ee3adab5
const StaffCalendar = () => {
  const { displayWeekView, selectedDayOnCalendar } = useSelector(
    (state) => state.machine
  );
  const showSidebar = useSelector((state) => state.app.showSidebar);
  const classNames = mergeStyleSets({
    root: {
      minHeight: "100%",
      width: 3650,
      display: "flex",
      flexDirection: "row",
    },
    leftContainer: {
      width: 250,
      position: "sticky",
      zIndex: 1002,
      top: 0,
      left: showSidebar ? 104 : 0,
      background: "rgb(241, 241, 241)",
      transition: "all 0.5s ease",
      marginTop: 74,
    },
    machineAndCalendarContainer: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      justifyContent: "flex-start",
      background: "rgb(241, 241, 241)",
    },
    machineContainer: {
      width: 250,
      minHeight: "100%",
      marginTop: 22,
    },
    calendarContainer: {
      minHeight: "100%",
      width: 300,
    },
    monthText: {
      fontSize: 26,
      fontWeight: "bold",
      textAlign: "center",
    },
    days: {
      display: "flex",
      justifyContent: "flex-start",
    },
    dayOfWeeks: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8,
    },
    weeks: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 4,
    },
    week: {
      minWidth: 175,
      height: 20,
      background: "#E7E7E7",
      fontSize: 11,
      lineHeight: 20,
      borderRadius: 10,
    },
    stickyHeader: {
      // position: -webkit-sticky; /* Safari */
      width: 4890,
      position: "sticky",
      top: 0,
      minWidth: "calc(100vw - 150px)",
      background: "#F1F1F1",
      zIndex: 1001,
    },
  });
  useBodyClass(`home`);
  const dispatch = useDispatch();

  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const { techDepartment } = useSelector((state) => state?.machine?.filter);
  const currentFilter = useSelector(
    (state) => state?.machine?.filter?.[techDepartment]
  );
  const { techArea, staffNames, staffRole } = currentFilter;
  const calendarStartFrom =
    useSelector(
      (state) => state?.user?.user?.defaultFilter.customFilters
    )?.find((item) => item.key === "calendarStartFrom")?.text || "month";

  const [dates, setDates] = useState([]);
  const [calendar, setCalendar] = useState({});
  const [totalHeight, setTotalHeight] = useState("calc(100vh - 305px)");
  const [plansData, setPlansData] = useState([]);
  const [calendarStepWidth, setCalendarStepWidth] = useState(
    displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
  );
  const { data: staffsData } = useQuery("staffs", dispatch(getStaffs));
  const { data: allPlansData } = useQuery("plans", () =>
    dispatch(getPlans(new Date(selectedDayOnCalendar).getFullYear()))
  );
  const { data: machinesData } = useQuery("machines", dispatch(getMachines));

  const holidayRef = useRef(null);

  const filterPlansData = () => {
    const startDate = dates[0][0];
    const endDate = getLastDate(dates);
    const startCalendar = new Date(
      startDate.year,
      startDate.month,
      startDate.date
    );
    const endCalendar = new Date(endDate.year, endDate.month, endDate.date);
    const filteredPlansData = allPlansData.filter(
      (plan) =>
        plan.projectNo &&
        plan.projectName &&
        plan.section &&
        !plan["inactive"] &&
        (plan["machineRequirements"].some((requirement) =>
          requirement.machines.some(
            (machine) =>
              new Date(machine.start) <= endCalendar &&
              new Date(machine.end) >= startCalendar
          )
        ) ||
          plan["machineRequirements"].some(
            (requirement) => !requirement.machines.length
          ))
    );
    return filteredPlansData;
  };

  useEffect(() => {
    if (allPlansData && dates.length) {
      const filteredPlansData = filterPlansData();
      setPlansData(filteredPlansData);
    }
  }, [allPlansData, dates]);

  const filterStaffs = () => {
    let staffList = [];
    const staffListWithPlan = [];

    if (!machinesData) {
      return { staffList, staffListWithPlan };
    }

    if (staffsData) {
      staffList = staffsData
        .filter(
          (staff) =>
            !staff.inactive &&
            (staff.firstName + " " + staff.lastName)
              .toLowerCase()
              .includes(staffNames?.toLowerCase() || "") &&
            techDepartment === staff.techDepartment &&
            staffRole.some(
              (role) => role.isOn && staff.workingRole.includes(role.key)
            ) &&
            techArea?.includes(staff.techArea)
        )
        .sort((a, b) =>
          a.firstName?.toLowerCase() < b.firstName?.toLowerCase() ? -1 : 1
        );

      staffList = staffList.map((el) => {
        const serviceSchedules = getCrewServiceSchedule(
          el.userId,
          machinesData
        );

        return {
          ...el,
          serviceSchedules,
        };
      });
      plansData.forEach((plan) => {
        if (!plan.inactive) {
          plan.machineRequirements.forEach(
            (machineRequirement, indexMachineRequirement) => {
              [
                machineRequirement["drivers"],
                machineRequirement["workers"],
                machineRequirement["managers"],
              ].forEach((array) => {
                array.forEach((item) => {
                  if (
                    staffList.some((item2) => item2.userId === item.id) &&
                    (item.firstName + " " + item.lastName)
                      .toLowerCase()
                      .includes(staffNames?.toLowerCase() || "")
                  ) {
                    let replicateStaff = null;
                    for (let i = staffListWithPlan.length - 1; i >= 0; i--) {
                      if (staffListWithPlan[i].id === item.id) {
                        replicateStaff = staffListWithPlan[i];
                        break;
                      }
                    }

                    // const serviceSchedules = getCrewServiceSchedule(
                    //   item.id,
                    //   machinesData
                    // );

                    const index = !replicateStaff
                      ? 0
                      : replicateStaff.index + 1;
                    staffListWithPlan.push({
                      ...item,
                      starfVacations: !replicateStaff
                        ? item.starfVacations
                        : null,
                      personResponsbible: plan?.personResponsbible?.name,
                      isQuotation: plan.isQuotation,
                      projectNo: plan.projectNo,
                      projectId: plan.projectId,
                      projectName: plan.projectName,
                      color: plan.color,
                      indexMachineRequirement,
                      index,
                      // serviceSchedules,
                    });
                  }
                });
              });
            }
          );
        }
      });
      staffList.forEach((item) => {
        const index = staffListWithPlan.findIndex(
          (item2) => item2.id === item.userId
        );
        if (index === -1) {
          staffListWithPlan.push({ ...item, id: item.userId, index: 0 });
        }
      });
    }
    return { staffList, staffListWithPlan };
  };

  const createHorizontalPositionMap = (dates) => {
    const result = {};
    const datePositionCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        //date: 1, month: 2, year: 2021
        let key = `${dates[i][j]["year"]}-${dates[i][j]["month"] + 1}-${
          dates[i][j]["date"]
        }`;
        result[key] = counter;
        datePositionCorrespondence[counter] = moment(key, "YYYY-M-D").format(
          "YYYY-MM-DD[T]HH:mm:ss.SSS"
        );
        counter++;
      }
    }
    result["end"] = --counter;
    result["positionsToDates"] = datePositionCorrespondence;
    return result;
  };

  const createVerticalPositionMap = (staffList, staffListWithPlan) => {
    const result = {};
    const positionIdCorrespondence = {};
    const dataInfo = {};
    let counter = 0;
    for (let i = 0; i < staffList.length; i++) {
      const tempListWithSameId = staffListWithPlan.filter(
        (staff) => staff.id === staffList[i]["userId"]
      );
      dataInfo[staffList[i]["userId"]] = {
        starfVacations: staffList[i].starfVacations || [],
        staffPlans: tempListWithSameId
          .map((item) => ({
            start: item.start,
            end: item.end,
            name: item.projectName,
          }))
          .filter((item) => item.start && item.end),
      };
      const tempIndex = counter;
      if (tempListWithSameId.length > 1) {
        for (let j = 0; j < tempListWithSameId.length; j++) {
          let flag = false;
          for (let k = j + 1; k < tempListWithSameId.length; k++) {
            if (
              !(
                new Date(tempListWithSameId[j]?.start).getTime() >
                  new Date(tempListWithSameId[k]?.end).getTime() ||
                new Date(tempListWithSameId[j]?.end).getTime() <
                  new Date(tempListWithSameId[k]?.start).getTime()
              )
            ) {
              result[staffList[i]["userId"]] = {
                ...result[staffList[i]["userId"]],
                // For staff plan
                // [tempListWithSameId[j].index]: ++counter,
                [tempListWithSameId[j].index]: counter,
              };
              flag = true;
              break;
            }
          }
          if (!flag) {
            result[staffList[i]["userId"]] = {
              ...result[staffList[i]["userId"]],
              [tempListWithSameId[j].index]: tempIndex,
            };
          }
        }
      } else {
        result[staffList[i]["userId"]] = { 0: counter };
      }
      positionIdCorrespondence[counter * CALENDAR_STAFFVIEW_STEP_HEIGHT] =
        staffList[i]["userId"];
      counter++;
    }
    result["positionsToIds"] = positionIdCorrespondence;
    result["dataInfo"] = dataInfo;
    return result;
  };

  const { staffList, staffListWithPlan } = useMemo(
    () => filterStaffs(),
    [staffsData, currentFilter, plansData, techArea, machinesData]
  );
  const verticalPositionMap = useMemo(
    () => createVerticalPositionMap(staffList, staffListWithPlan),
    [staffList, staffListWithPlan]
  );
  const horizontalPositionMap = useMemo(
    () => createHorizontalPositionMap(dates),
    [dates]
  );

  const getMonthIndicator = (dates) => {
    let components = [];
    // const currentMonth = calendar.month;
    let temp = dates[0][0]["month"];
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        if (dates[i][j]["month"] === temp) {
          counter++;
        } else {
          components.push({ length: counter, label: months[temp] });
          counter = 1;
          temp = dates[i][j]["month"];
        }
      }
    }
    components.push({ length: counter, label: months[temp] });
    return components;
  };

  const onClickChooseDate = (date) => {
    dispatch(selectNewDate(date));
    const array = new Array(displayWeekView ? 12 : 6);
    array[0] = datesGenerator({
      month: date.getMonth(),
      year: date.getFullYear(),
      startingDay: 1,
    });
    for (let i = 1; i < array.length; i++) {
      array[i] = datesGenerator({
        month: array[i - 1].nextMonth,
        year: array[i - 1].nextYear,
        startingDay: 1,
      });
    }
    let { finalDates, start, end } = calculateFinalDates(array);
    if (calendarStartFrom === "month" && !displayWeekView) {
      finalDates[0] = startCalendarFromMonth(date, finalDates[0]);
    }
    // Find the start of calendar if choose to start from selected week
    if (calendarStartFrom === "week") {
      finalDates = startCalendarFromSelectedWeek(date, finalDates);
    }
    start = new Date(
      finalDates[0][0].year,
      finalDates[0][0].month,
      finalDates[0][0].date
    );
    // Find the holiday of calendar
    async function getDatesWithHolidays() {
      const yearOfDates = findYearOfDates(finalDates);
      let holidays = [];
      if (
        !holidayRef.current ||
        JSON.stringify(yearOfDates) !==
          JSON.stringify(holidayRef.current?.years)
      ) {
        for (const year of yearOfDates) {
          const currentHolidays = await dispatch(getHolidayList(year));
          holidays = [...holidays, ...(currentHolidays?.holidays || [])];
        }
        holidayRef.current = {
          years: yearOfDates,
          holidays: holidays,
        };
      } else {
        holidays = holidayRef.current.holidays;
      }
      finalDates = getCalendarHoliday(finalDates, holidays);
      setDates(finalDates);
    }
    getDatesWithHolidays();
    setDates(finalDates);

    setCalendar({
      ...calendar,
      nextMonth: array[array.length - 1].nextMonth,
      nextYear: array[array.length - 1].nextYear,
      previousMonth: array[0].previousMonth,
      previousYear: array[0].previousYear,
      start,
      end,
    });
  };

  useEffect(() => {
    setCalendarStepWidth(
      displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
    );
    onClickChooseDate(new Date(selectedDayOnCalendar));
  }, [displayWeekView, new Date(selectedDayOnCalendar).toLocaleString()]);

  // Add icon to resize cursor
  useEffect(() => {
    const ref = setTimeout(() => {
      const resizeDivList = document.getElementsByClassName("react-draggable");
      for (let index = 0; index < resizeDivList.length; index++) {
        const resizeDiv = resizeDivList[index].lastChild;
        if (resizeDiv.firstChild && resizeDiv.lastChild) {
          resizeDiv.firstChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.firstChild.style.left = "-14px";
          resizeDiv.lastChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.lastChild.style.right = "3px";
        }
      }
    }, 500);

    return () => {
      clearTimeout(ref);
    };
  });

  const staffItemContainer = useMemo(() => {
    const list = [];
    let totalCount = 0;
    for (let i = 0; i < staffList.length; i++) {
      const temp = verticalPositionMap[staffList[i].userId];
      let tempList = new Set();
      for (const key in temp) {
        tempList.add(temp[key]);
      }
      tempList = Array.from(tempList);
      let count = 1;
      // For staff plan
      // if (tempList.length >= 2) {
      //     count = tempList.length;
      // }
      totalCount += count;
      list.push(
        <ItemContainer
          calendarPage={CALENDAR_PAGE.STAFF}
          count={count}
          key={`${staffList[i].userId}`}
          workingRole={staffList[i].workingRole}
          index={`${staffList[i].userId}`}
          name={`${staffList[i].firstName} ${staffList[i].lastName ?? ""}`}
          techDepartments={staffList[i].techDepartment.toString()}
          techAreas={staffList[i].techArea.toString()}
          imageUrl={staffList[i]?.imageUrl}
          staffId={staffList[i].userId}
          calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
        />
      );
    }
    setTotalHeight(totalCount * CALENDAR_STAFFVIEW_STEP_HEIGHT);
    return list;
  }, [verticalPositionMap]);

  return (
    <div className={classNames.root}>
      <ScreenSizeAndPosition>
        <StaffVacationBubble />
      </ScreenSizeAndPosition>
      <div className={classNames.leftContainer}>
        <DispositionStickyControl
          initialPosition={{
            top: 190,
          }}
          scrollToPosition={{
            top: 56,
          }}
          scrollPoint={25}
          style={{
            backgroundColor: "rgb(241, 241, 241)",
            zIndex: 1000,
          }}
          backgroundStyle={{
            backgroundColor: "rgb(241, 241, 241)",
            zIndex: 999,
            width: 385,
            position: "fixed",
            height: 203,
            top: 0,
            left: -10,
          }}
        >
          <CalendarControlsLeft calendarPage={CALENDAR_PAGE.STAFF} />
        </DispositionStickyControl>
        <div className={classNames.machineContainer}>{staffItemContainer}</div>
      </div>
      <div>
        <ScreenSizeAndPosition>
          <CalendarControlsRight
            onClickChooseDate={onClickChooseDate}
            calendarPage={CALENDAR_PAGE.STAFF}
          />
        </ScreenSizeAndPosition>
        <div className={classNames.stickyHeader}>
          <div style={{ height: 100 }} />
          <div className={classNames.machineAndCalendarContainer}>
            <div className={classNames.calendarContainer}>
              {displayWeekView && <div style={{ height: 54 }} />}
              <div className={classNames.weeks}>
                {dates.length &&
                  getMonthIndicator(dates).map((monthData, indexMonth) => (
                    <MonthLabel
                      isWeekView={displayWeekView}
                      index={indexMonth}
                      key={
                        indexMonth + monthData["length"] + monthData["label"]
                      }
                      length={monthData["length"]}
                      label={monthData["label"]}
                    />
                  ))}
              </div>

              <div
                className={classNames.weeks}
                style={{
                  marginLeft:
                    calendarStartFrom === "month" &&
                    !displayWeekView &&
                    Array.isArray(dates) &&
                    dates[0]?.length < 7
                      ? dates[0].length * CALENDAR_STEP_WIDTH
                      : 0,
                }} // Calculate position for first week
              >
                {dates.length > 0 &&
                  removeFirstWeek(
                    dates,
                    calendarStartFrom === "month" &&
                      !displayWeekView &&
                      Array.isArray(dates) &&
                      dates[0]?.length < 7
                  ).map((week, indexWeek) => (
                    <WeekLabel
                      isWeekView={displayWeekView}
                      index={indexWeek}
                      month={week[0]["month"]}
                      day={week[0]["date"]}
                      year={week[0]["year"]}
                      key={`${week[0]["month"]}-${week[0]["date"]}-${week[0]["year"]}`}
                    />
                  ))}
              </div>
              {!displayWeekView && (
                <>
                  <div className={classNames.days}>
                    {dates.length > 0 &&
                      dates.map((week) =>
                        week.map((each) => (
                          <DayLabel
                            label={each.date}
                            date={each}
                            key={JSON.stringify(each)}
                          />
                        ))
                      )}
                  </div>
                  <div className={classNames.dayOfWeeks}>
                    {dates.length > 0 &&
                      dates.map((week) =>
                        week.map((each) => (
                          <DayLabel
                            label={each.date}
                            date={each}
                            key={JSON.stringify(each)}
                            isDaysOfWeek
                          />
                        ))
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={classNames.machineAndCalendarContainer}>
          <div className={classNames.calendarContainer}>
            <div
              style={{
                minHeight: totalHeight,
                width: calendarStepWidth * 7 * dates.length - 24,
                position: "absolute",
                backgroundImage: displayWeekView
                  ? 'url("./img/calendarBackground5.png")'
                  : 'url("./img/calendarBackground3.png")',
                backgroundRepeat: "repeat",
                backgroundPositionX:
                  calendarStartFrom === "month" &&
                  !displayWeekView &&
                  Array.isArray(dates) &&
                  dates[0]?.length < 7
                    ? dates[0].length * CALENDAR_STEP_WIDTH
                    : 0,
              }}
            >
              <div
                id="staffBarContainer"
                className="staffBarContainer"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 556,
                  width: "100%",
                  height: staffList.length * CALENDAR_STAFFVIEW_STEP_HEIGHT,
                }}
              >
                {dates.length &&
                  horizontalPositionMap &&
                  verticalPositionMap &&
                  Array.isArray(staffList) &&
                  staffList.map((staff, index) => (
                    <Fragment key={`${staff.id}-${index}`}>
                      {staff.serviceSchedules &&
                        staff?.serviceSchedules.length > 0 &&
                        staff.serviceSchedules.map((item, index) => (
                          <StaffMachineServiceItemForStaffCalendar
                            calendarStepWidth={calendarStepWidth}
                            key={`${staff.userId}-${index}`}
                            staffVacationIndex={index}
                            // staffVacation={item}
                            staff={staff}
                            userId={staff.userId}
                            start={item["start"]}
                            end={item["end"]}
                            horizontalPositions={horizontalPositionMap}
                            verticalPositions={verticalPositionMap}
                            calendarStart={calendar.start}
                            calendarEnd={calendar.end}
                            isApproved={item["isApproved"]}
                            calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
                            staffsData={staffsData}
                            serviceItem={item}
                            serviceText={getServiceText({
                              comment: item.comment,
                              reason: item.reason,
                            })}
                          />
                        ))}
                    </Fragment>
                  ))}
                {dates.length &&
                  horizontalPositionMap &&
                  verticalPositionMap &&
                  machinesData &&
                  Array.isArray(staffList) &&
                  staffList.map((staff, index) => (
                    <Fragment key={`${staff.id}-${index}`}>
                      {staff.starfVacations &&
                        staff?.starfVacations.length > 0 &&
                        staff.starfVacations.map((item, index) => (
                          <StaffVacationItemForStaffCalendar
                            calendarStepWidth={calendarStepWidth}
                            key={`${staff.userId}-${index}`}
                            staffVacationIndex={index}
                            staffVacation={item}
                            staff={staff}
                            userId={staff.userId}
                            start={item["start"]}
                            end={item["end"]}
                            horizontalPositions={horizontalPositionMap}
                            verticalPositions={verticalPositionMap}
                            calendarStart={calendar.start}
                            calendarEnd={calendar.end}
                            isApproved={item["isApproved"]}
                            calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
                            staffsData={staffsData}
                            machines={machinesData}
                          />
                        ))}
                    </Fragment>
                  ))}
                {/* For staff plan */}
                {/* {dates.length &&
                                    horizontalPositionMap &&
                                    verticalPositionMap &&
                                    Array.isArray(staffListWithPlan) &&
                                    staffListWithPlan.map((staff, index) => (
                                        <Fragment key={`${staff.id}-${index}`}>
                                            <StaffPlan
                                                key={`${staff.id}-${index}`}
                                                staff={staff}
                                                horizontalPositions={horizontalPositionMap}
                                                verticalPositions={verticalPositionMap}
                                                calendarStart={calendar.start}
                                                calendarEnd={calendar.end}
                                                calendarStepHeight={CALENDAR_STAFFVIEW_STEP_HEIGHT}
                                            />
                                        </Fragment>
                                    ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffCalendar;
