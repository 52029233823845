import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { SideMenuSmall } from '../common/';

const fontStyleSets = {
    h1font: {
        color: 'white',
        fontSize: 12,
        lineHeight: 12
    },
}

const classNames = mergeStyleSets(fontStyleSets, {
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    pageContentContainer: {
        height: 50,
        background: 'red'
    },
    h1font: {
        lineHeight: 50,
        // pointer 'hotspot' 30px from the left and 15px from the top of the image.
        cursor: "url('./img/machine_32x32.png') 30 15, crosshair"
    },
});

const Test = () => {

    return (
        <div className={classNames.root}>
            <SideMenuSmall />
            <div className={classNames.pageContentContainer}>
                <div className={classNames.h1font} onClick={() => console.log('eeee')}>
                    dslkfaklsdfj;laksdfjlksdjflaksjfdlaksjdflksajdflksjdfl;kjads
                </div>
            </div>
        </div>   
    )
}

export default Test;
