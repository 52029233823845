// Import libraries
import { Dropdown, mergeStyleSets } from "@fluentui/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

// Import redux
import { saveMessage } from "../../../redux/message/message.actions";

// Import types
import { Skill, SkillTypeEnum } from "../../../types/settings";

// Import utils
import {
  SKILL_TYPE_KEY,
  techDepartmentOptions,
} from "../../../utils/constants";

// Import components
import RequiredSkillControl from "./RequiredSkillControl";

const classNames = mergeStyleSets({
  tableContainer: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tr: {
    textAlign: "left",
    height: 55,
    borderBottom: "1px solid #DBDBDB",
  },
  td: {
    whiteSpace: "noWrap",
    padding: "0px 0px 0px 16px",
  },
  th: {
    boxSizing: "border-box",
    padding: "0px 0px 0px 16px",
    fontSize: 11,
    lineHeight: 13.37,
    fontWeight: 700,
    color: "#000",
  },
  icon: {
    fontSize: 22,
    color: "rgb(0, 108, 173)",
    cursor: "pointer",
    marginRight: 12,
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

type RequiredSkillTableProp = {
  requiredSkills: string[];
  skills: Skill[];
  onUpdatedRequiredSkills: (newRequiredSkills: string[]) => void;
  techDepartments: string;
  hasEditRights: boolean;
  skillType: SkillTypeEnum;
};

const dropdownStyles = {
  dropdownItem: {
    height: 48,
  },
  dropdownItemSelected: {
    height: 48,
  },
  title: {
    border: "none",
  },
  root: {
    width: "100%",
  },
  dropdown: {
    width: "100%",
    caretDownWrapper: {
      marginTop: 8,
    },
  },
  caretDown: {
    color: "#006CAD",
    fontWeight: "1000",
  },
};

const RequiredSkillsTable = ({
  requiredSkills = [],
  skills = [],
  onUpdatedRequiredSkills,
  techDepartments,
  hasEditRights,
  skillType,
}: RequiredSkillTableProp) => {
  const [isNewSkill, setIsNewSkill] = useState<boolean>();
  const [newSkill, setNewSkill] = useState<string>();
  const [machineRequiredSkillsCopy, setMachineRequiredSkillsCopy] =
    useState<string[]>(requiredSkills);

  const dispatch = useDispatch();

  // We want the dropdown options to be of the same techDeparment
  // and also not already added to the machine
  const dropdownOptions = useMemo(
    () =>
      skills
        .filter(
          (el) =>
            !el.inactive &&
            el.type === skillType &&
            el.sections.some((section) =>
              techDepartments.includes(section + "")
            )
        )
        .map((el) => ({
          key: el.id,
          label: el.name,
          text: el.name,
        }))
        .filter((el) => !machineRequiredSkillsCopy.includes(el.key)),
    [skills, machineRequiredSkillsCopy, techDepartments]
  );

  const resetAddState = () => {
    setIsNewSkill(false);
    setNewSkill(undefined);
  };

  const onRemoveSkill = (index: number) => {
    if (isNewSkill) {
      dispatch(
        saveMessage(
          "You can only delete a skill after you are done adding the current skill"
        )
      );
    } else {
      const copy = [...machineRequiredSkillsCopy];

      copy.splice(index);

      setMachineRequiredSkillsCopy(copy);

      onUpdatedRequiredSkills(copy);
    }
  };

  const renderRequiredSkills = () => {
    return machineRequiredSkillsCopy.map((el, index) => {
      const skill = skills.find((skill) => skill.id === el);

      if (!skill) {
        return <></>;
      } else if (
        skill?.sections.every((el) => !techDepartments.includes(el + ""))
      ) {
        return <></>;
      }

      const labels =
        skill?.sections
          .map(
            (el) => techDepartmentOptions.find((item) => item.key === el)?.label
          )
          .filter((el): el is string => !!el) || [];

      return (
        <tr key={el} className={classNames.tr}>
          <td style={{ width: 156 }} className={classNames.td}>
            {skill?.name}
          </td>
          <td style={{ width: 160 }} className={classNames.td}>
            {/* {
              labels.map((label) => (
                <p key={label}>{label}</p>
              ))
            } */}
          </td>
          <td style={{ width: 104 }} className={classNames.td}>
            {hasEditRights ? (
              <RequiredSkillControl
                index={index}
                remove={() => onRemoveSkill(index)}
              />
            ) : (
              <></>
            )}
          </td>
        </tr>
      );
    });
  };

  const onApplyChange = () => {
    if (isNewSkill) {
      const missingInfo = !newSkill;

      if (missingInfo) {
        dispatch(saveMessage("Please provide missing information!"));
        return;
      }

      const copies = [...machineRequiredSkillsCopy];

      copies.push(newSkill);

      onUpdatedRequiredSkills(copies);

      setMachineRequiredSkillsCopy(copies);

      resetAddState();
    }
  };

  const onChange = (newSkillKey: string) => {
    setNewSkill(newSkillKey);
  };

  const renderNewSkill = () => {
    if (!isNewSkill) {
      return <></>;
    }

    const skill = skills.find((skill) => skill.id === newSkill);

    let labels: string[] = [];

    if (skill) {
      const { sections } = skill;

      labels =
        sections
          .map(
            (el) => techDepartmentOptions.find((item) => item.key === el)?.label
          )
          .filter((el): el is string => !!el) || [];
    }

    return (
      <tr className={classNames.tr}>
        <td className={classNames.td}>
          <Dropdown
            selectedKey={newSkill}
            options={dropdownOptions}
            onChange={(_, item) => {
              if (item?.key) {
                onChange(`${item.key}`);
              }
            }}
            styles={dropdownStyles}
          />
        </td>
        <td className={classNames.td}>
          {/* {labels.map((label) => (
            <p key={label}>{label}</p>
          ))} */}
        </td>
        <td className={classNames.td}>
          <div className={classNames.iconContainer}>
            <FontAwesomeIcon
              icon={faCheck as IconDefinition}
              className={classNames.icon}
              onClick={onApplyChange}
            />
            <FontAwesomeIcon
              icon={faTimes as IconDefinition}
              className={classNames.icon}
              onClick={() => {
                resetAddState();
              }}
            />
          </div>
        </td>
      </tr>
    );
  };

  const onAddSkillClick = () => {
    if (isNewSkill) {
      dispatch(saveMessage("You can only edit one item at a time!"));
    } else {
      setIsNewSkill(true);
    }
  };

  return (
    <div className={classNames.tableContainer}>
      <table className={classNames.table}>
        <tbody>
          <tr className={classNames.tr}>
            <th
              style={{
                minWidth: 156,
              }}
              className={classNames.th}
            >
              SKILL
            </th>
            <th
              style={{
                width: 160,
              }}
              className={classNames.th}
            >
              {/* SECTION */}
            </th>
            <th
              style={{
                minWidth: 104,
              }}
              className={classNames.th}
            ></th>
          </tr>
          {renderRequiredSkills()}
          {renderNewSkill()}
          <tr>
            <td style={{ width: 156 }} className={classNames.td}></td>
            <td style={{ width: 160 }} className={classNames.td}></td>
            <td
              style={{ width: 104 }}
              className={`${classNames.tr} ${classNames.td}`}
            >
              {hasEditRights ? (
                <FontAwesomeIcon
                  icon={faPlus as IconDefinition}
                  className={classNames.icon}
                  onClick={onAddSkillClick}
                />
              ) : (
                <></>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RequiredSkillsTable;
