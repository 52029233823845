// Import libraries
import { Route, RouteProps,Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Import types
import { children } from '../../types';

type PrivateRouteProps  = {
    children: children,
    isAuthenticated: boolean,
    isLoading: boolean,
    techDepartment: string,
} & RouteProps

const PrivateRoute = ({ children, isAuthenticated, isLoading, techDepartment, ...rest }: PrivateRouteProps):JSX.Element=> { 
    return <Route 
        {...rest} 
        render={({ location }) => {
            return ((!isAuthenticated && !isLoading) || !techDepartment) ? (<Redirect to='/login' />) : children }
        } 
    />
};

//@ts-ignore
const mapStateToProps = state => ({
    isAuthenticated: state.user.isAuthenticated,
    isLoading: state.user.isLoading,
    techDepartment: state.machine.filter.techDepartment,
});

export default connect( mapStateToProps, null )(PrivateRoute);
