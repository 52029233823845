// Import libraries
import { Persona, PersonaSize, ScrollablePane, ScrollbarVisibility, SearchBox, Stack, TeachingBubble, mergeStyleSets } from '@fluentui/react';
import { useMemo, useState } from 'react'

// Import types
import { Skill } from '../../../types/settings';
import { TTechDepartmentEnum } from '../../../types';

// Utils theme
import { searchBoxStyles } from '../../../utils/theme';

interface IPopupGeneralSkillsProps {
    toggleTeaching: () => void,
    target: string,
    availableGeneralSkills: Skill[],
    staffTechDepartment: TTechDepartmentEnum,
    onClickGeneralSkill: (skill: Skill) => void
}

const classNames = mergeStyleSets({
    Persona: {
        padding: '5px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

const PopupGeneralSkills = ({ toggleTeaching, target, availableGeneralSkills, staffTechDepartment, onClickGeneralSkill }: IPopupGeneralSkillsProps) => {

    const [showOtherSections, setShowOtherSections] = useState<boolean>(false);

    const [searchValue, setSearchValue] = useState('');

    const [sameDeptMachines, otherDeptMachines] = useMemo<[Skill[], Skill[]]>(() => {
        const sameDeptSkill: Skill[] = [];
        const otherDeptSkill: Skill[] = [];

        availableGeneralSkills.forEach((el) => {
            if (el.sections.includes(staffTechDepartment)) {
                sameDeptSkill.push(el);
            }
            else {
                otherDeptSkill.push(el);
            }
        })

        return [sameDeptSkill, otherDeptSkill];
    }, [availableGeneralSkills, staffTechDepartment])

    const listSKills = useMemo(() => {
        const list = [...sameDeptMachines, ...(showOtherSections ? otherDeptMachines : [])];
        return searchValue ? list.filter((el) => el.name.toLowerCase().includes(searchValue.toLowerCase())) : list;
    }, [sameDeptMachines, otherDeptMachines, showOtherSections, searchValue]);

    return (
        <TeachingBubble target={`#${target}`} hasSmallHeadline={true} onDismiss={toggleTeaching} closeButtonAriaLabel='Close'>
            <SearchBox
                styles={searchBoxStyles}
                placeholder='Søg'
                clearButtonProps={{
                    style: {
                        backgroundColor: '#FFFFFF',
                        marginLeft: '20px',
                    },
                }}
                value={searchValue}
                onChange={(e, value) => typeof value === 'string' && setSearchValue(value)}
            />
            <hr style={{ width: '145%', marginLeft: '-50px', marginTop: '-2px', opacity: 0.5 }} />
            <div style={{ height: '240px', position: 'relative', backgroundColor: 'white', display: 'flex', flexDirection: 'row' }}>
                <ScrollablePane style={{ height: '200px' }} scrollbarVisibility={ScrollbarVisibility.auto}>
                    <Stack
                        // @ts-ignore
                        vertical
                        tokens={{ childrenGap: 10 }}
                    >
                        {listSKills &&
                            listSKills.map((skill) => (
                                <div
                                    id={`skill-${skill.id}`}
                                    key={`skill-${skill.id}`}
                                    className={classNames.Persona}
                                    onClick={() => {
                                        onClickGeneralSkill(skill);
                                    }}
                                >
                                    <Persona
                                        text={skill.name}
                                        secondaryText={""}
                                        size={PersonaSize.size40}
                                        imageUrl={''}
                                    />
                                </div>
                            ))}
                    </Stack>
                </ScrollablePane>
                <div
                    style={{
                        margin: '220px auto 20px auto',
                        color: 'rgb(0, 108, 173)',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setShowOtherSections((section) => !section);
                    }}
                >
                    {showOtherSections ? 'Hide' : 'Show'} other sections
                </div>
            </div>
        </TeachingBubble>
    )
}

export default PopupGeneralSkills