export const buttonStyle = (
  selected,
  defaultBackground = "",
  marginRight = 15
) => ({
  root: {
    borderRadius: 4,
    height: 40,
    width: 53,
    minWidth: 53,
    borderColor: "#006CAD",
    color: "#006CAD",
    backgroundColor: selected ? "#006CAD" : defaultBackground,
    marginRight,
  },
  label: { fontWeight: 400 },
  rootHovered: {
    backgroundColor: !selected && "#99b3ff",
  },
});

export const crmButtonStyle = (selected, defaultBackground = "") => ({
  root: {
    borderRadius: 4,
    height: 40,
    // width: 53,
    // minWidth: 53,
    borderColor: "#006CAD",
    color: "#006CAD",
    backgroundColor: selected ? "#006CAD" : defaultBackground,
    marginRight: 15,
  },
  label: { fontWeight: 400 },
  rootHovered: {
    backgroundColor: !selected && "#99b3ff",
  },
});
