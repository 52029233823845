import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useQueryClient, useMutation } from "react-query";
import { updateMachine } from "../../../redux/machines/machines.actions";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";
import { DeleteConfirmation } from "../../common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPowerOff } from '@fortawesome/pro-regular-svg-icons';

const classNames = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        fontSize: 22,
        color: 'rgb(0, 108, 173)',
        cursor: 'pointer',
        marginRight: 12,
    },
});

const MachineControls = ({ machine, updateMachine, history }) => {
    const queryClient = useQueryClient();
	const [teachingBubbleEditMachine, { toggle: toggleTeachingEditMachine }] = useBoolean(false);

    const {mutate: mutationEditMachine} = useMutation((machineData) => updateMachine(machineData), {
		onSuccess: () => {
			queryClient.invalidateQueries("machines");
		},
	});

	const onClickConfirm = () => {
        toggleTeachingEditMachine();
        const temp = {...machine, inactive: !!!machine.inactive};
        mutationEditMachine(temp);
    }
    return (
        <Fragment>
            <div className={classNames.container}>
                {!machine.inactive && <FontAwesomeIcon icon={faEdit} className={classNames.icon} onClick={() => history.push(`/machine/${machine.machineId}`)} />}
                <FontAwesomeIcon icon={faPowerOff} className={classNames.icon} id={`editMachineId-${machine.machineId}`} onClick={toggleTeachingEditMachine}/>
            </div>
            {teachingBubbleEditMachine && (
                <DeleteConfirmation label={`Are you sure you want to ${machine.inactive ? 'active' : 'inactive'} this?`} target={`#editMachineId-${machine.machineId}`} toggleTeaching={toggleTeachingEditMachine} onClickConfirm={onClickConfirm}/>
            )}
        </Fragment>
    );
};

export default connect(null, { updateMachine })(MachineControls);
