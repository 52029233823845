import { mergeStyleSets } from "@fluentui/react";

const classNames = mergeStyleSets({
  container: {
    height: "100%",
    maxWidth: 144,
    display: "flex",
    alignItems: "center",
  },
  item: {
    display: "inline-block",
    color: "#868685",
    background: "#DFDFDF",
    borderRadius: 4,
    height: 14,
    fontSize: 11,
    lineHeight: 14,
    padding: "0 8px",
    margin: 2,
  },
});

type BadgeProps = {
  text: string;
  containerStyle?: React.CSSProperties
};

const Badge = ({ text,containerStyle }: BadgeProps) => {
  return (
    <div style={containerStyle} className={classNames.container}>
      <div className={classNames.item}>{text}</div>
    </div>
  );
};

export default Badge;
