import { DirectionalHint, IconButton } from "@fluentui/react";
import { Formik } from "formik";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { setMachineServiceBubble } from "../../../redux/app/app.actions";
import {
  getMachines,
  updateMachine,
} from "../../../redux/machines/machines.actions";
import { iconButtonStyles } from "../../../utils/theme";
import { formatDate } from "../../../utils/utils";
import CalendarSchedule from "../../common/calendar/CalendarSchedule";
import FixPositionBubble from "../../common/FixPositionBubble";
import MachineServiceForm from "./MachineServiceForm";

const MachineServiceBubble = () => {
  const dispatch = useDispatch();
  const realPageWidth = document.documentElement.offsetWidth;
  const { openMachineServiceBubble } = useSelector((state) => state.app);
  const queryClient = useQueryClient();

  const { data, status } = useQuery("machines", () => dispatch(getMachines()), {
    staleTime: 36000,
  });

  const { mutate: updateMachineService } = useMutation(
    (machine) => dispatch(updateMachine(machine)),
    {
      onSuccess: () => {
        if (data) {
          queryClient.invalidateQueries("machines");
          onExit();
        }
      },
    }
  );

  const validationSchema = Yup.object({
    machineIndex: Yup.number("Select a name").required(
      "Machine name is required"
    ),
    comment: Yup.string("Enter a comment").required("Comment is required"),
    reason: Yup.string("Select a reason").required("Reason is required"),
    period: Yup.array().of(Yup.date()).required("Period is required"),
  });

  const formSubmit = (formData) => {
    const [start, end] = formatDate(formData.period);
    const machineForm = {
      comment: formData.comment,
      reason: formData.reason,
      start,
      end,
      color: "110,84,47", // Brown
    };
    if (data[formData.machineIndex]) {
      const machineService =
        data[formData.machineIndex].machineService &&
        data[formData.machineIndex].machineService.length > 0
          ? [...data[formData.machineIndex].machineService, machineForm]
          : [machineForm];
      const machine = { ...data[formData.machineIndex], machineService };
      updateMachineService(machine);
    }
  };

  const onExit = () => {
    dispatch(setMachineServiceBubble(false));
  };

  return (
    openMachineServiceBubble && (
      <FixPositionBubble
        calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
        target={realPageWidth > 1000 ? "#newMachineService" : "#showActions"}
        mainPositionRight={realPageWidth > 1000 ? "253.645px" : "-9px"}
        beakPositionRight={realPageWidth > 1000 ? "155.8px" : "52.5px"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "-15px",
          }}
        >
          <IconButton
            styles={iconButtonStyles}
            iconProps={{ iconName: "Cancel" }}
            ariaLabel="Close popup modal"
            onClick={() => onExit()}
          />
        </div>
        <div style={{ zIndex: 2 }}>
          {status === "success" && data && (
            <Formik
              initialValues={{}}
              validationSchema={validationSchema}
              onSubmit={formSubmit}
            >
              {(props) => (
                <CalendarSchedule
                  target="machine"
                  targetInfo={data[props.values?.machineIndex]}
                >
                  <MachineServiceForm {...props} data={data} />
                </CalendarSchedule>
              )}
            </Formik>
          )}
        </div>
      </FixPositionBubble>
    )
  );
};

export default MachineServiceBubble;
