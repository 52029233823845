import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
const classNames = mergeStyleSets({
    projectNameBarContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'green',
        color: '#fff',
        height: 24,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 29, 0.05)',
        borderRadius: 4

    },
    projectNameBarText: {
        fontSize: 12,
        fontWeight: 700,
        marginLeft: 8,
        textTransform: 'uppercase'
    },
    projectOtherInfo: {
        fontSize: 11,
        marginLeft: 20,
    },
});

const ProjectNameBar = ({projectName, projectColor, address, postNummer, projectBy}) => {
    return (
        <div className={classNames.projectNameBarContainer} style={{background: `rgba(${projectColor},1)`}}>
            <div className={classNames.projectNameBarText}>{projectName}</div>
            <div className={classNames.projectOtherInfo}>{address}</div>
            <div className={classNames.projectOtherInfo}>{postNummer}</div>
            <div className={classNames.projectOtherInfo}>{projectBy}</div>
        </div>
    )
};

export default ProjectNameBar;