import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import "moment/locale/da";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { saveMessage } from "../../../../redux/message/message.actions";
import {
  calendarConfirmationStatus,
  reasonVacationStaffOptions,
  ROLES,
} from "../../../../utils/constants";
import { checkTimeOverlap, lighterDarkerColor } from "../../../../utils/utils";
import { TooltipForText } from "../../../common";
import ConfirmStaffVacationBubble from "../../../common/ConfirmStaffVacationBubble";

const classNames = mergeStyleSets({
  textStyles: {
    padding: "0 10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    userSelect: "none",
  },
});

const DetailPlanVacationDrawer = ({
  calendarStepWidth,
  horizontalPositionMap,
  drawData,
  setDrawData,
  staffData,
  machineData,
  containerId,
  onConfirmVacation,
  staffsInDetail,
}) => {
  const dispatch = useDispatch();
  const startOfXAxis = 316;
  const containerHeight = 36;
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const userZoomRatio = 1;
  const barInfoInitial = {
    start: null,
    end: null,
    color: drawData.type === "service" ? "219,219,219" : "245,124,0",
    reason: drawData.type === "service" ? "" : 0,
    name:
      drawData.type === "service"
        ? `Prebooking ${new Date().toUTCString()}`
        : reasonVacationStaffOptions.find((item) => item.key === 0).text,
  };
  const [barInfo, setBarInfo] = useState(barInfoInitial);
  const [x, setX] = useState(null);
  const [width, setWidth] = useState(0);
  const [display, setDisplay] = useState(false);
  const [drawing, setDrawing] = useState(true);
  const [scrollY, setScrollY] = useState(window.scrollY);
  const [boundArea, setBoundArea] = useState(window.scrollY);
  const [confirmation, setConfirmation] = useState(
    calendarConfirmationStatus.CONFIRMATION_UNSET
  );
  const [containersPosition, setContainersPosition] = useState([]);
  let bgColor = {
    background: `repeating-linear-gradient(90deg, rgb(${barInfo.color}), rgb(${
      barInfo.color
    }) 24px, rgb(${lighterDarkerColor(
      barInfo.color,
      10
    )}) 24px, rgb(${lighterDarkerColor(barInfo.color, 10)}) 48px)`,
  };
  if (drawData.type === "vacation") {
    bgColor = {
      backgroundImage: `linear-gradient(45deg, rgba(${barInfo.color},1) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(${barInfo.color},1) 75%), linear-gradient(45deg, transparent 75%, rgba(${barInfo.color},1) 75%), linear-gradient(45deg, rgba(${barInfo.color},1) 25%, rgba(${barInfo.color},0.5) 25%)`,
      backgroundSize: "38px 38px",
      backgroundPosition: "0 0, 0 0, -19px -19px, 19px 19px",
    };
  }

  useEffect(() => {
    const barContainers = document.querySelectorAll(".barContainer");
    const containersPosition = [...barContainers]
      .filter((container) =>
        drawData.type === "service"
          ? container.classList.value.includes("machine")
          : !container.classList.value.includes("machine")
      )
      .map((container) => {
        const classList = container.classList.value.split("Container");
        const id = classList[2].trim();
        const type = classList[1].trim();
        const boundArea = container.classList.value.split(" ")[1];
        const target =
          type === "machine"
            ? machineData.find((item) => item.machineId === id)
            : staffData.find((item) => item.userId === id);
        const name =
          type === "machine"
            ? target.machineName
            : `${target.firstName} ${target.lastName}`;
        let listToCheck = [];
        if (drawData.type === "vacation") {
          if (!target.workingRole.includes("0")) {
            const { staffVacation, staffSchedule } = staffsInDetail.find(
              (item) => item.id === id
            );
            listToCheck = [...staffSchedule, ...(staffVacation || [])];
          }
        }
        const result = {
          id,
          type,
          name,
          listToCheck,
          rect: container.getBoundingClientRect(),
          boundArea,
        };
        return result;
      });
    setContainersPosition(containersPosition);
  }, []);

  useEffect(() => {
    changeCursorOfPlanContainer(drawing);
  }, [drawing]);

  const changeCursorOfPlanContainer = (drawing) => {
    const planContainer = document.getElementsByClassName(
      "detailPlanContainer"
    )[0];
    planContainer.style.cursor = drawing
      ? `url(${window.location.origin}/img/pencil-alt.svg), auto`
      : "default";
  };

  const isOverlap = (newX, newWidth, listToCheck) => {
    const startDate = new Date(
      horizontalPositionMap.positionsToDates[newX].value.slice(0, 10)
    );
    let endDate = new Date(
      horizontalPositionMap.positionsToDates[newX + newWidth].value.slice(0, 10)
    );
    endDate.setDate(endDate.getDate() - 1);
    for (let i = 0; i < listToCheck.length; i++) {
      let itemStartDate = new Date(
        (listToCheck[i].startText?.value || listToCheck[i].start).slice(0, 10)
      );
      let itemEndDate = new Date(
        (listToCheck[i].endText?.value || listToCheck[i].end).slice(0, 10)
      );
      const isOverlap = checkTimeOverlap(
        startDate,
        endDate,
        itemStartDate,
        itemEndDate
      );

      if (isOverlap) {
        dispatch(saveMessage("Cannot overlap!"));
        setDisplay(false);
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown, { passive: true });
    window.addEventListener("mousemove", handleMouseMove, { passive: true });
    window.addEventListener("mouseup", handleMouseUp, { passive: true });
    window.addEventListener("keydown", handleEscClick, { passive: true });
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("keydown", handleEscClick);
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleMouseDown = (e) => {
    if (drawing) {
      const xPosition = e.pageX / userZoomRatio - startOfXAxis;
      const yPosition = e.pageY / userZoomRatio;
      const containerHovering = containersPosition.find((container) => {
        // const delta = yPosition - scrollY - (container.rect.y - containerHeight / 2);
        const delta = yPosition - (container.rect.y - containerHeight / 2);
        const hovering = delta <= containerHeight && delta > 0;
        return hovering;
      });
      if (!containerHovering) cancelDrawBar();
      setBoundArea(containerHovering.boundArea);
      // const delta = yPosition - scrollY - (containerHovering.y - containerHovering.height / 2);
      const delta =
        yPosition - (containerHovering.y - containerHovering.height / 2);
      let startOfBar = Math.floor(xPosition / calendarStepWidth);
      if (delta > containerHovering.height || delta < 0) cancelDrawBar();

      setBarInfo({
        ...barInfo,
        id: containerHovering.id,
        type: containerHovering.type,
        start: startOfBar,
        end: startOfBar + 1,
        startText:
          horizontalPositionMap.positionsToDates[
            startOfBar * calendarStepWidth
          ],
        endText:
          horizontalPositionMap.positionsToDates[
            (startOfBar + 1) * calendarStepWidth
          ],
      });
    }
  };

  const handleMouseMove = (e) => {
    // Highlight the container has same y axis
    if (drawing) {
      const yPosition = e.pageY / userZoomRatio;
      const containerHovering = containersPosition.find((container) => {
        // const delta = yPosition - scrollY - (container.rect.y - containerHeight / 2);
        const delta = yPosition - (container.rect.y - containerHeight / 2);
        const hovering = delta <= containerHeight && delta > 0;
        return hovering;
      });
      if (containerHovering && drawData?.id !== containerHovering.id) {
        setDrawData({
          id: containerHovering.id,
          name: containerHovering.name,
          listToCheck: containerHovering.listToCheck,
          type: drawData.type,
        });
      } else if (!containerHovering && drawData?.id) {
        setDrawData({ type: drawData.type });
      }
    }

    if (drawing && barInfo.start !== null) {
      const xPosition = e.pageX / userZoomRatio - startOfXAxis;
      const endOfBar = Math.floor(xPosition / calendarStepWidth);
      setBarInfo({
        ...barInfo,
        end: endOfBar,
        endText:
          horizontalPositionMap.positionsToDates[
            (endOfBar - 1) * calendarStepWidth
          ],
      });
    }
  };

  const handleMouseUp = (e) => {
    if (drawing) {
      const xPosition = e.pageX / userZoomRatio - startOfXAxis;
      const end = Math.floor(xPosition / calendarStepWidth);
      const endOfBar = end === barInfo.start ? end + 1 : end - 1;
      const isBackwardDrawing = endOfBar < barInfo.start;
      const overlap = isOverlap(
        barInfo.start * calendarStepWidth,
        (endOfBar - barInfo.start) * calendarStepWidth,
        drawData.listToCheck
      );
      if (isBackwardDrawing || overlap) cancelDrawBar();
      else {
        setConfirmation(
          calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
        );
        setDrawing(false);
      }
    }
  };

  const handleEscClick = ({ key }) => {
    if (key === "Escape") cancelDrawBar();
  };

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    if (barInfo.start !== null && barInfo.end) {
      setX(barInfo.start * calendarStepWidth);
      setWidth((barInfo.end - barInfo.start) * calendarStepWidth);
      setDisplay(true);
    }
  }, [horizontalPositionMap, calendarStepWidth, barInfo]);

  const resetDrawBar = () => {
    setBarInfo(barInfoInitial);
    setX(null);
    setWidth(0);
  };

  const cancelDrawBar = () => {
    changeCursorOfPlanContainer(false);
    setDrawing(false);
    setDrawData(null);
  };

  let renderDetail = (
    <TooltipForText text={barInfo.name}>
      <div
        id={`${drawData.type}-${drawData.id}`}
        style={{
          display: "flex",
          justifyContent:
            width > 11 * calendarStepWidth ? "space-between" : "center",
          color: "#fff",
          userSelect: "none",
        }}
      >
        {width > 11 * calendarStepWidth && (
          <div className={classNames.textStyles}>
            {barInfo.startText?.display}
          </div>
        )}
        <div className={classNames.textStyles}>
          {barInfo.name}
          <span style={{ fontWeight: 400, fontSize: 11, marginLeft: 8 }}>
            {barInfo.notes || ""}
          </span>
        </div>
        {width > 11 * calendarStepWidth && (
          <div className={classNames.textStyles}>
            {barInfo.endText?.display}
          </div>
        )}
      </div>
    </TooltipForText>
  );

  return display && width > 0 && boundArea === containerId ? (
    <Rnd
      style={{ zIndex: 3, top: 0, left: 0, position: "absolute" }}
      size={{ width: width, height: 20 }}
      position={{ x: x, y: 0 }}
      bounds={`.${boundArea}`}
      enableResizing={{
        left: false,
        right: false,
      }}
      disableDragging={true}
    >
      {confirmation ===
        calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED && (
        <ConfirmStaffVacationBubble
          onCancel={cancelDrawBar}
          onApprove={() => {
            const vacationData = {
              id: barInfo.id,
              type: barInfo.type,
              color: barInfo.color,
              reason: barInfo.reason,
              notes: barInfo.notes,
              start: barInfo.start,
              end: barInfo.end,
              startText: barInfo.startText,
              endText: barInfo.endText,
            };
            onConfirmVacation(vacationData);
            cancelDrawBar();
          }}
          targetId={`${drawData.type}-${drawData.id}`}
          reason={barInfo.reason}
          notes={barInfo.notes || ""}
          setReason={(value) =>
            setBarInfo({
              ...barInfo,
              name: reasonVacationStaffOptions.find(
                (item) => item.key === value
              ).text,
              color: reasonVacationStaffOptions.find(
                (item) => item.key === value
              ).color,
              reason: value,
            })
          }
          setNotes={(value) =>
            setBarInfo({
              ...barInfo,
              notes: value,
            })
          }
        />
      )}
      <div
        style={{
          ...bgColor,
          display: "flex",
          marginBottom: 12,
          height: 36,
          borderRadius: 8,
          userSelect: "none",
        }}
      >
        <div
          style={{
            color: "#fff",
            fontWeight: "bold",
            height: "100%",
            width: "100%",
            fontSize: 13,
            lineHeight: "32px",
            margin: "2px 4px",
          }}
        >
          {renderDetail}
        </div>
      </div>
    </Rnd>
  ) : (
    <></>
  );
};

export default DetailPlanVacationDrawer;
