import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import userReducer from "./user/user.reducer";
import messageReducer from "./message/message.reducer";
import machineReducer from "./machines/machines.reducer";
import planReducer from "./plan/plan.reducer";
import prebookingReducer from "./prebooking/prebooking.reducer";
import defaultDataReducer from "./defaultData/defaultData.reducer";
import appReducer from "./app/app.reducer";
import skillsReducer from "./skill/skill.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["app", "user", "defaultData", "machine", "skill"],
  stateReconciler: autoMergeLevel2,
  // blacklist: ['machine']
};

// const machinePersistConfig = {
//     key: 'machine',
//     storage,
//     blacklist: ['selectedDayOnCalendar']
// }

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  message: messageReducer,
  // machine: persistReducer(machinePersistConfig,machineReducer),
  machine: machineReducer,
  plan: planReducer,
  prebooking: prebookingReducer,
  defaultData: defaultDataReducer,
  skill: skillsReducer,
});

//@ts-ignore
export default persistReducer(persistConfig, rootReducer);
