import { DirectionalHint, TeachingBubble } from "@fluentui/react";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

/* when using css zoom, we need to adjust the position of bubble manually, 
that's why this component's position is complicated
We will get the x position from the mouse and y position from the target */
const EditPrebookingBubble = ({ children, targetId }) => {
  const targetPosition = document
    .getElementById(targetId)
    ?.getBoundingClientRect();
  const [mousePosition, setMousePosition] = useState(null);
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const userZoomRatio = 1;
  const [bubbleInitiated, setBubbleInitiated] = useState(false);
  const mouse = useRef(null);
  if (mousePosition && !mouse.current) {
    mouse.current = mousePosition;
  }

  useEffect(() => {
    document.addEventListener("mousemove", getMousePosition);
    return () => {
      document.removeEventListener("mousemove", getMousePosition);
    };
  });

  const getMousePosition = (e) => {
    if (!mousePosition) {
      setMousePosition({
        x: e.pageX,
        y: e.pageY,
      });
    }
  };

  const bubbleStyles = () => {
    setTimeout(() => {
      const teachingBubble = document.querySelectorAll(".ms-TeachingBubble")[0];
      const beak = document.querySelectorAll(".ms-Callout-beak")[0];
      if (teachingBubble) {
        setBubbleInitiated(true);
        beak.style.display = "block";
      }
    }, 10);
    const delta =
      (mouse.current?.x - window.pageXOffset) / userZoomRatio +
      56 -
      window.innerWidth;
    return delta > 169 ? 169 : delta > 0 ? 169 : 0;
  };

  return (
    mouse.current &&
    targetPosition && (
      <TeachingBubble
        target={{
          x: (mouse.current.x - window.pageXOffset) / userZoomRatio,
          y: targetPosition.y + 20,
        }}
        calloutProps={{
          directionalHint: DirectionalHint.bottomCenter,
        }}
        styles={{
          subText: { color: "black" },
          root: {
            left: (mouse.current.x - window.pageXOffset) / userZoomRatio - 182,
          },
          subComponentStyles: {
            callout: {
              calloutMain: {
                display: bubbleInitiated ? "block" : "none",
              },
              beak: {
                marginRight: bubbleStyles(),
              },
            },
          },
        }}
      >
        {children}
      </TeachingBubble>
    )
  );
};

export default EditPrebookingBubble;
