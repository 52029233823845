import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import { CALENDAR_STEP_WIDTH } from '../../../../utils/constants';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { PreBookingProjectBar } from './'

const classNames = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    pageContentContainer: {
        background: '#F1F1F1',
        width: '100%'
    },
    pageInnerContainer: {
        margin: '40px 0 40px 118px'
    },
    calendarContainer: {
        minHeight: '100%',
        width: 300,
        marginLeft: 200
    },
    days: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    dayOfWeeks: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: 8
    },
    weeks: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: 4
    },
    week: {
        minWidth: 175,
        height: 20,
        background: '#E7E7E7',
        fontSize: 11,
        lineHeight: 20,
        borderRadius: 10
    },
    image: {
        // marginRight: '15px',
        fontSize: 18
    },
    iconPlusContainer: {
        position: 'relative',
        left: -64,
        top: 10
    },
    iconPlus: {
        fontSize: 28,
        height: 29,
        color: '#006CAD',
        selectors: {
            ':hover': {
                color: '#DBDBDB',
            }
        },
        cursor: "pointer",
    },
    functionalIcon: {
        fontSize: 18,
        height: 29,
        color: '#006CAD',
        selectors: {
            ':hover': {
                color: '#DBDBDB',
            }
        },
        cursor: "pointer",
    },
    separatorContainerStyle: {
        marginTop: 60,
        height: '20px',
        position: 'relative',
        left: -230,
        width: 'calc(100% + 230px)'
    },
    separatorStyle: {
        border: '1px solid #DFDFDF'
    },
    ElementStyle: {
        position: 'relative',
        minHeight: 100,
        marginBottom: 40
    },
    titleElementStyle: {
        display: 'flex',
        alignItems: 'center',
        // height: '100px',
        position: 'absolute',
        left: -269
    },
    titleElementStyleIndent: {
        display: 'flex',
        alignItems: 'center',
        // height: '100px',
        position: 'absolute',
        left: -269,
        paddingLeft: '27px'
    },
    titleNameElementStyle: {
		display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
		marginRight: '16px',
        fontSize: '11px'
    },
    contentElementStyle: {
        position: 'relative',
        width: '100%',
        // top: 25,
        // minHeight: 100
    },
    avatarContainerStyle: {
        position: 'relative',
        left: -88,
        top: 5,
        width: 76,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    personaStyle: {
        width: 28
    },
    textBold: {
        fontWeight: '700',
        marginLeft: '2px'
    },
    textNormal: {
        fontWeight: '400',
        marginLeft: '2px'
    },
    iconContainer: {
        width: 24
    },
});

const getWidthStartEndNumberByHorizontalPositionMap = (horizontalPositionMap, start, end, printStart, printEnd) => {
    const keyStart = moment(start).utcOffset(0).format('YYYY-M-D');
    const keyEnd = moment(end).add(1, 'd').utcOffset(0).format('YYYY-M-D');

    let xPosition = horizontalPositionMap[keyStart];
    let endValue = horizontalPositionMap[keyEnd];

    if(new Date(start) < printStart && new Date(end) > printEnd && typeof xPosition === 'undefined' && typeof endValue === 'undefined' ){
        xPosition = 0;
        endValue = horizontalPositionMap['end'] + 1;
    }

    if(new Date(start) < printStart && typeof endValue !== 'undefined' ) {
        xPosition = 0;
    }

    if(new Date(end) > printEnd) {
        endValue = horizontalPositionMap['end'] + 1;
    }
    const width = (endValue - xPosition) * CALENDAR_STEP_WIDTH;
    return {width, start: xPosition * CALENDAR_STEP_WIDTH, end: endValue}
}

const MachinePreBooking = ({ booking, horizontalPositionMap, printStart, printEnd }) => {
	const [preBooking, setPreBooking] = useState([]);

	useEffect(() => {
		const tempBooking = booking && booking.reduce((array, item) => {
		    const {width, start, end} = getWidthStartEndNumberByHorizontalPositionMap(horizontalPositionMap, item.start, item.end, printStart, printEnd);
            const pre = {
				...item,
				start,
				end,
				width,
			}
			const index = array.findIndex((item1) => item1.id === item.machineId);
			if (index !== -1) {
                if (!Number.isNaN(start) && !Number.isNaN(end) && typeof !Number.isNaN(width)) {
                    array[index].array.push(pre)
                }
			} else if (!Number.isNaN(start) && !Number.isNaN(end) && typeof !Number.isNaN(width)) {
                array.push(
                    {
                        id: item.machineId, 
                        machineName: item.machineName, 
                        start: item.start,
                        end: item.end,
                        width: width,
                        projectName: item.projectName,
                        personResponsbible: item.personResponsbible,
                        array: [pre]
                    }
                )
            }
			
			return array;
		}, []);
		setPreBooking(tempBooking);
	}, []);

	return (
		<div className="ElementStyle" style={{position: 'relative', marginBottom: (preBooking.length > 0) ? 18 : 0}}>
            {preBooking.map((bookingList, index) => (
				<Fragment key={`${bookingList.id}-${index}`}>
					<div className={classNames.titleElementStyle}>
						<div className={classNames.titleNameElementStyle}>
                            <div className={classNames.iconContainer}>
                            <Image
								src={`${window.location.origin}/img/machineBlack.svg`}
								imageFit={ImageFit.cover}
								alt="machine"
								width={18}
								height={18}
								className={classNames.image}
							/>
                            </div>
							<span className={classNames.textBold}>{bookingList.machineName}</span>
						</div>
					</div>
					<div className={classNames.contentElementStyle}>
						<div style={{ zIndex: 556, width: '100%', height: 32}}>
                        {bookingList.array.map((booking, index2) => (
                            <PreBookingProjectBar 
                                key={`${index2}${booking.machineId}`} 
                                machine={booking}
                            />
                        ))}
						</div>
					</div>
				</Fragment>
			))}
		</div>
	)
};

export default MachinePreBooking;
