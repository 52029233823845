// Import libraries
import { mergeStyleSets } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faTrashAlt,
  faPowerOff,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import components
import { DeleteConfirmation } from "../../common";

const classNames = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    fontSize: 22,
    color: "rgb(0, 108, 173)",
    cursor: "pointer",
    marginRight: 12,
  },
});

type SkillTableControlProps = {
  onClickDelete: () => void;
  onClickInactive: () => void;
  onClickEdit: () => void;
  id: string;
  inactive: boolean;
};

const SkillTableControl = ({
  onClickDelete,
  onClickEdit,
  onClickInactive,
  id,
  inactive,
}: SkillTableControlProps) => {
  const [deleteConfirmation, { toggle: toggleDeleteConfirmation }] =
    useBoolean(false);
  const [inactiveConfirmation, { toggle: toggleInactiveConfirmation }] =
    useBoolean(false);

  const deleteTargetId = `DeleteSkill-${id}`;
  const inactiveTargetId = `InactiveSkill-${id}`;

  return (
    <>
      <div className={classNames.container}>
        {
          !inactive && 
          <FontAwesomeIcon
            icon={faEdit as IconDefinition}
            className={classNames.icon}
            onClick={() => onClickEdit()}
          />
        }
        <FontAwesomeIcon
          icon={faTrashAlt as IconDefinition}
          className={classNames.icon}
          onClick={toggleDeleteConfirmation}
          id={deleteTargetId}
        />
        <FontAwesomeIcon
          icon={faPowerOff as IconDefinition}
          className={classNames.icon}
          onClick={toggleInactiveConfirmation}
          id={inactiveTargetId}
        />
      </div>
      {deleteConfirmation && (
        <DeleteConfirmation
          target={`#${deleteTargetId}`}
          toggleTeaching={toggleDeleteConfirmation}
          onClickConfirm={()=>{
            toggleDeleteConfirmation();
            onClickDelete();
          }}
        />
      )}
      {inactiveConfirmation && (
        <DeleteConfirmation
          label={`Are you sure you want to ${
            inactive ? "active" : "inactive"
          } this?`}
          target={`#${inactiveTargetId}`}
          toggleTeaching={toggleInactiveConfirmation}
          onClickConfirm={() => {
            toggleInactiveConfirmation();
            onClickInactive();
          }}
        />
      )}
    </>
  );
};

export default SkillTableControl;

// import React, { Fragment } from 'react';
// import { mergeStyleSets } from '@fluentui/react/lib/Styling';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEdit, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
// import { useBoolean } from "@fluentui/react-hooks";
// import { DeleteConfirmation } from '../../common';

// const classNames = mergeStyleSets({
//     container: {
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center'
//     },
//     icon: {
//         fontSize: 22,
//         color: 'rgb(0, 108, 173)',
//         cursor: 'pointer',
//         marginRight: 12,
//     },
// });

// const MachineRequirementsControls = ({ index, edit, remove, isCrmReq }) => {
//     const [deleteConfirmation, { toggle: toggleDeleteConfirmation }] = useBoolean(false);

//     const onClickConfirm = () => {
//         toggleDeleteConfirmation()
//         remove(index)
//     };

//     return (
//         <Fragment>
//             <div className={classNames.container}>
//                 <FontAwesomeIcon icon={faEdit} className={classNames.icon} onClick={() => edit(index)} />
//                 {!isCrmReq && <FontAwesomeIcon id={`delete-${index}`} icon={faTrashAlt} className={classNames.icon} onClick={toggleDeleteConfirmation}/>}
//             </div>
//             {deleteConfirmation && (
//                 <DeleteConfirmation target={`#delete-${index}`} toggleTeaching={toggleDeleteConfirmation} onClickConfirm={onClickConfirm}/>
//             )}
//         </Fragment>
//     )
// }

// export default MachineRequirementsControls;
