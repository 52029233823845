import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { useHistory } from "react-router-dom";
import { logout } from "../../../redux/user/user.actions";
import { SOURCE_API } from "../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPencilRuler } from "@fortawesome/pro-regular-svg-icons";
import {
  changeCRMOverlay,
  changeSearchOverlay,
} from "../../../redux/app/app.actions";

const classNames = mergeStyleSets({
  header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "calc(100% - 3360px)",
    height: 72,
    margin: "40px 0",
  },
  innerContainer: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: 40,
    cursor: "pointer",
    borderRadius: "50%",
    selectors: {
      ":hover": {
        borderRadius: "50%",
        boxShadow: "0px 0px 30px #006CAD",
        transition: "all 0.2s ease-in",
      },
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "40px",
  },
  subTitle: {
    fontSize: "11px",
    lineHeight: "11px",
  },
  icon: {
    marginRight: 35,
    fontSize: 24,
    color: "#006CAD",
    cursor: "pointer",
  },
  iconNumber: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    borderRadius: "50%",
    marginTop: "-38px",
    marginLeft: "15px",
    color: "#FFFFFF",
    fontSize: 12,
    height: 24,
    width: 24,
    backgroundColor: "#006CAD",
  },
});

const CalendarHeaderRight = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  // const { crmCounter } = useSelector((state) => state.app);
  const realPageWidth = document.documentElement.offsetWidth;
  const history = useHistory();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={classNames.header}>
      <div
        className={classNames.innerContainer}
        style={{
          top: scrollPosition > 56 ? -100 : 56 - scrollPosition,
          right: realPageWidth > 1000 ? 144 : 20,
        }}
      >
        <FontAwesomeIcon
          onClick={() => dispatch(changeSearchOverlay(true))}
          icon={faSearch}
          className={classNames.icon}
        />
        <Persona
          className={classNames.avatar}
          onClick={() => {
            history.push("/notification-management");
          }}
          imageUrl={user.imageUrl ? `${SOURCE_API}${user.imageUrl}` : ""}
          // text={user ? `${user.firstName} ${user.lastName}` : ''}
          size={PersonaSize.size40}
        />
      </div>
    </div>
  );
};

export default CalendarHeaderRight;
