import { MessageActionTypes } from "./message.types";

const initialState = {
    message: null,
    messageType: null,
    conflictMessage: {
        machine: {},
        driver: {},
        worker: {},
        manager: {},
    },
};

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case MessageActionTypes.ADD_MESSAGE:
            return {
                ...state,
                message: action.payload.message,
                messageType: action.payload.type,
            };
        case MessageActionTypes.ADD_CONFLICT_MESSAGE:
            return {
                ...state,
                conflictMessage: action.payload.conflictMessage,
                messageType: action.payload.type,
            };
        case MessageActionTypes.CLEAR_MESSAGE:
            return {
                ...state,
                message: null,
            };
        case MessageActionTypes.CLEAR_CONFLICT_MESSAGE:
            return {
                ...state,
                conflictMessage: null,
                messageType: null,
            };
        default:
            return state;
    }
};

export default messageReducer;