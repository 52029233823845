// Import libraries
import { MessageBarType } from "@fluentui/react";
import axios, { AxiosResponse } from "axios";
import { NewSkill, Skill } from "../../types/settings";
import { SOURCE_API } from "../../utils/constants";

// Import utils
import setDefaults from "../../utils/setDefaults";
import { saveMessage } from "../message/message.actions";

// Import actions
import { checkTokenValidity } from "../user/user.actions";
import { skillsReducer } from "./skill.reducer";

export const getSkills = async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token") as string);

    const response = await axios.get<AxiosResponse<Skill[]>>(
      `${SOURCE_API}/api/Skill/Get`
    );

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
    return [];
  }
};

export const getMachineSkills = async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token") as string);

    const response = await axios.get<AxiosResponse<Skill[]>>(
      `${SOURCE_API}/api/Skill/GetMachineSkills`
    );

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const getGeneralSkills = async (dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token") as string);

    const response = await axios.get<AxiosResponse<Skill[]>>(
      `${SOURCE_API}/api/Skill/GetGeneralSkills`
    );

    return response.data.data;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const updateSkills = async (
  skills: (Skill | NewSkill)[],
  dispatch: any
) => {
  try {
    setDefaults(localStorage.getItem("token") as string);

    const response = await axios.put(`${SOURCE_API}/api/Skill`, skills);

    if (response.data.succeeded === true) {
      dispatch(
        saveMessage("Skills successfully updated!", MessageBarType.success)
      );
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const deleteSkill = async (id: string, dispatch: any) => {
  try {
    setDefaults(localStorage.getItem("token") as string);

    const response = await axios.delete(`${SOURCE_API}/api/Skill?id=${id}`);

    if (response.data.succeeded === true) {
      dispatch(
        saveMessage("Skill successfully updated!", MessageBarType.success)
      );
    } else {
      dispatch(saveMessage("There was an error"));
    }
    return response.data.succeeded;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const { setFilter } = skillsReducer.actions;
