import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Header, SideMenuSmall } from "../common/";
import { MachineTable, MachineServiceCalendar, MachineTeamsTable } from "./machines/";
import { useLocation } from "react-router-dom";
import { MACHINE_KEY_SELECT_PIVOT } from "../../utils/constants";
import { changeSelectedPivot } from "../../redux/app/app.actions";
import ScreenSizeAndPosition from "../common/ScreenSizeAndPosition";
import SearchOverlay from "../common/search/SearchOverlay";

const Machines = () => {
    const showSidebar = useSelector((state) => state.app.showSidebar);
    const { openSearchOverlay } = useSelector(state => state.app);
    const realPageWidth = document.documentElement.offsetWidth;
    const responsiveMarginLeft = showSidebar ? 144 : 40;
    const advanceResponsiveMarginLeft = showSidebar ? (realPageWidth > 1000 ? 144 : 124) : (realPageWidth > 1000 ? 40 : 20);
    const responsiveMarginLeftCalendar = showSidebar ? (realPageWidth > 1000 ? 124 : 104) : (realPageWidth > 1000 ? 20 : 0);
    const classNames = mergeStyleSets({
        root: {
            display: "flex",
            flexDirection: "row",
            minHeight: "100%",
        },
        pageContentContainer: {
            height: "100vh",
            width: "100%",
        },
        pageInnerContainer: {
            margin: `40px 124px 40px ${responsiveMarginLeft}px`,
            transition: 'all 0.5s ease',
        },
        responsiveContainer: {
            marginLeft: advanceResponsiveMarginLeft,
            transition: 'all 0.5s ease',
            width: `calc(100vw - ${advanceResponsiveMarginLeft + 22}px)`,
        },
        responsiveCalendarContainer: {
            marginLeft: responsiveMarginLeftCalendar,
            transition: 'all 0.5s ease',
        },
    });
    const location = useLocation();
    const dispatch = useDispatch();
    const selectedKey = useSelector(state => state.app.pivot.machine);


    useEffect(() => {
        if(location.state) {
            switch(location.state) {
                case MACHINE_KEY_SELECT_PIVOT.MACHINE_TABLE:
                    dispatch(changeSelectedPivot(MACHINE_KEY_SELECT_PIVOT.MACHINE_TABLE, 'machine'));
                    break;
                case MACHINE_KEY_SELECT_PIVOT.MACHINE_CALENDAR:
                    dispatch(changeSelectedPivot(MACHINE_KEY_SELECT_PIVOT.MACHINE_CALENDAR, 'machine'));
                    break;
                case MACHINE_KEY_SELECT_PIVOT.MACHINE_TABLE_TEAMS:
                    dispatch(changeSelectedPivot(MACHINE_KEY_SELECT_PIVOT.MACHINE_TABLE_TEAMS, 'machine'));
                    break;
                default:
                    dispatch(changeSelectedPivot(MACHINE_KEY_SELECT_PIVOT.MACHINE_TABLE, 'machine'));
                    break;
            }
        }
    }, [])

    let content = (
        <div className={classNames.responsiveContainer}>
            <ScreenSizeAndPosition>
                <MachineTable />
            </ScreenSizeAndPosition>
        </div>
    )

    switch(selectedKey) {
        case MACHINE_KEY_SELECT_PIVOT.MACHINE_CALENDAR:
            content = (
                <div className={classNames.responsiveCalendarContainer}>
                    <MachineServiceCalendar/>
                </div>
            )
            break;
        case MACHINE_KEY_SELECT_PIVOT.MACHINE_TABLE_TEAMS:
            content = (
                <div className={classNames.responsiveContainer}>
                    <ScreenSizeAndPosition><MachineTeamsTable/></ScreenSizeAndPosition>
                </div>
            )
            break;
        default:
            break;
    }

    return (
        <div className={classNames.root}>
            {openSearchOverlay && <SearchOverlay/>}
            <ScreenSizeAndPosition><SideMenuSmall/></ScreenSizeAndPosition>
            <div className={classNames.pageContentContainer}>
                <div className={classNames.pageInnerContainer}>
                    <ScreenSizeAndPosition><Header title="Maskiner" subTitle="OVERBLIK OVER MASKINER"/></ScreenSizeAndPosition>
                    <Pivot
                        selectedKey={selectedKey}
                        onLinkClick={(item) => dispatch(changeSelectedPivot(item.props.itemKey, 'machine'))}
                    >
                        <PivotItem headerText="MASKINER" itemKey={MACHINE_KEY_SELECT_PIVOT.MACHINE_TABLE}/>
                        <PivotItem headerText="SERVICE" itemKey={MACHINE_KEY_SELECT_PIVOT.MACHINE_CALENDAR}/>
                        <PivotItem headerText="TEAMS" itemKey={MACHINE_KEY_SELECT_PIVOT.MACHINE_TABLE_TEAMS}/>
                    </Pivot>
                </div>
                {content}
            </div>
        </div>
    );
};

export default Machines;
