import React, {
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
  useCallback,
  useEffect,
} from "react";
import { useSprings, animated, to } from "react-spring";
import { useGesture } from "react-use-gesture";

const clamp = (pos, low, high) => {
  const mid = Math.max(pos, low);
  return Math.min(mid, high);
};

const swap = (arr, from, to) => {
  const copy = [...arr];
  const [index] = copy.splice(from, 1);
  copy.splice(to, 0, index);
  return copy;
};

const CustomDraggableList = ({
  row,
  onDragEnd,
  children,
  maxLength,
  enableDrag,
  rowHeight,
}) => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    // After component loaded, enable the animation
    const ref = setTimeout(() => setAnimation(true), 1000);

    return () => {
      clearTimeout(ref);
    };
  }, []);

  const originLength = children.length;
  children = [...children, ...children];
  // Below block code is to prevent multiple stacked row display when changing children list
  if (children.length && children.length < maxLength) {
    const multiplier = Math.ceil(maxLength / children.length);
    let newChildren = [];
    for (let i = 0; i < multiplier; i++) {
      newChildren = [...newChildren, ...children];
    }
    children = newChildren;
  }

  // row/column display
  const view = useMemo(() => {
    return row
      ? {
          width: "width",
          height: "height",
          index: 0,
          sizes: ["scrollWidth", "scrollHeight"],
        }
      : {
          width: "height",
          height: "width",
          index: 1,
          sizes: ["scrollHeight", "scrollWidth"],
        };
  }, [row]);

  const [widthVal, setWidthVal] = useState(0);
  const [heightVal, setHeightVal] = useState(0);

  const animatedDiv = useRef(null);
  const order = useRef();
  if (!order.current) order.current = children.map((_, index) => index);

  const mapSprings = useCallback(
    (orderList = order.current, down, originalIndex, newWidth) => {
      return (index) =>
        down && index === originalIndex
          ? {
              active: "true",
              [view.width]: newWidth,
              [view.height]: 0,
              zIndex: 1,
              immediate: (key) =>
                key === "active" || key === view.width || key === "zIndex",
            }
          : {
              active: "",
              [view.width]: widthVal * orderList?.indexOf(index),
              [view.height]: 0,
              zIndex: 0,
              immediate: false,
            };
    },
    [view.height, view.width, widthVal]
  );

  let [springs, api] = useSprings(children.length, mapSprings());

  const bind = useGesture({
    onDrag({ args: [originalIndex], down, movement }) {
      const offset = movement[view.index];
      if (offset === 0) return;

      const curIndex = order.current.indexOf(originalIndex);
      const newWidth = widthVal * curIndex + offset;
      let nextIndex = clamp(
        Math.round(newWidth / widthVal),
        0,
        children.length - 1
      );
      if (nextIndex >= originLength) nextIndex = originLength - 1;
      const newOrder = swap(order.current, curIndex, nextIndex);

      api.start(mapSprings(newOrder, down, originalIndex, newWidth));
      if (!down) order.current = newOrder;
    },
    onDragEnd() {
      onDragEnd?.(order.current.slice(0, originLength));
    },
  });

  useLayoutEffect(() => {
    const [width, newHeight] = view.sizes.map(
      (key) => animatedDiv.current[key]
    );

    if (widthVal !== rowHeight || heightVal !== newHeight) {
      setWidthVal(rowHeight);
      setHeightVal(newHeight);
    } else {
      api.start(mapSprings());
    }
  }, [api, heightVal, mapSprings, view.sizes, widthVal]);

  // Function to remove animation on first load
  const animationStyle = (animation, width, height, index) => {
    return animation
      ? {
          transform: to(
            [width, height],
            (x, y) => `translate3d(${x}px, ${y}px, 0)`
          ),
        }
      : { marginTop: `${widthVal * index}px` };
  };

  const enablingDrag = (enableDrag, index) => (enableDrag ? bind(index) : {});

  return (
    <div
      style={{
        position: "relative",
        [view.width]: widthVal * originLength,
        [view.height]: heightVal,
      }}
    >
      {springs
        .slice(0, originLength)
        .map(({ active, width, height, zIndex }, index) => (
          <animated.div
            ref={animatedDiv}
            data-active={active.to((s) => s)}
            {...enablingDrag(enableDrag, index)}
            key={index}
            style={{
              position: "absolute",
              zIndex,
              ...animationStyle(animation, width, height, index),
            }}
          >
            {children[index]}
          </animated.div>
        ))}
    </div>
  );
};

export default CustomDraggableList;
