import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import moment from "moment";
import "moment/locale/da";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { connect, useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import {
  setBarDrawer,
  setBarDrawingMachineService,
  setDrawingMachineReqIndex,
  setDrawingProjectId,
  setDrawingProjectName,
  setHighlightMachine,
  setHighlightStaff,
} from "../../../../redux/app/app.actions";
import { updateMachine } from "../../../../redux/machines/machines.actions";
import { saveMessage } from "../../../../redux/message/message.actions";
import { createPlan, getPlan } from "../../../../redux/plan/plan.actions";
import {
  createPrebooking,
  updatePrebooking,
} from "../../../../redux/prebooking/prebooking.actions";
import {
  getProject,
  updateProject,
} from "../../../../redux/project/project.actions";
import { updateStaff } from "../../../../redux/user/user.actions";
import {
  calendarConfirmationStatus,
  reasonVacationStaffOptions,
  workingRoleOptions,
} from "../../../../utils/constants";
import {
  checkTimeOverlap,
  findStaffBarVariant,
  hasEditRight,
  lighterDarkerColor,
  updateProjectData,
} from "../../../../utils/utils";
import { TooltipForText } from "../../../common";
import ConfirmBubble from "../../../common/ConfirmBubble";
import ConfirmProjectBubble from "../../../common/ConfirmProjectBubble";
import ConfirmStaffVacationBubble from "../../../common/ConfirmStaffVacationBubble";
import { getStringifiedMachine } from "../../../../redux/machines/machine.selector";
import { getCrewServiceSchedule } from "../../../../utils/service";

const classNames = mergeStyleSets({
  nameInput: {
    background: "transparent",
    width: "90%",
  },
});

const BarDrawer = ({
  calendarStepWidth,
  calendarStepHeight,
  horizontalPositions,
  verticalPositions,
  calendarStart,
  calendarEnd,
  saveMessage,
  machinesData,
  staffsData,
  staffsWithPosition,
  displayStaffOnPlanning,
  onInvalidatePlansQuery,
  renderDependencies: propRenderDependencies,
  techDepartment,
  optimisticUpdateProject,
}) => {
  const drawerTypes = {
    plan: {
      text: "Plan",
      textColor: "#fff",
      colorCode: "0,107,173",
      onSave: () => savePlan(),
    },
    prebooking: {
      text: "Prebooking",
      textColor: "#575756",
      colorCode: "227,181,5",
      onSave: () => savePrebooking(),
      border: "none",
    },
    machineService: {
      text: "Machine service",
      textColor: "#575756",
      colorCode: "241,241,241",
      onSave: () => saveMachineService(),
      border: "3px solid #fff",
    },
    staffVacation: {
      text: "Staff vacation",
      textColor: "#575756",
      colorCode: "241,241,241",
      onSave: () => saveStaffVacation(),
      border: "3px solid #fff",
    },
  };
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const defaultData = useSelector((state) => state.defaultData);
  const allowEdit = hasEditRight(userRoles);
  const {
    barDrawer,
    highlightedMachineId,
    highlightedStaffId,
    drawingProjectId,
    drawingMachineReqIndex,
    drawingProjectName,
  } = useSelector((state) => state.app);
  const currentDrawType = drawerTypes[barDrawer];
  const [barInfo, setBarInfo] = useState({
    id: `Id-${currentDrawType.text} ${new Date().toUTCString()}`,
    start: null,
    end: null,
    currentItemIndex: null, // index of machine/staff with the y axis
    name: `${currentDrawType.text} ${new Date().toUTCString()}`,
  });
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const { comment, reason } = useSelector(
    (state) => state.app.barMachineServiceData
  );

  useEffect(() => {
    if (barDrawer === "machineService" && comment && reason) {
      setBarInfo((prev) => ({
        ...prev,
        name: comment,
      }));
    }
  }, [barDrawer, comment, reason]);

  const userZoomRatio = 1;
  const currentUserId = useSelector((state) => state?.user?.user?.userId);

  const calendarState = useSelector(getStringifiedMachine);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [confirmation, setConfirmation] = useState(
    calendarConfirmationStatus.CONFIRMATION_UNSET
  );
  const [timerRef, setTimerRef] = useState();
  const [width, setWidth] = useState(0);
  const [display, setDisplay] = useState(false);
  const [drawing, setDrawing] = useState(true);
  const [editName, setEditName] = useState(false);
  const queryClient = useQueryClient();
  const ref = useRef(null);
  const renderDependencies =
    propRenderDependencies ||
    calendarState + JSON.stringify(verticalPositions) + JSON.stringify(barInfo);
  if (!ref.current) ref.current = renderDependencies;
  const shouldComponentRerender = ref.current !== renderDependencies;

  const [updatePackage, setUpdatePackage] = useState(null);

  const { data: projectData } = useQuery(
    ["project", drawingProjectId],
    () => dispatch(getProject(drawingProjectId)),
    {
      staleTime: 36000,
      initialData: () =>
        queryClient
          .getQueryData("projects")
          ?.find((project) => project.projectId === drawingProjectId),
    }
  );

  const { data: planData } = useQuery(["plan", drawingProjectId], () =>
    dispatch(getPlan(drawingProjectId))
  );

  const { mutate: mutateCreatePrebooking } = useMutation(
    (prebookingData) => dispatch(createPrebooking(prebookingData)),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("prebookings");
          cancelDrawBar();
        }
      },
    }
  );

  const { mutate: updateMachineService } = useMutation(
    (machine) => dispatch(updateMachine(machine)),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("machines");
          cancelDrawBar();
        }
      },
    }
  );

  const { mutate: updateStaffMutation } = useMutation(
    (staff) => dispatch(updateStaff(staff)),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("plans");
          if (onInvalidatePlansQuery) onInvalidatePlansQuery(data);

          queryClient.invalidateQueries("staffs");
          cancelDrawBar();
        }
      },
    }
  );

  const mutationCreatePlan = useMutation(
    (project) => dispatch(createPlan(project)),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("plans");
          queryClient.invalidateQueries("projects");
          if (onInvalidatePlansQuery) onInvalidatePlansQuery(data);
          cancelDrawBar();
        }
      },
    }
  );

  const mutationUpdateProject = useMutation(
    (project) => dispatch(updateProject(project)),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("projects");
        queryClient.invalidateQueries("plans");
        if (onInvalidatePlansQuery) onInvalidatePlansQuery(data);
      },
    }
  );

  useEffect(() => {
    if (projectData) {
      setBarInfo({
        ...barInfo,
        name: projectData.projectName,
        section: projectData.section,
      });
    }
  }, [projectData]);

  const colorCode =
    projectData?.color || barInfo.color || currentDrawType.colorCode;
  let bgColor = `repeating-linear-gradient(90deg, rgb(${colorCode}), rgb(${colorCode}) 24px, rgb(${lighterDarkerColor(
    colorCode,
    10
  )}) 24px, rgb(${lighterDarkerColor(colorCode, 10)}) 48px)`;
  if (barDrawer === "plan") {
    bgColor = `repeating-linear-gradient(-45deg, rgb(${colorCode}), rgb(${colorCode}) 24px, rgb(${lighterDarkerColor(
      colorCode,
      10
    )}) 24px, rgb(${lighterDarkerColor(colorCode, 10)}) 48px)`;
  }

  useEffect(() => {
    changeCursorOfPlanContainer(drawing);

    // Style the resize cursor
    const barDiv = document.getElementById(barInfo.id);
    if (barDiv) {
      barDiv.parentElement.lastChild.firstChild.style.cursor = allowEdit
        ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
        : "default";
      barDiv.parentElement.lastChild.firstChild.style.left = "-14px";
      barDiv.parentElement.lastChild.lastChild.style.cursor = allowEdit
        ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
        : "default";
      barDiv.parentElement.lastChild.lastChild.style.right = "3px";
    }
  }, [drawing, allowEdit, barInfo.id]);

  const changeCursorOfPlanContainer = (drawing) => {
    const planContainer = document.getElementsByClassName("planContainer")[0];
    planContainer.style.cursor = drawing
      ? `url(${window.location.origin}/img/pencil-alt.svg), auto`
      : "default";
  };

  const isOverlap = (newX, newWidth, newY) => {
    const newStart = parseInt(parseInt(newX / calendarStepWidth).toFixed(0));
    const newEnd = parseInt(
      parseInt(newStart + newWidth / calendarStepWidth).toFixed(0)
    );
    if (displayStaffOnPlanning && machinesData) {
      const staffData = Object.values(verticalPositions.positionsToIds).find(
        (item) => item.id === barInfo.currentStaffId
      );
      const startDate = new Date(
        horizontalPositions.positionsToDates[newStart].slice(0, 10)
      );
      let endDate = new Date(
        horizontalPositions.positionsToDates[newEnd].slice(0, 10)
      );
      endDate.setDate(endDate.getDate() - 1);

      const serviceSchedules = getCrewServiceSchedule(
        staffData.id,
        machinesData
      );

      const listToCheck = [
        ...staffData.listOfPlan,
        ...staffData.listOfVacation,
        ...serviceSchedules,
      ];
      for (let i = 0; i < listToCheck.length; i++) {
        let itemStartDate = new Date(listToCheck[i].start.slice(0.1));
        let itemEndDate = new Date(listToCheck[i].end.slice(0.1));
        const isOverlap = checkTimeOverlap(
          startDate,
          endDate,
          itemStartDate,
          itemEndDate
        );

        if (isOverlap) {
          saveMessage("Cannot Overlap");
          setDisplay(false);
          return true;
        }
      }
    } else {
      const tempY = newY ?? y;
      // Prevent the project go out of vertical range
      if (!verticalPositions["positionsToIds"][tempY]) {
        return true;
      }
      let tempList = JSON.parse(
        JSON.stringify(verticalPositions["positionsToIds"][tempY])
      );
      if (y === tempY) {
        const preStart = x / calendarStepWidth;
        const preEnd = preStart + width / calendarStepWidth;
        const index = tempList.listOfPlan.findIndex(
          (item) => item.start === preStart && preEnd
        );
        index !== -1 && tempList.listOfPlan.splice(index, 1);
      }
      const listToCheck = [...tempList.listOfPlan, ...tempList.listOfService];
      for (let i = 0; i < listToCheck.length; i++) {
        let itemStart = listToCheck[i].start;
        let itemEnd = listToCheck[i].end;
        const startBeforeCurrentPlanEnd =
          typeof itemEnd === "undefined" ? true : newStart <= itemEnd;
        const endAfterCurrentPlanStart =
          typeof itemStart === "undefined" ? true : newEnd > itemStart;

        if (startBeforeCurrentPlanEnd && endAfterCurrentPlanStart) {
          console.log("Meow 2");
          saveMessage("Cannot Overlap");
          setDisplay(false);
          setDisplay(true);
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown, { passive: true });
    window.addEventListener("mousemove", handleMouseMove, { passive: true });
    window.addEventListener("mouseup", handleMouseUp, { passive: true });
    window.addEventListener("keydown", handleEscClick, { passive: true });
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("keydown", handleEscClick);
    };
  });

  useEffect(() => {
    if (timerRef) {
      return () => {
        clearTimeout(timerRef);
      };
    }
  }, [timerRef]);

  const handleMouseDown = (e) => {
    if (drawing) {
      const yPosition = e.pageY / userZoomRatio - 301; // 301 is starting point of y axis
      const xPosition = e.pageX / userZoomRatio - 354; // 354 is starting point of x axis
      let currentItemIndex = null;
      let startOfBar = Math.floor(xPosition / calendarStepWidth);
      let name = barInfo.name;

      // For staff vacation
      let staffY = null;
      let currentStaffId = null;
      let reason = 0;
      let notes = null;

      if (displayStaffOnPlanning) {
        const matchedStaff = staffsWithPosition.find(
          (item) =>
            yPosition >= item.y && yPosition <= item.y + calendarStepHeight
        );
        if (!matchedStaff) {
          cancelDrawBar();
        } else if (barDrawer === "staffVacation") {
          if (drawingProjectName) {
            reason = 2;
            notes = drawingProjectName;
          }
          staffY = matchedStaff.y;
          currentStaffId = matchedStaff.id;
          name = reasonVacationStaffOptions.find(
            (item) => item.key === reason
          ).text;
        } else {
          staffY = matchedStaff.y;
          currentStaffId = matchedStaff.id;
          name = drawingProjectName;
        }
      } else {
        currentItemIndex = Math.floor(yPosition / calendarStepHeight);
        if (
          yPosition < 0 ||
          !verticalPositions["positionsToIds"][
            currentItemIndex * calendarStepHeight
          ]
        )
          cancelDrawBar();
      }

      setBarInfo({
        ...barInfo,
        start: startOfBar,
        end: startOfBar + 1,
        currentItemIndex: currentItemIndex,
        currentStaffId,
        reason,
        y: staffY,
        name,
        notes,
      });
    }
  };

  const handleMouseMove = (e) => {
    // Highlight the machine has same y axis
    if (drawing) {
      const yPosition = e.pageY / userZoomRatio - 301; // 301 is starting point of y axis
      if (displayStaffOnPlanning) {
        const matchedStaff = staffsWithPosition.find(
          (item) =>
            yPosition >= item.y &&
            yPosition <=
              item.y +
                findStaffBarVariant(verticalPositions, item.id) *
                  calendarStepHeight
        );
        if (matchedStaff?.id !== highlightedStaffId) {
          dispatch(setHighlightStaff(matchedStaff?.id));
        }
      } else {
        const currentItemIndex = Math.floor(yPosition / calendarStepHeight);
        const machineData =
          verticalPositions["positionsToIds"][
            currentItemIndex * calendarStepHeight
          ];
        if (highlightedMachineId !== machineData?.id)
          dispatch(setHighlightMachine(machineData ? machineData.id : null));
      }
    }

    if (drawing && barInfo.start !== null) {
      const xPosition = e.pageX / userZoomRatio - 354; // 354 is starting point of x axis
      const endOfBar = Math.floor(xPosition / calendarStepWidth);
      setBarInfo({
        ...barInfo,
        end: endOfBar,
      });

      if (barInfo.start >= 0) {
        setWidth((endOfBar - barInfo.start) * calendarStepWidth);
      }
    }
  };

  const handleMouseUp = (e) => {
    if (drawing) {
      const xPosition = e.pageX / userZoomRatio - 354; // 354 is starting point of x axis
      const endOfBar = Math.floor(xPosition / calendarStepWidth);
      const isBackwardDrawing = endOfBar < barInfo.start;
      const overlap = isOverlap(
        barInfo.start * calendarStepWidth,
        (endOfBar - barInfo.start) * calendarStepWidth,
        barInfo.currentItemIndex * calendarStepHeight
      );
      if (isBackwardDrawing || overlap) cancelDrawBar();
      setDrawing(false);
      setBarInfo({
        ...barInfo,
        end: endOfBar === barInfo.start ? endOfBar + 1 : endOfBar,
      });
      setConfirmation(calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED);
      if (barDrawer !== "plan") {
        if (timerRef) {
          clearTimeout(timerRef);
        }
        const ref = setTimeout(() => setEditName(true), 100);
        setTimerRef(ref);
      }
    }
  };

  const handleEscClick = ({ key }) => {
    if (key === "Escape") cancelDrawBar();
  };

  useEffect(() => {
    if ((!x && !y) || shouldComponentRerender) {
      if (shouldComponentRerender) ref.current = renderDependencies;
      setX(barInfo.start * calendarStepWidth);
      setY(
        displayStaffOnPlanning
          ? barInfo.y
          : barInfo.currentItemIndex * calendarStepHeight
      );
      setWidth((barInfo.end - barInfo.start) * calendarStepWidth);
      setDisplay(true);
    }
  }, [
    horizontalPositions,
    verticalPositions,
    calendarStart,
    calendarEnd,
    calendarStepWidth,
    barInfo,
    shouldComponentRerender,
    renderDependencies,
    x,
    y,
    calendarStepHeight,
    displayStaffOnPlanning,
  ]);

  const savePrebooking = async () => {
    const machineData = verticalPositions["positionsToIds"][y];
    const startDate = horizontalPositions["positionsToDates"][barInfo.start];
    const endDate = horizontalPositions["positionsToDates"][barInfo.end - 1];
    const startText = moment(startDate, "YYYY-M-D").format("D.M.YY");
    const endText = moment(endDate, "YYYY-M-D").format("D.M.YY");
    const data = {
      projectName: barInfo.name,
      personResponsbible: {
        userId: null,
        name: null,
      },
      machineId: machineData.id,
      start: startDate,
      end: endDate,
      activityLog: {
        type: 1,
        resourceType: 3,
        userId: currentUserId,
        comment: `Created this Prebooking with period "${startText} - ${endText}"`,
      },
    };
    await mutateCreatePrebooking(data);
  };
  const saveMachineService = async () => {
    const startDate = horizontalPositions["positionsToDates"][barInfo.start];
    const endDate = horizontalPositions["positionsToDates"][barInfo.end - 1];
    const selectedMachine = verticalPositions["positionsToIds"][y];
    const machineData = machinesData.find(
      (item) => item.machineId === selectedMachine.id
    );
    const newService = {
      comment,
      start: startDate,
      end: endDate,
      color: "110,84,47", // Brown
      reason,
    };
    const machineService = machineData?.machineService?.length
      ? [...machineData.machineService, newService]
      : [newService];
    const machine = { ...machineData, machineService };
    await updateMachineService(machine);
  };

  const saveStaffVacation = async () => {
    const staffData = staffsData.find(
      (item) => item.userId === barInfo.currentStaffId
    );
    const start = horizontalPositions["positionsToDates"][barInfo.start];
    const end = horizontalPositions["positionsToDates"][barInfo.end - 1];
    let color = null;
    switch (barInfo.reason) {
      case 0:
        color = "245,124,0"; // orange
        break;
      case 1:
        color = "0,108,173"; // blue
        break;
      case 2:
        color = "26, 147, 111"; // green
        break;
      default:
    }
    let vacationStaffForm = {
      color,
      reason: barInfo.reason,
      notes: barInfo.notes,
      start,
      end,
    };
    const starfVacations =
      staffData.starfVacations && staffData.starfVacations.length > 0
        ? [...staffData.starfVacations, vacationStaffForm]
        : [vacationStaffForm];
    const staff = { ...staffData, starfVacations };
    await updateStaffMutation(staff);
  };

  const savePlan = async () => {
    const start = horizontalPositions["positionsToDates"][barInfo.start];
    const end = horizontalPositions["positionsToDates"][barInfo.end - 1];
    let machineRequirements = planData?.machineRequirements || [];

    if (displayStaffOnPlanning) {
      const staffData = staffsData.find(
        (item) => item.userId === barInfo.currentStaffId
      );
      const firstRoleKey = staffData.workingRole
        .split(";")
        .map((item) => parseInt(item))
        .filter((item) => item < 3)[0];
      const roleName = workingRoleOptions.find(
        (item) => item.key === firstRoleKey
      ).name;

      machineRequirements[drawingMachineReqIndex] = {
        ...machineRequirements[drawingMachineReqIndex],
        start,
        end,
        techDepartment,
        [roleName]: [
          {
            id: staffData.userId,
            firstName: staffData.firstName,
            lastName: staffData.lastName,
            start,
            end,
          },
        ],
      };
    } else {
      const selectedMachine = verticalPositions["positionsToIds"][y];
      const machineData = machinesData.find(
        (item) => item.machineId === selectedMachine.id
      );

      if (drawingMachineReqIndex !== null) {
        machineRequirements[drawingMachineReqIndex] = {
          ...machineRequirements[drawingMachineReqIndex],
          start,
          end,
          techDepartment,
          machines: [
            {
              ...machineData,
              start,
              end,
            },
          ],
        };
      } else {
        machineRequirements = [
          ...machineRequirements,
          {
            machineType: machineData.machineType,
            // techDepartment: machineData.techDepartment,
            techDepartment,
            sizeGroup: machineData.sizeGroup,
            start,
            end,
            machines: [
              {
                ...machineData,
                start,
                end,
              },
            ],
            drivers: [],
            workers: [],
            managers: [],
          },
        ];
      }
    }

    // Activitet set to none for drawing plan
    if (drawingMachineReqIndex && machineRequirements[drawingMachineReqIndex]) {
      machineRequirements[drawingMachineReqIndex].crmActivities = null;
    }

    const plan = {
      projectId: drawingProjectId,
      color: projectData.color || barInfo.color,
      // section: projectData.section || barInfo.section,
      machineRequirements,
      start: projectData.start,
      end: projectData.end,
    };

    cancelDrawBar();

    optimisticUpdateProject(plan);

    if (
      !projectData.color ||
      !projectData.section ||
      projectData.section === -1
    ) {
      const updatedProjectData = updateProjectData(
        projectData,
        {
          ...projectData,
          section: barInfo.section || projectData.section,
          color: barInfo.color || projectData.color,
        },
        defaultData,
        currentUserId
      );

      await mutationUpdateProject.mutate(updatedProjectData);
    }

    await mutationCreatePlan.mutate(plan);
  };

  const rollback = ({ previousX, previousY, previousW }) => {
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
    setX(previousX);
    setY(previousY);
    setWidth(previousW);
    setUpdatePackage(null);
  };

  const cancelDrawBar = () => {
    changeCursorOfPlanContainer(false);
    dispatch(setHighlightMachine(null));
    dispatch(setHighlightStaff(null));
    setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET);
    dispatch(setBarDrawer(""));
    dispatch(setDrawingProjectId(null));
    dispatch(setDrawingProjectName(null));
    dispatch(setDrawingMachineReqIndex(null));
    dispatch(
      setBarDrawingMachineService({
        comment: "",
        service: "",
      })
    );
  };

  let content = (
    <div
      className={editName ? "modifying" : "dragHandle"}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "calc(100% - 15px)",
        height: "100%",
        cursor: allowEdit
          ? `url(${window.location.origin}/img/arrows-alt.svg), auto`
          : "default",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          marginLeft: displayStaffOnPlanning ? 5 : 10,
        }}
      >
        <div style={{ marginTop: displayStaffOnPlanning ? -4 : 4 }}>
          {editName &&
          !displayStaffOnPlanning &&
          barDrawer !== "machineService" ? (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onBlur={() => setEditName(false)}
            >
              <input
                className={classNames.nameInput}
                value={barInfo["name"]}
                onChange={(e) =>
                  setBarInfo({
                    ...barInfo,
                    name: e.target.value,
                  })
                }
                autoFocus
                onFocus={(e) => e.target.select()}
              ></input>
            </div>
          ) : (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                userSelect: "none",
                marginTop: displayStaffOnPlanning ? 2 : 0,
              }}
            >
              <span
                style={{
                  color: currentDrawType.textColor,
                  fontWeight: "bold",
                  fontSize: 11,
                  marginRight: 8,
                  cursor: "text",
                }}
                onClick={() => setEditName(true)}
              >
                {barDrawer === "machineService"
                  ? `${comment} (${reason})`
                  : barInfo["name"]}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    display &&
    width > 0 && (
      <Rnd
        style={{ zIndex: 3, top: 0, left: 0, display: "absolute" }}
        size={{ width: width, height: calendarStepHeight }}
        position={{ x: x, y: y }}
        enableResizing={{
          left: allowEdit && !drawing ? true : false,
          right: allowEdit && !drawing ? true : false,
        }}
        onResize={() =>
          setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET)
        }
        onResizeStop={(e, direction, ref, delta, position) => {
          setConfirmation(
            calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
          );
          const prevX = x;
          const prevW = width;
          const newX = parseInt(position.x.toFixed(0));
          let newWidth = ref.offsetWidth;

          // Sometime the offsetWidth value maybe differnt 1 or 2 pixel which causing wrong value, below block code handle that case
          if (newWidth % calendarStepWidth !== 0) {
            newWidth =
              (newWidth / calendarStepWidth).toFixed(0) * calendarStepWidth;
          }
          if (newX === prevX && newWidth === prevW) {
            return;
          }
          if (isOverlap(newX, newWidth)) {
            rollback({ previousY: y, previousW: prevW });
            return;
          }
          setX(newX);
          setWidth(newWidth);
          const start = Math.floor(newX / calendarStepWidth);
          const end = Math.floor((newX + newWidth) / calendarStepWidth);
          setBarInfo({
            ...barInfo,
            start,
            end,
          });
        }}
        onDrag={() =>
          setConfirmation(calendarConfirmationStatus.CONFIRMATION_UNSET)
        }
        onDragStop={(e, d) => {
          setConfirmation(
            calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED
          );
          // this is kinda suprising but the values that match the grids are the lastX and lastY instead of x and y, so that is what we set
          const prevX = x;
          const prevY = y;
          const newX = parseInt(d.lastX.toFixed(0));
          const newY = parseInt(d.lastY.toFixed(0));
          if (newX === prevX && newY === prevY) {
            return;
          }
          if (isOverlap(newX, width, newY)) {
            return;
          }
          setX(newX);
          setY(newY);
          const start = Math.floor(newX / calendarStepWidth);
          const end = Math.floor((newX + width) / calendarStepWidth);
          const currentItemIndex = Math.floor(newY / calendarStepHeight);
          setBarInfo({
            ...barInfo,
            start,
            end,
            currentItemIndex,
          });
        }}
        resizeGrid={[calendarStepWidth, calendarStepHeight]}
        dragGrid={[calendarStepWidth, calendarStepHeight]}
        dragAxis="both"
        bounds=".planContainer"
        dragHandleClassName="dragHandle"
      >
        <div
          id={barInfo.id}
          style={{
            background: bgColor,
            display: "flex",
            marginTop: 5,
            height: calendarStepHeight - 8,
            borderRadius: 4,
            border: currentDrawType.border,
            boxSizing: "border-box",
          }}
        >
          {confirmation ===
            calendarConfirmationStatus.CONFIRMATION_DECISION_NEEDED &&
            typeof updatePackage === "object" &&
            (displayStaffOnPlanning && !drawingProjectName ? (
              <ConfirmStaffVacationBubble
                onCancel={cancelDrawBar}
                onApprove={currentDrawType.onSave}
                targetId={barInfo.id}
                reason={barInfo.reason}
                notes={barInfo.notes || ""}
                setReason={(value) =>
                  setBarInfo({
                    ...barInfo,
                    name: reasonVacationStaffOptions.find(
                      (item) => item.key === value
                    ).text,
                    reason: value,
                  })
                }
                setNotes={(value) =>
                  setBarInfo({
                    ...barInfo,
                    notes: value,
                  })
                }
              />
            ) : barDrawer === "plan" &&
              (!projectData.color ||
                projectData.section === -1 ||
                !projectData.section) ? (
              <ConfirmProjectBubble
                onCancel={cancelDrawBar}
                onApprove={currentDrawType.onSave}
                targetId={barInfo.id}
                projectData={projectData}
                section={barInfo.section}
                setSection={(value) =>
                  setBarInfo({
                    ...barInfo,
                    section: value,
                  })
                }
                color={barInfo.color}
                setColor={(value) =>
                  setBarInfo({
                    ...barInfo,
                    color: value,
                  })
                }
              />
            ) : (
              <ConfirmBubble
                onCancel={cancelDrawBar}
                onApprove={currentDrawType.onSave}
                targetId={barInfo.id}
              />
            ))}
          <TooltipForText text={barInfo["name"]}>{content}</TooltipForText>
        </div>
      </Rnd>
    )
  );
};

export default connect(null, { saveMessage, updatePrebooking })(BarDrawer);
