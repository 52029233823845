// Import libraries
import { MessageBarType, mergeStyleSets } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Prompt, Redirect, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { datesGenerator } from "dates-generator";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faDigging,
  faUserHardHat,
  faUserTie,
} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

// Import components
import { CalendarControlsDetailedPlanning } from "./planning/detailedPlanning";
import MachineServiceCalendar from "./machineservice/MachineServiceCalendar";
import PlanContainer from "./machineservice/PlanContainer";
import MachineSection from "./machineservice/MachineSection";
import SectionSeparator from "./machineservice/SectionSeparator";
import Section from "./machineservice/Section";
import MachineServiceBar from "./machineservice/MachineServiceBar";

// Import redux
import { getHolidayList } from "../../redux/app/app.actions";
import {
  getMachine,
  getMachines,
  getTeams,
  updateMachine,
} from "../../redux/machines/machines.actions";

// Import types
import { TPlanHorizontalPositionMap } from "../../types/planner";
import {
  TDates,
  TDatesGenerator,
  THoliday,
  TMachine,
  TMachineService,
  TMachineTeamModel,
  TPlan,
  TStaffVacation,
  User,
  UserWorkerVM,
} from "../../types";

// Import utils
import {
  addDaysToDate,
  findStaffSchedule,
  findYearOfDates,
  getCalendarHoliday,
  getLastDate,
  getMonday,
  hasEditRight,
  isProdUrl,
  sleep,
  startCalendarFromSelectedWeek,
} from "../../utils/utils";

import {
  BACKGROUND_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  COLOR_GREY_OUT,
  ID_TARGET,
  RND_BAR_HEIGHT,
  // RND_BAR_HEIGHT,
  ROLES,
  workingRoleOptions,
} from "../../utils/constants";

import { getCrewServiceSchedule } from "../../utils/service";
import { getWidthStartEndNumberByHorizontalPositionMap } from "../../utils/staff";
import {
  TVerticalMap,
  checkOverlap,
  findMostSuitableTime,
  generateUserDateMaps,
  generateVerticalMap,
  getMachineBookedProjects,
  getMachinePrebookings,
  getRole,
  getServiceText,
} from "../../utils/mapping";

// Import redux
import { getStaffs } from "../../redux/user/user.actions";
import { getPlans } from "../../redux/plan/plan.actions";

// Import types
import { AllMachineData, AllUserData } from "../../types/custom";

// Import redux
import { OverlapKeys } from "../../redux/project/project.actions";
import { saveMessage } from "../../redux/message/message.actions";

import MachineBookingBar from "./machineservice/MachineBookingBar";
import { getPrebookings } from "../../redux/prebooking/prebooking.actions";
import StaffVacationBar from "./machineservice/StaffVacationBar";
import CustomBarDrawer from "../common/CustomBarDrawer";
import BarDrawer from "./machineservice/BarDrawer";
// import StaffServiceSchedule from "./machineservice/StaffServiceSchedule";

const getClassNames = ({
  heightOfBackground,
  widthOfCalendar,
}: {
  widthOfCalendar: number;
  heightOfBackground: number;
}) => {
  return mergeStyleSets({
    root: {
      display: "flex",
      flexDirection: "row",
    },
    pageContentContainer: {
      display: "flex",
      background: "#F1F1F1",
      width: "100%",
    },
    pageInnerContainer: {
      margin: "40px 0 40px 118px",
    },
    pageContent: {
      display: "flex",
      width: widthOfCalendar,
    },
    leftContainer: {
      position: "sticky",
      top: 0,
      left: 0,
      background: "#f1f1f1",
      marginLeft: -318,
      marginTop: 100,
      // height: heightOfBackground,
      zIndex: 999,
    },
    sideBarContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 30,
      minHeight: 110,
    },
    sideBarHeader: {
      display: "flex",
      marginLeft: 65,
      marginTop: 12,
    },
    sideBarRightContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: 100,
      marginRight: 10,
      marginLeft: -20,
    },
    sidebarIconContainer: {
      width: 100,
      display: "flex",
      alignItems: "center",
      position: "relative",
      top: 5,
      justifyContent: "end",
    },
    calendarContainer: {
      width: widthOfCalendar,
    },
    days: {
      display: "flex",
      justifyContent: "flex-start",
    },
    dayOfWeeks: {
      marginTop: -15,
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8,
    },
    weeks: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 4,
    },
    week: {
      minWidth: 175,
      height: 20,
      background: "#E7E7E7",
      fontSize: 11,
      lineHeight: 20,
      borderRadius: 10,
    },
    image: {
      marginRight: "15px",
      fontSize: 16,
    },
    iconPlusContainer: {
      position: "relative",
      left: -61,
      top: 10,
    },
    iconPlus: {
      fontSize: 28,
      height: 29,
      color: "#006CAD",
      selectors: {
        ":hover": {
          color: "#DBDBDB",
        },
      },
      cursor: "pointer",
    },
    iconPlusCircle: {
      marginTop: 10,
      marginLeft: 20,
    },
    functionalIcon: {
      fontSize: 18,
      height: 16,
      color: "#006CAD",
      selectors: {
        ":hover": {
          color: "#DBDBDB",
        },
      },
      cursor: "pointer",
      marginRight: "10px",
    },
    sidebarSeparatorContainer: {
      marginTop: 25,
      height: "20px",
      position: "relative",
      left: 50,
    },
    separatorContainerStyle: {
      marginTop: 60,
      height: "20px",
      position: "relative",
      left: -230,
      width: "calc(100% + 230px)",
    },
    separatorStyle: {
      border: "1px solid #DFDFDF",
    },
    titleElementStyle: {
      display: "flex",
      alignItems: "center",
      height: "100px",
      position: "absolute",
      left: -245,
    },
    titleElementStyleCustom: {
      display: "flex",
      alignItems: "center",
      height: "100px",
      position: "absolute",
      left: -210,
      top: 30,
    },
    titleNameElementStyle: {
      fontWeight: "bold",
      marginRight: "16px",
    },
    titleNameElementStyleCustom: {
      fontWeight: "bold",
      marginRight: "16px",
      color: "#006CAD",
      cursor: "pointer",
    },
    contentElementStyle: {
      position: "relative",
      top: 25,
    },
    avatarContainerStyle: {
      position: "relative",
      left: -110,
      top: 5,
      width: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
    personaStyle: {
      width: 28,
      marginRight: "10px",
    },
    expandCalendarIcon: {
      marginLeft: 30,
      fontSize: 35,
      color: "#006CAD",
      cursor: "pointer",
    },
  });
};

type TDrawData = {
  role: OverlapKeys;
  id: string;
  isNew?: boolean;
};

export type TMachineServiceDrawData = TDrawData;

const MachineService = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { machineId, serviceIndex } = useParams<{
    machineId: string;
    serviceIndex: string;
  }>();

  const [toggleRefreshFunctions, setToggleRefreshFunctions] = useState<{
    refreshMachineService?: () => void;
  }>({});

  const staffRefreshRef = useRef<() => void>();

  const initialDataRef = useRef<{
    drivers: AllUserData[];
    workers: AllUserData[];
    supervisors: AllUserData[];
    machineService: TMachineService;
  }>();

  const [drawDatas, setDrawDatas] = useState<{
    driverDrawData?: TDrawData;
    workerDrawData?: TDrawData;
    supervisorDrawData?: TDrawData;
  }>({});

  const [newUserData, setNewUserData] = useState<{
    id: string;
    role: OverlapKeys;
  }>();

  const [drawExistingUserData, setExistingUserData] = useState<{
    id: string;
    role: OverlapKeys;
  }>();

  const [saveRef, setSaveRef] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (saveRef) {
      return () => {
        clearTimeout(saveRef);
      };
    }
  }, [saveRef]);

  // ======================================== Use Selector =====================================
  const displayWeekView = useSelector(
    // @ts-ignore
    (state) => state.machine.displayWeekView
  ) as boolean;

  // @ts-ignore
  const { techDepartment } = useSelector((state) => state?.machine?.filter) as {
    techDepartment: string;
  };

  // @ts-ignore
  const { addCalendarLength } = useSelector((state) => state.app) as number;

  const userRoles = useSelector(
    // @ts-ignore
    (state) => state?.user?.user?.workingRole
  ) as string;

  const allowEdit = hasEditRight(userRoles);

  // ======================================== Use Refs ======================================
  const holidayRef = useRef<{
    years: number[];
    holidays: THoliday[];
  } | null>(null);

  // ======================================== Use State =====================================
  const [calendarStepWidth] = useState(
    displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
  );

  const [machineService, setMachineService] = useState<TMachineService | null>(
    null
  );

  const [dates, setDates] = useState<TDates>([]);

  const [drivers, setDrivers] = useState<AllUserData[]>([]);
  const [workers, setWorkers] = useState<AllUserData[]>([]);
  const [supervisors, setSupervisors] = useState<AllUserData[]>([]);

  // This is all of the staffs pulled. We get their entire schedule and
  // store it in this
  // Because we don't want to keep doing it in runtime
  const [allUsersData, setAllUsersData] = useState<AllUserData[]>([]);

  const [machineTeamsData, setMachineTeamsData] = useState<TMachineTeamModel[]>(
    []
  );

  const [horizontalPositionMap, setHorizontalPositionMap] =
    useState<TPlanHorizontalPositionMap | null>(null);

  const [leftHeight, setLeftHeight] = useState<number>(0);

  useEffect(() => {
    const waitForRender = async () => {
      let observedElement = document.getElementById(
        "leftContainer"
      ) as HTMLElement;

      while (!observedElement) {
        observedElement = document.getElementById(
          "leftContainer"
        ) as HTMLElement;

        if (!observedElement) await sleep(100);
      }

      if (!observedElement) return;

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === observedElement) {
            setLeftHeight(entry.contentRect.height + 10);
          }
        }
      });

      resizeObserver.observe(observedElement);

      return () => {
        resizeObserver.unobserve(observedElement);
      };
    };

    waitForRender();
  }, []);

  // ======================================== Use Queries =====================================
  const { data: machinesData } = useQuery(
    "machines",
    // @ts-ignore
    () => getMachines()(dispatch),
    { staleTime: 36000 }
  );

  const { data: teamsData } = useQuery("teams", () => getTeams()(dispatch));

  const queryKey = useMemo(() => {
    return ["plans", dates.length];
  }, [dates]);

  const getEmployeeArray = useCallback(
    (role: OverlapKeys) => {
      switch (role) {
        case "drivers":
          return drivers;
        case "workers":
          return workers;
        case "managers":
          return supervisors;
        default:
          return null;
      }
    },
    [drivers, workers, supervisors]
  );

  const isDrawing = useMemo(() => {
    return (
      !!drawDatas.driverDrawData ||
      !!drawDatas.supervisorDrawData ||
      !!drawDatas.workerDrawData
    );
  }, [drawDatas]);

  const { data: allPlansData } = useQuery(
    queryKey,
    () => {
      if (dates.length) {
        const startDate = dates[0][0];
        const endDate = getLastDate(dates);
        const startCalendar = new Date(
          startDate.year,
          startDate.month,
          startDate.date
        ).toISOString();
        const endCalendar = new Date(
          endDate.year,
          endDate.month,
          endDate.date
        ).toISOString();

        return getPlans(
          startDate.year,
          startCalendar,
          endCalendar,
          techDepartment
        )(dispatch);
      }
      return [];
    },
    {
      staleTime: 6000 * 5,
      refetchOnWindowFocus: false,
    }
  );
  
  const { data: machineData } = useQuery(
    ["machine", machineId],
    () => getMachine(machineId)(dispatch),
    {
      enabled: !!machineId,
      // @ts-ignore
      initialData: () =>
        ((queryClient.getQueryData("machines") as TMachine[]) || null)?.find(
          (machine) => machine.machineId === machineId
        ),
    }
  );

  const { data: staffData } = useQuery("staffs", () => getStaffs()(dispatch), {
    placeholderData: [] as User[],
  });

  const { data: prebookingsData } = useQuery("prebookings", () =>
    getPrebookings()(dispatch)
  );

  // ======================================== Use Mutation ====================================
  const { mutate: updateMachineService, isLoading: isUpdatingMachineService } =
    useMutation((machine: TMachine) => updateMachine(machine)(dispatch), {
      onSuccess: () => {
        // if (machinesData) {
        //   queryClient.invalidateQueries("machines");
        // }
        setInitialDataToChangedData();
      },
      onError: () => {
        queryClient.invalidateQueries("machines");
      },
    });

  // ======================================== Use Memos =====================================

  const machineServices = useMemo<TMachineService[]>(() => {
    return machineData?.machineService || [];
  }, [machineData]);

  // ======================================== Use Effects ======================================
  useEffect(() => {
    if (machineData && machineData.machineService[+serviceIndex])
      setMachineService(machineData.machineService[+serviceIndex]);
  }, [machineData, serviceIndex]);

  useEffect(() => {
    // let arr = [];
    // // @ts-ignore
    // let res = [];
    // teamsData &&
    //   // @ts-ignore
    //   teamsData.forEach((t) => {
    //     if (
    //       !t.inactive &&
    //       t.techDepartments.includes(
    //         planData?.machineRequirements[+planningIndex]?.techDepartment
    //       )
    //     ) {
    //       // @ts-ignore
    //       if (!res[t.machineId]) {
    //         res[t.machineId] = {
    //           machineId: t.machineId,
    //           machineName: t.machineName,
    //           techAreas: t.techAreas,
    //           techDepartments: t.techDepartments,
    //           teams: [],
    //         };
    //       }
    //       // @ts-ignore
    //       res[t.machineId].teams.push(t);
    //     }
    //   });
    // // @ts-ignore
    // let temp = Object.values(res);
    // for (let i = 0; i < machines.length; i++) {
    //   for (let j = 0; j < temp.length; j++) {
    //     if (machines[i].id === temp[j].machineId) {
    //       arr.push(temp[j]);
    //     }
    //   }
    // }
    // setMachinesTeamList(arr);
    if (machineData && teamsData) {
      setMachineTeamsData(
        teamsData.filter((el) => el.machineId === machineData.machineId)
      );
    }
  }, [machineData, teamsData]);

  // If the user manually types the url then it will redirect to the main dashboard /
  useEffect(() => {
    if (!machineId || !serviceIndex) {
      history.push("/");
    }
  }, [machineId, serviceIndex, history]);

  useEffect(() => {
    if (
      staffData?.length &&
      machinesData?.length &&
      allPlansData?.length &&
      serviceIndex &&
      machineData
    ) {
      setAllUsersData(
        staffData.map((staff) => {
          return {
            array: [],
            serviceSchedules: getCrewServiceSchedule(
              staff.userId,
              machinesData,
              {
                ignoreMachineId: machineData.machineId,
                ignoreServiceIndex: +serviceIndex,
              }
            ),
            id: staff.userId,
            staffSchedule: findStaffSchedule(
              staff.userId,
              "",
              getRole(staff.workingRole) as OverlapKeys,
              allPlansData
            ),
            staffVacation: staff.starfVacations,
            maxCounter: 0,
            staffData: staff,
          };
        })
      );
    }
  }, [staffData, machinesData, allPlansData, serviceIndex, machineData]);

  // Get all the drivers,supervisors and workers under the machine service
  useEffect(() => {
    if (
      machineService &&
      staffData?.length &&
      machinesData?.length &&
      allPlansData?.length &&
      horizontalPositionMap &&
      machineData &&
      serviceIndex
    ) {
      const { crews } = machineService;

      const tempDrivers: AllUserData[] = [];
      const tempSupervisors: AllUserData[] = [];
      const tempWorkers: AllUserData[] = [];

      const staffMap = {
        [ROLES.SUPERVISOR]: tempSupervisors,
        [ROLES.WORKER]: tempWorkers,
        [ROLES.DRIVER]: tempDrivers,
      };

      crews.forEach((crew) => {
        const foundCrew = staffData.find((staff) => staff.userId === crew.id);

        if (foundCrew) {
          // Optimize later
          const role = workingRoleOptions.find((role) =>
            foundCrew.workingRole.includes(role.key + "")
          );

          const temp = machineData.machineService[+serviceIndex].crews.filter(
            (el) => el.id === foundCrew.userId
          );

          if (role && staffMap[role.role]) {
            staffMap[role.role].push({
              id: foundCrew.userId,
              serviceSchedules: getCrewServiceSchedule(
                foundCrew.userId,
                machinesData,
                {
                  ignoreMachineId: machineData.machineId,
                  ignoreServiceIndex: +serviceIndex,
                }
              ),
              array: [
                // @ts-ignore
                {
                  ...getWidthStartEndNumberByHorizontalPositionMap(
                    horizontalPositionMap,
                    crew.start,
                    crew.end,
                    calendarStepWidth
                  ),
                  ...foundCrew,
                },
              ],
              staffSchedule: findStaffSchedule(
                foundCrew.userId,
                "NONE",
                role.role as OverlapKeys,
                allPlansData
              ),
              staffVacation: foundCrew.starfVacations,
              currentMachineSchedule: temp.map(({ end, start }) => ({
                comment: machineService.comment,
                end,
                start,
                machineId: machineData.machineId,
                machineName: machineData.machineName,
                reason: machineService.reason,
              })),
              staffData: foundCrew,
            });
          }
        }
      });

      setDrivers(staffMap[ROLES.DRIVER]);
      setSupervisors(staffMap[ROLES.SUPERVISOR]);
      setWorkers(staffMap[ROLES.WORKER]);

      initialDataRef.current = {
        drivers: staffMap[ROLES.DRIVER],
        supervisors: staffMap[ROLES.SUPERVISOR],
        workers: staffMap[ROLES.WORKER],
        machineService,
      };
    }
  }, [
    machineService,
    staffData,
    horizontalPositionMap,
    machinesData,
    allPlansData,
    calendarStepWidth,
    machineData,
    serviceIndex,
  ]);

  // Calculate dates and horizontal planning map
  useEffect(() => {
    if (machineService) {
      const { start, end } = machineService;

      let startDate = new Date(start);
      let endDate = new Date(end);

      // Point 2
      // Basically we need to go through the crews and determine if there are any crews on the machine service who have an earlier or later start date
      // if (
      //   Array.isArray(planData["machineRequirements"][planningIndex]?.machines)
      // ) {
      //   const keyToCheck = ["machines", "drivers", "workers", "managers"];
      //   keyToCheck.forEach((key) => {
      //     planData["machineRequirements"][planningIndex][key].forEach(
      //       (machine) => {
      //         if (startDate > new Date(machine?.start)) {
      //           startDate = new Date(machine?.start);
      //         }
      //         if (endDate < new Date(machine?.end)) {
      //           endDate = new Date(machine?.end);
      //         }
      //       }
      //     );
      //   });
      // }

      // Point 3
      // if (startDate < new Date() && endDate > new Date()) {
      //   scrollToTodayAnchor();
      // }
      // // Handle for case endDate before today
      // if (endDate < new Date()) {
      //   endDate = addDaysToDate(new Date(), 60);
      //   scrollToTodayAnchor();
      // }
      // Add more day to end date

      endDate = addDaysToDate(endDate, (addCalendarLength || 0) * 30);

      let datesData = datesGenerator({
        month: startDate.getMonth(),
        year: startDate.getFullYear(),
        startingDay: 1,
      }) as TDatesGenerator;

      let dates = startCalendarFromSelectedWeek(
        getMonday(startDate),
        datesData["dates"]
      ) as TDates;

      while (datesData["nextYear"] <= endDate.getFullYear()) {
        datesData = datesGenerator({
          month: datesData.nextMonth,
          year: datesData.nextYear,
          startingDay: 1,
        });
        let temp = datesData["dates"];
        // removing duplicated weeks
        if (dates[dates.length - 1][0]["date"] === temp[0][0]["date"]) {
          temp.shift();
        }
        dates = [...dates, ...datesData["dates"]];
      }

      startDate.setHours(0);
      endDate.setHours(0);
      const startText = moment(startDate, "YYYY-M-D").format("D.M.YY");
      const endText = moment(endDate, "YYYY-M-D").format("D.M.YY");

      // @ts-ignore
      const horizontalPositionMap: TPlanHorizontalPositionMap = {
        startText,
        endText,
      };

      const datePositionCorrespondence: {
        [k: number]: {
          value: string;
          display: string;
        };
      } = {};
      let datesToDisplay: TDates = [];
      let effectiveCounter = 0;
      let counter = 0;

      for (let i = 0; i < dates.length; i++) {
        let weekToSave = [];
        for (let j = 0; j < dates[i].length; j++) {
          //date: 1, month: 2, year: 2021
          let currentDate = new Date(
            dates[i][j]["year"],
            dates[i][j]["month"],
            dates[i][j]["date"]
          );
          if (currentDate > endDate && counter >= 66) {
            break;
          }
          if (startDate <= currentDate) {
            weekToSave.push(dates[i][j]);
            let key = `${dates[i][j]["year"]}-${dates[i][j]["month"] + 1}-${
              dates[i][j]["date"]
            }`;
            horizontalPositionMap[key] = effectiveCounter;
            datePositionCorrespondence[effectiveCounter * calendarStepWidth] = {
              value: moment(key, "YYYY-M-D").format(
                "YYYY-MM-DD[T]HH:mm:ss.SSS"
              ),
              display: moment(key, "YYYY-M-D").format("D.M.YY"),
            };
            effectiveCounter++;
          }
          counter++;
        }
        if (weekToSave.length) {
          datesToDisplay.push(weekToSave);
        }
      }

      async function getDatesWithHolidays() {
        const yearOfDates = findYearOfDates(datesToDisplay);
        let holidays: THoliday[] = [];

        if (
          !holidayRef.current ||
          JSON.stringify(yearOfDates) !==
            JSON.stringify(holidayRef.current?.years)
        ) {
          for (const year of yearOfDates) {
            const currentHolidays = (await getHolidayList(year)(dispatch)) as {
              holidays: THoliday[];
            };
            holidays = [...holidays, ...(currentHolidays?.holidays || [])];
          }
          holidayRef.current = {
            years: yearOfDates,
            holidays: holidays,
          };
        } else {
          holidays = holidayRef.current.holidays;
        }

        datesToDisplay = getCalendarHoliday(datesToDisplay, holidays);
        setDates(datesToDisplay);
      }

      getDatesWithHolidays();
      setDates(datesToDisplay);

      const newStartDate = new Date(
        datesToDisplay[0][0].year,
        datesToDisplay[0][0].month,
        datesToDisplay[0][0].date
      );
      horizontalPositionMap.startText = moment(newStartDate, "YYYY-M-D").format(
        "D.M.YY"
      );
      horizontalPositionMap["end"] = --effectiveCounter;
      horizontalPositionMap["displayEnd"] = --counter;
      horizontalPositionMap["positionsToDates"] = datePositionCorrespondence;
      setHorizontalPositionMap(horizontalPositionMap);
    }
  }, [machineService, addCalendarLength, calendarStepWidth, dispatch]);

  const isInitialized = useMemo(() => {
    return (
      machineData && machinesData && machineService && allPlansData && staffData
    );
  }, [machineData, machinesData, machineService, allPlansData, staffData]);

  // Calculate the dates
  const widthOfCalendar = useMemo(
    () => dates.reduce((acc, cur) => acc + cur.length, 0) * calendarStepWidth,
    [dates, calendarStepWidth]
  );

  const classNames = useMemo(
    () =>
      getClassNames({
        widthOfCalendar,
        heightOfBackground: BACKGROUND_HEIGHT,
      }),
    [widthOfCalendar]
  );

  const mapData = useMemo<{
    verticalMap: TVerticalMap;
    separatorPositions: number[];
  } | null>(() => {
    return machineData
      ? generateVerticalMap({
          allowEdit,
          drivers,
          machines: [machineData],
          supervisors,
          workers,
        })
      : null;

    // Only length needs to be checked because if there are any changes then the length will change
    // No need to keep checking the array itself
  }, [drivers, workers, machineData, supervisors, allowEdit]);

  const machinePrebookings = useMemo<
    {
      color: string;
      end: string;
      prebookingName: string;
      start: string;
    }[]
  >(() => {
    if (!machineId || !prebookingsData) {
      return [];
    }

    return getMachinePrebookings({
      machineId,
      prebookingsData,
      projectId: "",
    });
  }, [prebookingsData, machineId]);

  const machineSchedules = useMemo<
    {
      projectId: string;
      name: string;
      color: string;
      start: string;
      end: string;
      crmProjectStatusCode: string;
    }[]
  >(() => {
    if (!allPlansData || !machineId) {
      return [];
    }

    return getMachineBookedProjects({
      allPlansData: allPlansData,
      machineId,
      projectId: "",
    });
  }, [allPlansData, machineId]);

  const verticalMap = mapData?.verticalMap || null;
  const separatorPositions = mapData?.separatorPositions || null;

  //  ================================ Use Callback =============================

  const renderMachineServices = useCallback(() => {
    if (
      serviceIndex &&
      machineService &&
      machineServices?.length &&
      horizontalPositionMap &&
      verticalMap &&
      machineId
    ) {
      const arr: JSX.Element[] = [];

      machineServices.forEach((service, idx) => {
        if (idx !== +serviceIndex) {
          arr.push(
            <MachineServiceBar
              textDisplay={getServiceText(service)}
              key={`other-machine-service-${idx}`}
              end={service.end}
              start={service.start}
              horizontalPositionMap={horizontalPositionMap}
              yPos={verticalMap.machines[machineId] || 0}
              allowEdit={!allowEdit}
            />
          );
        }
      });

      return arr;
    }
  }, [
    machineServices,
    serviceIndex,
    machineService,
    horizontalPositionMap,
    verticalMap,
    machineId,
    allowEdit,
  ]);

  const setInitialDataToChangedData = useCallback(() => {
    if (drivers && supervisors && workers && machineService) {
      initialDataRef.current = {
        drivers: [],
        supervisors: [],
        workers: [],
        machineService,
      };
    }
  }, [drivers, supervisors, workers, machineService]);

  const updateMachineServiceStartEnd = useCallback(
    ({ start, end }: { start: string; end: string }) => {
      const arr = [...machineServices];
      arr.splice(+serviceIndex, 1);

      const dates: {
        startDate: string;
        endDate: string;
      }[] = arr.map(({ start, end }) => ({
        startDate: start,
        endDate: end,
      }));

      const machineScheduleDates = machineSchedules.map(({ start, end }) => ({
        startDate: start,
        endDate: end,
      }));

      const machinePrebookingDates = machinePrebookings.map(
        ({ start, end }) => ({
          startDate: start,
          endDate: end,
        })
      );

      dates.push(...machineScheduleDates);
      dates.push(...machinePrebookingDates);

      if (checkOverlap({ end, start, dates })) {
        toggleRefreshFunctions.refreshMachineService &&
          toggleRefreshFunctions.refreshMachineService();
        dispatch(saveMessage("Cannot Overlap", MessageBarType.error));
        return;
      }

      const machineServiceCopy = { ...machineService } as TMachineService;

      machineServiceCopy.start = start;
      machineServiceCopy.end = end;

      setMachineService(machineServiceCopy);
    },
    [
      machineService,
      machineServices,
      serviceIndex,
      dispatch,
      machinePrebookings,
      machineSchedules,
      toggleRefreshFunctions,
    ]
  );

  const renderRightSideHorizontal = useCallback(() => {
    return (separatorPositions || []).map((yPos) => (
      <div
        style={{
          // border: "1px solid #DFDFDF",
          position: "absolute",
          top: yPos,
          width: "100%",
          height: 2,
          backgroundColor: "#DFDFDF",
        }}
        key={yPos}
      />
    ));
  }, [separatorPositions]);

  const renderMachineService = useCallback(() => {
    if (machineService && horizontalPositionMap && verticalMap && machineId) {
      const yPos = verticalMap.machines[machineId] || 0;

      return (
        <>
          {/* <div
            style={{
              // backgroundColor: "rgba(0, 108, 173, 0.2)":"unset",
              backgroundColor: "rgba(0, 108, 173, 0.2)",
              width: "100%",
              position: "absolute",
              top: yPos,
              height: RND_BAR_HEIGHT,
            }}
            id={targetId}
          /> */}
          <MachineServiceBar
            end={machineService.end}
            start={machineService.start}
            textDisplay={getServiceText(machineService)}
            key={`selected-machine-service`}
            horizontalPositionMap={horizontalPositionMap}
            yPos={yPos}
            selected
            allowEdit={allowEdit}
            onChangeStartEnd={updateMachineServiceStartEnd}
            onInitialize={({ recalculate }) => {
              toggleRefreshFunctions.refreshMachineService = recalculate;
              setToggleRefreshFunctions(toggleRefreshFunctions);
            }}
          />
        </>
      );
    }
    return <></>;
  }, [
    machineService,
    verticalMap,
    machineId,
    allowEdit,
    horizontalPositionMap,
    updateMachineServiceStartEnd,
    toggleRefreshFunctions,
  ]);

  const renderMachineSchedule = useCallback(() => {
    if (
      allPlansData?.length &&
      machineId &&
      verticalMap &&
      horizontalPositionMap
    ) {
      const arr: JSX.Element[] = [];

      const yPos = verticalMap.machines[machineId] || 0;

      machineSchedules.forEach(
        ({ start, end, crmProjectStatusCode, projectId, color, name }) => {
          arr.push(
            <MachineBookingBar
              yPos={yPos}
              start={start}
              end={end}
              crmProjectStatusCode={crmProjectStatusCode}
              color={COLOR_GREY_OUT}
              name={name}
              horizontalPositionMap={horizontalPositionMap}
              key={projectId + start + end}
            />
          );
        }
      );

      return arr;
    }

    return <></>;
  }, [
    allPlansData,
    machineId,
    verticalMap,
    horizontalPositionMap,
    machineSchedules,
  ]);

  const renderMachinePrebookings = useCallback(() => {
    if (
      machineId &&
      verticalMap &&
      horizontalPositionMap &&
      prebookingsData?.length
    ) {
      const arr: JSX.Element[] = [];

      const yPos = verticalMap.machines[machineId] || 0;

      machinePrebookings.forEach(({ color, end, prebookingName, start }) => {
        arr.push(
          <MachineBookingBar
            color={COLOR_GREY_OUT}
            end={end}
            start={start}
            crmProjectStatusCode=""
            horizontalPositionMap={horizontalPositionMap}
            yPos={yPos}
            name={prebookingName}
            key={start + end + prebookingName}
          />
        );
      });

      return arr;
    }

    return <></>;
  }, [
    machineId,
    verticalMap,
    horizontalPositionMap,
    prebookingsData,
    machinePrebookings,
  ]);

  const getEmployeeSetterArray = useCallback((role: OverlapKeys) => {
    switch (role) {
      case "drivers":
        return setDrivers;
      case "workers":
        return setWorkers;
      case "managers":
        return setSupervisors;
      default:
        return null;
    }
  }, []);

  // Hack unoptimized
  // const refreshStaff = useCallback(
  //   ({ role }: { role: OverlapKeys }) => {
  //     const setter = getEmployeeSetterArray(role);

  //     if (!setter) {
  //       return;
  //     }

  //     let arr: AllUserData[] = [];

  //     if (role === "drivers") {
  //       arr = drivers;
  //     } else if (role === "managers") {
  //       arr = supervisors;
  //     } else if (role === "workers") {
  //       arr = workers;
  //     }

  //     if (arr?.length) {
  //       setter([...arr]);
  //     }
  //   },
  //   [getEmployeeSetterArray, drivers, supervisors, workers]
  // );

  const onUpdateStaffServiceSchedule = useCallback(
    ({
      role,
      staffIndex,
      allUserData,
      end,
      start,
      userData,
      userServiceIndex,
    }: {
      role: OverlapKeys;
      start: string;
      end: string;
      allUserData: AllUserData[];
      staffIndex: number;
      userData: AllUserData;
      userServiceIndex: number;
    }) => {
      const setter = getEmployeeSetterArray(role);

      if (
        !setter ||
        !staffData ||
        !serviceIndex ||
        !verticalMap ||
        !allPlansData
      ) {
        return;
      }

      // const idx = +serviceIndex;

      let endDate = end;
      let startDate = start;

      endDate = endDate.slice(0, endDate.length - 1) + "Z";
      startDate = startDate.slice(0, startDate.length - 1) + "Z";

      const copy = [...allUserData];

      const schedules = findStaffSchedule(userData.id, "", role, allPlansData);

      // const vacations: TStaffVacation[] =
      //   staffData?.find((el) => el.userId === userData.id)?.starfVacations || [];
      const vacations = userData.staffVacation || [];

      const machineServiceSchedules = [...userData?.serviceSchedules];

      machineServiceSchedules.splice(userServiceIndex, 1);

      const dates: {
        startDate: string;
        endDate: string;
      }[] = schedules.map(({ start, end }) => ({
        startDate: start,
        endDate: end,
      }));

      dates.push(
        ...vacations.map(({ start, end }) => ({
          startDate: start,
          endDate: end,
        }))
      );

      dates.push(
        ...machineServiceSchedules.map(({ start, end }) => ({
          startDate: start,
          endDate: end,
        }))
      );

      if (checkOverlap({ start, end, dates })) {
        if (staffRefreshRef.current) {
          staffRefreshRef.current();
        }

        staffRefreshRef.current = undefined;

        dispatch(saveMessage("Cannot Overlap", MessageBarType.error));
        return;
      }

      staffRefreshRef.current = undefined;

      (copy[staffIndex].currentMachineSchedule || [])[userServiceIndex].start =
        startDate;
      (copy[staffIndex].currentMachineSchedule || [])[userServiceIndex].end =
        endDate;

      setter(copy);
    },
    [
      serviceIndex,
      staffData,
      allPlansData,
      verticalMap,
      dispatch,
      getEmployeeSetterArray,
    ]
  );

  const renderDrawer = useCallback(() => {
    let drawData: TDrawData | null = null;

    if (drawDatas.driverDrawData) {
      drawData = drawDatas.driverDrawData;
    } else if (drawDatas.supervisorDrawData) {
      drawData = drawDatas.supervisorDrawData;
    } else if (drawDatas.workerDrawData) {
      drawData = drawDatas.workerDrawData;
    }

    if (drawData) {
      return <CustomBarDrawer />;
    }

    return <></>;
  }, [drawDatas]);

  const onCancelDrawNew = useCallback(() => {
    if (newUserData) {
      const { id, role } = newUserData;

      const setter = getEmployeeSetterArray(role);
      const arr = getEmployeeArray(role);

      if (!arr || !setter) {
        return;
      }

      setter(arr.filter((el) => el.id !== id));
      setNewUserData(undefined);
      setDrawDatas({});
    }
  }, [getEmployeeArray, getEmployeeSetterArray, newUserData]);

  // Check overlap see if got anything here
  const onMouseDownDrawNew = useCallback(
    ({ xPos }: { xPos: number }) => {
      if (horizontalPositionMap && newUserData) {
        const userData = allUsersData.find((el) => el.id === newUserData.id);

        if (userData) {
          const start =
            horizontalPositionMap.positionsToDates[
              Math.floor(xPos / calendarStepWidth) * calendarStepWidth
            ]?.value;
          const dtMap = generateUserDateMaps(userData);

          if (dtMap[start.slice(0, 10)]) {
            dispatch(saveMessage("Cannot overlap", MessageBarType.error));

            onCancelDrawNew();

            return;
          }
        }
      }
    },
    [
      allUsersData,
      calendarStepWidth,
      dispatch,
      horizontalPositionMap,
      newUserData,
      onCancelDrawNew,
    ]
  );

  const onAddEmployee = useCallback(
    ({
      addedUser,
      role,
      ignoreExisting,
      isAddToAllTeam,
    }: {
      addedUser: AllUserData;
      role: OverlapKeys;
      ignoreExisting?: boolean;
      isAddToAllTeam?: boolean;
    }) => {
      const arr = getEmployeeArray(role);

      const setter = getEmployeeSetterArray(role);

      if (
        arr &&
        setter &&
        machineService &&
        horizontalPositionMap &&
        machineData
      ) {
        if (!ignoreExisting) {
          const foundCrew = arr.find(
            (user) => user.id === addedUser.staffData.userId
          );

          if (foundCrew) {
            dispatch(
              saveMessage(
                "This user has already been added to the service.",
                MessageBarType.error
              )
            );

            return;
          }
        }

        const { start, end } = findMostSuitableTime({
          start: machineService.start,
          end: machineService.end,
          allUserData: addedUser,
        });

        if (!start || !end) {
          dispatch(
            saveMessage(
              `Could not find suitable time to add this user. ${addedUser.staffData.firstName} ${addedUser.staffData.lastName}`,
              MessageBarType.error
            )
          );

          return;
        }

        const dateData = getWidthStartEndNumberByHorizontalPositionMap(
          horizontalPositionMap,
          start,
          end,
          calendarStepWidth
        );

        // getWidthStartEndNumberByHorizontalPositionMap returns the date string like this 2024-06-11T00:00:00.000
        // We want it in 2024-06-11T00:00:00.00Z. In the getMapTransform, it causes an issue
        dateData.startText.value =
          dateData.startText.value.slice(0, dateData.endText.value.length - 1) +
          "Z";

        dateData.endText.value =
          dateData.endText.value.slice(0, dateData.endText.value.length - 1) +
          "Z";

        const data: AllUserData = {
          ...addedUser,
          // serviceSchedules: [
          //   {
          //     comment: machineService.comment,
          //     start: dateData.startText.value,
          //     end: dateData.endText.value,
          //     machineId: machineId,
          //     machineName: machineData.machineName,
          //     reason: machineService.reason,
          //   },
          // ],
          currentMachineSchedule: [
            {
              comment: machineService.comment,
              start: dateData.startText.value,
              end: dateData.endText.value,
              machineId: machineId,
              machineName: machineData.machineName,
              reason: machineService.reason,
            },
          ],
        };

        if (isAddToAllTeam) {
          return data;
        } else {
          setter([
            ...arr,
            {
              ...data,
            },
          ]);
        }
      }
    },
    [
      calendarStepWidth,
      dispatch,
      getEmployeeArray,
      getEmployeeSetterArray,
      horizontalPositionMap,
      machineData,
      machineId,
      machineService,
    ]
  );

  const onRemoveEmployee = useCallback(
    (user: User, role: OverlapKeys) => {
      const arr = getEmployeeArray(role);
      const setter = getEmployeeSetterArray(role);

      if (arr && setter) {
        setter(arr.filter((el) => el.id !== user.userId));
      }
    },
    [getEmployeeArray, getEmployeeSetterArray]
  );

  // Use if the mostSuitableTime matches
  const onMouseUpDrawNew = useCallback(
    ({ endXPos, startXPos }: { startXPos: number; endXPos: number }) => {
      if (horizontalPositionMap && newUserData) {
        const start =
          horizontalPositionMap.positionsToDates[
            Math.floor(startXPos / calendarStepWidth) * calendarStepWidth
          ]?.value;
        const end =
          horizontalPositionMap.positionsToDates[
            Math.floor(endXPos / calendarStepWidth) * calendarStepWidth
          ]?.value;

        const setter = getEmployeeSetterArray(newUserData.role);
        const arr = getEmployeeArray(newUserData.role);

        if (start && end && arr && setter && machineService && machineData) {
          const userData = allUsersData.find((el) => el.id === newUserData.id);

          if (userData) {
            onRemoveEmployee(userData.staffData, newUserData.role);
            onAddEmployee({ addedUser: userData, role: newUserData.role });

            const { start: suitableStartDate, end: suitableEndDate } =
              findMostSuitableTime({
                start,
                end,
                allUserData: userData,
              });

            if (
              start.slice(0, 10) !== suitableStartDate?.slice(0, 10) ||
              end.slice(0, 10) !== suitableEndDate?.slice(0, 10)
            ) {
              dispatch(
                saveMessage(
                  "Not possible to allocate the user at this time.",
                  MessageBarType.error
                )
              );

              onCancelDrawNew();
              return;
            }

            // The filter is removing the previous temporary entry we created
            // Then we push in the new one with the start and end date
            setter([
              ...arr.filter((el) => el.id !== newUserData.id),
              {
                ...userData,
                currentMachineSchedule: [
                  {
                    comment: machineService.comment,
                    start: suitableStartDate as string,
                    end: suitableEndDate as string,
                    machineId: machineId,
                    machineName: machineData.machineName,
                    reason: machineService.reason,
                  },
                ],
              },
            ]);

            setNewUserData(undefined);
            setDrawDatas({});
          }
        }
      }
    },
    [
      allUsersData,
      calendarStepWidth,
      dispatch,
      getEmployeeArray,
      getEmployeeSetterArray,
      horizontalPositionMap,
      machineData,
      machineId,
      newUserData,
      machineService,
      onAddEmployee,
      onCancelDrawNew,
      onRemoveEmployee,
    ]
  );

  // Check overlap see if got anything here
  const onMouseDownDrawExisting = useCallback(
    ({ xPos }: { xPos: number }) => {
      if (horizontalPositionMap && drawExistingUserData) {
        const { role } = drawExistingUserData;

        const arr = getEmployeeArray(role);

        const userData = arr?.find((el) => el.id === drawExistingUserData.id);

        if (userData) {
          const start =
            horizontalPositionMap.positionsToDates[
              Math.floor(xPos / calendarStepWidth) * calendarStepWidth
            ]?.value;
          const dtMap = generateUserDateMaps(userData);

          if (dtMap[start.slice(0, 10)]) {
            dispatch(saveMessage("Cannot overlap", MessageBarType.error));

            onCancelDraw();

            return;
          }
        }
      }
    },
    [
      calendarStepWidth,
      dispatch,
      drawExistingUserData,
      getEmployeeArray,
      horizontalPositionMap,
    ]
  );

  // Use if the mostSuitableTime matches
  const onMouseUpDrawExisting = useCallback(
    ({ startXPos, endXPos }: { startXPos: number; endXPos: number }) => {
      const start =
        Math.floor(startXPos / calendarStepWidth) * calendarStepWidth;
      const end = Math.floor(endXPos / calendarStepWidth) * calendarStepWidth;

      if (
        !horizontalPositionMap ||
        !drawExistingUserData ||
        !machineData ||
        !machineService
      ) {
        return;
      }

      const { id, role } = drawExistingUserData;

      const setter = getEmployeeSetterArray(role);
      const arr = getEmployeeArray(role);

      const userData = allUsersData.find((el) => el.id === id);

      const startDate = horizontalPositionMap.positionsToDates[start]?.value;
      const endDate = horizontalPositionMap.positionsToDates[end]?.value;
      if (setter && arr && userData && startDate && endDate) {
        const { start: suitableStartDate, end: suitableEndDate } =
          findMostSuitableTime({
            allUserData: userData,
            start: startDate,
            end: endDate,
          });

        if (
          startDate.slice(0, 10) !== suitableStartDate?.slice(0, 10) ||
          endDate.slice(0, 10) !== suitableEndDate?.slice(0, 10)
        ) {
          dispatch(
            saveMessage(
              "Not possible to allocate the user at this time.",
              MessageBarType.error
            )
          );

          onCancelDraw();

          return;
        }

        setter([
          ...arr,
          {
            ...userData,
            currentMachineSchedule: [
              {
                comment: machineService.comment,
                start: startDate,
                end: endDate,
                machineId: machineId,
                machineName: machineData.machineName,
                reason: machineService.reason,
              },
            ],
          },
        ]);

        setExistingUserData(undefined);
        setDrawDatas({});
      }
    },
    [
      allUsersData,
      calendarStepWidth,
      dispatch,
      drawExistingUserData,
      getEmployeeArray,
      getEmployeeSetterArray,
      horizontalPositionMap,
      machineData,
      machineId,
      machineService,
    ]
  );

  const renderDrawExistingUser = useCallback(() => {
    const drawData =
      drawDatas.driverDrawData ||
      drawDatas.workerDrawData ||
      drawDatas.supervisorDrawData;

    if (drawData && verticalMap && machineService) {
      const yPos = verticalMap[drawData.role][drawData.id];

      return (
        <BarDrawer
          onMouseDown={onMouseDownDrawExisting}
          barHeight={RND_BAR_HEIGHT}
          colorCode="machineServiceAllocation"
          containerClassName="detailPlanContainer"
          onMouseUp={onMouseUpDrawExisting}
          onCancel={onCancelDraw}
          yPos={yPos}
          calendarStepWidth={calendarStepWidth}
          text={getServiceText(machineService)}
        />
      );
    }

    return <></>;
  }, [
    drawDatas,
    verticalMap,
    calendarStepWidth,
    machineService,
    onMouseDownDrawExisting,
    onMouseUpDrawExisting,
  ]);

  const renderDrawNewUser = useCallback(() => {
    const drawData =
      drawDatas.driverDrawData ||
      drawDatas.workerDrawData ||
      drawDatas.supervisorDrawData;

    if (drawData?.isNew && verticalMap && machineService) {
      const yPos = verticalMap[drawData.role][drawData.id];

      return (
        <BarDrawer
          onMouseDown={onMouseDownDrawNew}
          barHeight={RND_BAR_HEIGHT}
          colorCode="machineServiceAllocation"
          containerClassName="detailPlanContainer"
          onMouseUp={onMouseUpDrawNew}
          onCancel={onCancelDrawNew}
          yPos={yPos}
          calendarStepWidth={calendarStepWidth}
          text={getServiceText(machineService)}
        />
      );
    }

    return <></>;
  }, [
    calendarStepWidth,
    machineService,
    verticalMap,
    drawDatas,
    onMouseDownDrawNew,
    onMouseUpDrawNew,
    onCancelDrawNew,
  ]);

  // @ts-ignore
  const allMachineData = useMemo<AllMachineData | null>(() => {
    if (
      machineData &&
      horizontalPositionMap &&
      machineService &&
      calendarStepWidth
    ) {
      const { machineName, machineId } = machineData;

      return {
        id: machineId,
        machineName,
        machineService: machineData.machineService,
        machineBookedProjects: [],
        machineBookedPrebookings: [],
        array: [
          {
            ...machineData,
            // ...getWidth
            ...getWidthStartEndNumberByHorizontalPositionMap(
              horizontalPositionMap,
              machineService.start,
              machineService.end,
              calendarStepWidth
            ),
          },
        ],
      };
    }

    return null;
  }, [machineData, horizontalPositionMap, machineService, calendarStepWidth]);

  const onClickDraw = (drawData: TDrawData) => {
    const drawDatasCopy = { ...drawDatas };

    const { id, role } = drawData;

    switch (role) {
      case "drivers":
        drawDatasCopy.driverDrawData = drawData;
        break;
      case "managers":
        drawDatasCopy.supervisorDrawData = drawData;
        break;
      case "workers":
        drawDatasCopy.workerDrawData = drawData;
        break;
    }

    if (!drawData.isNew) {
      setExistingUserData({ id, role });
    }

    setDrawDatas(drawDatasCopy);
  };

  const onCancelDraw = () => {
    setExistingUserData(undefined);
    setDrawDatas({});
  };

  const onClickDrawNew = (drawData: TDrawData) => {
    if (Array.isArray(staffData)) {
      const { id, role } = drawData;

      const data = allUsersData.find((el) => el.id === id);

      if (data) {
        onClickDraw(drawData);

        const arr = getEmployeeArray(role);

        const setter = getEmployeeSetterArray(role);

        if (!setter || !Array.isArray(arr)) {
          return;
        }

        const foundCrew = arr.find((user) => user.id === id);

        if (foundCrew) {
          dispatch(
            saveMessage(
              "This user has already been added to the service.",
              MessageBarType.error
            )
          );

          setDrawDatas({});
          setNewUserData(undefined);

          return;
        }

        setNewUserData({ id: data.id, role });
        setter([...arr, data]);
      }
    }
  };

  // This is needed because a staff might have multiple allocation
  // under a service. When rendering the left side with the names,
  // We don't want duplicates
  const filterDuplicateStaffs = (staffs: AllUserData[]) => {
    const arr: AllUserData[] = [];

    const map: { [k: string]: number } = {};

    staffs.forEach((staff) => {
      const { id } = staff;
      if (!map[id]) {
        map[id] = 1;

        arr.push(staff);
      }
    });

    return arr;
  };

  const filterStaff = (staffsData: AllUserData[], role: string) => {
    const otherSectionStaffs: AllUserData[] = [];
    const users: AllUserData[] = [];

    const roles = workingRoleOptions.find((option) => option.role === role);

    if (roles) {
      staffsData
        .filter((staff) => staff.staffData.workingRole.includes(roles.key + ""))
        .forEach((user) => {
          if (
            !user.staffData.inactive &&
            machineData?.techDepartments.includes(
              user.staffData.techDepartment + ""
            )
          ) {
            users.push(user);
          } else if (!user.staffData.inactive) {
            otherSectionStaffs.push(user);
          }
        });
    }

    return {
      otherSectionStaffs,
      users,
    };
  };

  // ==================================================== Functions =============================================

  const submitUpdatePlan = useCallback(() => {
    if (
      isUpdatingMachineService ||
      !drivers ||
      !workers ||
      !supervisors ||
      !machineData ||
      isNaN(parseInt(serviceIndex))
    ) {
      return;
    }

    const newMachineData: TMachine = JSON.parse(
      JSON.stringify(machineData)
    ) as TMachine;

    const userWorkers: UserWorkerVM[] = [];

    [workers, supervisors, drivers].forEach((arr) => {
      arr.forEach((staff) => {
        (staff.currentMachineSchedule || []).forEach((schedule) => {
          userWorkers.push({
            activityLog: staff.staffData.activityLog,
            end: schedule.end,
            start: schedule.start,
            firstName: staff.staffData.firstName,
            lastName: staff.staffData.lastName,
            id: staff.staffData.userId,
            imageUrl: staff.staffData.imageUrl,
            inactive: staff.staffData.inactive,
            machineId: machineData.machineId,
            machineName: machineData.machineName,
            exceptionDates: [],
            starfVacations: staff.staffData.starfVacations || [],
            techArea: staff.staffData.techArea,
          });
        });
      });
    });

    newMachineData.machineService[+serviceIndex].crews = userWorkers;

    updateMachineService(newMachineData);
  }, [
    isUpdatingMachineService,
    machineData,
    drivers,
    workers,
    supervisors,
    serviceIndex,
    updateMachineService,
  ]);

  const submitAndClose = useCallback(() => {
    submitUpdatePlan();
    // if (saveResult) {
    if (saveRef) {
      clearTimeout(saveRef);
    }

    const ref = setTimeout(() => {
      // @ts-ignore
      history.goBack() || history.push("/");
    }, 1000);

    setSaveRef(ref);
    // }
  }, [history, saveRef, submitUpdatePlan]);

  const onDelete = useCallback(
    ({ role, userIndex }: { role: OverlapKeys; userIndex: number }) => {
      const arr = getEmployeeArray(role);
      const setter = getEmployeeSetterArray(role);

      if (arr && setter) {
        const copy = JSON.parse(JSON.stringify(arr)) as AllUserData[];
        copy.splice(userIndex, 1);
        setter(copy);
      }
    },
    [getEmployeeArray, getEmployeeSetterArray]
  );

  // renderDrivers,renderSupervisors,renderWorkers will all use this function
  // I split up driver,supervisors,workers render to avoid rerender
  const renderStaffs = useCallback(
    ({
      allUserData,
      role,
      horizontalPositionMap,
      verticalMap,
      allPlansData,
      drawData,
    }: {
      role: OverlapKeys;
      allUserData: AllUserData[];
      horizontalPositionMap: TPlanHorizontalPositionMap | null;
      allPlansData: TPlan[] | null | undefined;
      verticalMap: TVerticalMap | null;
      drawData?: TDrawData;
    }) => {
      if (!verticalMap || !horizontalPositionMap || !allPlansData) {
        return <></>;
      }

      const setter = getEmployeeSetterArray(role);

      if (!setter) {
        return <></>;
      }

      const arr: JSX.Element[] = [];

      allUserData.forEach((user, userIndex) => {
        const { id } = user;

        const serviceSchedules = user.currentMachineSchedule || [];

        const otherServiceSchedules = user.serviceSchedules || [];

        const schedules = findStaffSchedule(id, "", role, allPlansData);

        const vacations: TStaffVacation[] =
          staffData?.find((el) => el.userId === id)?.starfVacations || [];

        const yPos = verticalMap[role][id] || 0;

        schedules.forEach(
          ({
            color,
            // crmProjectStatusCode,
            //  projectId,
            end,
            name,
            start,
          }) => {
            arr.push(
              <MachineBookingBar
                color={COLOR_GREY_OUT}
                crmProjectStatusCode=""
                end={end}
                start={start}
                horizontalPositionMap={horizontalPositionMap}
                name={name}
                yPos={yPos}
                key={start + "-schedule-" + end + "-" + id}
              />
            );
          }
        );

        vacations.forEach((el, idx) => {
          const { color, end, notes, reason, start } = el;

          arr.push(
            <StaffVacationBar
              end={end}
              start={start}
              reason={reason}
              notes={notes}
              color={COLOR_GREY_OUT}
              horizontalPositionMap={horizontalPositionMap}
              yPos={yPos}
              key={start + "-vacation-" + end + "-" + idx + "-" + id}
            />
          );
        });

        serviceSchedules.forEach((el, idx) => {
          const {
            end,
            // machineId,
            //  machineName,
            start,
            comment,
            reason,
          } = el;

          const key = `${start}-${end}-service-${idx}-${id}-${userIndex}`;

          const serviceId = `service-${idx}-${id}-${userIndex}`;

          const serviceText = getServiceText({ comment, reason });

          arr.push(
            <MachineServiceBar
              end={end}
              start={start}
              textDisplay={serviceText}
              tooltipText={`${user.staffData.firstName} ${user.staffData.lastName} ${serviceText}`}
              key={key}
              horizontalPositionMap={horizontalPositionMap}
              yPos={yPos}
              selected
              allowEdit={allowEdit}
              onDelete={() => {
                onDelete({ role, userIndex });
              }}
              id={serviceId}
              onChangeStartEnd={({ end, start, recalculate }) => {
                let endDate = end;
                let startDate = start;

                endDate = endDate.slice(0, endDate.length - 1) + "Z";
                startDate = startDate.slice(0, startDate.length - 1) + "Z";

                if (recalculate) {
                  staffRefreshRef.current = recalculate;
                }

                onUpdateStaffServiceSchedule({
                  allUserData,
                  end: endDate,
                  start: startDate,
                  role,
                  staffIndex: userIndex,
                  userData: user,
                  userServiceIndex: idx,
                });
              }}
            />
          );
        });

        otherServiceSchedules.forEach((el, idx) => {
          const {
            end,
            // machineId,
            //  machineName,
            start,
            comment,
            reason,
          } = el;

          const key = `${start}-${end}-other-service-${idx}-${id}`;

          const serviceText = getServiceText({ comment, reason });

          arr.push(
            <MachineServiceBar
              end={end}
              start={start}
              textDisplay={serviceText}
              tooltipText={`${user.staffData.firstName} ${user.staffData.lastName} ${serviceText}`}
              key={key}
              horizontalPositionMap={horizontalPositionMap}
              yPos={yPos}
              allowEdit={false}
              onChangeStartEnd={({ end, start, recalculate }) => {
                let endDate = end;
                let startDate = start;

                endDate = endDate.slice(0, endDate.length - 1) + "Z";
                startDate = startDate.slice(0, startDate.length - 1) + "Z";

                if (recalculate) {
                  staffRefreshRef.current = recalculate;
                }

                onUpdateStaffServiceSchedule({
                  allUserData,
                  end: endDate,
                  start: startDate,
                  role,
                  staffIndex: userIndex,
                  userData: user,
                  userServiceIndex: idx,
                });
              }}
            />
          );
        });

        arr.push(
          <div
            style={{
              backgroundColor:
                user.id === drawData?.id ? "rgba(0, 108, 173, 0.2)" : "unset",
              width: "100%",
              position: "absolute",
              top: yPos,
              height: RND_BAR_HEIGHT,
            }}
            key={role + "-" + user.id}
          />
        );
      });

      return arr;
    },
    [
      staffData,
      allowEdit,
      onUpdateStaffServiceSchedule,
      getEmployeeSetterArray,
      onDelete,
    ]
  );

  const renderDrivers = useCallback(() => {
    return renderStaffs({
      allPlansData,
      allUserData: drivers,
      horizontalPositionMap,
      role: "drivers",
      verticalMap,
      drawData: drawDatas.driverDrawData,
    });
  }, [
    drivers,
    verticalMap,
    horizontalPositionMap,
    // machineService,
    allPlansData,
    renderStaffs,
    drawDatas.driverDrawData,
  ]);

  const renderSupervisors = useCallback(() => {
    return renderStaffs({
      allPlansData,
      allUserData: supervisors,
      horizontalPositionMap,
      role: "managers",
      verticalMap,
      drawData: drawDatas.supervisorDrawData,
    });
  }, [
    supervisors,
    verticalMap,
    horizontalPositionMap,
    // machineService,
    allPlansData,
    renderStaffs,
    drawDatas.supervisorDrawData,
  ]);

  const renderWorkers = useCallback(() => {
    return renderStaffs({
      allPlansData,
      allUserData: workers,
      horizontalPositionMap,
      role: "workers",
      verticalMap,
      drawData: drawDatas.workerDrawData,
    });
  }, [
    workers,
    verticalMap,
    horizontalPositionMap,
    // machineService,
    allPlansData,
    renderStaffs,
    drawDatas.workerDrawData,
  ]);

  const isDirty = useMemo(() => {
    // check if changes have been made by checking the saveRef
    if (saveRef) {
      return false;
    }

    if (machineService) {
      const { color, comment, end, reason, start } = machineService;

      const machineData: Omit<TMachineService, "crews"> = {
        color,
        comment,
        end,
        reason,
        start,
      };

      const evaluationArr = [
        JSON.stringify(initialDataRef?.current?.drivers) ===
          JSON.stringify(drivers),
        JSON.stringify(initialDataRef?.current?.supervisors) ===
          JSON.stringify(supervisors),
        JSON.stringify(initialDataRef?.current?.workers) ===
          JSON.stringify(workers),
        JSON.stringify({
          color: initialDataRef.current?.machineService.color,
          comment: initialDataRef.current?.machineService.comment,
          end: initialDataRef.current?.machineService.end,
          reason: initialDataRef.current?.machineService.reason,
          start: initialDataRef.current?.machineService.start,
        }) === JSON.stringify(machineData),
      ];

      return evaluationArr.some((el) => !el);
    }

    return false;
  }, [machineService, saveRef, drivers, supervisors, workers]);

  const onAddTeam = useCallback(() => {
    if (machineTeamsData.length && allUsersData.length) {
      const team = machineTeamsData[0];

      const { drivers, managers, workers } = team;

      const arr: { staffs: UserWorkerVM[]; role: OverlapKeys }[] = [
        { staffs: drivers, role: "drivers" },
        { staffs: workers, role: "workers" },
        { staffs: managers, role: "managers" },
      ];

      arr.forEach(({ role, staffs }) => {
        staffs.forEach((staff) => {
          const staffData = allUsersData.find((el) => el.id === staff.id);
         
          const employeesArr = getEmployeeArray(role);
          const foundCrew = employeesArr?.find(
            (user) => user.id === staffData?.staffData.userId
          );

          if (foundCrew) {
            dispatch(
              saveMessage(
                "This user has already been added to the service.",
                MessageBarType.error
              )
            );

            return;
          }

          if (staffData) {
            const userData = onAddEmployee({
              addedUser: staffData,
              role,
              isAddToAllTeam: true,
            })!;
            
            const setter = getEmployeeSetterArray(role);

            setter!((prev) => {
              return [...prev, userData];
            });
          }
        });
      });
    }
  }, [machineTeamsData, allUsersData, onAddEmployee, getEmployeeSetterArray]);

  if (isProdUrl()) {
    return <Redirect to={"/"} />;
  }
  return (
    <div className={classNames.root}>
      <Prompt
        when={isDirty}
        message={JSON.stringify({
          header:
            "Are you sure you want to leave the page, you have unsaved changes?",
          content: "",
        })}
      />
      <div className={classNames.pageInnerContainer}>
        {/* @ts-ignore */}
        <CalendarControlsDetailedPlanning
          hidden={false}
          setHidden={() => {}}
          machines={machinesData}
          onSelectMachines={() => {}}
          setAnyUpdate={() => {}}
          submitUpdatePlan={submitUpdatePlan}
          submitAndClose={submitAndClose}
          allowEdit={allowEdit}
          inactive={false}
          onDrawVacation={() => {}}
          isDisabled={!isInitialized || isUpdatingMachineService}
          showHideButton={false}
          showMachineFilter={false}
          showVacationDrawIcon={false}
          projectName={
            machineData?.machineName && serviceIndex
              ? `${machineData?.machineName} (Service Index ${serviceIndex})`
              : ""
          }
        />
        <div style={{ height: 90 }} />
        {staffData && machineData && machineService ? (
          <div className={classNames.pageContent}>
            <div id="leftContainer" className={classNames.leftContainer}>
              <MachineSection
                onClickMachineTeam={onAddTeam}
                allowEdit={allowEdit}
                machineData={machineData}
                showMachineTeamButton={!!machineTeamsData.length}
              />
              <SectionSeparator />
              <Section
                sectionTitle="MASKINFØRER"
                allowEdit={allowEdit}
                icon={faUserHardHat as IconDefinition}
                users={filterStaff(allUsersData, ROLES.DRIVER)}
                propName="drivers"
                sectionId={ID_TARGET.DRIVER}
                machineData={allMachineData}
                workers={filterDuplicateStaffs(drivers)}
                onAdd={(addedUser, role) => {
                  onAddEmployee({ addedUser, role });
                }}
                start={machineService.start}
                end={machineService.end}
                onClickRemove={onRemoveEmployee}
                onClickDraw={(drawData) => {
                  const { isNew } = drawData;
                  if (isNew) {
                    onClickDrawNew(drawData);
                  } else {
                    onClickDraw(drawData);
                  }
                }}
                isDrawingNew={isDrawing}
              />
              <SectionSeparator />
              <Section
                sectionTitle="HÅNDMAND"
                allowEdit={allowEdit}
                icon={faDigging as IconDefinition}
                propName="workers"
                sectionId={ID_TARGET.WORKER}
                machineData={allMachineData}
                users={filterStaff(allUsersData, ROLES.WORKER)}
                workers={filterDuplicateStaffs(workers)}
                onAdd={(addedUser, role) => {
                  onAddEmployee({ addedUser, role });
                }}
                start={machineService.start}
                end={machineService.end}
                onClickRemove={onRemoveEmployee}
                onClickDraw={(drawData) => {
                  const { isNew } = drawData;
                  if (isNew) {
                    onClickDrawNew(drawData);
                  } else {
                    onClickDraw(drawData);
                  }
                }}
                isDrawingNew={isDrawing}
              />
              <SectionSeparator />
              <Section
                sectionTitle="FORMAND"
                allowEdit={allowEdit}
                icon={faUserTie as IconDefinition}
                propName="managers"
                sectionId={ID_TARGET.SUPERVISOR}
                users={filterStaff(allUsersData, ROLES.SUPERVISOR)}
                workers={filterDuplicateStaffs(supervisors)}
                onAdd={(addedUser, role) => {
                  onAddEmployee({ addedUser, role });
                }}
                start={machineService.start}
                end={machineService.end}
                onClickRemove={onRemoveEmployee}
                onClickDraw={(drawData) => {
                  const { isNew } = drawData;
                  if (isNew) {
                    onClickDrawNew(drawData);
                  } else {
                    onClickDraw(drawData);
                  }
                }}
                isDrawingNew={isDrawing}
              />
            </div>
            <div style={{ marginLeft: 200 }}>
              <MachineServiceCalendar
                widthOfCalendar={widthOfCalendar}
                dates={dates}
                backgroundHeight={leftHeight || BACKGROUND_HEIGHT}
              />
              {horizontalPositionMap ? (
                <PlanContainer
                  horizontalPositionMap={horizontalPositionMap}
                  backgroundHeight={leftHeight || BACKGROUND_HEIGHT}
                >
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      top: 0,
                      left: 0,
                      zIndex: 556,
                      width: calendarStepWidth * horizontalPositionMap["end"],
                      height: "100%",
                      userSelect: "none",
                    }}
                  >
                    {isInitialized ? (
                      <>
                        {renderMachineServices()}
                        {renderMachineSchedule()}
                        {renderMachineService()}

                        {renderMachinePrebookings()}

                        {renderDrivers()}
                        {renderWorkers()}
                        {renderSupervisors()}
                        {renderDrawer()}
                        {renderDrawExistingUser()}
                        {renderDrawNewUser()}
                      </>
                    ) : (
                      <></>
                    )}
                    {renderRightSideHorizontal()}
                  </div>
                </PlanContainer>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MachineService;
