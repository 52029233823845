// Import libraries
import moment from "moment";

// Import Types
import { TMachine, TPlan, User } from "../types";
import { TPlanHorizontalPositionMap } from "../types/planner";
import { AllUserData } from "../types/custom";
import { getCrewServiceSchedule } from "./service";
import { findStaffSchedule } from "./utils";
import { OverlapKeys } from "../redux/project/project.actions";
import { workingRoleOptions } from "./constants";
import { addDays, eachDayOfInterval } from "date-fns";
import {
  BOOKED_STATE,
  EBOOKED,
} from "../components/pages/planning/detailedPlanning/PopupSupervisors";
import { TBooking } from "../types/api/booking";

export const getServiceText = ({
  comment,
  reason,
}: {
  comment: string;
  reason: string;
}) => comment + (reason ? ` (${reason})` : "");

export const formatDateForBar = (date: string | Date) =>
  moment(date, "YYYY-M-D").format("D.M.YY");

//   Returns the x position and width of the bar
export const getMapTransform = ({
  start,
  end,
  horizontalPositionMap,
  calendarStepWidth,
}: {
  horizontalPositionMap: TPlanHorizontalPositionMap;
  start: string;
  end: string;
  calendarStepWidth: number;
}): {
  xPos: number;
  width: number;
} => {
  let startDate = moment(start, "YYYY-M-D").format("YYYY-M-D");
  let endDate = moment(end, "YYYY-M-D").format("YYYY-M-D");
  // let endDate = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");
  const startCalendar = horizontalPositionMap.startText.split(".").reverse();
  const endCalendar = horizontalPositionMap.endText.split(".").reverse();
  const startDateBeforeStartCalendar =
    new Date(startDate).getTime() <
    new Date(
      +("20" + startCalendar[0]),
      +startCalendar[1] - 1,
      +startCalendar[2]
    ).getTime();
  const endDateAfterEndCalendar =
    new Date(endDate).getTime() >=
    new Date(
      +("20" + endCalendar[0]),
      +endCalendar[1] - 1,
      parseInt(endCalendar[2]) + 1
    ).getTime();
  let startTemp = startDateBeforeStartCalendar
    ? 0
    : (horizontalPositionMap[startDate] as number);
  let endTemp = endDateAfterEndCalendar
    ? (horizontalPositionMap[endDate] as number) ||
      horizontalPositionMap.end + 1
    : (horizontalPositionMap[endDate] as number);
  let tempX = startTemp * calendarStepWidth;
  let tempWidth = (endTemp - startTemp) * calendarStepWidth;

  //   Handling edge cases
  if (typeof startTemp === "undefined" && typeof endTemp !== "undefined") {
    tempX = 0;
    tempWidth = endTemp * calendarStepWidth;
  }

  if (typeof startTemp === "undefined" || typeof endTemp === "undefined") {
    tempX = 0;
    tempWidth = 0;
  }

  if (typeof startTemp === "undefined" && typeof endTemp === "undefined") {
    tempX = 0;
    tempWidth = (horizontalPositionMap["end"] - startTemp) * calendarStepWidth;
  }

  return {
    width: tempWidth ? tempWidth + calendarStepWidth : tempWidth,
    xPos: tempX,
  };
};

type TIDToYPos = {
  [k: string]: number;
};

export type TVerticalMap = Record<OverlapKeys, TIDToYPos>;

const BAR_HEIGHT = 32;
const LEFT_CONTAINER_TOP_MARGIN = 36;
const SECTION_GAP = 15;
const BAR_GAP = 8;
const MINIMUM_SECTION_HEIGHT = 110;
const SEPARATOR_HEIGHT = 2;

export const generateVerticalMap = ({
  machines,
  drivers,
  workers,
  supervisors,
  allowEdit,
}: {
  machines: TMachine[];
  drivers: AllUserData[];
  workers: AllUserData[];
  supervisors: AllUserData[];
  allowEdit: boolean;
}) => {
  const arrays: { data: AllUserData[]; key: OverlapKeys }[] = [
    { data: drivers, key: "drivers" },
    { data: workers, key: "workers" },
    { data: supervisors, key: "managers" },
  ];

  const separatorPositions: number[] = [];

  const allowEditHeight = allowEdit ? BAR_HEIGHT : 0;

  const map: TVerticalMap = {
    drivers: {},
    machines: {},
    managers: {},
    workers: {},
  };

  // This is because the left container is already shifted down by this amount by having it's margin top to 36
  let yPos = LEFT_CONTAINER_TOP_MARGIN;

  let machineSectionHeight: number = 0;

  // Loop through arr
  machines.forEach((machine, idx) => {
    const id = machine.machineId;

    map.machines[id] = yPos;

    const addToYPos = BAR_HEIGHT + (idx === 0 ? 0 : BAR_GAP);

    yPos += addToYPos;
    machineSectionHeight += addToYPos;
  });

  // If the current machines in the section does not exceed the minimum 110px height
  // Then we will override the added section height
  // This is done because what if only machines only make up for about 64px of height(meaning 2 staffs)
  // The remainder 110px - 64px = 46px is accounted for causing an incorrect right side
  // 110px is used to make the left side more aesthetic
  if (machineSectionHeight < MINIMUM_SECTION_HEIGHT) {
    yPos -= machineSectionHeight;
    yPos += MINIMUM_SECTION_HEIGHT;
  }

  yPos += SECTION_GAP;

  separatorPositions.push(yPos);

  yPos += SECTION_GAP + SEPARATOR_HEIGHT;

  arrays.forEach(({ data, key }, idx) => {
    let sectionHeight: number = 0;

    // separatorPositions.push(yPos);

    data.forEach((user, idx) => {
      const id = user.id;

      if (!map[key][id]) {
        map[key][id] = yPos;

        yPos += BAR_HEIGHT + (idx < data.length - 1 ? BAR_GAP : 0);
        sectionHeight += BAR_HEIGHT + (idx < data.length - 1 ? BAR_GAP : 0);
      }
    });

    const gapAboveEditButton = data.length ? BAR_GAP : 0;

    const addToYPos =
      data.length && allowEdit ? allowEditHeight + gapAboveEditButton : 0;

    yPos += addToYPos;
    sectionHeight += addToYPos;

    // If the current staff in the section does not exceed the minimum 110px height
    // Then we will override the added section height
    // This is done because what if only staffs only make up for about 64px of height(meaning 2 staffs)
    // The remainder 110px - 64px = 46px is accounted for causing an incorrect right side
    // 110px is used to make the left side more aesthetic
    if (sectionHeight < MINIMUM_SECTION_HEIGHT) {
      yPos -= sectionHeight;
      yPos += MINIMUM_SECTION_HEIGHT;
    }

    yPos += SECTION_GAP;

    // We don't want a line below
    if (arrays.length - 1 !== idx) {
      separatorPositions.push(yPos);
    }

    yPos += SECTION_GAP + SEPARATOR_HEIGHT;
  });

  return {
    verticalMap: map,
    separatorPositions,
  };
};

export const getUserAllData = ({
  user,
  machinesData,
  role,
  allPlansData,
}: {
  user: User;
  machinesData: TMachine[];
  role: string;
  allPlansData: TPlan[];
}): AllUserData => {
  return {
    id: user.userId,
    array: [
      // @ts-ignore
      // {
      //   ...getWidthStartEndNumberByHorizontalPositionMap(
      //     horizontalPositionMap,
      //     crew.start,
      //     crew.end,
      //     calendarStepWidth
      //   ),
      //   ...foundCrew,
      // },
    ],
    staffSchedule: findStaffSchedule(
      user.userId,
      "NONE",
      role as OverlapKeys,
      allPlansData
    ),
    staffVacation: user.starfVacations,
    serviceSchedules: getCrewServiceSchedule(user.userId, machinesData),
    staffData: user,
  };
};

export const convertRolePropToRole = (role: OverlapKeys): string => {
  switch (role) {
    case "drivers":
      return "DRIVER";
    case "managers":
      return "SUPERVISOR";
    case "workers":
      return "WORKER";
  }

  return "";
};

type TUserDateMaps = { [k: string]: number };

export const generateUserDateMaps = (userData: AllUserData): TUserDateMaps => {
  const { staffSchedule, serviceSchedules, staffData } = userData;

  const { starfVacations } = staffData;

  const maps: TUserDateMaps = {};

  serviceSchedules.forEach(({ start, end }) => {
    generateDatesRange({
      // startDate: addDays(new Date(start), 1).toISOString(),
      startDate: start,
      endDate: end,
    }).forEach((dt) => {
      maps[dt.toISOString().split("T")[0]] = 1;
    });

    // maps[start.split("T")[0]] = 1;
    // maps[end.split("T")[0]] = 1;
  });

  (starfVacations || []).forEach(({ start, end }) => {
    if (start > end) {
      end = start;
    }
    generateDatesRange({
      // startDate: addDays(new Date(start), 1).toISOString(),
      startDate: start,
      endDate: end,
    }).forEach((dt) => {
      maps[dt.toISOString().split("T")[0]] = 1;
    });

    // maps[start.split("T")[0]] = 1;
    // maps[end.split("T")[0]] = 1;
  });

  staffSchedule.forEach(({ start, end }) => {
    generateDatesRange({
      startDate: start,
      endDate: end,
    }).forEach((dt) => {
      maps[dt.toISOString().split("T")[0]] = 1;
    });

    // maps[start.split("T")[0]] = 1;
    // maps[end.split("T")[0]] = 1;
  });

  return maps;
};

// If we have multiple machine services and want to
// generate the maps for then we can use this
export const generateDatesMap = (
  values: { startDate: string; endDate: string }[]
) => {
  const dateMaps: { [k: string]: number } = {};

  values.forEach(({ startDate, endDate }) => {
    const arr = eachDayOfInterval({
      start: new Date(startDate),
      // We have to add day because the eachDayOfInterval does not include the last date.
      // For example, if the end date is 02-02-2024, they will only generate until 02-01-2024
      end: addDays(new Date(endDate), 1),
    });

    // We have to do this because if we put the start date as 08-01-2024, 07 is also included which we don't want
    arr.splice(0, 1);

    arr.forEach((dt) => {
      dateMaps[dt.toISOString().split("T")[0]] = 1;
    });
  });

  return dateMaps;
};

export const checkOverlap = ({
  dates,
  end,
  start,
}: {
  start: string;
  end: string;
  dates: { startDate: string; endDate: string }[];
}) => {
  if (start === end) {
    return true;
  }

  const datesMap = generateDatesMap(dates);

  // This is done because there is a false overlap
  // For example, we check from 1st August to 9th August
  // It might overlap with another vacation,scheduler starting at 9th
  // Which is inaccuracte. Since something can end at 9th august
  // and something can start on 9th august as well
  const subtractedOneEndDate = addDays(new Date(end), -1);
  const dateStr = subtractedOneEndDate.toISOString();

  if (datesMap[start.split("T")[0]] || datesMap[dateStr.split("T")[0]]) {
    return true;
  }

  return false;
};

export const findMostSuitableTime = ({
  start,
  end,
  allUserData,
}: {
  start: string;
  end: string;
  allUserData: AllUserData;
}) => {
  const dateMaps = generateUserDateMaps(allUserData);

  const dateRange = generateDatesRange({ startDate: start, endDate: end }).map(
    (dt) => dt.toISOString().split("T")[0]
  );

  let backStr = "T00:00:00Z";

  let currentStart: string | null = null;
  let currentEnd: string | null = null;

  dateRange.forEach((dt) => {
    if (!currentStart) {
      if (!dateMaps[dt]) {
        currentStart = dt;
      }
    } else {
      if (!dateMaps[dt]) {
        currentEnd = dt;
      }
    }
  });

  // If end/start is not specified or currentEnd is the same current start that means there are not suitable time
  if (currentEnd === currentStart || !currentEnd || !currentStart) {
    currentEnd = currentStart = null;
  }

  // Adding backStr because I want it to be in the format of 2022-08-22T00:00:00Z
  return {
    start: currentStart ? currentStart + backStr : currentStart,
    end: currentEnd ? currentEnd + backStr : currentStart,
  };
};

// Input example: startDate: 2022-08-22T00:00:00Z,endDate: 2022-08-27T00:00:00Z
// Output example:
// [
//   2022-08-22T00:00:00.000Z,
//   2022-08-23T00:00:00.000Z,
//   2022-08-24T00:00:00.000Z,
//   2022-08-25T00:00:00.000Z,
//   2022-08-26T00:00:00.000Z,
//   2022-08-27T00:00:00.000Z
// ]
export const generateDatesRange = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const arr = eachDayOfInterval({
    start: new Date(startDate),
    // We have to add day because the eachDayOfInterval does not include the last date.
    // For example, if the end date is 02-02-2024, they will only generate until 02-01-2024
    end: addDays(new Date(endDate), 1),
  });

  // We have to do this because if we put the start date as 08-01-2024, 07 is also included which we don't want
  arr.splice(0, 1);
  return arr;
};

// staff role is for example 3;4 and is stored in the database
// We want to convert it to our system which is ROLES in constants in ts
export const getRole = (staffRole: string): OverlapKeys | string => {
  const role = workingRoleOptions.find((role) =>
    staffRole.includes(role.key + "")
  );

  return role?.name || "UNKNOWN";
};

export const checkStaffBookedCondition = ({
  end,
  start,
  user,
}: {
  start: string;
  end: string;
  user: AllUserData;
}): EBOOKED => {
  const { end: suitableEndDate, start: suitableStartDate } =
    findMostSuitableTime({
      allUserData: user,
      end,
      start,
    });

  if (!suitableEndDate || !suitableStartDate) {
    return "FULLY";
  } else if (start === suitableStartDate && end === suitableEndDate) {
    return "NOT";
  }

  return "PARTIAL";
};

export const bookedStateStyle = (state: EBOOKED) => ({
  fontSize: "12px",
  borderRadius: "50%",
  color: state === BOOKED_STATE.PARTIAL ? "#F57C00" : "#d50000",
  backgroundColor: state === BOOKED_STATE.PARTIAL ? "#F57C00" : "#d50000",
});

export const getMachinePrebookings = ({
  prebookingsData,
  machineId,
  projectId,
}: {
  machineId: string;
  projectId: string;
  prebookingsData: TBooking[];
}) => {
  return prebookingsData
    .filter((el) => el.machineId === machineId)
    .map(({ projectName, start, end }) => ({
      color: "242, 135, 5",
      end: end,
      prebookingName: projectName,
      start: start,
    }));
};

export const getMachineBookedProjects = ({
  machineId,
  projectId,
  allPlansData,
}: {
  machineId: string;
  projectId: string;
  allPlansData: TPlan[];
}) => {
  const machineBookedProjects: {
    projectId: string;
    name: string;
    color: string;
    start: string;
    end: string;
    crmProjectStatusCode: string;
  }[] = [];
  allPlansData
    .filter((plan) => !plan.inactive)
    .forEach((plan) => {
      plan.machineRequirements.forEach((requirement) => {
        requirement.machines.forEach((machine) => {
          if (plan.projectId !== projectId && machine.machineId === machineId) {
            const machineInfo = {
              projectId: plan.projectId,
              name: plan.projectName,
              color: plan.color,
              start: machine.start as string,
              end: machine.end as string,
              crmProjectStatusCode: plan.crmProjectStatusCode,
            };
            machineBookedProjects.push(machineInfo);
          }
        });
      });
    });
  return machineBookedProjects;
};
