import React from 'react';
import { defaultButtonStyles, primaryButtonStyles } from '../../utils/theme';
import { DefaultButton, PrimaryButton } from '@fluentui/react/';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import FixPositionBubble from './FixPositionBubble';

const classNames = mergeStyleSets({
    buttonControls: {
        marginTop: '24px',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 8,
    },
    cancel: {
        marginRight: 10,
    }
});

const teachingBubbleWarningStyles = {
    headline: {
        fontSize: 30,
        display: 'flex',
        justifyContent: 'center',
        color: '#F57C00'
    },
    subText: {
        textAlign: 'center',
        color: 'black',
        fontSize: 14,
    },
}

const DeleteConfirmation = ({target, toggleTeaching, onClickConfirm, label="Are you sure you want to delete this?", mainPositionRight = {}, beakPositionRight = {}}) => {
    return (
        <FixPositionBubble
            styles={teachingBubbleWarningStyles}
            target={target}
            hasSmallHeadline={true}
            onDismiss={toggleTeaching}
            headline={<FontAwesomeIcon icon={faExclamationTriangle} className={classNames.warningIcon} />}
            closeButtonAriaLabel="Close"
            mainPositionRight={mainPositionRight}
            beakPositionRight={beakPositionRight}
        >
            <>
                {label}
                <div className={classNames.buttonControls}>
                    <DefaultButton styles={defaultButtonStyles} className={classNames.cancel} text="No" onClick={toggleTeaching} />
                    <PrimaryButton styles={primaryButtonStyles} text="Confirm" onClick={onClickConfirm} />
                </div>
            </>
        </FixPositionBubble>
    )
}

export default DeleteConfirmation;
