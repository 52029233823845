import { AppActionTypes } from "./app.types";
import {
  MACHINE_KEY_SELECT_PIVOT,
  STAFF_KEY_SELECT_PIVOT,
} from "../../utils/constants";

const initialState = {
  pivot: {
    machine: MACHINE_KEY_SELECT_PIVOT.MACHINE_TABLE,
    staff: STAFF_KEY_SELECT_PIVOT.STAFF_TABLE,
  },
  showOtherSections: {
    machines: false,
    drivers: false,
    workers: false,
    managers: false,
  },
  showSidebar: true,
  openSearchOverlay: false,
  searchHistory: [],
  selectedSearchEnum: 0,
  addCalendarLength: 0,
  barDrawer: "",
  highlightedMachineId: null,
  highlightedStaffId: null,
  openMachineServiceBubble: false,
  openStaffVacationBubble: false,
  openCRMOverlay: false,
  selectedCRMtab: "unplanned",
  drawingProjectName: null,
  drawingProjectId: null,
  drawingMachineReqIndex: null,
  crmCounter: null,
  openDarkOverlay: false,
  planPlaceholder: {
    active: false,
    target: null,
    machineType: null,
    machineSizeGroup: null,
    calendarStepWidth: null,
    calendarStepHeight: null,
    start: null,
    end: null,
  },
  currentUnplannedItem: null,
  syncProcess: null,
  syncLastCall: null,
  showInactiveProject: false,
  tableSort: {},
  storeVersion: "1.0",
  showInactiveMissingUser: false,
  barMachineServiceData: {
    comment: "",
    service: "",
  },
  weeksShown: 7,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.CHANGE_SELECTED_PIVOT:
      return {
        ...state,
        pivot: {
          ...state.pivot,
          [action.key]: action.value,
        },
      };
    case AppActionTypes.SET_WEEKS_SHOWN:
      return {
        ...state,
        weeksShown: action.value,
      };
    case AppActionTypes.TOGGLE_INACTIVE_MISSING_USERS:
      return {
        ...state,
        showInactiveMissingUser: !state.showInactiveMissingUser,
      };
    case AppActionTypes.CHANGE_SHOW_OTHER_SECTIONS:
      return {
        ...state,
        showOtherSections: {
          ...state.showOtherSections,
          [action.key]: action.value,
        },
      };

    case AppActionTypes.SET_SIDE_BAR_STATUS:
      return {
        ...state,
        showSidebar: action.value,
      };

    case AppActionTypes.CHANGE_ZOOM_RATIO:
      return {
        ...state,
        ...action.value,
      };

    case AppActionTypes.CHANGE_SEARCH_OVERLAY:
      return {
        ...state,
        openSearchOverlay: action.value,
      };

    case AppActionTypes.CHANGE_CRM_OVERLAY:
      return {
        ...state,
        openCRMOverlay: action.value,
      };

    case AppActionTypes.CHANGE_SEARCH_ENUM:
      return {
        ...state,
        selectedSearchEnum: parseInt(action.value),
      };

    case AppActionTypes.CHANGE_SEARCH_HISTORY:
      let newSearchHistory = state.searchHistory
        ? [...state.searchHistory]
        : [];
      if (!newSearchHistory.some((item) => item === action.value)) {
        if (newSearchHistory.length > 9) {
          newSearchHistory.splice(0, 1);
        }
        newSearchHistory.push(action.value);
      }
      return {
        ...state,
        searchHistory: newSearchHistory,
      };

    case AppActionTypes.CLEAR_SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: [],
      };

    case AppActionTypes.CHANGE_CALENDAR_LENGTH:
      return {
        ...state,
        addCalendarLength: action.value,
      };

    case AppActionTypes.SET_BAR_DRAWER:
      return {
        ...state,
        barDrawer: action.payload,
      };
    case AppActionTypes.SET_BAR_DRAWING_MACHINE_SERVICE:
      return {
        ...state,
        barMachineServiceData: action.payload,
      };

    case AppActionTypes.SET_HIGHLIGHT_MACHINE:
      return {
        ...state,
        highlightedMachineId: action.value,
      };

    case AppActionTypes.SET_HIGHLIGHT_STAFF:
      return {
        ...state,
        highlightedStaffId: action.value,
      };

    case AppActionTypes.SET_MACHINE_SERVICE_BUBBLE:
      return {
        ...state,
        openMachineServiceBubble: action.value,
      };

    case AppActionTypes.SET_STAFF_VACATION_BUBBLE:
      return {
        ...state,
        openStaffVacationBubble: action.value,
      };

    case AppActionTypes.SELECT_CRM_TAB:
      return {
        ...state,
        selectedCRMtab: action.value,
      };

    case AppActionTypes.DRAWING_PROJECT_ID:
      return {
        ...state,
        drawingProjectId: action.value,
      };

    case AppActionTypes.SET_DRAWING_MACHINE_REQ_INDEX:
      return {
        ...state,
        drawingMachineReqIndex: action.value,
      };

    case AppActionTypes.SET_CRM_COUNTER:
      return {
        ...state,
        crmCounter: action.value,
      };

    case AppActionTypes.SET_DARK_OVERLAY:
      return {
        ...state,
        openDarkOverlay: action.value,
      };

    case AppActionTypes.SET_PLAN_PLACE_HOLDER_DATA:
      return {
        ...state,
        planPlaceholder: action.value,
      };

    case AppActionTypes.SET_CURRENT_UNPLANNED_ITEM:
      return {
        ...state,
        currentUnplannedItem: action.value,
      };

    case AppActionTypes.DRAWING_PROJECT_NAME:
      return {
        ...state,
        drawingProjectName: action.value,
      };

    case AppActionTypes.SET_SYNC_PROCESS:
      return {
        ...state,
        syncProcess: action.value,
      };

    case AppActionTypes.TOGGLE_INACTIVE_PROJECT:
      return {
        ...state,
        showInactiveProject: !state.showInactiveProject,
      };

    case AppActionTypes.SET_TABLE_SORT:
      const { key, value } = action.value;
      return {
        ...state,
        tableSort: { ...state.tableSort, [key]: value },
      };

    case AppActionTypes.SET_SYNC_LAST_CALL:
      return {
        ...state,
        syncLastCall: action.value,
      };

    default:
      return state;
  }
};

export default appReducer;
