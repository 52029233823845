import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearConflictMessage } from "../../redux/message/message.actions";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faUserHardHat,
  faDigging,
  faUserTie,
} from "@fortawesome/pro-regular-svg-icons";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Image, MessageBarType } from "@fluentui/react/";
import moment from "moment";
import { MESSAGE_INFO } from "../../utils/constants";

const classNames = mergeStyleSets({
  closeIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 30,
    margin: 20,
    cursor: "pointer",
    borderRadius: "50%",
    color: "#006CAD",
    fontSize: 18,
    selectors: {
      ":hover": {
        color: "#DFDFDF",
        backgroundColor: "#868685",
      },
    },
  },
  tableColumn: {
    padding: "5px",
    fontWeight: "bold",
    overflow: "hidden",
  },
  titleElementStyle: {
    display: "flex",
  },
  titleNameElementStyle: {
    fontWeight: "bold",
    margin: "5px 0px 0px 10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const icons = {
  driver: faUserHardHat,
  worker: faDigging,
  manager: faUserTie,
};

const textStyles = {
  padding: "0 10px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  minWidth: "85px",
};

const ConflictMessage = () => {
  const dispatch = useDispatch();
  const { conflictMessage, messageType } = useSelector(
    (state) => state.message
  );
  const showConflictMessage =
    conflictMessage &&
    Object.keys(conflictMessage).some(
      (key) => Object.keys(conflictMessage[key]).length
    );
  const [open, setOpen] = useState(false);

  const backgroundProp = (color, backgroundCaro) => {
    let bgProp = {
      background: `repeating-linear-gradient(90deg, rgba(${color},1), rgba(${color},1) 24px, rgba(${color},0.7) 24px, rgba(${color},0.7) 48px)`,
    };
    if (backgroundCaro) {
      bgProp = {
        backgroundImage: `linear-gradient(45deg, rgba(${color},1) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(${color},1) 75%), linear-gradient(45deg, transparent 75%, rgba(${color},1) 75%), linear-gradient(45deg, rgba(${color},1) 25%, rgba(${color},0.5) 25%)`,
        backgroundSize: "31px 31px",
        backgroundPosition: "0 0, 0 0, -15px -15px, 15px 15px",
      };
    }
    return bgProp;
  };

  useEffect(() => {
    let timer;
    if (showConflictMessage) {
      setOpen(true);
      timer = setTimeout(() => {
        clearConflictMessage();
      }, 500000);
    } else {
      setOpen(false);
      dispatch(clearConflictMessage());
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conflictMessage]);

  if (!open) {
    return null;
  }

  const conflictBar = (start, end, name, color, backgroundCaro) => (
    <div
      style={{
        ...backgroundProp(color, backgroundCaro),
        display: "flex",
        marginBottom: 6,
        height: 32,
        width: 425,
        borderRadius: 8,
      }}
    >
      <div
        style={{
          color: "#fff",
          fontWeight: "bold",
          height: "100%",
          width: "100%",
          fontSize: 13,
          lineHeight: "32px",
          margin: "0px 4px",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#fff",
            }}
          >
            <div style={textStyles}>{start}</div>
            <div style={{ ...textStyles, minWidth: "170px" }}>{name}</div>
            <div style={textStyles}>{end}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    <table
      cellPadding="0"
      cellSpacing="0"
      style={{
        boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
        position: "fixed",
        top: 50,
        left: "calc(50% - 350px)",
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        minHeight: 80,
        width: 750,
        zIndex: 9999999,
      }}
    >
      <tbody>
        <tr
          style={{ borderRadius: 10, color: "#000", backgroundColor: "#fff" }}
        >
          <td
            style={{
              width: "11%",
              textAlign: "center",
              fontSize: 24,
              color: "#fff",
              backgroundColor: MESSAGE_INFO[messageType]?.color,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          >
            <FontAwesomeIcon icon={MESSAGE_INFO[messageType]?.icon} />
          </td>
          <td
            style={{
              width: "89%",
              textAlign: "left",
              wordBreak: "break-all",
              fontSize: 14,
              fontWeight: 400,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3 style={{ margin: "20px 0px 5px 20px" }}>Overlap!</h3>
                <div style={{ margin: "0px 0px 20px 20px" }}>
                  {messageType === MessageBarType.error
                    ? "You cannot do that "
                    : "Some items cannot be moved "}{" "}
                  beacuse these are allerede booked on:
                </div>
              </div>
              <div
                className={classNames.closeIcon}
                onClick={() => {
                  setOpen(false);
                  dispatch(clearConflictMessage());
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>

            {Object.keys(conflictMessage).map((key) =>
              Object.keys(conflictMessage[key]).length ? (
                <table
                  key={key}
                  style={{
                    borderCollapse: "collapse",
                    marginLeft: 20,
                    marginBottom: 20,
                    width: 640,
                  }}
                >
                  <tbody>
                    {Object.keys(conflictMessage[key]).map((name) =>
                      conflictMessage[key][name].map((item, index) => (
                        <tr key={`${item.projectId}${index}`}>
                          {index === 0 && (
                            <td
                              rowSpan={conflictMessage[key][name].length}
                              className={classNames.tableColumn}
                              style={{
                                width: "30%",
                                maxWidth: "180px",
                                verticalAlign: "top",
                              }}
                            >
                              <div className={classNames.titleElementStyle}>
                                {key === "machine" ? (
                                  <Image
                                    style={{ marginTop: -5 }}
                                    src={`${window.location.origin}/img/machineBlack.svg`}
                                    alt="machine"
                                    width={20}
                                    height={32}
                                    className={classNames.image}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={icons[key]}
                                    style={{ fontSize: 19, marginTop: 5 }}
                                  />
                                )}
                                <div
                                  className={classNames.titleNameElementStyle}
                                >
                                  {name}
                                </div>
                              </div>
                            </td>
                          )}
                          <td
                            className={classNames.tableColumn}
                            style={{ width: "70%", textAlign: "center" }}
                          >
                            {conflictBar(
                              moment(item.start).format("DD.MM.YYYY"),
                              moment(item.end).format("DD.MM.YYYY"),
                              item.name,
                              item.color,
                              item.backgroundCaro
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              ) : null
            )}
          </td>
        </tr>
      </tbody>
    </table>,
    document.querySelector("#message")
  );
};

export default ConflictMessage;
