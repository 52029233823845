import ReactDOM from "react-dom";
import React from "react";
import { OverlayConfirm } from './';
import { CONFIRM_PANEL_MODE } from '../../utils/constants';

const UserLeaveConfirmation = (message, callback, confirmOpen, setConfirmOpen) => {
    const container = document.createElement("div");

    container.setAttribute("custom-confirm-view", "");

    const handleConfirm = (callbackState) => {
        ReactDOM.unmountComponentAtNode(container);
        callback(callbackState);
        setConfirmOpen(false);
    };

    const handleCancel = (callbackState) => {
        ReactDOM.unmountComponentAtNode(container);
        callback();
        setConfirmOpen(false);
    };

    document.body.appendChild(container);
    const { header, content } = JSON.parse(message);
    ReactDOM.render(
        <OverlayConfirm
            overlayMode={confirmOpen && CONFIRM_PANEL_MODE.DANGER}
            title={header}
            content2={content}
            onClose={handleConfirm}
            onClickConfirm={handleCancel}
            firstButtonText="Yes, leave"
            secondButtonText="No, stay"
        />,
        container
    );
};

export default UserLeaveConfirmation;
