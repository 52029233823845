import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { datesGenerator } from "dates-generator";
import moment from "moment";
import "moment/locale/da";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { connect, useDispatch, useSelector } from "react-redux";
import { getHolidayList } from "../../../redux/app/app.actions";
import {
  getMachines,
  selectNewDate,
  updateMachine,
} from "../../../redux/machines/machines.actions";
import {
  CALENDAR_PAGE,
  CALENDAR_STEP_HEIGHT,
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  months,
} from "../../../utils/constants";
import {
  calculateFinalDates,
  findYearOfDates,
  getCalendarHoliday,
  getLastDate,
  hasEditRight,
  removeFirstWeek,
  startCalendarFromMonth,
  startCalendarFromSelectedWeek,
} from "../../../utils/utils";
import { ItemContainer } from "../../common";
import {
  CalendarControlsLeft,
  CalendarControlsRight,
  DayLabel,
  MonthLabel,
  WeekLabel,
} from "../../common/calendar";
import ScreenSizeAndPosition from "../../common/ScreenSizeAndPosition";
import { StationSelector } from "../planning/machineDisposition";
import MachineServiceBubble from "./MachineServiceBubble";
import MachineServiceItem from "./MachineServiceItem";
import { getPlans } from "../../../redux/plan/plan.actions";
import { getStaffs } from "../../../redux/user/user.actions";
import DispositionStickyControl from "../DispositionStickyControl";

// https://dev.to/aibrahim3546/creating-a-custom-calendar-in-react-from-scratch-1hej
// https://gist.github.com/aibrahim3546/dc68f9525f0f206ff08b1d09ee3adab5
const MachineServiceCalendar = ({
  getMachines,
  techDepartment,
  techArea,
  displayWeekView,
  selectNewDate,
  selectedDayOnCalendar,
  scrollY,
}) => {
  const showSidebar = useSelector((state) => state.app.showSidebar);
  const classNames = mergeStyleSets({
    root: {
      minHeight: "100%",
      width: 3650,
      display: "flex",
      flexDirection: "row",
    },
    leftContainer: {
      width: 250,
      position: "sticky",
      zIndex: 1002,
      top: 0,
      left: showSidebar ? 104 : 0,
      background: "rgb(241, 241, 241)",
      transition: "all 0.5s ease",
    },
    machineAndCalendarContainer: {
      width: "100%",
      minHeight: "100%",
      display: "flex",
      justifyContent: "flex-start",
      background: "rgb(241, 241, 241)",
    },
    machineContainer: {
      width: 250,
      minHeight: "100%",
    },
    calendarContainer: {
      minHeight: "100%",
      width: 300,
    },
    monthText: {
      fontSize: 26,
      fontWeight: "bold",
      textAlign: "center",
    },
    days: {
      display: "flex",
      justifyContent: "flex-start",
    },
    dayOfWeeks: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8,
    },
    weeks: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 4,
    },
    week: {
      minWidth: 175,
      height: 20,
      background: "#E7E7E7",
      fontSize: 11,
      lineHeight: 20,
      borderRadius: 10,
    },
    stickyHeader: {
      // position: -webkit-sticky; /* Safari */
      width: 4890,
      position: "sticky",
      top: 0,
      minWidth: "calc(100vw - 150px)",
      background: "#F1F1F1",
      zIndex: 1001,
    },
  });
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const calendarStartFrom =
    useSelector(
      (state) => state?.user?.user?.defaultFilter.customFilters
    )?.find((item) => item.key === "calendarStartFrom")?.text || "month";

  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const [dates, setDates] = useState([]);
  const [calendar, setCalendar] = useState({});
  const [calendarStepWidth, setCalendarStepWidth] = useState(
    displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
  );

  const fetchPlansQueryKey = useMemo(
    () => [
      "plans-" +
        moment(new Date(selectedDayOnCalendar)).format("YYYY-M-D") +
        "-" +
        techDepartment,
    ],
    [selectedDayOnCalendar, techDepartment]
  );

  const { data: allPlansData } = useQuery(
    fetchPlansQueryKey,
    () => {
      if (dates.length) {
        const startDate = dates[0][0];
        const endDate = getLastDate(dates);

        const startCalendar = new Date(
          startDate.year,
          startDate.month,
          startDate.date
        ).toISOString();
        const endCalendar = new Date(
          endDate.year,
          endDate.month,
          endDate.date
        ).toISOString();
        return dispatch(
          getPlans(startDate.year, startCalendar, endCalendar, techDepartment)
        );
      }
      return [];
    },
    { staleTime: 6000 * 5, refetchOnWindowFocus: false }
  );

  const { data: machinesData } = useQuery("machines", getMachines, {
    staleTime: 36000,
  });

  const { data: staffsData } = useQuery("staffs", dispatch(getStaffs));

  const { mutate: updateMachineService } = useMutation(
    (machine) => dispatch(updateMachine(machine)),
    {
      onSuccess: () => {
        if (machinesData) {
          queryClient.invalidateQueries("machines");
        }
      },
    }
  );
  const holidayRef = useRef(null);

  const filterMachines = (machinesData, techDepartment, techArea) => {
    let filterData = [];
    if (Array.isArray(machinesData)) {
      filterData = techDepartment
        ? machinesData
            .filter(
              (machine) =>
                !machine.inactive &&
                machine.techDepartments.includes(techDepartment) &&
                techArea.some((key) =>
                  machine.techAreas.replace("-1", "").includes(key)
                )
            )
            .sort((a, b) => a.sortIndex - b.sortIndex)
        : machinesData;
    }
    return filterData;
  };

  const createHorizontalPositionMap = (dates) => {
    const result = {};
    const datePositionCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        //date: 1, month: 2, year: 2021
        let key = `${dates[i][j]["year"]}-${dates[i][j]["month"] + 1}-${
          dates[i][j]["date"]
        }`;
        result[key] = counter;
        datePositionCorrespondence[counter] = moment(key, "YYYY-M-D").format(
          "YYYY-MM-DD[T]HH:mm:ss.SSS"
        );
        counter++;
      }
    }
    result["end"] = --counter;
    result["positionsToDates"] = datePositionCorrespondence;
    return result;
  };

  const createVerticalPositionMap = (machines) => {
    const result = {};
    const positionIdCorrespondence = {};
    let counter = 0;
    for (let i = 0; i < machines.length; i++) {
      result[machines[i]["machineId"]] = counter;
      positionIdCorrespondence[counter * CALENDAR_STEP_HEIGHT] =
        machines[i]["machineId"];
      counter++;
    }
    result["positionsToIds"] = positionIdCorrespondence;
    return result;
  };

  const filteredMachines = useMemo(
    () => filterMachines(machinesData, techDepartment, techArea),
    [machinesData, techDepartment, techArea]
  );
  const verticalPositionMap = useMemo(
    () => createVerticalPositionMap(filteredMachines),
    [filteredMachines]
  );
  const horizontalPositionMap = useMemo(
    () => createHorizontalPositionMap(dates),
    [dates]
  );

  const getMonthIndicator = (dates) => {
    let components = [];
    // const currentMonth = calendar.month;
    let temp = dates[0][0]["month"];
    let counter = 0;
    for (let i = 0; i < dates.length; i++) {
      for (let j = 0; j < dates[i].length; j++) {
        if (dates[i][j]["month"] === temp) {
          counter++;
        } else {
          components.push({ length: counter, label: months[temp] });
          counter = 1;
          temp = dates[i][j]["month"];
        }
      }
    }
    components.push({ length: counter, label: months[temp] });
    return components;
  };

  const onClickChooseDate = (date) => {
    selectNewDate(date);
    const array = new Array(displayWeekView ? 12 : 6);
    array[0] = datesGenerator({
      month: date.getMonth(),
      year: date.getFullYear(),
      startingDay: 1,
    });
    for (let i = 1; i < array.length; i++) {
      array[i] = datesGenerator({
        month: array[i - 1].nextMonth,
        year: array[i - 1].nextYear,
        startingDay: 1,
      });
    }
    let { finalDates, start, end } = calculateFinalDates(array);
    if (calendarStartFrom === "month" && !displayWeekView) {
      finalDates[0] = startCalendarFromMonth(date, finalDates[0]);
      start = new Date(
        finalDates[0][0].year,
        finalDates[0][0].month,
        finalDates[0][0].date
      );
    }
    // Find the start of calendar if choose to start from selected week
    if (calendarStartFrom === "week") {
      finalDates = startCalendarFromSelectedWeek(date, finalDates);
    }
    start = new Date(
      finalDates[0][0].year,
      finalDates[0][0].month,
      finalDates[0][0].date
    );
    async function getDatesWithHolidays() {
      // Find the holiday of calendar
      const yearOfDates = findYearOfDates(finalDates);
      let holidays = [];
      if (
        !holidayRef.current ||
        JSON.stringify(yearOfDates) !==
          JSON.stringify(holidayRef.current?.years)
      ) {
        for (const year of yearOfDates) {
          const currentHolidays = await dispatch(getHolidayList(year));
          holidays = [...holidays, ...(currentHolidays?.holidays || [])];
        }
        holidayRef.current = {
          years: yearOfDates,
          holidays: holidays,
        };
      } else {
        holidays = holidayRef.current.holidays;
      }
      finalDates = getCalendarHoliday(finalDates, holidays);
      setDates(finalDates);
    }
    getDatesWithHolidays();
    setDates(finalDates);
    const endDate = getLastDate(finalDates);
    end = new Date(endDate.year, endDate.month, endDate.date);

    setCalendar({
      ...calendar,
      nextMonth: array[array.length - 1].nextMonth,
      nextYear: array[array.length - 1].nextYear,
      previousMonth: array[0].previousMonth,
      previousYear: array[0].previousYear,
      start,
      end,
    });
  };

  useEffect(() => {
    setCalendarStepWidth(
      displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
    );
    onClickChooseDate(new Date(selectedDayOnCalendar));
  }, [displayWeekView, new Date(selectedDayOnCalendar).toLocaleString()]);

  // Add icon to resize cursor
  useEffect(() => {
    const ref = setTimeout(() => {
      const resizeDivList = document.getElementsByClassName("react-draggable");
      for (let index = 0; index < resizeDivList.length; index++) {
        const resizeDiv = resizeDivList[index].lastChild;
        if (resizeDiv.firstChild && resizeDiv.lastChild) {
          resizeDiv.firstChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.firstChild.style.left = "-14px";
          resizeDiv.lastChild.style.cursor = allowEdit
            ? `url(${window.location.origin}/img/arrows-alt-h.svg), auto`
            : "default";
          resizeDiv.lastChild.style.right = "3px";
        }
      }
    }, 500);

    return () => {
      clearTimeout(ref);
    };
  });

  return (
    <div className={classNames.root}>
      <ScreenSizeAndPosition>
        <MachineServiceBubble />
      </ScreenSizeAndPosition>
      <div className={classNames.leftContainer}>
        <DispositionStickyControl
          scrollPoint={25}
          initialPosition={{
            top: 140,
          }}
          scrollToPosition={{
            top: 21,
          }}
          style={{
            backgroundColor: "rgb(241, 241, 241)",
            zIndex: 1000,
          }}
          backgroundStyle={{
            backgroundColor: "rgb(241, 241, 241)",
            zIndex: 999,
            width: 375,
            position: "fixed",
            height: 184,
            top: 0,
            left: 0,
          }}
        >
          <CalendarControlsLeft calendarPage={CALENDAR_PAGE.MACHINE_SERVICE} />
          <StationSelector />
        </DispositionStickyControl>
        <div className={classNames.machineContainer}>
          {filteredMachines &&
            filteredMachines.map((machine, index) => (
              <ItemContainer
                key={machine.machineId}
                index={index}
                name={machine.machineName}
                techDepartments={machine.techDepartments}
                techAreas={machine.techAreas}
                imageUrl={machine.imageUrl}
                color={machine.color}
                machineData={machine}
                updateMachineService={updateMachineService}
              />
            ))}
        </div>
      </div>
      <div className={classNames.rightContainer}>
        <ScreenSizeAndPosition>
          <CalendarControlsRight
            onClickChooseDate={onClickChooseDate}
            calendarPage={CALENDAR_PAGE.MACHINE_SERVICE}
            scrollY={scrollY}
          />
        </ScreenSizeAndPosition>
        <div className={classNames.stickyHeader}>
          <div style={{ height: 80 }} />
          <div className={classNames.machineAndCalendarContainer}>
            <div className={classNames.calendarContainer}>
              {displayWeekView && <div style={{ height: 54 }} />}
              <div className={classNames.weeks}>
                {dates.length &&
                  getMonthIndicator(dates).map((monthData, indexMonth) => (
                    <MonthLabel
                      isWeekView={displayWeekView}
                      index={indexMonth}
                      key={
                        indexMonth + monthData["length"] + monthData["label"]
                      }
                      length={monthData["length"]}
                      label={monthData["label"]}
                    />
                  ))}
              </div>

              <div
                className={classNames.weeks}
                style={{
                  marginLeft:
                    calendarStartFrom === "month" &&
                    !displayWeekView &&
                    Array.isArray(dates) &&
                    dates[0]?.length < 7
                      ? dates[0].length * CALENDAR_STEP_WIDTH
                      : 0,
                }} // Calculate position for first week
              >
                {dates.length > 0 &&
                  removeFirstWeek(
                    dates,
                    calendarStartFrom === "month" &&
                      !displayWeekView &&
                      Array.isArray(dates) &&
                      dates[0]?.length < 7
                  ).map((week, indexWeek) => (
                    <WeekLabel
                      isWeekView={displayWeekView}
                      index={indexWeek}
                      month={week[0]["month"]}
                      day={week[0]["date"]}
                      year={week[0]["year"]}
                      key={`${week[0]["month"]}-${week[0]["date"]}-${week[0]["year"]}`}
                    />
                  ))}
              </div>
              {!displayWeekView && (
                <>
                  <div className={classNames.days}>
                    {dates.length > 0 &&
                      dates.map((week) =>
                        week.map((each) => (
                          <DayLabel
                            label={each.date}
                            date={each}
                            key={JSON.stringify(each)}
                          />
                        ))
                      )}
                  </div>
                  <div className={classNames.dayOfWeeks}>
                    {dates.length > 0 &&
                      dates.map((week) =>
                        week.map((each) => (
                          <DayLabel
                            label={each.date}
                            date={each}
                            key={JSON.stringify(each)}
                            isDaysOfWeek
                          />
                        ))
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={classNames.machineAndCalendarContainer}>
          <div className={classNames.calendarContainer}>
            <div
              style={{
                minHeight: "calc(100vh - 305px)",
                height: filteredMachines.length * CALENDAR_STEP_HEIGHT,
                width: calendarStepWidth * 7 * dates.length - 24,
                position: "absolute",
                backgroundImage: displayWeekView
                  ? 'url("./img/calendarBackground5.png")'
                  : 'url("./img/calendarBackground3.png")',
                backgroundRepeat: "repeat",
              }}
            >
              <div
                className="machineServiceContainer"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 556,
                  width: "100%",
                  height: filteredMachines.length * CALENDAR_STEP_HEIGHT,
                }}
              >
                {dates.length &&
                  horizontalPositionMap &&
                  verticalPositionMap &&
                  machinesData &&
                  Array.isArray(filteredMachines) &&
                  allPlansData &&
                  staffsData &&
                  filteredMachines.map(
                    (machine) =>
                      machine.machineService &&
                      machine?.machineService.length > 0 &&
                      machine.machineService.map((item, index) => (
                        <MachineServiceItem
                          calendarStepWidth={calendarStepWidth}
                          key={`${machine.machineId}-${index}`}
                          machineServiceIndex={index}
                          machineService={item}
                          machine={machine}
                          // machines={filteredMachines}
                          machineId={machine.machineId}
                          start={item["start"]}
                          end={item["end"]}
                          horizontalPositions={horizontalPositionMap}
                          verticalPositions={verticalPositionMap}
                          calendarStart={calendar.start}
                          calendarEnd={calendar.end}
                          machines={machinesData}
                          allPlansData={allPlansData}
                          staffsData={staffsData}
                        />
                      ))
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  techDepartment: state?.machine?.filter.techDepartment,
  techArea:
    state?.machine?.filter[state?.machine?.filter.techDepartment].techArea,
  displayWeekView: state.machine.displayWeekView,
  selectedDayOnCalendar: state.machine.selectedDayOnCalendar,
});

export default connect(mapStateToProps, { getMachines, selectNewDate })(
  MachineServiceCalendar
);
