import React, { useState, useEffect } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { ScrollablePane } from "@fluentui/react/lib/ScrollablePane";
import PersonaItem from "./PersonaItem";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getActivityByResourceIdAndResourceType } from "../../../../../redux/plan/plan.actions";
import { ACTIVITY_LOG_ENUM } from "../../../../../utils/constants";
import { getStaffs } from "../../../../../redux/user/user.actions";
import { getMachines } from "../../../../../redux/machines/machines.actions";
import { Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";

const classNames = mergeStyleSets({
    iconOutSide: {
        flexBasis: "10%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    iconInside: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "20%",
        cursor: "pointer",
        height: 30,
        width: 30,
    },
    icon: {
        color: "#fff",
        fontSize: 20,
    },
    commentContentSection: {
        borderRadius: 10,
        position: "relative",
        backgroundColor: "#fff",
    },
    pane: {
        padding: 16,
    },
    contentContainer: {
        borderRadius: 10,
    },
});

const scrollablePaneStyles = { root: classNames.pane, contentContainer: classNames.contentContainer };

const ActivityContainer = ({ projectId, planId, isPrebooking = false }) => {
    const dispatch = useDispatch();
    const { showPrebookingModal } = useSelector(state => state.prebooking);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    // Acvitivy of project or prebooking
    const { data: projectActivitiesData } = useQuery(
        ["projectActivities", showPrebookingModal],
        () => dispatch(getActivityByResourceIdAndResourceType(projectId, isPrebooking ? ACTIVITY_LOG_ENUM.BOOKING : ACTIVITY_LOG_ENUM.PROJECT)),
        { cacheTime: 0 }
    );
    const { data: planActivitiesData } = useQuery(
        "planActivities",
        () => dispatch(getActivityByResourceIdAndResourceType(planId, ACTIVITY_LOG_ENUM.PLANNING)),
        { cacheTime: 0 }
    );

    const { data: staffData } = useQuery("staffs", () => dispatch(getStaffs()));
    const { data: machineData } = useQuery("machines", () => dispatch(getMachines()));

    useEffect(() => {
        if (projectActivitiesData && (planId ? planActivitiesData : true) && staffData && machineData) {
            const tempData = [...projectActivitiesData, ...(planActivitiesData || [])].sort(
                (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
            );

            const staffRegex = new RegExp(`\\b(staffId:)[\\w]*\\b`, "g");
            const machineRegex = new RegExp(`\\b(machineId:)[\\w]*\\b`, "g");
            const colorRegex = new RegExp(`rgb\\{[\\d]{1,3},[\\d]{1,3},[\\d]{1,3}\\}`, "g");
            
            tempData.forEach((activity) => {
                if (activity.comment) {
                    const staffMatches = activity.comment.match(staffRegex);
                    const machineMatches = activity.comment.match(machineRegex);
                    const colorMatches = activity.comment.match(colorRegex);
                    staffMatches?.forEach((match) => {
                        const id = match.split(":")[1];
                        const staffInfo = staffData.find((item) => item.userId === id);
                        if (staffInfo) activity.comment = activity.comment.replace(match, `<b>${staffInfo.firstName} ${staffInfo.lastName}</b>`);
                    });
                    machineMatches?.forEach((match) => {
                        const id = match.split(":")[1];
                        const machineInfo = machineData.find((item) => item.machineId === id);
                        if (machineInfo) activity.comment = activity.comment.replace(match, `<b>${machineInfo.machineName}</b>`);
                    });
                    colorMatches?.forEach((match) => {
                        activity.comment = activity.comment.replace(
                            match,
                            `<span style="height: 13px; width: 13px; background-color: ${match.replace(/{/g, '(').replace(/}/g, ')')}; border-radius: 50%; display: inline-block"></span>`
                        );
                    });
                }
            });

            setData(tempData);
            setLoading(false);
        }
    }, [projectActivitiesData, planActivitiesData, staffData, machineData]);

    return (
        <div style={{ backgroundColor: "#fff", borderRadius: 10, height: 400, width: "100%", position: "relative" }}>
            <ScrollablePane styles={scrollablePaneStyles}>
                <div className={classNames.commentContentSection}>
                    {!loading ? (
                        data?.length ? (
                            data.map(({ id, userName, comment, userImageUrl, createdDate }, index) => (
                                <div key={id} style={{ padding: 16, backgroundColor: index % 2 === 0 ? "#F1F1F1" : "#fff" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        {/* <PersonaItem id={id} userName={userName} imageUrl={userImageUrl} createdDate={createdDate} /> */}
                                        <div style={{display: "flex", flexDirection: "column", marginBottom: 10}}>
                                            <div style={{fontSize: 14, fontWeight: "bold"}}>{userName}</div>
                                            <div style={{fontSize: 11, color: 'rgb(159,159,171)'}}>{moment(createdDate).format('MM.DD.YY HH:mm')}</div>
                                        </div>
                                        <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: comment }}/>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: 450,
                                }}
                            >
                                No activity recorded!
                            </div>
                        )
                    ) : (
                        <Spinner style={{ marginTop: 150 }} size={SpinnerSize.large} label="Loading..." labelPosition="right" />
                    )}
                </div>
            </ScrollablePane>
        </div>
    );
};

export default ActivityContainer;
