import React, { useCallback } from "react";
import { connect } from 'react-redux';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "../../redux/file/file.actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { DeleteConfirmation } from "./";
import { useBoolean } from "@fluentui/react-hooks";

const classNames = mergeStyleSets({
    uploadZone: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: "solid 1px #DFDFDF",
        marginBottom: 24,
        padding: 8,
        minHeight: 48,
    },
    flex: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 180,
    },
    iconClass: {
        fontSize: 50,
        height: 50,
        width: 50,
        margin: "0 25px",
        color: "#868685",
    },
    upper: {
        textTransform: "uppercase",
        fontWeight: 600
    },
    dropZone: {
        width:'80%',
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // padding: '0 10px'
    },
    icon: {
        fontSize: 20,
        cursor: 'pointer',
        marginRight: 10
    },
});

const FileUpload = ({ placeholder, setFile, fileUrl, uploadFile }) => {
    const [fileDeleteConfirmation, { toggle: toggleFileDeleteConfirmation }] = useBoolean(false);
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach(async (file) => {
            const uploadResult = await uploadFile(file);
            setFile(uploadResult['filePath']);
        });
    }, []);
    
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.pdf'
    });
    
    const onClickConfirm = () => {
        toggleFileDeleteConfirmation();
        setFile(null);
    }

    return (
        <>
            <div className={classNames.uploadZone}>
                {!fileUrl ? (
                    <>
                        <div className={classNames.dropZone}>
                            {placeholder && (
                                <span className={classNames.upper}>{placeholder}</span>
                            )}
                        </div>
                        <div className={classNames.icon} {...getRootProps()}>
                        <input {...getInputProps()} />
                            <FontAwesomeIcon icon={faUpload} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className={classNames.dropZone}>
                            {fileUrl && (
                                <p>{fileUrl}</p>
                            )}
                        </div>
                        <div className={classNames.icon}>
                            <FontAwesomeIcon id='removeFile' icon={faTrashAlt} className={classNames.removeIcon} onClick={toggleFileDeleteConfirmation}/>
                        </div>
                    </>
                )}
            </div>
            {fileDeleteConfirmation && (
                <DeleteConfirmation target={`#removeFile`} toggleTeaching={toggleFileDeleteConfirmation} onClickConfirm={onClickConfirm}/>
            )}
        </>
    );
}

export default connect(null, {uploadFile})(FileUpload);