import React, { useState, useMemo, useEffect, Fragment } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { Label } from "@fluentui/react/lib/Label";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import {
  IconButton,
  PrimaryButton,
  Stack,
  DefaultButton,
} from "@fluentui/react/";
import {
  customAutoCompleteStyles,
  primaryButtonStyles,
} from "../../utils/theme";
import {
  getPlans,
  createPlan,
  updatePlan,
} from "../../redux/plan/plan.actions";
import { getProjects } from "../../redux/project/project.actions";
import {
  clearOverlayPrebookingId,
  getPrebooking,
  deletePrebooking,
} from "../../redux/prebooking/prebooking.actions";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { ChooseMachineRequirementTable } from "./prebooking";
import {
  CONFIRM_PANEL_MODE,
  STORAGE_EVT_KEY_UPDATE_PLAN,
} from "../../utils/constants";
import { OverlayConfirm } from "../common";

import { Link } from "react-router-dom";

const classNames = mergeStyleSets({
  container: {
    minHeight: "100vh",
    background: "#F1F1F1",
  },
  label: {
    fontSize: 29,
    lineHeight: 29,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "136px 124px 116px 118px",
  },
  addNew: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: 320,
    margin: "0 auto",
  },
  pageInnerContainer: {
    margin: "auto auto",
    maxWidth: 1500,
  },
  back: {
    marginRight: 80,
  },
  messageContainer: {
    fontSize: 18,
    marginTop: 80,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createNewButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
});

const outlinedButtonStyles = {
  root: {
    borderRadius: 4,
    height: 40,
    borderColor: "#006CAD",
    color: "#006CAD",
    backgroundColor: "#fff",
  },
  label: { fontWeight: 400 },
  rootHovered: { borderColor: "#006CAD" },
};

const ReplacePrebooking = ({
  clearOverlayPrebookingId,
  getPrebooking,
  getPlans,
  getProjects,
  createPlan,
  updatePlan,
  deletePrebooking,
}) => {
  const history = useHistory();
  const { prebookingId } = useParams();
  const queryClient = useQueryClient();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [machineRequirements, setMachineRequirements] = useState([]);
  const [overlayMode, setOverlayMode] = useState(null);
  const [contentConfirmation, setContentConfirmation] = useState(null);
  const [isCreateNew, setIsCreateNew] = useState(false);

  const { data: prebookingData, isSuccess: prebookingStatusSuccess } = useQuery(
    ["prebooking", prebookingId],
    () => getPrebooking(prebookingId),
    {
      enabled: !!prebookingId,
      initialData: () =>
        queryClient
          .getQueryData("prebookings")
          ?.find((prebooking) => prebooking.id === prebookingId),
    }
  );

  const { data: plansData, refetch: refetchPlans } = useQuery("plans", () =>
    getPlans()
  );
  const { data: projectsData } = useQuery("projects", () => getProjects());
  const { mutate: mutateDeletePrebooking } = useMutation(
    () => deletePrebooking(prebookingId),
    {
      onSuccess: () => {
        clearOverlayPrebookingId();
        queryClient.invalidateQueries("plans");
        queryClient.invalidateQueries("projects");
        queryClient.invalidateQueries("prebookings");
        history.replace("/");
      },
    }
  );

  const { mutate: mutateCreatePlan } = useMutation(
    (planData) => createPlan(planData, prebookingId),
    {
      onSuccess: (data) => {
        if (data) {
          mutateDeletePrebooking();
        }
      },
    }
  );

  const { mutate: mutateUpdatePlan } = useMutation(
    (planData) => updatePlan(planData, prebookingId),
    {
      onSuccess: (data) => {
        if (data) {
          mutateDeletePrebooking();
        }
      },
    }
  );

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItem(selection.getSelection()[0]);
    },
  });

  const onClickOpenConfirmOverlay = (isCreatingNew) => {
    let mode = CONFIRM_PANEL_MODE.NORMAL;
    setContentConfirmation(null);
    const fieldsNotMatch = [];
    setIsCreateNew(isCreatingNew);
    if (!isCreatingNew && selectedItem) {
      const requirementStart = new Date(selectedItem.start).getTime();
      const requirementEnd = new Date(selectedItem.end).getTime();
      const prebookingMachineStart = new Date(prebookingData.start).getTime();
      const prebookingMachineEnd = new Date(prebookingData.end).getTime();
      // Period
      if (
        !(
          prebookingMachineStart >= requirementStart &&
          prebookingMachineEnd <= requirementEnd
        )
      ) {
        fieldsNotMatch.push("Period");
        mode = CONFIRM_PANEL_MODE.WARNING;
      }
      // Size
      if (prebookingData.sizeGroup !== selectedItem.sizeGroup) {
        fieldsNotMatch.push("Size");
        mode = CONFIRM_PANEL_MODE.WARNING;
      }
      // Type
      if (prebookingData.machineType !== selectedItem.machineType) {
        fieldsNotMatch.push("Type");
        mode = CONFIRM_PANEL_MODE.DANGER;
      }
      // Section
      if (prebookingData.techDepartment !== selectedItem.techDepartment) {
        fieldsNotMatch.push("Section");
        mode = CONFIRM_PANEL_MODE.DANGER;
      }
      if (fieldsNotMatch.length > 0) {
        const content = (
          <>
            <div style={{ marginTop: 50 }}>
              The following to NOT match the requirement?
            </div>
            <ul>
              {fieldsNotMatch.map((field) => (
                <li key={field}>{field}</li>
              ))}
            </ul>
          </>
        );
        setContentConfirmation(content);
      }
    }
    setOverlayMode(mode);
  };

  const onClickConfirm = () => {
    const machineTemp = {
      machineId: prebookingData.machineId,
      start: prebookingData.start,
      end: prebookingData.end,
    };
    let planData = null;
    if (selectedItem && !isCreateNew) {
      const machineRequirements = JSON.parse(
        JSON.stringify(selectedPlan.machineRequirements)
      );
      machineRequirements[selectedItem.index].machines = [machineTemp];
      planData = {
        ...selectedPlan,
        machineRequirements,
      };
      mutateUpdatePlan(planData);
    } else {
      if (selectedPlan) {
        planData = {
          ...selectedPlan,
          machineRequirements: [
            ...selectedPlan.machineRequirements,
            {
              sizeGroup: prebookingData.sizeGroup,
              techDepartment: prebookingData.techDepartment,
              machineType: prebookingData.machineType,
              start: prebookingData.start,
              end: prebookingData.end,
              machines: [machineTemp],
            },
          ],
        };
        mutateUpdatePlan(planData);
      } else {
        planData = {
          projectId: selectedProject.projectId,
          color: selectedProject.color,
          machineRequirements: [
            {
              sizeGroup: prebookingData.sizeGroup,
              techDepartment: prebookingData.techDepartment,
              machineType: prebookingData.machineType,
              start: prebookingData.start,
              end: prebookingData.end,
              machines: [machineTemp],
            },
          ],
          drivers: [],
          workers: [],
          managers: [],
          start: selectedProject.start,
          end: selectedProject.end,
        };
        mutateCreatePlan(planData);
      }
    }
  };

  const projectOptions = useMemo(() => {
    return projectsData
      ? projectsData.map((plan) => ({
          key: plan.projectId,
          label: `${plan.projectName} ${plan.projectNo}`,
          data: { ...plan },
        }))
      : [];
  }, [projectsData]);

  useEffect(() => {
    const receiveMessage = (ev) => {
      if (ev.key === STORAGE_EVT_KEY_UPDATE_PLAN) {
        var message = JSON.parse(ev.newValue);

        const id = message?.id;

        if (selectedProject && id === selectedProject?.id) {
          refetchPlans();
        }
      }
    };

    window.addEventListener("storage", receiveMessage);

    return () => {
      window.removeEventListener("storage", receiveMessage);
    };
  }, [refetchPlans, selectedProject]);

  useEffect(() => {
    const existingPlan = plansData?.find(
      (plan) => plan.projectId === selectedProject?.projectId
    );
    if (existingPlan) {
      setSelectedPlan(existingPlan);
      let tempMachineRequirements =
        existingPlan?.machineRequirements.map((requirement, index) => ({
          ...requirement,
          index,
        })) ?? [];
      if (tempMachineRequirements.length > 0) {
        tempMachineRequirements = tempMachineRequirements.filter(
          (requirement) => requirement.machines.length === 0
        );
      }
      setMachineRequirements(tempMachineRequirements);
    } else {
      setSelectedPlan(null);
      setMachineRequirements([]);
      setSelectedItem(null);
    }
  }, [plansData, selectedProject]);

  return (
    <Fragment>
      <div className={classNames.container}>
        <div className={classNames.header}>
          <div className={classNames.back}>
            <IconButton
              iconProps={{ iconName: "ChromeBack" }}
              onClick={() => history.goBack()}
            />
          </div>
          <Label className={classNames.label}>
            Konveter {prebookingStatusSuccess ? prebookingData.projectName : ""}{" "}
            til projekt
          </Label>
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <PrimaryButton
              disabled={!selectedItem}
              styles={primaryButtonStyles}
              text="Konveter"
              onClick={() => {
                onClickOpenConfirmOverlay(false /* isCreatingNew */);
              }}
            />
          </Stack>
        </div>
        <div className={classNames.content}>
          <div
            style={{
              fontFamily: "Verdana",
              color: "#000",
              fontSize: 11,
              fontWeight: "bold",
              transform: "translate(9px, 17px)",
              zIndex: 2,
            }}
          >
            VÆLG PROJEKT
          </div>
          <Select
            placeholder="VÆLG"
            name="machineId"
            closeMenuOnSelect={true}
            defaultValue={projectOptions.find(
              (item) => item.key === selectedProject?.projectId
            )}
            options={projectOptions}
            isClearable={true}
            isSearchable={true}
            onChange={(item) => {
              setSelectedProject(item?.data);
            }}
            className={classNames.input}
            styles={customAutoCompleteStyles(false, false)}
            getOptionValue={(option) => option.label}
          />
        </div>
        {machineRequirements.length !== 0 && selectedProject && (
          <div className={classNames.pageInnerContainer}>
            <div className={classNames.messageContainer}>
              Select existing requirement
            </div>
            <ChooseMachineRequirementTable
              machineRequirements={machineRequirements}
              selection={selection}
            />
            <div className={classNames.messageContainer}>
              <div>Or create new</div>
            </div>
            <div className={classNames.createNewButtonContainer}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={`/add-machine-requirements/${selectedProject.projectId}`}
              >
                <DefaultButton
                  styles={outlinedButtonStyles}
                  text="Create new requirement"
                />
              </Link>
            </div>
          </div>
        )}
        {machineRequirements.length === 0 && selectedProject && (
          <>
            <div className={classNames.messageContainer}>
              There is no requirements please create a new one
            </div>
            <div className={classNames.createNewButtonContainer}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={`/add-machine-requirements/${selectedProject.projectId}`}
              >
                <DefaultButton
                  styles={outlinedButtonStyles}
                  text="Create new requirement"
                />
              </Link>
            </div>
          </>
        )}
      </div>
      <OverlayConfirm
        overlayMode={overlayMode}
        onClose={() => {
          setOverlayMode(null);
        }}
        title="Are you sure you want to replace this Prebooking plan?"
        content={contentConfirmation}
        content2="The Prebooking will be deleted."
        onClickConfirm={onClickConfirm}
      />
    </Fragment>
  );
};

export default connect(null, {
  clearOverlayPrebookingId,
  getPrebooking,
  getPlans,
  getProjects,
  createPlan,
  updatePlan,
  deletePrebooking,
})(ReplacePrebooking);
